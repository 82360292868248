import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnaliticsComponent } from './analitics/analitics.component';
import { AddAddressComponent } from './customers/add-address/add-address.component';
import { AddCustomerUserComponent } from './customers/add-customer-user/add-customer-user.component';
import { AddCustomerComponent } from './customers/add-customer/add-customer.component';
import { CustomersComponent } from './customers/customers.component';
import { DetailcustomerComponent } from './customers/detailcustomer/detailcustomer.component';
import { EditCustomerComponent } from './customers/edit-customer/edit-customer.component';
import { UpdateAddressComponent } from './customers/update-address/update-address.component';
import { GeneralIndicatorsComponent } from './general-indicators/general-indicators.component';
import { AddMarathonComponent } from './general-indicators/marathons/add-marathon/add-marathon.component';
import { DetailMarathonComponent } from './general-indicators/marathons/detail-marathon/detail-marathon.component';
import { MarathonsComponent } from './general-indicators/marathons/marathons.component';
import { UserIndicatorsComponent } from './general-indicators/user-indicators/user-indicators.component';
import { LogComponent } from './log/log.component';
import { AddNewComponent } from './news/add-new/add-new.component';
import { DetailNewsComponent } from './news/detail-news/detail-news.component';
import { NewsComponent } from './news/news.component';
import { ProgrammersComponent } from './programmers.component';
import { AddTicketComponent } from './tickets/add-ticket/add-ticket.component';
import { DetTicketComponent } from './tickets/det-ticket/det-ticket.component';
import { TicketsComponent } from './tickets/tickets.component';
import { AddUserComponent } from './users/add-user/add-user.component';
import { DetailUserComponent } from './users/detail-user/detail-user.component';
import { EditDeptosComponent } from './users/edit-deptos/edit-deptos.component';
import { ModulesPermissionsComponent } from './users/modules-permissions/modules-permissions.component';
import { UsersInactiveComponent } from './users/users-inactive/users-inactive.component';
import { UsersComponent } from './users/users.component';

const routes: Routes = [
  //Programadores
  { path: 'programmers',children:[
    {path:'',component: ProgrammersComponent},
    {path:'users',children:[
        {path:'',component:UsersComponent},
        {path:'permissions_module/:id_user',component:ModulesPermissionsComponent},
        {path: 'edit_deptos/:id_user', component: EditDeptosComponent},
        {path: 'detail_user/:id_user', component: DetailUserComponent},
        {path: 'add_user', component: AddUserComponent},
        {path: 'users_inactive', component: UsersInactiveComponent},
      ] 
    },
    {path: 'general_indicators', children:[
      {path: '',  component: GeneralIndicatorsComponent},
      {path: 'user_indicators',  component: UserIndicatorsComponent},
      {path: 'marathons', children:[
        {path:'',component:MarathonsComponent},
        {path: 'add', component: AddMarathonComponent},
        {path: 'detail/:id', component: DetailMarathonComponent},
      ]
      },
      
    ]},
    {path:'customers', children:[
      {path: '', component: CustomersComponent},
      {path: 'add_customer', component: AddCustomerComponent},
      {path: 'add_address/:idcliente', component: AddAddressComponent},
      {path: 'add_user/:idcliente', component: AddCustomerUserComponent},
      {path: 'edit_customer/:idcliente', component: EditCustomerComponent},
      {path: 'edit_address/:iddireccion', component: UpdateAddressComponent}
    ]},
    {path: 'customer/:id', component: DetailcustomerComponent},
    
    {path:'tickets', children:[
      {path: '', component:TicketsComponent},
      {path: 'add_ticket/:id', component:AddTicketComponent},
      {path: 'det_ticket/:id', component:DetTicketComponent}
    ]},
  
    {path: 'news', children:[
      {path: '', component: NewsComponent},
      {path: 'detail_new/:id', component: DetailNewsComponent},
      {path: 'add_new', component: AddNewComponent}
    ]},
    {path:'log', component:LogComponent},
    {path:'analitics', component:AnaliticsComponent}
  ]
  },
  ];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProgrammersRoutingModule { }
