import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-event-history',
  templateUrl: './event-history.component.html',
  styleUrls: ['./event-history.component.css']
})
export class EventHistoryComponent implements OnInit {
  id_instrument:string;
  loader:number = 1;
  permmisions = JSON.parse(localStorage.getItem('permisos'));
  response:any = [];
  data:any= [];
  number_page= 0;
  pages = 0;
  tag = '';

  constructor(private activedRoute:ActivatedRoute,private eventService:EventService,private modalService:NgbModal) { }

  ngOnInit(): void {
    this.id_instrument = this.activedRoute.snapshot.paramMap.get('id');
    this.loadData();
  }

  loadData(){
    this.eventService.getEventsHistory(this.id_instrument).subscribe(
      res=>{
        this.response = res;
        console.log(this.response);
        if(this.response.code != '0_005')this.lauchModal(this.response.code,this.response.message,'');
        else{
          this.data = this.response.data.list;
          this.tag = this.response.data.tag;
        }
        this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta','');
        console.log(err);
        this.loader = 2;
      }
    );
  }

  lauchModal(code:string,title:string,message){
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }

}
