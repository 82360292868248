import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { NewsService } from "src/app/services/news.service";
import { OrdenVentaService } from "src/app/services/orden-venta.service";
import { SaleOrderService } from "src/app/services/sale-order.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-det-email",
  templateUrl: "./det-email.component.html",
  styleUrls: ["./det-email.component.css"],
})
export class DetEmailComponent implements OnInit {
  loader = 0;
  asunto = "";
  usuarios: any = [];
  mensaje = "";
  tipo_email = "";

  list_auxiliar: any = [];
  remitente = "";
  destinatario = "";
  new_email = "";

  notas = "";
  userResult: any = [];

  responseAuxiliar: any = [];
  list_Auxiliar: any = [];
  data_Auxiliar: any = [];

  tipoemail1 = false;
  tipoemail2 = false;
  tipoemail3 = false;
  sendingemail = false;

  id_auxiliar = ""; //Puede ser id de orden de compra o cualquier otro para poder adjuntar ese pdf por id
  axiliar_name = "";

  constructor(
    private activedRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private saleOrderService: SaleOrderService,
    private modalService: NgbModal,
    private avisoService: NewsService,
    private ordenVentaService: OrdenVentaService
  ) {}

  ngOnInit(): void {
    this.tipo_email = this.activedRoute.snapshot.paramMap.get("id_tipo");
    if (this.tipo_email == "1") {
      this.tipoemail1 = true;
    }
    if (this.tipo_email == "2") {
      this.getListOdc();
      this.asunto = "Información de orden de compra";
      this.tipoemail2 = true;
    }
    if (this.tipo_email == "3") {
      this.getListOdv();
      this.asunto = "Envío de informes en .ZIP de la orden de venta ";
      this.tipoemail3 = true;
    }
    this.getUsers();
  }

  /**
   * This function retrieves all users with an ID of 8 using a user service and stores the result in a
   * variable.
   */
  private getUsers() :void {
    this.userService.getAllUsers("8").subscribe(
      (res) => {
        this.userResult = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /**
   * This function retrieves a list of ODCs from a sale order service and handles any errors that may
   * occur.
   */
  private getListOdc() :void {
    this.saleOrderService.getODCList().subscribe(
      (res) => {
        this.responseAuxiliar = res;

        if (
          this.responseAuxiliar.code == "0_005" &&
          this.responseAuxiliar.data != "0"
        ) {
          this.responseAuxiliar.data.forEach((element) => {
            for (let o = 0; o < element.length; o++) {
              this.list_Auxiliar.push(element[o]);
            }
          });
        } else
          this.lauchModal(
            this.responseAuxiliar.code,
            this.responseAuxiliar.message,
            ""
          );
        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", err.message);
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * The function retrieves a list of orders for email details from a service and handles the response
   * accordingly.
   */
  private getListOdv() :void {
    this.ordenVentaService.getOdvListForDetEmail().subscribe(
      (res) => {
        this.responseAuxiliar = res;

        if (
          this.responseAuxiliar.code == "0_005" &&
          this.responseAuxiliar.data != "0"
        ) {
          this.list_Auxiliar = this.responseAuxiliar.data;
        } else
          this.lauchModal(
            this.responseAuxiliar.code,
            this.responseAuxiliar.message,
            ""
          );
        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", err.message);
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * This function retrieves details of an ODC (Order Delivery Confirmation) and handles any errors
   * that may occur during the process.
   */
  private getOdcDetail() :void {
    this.loader = 1;
    this.saleOrderService.getDetailODC(this.id_auxiliar).subscribe(
      (res) => {
        this.responseAuxiliar = res;
        if (this.responseAuxiliar.code != "0_005")
          this.lauchModal(
            this.responseAuxiliar.code,
            "",
            this.responseAuxiliar.message
          );
        else {
          this.data_Auxiliar = this.responseAuxiliar.data;
        }
        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "", "Error de consulta");
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * This function retrieves details of an order for a given email address and displays an error
   * message if there is an issue with the request.
   */
  private getOdvDetail() :void {
    this.loader = 1;
    this.ordenVentaService.getOdvDetailForDetEmail(this.id_auxiliar).subscribe(
      (res) => {
        this.responseAuxiliar = res;

        if (this.responseAuxiliar.code != "0_005")
          this.lauchModal(
            this.responseAuxiliar.code,
            "",
            this.responseAuxiliar.message
          );
        else {
          this.data_Auxiliar = this.responseAuxiliar.data;
        }
        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "", "Error de consulta");
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * The function "selectODC" sets the ID and name of an auxiliary and retrieves details about an ODC.
   * @param identificador - The parameter "identificador" is likely a string variable that represents
   * the name or identifier of an object. It is used as an input parameter for the "selectODC"
   * function.
   * @param id - The "id" parameter is a variable that is passed as an argument to the "selectODC"
   * function. It is used to store the value of the "id" parameter passed to the function, which is
   * then used later in the function to retrieve details about an ODC (Order Delivery Confirmation
   */
  public selectODC(identificador, id) :void {
    this.id_auxiliar = id;
    this.axiliar_name = identificador;
    this.getOdcDetail();
  }

  /**
   * The function "selectODV" sets certain properties and calls another function to get details related
   * to a specific identifier and ID.
   * @param identificador - It is a variable that represents the identifier or name of an ODV (Object
   * Data Value) that is being selected.
   * @param id - The "id" parameter is likely an identifier or reference to a specific object or data
   * in the system. It is being assigned to the "id_auxiliar" property of the object.
   */
  public selectODV(identificador, id) :void {
    this.asunto += identificador;
    this.id_auxiliar = id;
    this.axiliar_name = identificador;
    this.getOdvDetail();
  }

  /**
   * This function adds or removes an email from an array of emails, converting it to a string array
   * afterwards.
   * @param correo - a string representing an email address to be added to or removed from an array of
   * email addresses stored in the object.
   */
  private agregarCorreo(correo) :void {
    if (this.usuarios.length > 0) {
      let contador: number;
      let bandera: boolean = false;
      let posicioneliminar: number;
      for (contador = 0; contador < this.usuarios.length; contador++) {
        if (this.usuarios[contador] == correo) {
          bandera = true;
          posicioneliminar = contador;
        }
      }

      if (!bandera) {
        this.usuarios.push(correo);
      } else {
        this.usuarios.splice(posicioneliminar, 1);
      }
    } else {
      this.usuarios.push(correo);
    }
    this.pasarAStringArray();
  }

  /**
   * The function converts an array of strings to a single string separated by commas.
   */
  private pasarAStringArray() :void {
    this.destinatario = "";
    this.usuarios.forEach((element) => {
      if (this.destinatario == "") {
        this.destinatario = element;
      } else {
        this.destinatario = this.destinatario + "," + element;
      }
    });
  }

  /**
   * This function adds an extra email to a list if the input is not empty, otherwise it displays an
   * error message.
   */
  public agregarCorreoExtra() :void {
    if (this.new_email.trim() != "") {
      this.agregarCorreo(this.new_email);
      this.new_email = "";
    } else {
      this.lauchModal("", "Campo vacio", "Por favor ingresa un correo");
    }
  }

  /**
   * This function sends an email using the avisoService and handles any errors that may occur.
   */
  public sendEmail() :void {
    this.sendingemail = true;
    this.avisoService
      .sendMailAny(
        this.asunto,
        this.mensaje,
        this.destinatario,
        this.id_auxiliar,
        this.axiliar_name,
        this.tipo_email,
        this.notas
      )
      .subscribe(
        (res) => {
          this.router.navigate(["/commercial/email"]);
        },
        (err) => {
          this.lauchModal(
            "",
            "Ocurrió un error",
            "Por favor intenta más tarde."
          );
          this.sendingemail = false;
          console.log(err);
        }
      );
  }

  /**
   * This function launches a modal with a given code, title, and message using the Angular Material
   * modal service.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - The title of the modal that will be displayed.
   * @param message - The message parameter is likely a string that contains the content to be
   * displayed in the body of the modal dialog box. It could be a message to the user or some other
   * information that needs to be communicated.
   */
  private async lauchModal(code: string, title: string, message) :Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
  
}
