import { Component, OnInit, Input } from "@angular/core";
import {
  NgbActiveModal,
  NgbModal,
  NgbModalConfig,
} from "@ng-bootstrap/ng-bootstrap";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.css"],
})
export class ModalComponent implements OnInit {
  @Input() code;
  @Input() message: string = "";
  @Input() another_cause: boolean = false;
  @Input() title;
  constructor(
    public activeModal: NgbActiveModal,
    public config: NgbModalConfig,
    private userService: UserService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    if (this.code == "1_0001")
      this.message = "Llaves no autorizadas. Sin acceso.";
    else if (this.code == "1_0002")
      this.message = "Llave de usuario no autorizada. Sin acceso.";
    else if (this.code == "1_0003")
      this.message = "La sesión se ha cerrado. Inicie sesión nuevamente.";
    else if (this.code == "1_0004")
      this.message =
        "Intento fallido de acceso a módulo. (Módulo no autorizado)";
    else if (this.code == "0_007")
      this.message = "Algo salió mal. Intente de nuevo.";
    else if (this.code == "0_008")
      this.message = "Se ha encontrado un registro previo existente.";
    else if (this.code == "0000x00")
      this.message = "Error de decodificación en servidor.";
  }

  /**
   * The function dismisses all modals and navigates to the dashboard.
   */
  public goToDashboard(): void {
    this.modalService.dismissAll();
  }

  /**
   * This function closes the current user session and dismisses any open modals.
   */
  public closeSession(): void {
    this.modalService.dismissAll();
    this.userService.closeSession();
  }
}
