import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IWikiReg } from './models/IWikiReg';
import { environment } from 'src/app/variables/enviroment';

@Injectable({
  providedIn: 'root'
})
export class WikiService {

  uri:string = environment.uri;
  //uri:string = "http://ngdat.ucal.com.mx/api/";
  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');

  constructor(private httpClient: HttpClient) { }

  getWiki(){  
    return this.httpClient.get(this.uri+"get_wiki_data?api_key="+this.api_key+"&token="+this.token+"&module=28");
  }

  getDetail(id){
    return this.httpClient.get(this.uri+"get_wiki_detail?api_key="+this.api_key+"&token="+this.token+"&module=80&id="+id);
  }
  addNewRegister(data){
    return this.httpClient.post(this.uri+"save_wiki_reg?api_key="+this.api_key+"&token="+this.token+"&module=29",data);
  }
}
