import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { float } from "@zxing/library/esm/customTypings";
import { Chart } from "chart.js";
import { Html2CanvasOptions } from "jspdf";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { QuotationService } from "src/app/services/quotation.service";

@Component({
  selector: "app-indicators-cot",
  templateUrl: "./indicators-cot.component.html",
  styleUrls: ["./indicators-cot.component.css"],
})
export class IndicatorsCotComponent implements OnInit {
  loader: number = 1;
  permmisions = JSON.parse(localStorage.getItem("permisos"));
  data: any = [];

  chartTotalOdv: any = null;
  chartTotalFact: any = null;
  chartTotal: any = null;
  chartWinner: any = null;
  myChart: any = null;
  chartRep: any = null;
  chartRepLine: any = null;
  sales_rep_names: any = [];
  colores: any = [];
  private currentDate = new Date();
  month_selected = "01";
  anio_selected = "2024";

  anio_list = [{ anio: "2022" }, { anio: "2023" }, { anio: "2024" }];

  months_list = [
    { id: "01", desc: "Enero" },
    { id: "02", desc: "Febrero" },
    { id: "03", desc: "Marzo" },
    { id: "04", desc: "Abril" },
    { id: "05", desc: "Mayo" },
    { id: "06", desc: "Junio" },
    { id: "07", desc: "Julio" },
    { id: "08", desc: "Agosto" },
    { id: "09", desc: "Septiembre" },
    { id: "10", desc: "Octubre" },
    { id: "11", desc: "Noviembre" },
    { id: "12", desc: "Diciembre" },
  ];

  constructor(
    private modalService: NgbModal,
    private quotationServ: QuotationService
  ) {}

  ngOnInit(): void {
    this.month_selected = this.getMonthActual();
    this.getData();
  }

  /**
   * This function retrieves data from a server and generates various charts based on the data.
   */
  public getData(): void {
    this.loader = 1;
    this.sales_rep_names = [];
    let response: any = [];
    this.quotationServ
      .obtenerIndicadores(this.month_selected, this.anio_selected)
      .subscribe(
        (res) => {
          response = res;
          if (response.code != "0_005")
            this.lauchModal(response.code, response.message, "");
          else {
            this.data = response.data;
            this.colores = this.data.colores;
            this.data.sales_rep.forEach((ss) => {
              this.sales_rep_names.push(ss.nombre);
            });
            this.chartSpeedTotalMesCot();
            this.chartSpeedTotalMesOdv();
            this.chartSpeedTotalMesFact();
            this.chartdoughnutAllPorcent();
            this.chartSpeedWinner();
            this.salesRepresentative();
            this.salesRepresentativeLine();
          }
          this.loader = 2;
        },
        (err) => {
          this.loader = 2;
          this.lauchModal("0000x00", "Error de consulta", "");
          console.log(err);
        }
      );
  }

  /**
   * The function formats a given value as currency in Mexican pesos.
   * @param value - The value parameter is the number that needs to be formatted as currency in Mexican
   * pesos (MXN).
   * @returns The `setFormat` function is returning a formatted string representation of the `value`
   * parameter, using the `Intl.NumberFormat` API to format the number as a currency in Mexican pesos
   * (MXN).
   */
  public setFormat(value): string {
    const options2 = { style: "currency", currency: "MXN" };
    const numberFormat2 = new Intl.NumberFormat("es-MX", options2);

    return numberFormat2.format(value);
  }

  /**
   * This function destroys a chart if it exists.
   * @param chart - The parameter "chart" is a variable that represents a chart object. The function
   * "destroyChart" takes this chart object as input and destroys it if it is not null.
   */
  public destroyChart(chart): void {
    if (chart != null) chart.destroy();
  }

  /**
   * This function creates a doughnut chart with a needle that indicates the total value of a set of
   * data, and displays the value in a specific format.
   */
  private chartSpeedTotalMesCot() :void {
    if (this.chartTotal != undefined) this.chartTotal.destroy();

    var doc = document.getElementById(
      "chart-gauge-monto-cot"
    ) as HTMLCanvasElement;
    var ctx = doc.getContext("2d");

    let cots = this.data.all_cot;
    let sumatoria = 0;
    cots.forEach((a) => {
      sumatoria = sumatoria + parseFloat(a.total);
    });
    this.chartTotal = new Chart(ctx, {
      type: "doughnut",
      plugins: {
        afterDraw: (chart) => {
          var needleValue = chart.config.data.datasets[0].needleValue;
          var dataTotal = chart.config.data.datasets[0].data.reduce(
            (a, b) => a + b,
            0
          );
          var angle = Math.PI + (1 / dataTotal) * needleValue * Math.PI;
          var ctx = chart.ctx;
          var cw = chart.canvas.offsetWidth;
          var ch = chart.canvas.offsetHeight;
          var cx = cw / 2;
          var cy = ch;

          ctx.translate(cx, cy);
          ctx.rotate(angle);
          ctx.beginPath();
          ctx.moveTo(0, -3);
          ctx.lineTo(ch - 35, 0);
          ctx.lineTo(0, 3);
          ctx.moveTo(0, 3);
          ctx.fillStyle = "#444";
          ctx.fill();
          ctx.rotate(-angle);
          ctx.translate(-cx, -cy);

          ctx.font = "19px Helvetica";
          ctx.fillStyle = "#444";
          ctx.fillText(this.setFormat(needleValue), cx - 50, cy - 50);
          ctx.textAlign = "center";
          ctx.restore();
        },
      },
      data: {
        labels: [
          "$0 a $1 millon 500 mil",
          "$1 millon 500 mil a $2 millones",
          "Más de $2 millones",
        ],
        datasets: [
          {
            label: "Gauge",
            data: [1500000, 500000, 2000000],
            backgroundColor: [
              "rgb(252, 49, 25)",
              "rgb(238, 252, 25)",
              "rgb(75, 227, 17)",
            ],
            borderWidth: 1,
            needleValue: sumatoria,
          },
        ],
      },
      options: {
        circumference: Math.PI,
        rotation: Math.PI,
        cutoutPercentage: 80, // precent
        legend: {
          display: true,
        },
        tooltips: {
          enabled: false,
        },
      },
    });
  }

  /**
   * This function creates a doughnut chart with a needle that indicates a value based on the sum of
   * data points, and displays the value in text.
   */
  private chartSpeedTotalMesOdv() :void {
    if (this.chartTotalOdv != undefined) this.chartTotalOdv.destroy();

    var doc = document.getElementById(
      "chart-gauge-monto-odv"
    ) as HTMLCanvasElement;
    var ctx = doc.getContext("2d");

    let sumatoria = this.data.total_odv;
    this.chartTotalOdv = new Chart(ctx, {
      type: "doughnut",
      plugins: {
        afterDraw: (chart) => {
          var needleValue = chart.config.data.datasets[0].needleValue;
          var dataTotal = chart.config.data.datasets[0].data.reduce(
            (a, b) => a + b,
            0
          );
          var angle = Math.PI + (1 / dataTotal) * needleValue * Math.PI;
          var ctx = chart.ctx;
          var cw = chart.canvas.offsetWidth;
          var ch = chart.canvas.offsetHeight;
          var cx = cw / 2;
          var cy = ch;

          ctx.translate(cx, cy);
          ctx.rotate(angle);
          ctx.beginPath();
          ctx.moveTo(0, -3);
          ctx.lineTo(ch - 35, 0);
          ctx.lineTo(0, 3);
          ctx.moveTo(0, 3);
          ctx.fillStyle = "#444";
          ctx.fill();
          ctx.rotate(-angle);
          ctx.translate(-cx, -cy);

          ctx.font = "19px Helvetica";
          ctx.fillStyle = "#444";
          ctx.fillText(this.setFormat(needleValue), cx - 50, cy - 50);
          ctx.textAlign = "center";
          ctx.restore();
        },
      },
      data: {
        labels: [
          "$0 a $700 mil",
          "$700 mil a $1000 millón",
          "Más de $1 millón",
        ],
        datasets: [
          {
            label: "Gauge",
            data: [700000, 300000, 1000000],
            backgroundColor: [
              "rgb(252, 49, 25)",
              "rgb(238, 252, 25)",
              "rgb(75, 227, 17)",
            ],
            borderWidth: 1,
            needleValue: sumatoria,
          },
        ],
      },
      options: {
        circumference: Math.PI,
        rotation: Math.PI,
        cutoutPercentage: 80, // precent
        legend: {
          display: true,
        },
        tooltips: {
          enabled: false,
        },
      },
    });
  }

  /**
   * This function creates a doughnut chart with a needle that indicates a value based on the sum of
   * data and displays the value in a specific format.
   */
  private chartSpeedTotalMesFact() :void {
    if (this.chartTotalFact != undefined) this.chartTotalFact.destroy();

    var doc = document.getElementById(
      "chart-gauge-monto-fact"
    ) as HTMLCanvasElement;
    var ctx = doc.getContext("2d");

    let sumatoria = this.data.total_fact;
    this.chartTotalFact = new Chart(ctx, {
      type: "doughnut",
      plugins: {
        afterDraw: (chart) => {
          var needleValue = chart.config.data.datasets[0].needleValue;
          var dataTotal = chart.config.data.datasets[0].data.reduce(
            (a, b) => a + b,
            0
          );
          var angle = Math.PI + (1 / dataTotal) * needleValue * Math.PI;
          var ctx = chart.ctx;
          var cw = chart.canvas.offsetWidth;
          var ch = chart.canvas.offsetHeight;
          var cx = cw / 2;
          var cy = ch;

          ctx.translate(cx, cy);
          ctx.rotate(angle);
          ctx.beginPath();
          ctx.moveTo(0, -3);
          ctx.lineTo(ch - 35, 0);
          ctx.lineTo(0, 3);
          ctx.moveTo(0, 3);
          ctx.fillStyle = "#444";
          ctx.fill();
          ctx.rotate(-angle);
          ctx.translate(-cx, -cy);

          ctx.font = "19px Helvetica";
          ctx.fillStyle = "#444";
          ctx.fillText(this.setFormat(needleValue), cx - 50, cy - 50);
          ctx.textAlign = "center";
          ctx.restore();
        },
      },
      data: {
        labels: [
          "$0 a $700 mil",
          "$700 mil a $1000 millón",
          "Más de $1 millón",
        ],
        datasets: [
          {
            label: "Gauge",
            data: [700000, 300000, 1000000],
            backgroundColor: [
              "rgb(252, 49, 25)",
              "rgb(238, 252, 25)",
              "rgb(75, 227, 17)",
            ],
            borderWidth: 1,
            needleValue: sumatoria,
          },
        ],
      },
      options: {
        circumference: Math.PI,
        rotation: Math.PI,
        cutoutPercentage: 80, // precent
        legend: {
          display: true,
        },
        tooltips: {
          enabled: false,
        },
      },
    });
  }

 /**
  * This function creates a doughnut chart with a needle that displays the percentage of wins and
  * updates it based on the input data.
  */
  private chartSpeedWinner() :void {
    if (this.chartWinner != undefined) this.chartWinner.destroy();
    var doc = document.getElementById("chartjs-gauge") as HTMLCanvasElement;
    var ctx = doc.getContext("2d");

    var gradient = ctx.createLinearGradient(0, 200, 300, 550);
    gradient.addColorStop(0, "rgba(0, 175,0, 0.1)");
    gradient.addColorStop(0.3, "rgba(0, 205, 0, 0.40)");
    gradient.addColorStop(0.7, "rgba(0, 235, 0, 0.70)");
    gradient.addColorStop(1, "rgba(0, 255, 0, 1)");

    this.chartWinner = new Chart(ctx, {
      type: "doughnut",
      plugins: {
        afterDraw: (chart) => {
          var needleValue = chart.config.data.datasets[0].needleValue;
          var dataTotal = chart.config.data.datasets[0].data.reduce(
            (a, b) => a + b,
            0
          );
          var angle = Math.PI + (1 / dataTotal) * needleValue * Math.PI;
          var ctx = chart.ctx;
          var cw = chart.canvas.offsetWidth;
          var ch = chart.canvas.offsetHeight;
          var cx = cw / 2;
          var cy = ch;

          //needle
          ctx.translate(cx, cy);
          ctx.rotate(angle);
          ctx.beginPath();
          ctx.moveTo(0, -3);
          ctx.lineTo(ch - 30, 0);
          ctx.lineTo(0, 3);
          ctx.moveTo(0, 3);
          ctx.fillStyle = "#444";
          ctx.fill();
          ctx.rotate(-angle);
          ctx.translate(-cx, -cy);

          ctx.font = "30px Helvetica";
          ctx.fillStyle = "#444";
          ctx.fillText(needleValue + "%", cx - 30, cy - 90);
          ctx.textAlign = "center";
          ctx.restore();
        },
      },
      data: {
        labels: ["Porcentage de ganadas"],
        datasets: [
          {
            label: "Gauge",
            data: [25, 25, 25, 25],
            backgroundColor: gradient,
            borderWidth: 1,
            needleValue: this.data.ganadas_percent,
          },
        ],
      },
      options: {
        circumference: Math.PI,
        rotation: Math.PI,
        cutoutPercentage: 80, // precent
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
      },
    });

    // DEMO Code: not relevant to example
    /*
    function change_gauge(chart, label, data){
      chart.data.datasets.forEach((dataset) => {
        if(dataset.label == label){
          dataset.data = data;
        }  
      });
      chart.update();
      }

    var accelerating = false;
    function accelerate(){
      accelerating = false;
      window.setTimeout(function(){
          change_gauge(chart,"Gauge",[27,33,25,15])
      }, 1000);

      window.setTimeout(function(){
          change_gauge(chart,"Gauge",[15,25,25,35])
      }, 2000);

      window.setTimeout(function(){
          change_gauge(chart,"Gauge",[25,25,25,25])
      }, 3000);

      window.setTimeout(function(){
          change_gauge(chart,"Gauge",[17,25,25,33])
      }, 4000);

      window.setTimeout(function(){
          change_gauge(chart,"Gauge",[10,40,30,20])
      }, 5000);
    }

    // Start sequence
    accelerate();
    window.setInterval(function(){
      if(!accelerating){
        accelerating = true;
        accelerate();
      }
    }, 6000); */
  }

  //Grafica por estatus
  /**
   * This function creates a doughnut chart using Chart.js library to display the percentage of
   * different types of quotations.
   * @returns Nothing is being returned, this is a void function. It creates a doughnut chart using the
   * Chart.js library with data from the `this.data` object and displays it on the canvas element with
   * id "myChart". It also sets some options for the chart, such as the legend and tooltips.
   */
  private chartdoughnutAllPorcent() :void {
    if (this.myChart != undefined) this.myChart.destroy();
    let ctx: any;
    let context: any = document.getElementById("myChart");
    ctx = context.getContext("2d");

    this.myChart = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: ["Abiertas", "Ganado", "Perdido", "Toma de dec."],
        datasets: [
          {
            label: "Cotizaciones",
            data: [
              this.data.abiertas_porcent,
              this.data.ganadas_percent,
              this.data.perdidas_percent,
              this.data.toma_percent,
            ],
            backgroundColor: [
              "rgba(0,0,255, 0.2)",
              "rgba(0,255,0, 0.8)",
              "rgba(255,0,0, 0.8)",
              "rgba(0,0,255, 0.8)",
            ],
          },
        ],
      },
      options: {
        borderRadius: 25,
        borderWidth: 2,
        color: "black",
        display: function (context) {
          var dataset = context.dataset;
          var count = dataset.data.length;
          var value = dataset.data[context.dataIndex];
          return value > count * 1.5;
        },
        font: {
          weight: "bold",
        },
        padding: 6,
        formatter: Math.round,

        legend: {
          display: true,
        },
        tooltips: {
          enabled: true,
        },
      },
    });
  }

  /**
   * This function creates a line chart to display the sales representatives' percentages of total
   * sales.
   */
  private salesRepresentative() :void {
    if (this.chartRep != undefined) this.chartRep.destroy();

    let aux = this.data.reps;
    let porcentages = [];
    for (let i = 0; i < aux.length; i++) {
      let val = (aux[i] * 100) / this.data.total_cot;
      porcentages.push(val);
    }

    var ctx = document.getElementById("chart_reps_create");
    this.chartRep = new Chart(ctx, {
      type: "line",
      data: {
        labels: this.sales_rep_names,
        datasets: [
          {
            label: "Gauge",
            data: porcentages,
            backgroundColor: this.colores,
            fill: false,
            borderColor: "rgb(0, 0, 255)",
            tension: 0.1,
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        tooltips: {
          enabled: true,
        },
      },
    });
  }

  /**
   * This function creates a bar chart using Chart.js library to display the total sales of each sales
   * representative.
   */
  private salesRepresentativeLine() :void {
    if (this.chartRepLine != undefined) this.chartRepLine.destroy();

    let aux = this.data.total_rep;
    let sumatorias = [];
    aux.forEach((a) => {
      let sum = 0;
      a.forEach((b) => {
        sum += b.total;
      });
      sumatorias.push(sum.toFixed(3));
    });
    var ctx = document.getElementById("line_chart_reps_create");
    this.chartRepLine = new Chart(ctx, {
      type: "bar",
      data: {
        labels: this.sales_rep_names,
        datasets: [
          {
            label: "Gauge",
            data: sumatorias,
            backgroundColor: this.colores,
            fill: false,
            borderColor: "rgb(0, 0, 255)",
            tension: 0.1,
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        tooltips: {
          enabled: true,
        },
      },
    });
  }

  /**
   * This function returns the current month in a specific format for an API.
   * @returns a string representing the current month in API format.
   */
  private getMonthActual(): string {
    let month: any = this.currentDate.getMonth() + 1;
    let dateInApiFormat: string;

    if (month < 10) month = "0" + month.toString();

    dateInApiFormat = month;
    return dateInApiFormat;
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code, title, and
   * message using the Angular modal service.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - The title of the modal window that will be displayed.
   * @param message - The message parameter is likely a string that contains a message to be displayed
   * in the modal dialog box. It could be an error message, a confirmation message, or any other type
   * of message that the user needs to see.
   */
  private async lauchModal(code: string, title: string, message) :Promise<void>{
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }

  /*

  chartSpeedTotal(){
    var doc = (document.getElementById("chart-gauge-monto-porcent") as HTMLCanvasElement);
    var ctx = doc.getContext('2d');

    let cots = this.data.all_cot;
    let val_init=[], val_middle=[], val_end=[];

    cots.forEach(a => {
      if(parseFloat(a.total) > 1 && parseFloat(a.total) < 50001.00) val_init.push(a);
      if(parseFloat(a.total) > 50000.00 && parseFloat(a.total) <= 100000.00) val_middle.push(a);0
      if(parseFloat(a.total) > 100000.00) val_end.push(a);
    }); 

    let porcent_init = (val_init.length * 100) / cots.length;
    let porcent_middle = (val_middle.length * 100) / cots.length;
    let porcent_end = (val_end.length * 100) / cots.length;
            
    var chart = new Chart(ctx, {
        type:"doughnut",
        data: {
            labels : ['$1 a $50 mil','$50 mil a $100 mil', 'Más de $100 mil'],
            datasets: [{
              label: "Gauge",
              data : [porcent_init,porcent_middle,porcent_end],
              backgroundColor: ['red','yellow','green'],
              borderWidth: 1
            }]
        },
        options: {
          circumference: Math.PI,
          rotation : Math.PI,
          cutoutPercentage : 80, // precent
          legend: {
            display: true
          },
          tooltips: {
            enabled: true
          }
        }
    });
  }

  */
}
