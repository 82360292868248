import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { AdministrationComponent } from './administration.component';
import { CarteraComponent } from './cartera/cartera.component';
import { InvioceOdvComponent } from './cartera/invioce-odv/invioce-odv.component';
import { UpdateWalletComponent } from './cartera/update-wallet/update-wallet.component';
import { ProfitabilityComponent } from './profitability/profitability.component';
import { AddSaleOrderComponent } from './sale-orders/add-sale-order/add-sale-order.component';
import { DetailSaleOrderComponent } from './sale-orders/detail-sale-order/detail-sale-order.component';
import { EditSaleOrderComponent } from './sale-orders/edit-sale-order/edit-sale-order.component';
import { SaleOrdersComponent } from './sale-orders/sale-orders.component';
import { SendEmailOdcComponent } from './send-email-odc/send-email-odc.component';
import { AddSupplierComponent } from './suppliers/add-supplier/add-supplier.component';
import { DetailSupplierComponent } from './suppliers/detail-supplier/detail-supplier.component';
import { EditSupplierComponent } from './suppliers/edit-supplier/edit-supplier.component';
import { SendEmailComponent } from './suppliers/send-email/send-email.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { RequisitionsComponent } from "./requisitions/requisitions.component";
import { EditRequisitionsComponent } from './requisitions/edit-requisitions/edit-requisitions.component';
import { DetailRequisitionsComponent } from './requisitions/detail-requisitions/detail-requisitions.component';
import { IndicatorsWalletComponent } from './cartera/indicators-wallet/indicators-wallet.component';
import { InvoicesDateComponent } from './cartera/invoices-date/invoices-date.component';
import { VacationsComponent } from './vacations/vacations.component';
import { VacationsCalendarComponent } from './vacations/vacations-calendar/vacations-calendar.component';
import { VacationsRequestsComponent } from './vacations/vacations-requests/vacations-requests.component';
import { BrandsComponent } from './brands/brands.component';
import { DetailInvoiceComponent } from './cartera/update-wallet/detail-invoice/detail-invoice.component';
import { SendEmailWalletComponent } from './cartera/update-wallet/send-email-wallet/send-email-wallet.component';

const routes: Routes = [
  //Administracion
  { path: 'administration',
  children:[
    {
      path:'',
    component: AdministrationComponent},
    { path:'suppliers',
      children:[
        {path:'',component:SuppliersComponent},
        {path:'det/:id',component:DetailSupplierComponent},
        {path:'add',component:AddSupplierComponent},
        {path:'edit/:id',component:EditSupplierComponent},
        {path:'evaluate_supplier/:id',component:SendEmailComponent}
      ]
    },{ 
      path:'sale_order',
      children:[
        {path:'',component:SaleOrdersComponent},
        {path:'det/:id',component:DetailSaleOrderComponent},
        {path:'add',component:AddSaleOrderComponent},
        {path:'edit/:id',component:EditSaleOrderComponent},
        {path:'send_email/:id', component:SendEmailOdcComponent}
      ]
    },{
      path: 'profitability',
      component: ProfitabilityComponent
    },{
      path: 'wallet',
      children:[
        {path:'',component:CarteraComponent},
        {path:'update',component:UpdateWalletComponent},
        {path:'invioce_odv',component:InvioceOdvComponent},
        {path:'indicators',component:IndicatorsWalletComponent},
        {path:'invoice_pay',component:InvoicesDateComponent},
        {path:'detail_invoice/:id',children:[
          {path: '', component: DetailInvoiceComponent},
          {path:'sendmail_wallet', component: SendEmailWalletComponent}
        ]},
      ]
    },{
      path: 'requisitions',
      children:[
        {path:'',component:RequisitionsComponent},
        {path:'edit/:id',component:EditRequisitionsComponent},
        {path:'det/:id',component:DetailRequisitionsComponent},
      ]
    },{
      path: 'vacations',
      children:[
        {path:'',component:VacationsComponent},
        {path:'calendar',component:VacationsCalendarComponent},
        {path:'requests',component:VacationsRequestsComponent}
      ]
    },
    {
      path: 'brands',
      children:[
        {path:'',component: BrandsComponent},
      ]
    }
  ]
},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdministrationRoutingModule { }
