import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarComponent } from './calendar/calendar.component';
import { TraceabilityComponent } from './traceability/traceability.component';
import { DetailComponent } from './instruments/detail/detail.component';
import { InventoryComponent } from './instruments/inventory/inventory.component';
import { ReportComponent } from './instruments/report/report.component';
import { RouterModule } from '@angular/router';
import { ExpiredInstrumentsComponent } from './instruments/expired-instruments/expired-instruments.component';
import { ReportsListComponent } from './instruments/reports-list/reports-list.component';
import { EventListComponent } from './events/event-list/event-list.component';
import { EventHistoryComponent } from './events/event-history/event-history.component';
import { EventDetailComponent } from './events/event-detail/event-detail.component';


import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid'; 
import interactionPlugin from '@fullcalendar/interaction';
import { FormsModule } from '@angular/forms';
import { ExpiredDepartmentComponent } from './instruments/expired-department/expired-department.component';
import { InformesRechazadosComponent } from './events/event-list/informes-rechazados/informes-rechazados.component';
import { ShopComponent } from './shop/shop.component';
import { StockComponent } from './shop/stock/stock.component';
import { QuotesComponent } from './shop/quotes/quotes.component';
import { CartComponent } from './shop/cart/cart.component';
import { QuoteDetailComponent } from './shop/quotes/quote-detail/quote-detail.component';
import { QrComponent } from './qr/qr.component';
import { NgQrScannerModule } from 'angular2-qrscanner';
import { DriveComponent } from './drive/drive.component';
import { AccessRoutingModule } from './access-routing.module';
import { DirectoryComponent } from './drive/directory/directory.component';
import { ReportEventComponent } from './instruments/report-event/report-event.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { PermisoNotificationComponent } from './notifications/permiso-notification/permiso-notification.component';
import { CalendarMaintenanceComponent } from './calendar/calendar-maintenance/calendar-maintenance.component';
import { EventLabelsComponent } from './events/event-detail/event-labels/event-labels.component';
import { DisabledComponent } from './instruments/disabled/disabled.component';

FullCalendarModule.registerPlugins([ 
  dayGridPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [
    DetailComponent,
    InventoryComponent,
    CalendarComponent, 
    TraceabilityComponent, 
    ReportComponent, 
    ExpiredInstrumentsComponent, 
    ReportsListComponent, 
    EventListComponent, 
    EventHistoryComponent, 
    InformesRechazadosComponent,
    EventDetailComponent, 
    ExpiredDepartmentComponent, 
    ShopComponent, 
    StockComponent, 
    QuotesComponent, 
    CartComponent, 
    QuoteDetailComponent, 
    QrComponent, 
    DriveComponent, 
    DirectoryComponent, 
    NotificationsComponent,
    ReportEventComponent,
    PermisoNotificationComponent,
    CalendarMaintenanceComponent,
    EventLabelsComponent,
    DisabledComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FullCalendarModule,
    FormsModule,
    NgQrScannerModule,
    AccessRoutingModule
  ]
})
export class AccessModule { }
