import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { InstrumentService } from 'src/app/services/instrument.service';
import { environment } from 'src/app/variables/enviroment';

@Component({
  selector: 'app-expired-instruments',
  templateUrl: './expired-instruments.component.html',
  styleUrls: ['./expired-instruments.component.css']
})
export class ExpiredInstrumentsComponent implements OnInit {
  uri:string = environment.uri;
  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');

  results:any = [];
  number_page= 0;
  pages = 0;
  loader:number = 1;
  response:any = [];
  permmisions = JSON.parse(localStorage.getItem('permisos'));

  constructor(
    private activedRoute:ActivatedRoute, 
    private instument_service:InstrumentService,
    private modalService:NgbModal) { }

  ngOnInit(): void {
    this.peticion();
  }

  public peticion(){
    this.instument_service.getInstrumentExpired(0).subscribe(
      res=>{
        this.response = res;
        if(this.response.code != '0_005' )this.lauchModal(this.response.code,this.response.message)
        else {
          this.results = this.response.data;
          console.log(this.results);
          this.pages = this.results.length;
        }

        this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        console.log(err);
        this.loader = 2;
      }
    )
  }

  generarReporte(){
    this.loader=1
    let respuesta:any = [];
    this.instument_service.getInstrumentExpired(1).subscribe(
      res=>{
        respuesta=res;
        if(respuesta.code != '0_005' )this.lauchModal(respuesta.code,respuesta.message)
        else {
          this.downloadFile(respuesta.data);
        }
        this.loader = 2;
      },
      err=>{
        this.lauchModal('0000x00','Error de consulta');
        console.log(err);
        this.loader = 2;
      }
    )
  }

  downloadFile(file_name){
    var url = environment.uri_server+'storage/public/xlsx_reportes/generados/'+file_name;
    window.open(url, '_blank');
  }

  changePage(page){this.number_page = page;}

  lauchModal(code:string,title:string){
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

}
