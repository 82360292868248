import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reportmaker',
  templateUrl: './reportmaker.component.html',
  styleUrls: ['./reportmaker.component.css']
})
export class ReportmakerComponent implements OnInit {
  permmisions = JSON.parse(localStorage.getItem('permisos'));
  constructor() { }

  ngOnInit(): void {
  }

}
