import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { INews } from "src/app/services/models/INews";
import { NewsService } from "src/app/services/news.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-add-new",
  templateUrl: "./add-new.component.html",
  styleUrls: ["./add-new.component.css"],
})
export class AddNewComponent implements OnInit {
  loader = 0;
  titulo: string = "";
  mensaje: string = "";
  pie: string = "";
  users: string = "";
  userarray: string[] = [];
  result: any = [];
  userList: any = [];

  constructor(
    private activedRoute: ActivatedRoute,
    private router: Router,
    private newServ: NewsService,
    private modalService: NgbModal,
    private userSer: UserService
  /**
   * This is a private function that retrieves news data from a server and handles the response
   * accordingly.
   */
  ) {}

  ngOnInit(): void {
    this.getUsers();
  }

  /**
   * This function retrieves a list of users from a service and assigns it to a variable.
   */
  private getUsers() :void {
    this.newServ.getUsersList().subscribe(
      (res) => {
        this.userList = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }

 /**
  * This function posts a new news item to a server using data from the component's properties.
  */
 public postNew() :void {
    this.pasarAStringArray();
    let iAviso: INews = {
      title: this.titulo,
      message: this.mensaje,
      footer: this.pie,
      for_users: this.users,
    };

    this.newServ.postNew(iAviso).subscribe(
      (res) => {
        console.log(res);
        this.result = res;
        this.router.navigate(["programmers/news"]);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /**
   * This function converts an array of user objects to a comma-separated string.
   */
  private pasarAStringArray() :void {
    this.userarray.forEach((element) => {
      if (this.users == "") {
        this.users = element;
      } else {
        this.users = this.users + "," + element;
      }
    });
  }

 /**
  * This function adds or removes a user from an array based on whether or not they already exist in
  * the array.
  * @param iduser - a parameter representing the ID of a user that needs to be added or removed from an
  * array of users.
  */
 public addUser(iduser) :void {
    if (this.userarray.length > 0) {
      let contador: number;
      let bandera: boolean = false;
      let posicioneliminar: number;
      for (contador = 0; contador < this.userarray.length; contador++) {
        if (this.userarray[contador] == iduser) {
          bandera = true;
          posicioneliminar = contador;
        }
      }

      if (!bandera) {
        this.userarray.push(iduser);
      } else {
        this.userarray.splice(posicioneliminar, 1);
      }
    } else {
      this.userarray.push(iduser);
    }
  }
}
