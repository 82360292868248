import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-edit-deptos",
  templateUrl: "./edit-deptos.component.html",
  styleUrls: ["./edit-deptos.component.css"],
})
export class EditDeptosComponent implements OnInit {
  result: any = [];
  id_user: string = "";

  constructor(
    private activedRoute: ActivatedRoute,
    private userService: UserService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.id_user = this.activedRoute.snapshot.paramMap.get("id_user");
    this.getDeptosByUser();
  }

  /**
   * This function retrieves department information for a user using a service call in TypeScript.
   */
  private getDeptosByUser(): void {
    this.userService.getDeptosUser(this.id_user).subscribe(
      (res) => {
        this.result = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code and title using a
   * modal service.
   * @param {string} code - A string representing the code that will be displayed in the modal window.
   * @param {string} title - The title parameter is a string that represents the title of the modal
   * that will be launched.
   */
  private async lauchModal(code: string, title: string): Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

  /**
   * The function assigns a department to a user through a service call and then retrieves the updated
   * list of departments for the user.
   * @param id_depto - The parameter `id_depto` is a variable that represents the ID of a department
   * that is being assigned to a user.
   */
  private asignarDepto(id_depto): void {
    this.userService.setDeptoUser(this.id_user, id_depto).subscribe(
      (res) => {
        this.getDeptosByUser();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /**
   * This function removes a department from a user's list of departments.
   * @param id_depto - The parameter `id_depto` is the ID of a department that is being removed from a
   * user's list of departments.
   */
  private quitarDepto(id_depto): void {
    this.userService.quitDeptoUser(this.id_user, id_depto).subscribe(
      (res) => {
        this.getDeptosByUser();
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
