import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-users-inactive",
  templateUrl: "./users-inactive.component.html",
  styleUrls: ["./users-inactive.component.css"],
})
export class UsersInactiveComponent implements OnInit {
  result: any = [];
  permmisions = JSON.parse(localStorage.getItem("permisos"));

  constructor(
    private userService: UserService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.getUsers();
  }

  /**
   * This function retrieves all inactive users with an ID of 150 using a userService and stores the
   * result in a variable called "result".
   */
  private getUsers() :void {
    this.userService.getAllUsersInactive("150").subscribe(
      (res) => {
        this.result = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }

 /**
  * This function launches a modal with a given code and title using the Angular Material modal
  * service.
  * @param {string} code - A string parameter that represents the code to be displayed in the modal
  * window.
  * @param {string} title - The title parameter is a string that represents the title of the modal that
  * will be launched. It is used to set the title property of the ModalComponent instance that is
  * created.
  */
  private async lauchModal(code: string, title: string) :Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }
}
