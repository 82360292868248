import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ScrollResponder } from "@fullcalendar/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { SuppliersService } from "src/app/services/suppliers.service";
import { ISupplierAdd } from "src/app/services/models/ISupplierAdd";
@Component({
  selector: "app-add-supplier",
  templateUrl: "./add-supplier.component.html",
  styleUrls: ["./add-supplier.component.css"],
})
export class AddSupplierComponent implements OnInit {
  loader = 1;
  response: any = [];

  //Datos generales
  supplier_name = "";
  supplier_service = "";
  supplier_accreditation = "";
  supplier_rfc = "";
  supplier_account = "";
  supplier_coin = "1";
  supplier_web_page = "N/A";
  suppplier_invoice = "N/A";
  supplier_ptype = "";
  coin_type: any = [
    { desc: "MXN", id: "1" },
    { desc: "USD", id: "2" },
  ];
  pay_tipes: any = [];

  //Datos para agregar contacto
  contact_name = "";
  contact_email = "";
  contact_phone = "";
  contact_ext = "N/A";
  contact_puesto = "";

  //Datos para agregar direcciones
  address_street = "";
  address_ext = "";
  address_int = "N/A";
  address_colony = "";
  address_cp = "";
  address_mun = "";
  address_city = "";
  address_state = "";
  address_country = 1;
  country_list_: any = [
    { desc: "MX", id: "1" },
    { desc: "US", id: "2" },
  ];
  constructor(
    private suppliersService: SuppliersService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  /**
   * The function loads data from a supplier service and handles the response accordingly.
   */
  private loadData() :void {
    this.suppliersService.getDataToAddSupplier().subscribe(
      (res) => {
        this.response = res;
        if (this.response.code != "0_005")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.pay_tipes = this.response.data;
        }
        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", err.message);
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * This function saves supplier data and displays a success or error message.
   */
  public saveSupplier() :void {
    this.loader = 1;
    var data: ISupplierAdd = {
      s_name: this.supplier_name,
      s_service_product: this.supplier_service,
      s_acredditation: this.supplier_accreditation,
      s_rfc: this.supplier_rfc,
      s_account: this.supplier_account,
      s_pay: Number(this.supplier_ptype),
      s_coin: Number(this.supplier_coin),
      s_web_page: this.supplier_web_page,
      s_invoice: this.suppplier_invoice,

      c_name: this.contact_name,
      c_email: this.contact_email,
      c_stall: this.contact_puesto,
      c_phone: this.contact_phone,
      c_ext: this.contact_ext,

      a_calle: this.address_street,
      a_ext: this.address_ext,
      a_int: this.address_int,
      a_col: this.address_colony,
      a_cp: this.address_cp,
      a_mun: this.address_mun,
      a_ciu: this.address_city,
      a_est: this.address_state,
      a_pais: Number(this.address_country),
    };
    this.suppliersService.saveSupplier(data).subscribe(
      (res) => {
        this.loader = 0;
        this.response = res;
        if (this.response.code != "0_005")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.router.navigate([
            "/administration/suppliers/det",
            this.response.data,
          ]);
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", err.message);
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * This is a private function in TypeScript that sets the value of a coin for a supplier.
   * @param value - The value parameter is the new value that will be set for the supplier_coin
   * property.
   */
  public setCoin(value) :void {
    this.supplier_coin = value;
  }

  /**
   * This is a private function that sets the value of a property called "supplier_ptype".
   * @param value - The value parameter is the new value that will be assigned to the supplier_ptype
   * property.
   */
  public setPay(value) :void {
    this.supplier_ptype = value;
  }

  /**
   * This is a private function in TypeScript that sets the value of the address_country property.
   * @param value - The value parameter is the new value that will be set for the address_country
   * property.
   */
  public setCountry(value) :void {
    this.address_country = value;
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code, title, and
   * message using the ModalComponent.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - The title of the modal that will be displayed.
   * @param message - The message parameter is a string that represents the message to be displayed in
   * the modal dialog.
   */
  private async lauchModal(code: string, title: string, message) :Promise<void>{
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
}
