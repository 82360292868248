import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalPdfComponent } from "src/app/components/modal-pdf/modal-pdf.component";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { InstrumentService } from "src/app/services/instrument.service";
import { IBrand } from "src/app/services/models/IBrand";
import { OrdenVentaService } from "src/app/services/orden-venta.service";
import { QuotationService } from "src/app/services/quotation.service";
import { environment } from "src/app/variables/enviroment";

@Component({
  selector: "app-cot-det",
  templateUrl: "./cot-det.component.html",
  styleUrls: ["./cot-det.component.scss"],
})
export class CotDetComponent implements OnInit {
  uri: string = environment.uri;
  token = localStorage.getItem("token");
  api_key = localStorage.getItem("key");
  permmisions = JSON.parse(localStorage.getItem("permisos"));
  id_cot = "";
  loader = 2;
  data: any = [];
  total_price = 0;
  subtotal_p = 0;
  iva_p = 0;
  subtotal_c = 0;
  iva_c = 0;
  total_price_p = 0;

  total_price_c = 0;

  //Partidas
  parts: any = [];

  //Partidas manuales
  mp_tag = "";
  mp_marca = "Buscar y asignar";
  mp_id_marca = "";
  mp_modelo = "";
  mp_serie = "";
  mp_descripcion = "";
  mp_frecuencia = 6;
  mp_precio = "";
  mp_notas = "N/A";
  mp_cantidad = "";
  

  //Marcas
  public brand_list: IBrand[] = [];
  public new_brand: IBrand = {
    brand: "",
    notes: "N/A"
   }
  //Buscador Marcas
  public brand_selected:IBrand = {id:'',brand:''};
  public add_brand:boolean = false;
  public search_brand: boolean = false;
  public brand_search: string = '';
  //Edicion de partidas
  edit_tag = "";
  edit_desc = "";
  edit_model = "";
  public edit_brand:string = "";
  public edit_brand_id:string = "";
  edit_serie = "";
  edit_frec = "";
  edit_price = "";
  edit_quant = "";
  edit_notes = "";
  id_edit = "";
  public editingPart:number = 0;

  //Comments area
  comment = "";
  comment_list: any = [];

  //Email area
  email = "";
  email_manual = "";
  email_list = [];
  subject = "";
  body = "";

  //Contacts area
  public contacts_list: any = [];
  public contacts_list_1: any = [];
  public ventas_correos: any = [];
  public contact_type:number = 0;

  public id_c:string = "";
  public name_c:string = "";
  public last_name_c:string = "";
  public email_c:string = "";
  public phone_c:string = "";
  public is_temp_contact:boolean = false;
  public edit_contact:boolean = false;
  public saved_contact_action:boolean = false;
  public bg_clicked: string = '#FFFFFF';

  //Upload XLSX
  file_name = "";
  file: any;

  //Generar odv desde cotizacion
  id_social_reason = 1;
  social_reason_list = [
    { id: 1, razon_social: "ETALONS S.A. DE C.V." },
    { id: 2, razon_social: "ETALONET S.A. DE C.V." },
    { id: 3, razon_social: "UCAL MED S.A DE C.V." },
  ];
  identifier_add_odv = "";

  //Eliminar partidas de cotizacion
  id_part_delete = "";
  tag_to_delete = "";

  //Partidas copiadas para agregar a ODV
  copied_parts: any = [];

  //Orden de Venta
  odv_searched = "";
  odv_result_list: any = [];

  //Partidas de conceptos adicionales
  ac_concept = "";
  ac_price = "0.00";
  ac_quantity = "1";
  aditional_concepts: any = [];

  //PDF
  url_to_load = "";
  chunks_per_page: number = 7;

  //clientes
  client_to_search = "";
  clients_result: any = [];
  client_id = "";
  cliente_null = 0;

  total_con_desc = 0;
  iva_total = 0;

  doc_name:string= null;
  url_almacenamiento:string="storage/public/cotizaciones/pdf/";

  constructor(
    private activedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private quotationServ: QuotationService,
    private instument_service: InstrumentService,
    private odvService: OrdenVentaService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.id_cot = this.activedRoute.snapshot.paramMap.get("id_cot");
    this.getData();
  }
  /**
   * The function retrieves quotation details from a server and assigns the data to various variables
   * for use in the application.
   */
  private getData(): void {
    this.loader = 1;

    this.quotationServ.getQuotationDetail(this.id_cot).subscribe(
      (res) => {
        let response: any = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004")
          this.lauchModal(response.code, response.message, "");
        else {
          this.data = response.data;
          this.cliente_null = this.data.cliente;
          this.subject =
          this.data.razon_social + ", Cotización " + this.data.identifier;
          this.comment_list = this.data.comments;
          this.parts = this.data.parts;
          this.aditional_concepts = this.data.aditional_concepts;

          let contacts_list_ = this.data.client_users;
          this.contacts_list = contacts_list_;
          if(this.contacts_list.length > 0)
          this.contacts_list_1 = this.addCheckStatus(this.contacts_list);
          if(this.data.emails.length > 0)
          this.ventas_correos = this.addCheckStatus(this.data.emails);

          let sub = 0;
          this.parts.forEach((v) => {
            sub += v.precio * v.cantidad;
          });

          let sub_c = 0;
          this.aditional_concepts.forEach((v) => {
            sub += parseFloat(v.precio) * v.cantidad;
            sub_c += parseFloat(v.precio) * v.cantidad;
          });

          this.subtotal_c = sub_c;

          this.subtotal_p = sub;
          this.total_con_desc = sub - this.data.descontado;
          this.iva_total = this.total_con_desc * 0.16;
          this.total_price = this.total_con_desc + this.iva_total;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * The function adds a comment to a quotation and handles the response accordingly.
   * @param val - The parameter "val" is a variable that represents the status of the comment being
   * added. It is used to set the value of the "status" field in the FormData object that is sent to
   * the server.
   */
  public addComment(val): void {
    this.loader = 1;

    let data = new FormData();
    data.append("comment", this.comment);
    data.append("quotation", this.id_cot);
    data.append("status", val);

    this.quotationServ.saveComment(data).subscribe(
      (res) => {
        let response: any = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004")
          this.lauchModal(response.code, response.message, "");
        else {
          if (response.data == 0)
            this.lauchModal("0000x00", "Error de consulta", "");
          else {
            this.comment = "";
            this.getData();
          }
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }
   
  public addCheckStatus(list): any[] {
    list.forEach(element => {
      element.checked = false;
    });
    return list;
  }

  /**
   * The function adds or removes an email from an email list.
   * @param email_usr - The email address that is being added or removed from the email list.
   */
  public addEmail(email_usr): void {
    const contacto_externo = this.contacts_list_1.find(objeto => objeto.correo === email_usr);
    const contacto_interno = this.ventas_correos.find(objeto => objeto.correo === email_usr);
    if (!this.email_list.includes(email_usr)) {
      this.email_list.push(email_usr);
      if(contacto_externo) contacto_externo.checked = true;
      if(contacto_interno) contacto_interno.checked = true;
    } else {
      this.email_list.splice(this.email_list.indexOf(email_usr), 1);
      if(contacto_externo) contacto_externo.checked = false;
      if(contacto_interno) contacto_interno.checked = false;
    }
  }
 
  /**
   * This function sends an email with a quotation, subject, body, and list of emails using a FormData
   * object and a service, and displays a modal with a response message.
   */
  public sendEmail(): void {
    this.loader = 1;
    this.email = this.email_list.toString();
    this.email = this.email + "," + this.email_manual;
    let data = new FormData();
    data.append("quotation", this.id_cot);
    data.append("subject", this.subject);
    data.append("body", this.body);
    data.append("emails", this.email);

    this.quotationServ.sendEmail(data).subscribe(
      (res) => {
        let response: any = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004")
          this.lauchModal(response.code, response.message, "");
        else {
          this.lauchModal(response.code, response.message, response.data);
          this.getData();
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * This function loads a file and sets its name as a property.
   * @param value - The parameter "value" is an event object that is passed as an argument to the
   * function. It contains information about the event that triggered the function, such as the target
   * element that was interacted with. In this case, it is likely an event object related to a file
   * input element, as the
   */
  public loadFile(value): void {
    this.file = value.target;
    this.file_name = this.file.files[0].name;
  }

  //Enviar XLSX a servidor
  /**
   * This function loads an XLSX file to the server and handles the response accordingly.
   */
  public loadXLSXToServer(): void {
    this.loader = 1;
    let data = new FormData();
    data.append("file", this.file.files[0]);
    data.append("cot_id", this.id_cot);

    this.quotationServ.loadPartsByXLSX(data).subscribe(
      (res) => {
        let response: any = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004")
          this.lauchModal(response.code, response.message, "");
        else {
          this.getData();
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * This function saves a part to a quotation and displays a modal with the response.
   */
  public savePart(): void {
    this.loader = 1;

    let data = new FormData();
    data.append("tag", this.mp_tag);
    data.append("id_brand", this.mp_id_marca);
    data.append("model", this.mp_modelo);
    data.append("serie", this.mp_serie);
    data.append("des", this.mp_descripcion);
    data.append("frec", this.mp_frecuencia.toString());
    data.append("price", this.mp_precio);
    data.append("notes", this.mp_notas);
    data.append("quant", this.mp_cantidad);
    data.append("id_cot", this.id_cot);

    this.quotationServ.addPartToQuotation(data).subscribe(
      (res) => {
        let response: any = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004" || response.code == "0_0007")
          this.lauchModal(response.code, response.message, "");
        else {
          this.mp_tag = "";
          this.mp_marca = "Buscar y asignar";
          this.mp_id_marca = "";
          this.mp_modelo = "";
          this.mp_serie = "";
          this.mp_descripcion = "";
          this.mp_frecuencia = 6;
          this.mp_precio = "";
          this.mp_cantidad = "";
          this.brand_list = [];
          this.getData();
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * This function saves contact information to a client using a form data object and handles different
   * response codes.
   */
  public saveContactToClient():void {
    this.loader = 1;
    let form = new FormData();
    form.append("id_cot", this.id_cot);
    form.append("name", this.name_c);
    form.append("last_name", this.last_name_c);
    form.append("email", this.email_c);
    form.append("phone", this.phone_c);
    form.append("is_new_contact", `${this.edit_contact}`);
    form.append("id_contact", this.id_c);
    this.quotationServ.addContactToClient(form).subscribe(
      (res) => {
        let response: any = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004" || response.code == "0_0007")
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004" || response.code == "0_008")
          this.lauchModal(response.code, response.message, "");
        else {
          this.getData();
          this.vaciarContactFields(true);
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * This function sets a contact to a quotation and handles different response codes.
   * @param id - The parameter "id" is the ID of the contact that needs to be set to a quotation.
   */
  public setContactToQuotation(id): void {
    this.loader = 1;
    this.quotationServ.setContactToQuotation(this.id_cot, id).subscribe(
      (res) => {
        let response: any = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004" || response.code == "0_0007")
          this.lauchModal(response.code, response.message, "");
        else {
          this.getData();
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * This function deletes a part and displays a modal with a message depending on the result.
   */
  public deleteParts(): void {
    this.loader = 1;

    this.quotationServ.deletePart(this.id_part_delete).subscribe(
      (res) => {
        let data: any = res;
        if (
          data.code == "1_0001" ||
          data.code == "1_0002" ||
          data.code == "1_0003"
        )
          this.lauchModal(data.code, data.message, "");
        else if (data.code == "1_0004")
          this.lauchModal(data.code, data.message, "");
        else this.getData();
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * This function loads data from a specific index of an array into corresponding variables.
   * @param index - The index parameter is an integer that represents the position of the element in
   * the parts array that needs to be loaded.
   */
  public loadDataFromPart(index): void {
    this.id_edit = this.parts[index].det_id;
    this.edit_tag = this.parts[index].tag;
    this.edit_desc = this.parts[index].descripcion;
    this.edit_model = this.parts[index].modelo;
    this.edit_serie = this.parts[index].serie;
    this.edit_frec = this.parts[index].frecuencia;
    this.edit_price = this.parts[index].precio;
    this.edit_notes = this.parts[index].notas;
    this.edit_quant = this.parts[index].cantidad;
    this.edit_brand = this.parts[index].marca;
    this.edit_brand_id = this.parts[index].id_marca;
    this.editingPart = 1;
  }

  /**
   * This function edits a part and updates it in the database.
   */
  public editPart(): void {
    this.loader = 1;

    let data = new FormData();
    data.append("id_part", this.id_edit);
    data.append("tag", this.edit_tag);
    data.append("desc", this.edit_desc);
    data.append("model", this.edit_model);
    data.append("serie", this.edit_serie);
    data.append("frec", this.edit_frec);
    data.append("price", this.edit_price);
    data.append("notes", this.edit_notes);
    data.append("quant", this.edit_quant);
    data.append("brand", this.edit_brand_id);

    this.quotationServ.editPart(data).subscribe(
      (res) => {
        let data: any = res;
        if (
          data.code == "1_0001" ||
          data.code == "1_0002" ||
          data.code == "1_0003"
        )
          this.lauchModal(data.code, data.message, "");
        else if (data.code == "1_0004")
          this.lauchModal(data.code, data.message, "");
        else this.getData();
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * This function creates a temporal ODV (Order Delivery Voucher) from a quotation using data from a
   * form and sends a request to the server.
   */
  public createTemporalODVFromQUotation(): void {
    let data = new FormData();
    data.append("cot_id", this.id_cot);
    data.append("identifier", this.identifier_add_odv);
    data.append("social_reason", this.id_social_reason.toString());

    this.quotationServ.createTemporalODV(data).subscribe(
      (res) => {
        let data: any = res;
        if (
          data.code == "1_0001" ||
          data.code == "1_0002" ||
          data.code == "1_0003"
        )
          this.lauchModal(data.code, data.message, "");
        else if (data.code == "1_0004")
          this.lauchModal(data.code, data.message, "");
        else {
          this.router.navigate(["/commercial/odv/det/" + data.data]);
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * The function copies a part from an array to another array if it doesn't already exist in the
   * second array.
   * @param index - The index parameter is an integer representing the index of an element in an array.
   * In this case, it is used to access a specific element in the "parts" array.
   */
  public copyParts(index): void {
    let det_id = this.parts[index].det_id;

    let flag = false;
    for (let i = 0; i < this.copied_parts.length; i++) {
      if (this.copied_parts[i].det_id == det_id) flag = true;
    }

    if (!flag) this.copied_parts.push(this.parts[index]);
  }

  /**
   * This is a private function in TypeScript that deletes a specific element from an array called
   * "copied_parts".
   * @param index - The index parameter is an integer that represents the index of the element to be
   * removed from the copied_parts array. The splice method is used to remove the element at the
   * specified index.
   */
  public deleteCopiedParts(index): void {
    this.copied_parts.splice(index, 1);
  }

  /**
   * This function searches for an ODV to add to a quotation and displays the results in a modal.
   */
  public searchODVToAddQuotation(): void {
    this.quotationServ.searchODVToAddQuotation(this.odv_searched).subscribe(
      (res) => {
        let data: any = res;
        if (
          data.code == "1_0001" ||
          data.code == "1_0002" ||
          data.code == "1_0003"
        )
          this.lauchModal(data.code, data.message, "");
        else if (data.code == "1_0004")
          this.lauchModal(data.code, data.message, "");
        else {
          this.odv_result_list = data.data;
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", err.me);
        this.loader = 2;
      }
    );
  }

  /**
   * This function adds copied parts to an ODV (Order Delivery Voucher) from a quotation.
   * @param odv_id - The ID of the ODV (Order Delivery Vehicle) to which the copied parts will be
   * added.
   * @param odv_identifier - The identifier of the ODV (Order Delivery Voucher) to which the copied
   * parts are being added.
   */
  public addCopiedPartsToODV(odv_id, odv_identifier): void {
    let data = new FormData();
    data.append("odv_id", odv_id);

    let ids = "";
    for (let i = 0; i < this.copied_parts.length; i++) {
      if (i == this.copied_parts.length - 1) ids += this.copied_parts[i].det_id;
      else ids += this.copied_parts[i].det_id + "_";
    }
    data.append("parts", ids);
    data.append("cot_id", this.id_cot);

    this.quotationServ.addPartsToODVFromQuotation(data).subscribe(
      (res) => {
        let data: any = res;

        if (
          data.code == "1_0001" ||
          data.code == "1_0002" ||
          data.code == "1_0003"
        )
          this.lauchModal(data.code, data.message, "");
        else if (data.code == "1_0004")
          this.lauchModal(data.code, data.message, "");
        else {
          this.lauchModal(
            "1",
            "Opereción exitosa",
            "Se han agregado " +
              this.copied_parts.length +
              " partidas a la ODV " +
              odv_identifier
          );
          this.getData();
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * This function adds an additional concept to a quotation and displays a success or error message.
   */
  public addAditionalConcept(): void {
    let data = new FormData();
    data.append("cot_id", this.id_cot);
    data.append("concept", this.ac_concept);
    data.append("quantity", this.ac_quantity);
    data.append("price", this.ac_price);
    this.loader = 1;
    this.quotationServ.addAditionalConcepts(data).subscribe(
      (res) => {
        let data: any = res;
        this.loader = 2;
        if (
          data.code == "1_0001" ||
          data.code == "1_0002" ||
          data.code == "1_0003"
        )
          this.lauchModal(data.code, data.message, "");
        else if (data.code == "1_0004")
          this.lauchModal(data.code, data.message, "");
        else {
          this.lauchModal(
            "1",
            "Opereción exitosa",
            "Se ha agregado el concepto a la cotización."
          );
          this.getData();
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * This function deletes an additional concept from a quotation and displays a success or error
   * message.
   * @param index - The index of the additional concept to be deleted from the array of additional
   * concepts.
   */
  public deleteAditionalConcept(index): void {
    this.loader = 1;

    this.quotationServ
      .deleteAditionalConcepts(this.aditional_concepts[index].id)
      .subscribe(
        (res) => {
          let data: any = res;
          this.loader = 2;
          if (
            data.code == "1_0001" ||
            data.code == "1_0002" ||
            data.code == "1_0003"
          )
            this.lauchModal(data.code, data.message, "");
          else if (data.code == "1_0004")
            this.lauchModal(data.code, data.message, "");
          else {
            this.lauchModal(
              "1",
              "Opereción exitosa",
              "Se ha eliminado el concepto a la cotización."
            );
            this.getData();
          }
        },
        (err) => {
          this.lauchModal("0000x00", "Error de consulta", "");
          this.loader = 2;
        }
      );
  }

  /**
   * This function downloads a PDF file and handles different responses based on the result.
   */
  public descargarPdf(): void {
    this.loader=1;
    this.quotationServ.downloadPdf(this.id_cot, this.chunks_per_page).subscribe(
      (res) => {
        let data: any = res;
        this.loader = 2;
        if (
          data.code == "1_0001" ||
          data.code == "1_0002" ||
          data.code == "1_0003"
        )
          this.lauchModal(data.code, data.message, "");
        else if (data.code == "1_0004")
          this.lauchModal(data.code, data.message, "");
        else {
          this.doc_name = data.data;
          //this.downloadFile(data.data);
          this.loader = 2;
          //this.lauchModalPdf(data.data);

        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  private async lauchModalPdf(name): Promise<void> {
    const modalRef = this.modalService.open(ModalPdfComponent);
    modalRef.componentInstance.url_almacenamiento = this.url_almacenamiento;
    modalRef.componentInstance.doc_name = name;
  }

  
  docURL() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(environment.uri_server+"storage/public/cotizaciones/pdf/" + this.doc_name);
  }
  
  /**
   * This function downloads a file by opening its URL in a new window.
   * @param file_name - The parameter "file_name" is a string that represents the name of the file that
   * needs to be downloaded.
   */
  private downloadFile(doc_name): void {
    var url =
      environment.uri_server + "storage/public/cotizaciones/pdf/" + this.doc_name;
    window.open(url, "_blank");
  }

  /**
   * This function clones a quotation and generates a new quotation from a copy.
   */
  public cloneQuotation(): void {
    this.loader = 1;
    this.quotationServ
      .generateQuotationFromCopy(this.id_cot, this.client_id)
      .subscribe(
        (res) => {
          let response: any = res;
          this.router.navigateByUrl("commercial/cot/edit/" + response.data + "/0");

          this.loader = 2;
        },
        (err) => {
          this.lauchModal("0000x00", "Error de consulta", "");
          this.loader = 2;
        }
      );
  }

  //busqueda de cliente
  /**
   * This function searches for a client and displays the results or an error message.
   */
  public searchClient(): void {
    this.loader = 1;
    this.odvService.getClientSearch(this.client_to_search).subscribe(
      (res) => {
        this.clients_result = res;
        if (
          this.clients_result.code == "1_0001" ||
          this.clients_result.code == "1_0002" ||
          this.clients_result.code == "1_0003"
        )
          this.lauchModal(
            this.clients_result.code,
            this.clients_result.message,
            ""
          );
        else if (this.clients_result.code == "1_0004")
          this.lauchModal(
            this.clients_result.code,
            this.clients_result.message,
            ""
          );
        else this.clients_result = this.clients_result.data;

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  //Asignacion de cliente
  /**
   * This is a private function in TypeScript that sets the client ID.
   * @param id - The "id" parameter is a value that represents the client ID that will be set for the
   * current object instance. The method "setClient" is a private method that sets the client ID for
   * the object instance.
   */
  public setClient(id): void {
    this.client_id = id;
  }

  //Descarga formato de revisión de equipos
  /**
   * The function sets a URL to download a specific format of instruments for a given quotation.
   */
  public descargarFormato(): void {
    this.url_to_load =
      this.uri +
      "download_format_rev_instruments?api_key=" +
      this.api_key +
      "&token=" +
      this.token +
      "&module=180&cot=" +
      this.id_cot;
  }

  //Marcas

  /**
   * The function assigns a selected brand to either the "mp_marca" and "mp_id_marca" variables or the
   * "edit_brand" and "edit_brand_id" variables based on the value of the "assign_to" parameter.
   * @param assign_to - The parameter "assign_to" is used to determine whether the brand should be
   * assigned to a new variable or to an existing variable. If "assign_to" is equal to 0, the brand
   * will be assigned to the variables "mp_marca" and "mp_id_marca". If "assign
   */
  public assingSearchedBrand(assign_to):void {
    if(assign_to == 0) {
      this.mp_marca = this.brand_selected.brand;
      this.mp_id_marca = this.brand_selected.id;
    } else {
      this.edit_brand = this.brand_selected.brand;
      this.edit_brand_id = this.brand_selected.id;
    }
    
  }

  /**
   * The function "resetBrandSearcher" resets the brand search functionality by clearing the selected
   * brand, brand list, search status, and search query.
   */
  public resetBrandSearcher():void {
    this.brand_selected.id = '';
    this.brand_selected.brand = '';
    this.brand_list = [];
    this.search_brand = false;
    this.brand_search= '';
  }
  /**
   * This function searches for a brand based on user input and displays the results or an error
   * message.
   * @param data - The parameter "data" is an object that contains information about the event that
   * triggered the function. It is likely an instance of the Event class or a subclass of it. The
   * function is using the "target" property of this object to get the value of the input field that
   * triggered the event.
   */
  public searchBrand(data): void {
    this.loader = 1;
    this.instument_service.searchBrand(data).subscribe(
      (res) => {
        let data: any = res;
        if (
          data.code == "1_0001" ||
          data.code == "1_0002" ||
          data.code == "1_0003"
        )
          this.lauchModal(data.code, data.message, "");
        else if (data.code == "1_0004")
          this.lauchModal(data.code, data.message, "");
        else {
          this.brand_list = data.data;
          
        }
        this.search_brand = true;
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }
  /**
   * The function "agregarMarca" saves a new brand register and displays a modal with the result.
   */
  public agregarMarca():void {
    this.loader = 1;
    this.new_brand.isNew = true;
    this.instument_service.saveNewBrandRegister(this.new_brand).subscribe(
      (res) => {
        let data: any = res;
        this.loader = 2;
        if (
          data.code == "1_0001" ||
          data.code == "1_0002" ||
          data.code == "1_0003"
        )
          this.lauchModal(data.code, data.message, "");
        else if (data.code == "1_0004")
          this.lauchModal(data.code, data.message, "");
        else {
          this.lauchModal(
            "1",
            "Operación exitosa",
            `Se ha creado la marca ${this.new_brand.brand} correctamente.`
          );
          this.puedeAgregarMarca();
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

 /**
  * The function "puedeAgregarMarca" toggles the "add_brand" property and updates other related
  * properties accordingly.
  */
  public puedeAgregarMarca(): void {
    if(this.add_brand === false) {
      this.add_brand = true;
      this.new_brand.brand = this.brand_search;
    } else {
      this.add_brand = false; 
      this.brand_list = []; 
      this.brand_search = ''; 
      this.search_brand = false; 
      this.new_brand.notes = 'N/A'
    }
  }

  /**
   * The function "editarContacto" is used to edit a contact's information and update the form fields
   * with the contact's details.
   * @param contacto - The parameter "contacto" is an object that represents a contact. It has the
   * following properties:
   */
  public editarContacto(contacto): void {
    this.edit_contact = true;
    this.id_c = contacto.id;
    this.name_c = contacto.nombre;
    this.last_name_c = contacto.apellido;
    this.email_c = contacto.correo;
    this.phone_c = contacto.telefono;
    if(contacto.temporal == 0) this.is_temp_contact = true;
    else this.is_temp_contact = false;

    //cambiar color y mover scroll hacia el formulario
    this.bg_clicked = 'rgba(194, 241, 241, 0.2)';
    document.querySelector('#editClientContactData').scrollIntoView({
      behavior: 'smooth'
    });
    setTimeout(() => {
      this.bg_clicked = '#FFFFFF'; // Cambiar de nuevo a gris
    }, 2000);
  }

  /**
   * The function vaciarContactFields clears the contact fields and sets some variables to their
   * default values.
   * @param saved - The "saved" parameter is a boolean value that indicates whether the contact has
   * been saved or not.
   */
  public vaciarContactFields(saved): void {
    if(saved) this.saved_contact_action = true;

    this.id_c = '';
    this.name_c = '';
    this.last_name_c = '';
    this.email_c = '';
    this.phone_c = '';
    this.is_temp_contact = false;
    this.edit_contact = false;

  }

  /**
   * This function launches a modal with a given code, title, and message using the Angular Material
   * modal service.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - The title of the modal that will be displayed.
   * @param message - The message parameter is likely a string that contains the content of the message
   * to be displayed in the modal. It could be an error message, a confirmation message, or any other
   * type of message that the user needs to see.
   */
  private async lauchModal(
    code: string,
    title: string,
    message
  ): Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
}
