import { Component, OnInit } from "@angular/core";
import { Chart } from "chart.js";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { IndicatorService } from "src/app/services/indicator.service";
import { UserService } from "src/app/services/user.service";
import { TicketsService } from "src/app/services/tickets.service";

@Component({
  selector: "app-user-indicators",
  templateUrl: "./user-indicators.component.html",
  styleUrls: ["./user-indicators.component.css"],
})
export class UserIndicatorsComponent implements OnInit {
  loader = 1;
  response: any = [];
  data: any = [];
  permisos = JSON.parse(localStorage.getItem("permisos"));
  mostrando = false;

  ctx: any;
  myChart: any;
  data_general: any = [];
  data_charts: any = [];
  result: any = [];
  module: string = "";
  //USUARIO
  search_user = "";
  search_user_result = [];
  search_user_id = "";
  id_user: string = "";
  user_indicadores: string = "";
  //datos independientes ANUALES
  total_informes_usuario = 0;
  total_meta_anual = 0;
  rechazados_anual = 0;
  //PROGRESO
  progreso_anual = 0;
  //PORCENTAJE
  por_subidos = 0;
  por_rechazados = 0;

  ind_jan = 0;
  ind_feb = 0;
  ind_mar = 0;
  ind_apr = 0;
  ind_may = 0;
  ind_jun = 0;
  ind_jul = 0;
  ind_aug = 0;
  ind_sep = 0;
  ind_oct = 0;
  ind_nov = 0;
  ind_dec = 0;

  constructor(
    private modalService: NgbModal,
    private activedRoute: ActivatedRoute,
    private userSer: UserService,
    private indicatorSer: IndicatorService,
    private ticketService: TicketsService
  ) {}

  ngOnInit(): void {
    this.ctx = document.getElementById("chart");
    this.module = "87";
    this.mostrando = false;
  }

  /**
   * This function retrieves personal indicators by year and displays them in a line chart.
   */
  private getIndicatorsByYear() :void {
    this.userSer.getPersonalIndicators(this.id_user, this.module).subscribe(
      (res) => {
        this.result = res;
        this.data_general = this.result.data;
        if (
          this.result.code == "1_0001" ||
          this.result.code == "1_0002" ||
          this.result.code == "1_0003"
        )
          this.lauchModal(this.result.code, this.result.message);
        else if (this.result.code == "1_0004")
          this.lauchModal(this.result.code, this.result.message);
        else {
          this.myChart = new Chart(this.ctx, {
            type: "line",
            data: {
              labels: [
                "Ene",
                "Feb",
                "Mar",
                "Abr",
                "May",
                "Jun",
                "Jul",
                "Ago",
                "Sep",
                "Oct",
                "Nov",
                "Dic",
              ],
              datasets: [
                {
                  label: "Informes",
                  data: this.data_general.quantitys,
                  fill: true,
                  borderColor: "rgb(0, 255, 0)",
                  backgroundColor: "rgba(0, 255, 0,0.2)",
                  lineTension: 0,
                },
                {
                  label: "Rechazados",
                  data: this.data_general.rejected,
                  fill: true,
                  borderColor: "rgb(255, 0, 0)",
                  backgroundColor: "rgba(255, 0, 0,0.2)",
                  lineTension: 0,
                },
                {
                  label: "Meta",
                  data: this.data_general.for_month,
                  fill: false,
                  borderColor: "rgb(0, 0, 255)",
                  backgroundColor: "rgba(0, 0, 255,0.2)",
                  lineTension: 0,
                },
              ],
            },
            options: {
              bezierCurve: false,
              responsive: true,
              plugins: {
                legend: {
                  position: "top",
                },
                title: {
                  display: false,
                },
              },
            },
          });

          this.ind_jan = this.data_general.for_month[0];
          this.ind_feb = this.data_general.for_month[1];
          this.ind_mar = this.data_general.for_month[2];
          this.ind_apr = this.data_general.for_month[3];
          this.ind_may = this.data_general.for_month[4];
          this.ind_jun = this.data_general.for_month[5];
          this.ind_jul = this.data_general.for_month[6];
          this.ind_aug = this.data_general.for_month[7];
          this.ind_sep = this.data_general.for_month[8];
          this.ind_oct = this.data_general.for_month[9];
          this.ind_nov = this.data_general.for_month[10];
          this.ind_dec = this.data_general.for_month[11];
          this.calculoInformesAnual();
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This function calculates the annual progress percentage, the percentage of uploaded reports, and
   * the percentage of rejected reports based on data provided.
   */
  private calculoInformesAnual() :void {
    //OBTENER SUMA DE TODOS LOS REPORTES SUBIDOS DEL AÑO
    this.data_general.quantitys.forEach((total) => {
      this.total_informes_usuario += total;
    });
    //OBTENER SUMA DE LA META DEL AÑO
    this.data_general.for_month.forEach((total_meta) => {
      this.total_meta_anual += total_meta;
    });
    //OBTENER SUMA DE TODOS LOS REPORTES RECHAZADOS DEL AÑO
    this.data_general.rejected.forEach((rechazado) => {
      this.rechazados_anual += rechazado;
    });
    //OBTENER PORCENTAJE DE PROGRESO ANUAL
    if (this.total_meta_anual <= 0) {
      this.progreso_anual = 0;
    } else {
      this.progreso_anual =
        (this.total_informes_usuario * 100) / this.total_meta_anual;
    }
    this.por_subidos =
      (this.total_informes_usuario * 100) /
      (this.total_informes_usuario + this.rechazados_anual);
    this.por_rechazados = 100 - this.por_subidos;
  }

  //BUSCAR USUARIOS
  public searchUserName() :void {
    this.search_user = this.user_indicadores;
    this.searchUser();
  }

  /**
   * This function sets the search user and their details, resets various variables, and calls other
   * functions to display indicators and get data by year.
   * @param id - The ID of the user being searched for.
   * @param detail - The detail parameter is a variable that contains information about a user that is
   * being searched for. It could include details such as the user's name, email address, or any other
   * identifying information.
   */
  public setSearchUser(id, detail) :void {
    if (this.id_user != "") {
      this.myChart.destroy();
    }
    this.id_user = id;
    this.search_user = detail;
    this.search_user_result = [];
    //datos independientes ANUALES
    this.total_informes_usuario = 0;
    this.total_meta_anual = 0;
    this.rechazados_anual = 0;
    //PROGRESO
    this.progreso_anual = 0;
    //PORCENTAJE
    this.por_subidos = 0;
    this.por_rechazados = 0;
    this.mostrandoIndicadores();
    this.getIndicatorsByYear();
  }

  /**
   * The function "mostrandoIndicadores" returns a boolean value indicating whether the variable
   * "mostrando" has been set to true.
   * @returns A boolean value of `true`.
   */
  private mostrandoIndicadores() :boolean {
    return (this.mostrando = true);
  }

  /**
   * This function searches for a user using a form data object and displays the results or an error
   * message.
   */
  private searchUser() :void {
    let form = new FormData();
    form.append("user", this.search_user);
    this.loader = 1;
    this.ticketService.searchUser(form).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.search_user_result = this.response.data;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /* ASIGNAR META */
  /**
   * This function sets indicators for a user and updates the chart accordingly.
   */
  public setIndicatorToUser() :void {
    var values = [
      this.ind_jan,
      this.ind_feb,
      this.ind_mar,
      this.ind_apr,
      this.ind_may,
      this.ind_jun,
      this.ind_jul,
      this.ind_aug,
      this.ind_sep,
      this.ind_oct,
      this.ind_nov,
      this.ind_dec,
    ];

    let form = new FormData();
    form.append("metas", JSON.stringify(values));

    this.indicatorSer.setIndicatorsForUser(this.id_user, form).subscribe(
      (res) => {
        this.result = res;

        if (
          this.result.code == "1_0001" ||
          this.result.code == "1_0002" ||
          this.result.code == "1_0003"
        )
          this.lauchModal(this.result.code, this.result.message);
        else if (this.result.code == "1_0004")
          this.lauchModal(this.result.code, this.result.message);
        else {
          this.myChart.destroy();
          this.getIndicatorsByYear();
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code and title using
   * the ModalComponent.
   * @param {string} code - The code parameter is a string that represents the code that will be
   * displayed in the modal.
   * @param {string} title - The title parameter is a string that represents the title of the modal
   * that will be launched. It will be displayed at the top of the modal to provide context to the
   * user.
   */
  private async lauchModal(code: string, title: string) :Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }
}
