import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { float } from "@zxing/library/esm/customTypings";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { WalletService } from "src/app/services/wallet.service";

@Component({
  selector: "app-invoices-date",
  templateUrl: "./invoices-date.component.html",
  styleUrls: ["./invoices-date.component.css"],
})
export class InvoicesDateComponent implements OnInit {
  data: any = [];
  loader: number = 0;

  number_page = 0;
  pages = 0;

  totales = [0.0, 0.0, 0.0, 0.0, 0.0];

  permmisions = JSON.parse(localStorage.getItem("permisos"));

  constructor(
    private carteraService: WalletService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.obtenerListaFacturas();
  }

  /**
   * This function obtains a list of invoices and handles different response codes accordingly.
   */
  private obtenerListaFacturas() :void {
    this.loader = 1;
    let response: any;
    this.carteraService.facturasFechasPago().subscribe(
      (res) => {
        response = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004" || response.code == "0_0007")
          this.lauchModal(response.code, response.message, "");
        else {
          let dataTemp = response.data;
          this.dataStructure(dataTemp);
        }

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * This is a TypeScript function that launches a modal with a given code, title, and message.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - The title of the modal window that will be displayed.
   * @param {string} message - The message parameter is a string that represents the content of the
   * message to be displayed in the modal.
   */
  private async lauchModal(code: string, title: string, message: string) :Promise<void>{
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }

  /**
   * The function takes in an array of data and categorizes it based on the number of days past due,
   * while also calculating the total amount of money owed in each category.
   * @param dataTemp - an array of objects containing data to be processed and stored in the data
   * structure. Each object should have the following properties:
   * @returns There is no explicit return statement in the code provided, so nothing is being returned.
   * The function is likely being used to modify and set values for the properties `data` and `totales`
   * of the object it is a method of.
   */
  private dataStructure(dataTemp) :void{
    this.totales = [0.0, 0.0, 0.0, 0.0, 0.0];
    let context = this;
    const newData = dataTemp.map(function (item: any) {
      if (item.dias < 1) {
        item.pos = 1;
        context.totales[0] += parseFloat(item.saldo);
      }
      if (item.dias > 0 && item.dias < 16) {
        item.pos = 2;
        context.totales[1] += parseFloat(item.saldo);
      }
      if (item.dias > 15 && item.dias < 31) {
        item.pos = 3;
        context.totales[2] += parseFloat(item.saldo);
      }
      if (item.dias > 31 && item.dias < 61) {
        item.pos = 4;
        context.totales[3] += parseFloat(item.saldo);
      } else if (item.dias > 60) {
        item.pos = 5;
        context.totales[4] += parseFloat(item.saldo);
      }

      return item;
    });

    this.data = newData;
    this.totales = this.totales.map((a) => {
      let money: any = new Intl.NumberFormat("es-MX", {
        maximumSignificantDigits: 15,
      }).format(a);
      return money;
    });

    //this.pages = dataTemp.length;
  }
}
