import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { getDateMeta } from '@fullcalendar/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { WikiService } from 'src/app/services/wiki.service';
import { environment } from 'src/app/variables/enviroment';

@Component({
  selector: 'app-det-wiki',
  templateUrl: './det-wiki.component.html',
  styleUrls: ['./det-wiki.component.css']
})
export class DetWikiComponent implements OnInit {
  id_reg:string;
  loader:number = 1;
  response:any = [];
  data:any = [];
  doc_name = "";
  url_to_load = environment.uri_server+'storage/public/wiki/';
  constructor(private activedRoute:ActivatedRoute,private wikiService:WikiService,private modalService:NgbModal,  private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.id_reg = this.activedRoute.snapshot.paramMap.get('id_det');
    this.getData();
  }

  /**
   * This function retrieves data from a service and handles different response codes by launching a
   * modal or assigning data to variables.
   */
  private getData() :void {
    this.loader = 1;
    this.wikiService.getDetail(this.id_reg).subscribe(
      res=>{
        this.response = res;
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message,'');
        else if(this.response.code == '1_0004')this.lauchModal(this.response.code,this.response.message,'');
        else {
          this.data = this.response.data;
          this.doc_name = this.data.archivo;
          this.url_to_load = this.url_to_load+this.data.archivo;
        }
        this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta',err.message);
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * The function returns a sanitized URL to be loaded as a trusted resource.
   * @returns The `docURL()` function is returning a sanitized URL that can be used to load a resource.
   * The URL is obtained by calling the `bypassSecurityTrustResourceUrl()` method of the `sanitizer`
   * object, passing in the `url_to_load` property of the current object as an argument. The return
   * value is of type `any`.
   */
  public docURL() :any {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.url_to_load);
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code, title, and
   * message using the Angular ModalService.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - The title of the modal window that will be displayed.
   * @param message - The message parameter is a string that represents the message to be displayed in
   * the modal dialog box.
   */
  private async lauchModal(code:string,title:string,message) :Promise<void>{
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
}
