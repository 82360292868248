import { Pipe, PipeTransform } from '@angular/core';
import { IBrand } from 'src/app/services/models/IBrand';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(value: IBrand[], ...args: any[]): IBrand[] {
    if(!Array.isArray(value)) {
      return value;
    } 
    
    const [property, order = 'asc'] = args;
    if(property !== 'equipos_asignados') {
      return value.slice().sort((a, b) => {
        const compare = `${a[property]}`.localeCompare(`${b[property]}`);
        return order === 'asc' ? compare: -compare;
      })
    } 
    if (property === 'equipos_asignados'){
      return value.slice().sort((a, b) =>{
       const compare = a[property] - b[property];
        return order === 'asc' ? compare: -compare;
      } );
    }
  }

}
