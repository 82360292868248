import { Component, OnInit } from "@angular/core";
import * as path from "path";

@Component({
  selector: "app-administration",
  templateUrl: "./administration.component.html",
  styleUrls: ["./administration.component.css"],
})
export class AdministrationComponent implements OnInit {
  permmisions = JSON.parse(localStorage.getItem("permisos"));
  options: any[] = [];

  constructor() {}

  ngOnInit(): void {
    this.validatePermmissions();
  }

  private validatePermmissions() :void {
    //URL ODC ,
    if (this.permmisions.includes(26)) {
      let uri = {
        path: "/administration/suppliers",
        title: "Proveedores",
        icon: "fa-people-carry",
        class: "",
      };
      this.options.push(uri);
    }

    if (this.permmisions.includes(46)) {
      let uri = {
        path: "/administration/sale_order",
        title: "ODC",
        icon: "fa-shopping-cart",
        class: "",
      };
      this.options.push(uri);
    }

    if (this.permmisions.includes(76)) {
      let uri = {
        path: "/administration/profitability",
        title: "Rentabilidad",
        icon: "fas fa-coins",
        class: "",
      };
      this.options.push(uri);
    }

    if (this.permmisions.includes(158)) {
      let uri = {
        path: "/administration/requisitions",
        title: "Requisiciones",
        icon: "fa-solid fa-stamp",
        class: "",
      };
      this.options.push(uri);
    }

    if (this.permmisions.includes(177)) {
      let uri = {
        path: "/administration/vacations/calendar",
        title: "Vacaciones",
        icon: "fa-solid fa-plane-departure",
        class: "",
      };
      this.options.push(uri);
    }

    if (this.permmisions.includes(183)) {
      let uri = {
        path: "/administration/brands",
        title: "Marcas",
        icon: "fa-solid fa-bookmark",
        class: "",
      };
      this.options.push(uri);
    }
  }
}
