import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CustomerService } from "src/app/services/customer.service";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { IAddress } from "src/app/services/models/IAddress";

@Component({
  selector: "app-detailcustomer",
  templateUrl: "./detailcustomer.component.html",
  styleUrls: ["./detailcustomer.component.css"],
})
export class DetailcustomerComponent implements OnInit {
  permmisions = JSON.parse(localStorage.getItem("permisos"));
  id_customer: string = "";
  response: any = [];
  loader: number = 1;
  data: any = [];
  direcciones: any = [];
  deptos: any = [];
  editing_direccion_fiscal = false;
  result: any;

  constructor(
    private activedRoute: ActivatedRoute,
    private customerService: CustomerService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.id_customer = this.activedRoute.snapshot.paramMap.get("id");
    this.getCustomer();
  }

  /**
   * This function retrieves customer data from a service and handles different response codes
   * accordingly.
   */
  private getCustomer() :void {
    this.customerService.getCustomer(this.id_customer).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.data = this.response.data;
          this.direcciones = this.data.direcciones;
        }

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

 /**
  * This function selects an address by sending a post request to the server and updating the
  * customer's information.
  * @param id - The parameter "id" is a variable that represents the ID of an address that will be used
  * to create a new address object and send it to the server using the customerService.postDireccion()
  * method.
  */
  public selectAddress(id) :void {
    let iDireccion: IAddress = {
      id: id,
      nueva: "2",
    };

    this.customerService.postDireccion(iDireccion).subscribe(
      (res) => {
        console.log(res);
        this.result = res;
        if (this.result.data > 0) {
          this.editandoFisco();
          this.getCustomer();
        } else {
          this.lauchModal(
            this.result.code,
            "Ocurrió un error en el servidor, intenta mas tarde."
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /**
   * This function toggles the editing state of a variable called "editing_direccion_fiscal".
   */
  public editandoFisco() :void {
    if (this.editing_direccion_fiscal) {
      this.editing_direccion_fiscal = false;
    } else {
      this.editing_direccion_fiscal = true;
    }
  }

  /**
   * The function loads departments from a customer service and handles different response codes.
   * @param id - The parameter "id" is the identifier of the customer for which the departments are
   * being loaded. It is used to make a request to the customerService to get the list of departments
   * associated with that customer.
   */
  public loadDepartments(id) :void {
    this.customerService.getDepartments(id).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message);
        else this.deptos = this.response.data;

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This is an asynchronous function that launches a modal with a given code and title using a modal
   * service.
   * @param {string} code - The code parameter is a string that represents the code that will be
   * displayed in the modal.
   * @param {string} title - The title parameter is a string that represents the title of the modal
   * that will be launched. It will be displayed at the top of the modal to provide context to the
   * user.
   */
  private async lauchModal(code: string, title: string) :Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }
}
