import { CompileShallowModuleMetadata } from "@angular/compiler";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Console } from "console";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { SaleOrderService } from "src/app/services/sale-order.service";
import { environment } from "src/app/variables/enviroment";

@Component({
  selector: "app-sale-orders",
  templateUrl: "./sale-orders.component.html",
  styleUrls: ["./sale-orders.component.css"],
})
export class SaleOrdersComponent implements OnInit {
  permmisions = JSON.parse(localStorage.getItem("permisos"));
  status = 1;
  suppliers_list: any = [];
  etalons_etalonet = "";
  supplier_id = "";
  value_to_search = "";
  loader = 1;
  suppliers_name: any = [];
  selectProveedor = "";
  flexRadioDefault = "0";
  file_name = "";

  list: any = [];
  response: any = [];
  number_page = 0;
  pages = 0;
  tipo_reporte = 0;

  @ViewChild("btn_close_modal") divView: ElementRef;

  constructor(
    private saleOrderService: SaleOrderService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getList();
    this.getListSupplier();
  }

  /**
   * This function retrieves a list of ODCs from a service and handles the response and errors.
   */
  private getList() :void {
    this.saleOrderService.getODCList().subscribe(
      (res) => {
        this.response = res;
        if (this.response.code == "0_005" && this.response.data != "0") {
          this.list = this.response.data;
          this.pages = this.list.length;
        } else this.lauchModal(this.response.code, this.response.message, "");
        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", err.message);
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * This is a private function in TypeScript that sets the ID of a client and saves it.
   * @param id - The parameter "id" is a value that is passed into the "setIDClient" function. It is
   * used to set the value of the "etalons_etalonet" property of the object that the function is called
   * on. The function then saves the updated object using the "saveODC
   */
  public setIDClient(id) :void {
    this.etalons_etalonet = id;
    this.saveODC();
  }

  /**
   * This function saves an ODC (Order Delivery Confirmation) and navigates to the edit page of the
   * saved sale order.
   */
  private saveODC() :void  {
    this.loader = 1;
    let result: any = [];
    let form = new FormData();
    form.append("client", this.etalons_etalonet);
    form.append("supplier", this.supplier_id);

    this.saleOrderService.saveODC(form).subscribe(
      (res) => {
        result = res;
        if (result.code == "0_005" && result.data != "0") {
          this.divView.nativeElement.click();
          this.loader = 0;
          this.router.navigate([
            "/administration/sale_order/edit",
            result.data,
          ]);
        } else this.lauchModal(result.code, result.message, "");
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", err.message);
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * This function searches for a supplier using a value to search and displays the results or an error
   * message.
   */
  public searchSupplier() :void {
    let data: any = [];
    this.saleOrderService.getSupplier(this.value_to_search).subscribe(
      (res) => {
        data = res;
        if (data.code == "0_005") {
          this.suppliers_list = data.data;
        } else this.lauchModal(data.code, data.message, "");
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", err.message);
        console.log(err);
      }
    );
  }

  /**
   * This is a private function in TypeScript that sets the supplier ID.
   * @param id - The "id" parameter is a value that represents the ID of a supplier. The "setSupplier"
   * method is a function that takes this ID as an argument and sets it as the value of the
   * "supplier_id" property of the object. The "private" keyword indicates that this method can only
   */
  public setSupplier(id) :void {
    this.supplier_id = id;
  }

  /**
   * The function changes the current page number to the specified page.
   * @param page - The "page" parameter is a variable that represents the page number that the user
   * wants to navigate to. The "changePageResults" function takes this parameter and updates the
   * "number_page" variable with the new page number.
   */
  public changePageResults(page) :void {
    this.number_page = page;
  }

  /**
   * This is an asynchronous function that launches a modal with a given code, title, and message using
   * a modal service.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - The title of the modal that will be displayed.
   * @param message - The message parameter is likely a string that contains the content to be
   * displayed in the body of the modal dialog box. It could be a message to the user or some other
   * information that needs to be communicated.
   */
  private async lauchModal(code: string, title: string, message) :Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }

  /**
   * This function retrieves a list of suppliers from a sale order service and handles any errors that
   * may occur.
   */
  private getListSupplier() :void {
    this.saleOrderService.getList().subscribe(
      (res) => {
        this.response = res;
        if (this.response.code == "0_005" && this.response.data != "0") {
          this.suppliers_name = this.response.data;
        } else this.lauchModal(this.response.code, this.response.message, "");
        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", err.message);
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * This function generates a report for suppliers based on selected criteria and downloads the
   * resulting file.
   */
  public generateReportSuppliers() :void {
    let form = new FormData();
    form.append("cliente", this.flexRadioDefault);
    form.append("proveedor", this.selectProveedor);
    this.saleOrderService
      .postConsultaClienteProveedor(
        this.selectProveedor,
        this.flexRadioDefault,
        this.tipo_reporte
      )
      .subscribe(
        (res) => {
          this.response = res;
          if (this.response.code == "0_005" && this.response.data != "0") {
          } else this.lauchModal(this.response.code, this.response.message, "");
          this.loader = 0;
          this.file_name = this.response.data;
          this.downloadFile();
        },
        (err) => {
          this.lauchModal("0000x00", "Error de consulta", err.message);
          console.log(err);
          this.loader = 0;
        }
      );
  }
  
/**
 * The function downloads a file from a specific URL and opens it in a new window.
 */
  private downloadFile() :void {
    var url =
      environment.uri_server +
      "storage/public/odc_reportes/reportes_odc_generados/" +
      this.file_name;
    window.open(url, "_blank");
  }
}
