import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneratorReportsService } from 'src/app/services/generator-reports.service';
import { Chart } from 'chart.js';
import { IInforme } from 'src/app/services/models/IInformes';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { environment } from 'src/app/variables/enviroment';


@Component({
  selector: 'app-transmisor-temp120',
  templateUrl: './transmisor-temp120.component.html',
  styleUrls: ['./transmisor-temp120.component.css']
})
export class TransmisorTemp120Component implements OnInit {

  uri:string = environment.uri;
  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');
  url_to_load = '';

  columnas:any=[];
  finalValores:any=[];
  idsEquipos:any=[];
  idShet:string='';
  idpatron:any=[];

  equiposOdv:any=[]

  loader:number = 1;
  response:any = [];
  data:any=[];
  pdfInformes:any=[];
  clic:string;

  ibc_1:number;
  ibc_2:number;
  ibc_3:number;
  ibc_4:number;

  lugar:any = "sitio";
  temp:any;
  unidad:any;
  format:any = "AAA-MM-DD";
  fecha:any;
  ctx:any;
  myChart:any;

  constructor(
    private datos: ActivatedRoute, 
    private router:Router, 
    public conn: HttpClient,
    private generadorReports:GeneratorReportsService, 
    private modalService:NgbModal
  ) { }

  ngOnInit(): void {
    let datos=this.datos.snapshot.paramMap.get('id_equipos')
    this.idsEquipos.push(datos.split(','));
    this.idShet = this.datos.snapshot.paramMap.get('id_hoja');
    this.idpatron = this.datos.snapshot.paramMap.get('id_patron');

    this.getData();
  }

  getData(){
    this.generadorReports.verPlantilla(this.idShet,this.idsEquipos).subscribe(
      res=>{
        this.response = res;
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
        else this.data = this.response.data;

        this.equiposOdv = this.data.equipos;

        for (let i = 0; i < this.data[1].length; i++) {
          let colums:any=[];
              colums["pos"]=i;
              colums["column"]=this.data[1][i].columna;
              colums["tabla"]=this.data[1][i].tabla;
          this.columnas.push(colums);
        }

        this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        this.loader = 2;
      }
    )
   
  }
  
  obtenerDatos(){

    this.loader = 1;
    var min_1 = 49.4; var min_2 = 74.4; var min_3 = 99.4; var min_4 = 119.4;
    var max_1 = 50.6; var max_2 = 75.6; var max_3 = 100.6; var max_4 = 120.6;

    var inTol_1=""; var inTol_2=""; var inTol_3=""; var inTol_4="";
    var informes=[];
    for (let i = 0; i < this.equiposOdv.length; i++) {
      let listError = [];
      let listIbc = []; let listInTol = [];
      let equipo_id = this.equiposOdv[i].idEquipo;

      this.ibc_1 = parseFloat((document.getElementById(equipo_id+"-0") as HTMLInputElement).value);
      listIbc.push(this.ibc_1);
      this.ibc_2 = parseFloat((document.getElementById(equipo_id+"-1") as HTMLInputElement).value);
      listIbc.push(this.ibc_2);
      this.ibc_3 = parseFloat((document.getElementById(equipo_id+"-2") as HTMLInputElement).value);
      listIbc.push(this.ibc_3);
      this.ibc_4 = parseFloat((document.getElementById(equipo_id+"-3") as HTMLInputElement).value);
      listIbc.push(this.ibc_4);

      this.ibc_1 <= min_1 ? inTol_1 = 'No cumple' : this.ibc_1 >= max_1 ? inTol_1 = 'No cumple'
      : inTol_1 = 'Si cumple';

      this.ibc_2 <= min_2 ? inTol_2 = 'No cumple' : this.ibc_2 >= max_2 ? inTol_2 = 'No cumple'
      : inTol_2 = 'Si cumple';

      this.ibc_3 <= min_3 ? inTol_3 = 'No cumple' : this.ibc_3 >= max_3 ? inTol_3 = 'No cumple'
      : inTol_3 = 'Si cumple';

      this.ibc_4 <= min_4 ? inTol_4 = 'No cumple' : this.ibc_4 >= max_4 ? inTol_4 = 'No cumple'
      : inTol_4 = 'Si cumple';

      (document.getElementById(equipo_id+"in0") as HTMLInputElement).setAttribute("value",inTol_1);
      listInTol.push(inTol_1);
      (document.getElementById(equipo_id+"in1") as HTMLInputElement).setAttribute("value",inTol_2);
      listInTol.push(inTol_2);
      (document.getElementById(equipo_id+"in2") as HTMLInputElement).setAttribute("value",inTol_3);
      listInTol.push(inTol_3);
      (document.getElementById(equipo_id+"in3") as HTMLInputElement).setAttribute("value",inTol_4);
      listInTol.push(inTol_4);

      var error50 = this.ibc_1 - 50.000;
      var error75 = this.ibc_2 - 75.000;
      var error100 = this.ibc_3 - 100.000;
      var error120 = this.ibc_4 - 120.000;

      listError.push(error50);
      listError.push(error75);
      listError.push(error100);
      listError.push(error120);

      var incertidumbre1 = error50-0.11;
      var incertidumbre2 = error75-0.11;
      var incertidumbre3 = error100-0.11;
      var incertidumbre4 = error120-0.11;
      
      var yincertidumbre1 = error50 + 0.11;
      var yincertidumbre2 = error75 + 0.11;
      var yincertidumbre3 = error100 + 0.11;
      var yincertidumbre4 = error120 + 0.11;

      this.ctx = document.getElementById(equipo_id+"myChart");
      var step = 0.000;
      var max = 0.400;
      var start = -0.400;

      this.myChart = new Chart(this.ctx, {
        type: "line",
        data: {
          labels: ["50", "75", "100", "120"],
          datasets: [
            {
              label: "Tolerancia del instrumento",
              data: [0.500, 0.500, 0.500, 0.500],
              fill: false,
              borderDash: [5,5],
              borderColor: "rgb(0, 0, 255)"
            },
            {
              label: "Incertidumbre",
              data: [yincertidumbre1, yincertidumbre2, yincertidumbre3, yincertidumbre4],
              fill: false,
              lineTension: 0.000001,
              borderDash: [5,5],
              borderColor: "rgb(0, 0, 0)"
            },
            {
              label: "Error = (Media Inst. - Patrón)",
              data: [error50, error75, error100, error120],
              fill: false,
              lineTension: 0.000001,
              pointBackgroundColor: "rgb(0,0,0)",
              borderColor: "rgb(255, 0, 0)"
            },
            {
              label: "Incertidumbre",
              data: [incertidumbre1, incertidumbre2, incertidumbre3, incertidumbre4],
              fill: false,
              lineTension: 0.000001,
              borderDash: [5,5],
              borderColor: "rgb(0, 0, 0)"
            },
            {
              label: "Tolerancia del instrumento",
              data: [-0.500, -0.500, -0.500, -0.500],
              fill: false,
              borderDash: [5,5],
              borderColor: "rgb(0, 0, 255)"
            },
          ],
        },
        options: {
          legend: {
            display: false
          },
          scaleSteps: Math.ceil((max - start) / step),
                scaleStepWidth: step,
                scaleStartValue: start
        },
        
      });
      
      if(this.temp == null || this.unidad == null || this.fecha == null) {
        alert("Campos vacios");
      } else {
        let global = [];
        global.push(this.temp);
        global.push(this.unidad);
        global.push(this.format);
        global.push(this.fecha);
        global.push(this.lugar);
        
        let clic = this.uri+'transmisor_temperatura_120?api_key='+this.api_key+'&token='+this.token+
        '&module=2&id_plantilla='+this.idShet+'&libc='+listIbc+'&inTol='+listInTol+'&lerror='+
        listError+'&globales='+global+"&id_equipo="+equipo_id+"&patron_id="+this.idpatron;
        let a=[];
        a[0] = clic;
        a[1]=this.equiposOdv[i].identificador+this.equiposOdv[i].partida;
        a[2]=this.myChart;  
        informes.push(a);
      }

    }
    //this.url_to_load = this.uri+"transmisor_temperatura_120?api_key="+this.api_key+"&token="+this.token+"&module=2";
    this.pdfInformes = informes;
    this.loader=2;
    
  }

  obtenerImagen(url_pdf,myChartId){
    this.loader=1;
    url_pdf = url_pdf[0];
    let base64 = myChartId.toBase64Image();

    var to_send:IInforme = {
      img: base64,
    };

    this.conn.post(url_pdf, to_send).subscribe(
      res => {
        this.response = res;
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
        else this.data = this.response.data;
        let informe = this.data;
        console.log(informe);
        this.loader = 2;
        this.downloadFile(informe);
      },
      error=>{
        console.log(error);
        this.lauchModal('0000x00','Error de consulta');
        this.loader = 2;
      }
    );
    
  }


  asignarInforme(url_pdf, myChartId){
    this.loader = 1;
    url_pdf = url_pdf[0];
    let base64 = myChartId.toBase64Image();

    var to_send:IInforme = {
      img: base64,
    };


    this.conn.post(url_pdf+"&asignar="+"asignar", to_send).subscribe(
      res => {
        this.response = res;
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
        else this.data = this.response.data;
        alert("Asignado correctamente");
        this.loader = 2;
      },
      error=>{
        console.log(error);
        alert("Error de petición");
        this.loader = 2;
      }
    );
  }
  

  downloadFile(file_name){
    var url = environment.uri_for_reports+file_name;
    window.open(url, '_blank');
  }
  
  lauchModal(code:string,title:string){
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

}
