import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { SuppliersService } from "src/app/services/suppliers.service";
import { environment } from "src/app/variables/enviroment";
import { Url } from "url";

@Component({
  selector: "app-detail-supplier",
  templateUrl: "./detail-supplier.component.html",
  styleUrls: ["./detail-supplier.component.css"],
})
export class DetailSupplierComponent implements OnInit {
  id_prov = "";
  loader = 1;
  response: any = [];
  data: any = [];
  contact_data: any = [];
  address: any = "";
  url_to_load = environment.uri_server + "/storage/public/actas_constitutivas/";
  file: any;
  file_name = "";
  aux = "";
  desplegaraux: boolean = false;

  constructor(
    private activedRoute: ActivatedRoute,
    private suppliersService: SuppliersService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.id_prov = this.activedRoute.snapshot.paramMap.get("id");
    this.getData();
  }

  /**
   * This is a private function in TypeScript that retrieves data from a supplier service and updates
   * the component's properties accordingly.
   */
  private getData() :void {
    this.loader = 1;
    this.suppliersService.getDetail(this.id_prov).subscribe(
      (res) => {
        this.response = res;

        if (this.response.code != "0_005")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.data = this.response.data;
          this.contact_data = this.data.contact;
          this.address = this.data.dire;
          if (this.data.acta_constitutiva != null) {
            this.url_to_load = this.url_to_load + this.data.acta_constitutiva;
          }
          this.aux = this.data.acta_constitutiva;
        }
        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", err.message);
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * This function toggles a boolean value between true and false.
   */
  public cambiarAux() :void {
    if (this.desplegaraux) {
      this.desplegaraux = false;
    } else {
      this.desplegaraux = true;
    }
  }

  /**
   * This function loads a file and sets its name as a property.
   * @param value - The parameter "value" is an event object that is passed as an argument to the
   * function. It contains information about the event that triggered the function, such as the target
   * element that was interacted with. In this case, it is likely an event object related to a file
   * input element, as the
   */
  public loadFile(value) :void {
    this.file = value.target;
    this.file_name = this.file.files[0].name;
  }

  /**
   * This function sends an acta constitutiva file to a server using a POST request and displays a
   * modal with the result.
   */
  public enviarActa() :void {
    let result: any = [];
    this.loader = 1;

    if (this.file.files.length > 0) {
      let form = new FormData();
      form.append("file", this.file.files[0]);
      form.append("id_prov", this.id_prov);

      this.suppliersService.addActaConstitutiva(form).subscribe(
        (res) => {
          result = res;
          if (
            result.code == "1_0001" ||
            result.code == "1_0002" ||
            result.code == "1_0003"
          )
            this.lauchModal(result.code, "Informacion", result.message);
          else if (result.code == "1_0004" || result.code == "0_007")
            this.lauchModal(result.code, "Informacion", result.message);
          else {
            if (result.data == 1) {
              this.file_name = "";
              this.getData();
            } else
              this.lauchModal(
                "0000x00",
                "Informacion",
                "Algo salió mal. Intenta nuevamente"
              );
          }
          this.loader = 2;
        },
        (err) => {
          this.lauchModal("0000x00", "Error", "Error de consulta");
          console.log(err);
          this.loader = 2;
        }
      );
    }
  }

  /**
   * The function sets a URL and returns a sanitized resource URL.
   * @returns The `setUrl()` method is returning a sanitized URL that can be used to load a resource.
   * The `bypassSecurityTrustResourceUrl()` method of the `sanitizer` service is used to bypass the
   * default security checks and allow the URL to be loaded.
   */
  public setUrl() :any {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.url_to_load);
  }

  /**
   * This function launches a modal with a given code, title, and message using the Angular Material
   * modal service.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - The title of the modal that will be displayed.
   * @param message - The message parameter is likely a string that contains the content to be
   * displayed in the body of the modal dialog box. It could be a message to the user or some other
   * information that needs to be communicated.
   */
  private async lauchModal(code: string, title: string, message) :Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
}
