import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { log } from 'console';
//import jsPDF from 'jspdf';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { GeneratorReportsService } from 'src/app/services/generator-reports.service';
import { IElement } from 'src/app/services/models/IElement';
import { environment } from 'src/app/variables/enviroment';

@Component({
  selector: 'app-dinamic-generator',
  templateUrl: './dinamic-generator.component.html',
  styleUrls: ['./dinamic-generator.component.css']
})
export class DinamicGeneratorComponent implements OnInit {
  uri:string = environment.uri;
  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');

  columnas:any=[];
  valores=[];
  
  idsEquipos:any=[];
  idShet:string='';
  idpatron:any=[];

  equiposOdv:any=[];
  loader:number = 1;
  response:any = [];
  data:any=[];
  pdfInformes:any=[];

  clic:string;
  lugar:any = "sitio";
  temp:any;
  unidad:any;
  format:any = "AAA-MM-DD";
  fecha:any;
  ctx:any;
  myChart:any;

  info_req:number;
  doc;
  url_to_pdf = '';
  base_64_plantilla = '';
  elements_tree:any = [];
  imgData = '../../../assets/img/0002.jpg';
  array_vals:any=[];
  dataHoja:any=[];
  data_med:any=[];

  constructor(
    private sanitizer: DomSanitizer,
    private datos: ActivatedRoute,
    public conn: HttpClient,
    private generadorReports:GeneratorReportsService, 
    private modalService:NgbModal
  ) { }

  ngOnInit(): void {
    let datos=this.datos.snapshot.paramMap.get('id_equipos')
    this.idsEquipos.push(datos.split(','));
    this.idShet = this.datos.snapshot.paramMap.get('id_hoja');
    this.idpatron = this.datos.snapshot.paramMap.get('id_patron');

    this.getData();
  }

  getData(){
    this.generadorReports.verPlantilla(this.idShet,this.idsEquipos).subscribe(
      res=>{
        this.response = res;
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
        else this.data = this.response.data;
        this.dataHoja=this.data[0][0];
        this.equiposOdv = this.data.equipos;
        this.createTable();
        
        this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        this.loader = 2;
      }
    )
   
  }

  createTable(){
    for (let i = 0; i < this.data[1].length; i++) {
      let colums:any=[];
          colums["column"]=this.data[1][i].columna;
      this.columnas.push(colums);
    }

    for (let a = 0; a < this.columnas.length; a++) {
      let arr = this.data[1][a].valores.split(',');
      switch (arr[0]) {
        case "na":
          let na_aux =[];
          for(let i=0;i<arr.length;i++ ){ na_aux.push(0); }
          arr=na_aux;
          break;
        case "mi":
          let mi_aux =[];
          for(let i=0;i<arr.length;i++ ) {mi_aux.push(0);}
          arr=mi_aux;
          break;
        case "ma":
          let ma_aux =[];
          for(let i=0;i<arr.length;i++ ) {ma_aux.push(0);}
          arr=ma_aux;
          break;
        case "er":
          let er_aux =[];
          for(let i=0;i<arr.length;i++ ) er_aux.push(0);
          arr=er_aux;
          break;
        case "y":
          let tol_aux =[];
          for(let i=0;i<arr.length;i++ ) {tol_aux.push(0);}
          arr=tol_aux;
          break;
        case "incert":
          let incer_aux =[];
          for(let i=0;i<arr.length;i++ ) incer_aux.push(0);
          arr=incer_aux;
          break;
        case "ref":
          let ref_aux =[];
          for(let i=0;i<arr.length;i++ ) ref_aux.push(0);
          arr=ref_aux;
          break;
      }
      this.valores.push(arr);
    }
  }

  obtenerDatos(){
    var dataMin:any=[], dataMax:any=[], dataRef:any=[], dataTol:any=[], dataRead:any=[];
    this.data_med=[];
    this.array_vals=[];
    for (let a = 0; a < this.columnas.length; a++) {
      var aux = [this.columnas[a].column];
      for (let i = 0; i < this.valores[a].length; i++) {
        let vals = (document.getElementById(a+"-"+i) as HTMLInputElement).value;
        let valor = parseFloat(vals).toFixed(this.dataHoja.decimales);
        switch (this.columnas[a].column) {
          case "IBC":
            dataRead.push(valor);
            aux.push(valor)
            this.data_med.push(valor);
            break;
          case "Referencia":
            dataRef.push(valor);
            aux.push(valor)
            this.data_med.push(valor);
            break;
          case "Tolerancia":
            dataTol.push(valor);
            let minT=parseFloat(dataRef[i]) - parseFloat(vals);
            let maxT=parseFloat(dataRef[i]) + parseFloat(vals);
            dataMin.push(minT.toFixed(this.dataHoja.decimales));
            dataMax.push(maxT.toFixed(this.dataHoja.decimales));
            aux.push(valor)
            this.data_med.push(valor);
            break;
          case "Min":
            if(vals=="0"){
              let minMi=parseFloat(dataRef[i]) - parseFloat(vals);
              (document.getElementById(a+"-"+i) as HTMLInputElement).setAttribute("value",minMi.toString());
              dataMin.push(minMi.toFixed(this.dataHoja.decimales));
              aux.push(minMi.toFixed(this.dataHoja.decimales))
              this.data_med.push(minMi.toFixed(this.dataHoja.decimales));
            }else {
              dataMin.push(valor);
              aux.push(valor)
              this.data_med.push(valor);
            }
            break;
          case "Max":
            if(vals=="0"){
              let maxMa=parseFloat(dataRef[i]) + parseFloat(vals);
              (document.getElementById(a+"-"+i) as HTMLInputElement).setAttribute("value",maxMa.toString());
              dataMax.push(maxMa.toFixed(this.dataHoja.decimales));
              aux.push(maxMa.toFixed(this.dataHoja.decimales))
              this.data_med.push(maxMa.toFixed(this.dataHoja.decimales));
            }else {
              dataMax.push(valor);
              aux.push(valor)
              this.data_med.push(valor);
            }
            break;
          case "In Tol":
              let valor_min = parseFloat(dataRef[i]) - parseFloat(dataTol[i]);
              let valor_max = parseFloat(dataRef[i]) + parseFloat(dataTol[i]);

              if (parseFloat(dataRead[i]) < valor_min) {
                (document.getElementById(a+"-"+i) as HTMLInputElement).setAttribute("value","No cumple");
                aux.push("No cumple")
                dataTol.push("No cumple");
                this.data_med.push("No cumple");
              } else if(parseFloat(dataRead[i]) > valor_max){
                (document.getElementById(a+"-"+i) as HTMLInputElement).setAttribute("value","No cumple");
                aux.push("No cumple");
                dataTol.push("No cumple");
                this.data_med.push("No cumple");
              }else{
                (document.getElementById(a+"-"+i) as HTMLInputElement).setAttribute("value","Si cumple");
                aux.push("Si cumple");
                dataTol.push("Si cumple");
                this.data_med.push("Si cumple");
              }

            break;
          case "Error":
            let valor_error = dataRead[i] - dataRef[i];
            (document.getElementById(a+"-"+i) as HTMLInputElement).setAttribute("value",valor_error.toString());
            aux.push(valor_error.toFixed(this.dataHoja.decimales))
            this.data_med.push(valor_error.toFixed(this.dataHoja.decimales));
            break;
          case "Incertidumbre":
            aux.push(valor);
            this.data_med.push(valor);
            break;
        }
      }
      this.array_vals.push(aux);
    }

    let filas = this.data[1][0].valores.split(',');
    
    let global = this.fecha+","+this.temp+","+this.unidad+",100,0,100,"+filas.length;
    let ids = this.equiposOdv[0].id_cliente+","+this.dataHoja.id+","+this.equiposOdv[0].idEquipo+","+this.equiposOdv[0].idOdv+","+this.lugar;
    this.generarInforme(global,ids,this.idpatron,this.data_med,this.dataHoja.id);
  }

  docURL(){
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.url_to_pdf);
  }

  generarInforme(global,ids,patrones,med,id_hoja){
    this.generadorReports.generarInformeDinamico(global,ids,patrones,med,id_hoja).subscribe(
      res=>{
        this.response = res;
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
        else this.downloadFile(this.response.data);
        this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        this.loader = 2;
      }
    )
  }

  downloadFile(file_name){
    var url = environment.uri_for_reports+file_name;
    window.open(url, '_blank');
  }

  lauchModal(code:string,title:string){
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

}
