import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { CommercialComponent } from './commercial.component';
import { CotDetComponent } from './cotizaciones/cot-det/cot-det.component';
import { CotEditComponent } from './cotizaciones/cot-edit/cot-edit.component';
import { CotizacionesComponent } from './cotizaciones/cotizaciones.component';
import { CotAddClientComponent } from './cotizaciones/cot-add-client/cot-add-client.component';
import { IndicatorsCotComponent } from './cotizaciones/indicators-cot/indicators-cot.component';
import { DetEmailComponent } from './emails/det-email/det-email.component';
import { EmailsComponent } from './emails/emails.component';
import { DownloadZipComponent } from './odv/download-zip/download-zip.component';
import { EditOdvComponent } from './odv/edit-odv/edit-odv.component';
import { OdvDetComponent } from './odv/odv-det/odv-det.component';
import { OdvQrComponent } from './odv/odv-qr/odv-qr.component';
import { OdvComponent } from './odv/odv.component';
import { SendEmailOdvComponent } from './odv/send-email-odv/send-email-odv.component';
import { ServiceSurveysComponent } from './odv/service-surveys/service-surveys.component';
import { TemporalOdvComponent } from './temporal-odv/temporal-odv.component';
import { ServiceNotificationsComponent } from './service-notifications/service-notifications.component';
import { EmailNotificationComponent } from './service-notifications/email-notification/email-notification.component';

const routes: Routes = [
  //Comercial
{ path: 'commercial',
  children:[
  {path:'',component:CommercialComponent},

  {path:'odv',children:[
    {path:'',component:OdvComponent},
    {path:'survey/:id_odv',component:ServiceSurveysComponent},
    {path:'download/:id_odv',component:DownloadZipComponent},
    {path:'det/:id_odv', children:[
      {path: '', component: OdvDetComponent},
      {path:'sendmail', component: SendEmailOdvComponent}
    ]} ,
    {path:'edit/:id_odv',component:EditOdvComponent},
    {path:'qr_odv/:id_odv',component:OdvQrComponent} ,
  ]},

  {path:'cot',children:[
    {path:'',component:CotizacionesComponent},
    {path:'det/:id_cot',component:CotDetComponent},
    {path:'edit/:id_cot',component:CotEditComponent},
    {path:'add_client/:id_cot',component:CotAddClientComponent},
    {path:'indicator_cot',component:IndicatorsCotComponent},
  ]},
  {path:'email',children:[
    {path:'',component:EmailsComponent},
    {path:'det/:id_tipo',component:DetEmailComponent},
  ]},
  {path:'tmp_odv',children:[
    {path:'',component:TemporalOdvComponent},
    //{path:'det/:id_tipo',component:DetEmailComponent}
  ]},
  {path:'notifications',children:[
    {path:'',component:ServiceNotificationsComponent},
    {path:'email_notification/:id_cot',component:EmailNotificationComponent}
  ]}
]
},
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CommercialRoutingModule { }
