import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { EventService } from 'src/app/services/event.service';
import { InstrumentService } from 'src/app/services/instrument.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/app/variables/enviroment';

@Component({
  selector: 'app-event-labels',
  templateUrl: './event-labels.component.html',
  styleUrls: ['./event-labels.component.css']
})
export class EventLabelsComponent implements OnInit {
  uri: string = environment.uri;
  token = localStorage.getItem("token");
  api_key = localStorage.getItem("key");
  permmisions = JSON.parse(localStorage.getItem("permisos"));

  id_event: string = "";
  loader: number = 1;
  response: any = [];
  data: any = [];

  partidas:any =[];

  public send_partidas:any[] = [];
  public check_all:boolean = false;

  public cal_date:string = '';

  public new_labels = {
    id_event : '',
    fecha_calibracion : '',
    partidas: [],
  }
  
  public search_user: string;
  public search_user_result: any = [];
  public userUploadReport: string = '';

  user_list = [];
  constructor(
    private eventService: EventService,
    private modalService: NgbModal,
    private activedRoute: ActivatedRoute,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.id_event = this.activedRoute.snapshot.paramMap.get("id");
    this.loadEventDetail();
  }

  private loadEventDetail() : void {
    this.eventService.getEventDetail(this.id_event).subscribe(
      (res) => {
        this.response = res;
        this.loader = 2;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.data = this.response.data;
          this.partidas = this.data.partidas;
          this.partidas.forEach(partidas => {
            partidas.check = false;

            if (partidas.id_informe != 0) {
              partidas.informe =  partidas.informe.replace('.pdf', '');
            }
         });
      
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  public generateLabels(): void {
    this.new_labels.fecha_calibracion = this.cal_date;
    this.new_labels.id_event = this.id_event;
    this.new_labels.partidas = [...this.send_partidas];

    this.eventService.generateLabelsEvent(this.new_labels).subscribe(
      (res) => {
        this.response = res;
        this.loader = 2;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {this.dowloadFile(this.response.data);
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  private dowloadFile(name) :void {
    var url = environment.uri_server + "storage/public/xlsx_etiquetas/generados/" + name;
    window.open(url, "_blank");
  }

  /* FECHA CALIBRACION */
  public loadDate(value):void {
    this.cal_date = value;
  }
  public calculateEndDate(frecuencia:number): string {
    const fecha_calibracion = new Date(this.cal_date);

    fecha_calibracion.setMonth(fecha_calibracion.getMonth() + frecuencia);
    const anio = fecha_calibracion.getFullYear();
    const mes = fecha_calibracion.getMonth() + 1;
    const dia = fecha_calibracion.getDate();

    return `${dia.toString().padStart(2, "0")}-${mes
      .toString()
      .padStart(2, "0")}-${anio}`;
  }

  /* SELECT PARTIDAS */
  public setPartida(id_event_det, user_id) : void {
    const partida = this.partidas.find(obj => obj.id_det_ev === id_event_det);
    const new_part = {
      partida: id_event_det,
      user_id: user_id
    }

    const existingIndex = this.send_partidas.findIndex(objeto => objeto.partida === id_event_det);

    if (existingIndex === -1) {
      this.send_partidas.push(new_part);
    } else {
      this.send_partidas.splice(existingIndex, 1);
    }
    partida.check = !partida.check;
    this.check_all = this.allSelected(this.partidas);
  }

  public allSelected(partidas): boolean {
    return partidas.every(obj => obj.check === true)
  }

  public selectAllParts(): void {
    this.send_partidas = [];
    this.check_all = !this.check_all;
  
    this.partidas.forEach(all_parts => {
      all_parts.check = this.check_all;
      if (this.check_all) {
        const new_part = {
          partida: all_parts.id_det_ev,
          user_id: all_parts.metrologo_id
        }
        this.send_partidas.push(new_part);
      }
    });
  }

  /* SELECCION DE USUARIOS */
  public searchUser() : void {
    this.search_user = this.userUploadReport;
    let form = new FormData();
    form.append("user", this.search_user);
    this.loader = 1;
    this.userService.searcEtalonethUser(form).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.search_user_result = this.response.data;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  public addUserList(user) : void{
    const index = this.user_list.findIndex(objeto => objeto.id === user.id);
    if (index == -1) {
      this.user_list.push(user);
    }
    
  }

  public removeUserList(user) : void {
    const index = this.user_list.findIndex(objeto => objeto.id === user.id);
    this.user_list.splice(index, 1);
    
  }

  public applyAll(user) :void {
    this.partidas.forEach(partida => {
      if(partida.id_informe == 0 || partida.informe_tipo == 'mant') {
        partida.metrologo_id = user.id
      }
    });
  }

  public canSelectAll(array, buscar, parametro) : boolean {
    return  array.some(objeto => objeto[parametro] == buscar);
  }

  lauchModal(code: string, title: string, message) {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
}
