import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { OrdenVentaService } from "src/app/services/orden-venta.service";
import { environment } from "src/app/variables/enviroment";

@Component({
  selector: "app-odv-qr",
  templateUrl: "./odv-qr.component.html",
  styleUrls: ["./odv-qr.component.css"],
})
export class OdvQrComponent implements OnInit {
  id_odv = "";
  loader: number = 1;

  response: any = [];
  data: any = [];

  parts: any = [];
  listEquipo: any = [];

  url_to_load = "";
  token = localStorage.getItem("token");
  api_key = localStorage.getItem("key");
  permmisions = JSON.parse(localStorage.getItem("permisos"));

  constructor(
    private odvService: OrdenVentaService,
    private activedRoute: ActivatedRoute,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.id_odv = this.activedRoute.snapshot.paramMap.get("id_odv");
    this.loader = 1;
    this.getDataDevices();
  }

  /**
   * The function retrieves data for devices from a service and handles different response codes.
   */
  private getDataDevices() :void {
    this.odvService.getPartsOdvQr(this.id_odv).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.parts = this.response.data[0];
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * The function adds or removes an ID from a list of IDs and logs the updated list.
   * @param {string} id_equipo - string type parameter representing the ID of an equipment to be added
   * or removed from a list of equipment.
   */
  public agregarEquipo(id_equipo: string) :void {
    if (!this.listEquipo.includes(id_equipo)) this.listEquipo.push(id_equipo);
    else {
      var g = this.listEquipo.indexOf(id_equipo);
      this.listEquipo.splice(g, 1);
    }
  }

  /**
   * This function selects all parts' QR codes and adds their corresponding equipment IDs to a list.
   */
  public selectAllPartsQR() :void {
    this.parts.forEach((a) => {
      this.listEquipo.push(a.id_equipo);
    });
  }

  /**
   * This private function sets the URL to download a QR code for a specific module and equipment using
   * environment variables and input parameters.
   */
  public descargar() :void {
    this.url_to_load =
      environment.uri +
      "get_odv_qr?api_key=" +
      this.api_key +
      "&token=" +
      this.token +
      "&module=39&id_odv=" +
      this.id_odv +
      "&id_equipos=" +
      this.listEquipo;
  }

  /**
   * This is an asynchronous function that launches a modal with a given code, title, and message using
   * a modal service.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - The title of the modal that will be displayed.
   * @param message - The message parameter is likely a string that contains the content to be
   * displayed in the body of the modal dialog box. It could be a message to the user or some other
   * information that needs to be communicated.
   */
  private async lauchModal(code: string, title: string, message) :Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }

}
