// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // Your web app's Firebase configuration
  firebase: {
    apiKey: "AIzaSyCtDH7i-3cTVNVZhuYHV5FfZS0bYKSGDbQ",
    authDomain: "notificacionestest-b58f6.firebaseapp.com",
    projectId: "notificacionestest-b58f6",
    storageBucket: "notificacionestest-b58f6.appspot.com",
    messagingSenderId: "291694056196",
    appId: "1:291694056196:web:7ba0fa572bbfa8a422f651"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
