import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { QuotationService } from "src/app/services/quotation.service";

@Component({
  selector: "app-cot-edit",
  templateUrl: "./cot-edit.component.html",
  styleUrls: ["./cot-edit.component.css"],
})
export class CotEditComponent implements OnInit {
  permmisions = JSON.parse(localStorage.getItem("permisos"));
  id_cot = "";
  loader = 2;
  data: any = [];

  //Datos a editar
  priority_list: any = [];
  sales_rep_list: any = [];
  contact_list: any = [];
  adress_list: any = [];
  type_service = 0;

  id_bill_to = null;
  id_ship_to = null;

  notes = "";
  priority = 0;
  sales_rep = 0;
  identifier = "";
  atention_to = 0;

  coin = 1;
  coin_list = [
    { id: 1, coin: "MXN" },
    { id: 2, coin: "USD" },
  ];

  payment = "1";
  payment_list = [
    { id: "0", type: "N/A" },
    { id: "1", type: "Contado" },
    { id: "2", type: "7 días" },
    { id: "3", type: "15 días" },
    { id: "4", type: "21 días" },
    { id: "5", type: "30 días" },
    { id: "6", type: "45 días" },
    { id: "7", type: "60 días" },
    { id: "8", type: "90 días" },
    { id: "9", type: "120 días" },
  ];
  descuento = 0;

  notificacion_email: any = [];

  constructor(
    private activedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private quotationServ: QuotationService,
    private routerService: Router
  ) {}

  ngOnInit(): void {
    this.id_cot = this.activedRoute.snapshot.paramMap.get("id_cot");
    this.getData();
  }

  /**
   * The function retrieves data for editing a quotation and assigns it to various variables.
   */
  private getData(): void {
    this.clearData();
    this.loader = 1;

    this.quotationServ.getDataForEdit(this.id_cot).subscribe(
      (res) => {
        let response: any = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004")
          this.lauchModal(response.code, response.message, "");
        else {
          this.data = response.data;
          this.priority_list = this.data.priority_list;
          this.sales_rep_list = this.data.sales_rep_list;
          this.contact_list = this.data.users_client;
          this.notes = this.data.notes;
          this.priority = this.data.priority_id;
          this.sales_rep = this.data.id_asesor_comercial;
          this.identifier = this.data.identifier;
          this.atention_to = this.data.id_atencion_a;

          this.adress_list = this.data.direcciones;
          this.type_service = this.data.servicio;

          this.id_bill_to = this.data.id_bill_to;
          this.id_ship_to = this.data.id_ship_to;

          this.coin = this.data.moneda;
          this.payment = this.data.id_tipo_pago;
          this.descuento = this.data.descuento;

          this.notificacion_email = this.data.notificado == null ? 2 : this.data.notificado.email;
          console.log(this.notificacion_email)
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This function saves edits made to a quotation and updates the data accordingly.
   * @param return_page - A boolean value indicating whether to return to the quotation detail page
   * after saving the edits or not.
   */
  public saveEdit(return_page): void {
    this.loader = 1;

    let newData = new FormData();

    newData.append("quotation", this.id_cot);
    newData.append("identifier", this.identifier);
    newData.append("priority", this.priority.toString());
    newData.append("notes", this.notes);
    newData.append("contact", this.atention_to.toString());
    newData.append("sales_rep", this.sales_rep.toString());
    newData.append("servicio", this.type_service.toString());
    newData.append("ship_to", this.id_ship_to);
    newData.append("bill_to", this.id_bill_to);
    newData.append("payment", this.payment.toString());
    newData.append("coin", this.coin.toString());
    newData.append("desc", this.descuento.toString());

    this.quotationServ.saveEdit(newData).subscribe(
      (res) => {
        let response: any = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004")
          this.lauchModal(response.code, response.message, "");
        else {
          this.data = response.data;

          if (return_page == 0)
            this.getData();
            
          if (return_page == 1) 
            this.routerService.navigateByUrl(
              "/commercial/cot/det/" + this.id_cot
            );

          if (return_page == 2)
            this.routerService.navigateByUrl(
              "/commercial/notifications/email_notification/"+this.id_cot
            );
          
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code, title, and
   * message using the Angular ModalService.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - The title of the modal window that will be displayed.
   * @param message - The message parameter is a string that represents the message to be displayed in
   * the modal dialog box.
   */
  private async lauchModal(
    code: string,
    title: string,
    message
  ): Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }

  /**
   * The function clears all data and resets certain variables to their default values.
   */
  private clearData(): void {
    this.data = [];
    this.priority_list = [];
    this.sales_rep_list = [];
    this.contact_list = [];

    this.notes = "";
    this.priority = 0;
    this.sales_rep = 0;
    this.identifier = this.data.identifier;
    this.atention_to = this.data.id_atencion_a;

    this.adress_list = [];

    this.id_bill_to = null;
    this.id_ship_to = null;
    this.type_service = 0;

    this.coin = 1;
    this.payment = "";
  }
}
