import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { DriveService } from "src/app/services/drive.service";
import { GeneratorReportsService } from "src/app/services/generator-reports.service";
import { environment } from "src/app/variables/enviroment";

@Component({
  selector: "app-drive",
  templateUrl: "./drive.component.html",
  styleUrls: ["./drive.component.scss"],
})
export class DriveComponent implements OnInit {
  uri: string = environment.uri;
  token = localStorage.getItem("token");
  api_key = localStorage.getItem("key");
  data_files: any = [];
  files: any = [];
  file_names = "";
  loader: number = 0;
  loader_add_odv = 0;

  numSelect: number = 0;
  listFiles: any = [];
  nombresFiles: string = null;

  new_directory: string = "";

  folders: any = [];

  usuarios: any = [];

  array_usuarios: any = [];
  folder_select: string = "";

  number_page = 0;
  pages = 0;

  permmisions = JSON.parse(localStorage.getItem("permisos"));

  constructor(
    private activedRoute: ActivatedRoute,
    private modalService: NgbModal,
    public conn: HttpClient,
    private driveService: DriveService,
    private generadorReports: GeneratorReportsService
  ) {}

  ngOnInit(): void {
    this.getFilesCsv();
  }

  getFilesCsv() {
    let result: any = [];
    this.loader = 1;
    this.generadorReports.getFilesCsvNube().subscribe(
      (res) => {
        result = res;
        if (
          result.code == "1_0001" ||
          result.code == "1_0002" ||
          result.code == "1_0003"
        )
          this.lauchModal(result.code, result.message);
        else if (result.code == "1_0004" || result.code == "0_007")
          this.lauchModal(result.code, result.message);
        else {
          this.listFiles = result.data;
          this.pages = this.listFiles.length;
          if (this.permmisions.includes(111)) this.getFolders();
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  loadFiles(value) {
    for (var i = 0; i < value.target.files.length; i++) {
      this.files.push(value.target.files[i]);
      this.nombresFiles == null
        ? (this.nombresFiles = value.target.files[i].name)
        : (this.nombresFiles += ", " + value.target.files[i].name);
    }
    this.numSelect = this.files.length;
  }

  subirAchivosCsv() {
    let result: any = [];
    this.loader = 1;

    if (this.files.length > 0) {
      let form = new FormData();
      var i = 1;
      this.files.forEach((element) => {
        form.append("file_" + i, element);
        i++;
      });
      this.driveService.subirMultiplesCsv(form).subscribe(
        (res) => {
          result = res;
          if (
            result.code == "1_0001" ||
            result.code == "1_0002" ||
            result.code == "1_0003"
          )
            this.lauchModal(result.code, result.message);
          else if (result.code == "1_0004" || result.code == "0_007")
            this.lauchModal(result.code, result.message);
          else {
            this.data_files = result.data;
            if (this.data_files == 1) {
              this.getFilesCsv();
            }
          }
          this.loader = 2;
        },
        (err) => {
          this.lauchModal("0000x00", "Error de consulta");
          console.log(err);
          this.loader = 2;
        }
      );
    }
  }

  changeStatusFile(id) {
    let result: any = [];
    this.loader = 1;
    this.driveService.cambiarStatus(id).subscribe(
      (res) => {
        result = res;
        if (
          result.code == "1_0001" ||
          result.code == "1_0002" ||
          result.code == "1_0003"
        )
          this.lauchModal(result.code, result.message);
        else if (result.code == "1_0004" || result.code == "0_007")
          this.lauchModal(result.code, result.message);
        else {
          if (result.data == 1) {
            this.getFilesCsv();
          }
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  getFolders() {
    let result: any = [];
    this.loader = 1;
    this.driveService.getFoldersUser().subscribe(
      (res) => {
        result = res;
        if (
          result.code == "1_0001" ||
          result.code == "1_0002" ||
          result.code == "1_0003"
        )
          this.lauchModal(result.code, result.message);
        else if (result.code == "1_0004" || result.code == "0_007")
          this.lauchModal(result.code, result.message);
        else {
          let carpetas = result.data;
          carpetas = this.sortJSON(carpetas, "nombre", "asc");
          this.folders = carpetas;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  sortJSON(data, key, orden) {
    return data.sort(function (a, b) {
      var x = a[key],
        y = b[key];

      if (orden === "asc") {
        return x < y ? -1 : x > y ? 1 : 0;
      }

      if (orden === "desc") {
        return x > y ? -1 : x < y ? 1 : 0;
      }
    });
  }

  createDirectory() {
    let result: any = [];
    this.loader = 1;
    this.driveService.createDirectoryUser(this.new_directory).subscribe(
      (res) => {
        result = res;
        if (
          result.code == "1_0001" ||
          result.code == "1_0002" ||
          result.code == "1_0003"
        )
          this.lauchModal(result.code, result.message);
        else if (result.code == "1_0004" || result.code == "0_007")
          this.lauchModal(result.code, result.message);
        else {
          this.getFolders();
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  shareFolder(nombre, id) {
    this.usuarios = [];
    let result: any = [];
    this.folder_select = nombre;
    this.loader = 1;
    this.driveService.getUserShared(nombre).subscribe(
      (res) => {
        result = res;
        console.log(result);
        if (
          result.code == "1_0001" ||
          result.code == "1_0002" ||
          result.code == "1_0003"
        )
          this.lauchModal(result.code, result.message);
        else if (result.code == "1_0004" || result.code == "0_007")
          this.lauchModal(result.code, result.message);
        else {
          result.data.forEach((u) => {
            if (u.id != id) {
              this.usuarios.push(u);
            }
          });
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  agregarAcceso(id) {
    for (let i = 0; i < this.usuarios.length; i++) {
      if (this.usuarios[i].id == id) {
        if (this.array_usuarios.length < 1) {
          this.array_usuarios.push(id);
        } else {
          for (let b = 0; b < this.array_usuarios.length; b++) {
            if (this.array_usuarios[b] == id) {
              this.array_usuarios.splice(b, 1);
            } else {
              return this.array_usuarios.push(id);
            }
          }
        }
      }
    }
  }

  otorgarAcceso() {
    let result: any = [];
    console.log(this.array_usuarios);
    this.loader = 1;
    this.driveService
      .sharedFolder(this.folder_select, this.array_usuarios)
      .subscribe(
        (res) => {
          result = res;
          console.log(result);
          if (
            result.code == "1_0001" ||
            result.code == "1_0002" ||
            result.code == "1_0003"
          )
            this.lauchModal(result.code, result.message);
          else if (result.code == "1_0004" || result.code == "0_007")
            this.lauchModal(result.code, result.message);
          else {
            this.array_usuarios = [];
            this.getFolders();
          }
          this.loader = 2;
        },
        (err) => {
          this.lauchModal("0000x00", "Error de consulta");
          console.log(err);
          this.loader = 2;
        }
      );
  }

  lauchModal(code: string, title: string) {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

  backPage() {
    window.history.back();
  }

  changePageResults(page) {
    this.number_page = page;
  }
}
