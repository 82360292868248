import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { InstrumentService } from 'src/app/services/instrument.service';

@Component({
  selector: "app-foreign-service",
  templateUrl: "./foreign-service.component.html",
  styleUrls: ["./foreign-service.component.css"],
})
export class ForeignServiceComponent implements OnInit {
  loader = 1;
  response: any = [];

  number_page = 0;
  pages = 0;
  list: any = [];
  requests: any = [];
  selected_tag: string = "";

  constructor(
    private instrumentService: InstrumentService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.getPatronList();
  }

  /**
   * This function retrieves a list of internal instruments and handles the response accordingly.
   */
  private getPatronList(): void {
    this.loader = 1;
    this.instrumentService.listInternalInstruments().subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.list = this.response.data;
          this.pages = this.list.length;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }
  obtenerSolicitudesEquipo(id, tag): void {
    this.selected_tag = tag;
    this.loader = 1;
    this.instrumentService.getSelectedInstrumentRequests(id).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_0007"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.requests = this.response.data;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");

        this.loader = 2;
      }
    );
  }

  /**
   * This function changes the current page number for page results.
   * @param page - The "page" parameter is a variable that represents the page number that the user
   * wants to navigate to. The "changePageResults" function takes this parameter and sets the
   * "number_page" variable to the value of "page". This function is likely used in a pagination system
   * to allow the user to
   */
  public changePageResults(page): void {
    this.number_page = page;
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code, title, and
   * message using a modal service.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - The title of the modal window that will be displayed.
   * @param message - The message parameter is a string that represents the message to be displayed in
   * the modal dialog box.
   */
  private async lauchModal(
    code: string,
    title: string,
    message
  ): Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
}
