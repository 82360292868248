import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { ServiceReportService } from "src/app/services/service-report.service";

@Component({
  selector: "app-edit-rs",
  templateUrl: "./edit-rs.component.html",
  styleUrls: ["./edit-rs.component.css"],
})
export class EditRsComponent implements OnInit {
  loader = 0;
  id_rs: string = "";
  validator = 0;
  message: string = "";
  response: any = [];
  list: any = [];
  partidas_odv: any = [];
  partidas_estatus:any = [];
  identifier_rs: string = "";

  check_all:boolean = false;;

  constructor(
    private activedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private rs_service: ServiceReportService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.id_rs = this.activedRoute.snapshot.paramMap.get("id");
    this.getData();
  }

  /**
   * The function retrieves data from a server and handles different response codes accordingly.
   */
  private getData(): void {
    this.rs_service.getParts(this.id_rs).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.identifier_rs = this.response.data.rs_identifier;
          this.partidas_odv = this.response.data.no_rs_parts;
          this.partidas_estatus = this.response.data.parts_estatus;
          this.partidas_odv.forEach(partidas => {
              partidas.check = false;
           });
        }
        console.log(this.partidas_estatus)
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "Error en servidor.");
        this.loader = 2;
      }
    );
  }

  /**
   * The function validates data and sends a request to save if the list is not empty.
   */
  public validateData(): void {
    this.validator = 0;

    if (this.list.length == 0) {
      this.validator++;
      this.message += "Debe seleccionar al menos un equipo.";
    } else this.sendPeticionToSave();
  }

  private sendPeticionToSave(): void {

    this.list = this.list.sort(( a,b ) => {
      return a-b;
    }); 

    let form = new FormData();
    form.append("list", this.list);
    this.rs_service.addParts(this.id_rs, form).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_007"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else this.router.navigate(["/operations/rs/det/", this.response.data]);

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

 
 /**
  * The function "setpartida" toggles the "check" property of a partida object based on whether its id
  * is already in the "list" array.
  * @param id - The "id" parameter is the identifier of a partida that needs to be set.
  */
  public setpartida(id): void {
    const partida = this.partidas_odv.find(obj => obj.id === id);
    if (!this.list.includes(id)) {
      this.list.push(id);
      partida.check = true;
    }
    else {
      var i = this.list.indexOf(id);
      this.list.splice(i, 1);
      partida.check = false;
    }
    this.check_all = this.allSelected(this.partidas_odv);
    console.log(this.list);
  }

 /**
  * The function checks if all elements in the "partidas" array have the same value for the "check"
  * attribute.
  * @param partidas - The parameter "partidas" is an array of objects. Each object in the array has a
  * property called "check" which is a boolean value.
  * @returns a boolean value.
  */
  public allSelected(partidas): boolean {
    return partidas.every(obj => obj.check === true)
  }

  /**
   * The function `selectAllParts` selects or deselects all parts in a list based on the current state
   * of a checkbox.
   */
  public selectAllParts():void {
    if(this.check_all === false) {
      this.list = [];
      this.check_all = true;
      this.partidas_odv.forEach(allParts => {
        this.list.push(allParts.id);
        allParts.check = true;
      });
    }else {
      this.list = [];
      this.check_all = false;
      this.partidas_odv.forEach(allParts => {
        allParts.check = false;
      });
    }
    console.log(this.list);
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code, title, and
   * message using the Angular ModalService.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - The title of the modal window that will be displayed.
   * @param message - The message parameter is a string that represents the message to be displayed in
   * the modal dialog box.
   */
  private async lauchModal(
    code: string,
    title: string,
    message
  ): Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
}
