import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { VacationsService } from "src/app/services/vacations.service";

@Component({
  selector: "app-vacations-requests",
  templateUrl: "./vacations-requests.component.html",
  styleUrls: ["./vacations-requests.component.css"],
})
export class VacationsRequestsComponent implements OnInit {
  response: any = [];
  data: any = [];
  loader: number = 0;
  number_page = 0;
  pages = 0;
  solicitudes_pend = [];
  solicitudes_gest = [];

  empalme_seleccionado = {
    nombre:'',
    apellido:'',
    area:'',
    empalmes:[],
    fecha_inicio:'',
    fecha_fin:'',
    fecha_solicitud:'',
    fechas_vacaciones:'',
    req_estatus:'',
    req_id:'',
    user_id:''
  };

  //Gestionar Fechas
  fecha_inicio;
  fecha_regreso;
  id_request;
  id_user_request: string = "";
  disabledEditing: boolean = true;
  disable_save: boolean = true;
  
  public mostrar_alerta: boolean = false;
  constructor(
    private vacationService: VacationsService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.obtenerSolicitudes();
  }

  /**
   * The function toggles the disabledEditing flag between true and false, allowing the user to either
   * edit or cancel the editing of a request detail.
   */
  public editDetail() :void {
    if (this.disabledEditing == true) {
      //editar
      this.disabledEditing = false;
    } else {
      // cancelar
      this.getRequestDetail(this.id_request);
      this.disabledEditing = true;
    }
  }

  /**
   * This function enables the ability to save.
   */
  public ableToSave() :void {
    if (this.disable_save == true) {
      this.disable_save = false;
    }
  }

  /**
   * This function retrieves vacation requests and sorts them into pending and managed categories.
   */
  public obtenerSolicitudes() :void {
    this.data = [];
    this.solicitudes_pend = [];
    this.solicitudes_gest = [];

    this.vacationService.getAllVacationRequests().subscribe(
      (res) => {
        let response: any = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004" || response.code == "0_0007")
          this.lauchModal(response.code, response.message, "");
        else {
          this.data = response.data;
          this.data.forEach((element) => {
            if (element.req_estatus == 0) {
              this.gestionarEmpalmes(element);
            } else {
              this.solicitudes_gest.push(element);
            }
          });
        }
        this.solicitudes_gest.sort((a, b) => {
          const dateA = new Date(a.fecha_solicitud);
          const dateB = new Date(b.fecha_solicitud);
          return dateB.getTime() - dateA.getTime(); // Ordenar de más reciente a más antiguo
        });
        
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * This function updates the status of a vacation request and displays a modal or alert depending on
   * the response.
   * @param id - The ID of the request to update the status for.
   * @param estatus - The "estatus" parameter is a string that represents the new status that will be
   * assigned to a vacation request identified by the "id" parameter.
   */
  public CambiarEstatusSolicitud(id, estatus) :void {
    let form_d = new FormData();
    form_d.append("id_vrequest", id);
    form_d.append("estatus", estatus);

    this.vacationService.updateStatusRquests(form_d).subscribe(
      (res) => {
        let response: any = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004" || response.code == "0_0007")
          this.lauchModal(response.code, response.message, "");
        else {
          this.solicitudGestionadaAlerta();
          this.obtenerSolicitudes();
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }
  public solicitudGestionadaAlerta() {
    this.mostrar_alerta = true;
    setTimeout(() => {
      this.mostrar_alerta = false;
    }, 5000); 
  }

  /**
   * The function retrieves details of a vacation request from a service and handles different response
   * codes accordingly.
   * @param id - The parameter "id" is a unique identifier used to retrieve details of a specific
   * vacation request.
   */
  private getRequestDetail(id) :void {
    this.vacationService.getRequestDetail(id).subscribe(
      (res) => {
        let response: any = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004" || response.code == "0_0007")
          this.lauchModal(response.code, response.message, "");
        else {
          this.data = response.data;
          this.fecha_inicio = this.data.fechas_vacaciones.substr(0, 10);
          this.fecha_regreso = this.data.fechas_vacaciones.substr(11, 10);
          this.id_request = this.data.id;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * This function sends a vacation request to the server and handles the response accordingly.
   */
  public editRequest() :void {
    let fechas = this.fecha_inicio + "/" + this.fecha_regreso;

    let form_d = new FormData();
    form_d.append("fechas", fechas);
    form_d.append("nuevo", "0");
    form_d.append("id_req", this.id_request);
    this.vacationService.sendVacationRequest(form_d).subscribe(
      (res) => {
        let response: any = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004" || response.code == "0_0007")
          this.lauchModal(response.code, response.message, "");
        else {
          alert("Solicitud Actualizada");
          this.obtenerSolicitudes();
        }

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  //EMPALMES  
  public gestionarEmpalmes(solicitud_pendiente): void {
    solicitud_pendiente.empalmes = [];
    const area_requests = this.data.filter(request => request.area === solicitud_pendiente.area);
    for(let solicitud of area_requests) {
      if( solicitud.req_id != solicitud_pendiente.req_id){
        if(this.hayEmpalme(solicitud, solicitud_pendiente)){
          this.registrarEmpalme(solicitud, solicitud_pendiente)
        }
      }
    }

    this.solicitudes_pend.push(solicitud_pendiente);
  }

  private hayEmpalme(solicitud1, solicitud2) {
    return solicitud1.fecha_inicio < solicitud2.fecha_fin &&
          solicitud2.fecha_inicio < solicitud1.fecha_fin;
  }

  private registrarEmpalme(empalme, solicitud_pendiente) {
    solicitud_pendiente.empalmes = solicitud_pendiente.empalmes || [];
    solicitud_pendiente.empalmes.push({
      nombre: `${empalme.nombre} ${empalme.apellido}`,
      inicio: empalme.fecha_inicio,
      fin: empalme.fecha_fin,
      fecha_solicitud: empalme.fecha_solicitud
    });
  } 

  public asignarEmpalme(solicitud) {
    this.empalme_seleccionado = {...solicitud };
  }

  calcularDiasEntreFechas(fecha_inicio: string, fecha_solicitud: string): boolean {
    const fecha = fecha_solicitud.split(' ');
    const date1 = new Date(fecha_inicio);
    const date2 = new Date(fecha[0]);

    const diferencia_milisegundos = date1.getTime() - date2.getTime();
    const milisegundos_dia = 1000 * 60 * 60 * 24;
    const dif_dias = diferencia_milisegundos / milisegundos_dia;
    
    if(dif_dias < 15) {
      return true;
    }else {
      return false;
    }
  }

  public formatearFecha(fecha_string: string): string {
    const fecha_array = fecha_string.split(' ');
    
    const fecha = this.parseFecha(fecha_array[0]);
    const meses_abreviados = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];
    const dia: any = fecha.getDate();
    const mes_abreviado = meses_abreviados[fecha.getMonth()];
    
    return `${dia} ${mes_abreviado}`;
  }

  public parseFecha(fecha_string) {
    const partes = fecha_string.split('-');
    const año = parseInt(partes[0]);
    const mes = parseInt(partes[1]) - 1;
    const dia = parseInt(partes[2]);

    return new Date(año, mes, dia, 12, 0, 0);
  }

  public changePageResults(page): void {
    this.number_page = page;
  }
  /**
   * This function launches a modal with a given code, title, and message using the Angular
   * ModalService.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - The title of the modal window that will be displayed.
   * @param {string} message - The message parameter is a string that represents the message to be
   * displayed in the modal.
   */
  public async lauchModal(code: string, title: string, message: string) :Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
}
