import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PromiseType } from "protractor/built/plugins";
import { generate } from "rxjs";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { RequisitionsService } from "src/app/services/requisitions.service";
import { SaleOrderService } from "src/app/services/sale-order.service";
import { environment } from "src/app/variables/enviroment";

@Component({
  selector: "app-detail-requisitions",
  templateUrl: "./detail-requisitions.component.html",
  styleUrls: ["./detail-requisitions.component.css"],
})
export class DetailRequisitionsComponent implements OnInit {
  uri: string = environment.uri;
  token = localStorage.getItem("token");
  api_key = localStorage.getItem("key");

  permmisions = JSON.parse(localStorage.getItem("permisos"));
  url_to_load = "";
  loader = 2;
  data: any = [];

  odc = null;
  identifier = "";
  notes = "";
  razon_social = "";
  attention_to = "";
  bill_to = "";
  ship_to = "";
  proveedor = "";
  contacto = "";
  creador = "";
  estatus = "";

  direccion = "";
  a_nombre_de = "";
  municipio = "";

  file: any;
  comments = [];
  files = [];
  parts = [];
  comment = "";
  file_name = "";
  total = 0;
  id_requisicion = "";
  tipo_moneda = 0;

  edit_tag = "";
  edit_desc = "";
  edit_model = "";
  edit_serie = "";
  edit_marca = "";
  edit_price = "";
  edit_quant = "";
  edit_notes = "";
  id_part = "";

  partida = 0;

  constructor(
    private activedRoute: ActivatedRoute,
    private requisitionService: RequisitionsService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.id_requisicion = this.activedRoute.snapshot.paramMap.get("id");
    this.getData();
  }

  /**
   * The function retrieves data from a requisition and assigns it to various variables.
   */
  private getData() :void {
    let response: any = [];
    this.total = 0;
    this.requisitionService.getDetailRequisition(this.id_requisicion).subscribe(
      (res) => {
        response = res;
        if (response.code != "0_005")
          this.lauchModal(response.code, response.message, "");
        else {
          this.data = response.data;
          this.estatus = this.data.general.estatus;
          this.identifier = this.data.general.folio;
          this.tipo_moneda = this.data.general.tipo_moneda;
          this.notes = this.data.general.nota;
          this.razon_social = this.data.general.razon_social;
          this.attention_to = this.data.atencion_a;

          this.odc = this.data.general.odc;
          if (this.data.bill_to != null)
            this.bill_to =
              this.data.bill_to.calle +
              " #" +
              this.data.bill_to.numero_ext +
              ", " +
              this.data.bill_to.colonia +
              ", " +
              this.data.bill_to.ciudad +
              ", " +
              this.data.bill_to.estado +
              ". CP:" +
              this.data.bill_to.codigo_postal;
          if (this.data.ship_to != null)
            this.ship_to =
              this.data.ship_to.calle +
              " #" +
              this.data.ship_to.n_exterior +
              ", " +
              this.data.ship_to.colonia +
              ", " +
              this.data.ship_to.ciudad +
              ", " +
              this.data.ship_to.estado +
              ". CP:" +
              this.data.ship_to.cp;
          this.proveedor = this.data.proveedor;

          if (this.data.atencion_a != null)
            this.contacto = this.data.atencion_a.contacto;

          this.a_nombre_de =
            this.data.general.a_nombre_de != null
              ? this.data.general.a_nombre_de
              : "S/I";

          this.direccion =
            this.data.general.direccion != null
              ? this.data.general.direccion
              : "S/I";

          this.municipio =
            this.data.general.municipio != null
              ? this.data.general.municipio
              : "S/I";

          this.creador =
            this.data.general.nombre + " " + this.data.general.apellido;
          this.parts = this.data.partidas;
          this.files = this.data.files;
          for (let i = 0; i < this.parts.length; i++) {
            this.total += this.parts[i].cantidad * this.parts[i].precio;
          }
        }
      },
      (err) => {
        this.loader = 2;
        this.lauchModal("0000x00", "Error de consulta", err.message);
        console.log(err);
      }
    ).unsubscribe;
  }

  /**
   * This function sends a request to disavow a requisition and handles the response accordingly.
   */
  public desautorizar() :void {
    let response: any = [];
    this.loader = 1;

    let form = new FormData();
    form.append("id_req", this.id_requisicion);

    this.requisitionService.disavowRequisition(form).subscribe(
      (res) => {
        response = res;
        if (response.code != "0_005")
          this.lauchModal(response.code, response.message, "");
        else {
          if (response.data == 1) {
            this.getData();
          } else
            this.lauchModal(
              response.code,
              "Ocurrió un error al desautorizar",
              ""
            );
        }
        this.loader = 2;
      },
      (err) => {
        this.loader = 2;
        this.lauchModal("0000x00", "Error de consulta", err.message);
        console.log(err);
      }
    ).unsubscribe;
  }

  /**
   * This is a private function in TypeScript that sets the status of a requisition and performs some
   * validation before doing so.
   * @param val - a string representing the new status to set for a requisition
   * @returns A boolean value.
   */
  public setEstatus(val) :boolean {
    let response: any = [];
    let permiso = null;
    if (val == "2") permiso = "162";
    if (val == "3") permiso = "164";
    if (val == "4") {
      permiso = "163";
      if (this.validarCampos()) {
        this.lauchModal(
          response.code,
          "Campos faltantes",
          "Completa los campos faltantes para continuar"
        );
        return true;
      }
    }
    this.loader = 1;
    this.requisitionService
      .setStatusRequisition(val, permiso, this.id_requisicion)
      .subscribe(
        (res) => {
          response = res;
          if (response.code != "0_005")
            this.lauchModal(response.code, response.message, "");
          else {
            if (response.data == 1) {
              val == "2" || val == "3" ? this.getData() : this.generateOdc();
            } else
              this.lauchModal(
                response.code,
                "Ocurrió un error al aplicar el estatus",
                ""
              );
          }
          this.loader = 2;
        },
        (err) => {
          this.loader = 2;
          this.lauchModal("0000x00", "Error de consulta", err.message);
          console.log(err);
        }
      ).unsubscribe;
  }

  /**
   * This function sets the ID part and updates the edit fields based on the selected index.
   * @param id - The ID of a part.
   * @param index - The index parameter is an integer that represents the position of an element in an
   * array. In this case, it is used to access a specific element in the "parts" array.
   */
  public setIdPart(id, index) :void {
    this.id_part = id;
    let info = this.parts[index];
    this.edit_quant = info.cantidad;
    this.edit_desc = info.descripcion;
    this.edit_marca = info.marca;
    this.edit_model = info.modelo;
    this.edit_serie = info.serie;
    this.edit_tag = info.tag;
    this.edit_notes = info.notas;
    this.edit_price = info.precio;
    this.partida = index;
  }

  /**
   * This function edits a part and sends a request to the server using form data.
   */
  public editPart() :void{
    this.loader = 1;
    let form = new FormData();
    form.append("cantidad", this.edit_quant);
    form.append("desc", this.edit_desc);
    form.append("marca", this.edit_marca);
    form.append("modelo", this.edit_model);
    form.append("serie", this.edit_serie);
    form.append("tag", this.edit_tag);
    form.append("notas", this.edit_notes);
    form.append("precio", this.edit_price);
    form.append("id_part", this.id_part);
    let response: any = [];
    this.requisitionService.editPartReq(form).subscribe(
      (res) => {
        response = res;
        if (response.code != "0_005")
          this.lauchModal(response.code, response.message, "");
        else {
          if (response.data == 1) this.getData();
          else this.lauchModal(response.code, "Ocurrió un error", "");
        }
        this.id_part = "";
        this.loader = 2;
      },
      (err) => {
        this.loader = 2;
        this.lauchModal("0000x00", "Error de consulta", err.message);
        console.log(err);
      }
    ).closed;
  }

  /**
   * This function deletes a part and displays a modal with a message depending on the response.
   */
  public deleteParts() :void {
    let response: any = [];
    this.loader = 1;
    this.requisitionService.deletePartReq(this.id_part).subscribe(
      (res) => {
        response = res;
        if (response.code != "0_005")
          this.lauchModal(response.code, response.message, "");
        else {
          response.data == 1
            ? this.getData()
            : this.lauchModal(
                response.code,
                "Ocurrió un error al eliminar la partida",
                ""
              );
        }
        this.id_part = "";
        this.loader = 2;
      },
      (err) => {
        this.loader = 2;
        this.lauchModal("0000x00", "Error de consulta", err.message);
        console.log(err);
      }
    );
  }

  /**
   * This function generates an ODC (Order Delivery Confirmation) by making a request to a requisition
   * service and handling the response.
   */
  private generateOdc() :void{
    let response: any = [];

    this.requisitionService
      .addOdcRequisition({ id_requisicion: this.id_requisicion })
      .subscribe(
        (res) => {
          response = res;
          if (response.code != "0_005")
            this.lauchModal(response.code, response.message, "");
          else {
            this.router.navigateByUrl(
              "/administration/sale_order/edit/" + response.data
            );
          }
        },
        (err) => {
          this.loader = 2;
          this.lauchModal("0000x00", "Error de consulta", err.message);
          console.log(err);
        }
      ).unsubscribe;
  }

  /**
   * The function sets a URL to download a document related to a requisition using a specific API key,
   * token, and module ID.
   * @param {string} idReq - idReq is a string parameter that represents the ID of a file that needs to
   * be downloaded for a requisition.
   */
  public descargarDocs(idReq: string) {
    this.url_to_load =
      this.uri +
      "get_doc_requisicion?api_key=" +
      this.api_key +
      "&token=" +
      this.token +
      "&module=60&id_archivo=" +
      idReq +
      "&id_req=" +
      this.id_requisicion;
  }

  /**
   * This function loads a file and sets its name as a property.
   * @param file - The parameter "file" is an object that represents the file input element in HTML. It
   * is passed as an argument to the "loadFile" function.
   */
  public loadFile(file) :void {
    this.file = file.target;
    this.file_name = this.file.files[0].name;
  }

  /**
   * This function saves a file to a requisition and displays a modal with the result.
   */
  public saveFile() :void {
    let result: any = [];
    this.loader = 1;

    if (this.file.files.length > 0) {
      let form = new FormData();
      form.append("file", this.file.files[0]);
      form.append("id", this.id_requisicion);

      this.requisitionService.addDocumentoToReq(form).subscribe(
        (res) => {
          result = res;
          if (
            result.code == "1_0001" ||
            result.code == "1_0002" ||
            result.code == "1_0003"
          )
            this.lauchModal(result.code, result.message, "");
          else if (result.code == "1_0004" || result.code == "0_007")
            this.lauchModal(result.code, result.message, "");
          else {
            console.log(result);
            if (result.data == 1) {
              this.file_name = "";
              this.getData();
            } else
              this.lauchModal(
                "0000x00",
                "Algo salió mal. Intenta nuevamente",
                ""
              );
          }
          this.loader = 2;
        },
        (err) => {
          this.lauchModal("0000x00", "Error de consulta", "");
          console.log(err);
          this.loader = 2;
        }
      );
    }
  }

  /**
   * The function checks if certain fields are null and returns a boolean value.
   * @returns The method `validarCampos()` is returning a boolean value. It will return `true` if any
   * of the following conditions are met: `this.attention_to` is null, `this.data.bill_to` is null, or
   * `this.data.ship_to` is null. Otherwise, it will return `false`.
   */
  private validarCampos() :boolean {
    console.log(this.attention_to);
    return (
      this.attention_to == null ||
      this.data.bill_to == null ||
      this.data.ship_to == null
    );
  }

 /**
  * This function launches a modal with a given code, title, and message using the Angular Material
  * modal service.
  * @param {string} code - a string representing some code that will be displayed in the modal
  * @param {string} title - The title of the modal that will be displayed.
  * @param message - The message parameter is likely a string that contains the content of the message
  * to be displayed in the modal.
  */
  private async lauchModal(code: string, title: string, message) :Promise<void>{
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }

  
}
