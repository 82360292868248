import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../variables/enviroment';

@Injectable({
  providedIn: 'root'
})
export class DataAnaliticsService {

  uri:string = environment.uri;
  //uri:string = "http://ngdat.ucal.com.mx/api/";

  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');
  constructor(private httpClient:HttpClient) { }

  getPercentDeclinedByMetro(reporte,initial,final){
    return this.httpClient.get(this.uri+"average_reports_declined_da?api_key="+this.api_key+"&token="+this.token+"&module=112&report="+reporte+"&initial="+initial+"&final_="+final);
  }

  getTimeToAcceptReports(){
    return this.httpClient.get(this.uri+"time_accept_reports_da?api_key="+this.api_key+"&token="+this.token+"&module=112");
  }

  getOdvData(initial,final){
    return this.httpClient.get(this.uri+"odv_data_da?api_key="+this.api_key+"&token="+this.token+"&module=112&initial="+initial+"&final_="+final);
  }

  getOdvDataXLSXByMonth(month,to_share){
    return this.httpClient.get(this.uri+"odv_data_xlsx_by_month_da?api_key="+this.api_key+"&token="+this.token+"&module=112&month="+month+"&emails="+to_share);
  }

  getODVDataXLSXByList(initial,final,to_share){
    return this.httpClient.get(this.uri+"odv_data_xlsx_by_list_da?api_key="+this.api_key+"&token="+this.token+"&module=112&initial="+initial+"&final_="+final+"&emails="+to_share);
  }

  getReportsRevisationsByUsersXLSX(initial,final){
    return this.httpClient.get(this.uri+"reports_revisation_by_users_xlsx?api_key="+this.api_key+"&token="+this.token+"&module=112&initial="+initial+"&final_="+final);
  }

}
