import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { IndicatorService } from "src/app/services/indicator.service";
import { IMarathon } from "src/app/services/models/IMarathon";
@Component({
  selector: "app-add-marathon",
  templateUrl: "./add-marathon.component.html",
  styleUrls: ["./add-marathon.component.css"],
})
export class AddMarathonComponent implements OnInit {
  loader: number = 0;
  response: any = [];
  identifier: string = "";
  date_start: any = "";
  date_end: any = "";

  constructor(
    private indicators_service: IndicatorService,
    private modalHelper: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {}

  /**
   * This function saves marathon data and handles the response accordingly.
   */
  private saveRegister() :void {
    let data: IMarathon = {
      identifier: this.identifier,
      start: this.date_start,
      end: this.date_end,
    };
    this.loader = 1;
    this.indicators_service.saveMarathon(data).subscribe(
      (res) => {
        this.loader = 0;
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message);
        else
          this.router.navigate(["/programmers/general_indicators/marathons"]);
      },
      (err) => {
        this.loader = 0;
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
      }
    );
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code and title using a
   * modal helper.
   * @param {string} code - A string representing some code that will be displayed in the modal window.
   * @param {string} title - The title parameter is a string that represents the title of the modal
   * that will be launched. It will be displayed at the top of the modal to provide context to the
   * user.
   */
  private async lauchModal(code: string, title: string) :Promise<void> {
    const modalRef = await this.modalHelper.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }
}
