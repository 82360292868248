import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { OrdenVentaService } from "src/app/services/orden-venta.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-send-email-odv",
  templateUrl: "./send-email-odv.component.html",
  styleUrls: ["./send-email-odv.component.css"],
})
export class SendEmailOdvComponent implements OnInit {
  loader: number = 0;
  userResult: any = [];
  id_odv: string = "";
  response: any = [];
  data: any = [];
  titulo: string = "";
  mensaje: string = "";
  correos: string = "";
  correoarray: string[] = [];
  showingtableusers: boolean = true;
  etiquetaCorreosGrupos = "";

  constructor(
    private userService: UserService,
    private activedRoute: ActivatedRoute,
    private router: Router,
    private odvService: OrdenVentaService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.id_odv = this.activedRoute.snapshot.paramMap.get("id_odv");
    this.getDetailODV();
    this.getUsers();
  }

  /**
   * The function sets a group label and hides a table of users while populating an array of email
   * addresses.
   */
  public setMtyGroup() : void {
    this.showingtableusers = false;
    this.correoarray = [
      "roberto.benitez@etalonet.com",
      "alfonso.guerra@etalonet.com",
      "mario.ramirez@etalonet.com",
      "adrian.zepahua@etalonet.com",
      "miriam.rocha@etalonet.com",
      "lesquivel@etalonet.com",
      "calidad@etalonet.com", 
      "jessica.murillo@etalonet.com",
      "ventas@etalonet.com",
      "jose.porras@etalonet.com"
    ];
    this.setLabelGroups();
  }

  /**
   * The function sets a group label and an array of email addresses for the San Luis group and hides a
   * table of users.
   */
  public setSanLuisGroup() : void{
    this.showingtableusers = false;
    this.correoarray = [
      "roberto.benitez@etalonet.com",
      "alfonso.guerra@etalonet.com",
      "adrian.zepahua@etalonet.com",
      "miriam.rocha@etalonet.com",
      "lesquivel@etalonet.com",
      "mario.ramirez@etalonet.com",
      "jessica.murillo@etalonet.com",
      "calidad@etalonet.com",
      "leonel.hernandez@etalonet.com"
    ];
    this.setLabelGroups();
  }
  /**
   * The function sets an array of email addresses and calls another function to set label groups.
   */
  public setMBGroup() :void{
    this.showingtableusers = false;
    this.correoarray = [
      "roberto.benitez@etalonet.com",
      "alfonso.guerra@etalonet.com",
      "mario.ramirez@etalonet.com",
      "adrian.zepahua@etalonet.com",
      "miriam.rocha@etalonet.com",
      "lesquivel@etalonet.com",
      "calidad@etalonet.com",
      "luis.martinez@etalonet.com",
      "juan.moreno@etalonet.com"
    ];
    this.setLabelGroups();
  }

  /**
   * The function sets an array of email addresses and calls another function to set label groups.
   */
  public setRamosGroup():void {
    this.showingtableusers = false;
    this.correoarray = [
      "roberto.benitez@etalonet.com",
      "adrian.zepahua@etalonet.com",
      "alfonso.guerra@etalonet.com",
      "lesquivel@etalonet.com",
      "mario.ramirez@etalonet.com",
    ];
    this.setLabelGroups();
  }

  /**
   * This function sets a label for groups of email addresses.
   */
  private setLabelGroups():void {
    this.etiquetaCorreosGrupos = "";
    this.correoarray.forEach((element) => {
      if (this.etiquetaCorreosGrupos == "") {
        this.etiquetaCorreosGrupos = element;
      } else {
        this.etiquetaCorreosGrupos = this.etiquetaCorreosGrupos + "," + element;
      }
    });
  }

  /**
   * This function retrieves details of an order of sale and displays them in a modal window.
   */
  private getDetailODV() :void {
    this.loader = 1;
    this.odvService.getODVDetail(this.id_odv).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.data = this.response.data;
          this.titulo =
            "Información de Apertura de Orden de Venta " +
            this.data.identificador;
          this.mensaje =
            "Por medio del presente se comunica la apertura de la Orden de Venta " +
            this.data.identificador +
            " del cliente " +
            this.data.client_data.cliente +
            ". Por favor no contestar a este correo. \r\n\r\nNOTAS: " +
            this.data.notas;
        }
        this.loader = 0;
      },
      (err) => {
        this.loader = 0;
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
      }
    );
  }

  /**
   * This function retrieves all users with an ID of 8 and sets the result to a variable while also
   * handling any errors.
   */
  private getUsers(): void {
    this.loader = 1;
    this.userService.getAllUsers("8").subscribe(
      (res) => {
        this.userResult = res;
        this.loader = 0;
      },
      (err) => {
        this.loader = 0;
        console.log(err);
      }
    );
  }

  /**
   * The function adds or removes an email from an array based on whether it already exists in the
   * array or not.
   * @param correo - The parameter "correo" is a string representing an email address that is being
   * added to or removed from an array of email addresses.
   */
  public agregarCorreo(correo) : void {
    if (this.correoarray.length > 0) {
      let contador: number;
      let bandera: boolean = false;
      let posicioneliminar: number;
      for (contador = 0; contador < this.correoarray.length; contador++) {
        if (this.correoarray[contador] == correo) {
          bandera = true;
          posicioneliminar = contador;
        }
      }

      if (!bandera) {
        this.correoarray.push(correo);
      } else {
        this.correoarray.splice(posicioneliminar, 1);
      }
    } else {
      this.correoarray.push(correo);
    }
  }

  /**
   * The function converts an array of email addresses to a comma-separated string.
   */
  private pasarAStringArray() :void{
    this.correoarray.forEach((element) => {
      if (this.correos == "") {
        this.correos = element;
      } else {
        this.correos = this.correos + "," + element;
      }
    });
  }

  /**
   * This function sends an email using data from an odvService and navigates to a commercial/odv page
   * upon success.
   */
  public sendEmail() :void {
    this.pasarAStringArray();
    this.odvService
      .sendEmail(
        this.titulo,
        this.mensaje,
        this.correos,
        this.id_odv,
        this.data.identificador
      )
      .subscribe(
        (res) => {
          this.router.navigate(["/commercial/odv"]);
        },
        (err) => {
          console.log(err);
        }
      );
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code and title using
   * the Angular Material modal service.
   * @param {string} code - The code parameter is a string that represents the code that will be
   * displayed in the modal.
   * @param {string} title - The title parameter is a string that represents the title of the modal
   * that will be launched.
   */
  private async lauchModal(code: string, title: string) :Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }
}
