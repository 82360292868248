import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { CustomerService } from "src/app/services/customer.service";
import { IAddress } from "src/app/services/models/IAddress";

@Component({
  selector: "app-add-address",
  templateUrl: "./add-address.component.html",
  styleUrls: ["./add-address.component.css"],
})
export class AddAddressComponent implements OnInit {
  id_customer: string = "";
  razondir: string = "";
  calle: string = "";
  colonia: string = "";
  numero: string = "";
  ciudad: string = "";
  estado: string = "";
  cp: string = "";
  result: any;

  constructor(
    private router: Router,
    private activedRoute: ActivatedRoute,
    private customerService: CustomerService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.id_customer = this.activedRoute.snapshot.paramMap.get("idcliente");
  }

  /**
   * This function posts a new address for a customer using data provided by the user and sends a
   * request to the server to save the information.
   */
  public postDireccion(): void {
    let iDireccion: IAddress = {
      id_cliente: this.id_customer,
      nombre_direccion: this.razondir,
      calle: this.calle,
      colonia: this.colonia,
      n_exterior: this.numero,
      ciudad: this.ciudad,
      estado: this.estado,
      cp: this.cp,
      nueva: "1",
    };

    this.customerService.postDireccion(iDireccion).subscribe(
      (res) => {
        console.log(res);
        this.result = res;
        if (this.result.data > 0) {
          this.router.navigate(["programmers/customer/", this.id_customer]);
        } else {
          this.lauchModal(
            this.result.code,
            "Ocurrió un error en el servidor, intenta mas tarde."
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code and title using
   * the ModalComponent.
   * @param {string} code - a string representing the code that will be displayed in the modal window.
   * @param {string} title - The title parameter is a string that represents the title of the modal
   * that will be launched. It will be displayed at the top of the modal to provide context to the
   * user.
   */
  private async lauchModal(code: string, title: string): Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }
}
