import { HttpClient } from '@angular/common/http';
import { CloneVisitor } from '@angular/compiler/src/i18n/i18n_ast';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { GeneratorReportsService } from 'src/app/services/generator-reports.service';
import { IInforme } from 'src/app/services/models/IInformes';
import { environment } from 'src/app/variables/enviroment';

@Component({
  selector: 'app-new-report',
  templateUrl: './new-report.component.html',
  styleUrls: ['./new-report.component.css']
})
export class NewReportComponent implements OnInit {
  uri:string = environment.uri;
  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');
  url_to_load = '';

  columnas:any=[];
  finalValores:any=[];
  idsEquipos:any=[];
  idShet:string='';
  idpatron:any=[];

  equiposOdv:any=[]

  loader:number = 1;
  response:any = [];
  data:any=[];
  pdfInformes:any=[];
  clic:string;

  ibc_1:number;
  ibc_2:number;
  ibc_3:number;
  ibc_4:number;

  lugar:any = "sitio";
  temp:any;
  unidad:any;
  format:any = "AAA-MM-DD";
  fecha:any;
  modelo:string = "";
  marca:string = "";

  asignado:number=2;

  constructor(
    private datos: ActivatedRoute, 
    private router:Router, 
    public conn: HttpClient,
    private generadorReports:GeneratorReportsService, 
    private modalService:NgbModal) { }

  ngOnInit(): void {
    let datos=this.datos.snapshot.paramMap.get('id_equipos')
    this.idsEquipos.push(datos.split(','));
    this.idShet = this.datos.snapshot.paramMap.get('id_hoja');
    this.idpatron = this.datos.snapshot.paramMap.get('id_patron');

    this.getData();
  }

  getData(){
    this.generadorReports.verPlantilla(this.idShet,this.idsEquipos).subscribe(
      res=>{
        this.response = res;
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
        else this.data = this.response.data;

        this.equiposOdv = this.data.equipos;

        for (let i = 0; i < this.data[1].length; i++) {
          let colums:any=[];
              colums["pos"]=i;
              colums["column"]=this.data[1][i].columna;
              colums["tabla"]=this.data[1][i].tabla;
          this.columnas.push(colums);
        }

        this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        this.loader = 2;
      }
    )

   
  }

  obtenerDatos(){
    this.loader = 1;
    var min_1 = 1; var min_2 = 3; var min_3 = 5; var min_4 = 7;
    var max_1 = 3; var max_2 = 5; var max_3 = 7; var max_4 = 9;

    var inTol_1=""; var inTol_2=""; var inTol_3=""; var inTol_4=""; 

    var informes=[];

    if(this.temp == null || this.unidad == null || this.fecha == null) {
      alert("Campos vacios"); this.loader = 2;
    } else {
      for (let i = 0; i < this.equiposOdv.length; i++) {
        let listIbc = []; let listInTol = [];
        let equipo_id = this.equiposOdv[i].idEquipo;

        this.ibc_1 = parseFloat((document.getElementById(equipo_id+"-0") as HTMLInputElement).value);
        listIbc.push(this.ibc_1);

        this.ibc_2 = parseFloat((document.getElementById(equipo_id+"-1") as HTMLInputElement).value);
        listIbc.push(this.ibc_2);

        this.ibc_3 = parseFloat((document.getElementById(equipo_id+"-2") as HTMLInputElement).value);
        listIbc.push(this.ibc_3);

        this.ibc_4 = parseFloat((document.getElementById(equipo_id+"-3") as HTMLInputElement).value);
        listIbc.push(this.ibc_4);

        this.ibc_1 < min_1 ? inTol_1 = 'No cumple' : this.ibc_1 > max_1 ? inTol_1 = 'No cumple'
        : inTol_1 = 'Si cumple';

        this.ibc_2 < min_2 ? inTol_2 = 'No cumple' : this.ibc_2 > max_2 ? inTol_2 = 'No cumple'
        : inTol_2 = 'Si cumple';

        this.ibc_3 < min_3 ? inTol_3 = 'No cumple' : this.ibc_3 > max_3 ? inTol_3 = 'No cumple'
        : inTol_3 = 'Si cumple';

        this.ibc_4 < min_4 ? inTol_4 = 'No cumple' : this.ibc_4 > max_4 ? inTol_4 = 'No cumple'
        : inTol_4 = 'Si cumple';

        (document.getElementById(equipo_id+"y0") as HTMLInputElement).setAttribute("value",inTol_1);
        listInTol.push(inTol_1);

        (document.getElementById(equipo_id+"y1") as HTMLInputElement).setAttribute("value",inTol_2);
        listInTol.push(inTol_2);

        (document.getElementById(equipo_id+"y2") as HTMLInputElement).setAttribute("value",inTol_3);
        listInTol.push(inTol_3);

        (document.getElementById(equipo_id+"y3") as HTMLInputElement).setAttribute("value",inTol_4);
        listInTol.push(inTol_4);
      
        let global = [];
        global.push(this.temp);
        global.push(this.unidad);
        global.push(this.format);
        global.push(this.fecha);
        global.push(this.lugar);
        global.push(this.marca);
        global.push(this.modelo);
        
        let clic = this.uri+"get_equipos_pdf?api_key="+this.api_key+"&token="+this.token+
              "&module=2&id_plantilla="+this.idShet+"&libc="+listIbc+"&tol="+listInTol+"&globales="+global+
              "&id_equipo="+equipo_id+"&patron_id="+this.idpatron;

        let a=[];
        
        a[0] = clic;
        a[1]=this.equiposOdv[i].identificador+this.equiposOdv[i].partida;
        informes.push(a);
      }
      
      this.pdfInformes = informes;
      this.loader = 2;
    }
    
  }

  asignarInforme(url_pdf){
    this.loader = 1;
    url_pdf = url_pdf[0];


    this.conn.get(url_pdf+"&asignar="+"asignar").subscribe(
      res => {
        this.response = res;
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
        else this.data = this.response.data;
        this.asignado=1;
        alert("Asignado correctamente");
        this.loader = 2;
      },
      error=>{
        console.log(error);
        alert("Error de petición");
        this.loader = 2;
      }
    );
  }

  lauchModal(code:string,title:string){
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

}
