import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { GeneratorReportsService } from "src/app/services/generator-reports.service";
import { environment } from "src/app/variables/enviroment";
import { SwPush } from "@angular/service-worker";
import { NotificationsService } from "src/app/services/notifications.service";
import { initializeApp } from "firebase/app";
import { title } from "process";

@Component({
  selector: "app-generatorreport",
  templateUrl: "./generatorreport.component.html",
  styleUrls: ["./generatorreport.component.css"],
})
export class GeneratorreportComponent implements OnInit {
  uri: string = environment.uri;
  token = localStorage.getItem("token");
  api_key = localStorage.getItem("key");

  loader: number = 1;
  response: any = [];
  respuestaId: any = [];

  respPatron: any = [];
  dataEquipoId: any = [];

  dataEquipoSearch: any = [];

  equipoTag: string = "";
  hojaDatos: string = "";

  tagPatron: string = "";
  data: any = [];
  hoja: any = [];
  patronRequest: any = [];
  listEquipos: any = [];
  listHoja: any = [];
  listEquipoP: any = [];
  dataOdv: any = [];
  idequipo: string = "";

  final_odv: any = [];

  check_list: any = [];
  message = "";
  vigencia_patron: boolean = true;

  notificacion: any;
  private readonly VAPID_PRIVATE_KEY =
    "f0I3f0t5_pED64hqIUZP0y8tWtz6Hoi3AlWZFaP83Do";
  public readonly VAPID_PUBLIC_KEY =
    "BPY-OsP6hLCYPQDaao_6h-MjsjY3ZRLiyJjZG7gsDXLt6EKgY1tMITWgktDocd9hs02sprZPchNzaZmAdGS3RXs";

  constructor(
    private router: Router,
    private generadorReports: GeneratorReportsService,
    private modalService: NgbModal,
    private swPush: SwPush,
    private notificationsApi: NotificationsService
  ) {}

  ngOnInit(): void {
    this.loader = 2;
    this.subscribeToNotification();
  }

  subscribeToNotification() {
    let request: any = [];

    this.swPush
      .requestSubscription({
        serverPublicKey: this.VAPID_PUBLIC_KEY,
      })
      .then((res) => {
        this.notificacion = res;
        const token_notifications = JSON.parse(JSON.stringify(res));
        console.log(token_notifications);
        console.log(token_notifications.endpoint);
        console.log(token_notifications.expirationTime);
        console.log(token_notifications.keys);

        this.notificationsApi
          .saveToken //token_notifications
          ()
          .subscribe(
            (sub) => {
              request = sub;
              console.log(request.data);
            },
            (err) => {
              console.log("ERR", err);
            }
          );
      })
      .catch((err) => {
        this.notificacion = err;
        console.log("UPS :( ", err);
      });
  }

  buscarEquipo() {
    this.loader = 1;
    this.generadorReports.buscarTags("200", this.equipoTag).subscribe(
      (res) => {
        this.response = res;

        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_0007"
        )
          this.lauchModal(this.response.code, this.response.message);
        else this.data = this.response.data;

        if (this.listEquipos.length > 0) {
          //this.getEquiposChecked();
          for (var i = 0; i < this.listEquipos.length; i++) {
            this.data.push(this.listEquipos[i]);
          }
          // console.log(this.listEquipos)
        }

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");

        this.loader = 2;
      }
    );
  }

  buscarHoja() {
    this.loader = 1;
    this.generadorReports.buscarHoja(this.hojaDatos).subscribe(
      (res) => {
        this.response = res;

        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_0007"
        )
          this.lauchModal(this.response.code, this.response.message);
        else this.hoja = this.response.data;

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");

        this.loader = 2;
      }
    );
  }

  buscarOdv() {
    this.loader = 1;
    this.generadorReports.buscarOdvTest().subscribe(
      (res) => {
        this.response = res;

        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_0007"
        )
          this.lauchModal(this.response.code, this.response.message);
        else console.log(this.response.data);

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        this.loader = 2;
      }
    );
  }

  buscarPatron() {
    this.loader = 1;
    this.generadorReports.buscarPatron(this.tagPatron).subscribe(
      (res) => {
        this.respPatron = res;

        if (
          this.respPatron.code == "1_0001" ||
          this.respPatron.code == "1_0002" ||
          this.respPatron.code == "1_0003"
        )
          this.lauchModal(this.respPatron.code, this.respPatron.message);
        else if (
          this.respPatron.code == "1_0004" ||
          this.respPatron.code == "0_0007"
        )
          this.lauchModal(this.respPatron.code, this.respPatron.message);
        else {
          this.patronRequest = this.respPatron.data;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");

        this.loader = 2;
      }
    );
  }

  setEquipo(idEquipoCheck: string, z: number) {
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].id == idEquipoCheck) {
        if (this.listEquipos.length < 1) {
          this.listEquipos.push(this.data[i]);
        } else {
          for (let b = 0; b < this.listEquipos.length; b++) {
            if (this.listEquipos[b].id == idEquipoCheck) {
              this.listEquipos.splice(b, 1);
            } else {
              return this.listEquipos.push(this.data[i]);
            }
          }
        }
      }
    }
  }

  setHoja(idHojaCheck: string) {
    if (!this.listHoja.includes(idHojaCheck)) this.listHoja.push(idHojaCheck);
    else {
      var x = this.listHoja.indexOf(idHojaCheck);
      this.listHoja.splice(x, 1);
    }
  }

  setPatron(idPatronCheck: string, vigente: number) {
    this.vigencia_patron = true;
    if (vigente == 2) {
      this.message = "Equipo con informe vencido";
      this.vigencia_patron = false;
    }
    if (vigente == 3) {
      this.message = "Equipo sin informe";
      this.vigencia_patron = false;
    }

    if (!this.listEquipoP.includes(idPatronCheck)) {
      this.listEquipoP.push(idPatronCheck);
    } else {
      var g = this.listEquipoP.indexOf(idPatronCheck);
      this.listEquipoP.splice(g, 1);
    }
  }

  evaluarOdvActiva() {
    this.loader = 1;
    let ids: any = [];
    for (let i = 0; i < this.listEquipos.length; i++) {
      ids.push(this.listEquipos[i].id);
    }
    this.generadorReports.evaluarOdv(ids).subscribe(
      (res) => {
        this.response = res;

        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_0007"
        )
          this.lauchModal(this.response.code, this.response.message);
        else this.dataOdv = this.response.data;

        //console.log(this.dataOdv);
        let aux: any = [];
        for (let f = 0; f < this.dataOdv.length; f++) {
          if (this.dataOdv[f].estatus != 5) {
            aux.push(this.dataOdv[f]);
            if (aux.length == ids.length) {
              switch (this.listHoja[0]) {
                case 1:
                  this.router.navigate([
                    "operations/reportmaker/generator/new_report/" +
                      ids +
                      "/" +
                      this.listHoja +
                      "/" +
                      this.listEquipoP,
                  ]);
                  break;
                case 2:
                  this.router.navigate([
                    "operations/reportmaker/generator/termohigrometro/" +
                      ids +
                      "/" +
                      this.listHoja +
                      "/" +
                      this.listEquipoP,
                  ]);
                  break;
                case 3:
                  this.router.navigate([
                    "operations/reportmaker/generator/transmisor_temp_20/" +
                      ids +
                      "/" +
                      this.listHoja +
                      "/" +
                      this.listEquipoP,
                  ]);
                  break;
                case 4:
                  this.router.navigate([
                    "operations/reportmaker/generator/transmisor_temp_120/" +
                      ids +
                      "/" +
                      this.listHoja +
                      "/" +
                      this.listEquipoP,
                  ]);
                  break;
                case 5:
                  this.router.navigate([
                    "operations/reportmaker/generator/medidor_ph/" +
                      ids +
                      "/" +
                      this.listHoja +
                      "/" +
                      this.listEquipoP,
                  ]);
                  break;
                case 6:
                  this.router.navigate([
                    "operations/reportmaker/generator/termometro_economico/" +
                      ids +
                      "/" +
                      this.listHoja +
                      "/" +
                      this.listEquipoP,
                  ]);
                  break;
                case 7:
                  this.router.navigate([
                    "operations/reportmaker/generator/termohigrometro_economico/" +
                      ids +
                      "/" +
                      this.listHoja +
                      "/" +
                      this.listEquipoP,
                  ]);
                  break;
                case 8:
                  this.router.navigate([
                    "operations/reportmaker/generator/bascula_masas/" +
                      ids +
                      "/" +
                      this.listHoja +
                      "/" +
                      this.listEquipoP,
                  ]);
                  break;
                case 9:
                  this.router.navigate([
                    "operations/reportmaker/generator/volumen_01/" +
                      ids +
                      "/" +
                      this.listHoja +
                      "/" +
                      this.listEquipoP,
                  ]);
                  break;
                case 10:
                  this.router.navigate([
                    "operations/reportmaker/generator/micrometro/" +
                      ids +
                      "/" +
                      this.listHoja +
                      "/" +
                      this.listEquipoP,
                  ]);
                  break;
                case 11:
                  this.router.navigate([
                    "operations/reportmaker/generator/escantillon/" +
                      ids +
                      "/" +
                      this.listHoja +
                      "/" +
                      this.listEquipoP,
                  ]);
                  break;
                case 13:
                  this.router.navigate([
                    "operations/reportmaker/generator/manometro/" +
                      ids +
                      "/" +
                      this.listHoja +
                      "/" +
                      this.listEquipoP,
                  ]);
                  break;
                default:
                  this.router.navigate([
                    "operations/reportmaker/generator/data_sheet/" +
                      ids +
                      "/" +
                      this.listHoja +
                      "/" +
                      this.listEquipoP,
                  ]);
                  break;
              }
            }
          }
        }

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");

        this.loader = 2;
      }
    );
  }

  getEquiposChecked() {
    this.generadorReports.buscarEquiposId(this.listEquipos).subscribe(
      (res) => {
        this.respuestaId = res;

        if (
          this.respuestaId.code == "1_0001" ||
          this.respuestaId.code == "1_0002" ||
          this.respuestaId.code == "1_0003"
        )
          this.lauchModal(this.respuestaId.code, this.respuestaId.message);
        else if (
          this.respuestaId.code == "1_0004" ||
          this.respuestaId.code == "0_0007"
        )
          this.lauchModal(this.respuestaId.code, this.respuestaId.message);
        else this.dataEquipoId.push(this.respuestaId.data);

        for (let w = 0; w < this.dataEquipoId.length; w++) {
          let imprimir = this.dataEquipoId[w];

          for (let d = 0; d < imprimir.length; d++) {
            let aux: any = [];
            aux["id"] = imprimir[d].id;
            aux["tag"] = imprimir[d].tag;
            aux["serie"] = imprimir[d].serie;
            aux["marca"] = imprimir[d].marca;
            aux["modelo"] = imprimir[d].modelo;

            this.data.push(aux);
          }
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        this.loader = 2;
      }
    );
  }

  lauchModal(code: string, title: string) {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

  descargarDefault() {
    window.open(
      this.uri +
        "download_default?api_key=" +
        this.api_key +
        "&token=" +
        this.token +
        "&module=2"
    );

    //window.open(this.uri+"download_almacen_pdf?api_key="+this.api_key+"&token="+this.token+"&module=2");
  }
}
