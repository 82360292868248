import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { OrdenVentaService } from 'src/app/services/orden-venta.service';
import { QuotationService } from 'src/app/services/quotation.service';

@Component({
  selector: 'app-email-notification',
  templateUrl: './email-notification.component.html',
  styleUrls: ['./email-notification.component.css']
})
export class EmailNotificationComponent implements OnInit {
  id_cot = "";
  loader = 2;
  response: any = [];
  data: any = [];

  mensaje:string='Permítanos apoyarle con el servicio de calibración de sus instrumentos. \n\n'+
  'Nos estamos comunicando con ustedes para recordarles la recalibración de algunos de sus equipos. '+
  'Nuestra base de datos muestra que los instrumentos listados en la notificación adjunta requieren ser calibrados en próximas fechas.'+
  ' Por tal motivo agradecemos nos permita hacerle llegar el presupuesto del servicio antes mencionado.';

  asunto:string='RV: Re-calibración de equipos';
  destinatario = '';
  sendingemail:Boolean=false;
  cotizacion_folio:string="";

  constructor(private activedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private quotationServ: QuotationService,
    private routerService: Router,
    private odvService: OrdenVentaService
    ) { }

  ngOnInit(): void {
    this.id_cot = this.activedRoute.snapshot.paramMap.get("id_cot");
    this.getData();
  }

  private getData() :void{

    this.loader = 1;
    this.odvService.getInfoCotEmail(this.id_cot).subscribe(
      (res) => {
        this.response = res;

        if (this.response.code != "0_005")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.data = this.response.data;
          this.cotizacion_folio = this.data.identificador;
          this.destinatario = this.data.correo;
        }

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  public sendEmail() :void{
    this.sendingemail = true;
    let form = new FormData();
    form.append('id_cot',this.id_cot);
    form.append('destinatario',this.destinatario);
    form.append('asunto',this.asunto);
    form.append('mensaje',this.mensaje);

    this.odvService.sendEmailCotNotification(form).subscribe(
      (res) => {
        this.response = res;

        if (this.response.code != "0_005")
          this.lauchModal(this.response.code, this.response.message, "");
        else this.routerService.navigateByUrl(
          "/commercial/notifications"
        );

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    ).unsubscribe;


  }

  /**
   * This is a private asynchronous function that launches a modal with a given code, title, and
   * message using the Angular ModalService.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - The title of the modal window that will be displayed.
   * @param message - The message parameter is a string that represents the message to be displayed in
   * the modal dialog box.
   */
  private async lauchModal(
    code: string,
    title: string,
    message
  ): Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
}
