import { ConditionalExpr } from "@angular/compiler";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { SaleOrderService } from "src/app/services/sale-order.service";
import { environment } from "src/app/variables/enviroment";

@Component({
  selector: "app-detail-sale-order",
  templateUrl: "./detail-sale-order.component.html",
  styleUrls: ["./detail-sale-order.component.css"],
})
export class DetailSaleOrderComponent implements OnInit {
  uri: string = environment.uri;
  token = localStorage.getItem("token");
  api_key = localStorage.getItem("key");

  url_to_load = "";
  aux: number = 0;

  loader: number = 1;
  id_odc = "";
  parts: any = [];
  comments: any = [];
  response: any = [];
  files: any = [];
  data: any = 0;
  subtotal = 0;
  iva = 0;
  iva_ret = 0;
  total = 0;
  estatus_info = "...";
  permmisions = JSON.parse(localStorage.getItem("permisos"));
  comment = "";
  file: any;
  file_name = "";

  availableForBill: boolean = false;
  parts_to_bill: any = [];
  bill_to_send = "";
  tipo_concepto = 0;

  odcs: any = [];
  cont: number = 0;
  odc_ant: number = 0;
  odc_prox: number = 0;

  url_to_download = "";
  @ViewChild("btn_download_pdf") divView: ElementRef;
  constructor(
    private activedRoute: ActivatedRoute,
    private saleOrderService: SaleOrderService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.id_odc = this.activedRoute.snapshot.paramMap.get("id");
    this.habilitarDescarga();
    this.getData();
  }

  /**
   * The function retrieves data from a service and performs calculations on the data to display
   * information about an order.
   */
  private getData() :void {
    this.loader = 1;
    this.saleOrderService.getDetailODC(this.id_odc).subscribe(
      (res) => {
        this.response = res;
        if (this.response.code != "0_005")
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.data = this.response.data;
          this.tipo_concepto = this.data.concept_odc;
          this.parts = this.data.parts;
          this.comments = this.data.comments;
          this.files = this.data.files;
          this.subtotal = 0;
          this.iva = 0;
          this.iva_ret = 0;
          this.total = 0;
          for (var i = 0; i < this.parts.length; i++) {
            this.subtotal += this.parts[i].precio * this.parts[i].cantidad;
          }
          this.iva = this.subtotal * 0.16;
          this.total = this.subtotal + this.iva;
          this.iva_ret = this.subtotal * 0.06;
          if (this.data.iva_ret != 0) {
            this.total = this.total - this.iva_ret;
          }
          if (this.data.estatus == 1) this.estatus_info = "Abierta";
          else if (this.data.estatus == 2) this.estatus_info = "Autorizada";
          else if (this.data.estatus == 3) this.estatus_info = "Enviada";
          else if (this.data.estatus == 5) this.estatus_info = "Facturada";
          else if (this.data.estatus == 6) this.estatus_info = "Cancelada";
          else if (this.data.estatus == 6) this.estatus_info = "Aprobada";
        }

        this.odc_ant = parseInt(this.id_odc) - 1;
        this.odc_prox = parseInt(this.id_odc) + 1;
        let aux_odcs = [this.odc_ant, this.odc_prox];
        this.cont = 0;
        this.verOdcs(aux_odcs);

        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * This function sends a request to authorize an ODC and handles the response accordingly.
   */
  public sendPeticionToAuthorize() :void {
    this.loader = 1;
    this.saleOrderService.authorizeODC(this.id_odc).subscribe(
      (res) => {
        this.response = res;
        if (this.response.code != "0_005" || this.response.data == 0)
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.getData();
        }
        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * This function sends a petition to approve an ODC and handles the response accordingly.
   */
  public sendPetitionApprove() :void {
    this.loader = 1;
    this.saleOrderService.approveOdc(this.id_odc).subscribe(
      (res) => {
        this.response = res;
        if (this.response.code != "0_005" || this.response.data == 0)
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.getData();
        }
        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * This function sends a request to unauthorize an ODC and handles the response accordingly.
   */
  public sendPeticionToUnauthorize() :void{
    this.loader = 1;
    this.saleOrderService.unauthorizeODC(this.id_odc).subscribe(
      (res) => {
        this.response = res;
        if (this.response.code != "0_005" || this.response.data == 0)
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.getData();
        }
        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * This function sends a request to cancel an ODC and handles the response accordingly.
   */
  public sendPeticionToCancel() :void  {
    this.loader = 1;
    this.saleOrderService.cancelODC(this.id_odc).subscribe(
      (res) => {
        this.response = res;
        if (this.response.code != "0_005" || this.response.data == 0)
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.getData();
        }
        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * This function saves a comment for an ODC (Order Delivery Confirmation) and handles the response
   * accordingly.
   */
  public saveCommentODC() :void {
    this.loader = 1;
    let form = new FormData();
    form.append("id", this.id_odc);
    form.append("comment", this.comment);

    this.comment = "";
    this.saleOrderService.saveCommentODC(form).subscribe(
      (res) => {
        if (this.response.code != "0_005")
          this.lauchModal(this.response.code, this.response.message);
        else this.getData();

        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * This function loads a file and sets its name as a property.
   * @param value - The parameter "value" is an event object that is passed as an argument to the
   * function when it is called. It contains information about the event that triggered the function,
   * such as the target element that was interacted with. In this case, it is likely an event object
   * related to a file input
   */
  public loadFile(value) :void  {
    this.file = value.target;
    this.file_name = this.file.files[0].name;
  }

  /**
   * This function saves a file by sending it to a server and updating the UI accordingly.
   */
  public saveFile() :void {
    let result: any = [];
    this.loader = 1;
    if (this.file.files.length > 0) {
      let form = new FormData();
      form.append("file", this.file.files[0]);
      form.append("id", this.id_odc);

      this.saleOrderService.addDocumentToODC(form).subscribe(
        (res) => {
          result = res;
          if (result.code != "0_005")
            this.lauchModal(this.response.code, this.response.message);
          else {
            this.file_name = "";
            this.getData();
          }
          this.loader = 2;
        },
        (err) => {
          this.lauchModal("0000x00", "Error de consulta");
          console.log(err);
          this.loader = 2;
        }
      );
    }
  }

  /**
   * This function changes the status of a sale order to "send" and displays a modal with a message
   * based on the response received from the server.
   */
  public changeStatusToSend() :void {
    this.loader = 1;
    this.saleOrderService.changeEstatusToSend(this.id_odc).subscribe(
      (res) => {
        this.response = res;
        if (this.response.code != "0_005" || this.response.data == 0)
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.getData();
        }
        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * This function toggles the availability for billing and clears the bill to send variable.
   */
  public availableForBilling() :void {
    this.bill_to_send = "";
    this.availableForBill = !this.availableForBill;
  }

  /**
   * This function adds or removes a part from a bill depending on whether it is already included or
   * not.
   * @param id_part - The parameter "id_part" is a variable representing the ID of a part that is being
   * added or removed from a bill.
   */
  public add_removeToBill(id_part) :void {
    if (!this.parts_to_bill.includes(id_part)) this.parts_to_bill.push(id_part);
    else {
      var i = this.parts_to_bill.indexOf(id_part);
      this.parts_to_bill.splice(i, 1);
    }
  }

  /**
   * This function sends data related to billing and parts to a server and handles the response
   * accordingly.
   */
  public sendDataToBillParts() :void {
    let form = new FormData();
    form.append("bill", this.bill_to_send);
    form.append("parts", this.parts_to_bill);
    form.append("id", this.id_odc);

    this.loader = 1;
    this.saleOrderService.billParts(form).subscribe(
      (res) => {
        this.response = res;
        if (this.response.code != "0_005" || this.response.data == 0)
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.getData();
        }
        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * This function retrieves a PDF file from a server and opens it in a new window.
   */
  public getPDF() :void {
    this.loader = 1;
    this.saleOrderService.getPDF(this.id_odc).subscribe(
      (res) => {
        if (this.response.code != "0_005" || this.response.data == 0)
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.response = res;
          this.url_to_download =
            environment.uri_server +
            "storage/public/odc_files/" +
            this.response.data;
          window.open(this.url_to_download, "_blank");
        }
        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * The function "habilitarDescarga" checks if a certain permission is included in an array and sets a
   * variable accordingly.
   */
  private habilitarDescarga() :void {
    if (this.permmisions.includes(67)) {
      this.aux = 1;
    }
  }

  /**
   * This function sets a URL to download a document with specific parameters.
   * @param {string} idDoc - A string representing the ID of a document.
   * @param {string} archivo - The "archivo" parameter is a string that represents the name of the file
   * to be downloaded.
   */
  public descargarDocs(idDoc: string, archivo: string) :void {
    this.url_to_load =
      this.uri +
      "get_doc_odc?api_key=" +
      this.api_key +
      "&token=" +
      this.token +
      "&module=31&id_archivo=" +
      idDoc +
      "&id_odv=" +
      this.id_odc +
      "&archivo=" +
      archivo;
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code and title using
   * the Angular modal service.
   * @param {string} code - a string representing the code that will be displayed in the modal window.
   * @param {string} title - The title parameter is a string that represents the title of the modal
   * that will be launched.
   */
  private async lauchModal(code: string, title: string) :Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

  /**
   * The function verOdcs makes a recursive call to retrieve data from a service and updates variables
   * based on the response.
   * @param aux_odvs - The parameter "aux_odvs" is an array that contains two elements. The first
   * element represents the starting ODC number to be queried, and the second element represents the
   * number of ODCs to be queried. This function is used to recursively query ODCs until a specific ODC
   * is found
   */
  private verOdcs(aux_odvs) :void {
    this.cont++;
    let response;
    this.saleOrderService.getOdcs(aux_odvs).subscribe(
      (res) => {
        response = res;
        if (this.response.code != "0_005")
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.odcs = response.data;
          if (this.odcs.length == 1) {
            if (this.odcs[0].id > this.id_odc) {
              aux_odvs[0] = aux_odvs[0] - 1;
              this.verOdcs(aux_odvs);
            } else {
              if (this.cont < 6) {
                aux_odvs[1] = aux_odvs[1] + 1;
                this.verOdcs(aux_odvs);
              }
            }
          } else if (this.odcs.length < 1) {
            aux_odvs[0] = aux_odvs[0] - 1;
            this.verOdcs(aux_odvs);
          }
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /* The above code is defining a private method called `reload_window` that takes an `id` parameter.
  Inside the method, the `id` value is assigned to the `id_odc` property of the class. Then, a URL
  is constructed using the `id` value and the `router.navigate` method is called to navigate to that
  URL. Finally, the `getData` method is called to retrieve data related to the `id` value. */
  public reload_window(id) :void{
    this.id_odc = id;
    let url = "/administration/sale_order/det/" + id;
    this.router.navigate([url]);
    this.getData();
  }
}
