import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {
  user_name:string='...';
  results:any = [];

  constructor(
    private userService:UserService,
    private router:Router,
    private modalService:NgbModal,) { 
    }

  ngOnInit() {
    if(!this.userService.getSession) this.router.navigate(['login']);
    else this.getUserData();
  }
  
  getUserData(){

    this.userService.getUserData().subscribe(
      res=>{
        this.results = res;
        if(this.results.code == '1_0001' || this.results.code == "1_0002" || this.results.code == '1_0003'){
          this.lauchModal(this.results.code,this.results.message);
        }
        
        else if(this.results.code == '0_005'){
          this.user_name = this.results.data.name+" "+this.results.data.last_name
          localStorage.setItem('user_name',this.user_name);
          localStorage.setItem('img_user',this.results.data.image);
          localStorage.setItem('permisos', JSON.stringify(this.results.data.modules));
        }
        else if(this.results.code == '1_0004') {
          this.lauchModal(this.results.code,this.results.message);
        }
      },
      err=>{
        this.lauchModal('0000x00','Error de consulta');
        console.log(err);
      }
    );
    
  }

  lauchModal(code:string,title:string){
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

}
