import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { InstrumentService } from 'src/app/services/instrument.service';

@Component({
  selector: 'app-expired-department',
  templateUrl: './expired-department.component.html',
  styleUrls: ['./expired-department.component.css']
})
export class ExpiredDepartmentComponent implements OnInit {
  results:any = [];
  number_page= 0;
  pages = 0;
  loader:number = 1;
  response:any = [];
  depto_data:any = [];
  permmisions = JSON.parse(localStorage.getItem('permisos'));
  id_depto = '';
  constructor(
    private activedRoute:ActivatedRoute, 
    private instument_service:InstrumentService,
    private modalService:NgbModal
  ) { }

  ngOnInit(): void {
    this.id_depto = this.activedRoute.snapshot.paramMap.get('id');
    this.peticion();
  }

  public peticion(){
    this.instument_service.getInstrumentExpiredByDepto(this.id_depto).subscribe(
      res=>{
        this.response = res;
        if(this.response.code != '0_005' )this.lauchModal(this.response.code,this.response.message)
        else {
          this.results = this.response.data.vals;
          this.pages = this.results.length;
          this.depto_data = this.response.data.depto;
        }

        this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        console.log(err);
        this.loader = 2;
      }
    )
  }

  changePage(page){this.number_page = page;}

  lauchModal(code:string,title:string){
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

}
