import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { DepotService } from "src/app/services/depot.service";
import { IDepotEdit } from "src/app/services/models/IDepotEdit";
import { IInstrument } from "src/app/services/models/IInstrument";
import { IDepotDetail } from "src/app/services/models/idepot-detail";

@Component({
  selector: "app-depot-edit",
  templateUrl: "./depot-edit.component.html",
  styleUrls: ["./depot-edit.component.css"],
})
export class DepotEditComponent implements OnInit {
  loader = 1;
  response: any = [];
  data: any = [];
  
  usuarios: any = [];

  //Variables para buscar departamentos
  select_deptos: any = [];

  detail_parts = [];
  permmisions = JSON.parse(localStorage.getItem("permisos"));

  //INICIALES
  public tipo: string = "";
  public id: string = "";

  private id_client: number = -1;
  private depot_id : number = -1;

  public partidas: any = [];
  public detalle: any = [];
  public contactos: any = [];
  public tipos_entrega: any = [];
  public almacenes: any = [];

  public equipos : any = [];
  public ver_equipos : boolean = false;

  //EDITABLE
  public almacen_edit: IDepotDetail = {
    depot_id: 0,
    depot_type: "",
    delivery_type: 0,
    info_delivery: "",
    general_notes: "",
    contact: 0,
    depot_date: "",
    depot_ubication: 0,
    dir_delivery: "",
    parcel: "",
  };
  public partidas_editadas: any = [];
  public partidas_remove: any = [];

  //ESTADOS
  public edit_parts: boolean = false;
  public part_saved: boolean = false;
  public parts_remove: boolean = false;
  public removed: boolean = false;
  public add_part: boolean = false;


  //PAQUETERIAS
  public paqueterias: any = [];
  public selected_parcel = {
    id: 0,
    nombre: "Por definir",
    digitos: 0,
    min_digitos: 0,
  };

  //Search
  public buscar = {
    client_id : 0,
    valor: '',
    search_type: ''
  }
  //NUEVA PARTIDA
  public add_new_part : boolean = false;
  public tipo_entrada:string = '';
  public new_part_depot : IDepotEdit = {
    depot_type : '',
    add_type : '',
    depot_id : 0,
  }

  //Nuevo equipo
  public brand:string = '';
  public selected_brand:string = '';
  public brand_list: any[] = [];
  public create_new_instrument: boolean = false;
  public new_instrument : IInstrument = {
    tag : '',
    model : 'S/M',
    description : '',
    serie : 'S/N',
    id_brand : 0,
    id_depto : 0
   };
   private new_instrument_default : IInstrument = {
    tag : '',
    model : 'S/M',
    description : '',
    serie : 'S/N',
    id_brand : 0,
    id_depto : 0
   };

  @ViewChild("btn_close_modal") close_modal: ElementRef;
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private depotService: DepotService,
    private activedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activedRoute.paramMap.subscribe((params) => {
      this.id = params.get("id");
      this.tipo = params.get("tipo");
    });
    this.getDetailInOut();
  }

  private getDetailInOut(): void {
    this.loader = 1;
    this.depotService.getDepotDetailInOut(this.id, this.tipo).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.data = this.response.data;
          this.partidas = this.addCheckStatus(this.data.almacen_partidas);
          this.detalle = this.data.almacen_grupo;
          this.contactos = this.data.contactos;
          this.tipos_entrega = this.data.tipos_entrega;
          this.almacenes = this.data.almacenes;
          this.paqueterias = this.data.paqueterias;

          this.id_client = this.detalle.id_cliente;
          this.depot_id = this.detalle.id;
          this.searchDepto();
          this.asignarEditarDatos();
        }

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  private asignarEditarDatos(): void {
    this.almacen_edit.depot_id = this.detalle.id;
    this.almacen_edit.depot_type = this.tipo;
    this.almacen_edit.delivery_type = this.detalle.tipo_id;
    this.almacen_edit.info_delivery = this.detalle.dato_entrega;
    this.almacen_edit.general_notes = this.detalle.comentarios;
    this.almacen_edit.contact = this.detalle.contacto_id;
    if(this.almacen_edit.depot_date!= '')
      this.almacen_edit.depot_date = this.detalle.fecha+'T'+this.detalle.hora;
    this.almacen_edit.depot_ubication = this.detalle.depto_id;
    this.almacen_edit.parcel = this.detalle.paqueteria;
    //Seleccionar paqueteria sí aplica
    if (this.almacen_edit.delivery_type == 1)
      this.selectedParcel(this.almacen_edit.parcel);

    if (this.tipo === "out")
      this.almacen_edit.dir_delivery = this.detalle.direccion_envio;
  }

  public saveDepotDetail(action): void {
    this.loader = 1;
    this.depotService.saveDepotInOutEdit(this.almacen_edit).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003" ||
          this.response.code == "1_0004"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (action == 0) {
          this.getDetailInOut();
        } else {
          this.router.navigate([
            "/operations/depot/det",
            this.response.data.type_entry,
            this.response.data.almacen_id,
          ]);
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }


  public searchDepto() :void {
    let response_: any = [];
    this.loader = 1;
    this.depotService.serchDepto(this.id_client).subscribe(
      (res) => {
        response_ = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.select_deptos = response_.data;
          this.new_instrument.id_depto = this.select_deptos[0].id;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * This is a private function in TypeScript that searches for a brand using a form data object and
   * updates the brand result based on the response received from the server.
   */
  public searchBrand(): void {
    this.loader = 1;
      let form = new FormData();
      form.append("brand", this.brand);
  
    this.depotService.searchBrand(form).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.brand_list = this.response.data;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  setBrandInstrument(brand_id, brand_name) : void {
    this.brand = brand_name;
    this.new_instrument.id_brand = brand_id;
    this.brand_list = [];
  }

  /*  EDITAR PARTIDAS  */

  public crearObjetoPartida ( partida_det ) : any {
    const { id_almacen, equipo_det } = partida_det;
    const { tag, descripcion, marca, modelo, serie, id } = equipo_det;

    return {
      depot_detail: id_almacen,
      depot_type: this.tipo,
      equipo_id: id,
      depot_tag: tag,
      depot_description: descripcion,
      depot_brand: marca,
      depot_model: modelo,
      depot_serie: serie,
    };
  }

  public addPartToEdit( partida_det ) : void {
    const add_partida = this.crearObjetoPartida(partida_det);

    const part = this.partidas.find(
      (objeto) => objeto.id_almacen === partida_det.id_almacen
    );
    const IS_IN_ARRAY_PART = this.partidas_editadas.some(
      (objeto) => objeto.depot_detail === add_partida.depot_detail
    );

    if (IS_IN_ARRAY_PART) {
      this.partidas_editadas = this.partidas_editadas.filter(
        (objeto) => objeto.depot_detail !== add_partida.depot_detail
      );
      part.checked = false;
    } else {
      this.partidas_editadas.push({ ...add_partida });
      part.checked = true;
    }

  }

  public editDepotParts(): void {
    this.loader = 1;
    this.depotService.saveEditedPart(this.partidas_editadas).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003" ||
          this.response.code == "1_0004" ||
          this.response.code == "0_007"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.part_saved = true;
          this.canEditPart();
        }

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  public canEditPart() {
    if (this.edit_parts) {
      this.edit_parts = false;
      this.getDetailInOut();
      this.partidas_editadas = [];
    } else {
      this.edit_parts = true;
    }
  }

  /*  ELIMINAR PARTIDAS  */
  public addPartToRemove( id ): void {
    const add_partida = {
      id_partida: id,
      depot_type: this.tipo,
    };

    const part = this.partidas.find((objeto) => objeto.id_almacen === id);
    const IS_IN_ARRAY_PART = this.partidas_remove.some(
      (objeto) => objeto.id_partida === add_partida.id_partida
    );

    if (IS_IN_ARRAY_PART) {
      this.partidas_remove = this.partidas_remove.filter(
        (objeto) => objeto.id_partida !== add_partida.id_partida
      );
      part.checked = false;
    } else {
      this.partidas_remove.push({ ...add_partida });
      part.checked = true;
    }
  }

  public removePartidas(): void {
    this.loader = 1;
    this.depotService.deleteDepotPart(this.partidas_remove).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003" ||
          this.response.code == "1_0004" ||
          this.response.code == "0_007"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.removed = true;
          this.canRemovePart();
        }

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  public canRemovePart() {
    if (this.parts_remove) {
      this.parts_remove = false;
      this.getDetailInOut();
      this.partidas_remove = [];
    } else {
      this.parts_remove = true;
    }
  }

  /* PAQUETERIAS */
  public selectedParcel( parcel ) : void {
    const paqueteria = this.paqueterias.find((paq) => paq.nombre === parcel);
    if (paqueteria) this.selected_parcel = paqueteria;
  }

  public resetParcel() : void {
    this.almacen_edit.info_delivery = "";
    this.almacen_edit.parcel = "Seleccionar";
    this.selected_parcel = this.paqueterias[0];
  }

  /* AÑADIR PARTIDAS */
  public resetAddPart() : void{
    this.equipos = [];
    this.add_new_part = false;
    this.buscar.search_type = '';
    this.buscar.valor = '';
    this.create_new_instrument = false;
    this.new_instrument = this.new_instrument_default;
  }

  public canAddNewPart(type:string) : void {
    if(type != ''){
      this.tipo_entrada = type;
      this.add_new_part = true;
    }else {
      this.tipo_entrada = '';
      this.add_new_part = false;
    }
    
  }
  /**
   * This function searches for a general instrument using a form data object and updates the general
   * result array based on the response.
   */
  public searchInstrument(search_type: string): void  {
    this.buscar.search_type = search_type;
    this.buscar.client_id = this.id_client;
    this.loader = 1;
    this.depotService.searchInformationNewPart(this.buscar).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003" ||
          this.response.code == "1_0004"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.equipos = this.response.data;
          this.ver_equipos = this.noResults(this.equipos);
        }

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  public addNewDepotPart( type_add:string, id_info:number = 0 ): void {
    this.createNewPartInfo(id_info, type_add);

    this.loader = 1;
    this.depotService.addNewDepotPart(this.new_part_depot).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004" ||  this.response.code == "0_008")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.getDetailInOut();
          this.resetAddPart();
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  private createNewPartInfo( id_info:number, type_add:string ) : void {
      this.new_part_depot.depot_id = this.depot_id;
      this.new_part_depot.add_type = type_add;
      this.new_part_depot.depot_type = this.tipo;

      switch( type_add ) {
        case 'odv' :
          this.new_part_depot.odv_id = id_info;
          break;
        case 'entry' : 
          this.new_part_depot.entry_id = id_info;
          break;
        case 'new' :
          this.new_part_depot.new_instrument = this.new_instrument;
          break;
        default : 
        this.new_part_depot.instrument_id = id_info;
          break;
      }
      
  }

  disableCanRemove(salida) : boolean {
    if(this.tipo === 'in') {
      return  salida != 0;
    }else {
      return false;
    }
  }

  /* UTILITIES */
  public noResults( results : any[] ) : boolean {
    return results.length < 1;
  }

  public addCheckStatus(list): any[] {
    list.forEach((element) => {
      element.checked = false;
    });
    return list;
  }
  /**
   * This is a private asynchronous function that launches a modal with a given code, title, and
   * message using the ModalComponent.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - The title of the modal that will be displayed.
   * @param message - The message parameter is a string that represents the message to be displayed in
   * the modal dialog.
   */
  private async lauchModal(
    code: string,
    title: string,
    message
  ): Promise<void> {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
}
