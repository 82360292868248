import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { InstrumentService } from 'src/app/services/instrument.service';
import { environment } from 'src/app/variables/enviroment';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  id_instrument:string;
  id_report:string;
  loader:number = 1;
  response:any = [];
  data:any= [];
  doc_name = '';
  url_to_load = environment.uri_for_reports;
  //url_to_load = 'http://ngdat.ucal.com.mx/storage/public/informes/';
  permmisions = JSON.parse(localStorage.getItem('permisos'));

  constructor(
    private modalService:NgbModal,
    private instument_service:InstrumentService,
    private activedRoute:ActivatedRoute,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.id_instrument = this.activedRoute.snapshot.paramMap.get('id_instrument');
    this.id_report = this.activedRoute.snapshot.paramMap.get('id_report');
    this.loadData();
  }

  loadData(){
    this.instument_service.getReport(this.id_instrument,this.id_report).subscribe(
      res=>{
        this.response = res;
        if(this.response.code != '0_005' )this.lauchModal(this.response.code,this.response.message);
        else{
          this.data = this.response.data;
          this.doc_name = this.data.name;
          this.url_to_load = this.url_to_load+this.doc_name; 
        }
        this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        console.log(err);
        this.loader = 2;
      }
    );
  }

  docURL() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.url_to_load);
  }

  lauchModal(code:string,title:string){
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

}
