import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../variables/enviroment';
import { Observable } from 'rxjs';
import { ICobranzaReport } from './models/ICobranzaReport';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  uri:string = environment.uri;


  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');

  constructor(private httpClient:HttpClient) { }

  getInvoiceComments(id){
    return this.httpClient.get(this.uri+"get_invoice_comments?api_key="+this.api_key+"&token="+this.token+"&module=95&invoice_id="+id);
  }

  getInvoicesReport(reportData:ICobranzaReport):Observable<ICobranzaReport>{
    return this.httpClient.post(this.uri+"get_invoices_report?api_key="+this.api_key+"&token="+this.token+"&module=95",reportData);
  }

  getInvoicesMonth(mes){
    return this.httpClient.get(this.uri+"get_list_invoices_month?api_key="+this.api_key+"&token="+this.token+"&module=95&month="+mes);
  }

  getInviocesAll(){
    return this.httpClient.get(this.uri+"get_list_invoices?api_key="+this.api_key+"&token="+this.token+"&module=95");
  }
  searchInvoice(factura){
    return this.httpClient.get(this.uri+"search_invoices?api_key="+this.api_key+"&token="+this.token+"&module=95&factura="+factura);
  }

  reprogramarCobro(id,fecha){
    return this.httpClient.get(this.uri+"reschedule_invoice?api_key="+this.api_key+"&token="+this.token+"&module=95&id_factura="+id+"&fecha_nueva="+fecha);
  }

  getCalendarAll(mes){
    return this.httpClient.get(this.uri+"get_calendar_invoices_all?api_key="+this.api_key+"&token="+this.token+"&module=95&month="+mes);
  }

  getToPayInvoice(id,fecha,comentario){
    return this.httpClient.get(this.uri+"to_pay_invoice?api_key="+this.api_key+"&token="+this.token+"&module=95&id_factura="+id+"&fecha_pago="+fecha+"&comentario="+comentario);
  }

  getCalendarDay(dia){
    return this.httpClient.get(this.uri+"get_calendar_invoices_day?api_key="+this.api_key+"&token="+this.token+"&module=95&day="+dia);
  }

  getInviocesUpdate(valueRS){
    return this.httpClient.get(this.uri+"get_list_invoices_update?api_key="+this.api_key+"&token="+this.token+"&module=171&serie="+valueRS);
  }

  getInviocesByOdv(){
    return this.httpClient.get(this.uri+"get_invoices_odv?api_key="+this.api_key+"&token="+this.token+"&module=95");
  }
  
  changeStatusInvoice(form,module){
    return this.httpClient.post(this.uri+"change_status_invioce?api_key="+this.api_key+"&token="+this.token+"&module="+module,form);
  }

  subirMultiplesArchivos(form){
    return this.httpClient.post(this.uri+"upload_xlsx_update_wallet?api_key="+this.api_key+"&token="+this.token+"&module=172",form)
  }

  obtenerComparacionFacturas(mes){
    return this.httpClient.get(this.uri+"get_to_compare_invoices?api_key="+this.api_key+"&token="+this.token+"&module=172&mes="+mes)
  }

  reprogramarFacturaCobranza(data){
    return this.httpClient.post(this.uri+"set_new_date_paymend?api_key="+this.api_key+"&token="+this.token+"&module=176",data)
  }

  facturasFechasPago(){
    return this.httpClient.get(this.uri+"get_iovoices_by_date?api_key="+this.api_key+"&token="+this.token+"&module=171");
  }
  
  getDetailInvoice(id_factura){
    return this.httpClient.get(this.uri+"get_iovoice_detail?api_key="+this.api_key+"&token="+this.token+"&module=185&id_fact="+id_factura);
  }

  uploadFileInvoice(form){
    return this.httpClient.post(this.uri+"upload_file_invoice_wallet?api_key="+this.api_key+"&token="+this.token+"&module=185",form);
  }

  sendEmailInvoice(form){
    return this.httpClient.post(this.uri+"send_mail_invoice?api_key="+this.api_key+"&token="+this.token+"&module=185", form);
  }

}
