import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/app/variables/enviroment';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  uri:string = environment.uri;
  //uri:string = "http://ngdat.ucal.com.mx/api/";

  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');

  constructor(private httpClient: HttpClient) { }

  getLogs(){  
    
    return this.httpClient.get(this.uri+"get_log_activity_data?api_key="+this.api_key+"&token="+this.token+"&module=12");
  }

}
