import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TicketsService } from "src/app/services/tickets.service";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-det-ticket",
  templateUrl: "./det-ticket.component.html",
  styleUrls: ["./det-ticket.component.css"],
})
export class DetTicketComponent implements OnInit {
  loader = 1;
  response: any = [];
  data: any = [];
  ticket_list: any = [];
  id = "";
  ticket_detail = [];

  @ViewChild("btn_close_modal") divView: ElementRef;
  permmisions = JSON.parse(localStorage.getItem("permisos"));

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private ticketService: TicketsService,
    private activedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.id = this.activedRoute.snapshot.paramMap.get("id");
    this.getData();
  }

  /**
   * The function retrieves ticket details and displays them in a modal or logs an error message.
   */
  private getData() :void {
    this.loader = 1;
    this.ticketService.getTicketDetail(this.id).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.data = this.response.data;
          this.ticket_detail = this.data.atendidoPor;
        }

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * The function retrieves a list of tickets and handles different response codes accordingly.
   */
  private getAllList() :void {
    this.loader = 1;
    this.ticketService.getTicketList().subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else this.ticket_list = this.response.data;
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This function launches a modal with a given code, title, and message using the Angular Material
   * modal service.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - The title of the modal that will be displayed.
   * @param message - The message parameter is likely a string that contains the content to be
   * displayed in the body of the modal dialog box. It could be a message to the user or some other
   * information that needs to be communicated.
   */
  private async lauchModal(code: string, title: string, message) :Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
}
