import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../variables/enviroment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DriveService {
  uri:string = environment.uri;
  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');

  constructor(private httpClient:HttpClient) { }

  subirMultiplesCsv(data:any):Observable<any>{
    return this.httpClient.post(this.uri+"add_files_drive_csv?api_key="+this.api_key+"&token="+this.token+"&module=105",data);
  }

  cambiarStatus(id){
    return this.httpClient.get(this.uri+"change_status_drive?api_key="+this.api_key+"&token="+this.token+"&module=105&id_file="+id);
  }

  getFoldersUser(){
    return this.httpClient.get(this.uri+"list_folders_user?api_key="+this.api_key+"&token="+this.token+"&module=111");
  }

  createDirectoryUser(folder){
    return this.httpClient.get(this.uri+"create_directory?api_key="+this.api_key+"&token="+this.token+"&module=111&folder="+folder);
  }

  getUserShared(nombre){
    return this.httpClient.get(this.uri+"get_user_share_folder_drive?api_key="+this.api_key+"&token="+this.token+"&module=111&nombre="+nombre);
  }

  sharedFolder(nombre,array_usuarios:any){
    return this.httpClient.get(this.uri+"share_folder_drive?api_key="+this.api_key+"&token="+this.token+"&module=111&nombre="+nombre+"&usuarios="+array_usuarios);
  }

  readFolderUser(folder,id_propietario){
    return this.httpClient.get(this.uri+"read_directory?api_key="+this.api_key+"&token="+this.token+"&module=111&folder="+folder+"&id_propietario="+id_propietario);
  }

  subirMultiplesArchivos(data:any):Observable<any>{
    return this.httpClient.post(this.uri+"add_files_drive?api_key="+this.api_key+"&token="+this.token+"&module=111",data);
  }

  eliminarArchivos(nombre,id_propietario,directory_act){
    return this.httpClient.get(this.uri+"delete_file_drive?api_key="+this.api_key+"&token="+this.token+"&module=111&file="+nombre+"&id_propietario="+id_propietario+"&carpeta="+directory_act);
  }

}