import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TicketsService } from "src/app/services/tickets.service";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-add-ticket",
  templateUrl: "./add-ticket.component.html",
  styleUrls: ["./add-ticket.component.css"],
})
export class AddTicketComponent implements OnInit {
  loader = 1;
  response: any = [];
  data: any = [];
  tipos_soporte: any = [];
  atendido_por: any = [];

  id = "";
  identifier = "";
  entry_date = "";
  descripcionA = "";

  search_user = "";
  search_user_result = [];
  search_user_id = "";

  id_send_type = "";

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private ticketService: TicketsService,
    private activedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.id = this.activedRoute.snapshot.paramMap.get("id");
    this.getData();
  }

  /**
   * The function retrieves ticket details and displays them in a modal if there are any errors,
   * otherwise it sets the retrieved data to variables and sets the loader to 2.
   */
  private getData() :void {
    this.loader = 1;
    this.ticketService.getTicketDetail(this.id).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.data = this.response.data;
          this.identifier = this.data.folio;
          this.tipos_soporte = this.data.tipoSoporte;
          this.atendido_por = this.data.atendidoPor;
        }

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );
  }

 /**
  * This function searches for a user using a form data object and displays the results or an error
  * message.
  */
 public searchUser() :void {
    let form = new FormData();
    form.append("user", this.search_user);
    this.loader = 1;
    this.ticketService.searchUser(form).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.search_user_result = this.response.data;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This function sets the search user ID, detail, and result to empty array.
   * @param id - The user ID that is being searched for.
   * @param detail - It is a variable that contains the details of a user. It could be their name,
   * email, phone number, or any other information that is relevant to the search.
   */
  public setSearchUser(id, detail) :void {
    this.search_user_id = id;
    this.search_user = detail;
    this.search_user_result = [];
  }

  /**
   * This is a private function in TypeScript that sets the value of a class property called
   * "id_send_type".
   * @param id - The "id" parameter is a value that is passed into the "setType" method. It is used to
   * set the value of the "id_send_type" property of the object that the method is called on.
   */
  public setType(id) :void {
    this.id_send_type = id;
  }

  /**
   * The function saves changes made to a ticket and displays a modal message based on the response
   * received.
   * @param action - The "action" parameter is a variable that determines what action to take after
   * saving the changes to the ticket. It is passed as an argument to the "saveChanges" function. The
   * value of "action" can be either 0 or 1. If it is 0, no action is
   */
  public saveChanges(action) :void{
    let form = new FormData();
    form.append("id", this.id);
    form.append("identifier", this.identifier);
    form.append("description", this.descripcionA);
    form.append("send_type", this.id_send_type);
    form.append("id_brindado", this.search_user_id);

    this.loader = 1;
    this.ticketService.saveTicketEdit(form).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          if (action == 0) {
          } else this.router.navigate(["/programmers/tickets/"]);
        }

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This is an asynchronous function that launches a modal with a given code, title, and message using
   * a modal service.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - The title of the modal that will be displayed.
   * @param message - The message parameter is likely a string that contains the content to be
   * displayed in the body of the modal dialog box. It could be a message to the user or some other
   * information that needs to be communicated.
   */
  private async lauchModal(code: string, title: string, message) :Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
}
