import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { OrdenVentaService } from "src/app/services/orden-venta.service";

@Component({
  selector: "app-service-surveys",
  templateUrl: "./service-surveys.component.html",
  styleUrls: ["./service-surveys.component.css"],
})
export class ServiceSurveysComponent implements OnInit {
  permmisions = JSON.parse(localStorage.getItem("permisos"));
  loader: number = 1;
  response: any = [];
  data: any = [];

  id_odv: string = "";
  identifier: string = "";
  notes: string = "";
  show_explain_message = false;
  public contact_list:any = [];
  public check_all:boolean = false;

  public send_email:any = [];
  constructor(
    private activedRoute: ActivatedRoute,
    private odvService: OrdenVentaService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.id_odv = this.activedRoute.snapshot.paramMap.get("id_odv");
    this.loadODVData();
  }

  /**
   * The function loads ODV data from a service and handles different response codes.
   */
  private loadODVData(): void {
    this.odvService.getODVDetail(this.id_odv).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.data = this.response.data.client_data;
          this.identifier = this.response.data.identificador;
          this.contact_list = this.buscarYColocarAlInicio(this.data.email, this.data.contact_list);

          this.contact_list.forEach(contactos => {
            contactos.check = false;
         });
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        this.loader = 2;
      }
    );
  }

  public setCorreo(correo) : void {
    const email = this.contact_list.find(obj => obj.correo === correo);
    const new_email = {
      correo: correo
    }

    const existingIndex = this.send_email.findIndex(objeto => objeto.correo === correo);

    if (existingIndex === -1) {
      this.send_email.push(new_email);
    } else {
      this.send_email.splice(existingIndex, 1);
    }
    email.check = !email.check;
    this.check_all = this.allSelected(this.contact_list);
  }

  public allSelected(email): boolean {
    return email.every(obj => obj.check === true)
  }

  public selectAllParts(): void {
    this.send_email = [];
    this.check_all = !this.check_all;
  
    this.contact_list.forEach(all_parts => {
      all_parts.check = this.check_all;
      if (this.check_all) {
        const new_email = {
          correo: all_parts.correo,
        }
        this.send_email.push(new_email);
      }
    });
  }

  private buscarYColocarAlInicio(correoBuscado: string, lista: any[] ): any[] {
    const indice = lista.findIndex(usuario => usuario.correo === correoBuscado);
    if (indice !== -1) {
      const usuarioEncontrado = lista.splice(indice, 1)[0];
      lista.unshift(usuarioEncontrado);
    }
    return lista;
  }

  /**
   * The function sends an email with a form data containing email and notes, and handles the response
   * accordingly.
   */
  public sendEmail() :void{
    this.loader = 1;
    this.show_explain_message = false;
    const contactos = this.convertToString(this.send_email);
    let form = new FormData();
    form.append("emails", contactos);
    form.append("notes", this.notes);

    this.odvService.sendSurveyEmail(form).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.show_explain_message = true;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        this.loader = 2;
      }
    );
  }
  private convertToString(contactos:any[]) : string {
    return  contactos.map(contacto => contacto.correo).join(', ');
  }

  /**
   * The function launches a modal with a given code and title using the Angular Material modal
   * service.
   * @param {string} code - The code parameter is a string that represents the code that will be
   * displayed in the modal.
   * @param {string} title - The title parameter is a string that represents the title of the modal
   * window that will be displayed.
   */
  private async lauchModal(code: string, title: string): Promise<void> {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }
}
