import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { CalendarService } from 'src/app/services/calendar.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    weekends: false
  };

  todos:number= 0;
  data:any = [];
  eventosT:any=[];
  response:any = [];
  loader:number = 1;
  
  mesActual:any;
  anioActual:any;
  anioAux:any="2023";
  diaActual:string='';
  fecha: string='';
  calendarView:number=0;
  
  dias:number;
  arrayDias:any=[];
  finalEvents:any=[];
  hoy:any;
  filtrarMes:any;
  

  filtraranio="2023"

  mesSelectAux:any = "00";

  nueva_fecha:any;
  informe_equipo_r:string;

  private currentDate = new Date();

  permmisions = JSON.parse(localStorage.getItem('permisos'));

  constructor( private activedRoute:ActivatedRoute, private calendarService:CalendarService,private modalService:NgbModal,private router:Router) { }

  handleDateClick(arg) {
    this.loader = 1;

    this.diaActual = this.getCurrentDateDay();

    this.calendarService.getEventosDia(arg.dateStr,this.diaActual).subscribe(
      res=>{
        this.response = res;
        
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
        else this.data = this.response.data;

        this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        console.log(err);
        this.loader = 2;
      }
    );

  }

  toggleWeekends() {
    this.calendarOptions.weekends = !this.calendarOptions.weekends // toggle the boolean!
  }

  ngOnInit(): void {
    this.anioActual = this.currentDate.getFullYear();
    //this.getMesData();
    this.filtrarMes = this.getMonthActual(); 
    this.verTodos();
  }

  mostrareventosCalendar(val){
    this.calendarOptions = ({
      initialView: 'dayGridMonth',
      dateClick: this.handleDateClick.bind(this), // bind is important!
      navLinks:false,
      editable:true,
      events: val,
    });
    this.loader = 2;
  }

  getMesData(){
    this.fecha = this.getCurrentDateMonth();
    this.diaActual = this.getCurrentDateDay();
    let vig = []; let prox =[]; let venc=[];

    this.calendarService.getEventosMes(this.fecha,this.diaActual).subscribe(
      res=>{
        this.response = res;
        
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
        else this.eventosT = this.response.data;
        
        let val=[];
        for (let i = 0; i < this.eventosT.length; i++) {

          if(this.eventosT[i].prox==0){
            vig.push(this.eventosT[i].fecha_vencimiento);

          }else if(this.eventosT[i].prox==1){
            prox.push(this.eventosT[i].fecha_vencimiento);

          }else if(this.eventosT[i].prox==2){
            venc.push(this.eventosT[i].fecha_vencimiento);
          }
            
        }

        var final_fechas = [];

        ///------------ Vigentes
        var arr_aux_vig = [];
        for(var a = 0; a< vig.length; a++){
          if(!arr_aux_vig.includes(vig[a])) arr_aux_vig.push(vig[a]);
        }

        for(var b = 0; b < arr_aux_vig.length ; b++){
          let cont = 0;
          for(var c = 0; c < vig.length ; c++){
            if(arr_aux_vig[b] == vig[c]) cont++;
          }
          
          let data:any = [];
          data['title']=cont;
          data['start']=arr_aux_vig[b];
          data['color']='#4caf50';

          final_fechas.push(data);
        }

        ///--------- Proximos
        var arr_aux_prox = [];
        for(var d = 0; d< prox.length; d++){
          if(!arr_aux_prox.includes(prox[d])) arr_aux_prox.push(prox[d]);
        }

        for(var e = 0; e < arr_aux_prox.length ; e++){
          let cont = 0;
          for(var f = 0; f < prox.length ; f++){
            if(arr_aux_prox[e] == prox[f]) cont++;
          }
          let data:any = [];
          data['title']=cont;
          data['start']=arr_aux_prox[e];
          data['color']='#ff9800';

          final_fechas.push(data);
        }

        ///--------- Vencidos
        var arr_aux_ven = [];
        for(var i = 0; i < venc.length ; i++){
          if(!arr_aux_ven.includes(venc[i])) arr_aux_ven.push(venc[i]);
        }
        
        for(var i = 0; i < arr_aux_ven.length ; i++){
          var cont = 0;
          for(var j = 0; j < venc.length ; j++){
            if(arr_aux_ven[i] == venc[j]) cont++;
          }

          var data:any = [];
          data['title']=cont;
          data['start']=arr_aux_ven[i];
          data['color']='#e53935';

          final_fechas.push(data);
        }

        this.mostrareventosCalendar(final_fechas);
        this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        console.log(err);
        this.loader = 2;
      }
    );

  }


  verTodos(){
    this.getMesData();
    if(this.todos==0){
      
      this.todos = 1;
      this.consultaTodosIni();

    }else{
      if (this.todos = 1) {
        this.arrayDias=[];
        this.finalEvents=[];
        this.filtrarMes = this.getMonthActual();
        if (this.mesActual === this.filtrarMes) {

          this.consultaTodosIni();
  
        }else{
          this.todos=0;
          
        }
      }
    }
  }



  consultaTodosIni(){

    this.dias = this.diasEnUnMes();
    for (let i = 0; i < this.dias; i++) {
      let b =i+1;
      if(i<9)this.arrayDias.push('0'+b); else this.arrayDias.push(''+b);
    }

    this.loader = 1;
    this.diaActual = this.getCurrentDateDay();
    var mes  =  this.getCurrentDateMonth();

    this.calendarService.getTodosEventos(mes,this.diaActual).subscribe(
    res=>{
      this.response = res;
      
      if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
      else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
      else{
        let array = this.response.data;

        for (let f = 0; f < array.length; f++) {
          var divisiones = array[f].fecha_vencimiento.split("-", 3);

          this.hoy = this.currentDate.getDate();
          if(this.hoy<10){
              this.hoy = "0" + this.hoy.toString();
          }

          let equiposVencidos={
            "id_informe":array[f].id_informe ,
            "id_equipo":array[f].id_equipo,
            "tag":array[f].tag,
            "informe":array[f].informe,
            "dia":''+divisiones[2],
            "prox":array[f].prox
          };
          this.finalEvents.push(equiposVencidos);
        }

      }

      this.loader = 2;
    },err=>{
      this.lauchModal('0000x00','Error de consulta');
      console.log(err);
      this.loader = 2;
    }
  );
  }


  verTodosMesSelected(){
    this.mesActual= this.currentDate.getMonth() + 1;
    if(this.mesActual<10){
      this.mesActual = "0" + this.mesActual.toString();
    }

    if (this.filtrarMes === this.getMonthActual()) {
      this.verTodos();

    }else{
      this.mesSelectAux = this.filtrarMes;
      this.arrayDias=[];
      this.finalEvents=[];
      this.dias = this.diasEnMesSelected(this.filtrarMes);
  

      for (let i = 0; i < this.dias; i++) {
        let b =i+1;
        if(i<9)this.arrayDias.push('0'+b); else this.arrayDias.push(''+b);
      }

      let anio_mes: string = this.filtraranio+'-'+this.filtrarMes;

      this.loader = 1;
      this.diaActual = this.getCurrentDateDay();

      this.calendarService.getTodosEventos(anio_mes,this.diaActual).subscribe(
      res=>{
        this.response = res;
        
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
        else{
          let array = this.response.data;

          for (let f = 0; f < array.length; f++) {
            var divisiones = array[f].fecha_vencimiento.split("-", 3);

            this.hoy = this.currentDate.getDate();
            if(this.hoy<10){
                this.hoy = "0" + this.hoy.toString();
            }

            let equiposVencidos={
              "id_informe":array[f].id_informe ,
              "id_equipo":array[f].id_equipo,
              "informe":array[f].informe,
              "tag":array[f].tag,
              "dia":''+divisiones[2],
              "prox":array[f].prox
            };
            this.finalEvents.push(equiposVencidos);
          }

        }

        this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        console.log(err);
        this.loader = 2;
      }
    );
    }
      

  }

  someMethod() {
    
    let calendarApi = this.calendarComponent.getApi();

    let mesCalendar;

    let mesNext = calendarApi.getDate().getMonth()+1;

    let anioCalendar = calendarApi.getDate().getFullYear();

    
    if(calendarApi.getDate().getMonth()+1<10){
      mesCalendar = "0"+mesNext.toString();
    }else mesCalendar = mesNext.toString();

    let anio_mes = anioCalendar+"-"+mesCalendar;

    this.diaActual = this.getCurrentDateDay();
    let vig = []; let prox =[]; let venc=[];

    this.calendarService.getEventosMes(anio_mes,this.diaActual).subscribe(
      res=>{
        this.response = res;
        
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
        else this.eventosT = this.response.data;
        
        let val=[];
        for (let i = 0; i < this.eventosT.length; i++) {

          if(this.eventosT[i].prox==0){
            vig.push(this.eventosT[i].fecha_vencimiento);

          }else if(this.eventosT[i].prox==1){
            prox.push(this.eventosT[i].fecha_vencimiento);

          }else if(this.eventosT[i].prox==2){
            venc.push(this.eventosT[i].fecha_vencimiento);
          }
            
        }

        var final_fechas = [];

        ///------------ Vigentes
        var arr_aux_vig = [];
        for(var a = 0; a< vig.length; a++){
          if(!arr_aux_vig.includes(vig[a])) arr_aux_vig.push(vig[a]);
        }

        for(var b = 0; b < arr_aux_vig.length ; b++){
          let cont = 0;
          for(var c = 0; c < vig.length ; c++){
            if(arr_aux_vig[b] == vig[c]) cont++;
          }
          let data:any = [];
          data['title']=cont;
          data['start']=arr_aux_vig[b];
          data['color']='#4caf50';

          final_fechas.push(data);
        }

        ///--------- Proximos
        var arr_aux_prox = [];
        for(var d = 0; d< prox.length; d++){
          if(!arr_aux_prox.includes(prox[d])) arr_aux_prox.push(prox[d]);
        }

        for(var e = 0; e < arr_aux_prox.length ; e++){
          let cont = 0;
          for(var f = 0; f < prox.length ; f++){
            if(arr_aux_prox[e] == prox[f]) cont++;
          }
          let data:any = [];
          data['title']=cont;
          data['start']=arr_aux_prox[e];
          data['color']='#ff9800';

          final_fechas.push(data);
        }

        ///--------- Vencidos
        var arr_aux_ven = [];
        for(var i = 0; i < venc.length ; i++){
          if(!arr_aux_ven.includes(venc[i])) arr_aux_ven.push(venc[i]);
        }
        
        for(var i = 0; i < arr_aux_ven.length ; i++){
          var cont = 0;
          for(var j = 0; j < venc.length ; j++){
            if(arr_aux_ven[i] == venc[j]) cont++;
          }
          var data:any = [];
          data['title']=cont;
          data['start']=arr_aux_ven[i];
          data['color']='#e53935';

          final_fechas.push(data);
        }

        this.mostrareventosCalendar(final_fechas);
        this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        console.log(err);
        this.loader = 2;
      }
    );



  }

  getMonthActual(): string{
    let month:any = this.currentDate.getMonth() + 1;
    let dateInApiFormat: string;
    
    if(month<10) month = "0" + month.toString();
    
    dateInApiFormat = month;
    return dateInApiFormat;
  }

  diasEnMesSelected(month:any) {
    let year:any = this.currentDate.getFullYear();
    return new Date(year, month, 0).getDate();
  }

  diasEnUnMes() {
    let month:any = this.currentDate.getMonth() + 1;
    let year:any = this.currentDate.getFullYear();

    if(month<10)  month = "0" + month.toString();
      
    return new Date(year, month, 0).getDate();
  }

  getCurrentDateMonth(): string{
    let month:any = this.currentDate.getMonth() + 1;
    let year:any = this.currentDate.getFullYear();
    let dateInApiFormat: string;

    if(month<10) month = "0" + month.toString();
    
    dateInApiFormat = year.toString() + "-" + month;
    return dateInApiFormat;
  }

  getCurrentDateDay(): string{
    let day:any = this.currentDate.getDate();
    let month:any = this.currentDate.getMonth() + 1;
    let year:any = this.currentDate.getFullYear();
    let dateInApiFormat: string;
      
    if(day<10) day = "0" + day.toString();
      
    if(month<10) month = "0" + month.toString();
      
    dateInApiFormat = year.toString() + "-" + month + "-" + day;
    return dateInApiFormat;
  }
  
  reprogramar(id_info:string){
    this.informe_equipo_r = id_info;
  }
  
  loadDate(date:any){
    this.nueva_fecha = date;
  }
      
  reprogramarEquipo(){
    this.calendarService.setFechaEventoInforme(this.informe_equipo_r,this.nueva_fecha).subscribe(
    res=>{
      this.response = res;
      if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        
      else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
      else this.data = this.response.data;
        
      this.getMesData();
      this.loader = 2;
    },err=>{
      this.lauchModal('0000x00','Error de consulta');
      console.log(err);
      this.loader = 2;
    }
    )
  }
    
  lauchModal(code:string,title:string){
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

}
