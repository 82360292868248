import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-programmers',
  templateUrl: './programmers.component.html',
  styleUrls: ['./programmers.component.css']
})
export class ProgrammersComponent implements OnInit {
  permmisions = JSON.parse(localStorage.getItem('permisos'));
  options: any[] = [];
  constructor() { }

  ngOnInit(): void {
    this.validarPermisos();
  }

  /**
   * The function adds different options to an array based on the user's permissions.
   */
  private validarPermisos() :void {
    let uri = { path: '/programmers/analitics', title: 'Analisis de Datos',  icon: 'fa-server', class: '' };
    this.options.push(uri);

    if(this.permmisions.includes(23)){
      let uri = { path: '/programmers/news', title: 'Avisos',  icon: 'fa-exclamation-triangle', class: '' };
      this.options.push(uri);
    }
    if(this.permmisions.includes(21)){
      let uri = { path: '/programmers/customers', title: 'Clientes',  icon: 'fa-address-book', class: '' };
      this.options.push(uri);
    }
    if(this.permmisions.includes(12)){
      let uri = { path: '/programmers/log', title: 'Log',  icon: 'fa-history', class: '' };
      this.options.push(uri);
    }
    if(this.permmisions.includes(14)){
      let uri = { path: '/programmers/users', title: 'Usuarios',  icon: 'fa-user', class: '' };
      this.options.push(uri);
    }
    if(this.permmisions.includes(88)){
      let uri = { path: '/programmers/general_indicators', title: 'Indicadores y Maratones',  icon: 'fa-chart-line', class: '' };
      this.options.push(uri);
    }
    if(this.permmisions.includes(121)){
      let uri = { path: '/programmers/tickets', title: 'Tickets',  icon: 'fas fa-laptop-code', class: '' };
      this.options.push(uri);
    }
  }

}
