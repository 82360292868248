import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { WalletService } from 'src/app/services/wallet.service';
import { environment } from 'src/app/variables/enviroment';

interface SendEmail {
  id_factura?:string;
  asunto?: string;
  mensaje?: string;
  archivos_enviar?:string[];
  enviar_a?:string;
}
@Component({
  selector: 'app-send-email-wallet',
  templateUrl: './send-email-wallet.component.html',
  styleUrls: ['./send-email-wallet.component.css']
})

export class SendEmailWalletComponent implements OnInit {

  public loader:number = 0;
  private response:any = [];

  public factura_info:any = [];
  public archivos_factura:any =[];
  public tipos_archivos:any =[];

  public correos_cliente:any =[];
  public correos_internos:any =[];
  public tipo_correo:number = 0;
  /* ENVIAR DATOS EMAIL */
  public id_factura:string = '';
  public add_manual_email:string = '';
  public enviando: boolean = false;

  public email_list:any = []
  public enviar_email: SendEmail = {
    asunto: 'Asunto del correo',
    mensaje: '',
    archivos_enviar:[],
    enviar_a:'',
    id_factura:''
  };

  constructor(
    private activedRoute: ActivatedRoute,
    private carteraService: WalletService,
    private modalService: NgbModal,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.id_factura = this.activedRoute.snapshot.paramMap.get("id");
    this.enviar_email.id_factura = this.id_factura;
    this.getDetailInvoice();
  }
  
  private getDetailInvoice(): void {
    this.loader = 1;
    this.carteraService.getDetailInvoice(this.id_factura).subscribe(
      (res) => {
        this.response = res;
        if (this.response.code != "0_005")
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.factura_info = this.response.data[0];
          this.archivos_factura = this.response.data[2].archivos;
          this.tipos_archivos = this.response.data[2].tipos;
          this.correos_cliente = this.addCheckStatus(this.response.data[3].externos, 0);
          this.correos_internos = this.addCheckStatus(this.response.data[3].internos, 1);

          const DIVISA = this.selectDivisa(this.factura_info.divisa);
          this.enviar_email.asunto =
          `${this.factura_info.folio} - Usted tiene un nuevo documento por pagar, monto: $${this.factura_info.saldo} ${DIVISA}`;
        }
        this.loader = 0;
      },
      err=>{
        this.lauchModal("0000x00", "Error de consulta");
        this.loader = 0;
      }
    )

  }
  
/**
 * The function adds a "checked" property with a value of false and a "type" property with a specified
 * value to each element in a given list.
 * @param {any[]} list - An array of elements that need to be modified.
 * @param {number} type - The "type" parameter is a number that is used to set the "type" property of
 * each element in the "list" array.
 * @returns the modified list with the "checked" and "type" properties added to each element.
 */
  private addCheckStatus(list:any[], type:number): any[] {
    list.forEach(element => {
      element.checked = false;
      element.type = type;
    });
    return list;
  }

  /**
   * The function "selectDivisa" returns the currency code "MXN" if the input is "Pesos", otherwise it
   * returns "USD".
   * @param fac_divisa - The parameter `fac_divisa` is a string that represents the currency. It can be
   * either "Pesos" or any other value.
   * @returns a string value. If the input parameter `fac_divisa` is equal to 'Pesos', then the
   * function returns 'MXN'. Otherwise, it returns 'USD'.
   */
  private selectDivisa(fac_divisa:string):string {
    if(fac_divisa == 'Pesos') return 'MXN';
    else return 'USD';
  }

  /* ARCHIVOS */

  /**
   * The function `addFileToSend` adds or removes a file from an array based on whether it already exists
   * in the array.
   * @param archivo_id - The parameter "archivo_id" represents the ID of a file that needs to be added to
   * or removed from the list of files to be sent via email.
   */
  public addFileToSend(archivo_id:string): void {
    if(!this.inArrayFile(archivo_id)) {
      this.enviar_email.archivos_enviar.push(archivo_id);
    }else {
      const indice = this.enviar_email.archivos_enviar.indexOf(archivo_id);
      this.enviar_email.archivos_enviar.splice(indice, 1);
    }
  }

  /**
   * The function checks if a given file ID is included in an array of files to be sent via email.
   * @param archivo_id - The parameter "archivo_id" represents the ID of a file that needs to be
   * checked if it exists in the list of files to be sent via email.
   * @returns a boolean value. If the `archivo_id` is included in the `archivos_enviar` array of the
   * `enviar_email` object, then it returns `true`. Otherwise, it returns `false`.
   */
  public inArrayFile(archivo_id:string): boolean {
    if(this.enviar_email.archivos_enviar.includes(archivo_id)) {
      return true;
    }
    return false;
  }

  /**
   * The function `downloadFile` opens a new window to download a file from a specific URL.
   * @param document - The "document" parameter is a string that represents the name or identifier of
   * the file that you want to download.
   */
  public downloadFile(document): void {
    let url = environment.uri_server + "storage/public/documentos_facturas/" + document;
    window.open(url, "_blank");
  }


  /* LISTADO DE CORREOS */


  /**
 * The function `addEmailToSend` adds or removes an email from a list based on its type and updates the
 * checked status of the corresponding contact.
 * @param {string} email_usr - The parameter `email_usr` is a string that represents the email address
 * to be added to the email list.
 * @param {number} type - The "type" parameter is a number that indicates the type of email. It is used
 * to determine whether the email should be added to the "correos_cliente" or "correos_internos"
 * contact list. If the type is 0, the email is added to "cor
 */
  public addEmailToSend(email_usr:string, type:number): void {
    const email_to_add = {
      correo: email_usr,
      type: type
    }
    let contactList = type === 0 ? this.correos_cliente : this.correos_internos;
    const contacto = contactList
        .find(objeto => objeto.correo === email_to_add.correo);
    const IN_LIST_EMAIL = this.email_list
        .find(email => email.correo === email_to_add.correo);

    if (!IN_LIST_EMAIL) {
      this.email_list.push(email_to_add);
    } else {
      this.email_list = this.email_list
          .filter(email => email.correo !== email_to_add.correo);
    }
    if (contacto) {
      contacto.checked = !IN_LIST_EMAIL;
    }

  }
  /**
   * The function collects a list of emails and returns them as a comma-separated string.
   * @returns a string that contains a list of emails separated by commas.
   */
  private collectEmails() :string{
    let correos:string = '';
    this.email_list.forEach((email) => {
      if(correos == '') {
        correos = email.correo;
      } else {
        correos = `${correos},${email.correo}`;
      }
    });
    return correos;
  }

 /**
  * The function `sendEmailInvoice` sends an email invoice and handles the response accordingly.
  */
  public sendEmailInvoice(){
    this.enviando = true;
    this.enviar_email.enviar_a= this.collectEmails();
    this.carteraService.sendEmailInvoice(this.enviar_email).subscribe(
        (res) => {
          this.response = res;
          if (this.response.code != "0_005")
          this.lauchModal(this.response.code, this.response.message);
          else {
            this.enviando = false;
            this.router.navigate([`/administration/wallet/detail_invoice/${this.id_factura}`]);
          }
        },
        (err) => {
          this.lauchModal("0000x00", "Error de consulta");
        }
      );
  }

  /**
   * The function checks if the email list is empty and returns true if it is, indicating that an email
   * can be sent.
   * @returns a boolean value. If the length of the email_list is less than 1, it will return true.
   * Otherwise, it will return false.
   */
  public canSendEmail():boolean {
    if(this.email_list.length < 1) {
      return true;
    }
    return false;
  }

  /**
   * The `lauchModal` function opens a modal component with the given code and title.
   * @param {string} code - The `code` parameter is a string that represents the code that will be
   * displayed in the modal. It could be any valid code, such as JavaScript, HTML, CSS, etc.
   * @param {string} title - The `title` parameter is a string that represents the title of the modal.
   * It is used to set the `title` property of the `ModalComponent` instance.
   */
  private async lauchModal(code: string, title: string) :Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

}
