import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { WalletService } from 'src/app/services/wallet.service';
import { environment } from 'src/app/variables/enviroment';

@Component({
  selector: 'app-detail-invoice',
  templateUrl: './detail-invoice.component.html',
  styleUrls: ['./detail-invoice.component.css']
})
export class DetailInvoiceComponent implements OnInit {

  id_factura:string='';
  loader: number = 0;

  response:any = [];

  general_data:any = [];
  comments:any = [];
  docs:any = [];
  

  file: any;
  file_name:string='';

  partidas :any=[];

  constructor(
    private activedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private carteraService: WalletService,
    ) { }

  ngOnInit(): void {
    this.id_factura = this.activedRoute.snapshot.paramMap.get("id");
    this.getDetailInvoice();
  }

  
  /**
   * The function `getDetailInvoice` retrieves detailed information about an invoice and updates the
   * corresponding variables in the component.
   */
  private getDetailInvoice() :void {
    this.loader = 1;

    this.carteraService.getDetailInvoice(this.id_factura).subscribe(
      (res) => {
        this.response = res;
        
        if (this.response.code != "0_005")
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.general_data = this.response.data[0];
          this.docs = this.response.data[2];
          for (let i = 0; i < this.response.data[1].length; i++) {
            this.response.data[1][i].estatus = this.response.data[1][i].estatus == 1 ? 'Pagada'
            : this.response.data[1][i].estatus == 2 ? 'Cancelar' : 'Agendada'
          }
          this.comments = this.response.data[1];
          this.partidas = this.general_data.odv[0];
        }
        this.loader = 0;
      },
      err=>{
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 0;
      }
    )

  }

  /**
   * The function "loadFile" assigns the selected file to a variable and extracts the file name.
   * @param value - The "value" parameter is the event object that is passed when the file input
   * element's value changes.
   */
  public loadFile(value) :void {
    this.file = value.target;
    this.file_name = this.file.files[0].name;
  }

  /**
   * The function `downloadFile` opens a new window to download a file from a specific URL.
   * @param document - The "document" parameter is the name or identifier of the file that you want to
   * download.
   */
  public downloadFile(document) :void {
    var url = environment.uri_server + "storage/public/documentos_facturas/" + document;
    window.open(url, "_blank");
  }

  /**
   * The `uploadFile` function is responsible for uploading a file and handling the response.
   */
  public uploadFile() :void {
    this.loader=1;

    let form = new FormData();
    form.append("file", this.file.files[0]);
    form.append('id_fact',this.id_factura);
    
    this.carteraService.uploadFileInvoice(form).subscribe(
      (res) => {
        this.response = res;
        
        if (this.response.code != "0_005")
          this.lauchModal(this.response.code, this.response.message);
        else {
          if (this.response.data == 1) this.getDetailInvoice();
        }
        this.loader=0;
      },
      err=>{
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 0;
      }
    )
  }


  public orderSalesParts(obj:any) :void{
    if(this.partidas.length > 0) {
      if(obj.id == this.partidas.id) this.partidas = []
      else this.partidas = obj;
    } else this.partidas = obj;
  }

  /**
   * The `lauchModal` function opens a modal component with the given code and title.
   * @param {string} code - The `code` parameter is a string that represents the code that will be
   * displayed in the modal. It could be any valid code, such as JavaScript, HTML, CSS, etc.
   * @param {string} title - The `title` parameter is a string that represents the title of the modal.
   * It is used to set the `title` property of the `ModalComponent` instance.
   */
  private async lauchModal(code: string, title: string) :Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

}
