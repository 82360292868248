import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { NewsService } from "src/app/services/news.service";

@Component({
  selector: "app-detail-news",
  templateUrl: "./detail-news.component.html",
  styleUrls: ["./detail-news.component.css"],
})
export class DetailNewsComponent implements OnInit {
  id_new: string = "";
  response: any = [];
  loader: number = 1;
  data: any = [];

  constructor(
    private activedRoute: ActivatedRoute,
    private newServ: NewsService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.id_new = this.activedRoute.snapshot.paramMap.get("id");
    this.getNew();
  }

  /**
   * This is a private function that retrieves news data from a server and handles the response
   * accordingly.
   */
  private getNew() :void {
    this.newServ.getNew(this.id_new).subscribe(
      (res) => {
        this.response = res;
        console.log(res);
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message);
        else this.data = this.response.data;
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

 /**
  * This function launches a modal with a given code and title using the Angular Material modal
  * service.
  * @param {string} code - A string parameter that represents the code to be displayed in the modal
  * window.
  * @param {string} title - The title parameter is a string that represents the title of the modal that
  * will be launched. It will be displayed at the top of the modal to provide context to the user.
  */
  private async lauchModal(code: string, title: string) :Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }
}
