import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/app/variables/enviroment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  uri:string = environment.uri;
  //uri:string = "http://ngdat.ucal.com.mx/api/";
  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');

  
  constructor(private httpClient:HttpClient) { }

  getNavbarData(){
    return this.httpClient.get(this.uri+"get_primary_data?api_key="+this.api_key+"&token="+this.token+"&module=41");
  }

  getDashboardData(){
    return this.httpClient.get(this.uri+"get_dashboard_data?api_key="+this.api_key+"&token="+this.token+"&module=41");
  }

  getNotifications(all){
    return this.httpClient.get(this.uri+"get_primary_notifications?api_key="+this.api_key+"&token="+this.token+"&module=41&all_not="+all);
  }
  
  setStatusAlert(id){
    return this.httpClient.get(this.uri+"set_status_alert?api_key="+this.api_key+"&token="+this.token+"&module=41&alert="+id);
  }
}
