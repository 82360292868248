import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Integer from "@zxing/library/esm/core/util/Integer";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { IODC } from "src/app/services/models/IODC";
import { IPartODC } from "src/app/services/models/IPartODC";
import { SaleOrderService } from "src/app/services/sale-order.service";

@Component({
  selector: "app-edit-sale-order",
  templateUrl: "./edit-sale-order.component.html",
  styleUrls: ["./edit-sale-order.component.css"],
})
export class EditSaleOrderComponent implements OnInit {
  message_add_part = "";
  id_odc = "";
  loader = 1;
  response: any = [];
  data_general: any = [];
  parts: any = [];
  //Para Editar
  identifier = "";
  bill = "";
  notes = "";
  ship_to = "";
  bill_to = "";
  supplier_directions: any = "";
  client_directions: any = "";
  pay = "";
  pay_tipes: any = [];
  coin = 0;
  a_nombre = "";
  direccion = "";
  municipio = "";
  cotizacion = "";
  tiempo_entrega = "";

  attention_id = "";
  supl_users: any = [];

  coin_type: any = [
    { desc: "MXN", id: "1" },
    { desc: "USD", id: "2" },
  ];
  iva_opt: any = [
    { desc: "Aplica", id: "1" },
    { desc: "No aplica", id: "0" },
  ];
  iva_ret_opt: any = [
    { desc: "Aplica", id: "1" },
    { desc: "No aplica", id: "0" },
  ];
  //Agregar partidas
  concept = 0;
  price_part = 0;
  quantity_part = 1;
  part = "";
  notes_part = "N/A";
  desc_part = "N/A";
  tag = "N/A";
  marca = "N/A";
  modelo = "N/A";
  serie = "N/A";

  subtotal = 0;
  iva = 0;
  total = 0;
  iva_apl = 1;
  iva_ret = 1;
  iva_ret_val = 1;

  //Datos para editar la partida
  edit = 0;

  id_part_to_delete = 0;
  tag_part_to_delete = "";
  price_part_to_delete = "";

  constructor(
    private activedRoute: ActivatedRoute,
    private saleOrderService: SaleOrderService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.id_odc = this.activedRoute.snapshot.paramMap.get("id");
    this.getData();
  }

 /**
  * The function retrieves data from a service and assigns it to various variables for use in a sales
  * order form.
  */
  private getData() :void {
    this.saleOrderService.getDataToEdit(this.id_odc).subscribe(
      (res) => {
        this.response = res;
        if (this.response.code != "0_005")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.data_general = this.response.data;

          this.identifier = this.data_general.consecutivo;
          this.bill = this.data_general.factura;
          this.notes = this.data_general.notas;

          this.ship_to = this.data_general.id_ship_to;
          this.bill_to = this.data_general.id_bill_to;
          this.supplier_directions = this.data_general.proveedor_dirs;
          this.client_directions = this.data_general.client_dirs;

          this.pay = this.data_general.id_tipo_pago;
          this.pay_tipes = this.data_general.t_pay;
          this.coin = this.data_general.tipo_moneda;

          this.attention_id = this.data_general.id_atencion_a;
          this.supl_users = this.data_general.supl_users;

          this.a_nombre = this.data_general.a_nombre;
          this.direccion = this.data_general.direccion;
          this.cotizacion = this.data_general.cotizacion;
          this.municipio = this.data_general.municipio;
          this.tiempo_entrega = this.data_general.tiempo_entrega;
          this.parts = this.data_general.partidas;
          this.iva_apl = this.data_general.iva;
          this.iva_ret = this.data_general.iva_ret;
          this.subtotal = 0;
          this.iva = 0;
          this.total = 0;
          for (var i = 0; i < this.parts.length; i++) {
            this.subtotal += this.parts[i].precio * this.parts[i].cantidad;
          }
          this.iva = this.subtotal * 0.16;
          this.iva_ret_val = this.subtotal * 0.06;
          this.total = this.subtotal + this.iva;
          if (this.iva_ret != 0) {
            this.total = this.total - this.iva_ret_val;
          }
        }

        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", err.message);
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * This is a private function in TypeScript that sets the value of a property called "bill_to".
   * @param value - The value parameter is the new value that will be assigned to the bill_to property.
   */
  public setBillTo(value) :void {
    this.bill_to = value;
  }
  
  /**
   * This is a private function in TypeScript that sets the value of a ship_to property.
   * @param value - The value parameter is the new value that will be assigned to the ship_to property.
   */
  public setShipTo(value) :void {
    this.ship_to = value;
  }
  
  /**
   * This is a private function in TypeScript that sets the value of a pay property.
   * @param value - The value parameter is the new value that will be assigned to the pay property.
   */
  public setPay(value) :void {
    this.pay = value;
  }
  
  /**
   * The function sets the value of a coin and updates a variable if the value is equal to 1.
   * @param value - The value parameter is the new value that is being set for the "coin" property.
   */
  public setCoin(value) :void {
    this.coin = value;
    if (value == 1) this.iva_apl = 1;
  }
  
  /**
   * This is a private function in TypeScript that sets the attention_id property to a given value.
   * @param value - The value that will be assigned to the `attention_id` property.
   */
  public setAttentionTo(value) :void {
    this.attention_id = value;
  }
  
  /**
   * This is a private function in TypeScript that sets the value of a concept.
   * @param value - The value parameter is the new value that will be assigned to the concept property.
   */
  public setConcept(value) :void {
    this.concept = value;
  }
  
 /**
  * This is a private function in TypeScript that sets the value of a variable called "iva_apl".
  * @param value - The value parameter is the new value that will be assigned to the iva_apl property.
  */
 public setIVA(value) :void {
    this.iva_apl = value;
  }
  
  /**
   * This is a private function in TypeScript that sets a value to a variable and logs it to the
   * console.
   * @param value - The value to be set for the instance variable `iva_ret`.
   */
  public setIVARet(value) :void {
    this.iva_ret = value;
    console.log(this.iva_ret);
  }
  
  /**
   * This is a private function in TypeScript that validates and saves data for an ODC (Order Delivery
   * Confirmation) and launches a modal with a response message.
   * @param action - The "action" parameter is a number that determines the action to be taken after
   * validating the ODC data. If it is 0, the function will call the "getData()" method. If it is any
   * other number, the function will navigate to a different page using the Angular router.
   */
  public validateODVData(action) :void {
    let data: IODC = {
      id_odc: this.id_odc,
      identifier: this.identifier,
      bill: this.bill,
      notes: this.notes,
      id_days: this.pay,
      id_coin: String(this.coin),
      id_attention_to: this.attention_id,
      id_bill_to: this.bill_to,
      id_ship_to: this.ship_to,
      a_nombre: this.a_nombre,
      direccion: this.direccion,
      municipio: this.municipio,
      tiempo_entrega: this.tiempo_entrega,
      cotizacion: this.cotizacion,
      iva: this.iva_apl,
      iva_ret: this.iva_ret,
    };
    this.loader = 1;
    this.saleOrderService.saveEditODC(data).subscribe(
      (res) => {
        this.response = res;
        if (this.response.code != "0_005")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          if (action == 0) this.getData();
          else
            this.router.navigate([
              "/administration/sale_order/det/",
              this.id_odc,
            ]);
        }
        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", err.message);
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * The function validates changes made to a set of data and returns a boolean value indicating
   * whether any changes were made.
   * @returns a boolean value. If the sum of differences between the current values and the original
   * values of certain properties is greater than 0, it returns false. Otherwise, it returns true.
   */
  private validateChanges() :boolean {
    var identifier_val = this.data_general.consecutivo;
    var bill_val = this.data_general.factura;
    var notes_val = this.data_general.notas;
    var pay_val = this.data_general.id_tipo_pago;
    var coin_val = this.data_general.tipo_moneda;
    var cotizacion_val = this.data_general.cotizacion;
    var iva_apl_val = this.data_general.iva;

    var attention_id_val = this.data_general.id_atencion_a;
    var ship_to_val = this.data_general.id_ship_to;
    var bill_to_val = this.data_general.id_bill_to;

    var a_nombre_val = this.data_general.a_nombre;
    var tiempo_entrega_val = this.data_general.tiempo_entrega;
    var direccion_val = this.data_general.direccion;
    var municipio_val = this.data_general.municipio;

    var suma = 0;
    if (identifier_val != this.identifier) suma++;
    if (bill_val != this.bill) suma++;
    if (notes_val != this.notes) suma++;
    if (pay_val != this.pay) suma++;
    if (coin_val != this.coin) suma++;
    if (cotizacion_val != this.cotizacion) suma++;
    if (iva_apl_val != this.iva_apl) suma++;
    if (attention_id_val != this.attention_id) suma++;
    if (ship_to_val != this.ship_to) suma++;
    if (bill_to_val != this.bill_to) suma++;
    if (a_nombre_val != this.a_nombre) suma++;
    if (tiempo_entrega_val != this.tiempo_entrega) suma++;
    if (direccion_val != this.direccion) suma++;
    if (municipio_val != this.municipio) suma++;

    if (suma > 0) return false;
    else return true;
  }

  /**
   * This function validates data to add a part and sends a request to add the part to the sale order
   * service.
   */
  public validateDataToAddPart() :void {
    if (this.validateChanges()) {
      this.loader = 1;
      let part: IPartODC = {
        id_odc: this.id_odc,
        price: this.price_part,
        cantidad: this.quantity_part,
        description: this.desc_part,
        notes: this.notes_part,
        tag: this.tag,
        marca: this.marca,
        modelo: this.modelo,
        serie: this.serie,
        concept: this.concept,
      };

      this.saleOrderService.addPartODC(part).subscribe(
        (res) => {
          this.response = res;

          if (this.response.code != "0_005")
            this.lauchModal(this.response.code, this.response.message, "");
          else {
            this.getData();
          }
          this.loader = 0;
        },
        (err) => {
          this.lauchModal("0000x00", "Error de consulta", err.message);
          console.log(err);
          this.loader = 0;
        }
      );
    } else
      this.infoUser(
        "Advertencia",
        "No se han guardado los cambios en la información general."
      );
  }

  //Edicion de partidas
  /**
   * The function changes the value of a boolean variable called "edit".
   * @param val - val is a parameter of type boolean that is passed to the function changeToEdit(). It
   * is used to set the value of the variable 'edit' to either true or false, depending on the value of
   * val.
   */
  public changeToEdit(val) :void {
    this.edit = val;
  }

  /**
   * This function edits a specific part's properties based on the type of edit requested.
   * @param id - The id of the part that needs to be edited.
   * @param event - The event parameter is an object that represents the event that triggered the
   * function. It is used to access the value of the input field that was edited.
   * @param type - The "type" parameter is an integer value that determines which property of the
   * "part" object should be edited. The possible values and their corresponding properties are:
   */
  public editValue(id, event, type) :void {
    let edit = event.target.value;
    for (var i = 0; i < this.parts.length; i++) {
      if (this.parts[i].id == id) {
        let part: IPartODC = {
          id_det: this.parts[i].id,
          price: this.parts[i].precio,
          cantidad: this.parts[i].cantidad,
          description: this.parts[i].descripcion,
          notes: this.parts[i].notas,
          tag: this.parts[i].tag,
          marca: this.parts[i].marca,
          modelo: this.parts[i].modelo,
          serie: this.parts[i].serie,
          concept: this.parts[i].concepto,
        };

        if (type == 1) part.price = edit;
        if (type == 2) part.cantidad = edit;
        if (type == 3) part.description = edit;
        if (type == 4) part.marca = edit;
        if (type == 5) part.modelo = edit;
        if (type == 6) part.serie = edit;
        if (type == 7) part.tag = edit;
        if (type == 8) part.notes = edit;

        this.sendToEditPart(part);
      }
    }
  }

  /**
   * The function sends a value to edit a part and handles the response accordingly.
   * @param value - It is a parameter passed to the function `sendToEditPart()`. It is likely an object
   * or a value that contains data to be sent to the `saleOrderService` for editing.
   */
  private sendToEditPart(value) :void {
    let res_serv: any = [];
    this.loader = 1;
    this.saleOrderService.saveEditPart(value).subscribe(
      (res) => {
        res_serv = res;
        if (res_serv.code != "0_005")
          this.lauchModal(res_serv.code, res_serv.message, "");
        else {
          this.getData();
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", err.message);
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * The function confirms the deletion of a specific part by storing its ID, tag, and price.
   * @param id - The ID of the part that needs to be deleted.
   */
  public confirmDeletePart(id) :void {
    this.id_part_to_delete = id;

    for (var i = 0; i < this.parts.length; i++) {
      if (this.parts[i].id == id) {
        this.tag_part_to_delete = this.parts[i].tag;
        this.price_part_to_delete = this.parts[i].precio;
      }
    }
  }

  /**
   * This function sends a request to delete a part and handles the response accordingly.
   */
  public sendDeletePart() :void {
    let res_serv: any = [];
    this.saleOrderService.deleteEditPart(this.id_part_to_delete).subscribe(
      (res) => {
        res_serv = res;
        if (res_serv.code != "0_005")
          this.lauchModal(res_serv.code, res_serv.message, "");
        else if (res_serv.data == 1) {
          this.getData();
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", err.message);
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * This is a private function in TypeScript that launches a modal with a given code, title, and
   * message.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - A string that represents the title of the modal window.
   * @param message - The message parameter is likely a string that contains additional information or
   * instructions to be displayed in the modal dialog box.
   */
  private lauchModal(code: string, title: string, message) :void {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }

  /**
   * This is a private function in TypeScript that opens a modal with a title and message.
   * @param {string} title - A string representing the title of the modal window that will be
   * displayed.
   * @param message - The message parameter is a string that contains the message to be displayed in
   * the modal dialog box.
   */
  private infoUser(title: string, message) :void {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.another_cause = true;
    modalRef.componentInstance.code = "NA";
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
}
