import { Component, ElementRef, HostListener, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxImageCompressService, UploadResponse } from "ngx-image-compress-legacy";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { DepotService } from "src/app/services/depot.service";
import { environment } from "src/app/variables/enviroment";

interface NewDepot {
  id_entry?: number,
  type: string
}

@Component({
  selector: "app-depot-detail",
  templateUrl: "./depot-detail.component.html",
  styleUrls: ["./depot-detail.component.scss"],
})
export class DepotDetailComponent implements OnInit {
  uri: string = environment.uri;
  token = localStorage.getItem("token");
  api_key = localStorage.getItem("key");

  permmisions = JSON.parse(localStorage.getItem("permisos"));

  @ViewChild("cerrarModal") divView: ElementRef;
  peticion: any = null;

  public loader = 0;
  private response: any = [];
  public data: any = [];

  public id: string = "";
  public tipo: string = "";

  public partidas: any = [];
  public detalle: any = [];

  public new_status_parts: any = [];

  /* ESTADOS */
  public success_action: boolean = false;
  public can_edit_estatus: boolean = false;
  public select_all_parts: boolean = false;

  /* IMAGENES */
  public compressed: boolean = false;
  public img_result_multiple = [];
  public img_compress_result = [];

  public instrument_new_img = {
    tag: "",
    id_almacen: 0,
    no_imgs: 0,
  };

  public images_list = [];
  public have_images: number = 0;
  public full_size_img = {
    imagen: "",
    link: "",
  };

  public modal_open: boolean = false;
  public overlay_visible: boolean = false;
  public hide_images = false;
  public open_gallery = true;
  public success_img_upload = false;

  constructor(
    private modalService: NgbModal,
    private depotService: DepotService,
    private activedRoute: ActivatedRoute,
    private router: Router,
    private imageCompress: NgxImageCompressService
  ) {}

  ngOnInit(): void {
    this.activedRoute.paramMap.subscribe((params) => {
      this.tipo = params.get("tipo");
      this.id = params.get("id");
    });
    this.getDetailInOut();
  }

  ngOnDestroy() {
    if (this.peticion != null) this.peticion.unsubscribe();
  }

 /**
  * The function `getDetailInOut` retrieves detailed information about depot in and out transactions,
  * handling different response codes and displaying appropriate messages.
  */
  private getDetailInOut(): void {
    this.loader = 1;
    this.peticion = this.depotService
      .getDepotDetailInOut(this.id, this.tipo)
      .subscribe(
        (res) => {
          this.response = res;
          if (
            this.response.code == "1_0001" ||
            this.response.code == "1_0002" ||
            this.response.code == "1_0003"
          )
            this.lauchModal(this.response.code, this.response.message, "");
          else if (this.response.code == "1_0004")
            this.lauchModal(this.response.code, this.response.message, "");
          else {
            this.data = this.response.data;
            this.partidas = this.addCheckStatus(this.data.almacen_partidas);
            this.detalle = this.data.almacen_grupo;
          }

          this.loader = 2;
        },
        (err) => {
          this.lauchModal("0000x00", "Error de consulta", "");
          this.loader = 2;
        }
      );
  }

  /**
   * The `reload_window` function sets the type to "in", updates the id, navigates to a new URL, resets
   * an upload, and retrieves details for input/output.
   * @param id - The `id` parameter in the `reload_window` function is used to specify the identifier
   * of the window that needs to be reloaded. This identifier is then used to construct a URL for
   * navigating to a specific page using Angular's router.
   */
  public reload_window(id): void {
    this.tipo = "in";
    this.id = id;
    let url = "/operations/depot/det/" + this.tipo + "/" + this.id;

    this.router.navigate([url]);
    this.resetUpload();
    this.getDetailInOut();
  }

  /*    CAMBIO DE ESTATUS    */

  /**
   * The `editMode` function toggles the edit status and resets certain properties when edit mode is
   * turned off.
   */
  public editMode(): void {
    this.can_edit_estatus = !this.can_edit_estatus;
    if (this.can_edit_estatus === false) {
      this.new_status_parts = [];
      this.partidas.forEach((partida) => {
        partida.checked = false;
      });
    }
  }
  /**
   * The function `selectAllParts` sets the `checked` property of all parts in the `partidas` array to
   * a specified value and adds their IDs to a new array if `checked` is true.
   * @param {boolean} checked - The `checked` parameter is a boolean value that indicates whether a
   * part is selected or not. If `checked` is true, it means the part is selected; if false, it means
   * the part is not selected.
   */
  public selectAllParts(checked: boolean): void {
    this.new_status_parts = [];
    this.partidas.forEach((partida) => {
      const PART_ID = partida.id_almacen;

      partida.checked = checked;
      if (checked) this.new_status_parts.push(PART_ID);
    });
  }

  /**
   * The function `allPartsSelected` checks if all parts in `new_status_parts` have been selected.
   * @returns The function `allPartsSelected` is returning a boolean value. It checks if the length of
   * the `new_status_parts` array is equal to the length of the `partidas` array and returns `true` if
   * they are equal, indicating that all parts have been selected. Otherwise, it returns `false`.
   */
  public allPartsSelected(): boolean {
    return this.new_status_parts.length === this.partidas.length;
  }

  /**
   * The function `addPartToUpdateStatus` toggles the status of a part in an array based on its
   * presence and updates a corresponding property in an object.
   * @param id - The `id` parameter is the unique identifier of the part that needs to be updated in
   * the status.
   */
  public addPartToUpdateStatus(id): void {
    const PART_INDEX = this.new_status_parts.indexOf(id);

    const part = this.partidas.find((objeto) => objeto.id_almacen === id);

    if (PART_INDEX !== -1) {
      this.new_status_parts.splice(PART_INDEX, 1);
      part.checked = false;
    } else {
      this.new_status_parts.push(id);
      part.checked = true;
    }
  }

  /**
   * The function `updatePartStatus` updates the status of parts in a depot and handles the response
   * accordingly.
   * @param new_status - The `new_status` parameter in the `updatePartStatus` function represents the
   * new status that you want to update for a particular part in the system. This status could indicate
   * various states such as "in progress", "completed", "pending", "shipped", etc. When calling this
   * function,
   */
  public updatePartStatus(new_status): void {
    this.loader = 1;
    const send_parts = {
      depot_type: this.tipo,
      depot_estatus: new_status,
      depot_parts: this.new_status_parts,
      depot_group: this.id,
    };
    this.peticion = this.depotService
      .updateDepotStatusParts(send_parts)
      .subscribe(
        (res) => {
          this.response = res;
          if (
            this.response.code == "1_0001" ||
            this.response.code == "1_0002" ||
            this.response.code == "1_0003" ||
            this.response.code == "1_0004"
          )
            this.lauchModal(this.response.code, this.response.message, "");
          else {
            this.getDetailInOut();
            this.editMode();
            this.success_action = true;
          }

          this.loader = 2;
        },
        (err) => {
          this.lauchModal("0000x00", "Error de consulta", "");
          this.loader = 2;
        }
      );
  }

  /* CREAR SALIDA DESDE LA ENTRADA */
  /**
   * The function `canCreateDepotOut` calculates the number of parts available for shipment in a
   * TypeScript class.
   * @returns The function `canCreateDepotOut` is returning the number of parts that have a `salida`
   * value of 0 in the `partidas` array.
   */
  public canCreateDepotOut(): number {
    let avariable_parts: number = 0;

    this.partidas.forEach((partida) => {
      if (partida.salida == 0) {
        avariable_parts++;
      }
    });

    return avariable_parts;
  }

  /**
   * The function `newOutByEntryDepot` sends a request to create a new depot entry and handles the
   * response accordingly.
   */
  public newOutByEntryDepot(): void {
    const new_depot: NewDepot = {
      id_entry: parseInt(this.id, 10),
      type: this.tipo,
    };
    this.response = [];
    this.loader = 1;
    this.depotService.newOutByEntryDepot(new_depot).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003" ||
          this.response.code == "1_0004"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          const id_almacen = this.response.data.almacen_id;
          const tipo_entrada = this.response.data.type_entry;
          this.divView.nativeElement.click();
          this.router.navigate([
            "/operations/depot/edit",
            tipo_entrada,
            id_almacen,
          ]);
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /* PDF */
  /**
   * This is a private function in TypeScript that downloads a PDF file for a detail depot.
   * @param tipo_pdf - It is a parameter that specifies the type of PDF file to be downloaded. It is
   * passed as an argument to the function `descargarDefault()` and used in the URL to generate the
   * download link for the PDF file.
   */
  public descargarPdf(): void {
    this.loader = 1;
    this.depotService.downloadDepotFile(this.tipo, this.id).subscribe(
      (res) => {
        let data: any = res;
        if (
          data.code == "1_0001" ||
          data.code == "1_0002" ||
          data.code == "1_0003" ||
          data.code == "1_0004"
        )
          this.lauchModal(data.code, data.message, "");
        else {
          this.downloadFile(data.data);
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * The function `downloadFile` opens a new window to download a file from a specific URL.
   * @param file_name - The `file_name` parameter in the `downloadFile` function is a string that
   * represents the name of the file that you want to download.
   */
  private downloadFile(file_name): void {
    const url =
      environment.uri_server + "storage/public/almacen/generados/" + file_name;
    window.open(url, "_blank");
  }

  /* VER IMAGENES */
  /**
   * The function `getDepotImages` retrieves a list of images for a depot, processes the response data,
   * and updates the UI accordingly.
   */
  public getDepotImages(): void {
    this.loader = 1;
    this.peticion = this.depotService
      .getDepotImagesList(this.tipo, this.id)
      .subscribe(
        (res) => {
          this.response = res;
          if (
            this.response.code == "1_0001" ||
            this.response.code == "1_0002" ||
            this.response.code == "1_0003"
          )
            this.lauchModal(this.response.code, this.response.message, "");
          else if (this.response.code == "1_0004")
            this.lauchModal(this.response.code, this.response.message, "");
          else {
            this.images_list = this.response.data;
            this.have_images = Object.keys(this.images_list).length;
            if (this.have_images > 0) this.open_gallery = true;
            else this.open_gallery = false;

            for (const clave in this.images_list) {
              if (this.images_list.hasOwnProperty(clave)) {
                const list_images = this.images_list[clave];
                
                list_images.forEach((objeto) => {
                  objeto.link = `${environment.uri_server}storage/public/imagenes_almacen/${this.tipo}/${this.id}/${encodeURIComponent(objeto.imagen)}`;
                  objeto.cargada = true;
                });
              }
            }
          }

          this.loader = 2;
        },
        (err) => {
          this.lauchModal("0000x00", "Error de consulta", "");
          this.loader = 2;
        }
      );
  }
  /**
   * The function `getImageFullSize` sets the full-size image and opens a modal overlay.
   * @param imagen - The parameter `imagen` in the `getImageFullSize` function is typically used to
   * pass the image data or URL that you want to display in full size. It could be an image object,
   * image URL, or any other representation of the image that you want to show in a modal or overlay.
   */
  public getImageFullSize(imagen): void {
    this.full_size_img = imagen;
    this.modal_open = true;
    this.openOverlay();
  }

  imagenNoCargada(imagen) {
    imagen.cargada = false;
  }

  /* SUBIDA DE IMAGEN */
/**
 * The function `instrumentNewImage` assigns values to properties of an object and resets other
 * properties.
 * @param instrument - The `instrument` parameter seems to be an object with the following properties:
 */
  public instrumentNewImage(instrument): void {
    this.instrument_new_img = {
      tag: instrument.tag,
      id_almacen: instrument.id_almacen,
      no_imgs: instrument.no_imgs,
    };
    this.success_img_upload = false;
    this.img_result_multiple = [];
  }

  /**
   * The function `removeImageToUpload` removes an image from an array based on the file name provided.
   * @param file_name - The `file_name` parameter in the `removeImageToUpload` function is the name of
   * the file that you want to remove from the `img_result_multiple` array.
   */
  public removeImageToUpload(file_name): void {
    const indice_archivo = this.img_result_multiple.findIndex((imgs) => {
      return imgs.fileName === file_name;
    });

    this.img_result_multiple.splice(indice_archivo, 1);
  }

  /**
   * The function `uploadMultipleFiles` uploads multiple files and appends the results to an array.
   */
  public uploadMultipleFiles(): void {
    this.imageCompress
      .uploadMultipleFiles()
      .then((multipleOrientedFiles: UploadResponse[]) => {
        this.img_result_multiple = [
          ...this.img_result_multiple,
          ...multipleOrientedFiles,
        ];
      });
  }

/**
 * The `compressImgs` function compresses images if their byte count exceeds a specified limit and adds
 * them to an array of compressed images.
 */
  private async compressImgs(): Promise<void> {
    let cont: number = this.instrument_new_img.no_imgs + 1;
    const mega_byte = 1000000;

    const compression_promises: Promise<void>[] = [];

    const processImage = async (element: any): Promise<void> => {
      if (this.imageCompress.byteCount(element.image) > mega_byte) {
        const compressed_image = await this.imageCompress.compressFile(
          element.image,
          element.orientation,
          50,
          50
        );

        const img_compressed = {
          fileName: this.renameImgs(element.fileName, cont),
          image: compressed_image,
          orientation: element.orientation,
        };

        this.img_compress_result.push(img_compressed);
        cont++;
      } else {
        element.fileName = this.renameImgs(element.fileName, cont);
        this.img_compress_result.push(element);
        cont++;
      }
    };

    for (const element of this.img_result_multiple) {
      const compression_promise = processImage(element);
      compression_promises.push(compression_promise);
    }

    await Promise.all(compression_promises);
  }

  /**
   * The function `renameImgs` renames images by appending specific details and a counter to the
   * original file name.
   * @param {string} actual_name - The `actual_name` parameter is a string that represents the original
   * name of an image file.
   * @param {number} contador - The `contador` parameter is a number that is used to keep track of the
   * count of images being renamed. It is incremented each time a new image is processed in order to
   * ensure that each image has a unique name.
   * @returns The function `renameImgs` is returning a string that is constructed using the following
   * template:
   * `${this.detalle.folio}_${this.instrument_new_img.tag}_${this.instrument_new_img.id_almacen}_.`.
   */
  private renameImgs(actual_name: string, contador: number): string {
    const formatos: string[] = actual_name.split(".");
    const extension: string = formatos[formatos.length - 1];

    return `${this.detalle.folio}_${this.instrument_new_img.tag}_${this.instrument_new_img.id_almacen}_${contador}.${extension}`;
  }

  /**
   * The `uploadImages` function in TypeScript asynchronously compresses and uploads images using
   * FormData to a server endpoint, handling different response scenarios accordingly.
   */
  public async uploadImages(): Promise<void> {
    this.loader = 1;
    await this.compressImgs();

    let form = new FormData();
    this.img_compress_result.forEach((imagen) => {
      const data_blob = this.dataURItoBlob(imagen.image);
      const file_upload = this.blobToFile(data_blob, imagen.fileName);
      form.append("imagenes[]", file_upload);
    });
    form.append("part_id", this.instrument_new_img.id_almacen.toString());
    form.append("depot_type", this.tipo);
    form.append("depot_group", this.id);

    this.peticion = this.depotService.addPartImages(form).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_007"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.resetUpload();
          this.divView.nativeElement.click();
          this.getDetailInOut();
        }
        this.success_img_upload = true;
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * The function `resetUpload` resets various image-related properties to their initial values.
   */
  private resetUpload(): void {
    this.img_compress_result = [];
    this.img_result_multiple = [];
    this.images_list = [];
    this.have_images = 0;
    this.hide_images = false;
  }

 /**
  * The function `onFileSelected` in TypeScript reads and compresses an image file selected by the
  * user, storing the image data and orientation in an array.
  * @param {any} event - The `event` parameter in the `onFileSelected` function represents the event
  * that occurred when a file is selected by the user, typically through an input element of type file.
  * This event contains information about the selected file, such as its name and other properties. In
  * the provided code snippet, the
  */
  public onFileSelected(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.imageCompress
        .getOrientation(file)
        .then((orienta) => {
          let data_url = {
            fileName: file.name,
            image: "",
            orientation: orienta,
          };

          const reader = new FileReader();
          reader.onload = () => {
            const img_url = reader.result as string;
            data_url.image = img_url;

            this.img_result_multiple.push(data_url);
          };
          reader.readAsDataURL(file);
        })
        .catch((error) => {
          this.lauchModal("0000x00", "Error de consulta", "");
        });
    }
  }
  /* UTILITIES */
  /**
   * The function `blobToFile` converts a Blob object into a File object with a specified file name.
   * @param {Blob} blob - A Blob object representing raw data, such as a file or image.
   * @param {string} fileName - The `fileName` parameter is a string that represents the name you want
   * to give to the file when it is created. It is used to specify the name of the file that will be
   * created from the blob data.
   * @returns The function `blobToFile` returns a `File` object created from the provided `Blob`
   * object, with the specified file name and type.
   */
  private blobToFile(blob: Blob, fileName: string): File {
    return new File([blob], fileName, { type: blob.type });
  }

  /**
   * The function `dataURItoBlob` converts a data URI string to a Blob object.
   * @param data_uri - The `data_uri` parameter in the `dataURItoBlob` function is a string that
   * represents a data URI. It typically consists of a MIME type and the data itself, encoded as a
   * base64 string. For example, a data URI might look like this: "data:image/png;base64
   * @returns The function `dataURItoBlob` returns a Blob object.
   */
  private dataURItoBlob(data_uri): Blob {
    const byte_string = atob(data_uri.split(",")[1]);
    const mime_string = data_uri.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byte_string.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byte_string.length; i++) {
      ia[i] = byte_string.charCodeAt(i);
    }
    return new Blob([ab], { type: mime_string });
  }

  /**
   * The function `addCheckStatus` sets the `checked` property to `false` for each element in a given
   * list.
   * @param list - The `list` parameter in the `addCheckStatus` function is an array of elements that you
   * want to update by adding a `checked` property to each element and setting it to `false`.
   * @returns An array of elements with the "checked" property set to false.
   */
  public addCheckStatus(list): any[] {
    list.forEach((element) => {
      element.checked = false;
    });
    return list;
  }

  /* The above code is using a HostListener decorator in Angular to listen for the "wheel" event on the
host element. When the event is triggered, the onWheel method is called with the event object as a
parameter. Within the method, it checks if the overlay_visible property is true, and if so, it calls
event.preventDefault() to prevent the default behavior of the wheel event. This is typically used to
prevent scrolling when an overlay is visible on the screen. */
  @HostListener("wheel", ["$event"])
  onWheel(event: WheelEvent): void {
    if (this.overlay_visible) {
      event.preventDefault();
    }
  }
  /**
   * The function `openOverlay` sets a flag to make an overlay visible and disables scrolling on the
   * body element.
   */
  public openOverlay(): void {
    this.overlay_visible = true;
    document.body.style.overflow = "hidden";
  }

  /**
   * The closeOverlay function sets the overlay and modal visibility to false and restores the body
   * overflow to auto.
   */
  public closeOverlay(): void {
    this.overlay_visible = false;
    this.modal_open = false;
    document.body.style.overflow = "auto";
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code, title, and
   * message using the ModalComponent.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - The title of the modal that will be displayed.
   * @param message - The message parameter is a string that represents the message to be displayed in
   * the modal dialog.
   */
  private async lauchModal(
    code: string,
    title: string,
    message
  ): Promise<void> {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
}