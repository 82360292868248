import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/app/variables/enviroment';

@Component({
  selector: 'app-modal-pdf',
  templateUrl: './modal-pdf.component.html',
  styleUrls: ['./modal-pdf.component.css'],
})
export class ModalPdfComponent implements OnInit {

  @Input() url_almacenamiento:string='';
  @Input() doc_name:string= '';
  url_pdf:SafeResourceUrl;

  constructor(public activeModal: NgbActiveModal,
    public config: NgbModalConfig,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.url_pdf = this.sanitizer.bypassSecurityTrustResourceUrl(environment.uri_server+this.url_almacenamiento+this.doc_name);
  }

  public onCloseModal(): void {
    this.modalService.dismissAll();
  }


}
