import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-operations",
  templateUrl: "./operations.component.html",
  styleUrls: ["./operations.component.css"],
})
export class OperationsComponent implements OnInit {
  permmisions = JSON.parse(localStorage.getItem("permisos"));
  options: any[] = [];

  constructor() {}

  ngOnInit(): void {
    this.validatePermmissions();
  }

  /**
   * The function adds various options to an array based on the user's permissions.
   */
  private validatePermmissions() :void {
    if (this.permmisions.includes(4)) {
      let uri = {
        path: "/operations/rs",
        title: "RS",
        icon: "fa-clipboard-check",
        class: "",
      };
      this.options.push(uri);
    }
    /*
    if(this.permmisions.includes(4)){
      let uri = { path: '/sale_order', title: 'Almacén',  icon: 'fa-archive', class: '' };
      this.options.push(uri);
    }*/
    if (
      this.permmisions.includes(43) ||
      this.permmisions.includes(44) ||
      this.permmisions.includes(45)
    ) {
      let uri = {
        path: "/operations/reportmaker",
        title: "G. de Informes",
        icon: "fa-file-code",
        class: "",
      };
      this.options.push(uri);
    }
    if (this.permmisions.includes(39)) {
      let uri = {
        path: "/operations/qrmaker",
        title: "Generador QR",
        icon: "fa-qrcode",
        class: "",
      };
      this.options.push(uri);
    }
    /*
    if(this.permmisions.includes(4)){
      let uri = { path: '/operations/sensors', title: 'Sensores 4.0',  icon: 'fa-info-circle', class: '' };
      this.options.push(uri);
    }*/
    if (this.permmisions.includes(75)) {
      let uri = {
        path: "/operations/generator-xlsx",
        title: "G. Reportes",
        icon: "fa-file-excel",
        class: "",
      };
      this.options.push(uri);
    }
    if (this.permmisions.includes(28)) {
      let uri = {
        path: "/operations/wiki",
        title: "WIKI",
        icon: "fa-book",
        class: "",
      };
      this.options.push(uri);
    }

    if (this.permmisions.includes(100) || this.permmisions.includes(98)) {
      let uri = {
        path: "/operations/depot",
        title: "Almacén",
        icon: "fa-dolly-flatbed",
        class: "",
      };
      this.options.push(uri);
    }

    if (this.permmisions.includes(118)) {
      let uri = {
        path: "/operations/vehicle",
        title: "Vehiculos",
        icon: "fa-car-alt",
        class: "",
      };
      this.options.push(uri);
    }

    if(this.permmisions.includes(181)){
      let uri = { path: '/operations/foreign-service', title: 'Almacén Interno',  icon: 'fa-solid fa-warehouse', class: '' };
      this.options.push(uri);
    }
  }
  
}
