import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { DepotService } from "src/app/services/depot.service";
import { OrdenVentaService } from "src/app/services/orden-venta.service";

interface newDepot {
  id_cliente?: number,
  id_odv?: number,
  id_entry?: number,
  type: string
}

@Component({
  selector: "app-depot",
  templateUrl: "./depot.component.html",
  styleUrls: ["./depot.component.css"],
})
export class DepotComponent implements OnInit {
  @ViewChild("btn_close_modal") divView: ElementRef;
  permmisions = JSON.parse(localStorage.getItem("permisos"));

  public loader: number = 1;
  private response: any = [];
  public list: any = [];

  public clients_result: any = [];
  public odvs_results: any = [];
  public entry_depot_result: any = [];

  public send_new_depot: newDepot = {
    id_cliente: -1,
    id_entry: 0,
    type: "",
    id_odv: 0,
  };

  /* PAGINATION */
  public number_page: number = 0;
  public pages: number = 0;

  constructor(
    private odvService: OrdenVentaService,
    private modalService: NgbModal,
    private router: Router,
    private depotService: DepotService
  ) {}

  ngOnInit(): void {
    this.getInOutList();
  }

  /**
   * The function `getInOutList` retrieves a list of in and out depot items and handles the response
   * accordingly.
   */
  public getInOutList(): void {
    this.loader = 1;
    this.depotService.getInOutDepotList().subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003" ||
          this.response.code == "1_0004"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.list = this.response.data;
          this.pages = this.list.length;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * The function `getInList` retrieves a list of items from a depot service and handles the response
   * accordingly.
   */
  public getInList(): void {
    this.loader = 1;
    this.depotService.getInDepotList().subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003" ||
          this.response.code == "1_0004"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.list = this.response.data;
          this.pages = this.list.length;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * The function `getOutList` retrieves a list of items from a depot and handles the response
   * accordingly.
   */
  public getOutList(): void {
    this.loader = 1;
    this.depotService.getOutDepotList().subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003" ||
          this.response.code == "1_0004"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.list = this.response.data;
          this.pages = this.list.length;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * The function `addNewDepot` sends a request to add a new depot and handles the response
   * accordingly.
   */
  public addNewDepot(): void {
    this.response = [];
    this.loader = 1;
    this.depotService.addDepotInOut(this.send_new_depot).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003" ||
          this.response.code == "1_0004"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          const id_almacen = this.response.data.almacen_id;
          const tipo_entrada = this.response.data.type_entry;
          this.divView.nativeElement.click();
          this.router.navigate([
            "/operations/depot/edit",
            tipo_entrada,
            id_almacen,
          ]);
        }

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }
  /* NEW DEPOT BY CLIENT */

  /**
   * This function searches for a client using a client search parameter and displays the search
   * results or an error message.
   */
  public searchClient(cliente): void {
    this.loader = 1;
    this.depotService.getClientSearch(cliente).subscribe(
      (res) => {
        this.clients_result = res;
        if (
          this.clients_result.code == "1_0001" ||
          this.clients_result.code == "1_0002" ||
          this.clients_result.code == "1_0003" ||
          this.clients_result.code == "1_0004"
        )
          this.lauchModal(
            this.clients_result.code,
            this.clients_result.message,
            ""
          );
        else {
          this.clients_result = this.clients_result.data;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * This function sets the client ID, ODV ID, and ODVS results to default values.
   * @param id - The parameter "id" is the client ID that is being passed to the function. It is used
   * to set the value of the "client_id" property of the object.
   */
  public setClient(id): void {
    this.send_new_depot.id_cliente = id;
    this.send_new_depot.id_odv = 0;
    this.odvs_results = [];
  }

  /* NEW DEPOT BY SALE ORDER */

  /**
   * This function sets the ODV ID, client ID, and clients result to default values.
   * @param id - The parameter "id" is a value that is passed into the function "setODV" and is used to
   * set the value of the "odv_id" property of the object.
   */
  public setODV(id): void {
    this.send_new_depot.id_odv = id;
    this.send_new_depot.id_cliente = -1;
    this.clients_result = [];
  }

  /**
   * This is a private function in TypeScript that searches for ODV and launches a modal based on the
   * response code.
   * @param value - The parameter "value" is the input value used to search for ODVs (Order Delivery
   * Vouchers) in the system.
   */
  public searchODV(value): void {
    this.loader = 1;
    let resp: any = [];
    this.odvService.searchODVForAddDepotEntry(value).subscribe(
      (res) => {
        resp = res;
        if (
          resp.code == "1_0001" ||
          resp.code == "1_0002" ||
          resp.code == "1_0003" ||
          resp.code == "1_0004"
        )
          this.lauchModal(resp.code, resp.message, "");
        else {
          this.odvs_results = resp.data;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * The function adds a request for a depot with ODV and handles the response accordingly.
   */
  public newOdvDepot(): void {
    this.response = [];
    this.loader = 1;
    this.depotService.newOdvDepotIn(this.send_new_depot).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003" ||
          this.response.code == "1_0004"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          const id_almacen = this.response.data.almacen_id;
          const tipo_entrada = this.response.data.type_entry;
          this.divView.nativeElement.click();
          this.router.navigate([
            "/operations/depot/edit",
            tipo_entrada,
            id_almacen,
          ]);
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /* NEW DEPOT BY IN-DEPOT */

  /**
   * This function sets the ODV ID, client ID, and clients result to default values.
   * @param id - The parameter "id" is a value that is passed into the function "setODV" and is used to
   * set the value of the "odv_id" property of the object.
   */
  public setEntry(id): void {
    this.send_new_depot.id_entry = id;
    this.send_new_depot.id_cliente = -1;
    this.clients_result = [];
  }
  /**
   * This is a private function in TypeScript that searches for ODV and launches a modal based on the
   * response code.
   * @param value - The parameter "value" is the input value used to search for ODVs (Order Delivery
   * Vouchers) in the system.
   */
  public searchEntryDepot(value): void {
    this.loader = 1;
    let response: any = [];
    this.depotService.getByEntryDepotOut(value).subscribe(
      (res) => {
        response = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003" ||
          response.code == "1_0004"
        )
          this.lauchModal(response.code, response.message, "");
        else {
          this.entry_depot_result = response.data;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * The function `newOutByEntryDepot` sends a request to the `depotService` to create a new outgoing
   * entry in the depot, and then handles the response accordingly.
  */
  public newOutByEntryDepot(): void {
    this.response = [];
    this.loader = 1;
    this.depotService.newOutByEntryDepot(this.send_new_depot).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003" ||
          this.response.code == "1_0004"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          const id_almacen = this.response.data.almacen_id;
          const tipo_entrada = this.response.data.type_entry;
          this.divView.nativeElement.click();
          this.router.navigate([
            "/operations/depot/edit",
            tipo_entrada,
            id_almacen,
          ]);
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /* COMPONENT UTILITY */

  /**
   * The function clears the client ID and client results array.
   */
  public clearDataClient(): void {
    this.send_new_depot.id_cliente = -1;
    this.send_new_depot.id_odv = 0;
    this.send_new_depot.id_entry = 0;
    this.send_new_depot.type = "";
    this.clients_result = [];
    this.odvs_results = [];
    this.entry_depot_result = [];
  }

  /**
   * The function changes the current page results to the specified page number.
   * @param page - The "page" parameter is a variable that represents the page number that the user
   * wants to navigate to. The "changePageResults" function takes this parameter and updates the
   * "number_page" variable with the new page number.
   */
  public changePageResults(page): void {
    this.number_page = page;
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code, title, and
   * message using the ModalComponent.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - The title of the modal that will be displayed.
   * @param message - The message parameter is a string that represents the message to be displayed in
   * the modal dialog.
   */
  private async lauchModal(
    code: string,
    title: string,
    message
  ): Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
}
