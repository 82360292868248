import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { InstrumentService } from 'src/app/services/instrument.service';
import { IBrand } from 'src/app/services/models/IBrand';
import { IInstrument } from 'src/app/services/models/IInstrument';
import { environment } from 'src/app/variables/enviroment';

@Component({
  selector: "app-detail",
  templateUrl: "./detail.component.html",
  styleUrls: ["./detail.component.css"],
})
export class DetailComponent implements OnInit {
  uri: string = environment.uri;
  //uri:string = "http://ngdat.ucal.com.mx/api/";
  img_err = false;
  img_selected_name = "";
  token = localStorage.getItem("token");
  api_key = localStorage.getItem("key");

  id_instrument: string;
  loader: number = 1;
  response: any = [];
  data: any = [];
  permmisions = JSON.parse(localStorage.getItem("permisos"));

  src_image =
    "https://img.freepik.com/vector-gratis/ilustracion-icono-doodle-engranaje_53876-5596.jpg?size=338&ext=jpg";

  url_to_load = "";
  comment = "";

  file: any;
  img_file: any;
  file_name = "";
  date_t = "";

  //Variables para la edicion
  edit_data: boolean = false;
  edit_tag = "";
  edit_model = "";
  edit_serie = "";
  edit_frecuency = "";
  edit_ubi = "";
  edit_area = "";
  edit_interval = "";
  edit_patron = "";
  edit_tole = "";
  edit_inf_pat = "";
  level = "";
  tEquipo: string;
  typeListInstrument: any = [];
  typeSelect: number = null;

  depto_list: any = [];
  depto_id = 0;
  active = 0;
  direccion_cliente: any = [];

  file_manual = "";
  descripcion_manual = "";

  new_manual = 0;
  manual_list = [];
  manual_select = "";
  manuales = [];

  //brands
  brand_list: any = [];
  mp_id_marca = 0;

  brand_selected:string = '';
  add_brand: boolean = false;
  search_brand: boolean = false;
  brand_search: string = "";
  new_notes: string = "N/A";
  new_brand: IBrand = {
    brand: "",
    notes: "N/A",
  };

  //@ViewChild("btn_close_modal") divView: ElementRef;
  constructor(
    private activedRoute: ActivatedRoute,
    private instument_service: InstrumentService,
    private modalService: NgbModal,
    private config: NgbModalConfig
  ) {
    config.backdrop = "static";
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.id_instrument =
      this.activedRoute.snapshot.paramMap.get("id_instrument");
    this.loadData();
    this.getListManual();
  }

  loadData() {
    this.loader = 1;
    this.instument_service.getInstrumentDetail(this.id_instrument).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.data = this.response.data;
          this.direccion_cliente = this.data.direccion_cliente;

          this.active = this.data.activo;

          this.depto_id = this.data.id_depto;
          this.mp_id_marca = this.data.id_m;
          this.edit_tag = this.data.tag;
          this.edit_model = this.data.modelo;
          this.edit_serie = this.data.serie;
          this.edit_frecuency = this.data.frecuencia;

          this.edit_ubi = this.data.ubicacion;
          this.edit_area = this.data.area;
          this.edit_interval = this.data.intervalo;
          this.edit_patron = this.data.equipo_patron;
          this.edit_tole = this.data.tolerancia;
          this.edit_inf_pat = this.data.informacion_patron;
          this.manuales = this.data.manual;
          this.brand_selected = this.data.marca;

          if (this.data.imagen == "default.png") {
            this.src_image =
              "https://img.freepik.com/vector-gratis/ilustracion-icono-doodle-engranaje_53876-5596.jpg?size=338&ext=jpg";
          } else {
            this.src_image =
              environment.uri_server +
              "storage/public/fotos_equipos/" +
              this.data.imagen;
          }
          this.data.permanente == "1"
            ? (this.level = "Permanente")
            : (this.level = "Temporal");
        }
        this.loader = 2;
      },
      (err) => {
        console.log(err);
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  loadInstrumentFile(value) {
    this.img_file = value.target;
    this.img_selected_name = this.img_file.files[0].name;
  }

  sendPictureToSave() {
    this.loader = 1;
    let form = new FormData();
    form.append("file", this.img_file.files[0]);
    form.append("id_instrument", this.id_instrument);

    this.instument_service.loadInstrumentImage(form).subscribe(
      (res) => {
        this.response = res;
        this.loader = 2;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003" ||
          this.response.code == "1_0004" ||
          this.response.code == "0_007"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "0_005") {
          if (this.response.data == "0") {
            this.img_err = true;
          } else {
            this.img_err = false;
            this.loadData();
          }
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  getUri() {
    this.url_to_load =
      this.uri +
      "get_qr_instrument?api_key=" +
      this.api_key +
      "&token=" +
      this.token +
      "&module=38&id_inst=" +
      this.id_instrument;
  }

  loadComment() {
    this.loader = 1;
    let form = new FormData();
    form.append("comment", this.comment);
    form.append("instrument", this.id_instrument);
    this.instument_service.setComment(form).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003" ||
          this.response.code == "1_0004" ||
          this.response.code == "0_007"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.comment = "";
          this.loadData();
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  loadFile(value) {
    this.file = value.target;
    this.file_name = this.file.files[0].name;
  }

  loadFileManual(value) {
    this.file = value.target;
    this.file_manual = this.file.files[0].name;
  }

  loadDate(value) {
    this.date_t = value;
  }

  saveFileInServer() {
    let result: any = [];
    this.loader = 1;
    let form = new FormData();
    form.append("file", this.file.files[0]);
    form.append("id_equipo", this.id_instrument);
    form.append("date_t", this.date_t);

    this.instument_service.saveReport(form).subscribe(
      (res) => {
        result = res;
        if (
          result.code == "1_0001" ||
          result.code == "1_0002" ||
          result.code == "1_0003"
        )
          this.lauchModal(result.code, result.message, "");
        else if (
          result.code == "1_0004" ||
          result.code == "0_007" ||
          result.code == "0_008"
        )
          this.lauchModal(result.code, result.message, "");
        else {
          if (result.data == 1) {
            this.file_name = "";
            this.date_t = "";
            this.loadData();
          }
        }
        //this.divView.nativeElement.click();
        this.loader = 2;
      },
      (err) => {
        //this.divView.nativeElement.click();
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err.error);
        this.loader = 2;
      }
    );
  }

  changeToEdit() {
    this.edit_data = !this.edit_data;
    if (this.edit_data == false) this.active = this.data.activo;
  }

  saveEditatoInstrument() {
    this.loader = 1;
    let obj: IInstrument = {
      id: this.id_instrument,
      ubication: this.edit_ubi,
      area: this.edit_area,
      interval: this.edit_interval,
      tolerancia: this.edit_tole,
      tag: this.edit_tag,
      model: this.edit_model,
      serie: this.edit_serie,
      frecuency: this.edit_frecuency,
      patron: this.edit_patron,
      patron_info: this.edit_inf_pat,
      id_brand: this.mp_id_marca,
      id_depto: this.depto_id,
      active: this.active,
    };

    this.instument_service.saveEdit(obj).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.loadData();
          this.edit_data = false;
        }
        this.loader = 2;
      },
      (err) => {
        console.log(err);
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  serchInstrumentType() {
    this.loader = 1;
    this.instument_service.searchInstrumentType(this.tEquipo).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.typeListInstrument = this.response.data;
        }
        this.loader = 2;
      },
      (err) => {
        console.log(err);
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  chengeInstrumentToPermanent() {
    this.loader = 1;
    this.instument_service
      .changeInstrumentPermanent(this.id_instrument, this.typeSelect)
      .subscribe(
        (res) => {
          this.response = res;
          if (
            this.response.code == "1_0001" ||
            this.response.code == "1_0002" ||
            this.response.code == "1_0003"
          )
            this.lauchModal(this.response.code, this.response.message, "");
          else if (this.response.code == "1_0004")
            this.lauchModal(this.response.code, this.response.message, "");
          else {
            this.loadData();
          }
          this.loader = 2;
        },
        (err) => {
          console.log(err);
          this.lauchModal("0000x00", "Error de consulta", "");
          this.loader = 2;
        }
      );
  }

  public searchBrand(data): void {
    this.loader = 1;
    this.brand_search = data.target.value;

    this.instument_service.searchBrand(this.brand_search).subscribe(
      (res) => {
        let data: any = res;
        if (
          data.code == "1_0001" ||
          data.code == "1_0002" ||
          data.code == "1_0003"
        )
          this.lauchModal(data.code, data.message, "");
        else if (data.code == "1_0004")
          this.lauchModal(data.code, data.message, "");
        else {
          this.brand_list = data.data;
        }
        this.search_brand = true;
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  public agregarMarca(): void {
    this.loader = 1;
    this.new_brand.isNew = true;
    this.instument_service.saveNewBrandRegister(this.new_brand).subscribe(
      (res) => {
        let data: any = res;
        this.loader = 2;
        if (
          data.code == "1_0001" ||
          data.code == "1_0002" ||
          data.code == "1_0003"
        )
          this.lauchModal(data.code, data.message, "");
        else if (data.code == "1_0004")
          this.lauchModal(data.code, data.message, "");
        else {
          this.lauchModal(
            "1",
            "Operación exitosa",
            `Se ha creado la marca ${this.new_brand.brand} correctamente.`
          );
          this.puedeAgregarMarca();
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }
  public puedeAgregarMarca(): void {
    if (this.add_brand === false) {
      this.add_brand = true;
      this.new_brand.brand = this.brand_search;
    } else {
      this.add_brand = false;
      this.brand_list = [];
      this.brand_search = "";
      this.search_brand = false;
      this.new_brand.notes = "N/A";
    }
  }

  searchDepto(data) {
    this.loader = 1;
    let value = data.target.value;

    this.instument_service.searchDepto(this.id_instrument, value).subscribe(
      (res) => {
        console.log(res);
        let data: any = res;
        if (
          data.code == "1_0001" ||
          data.code == "1_0002" ||
          data.code == "1_0003"
        )
          this.lauchModal(data.code, data.message, "");
        else if (data.code == "1_0004")
          this.lauchModal(data.code, data.message, "");
        else {
          this.depto_list = data.data;
        }
        this.loader = 2;
      },
      (err) => {
        console.log(err);
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  changeInstrumentActive() {
    this.active = this.active == 1 ? 0 : 1;
  }

  private async lauchModal(
    code: string,
    title: string,
    message
  ): Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }

  cargarManual() {
    if (this.new_manual == 0) {
      this.new_manual = 1;
    } else {
      this.new_manual = 0;
    }
  }

  getListManual() {
    this.loader = 1;
    this.instument_service.getListManual().subscribe(
      (res) => {
        let data: any = res;
        if (
          data.code == "1_0001" ||
          data.code == "1_0002" ||
          data.code == "1_0003"
        )
          this.lauchModal(data.code, data.message, "");
        else if (data.code == "1_0004")
          this.lauchModal(data.code, data.message, "");
        else {
          this.manual_list = data.data;
        }
        this.loader = 2;
      },
      (err) => {
        console.log(err);
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  setManualtoInstrument() {
    this.loader = 1;
    this.instument_service
      .asignarManual(this.manual_select, this.id_instrument)
      .subscribe(
        (res) => {
          let data: any = res;
          if (
            data.code == "1_0001" ||
            data.code == "1_0002" ||
            data.code == "1_0003"
          )
            this.lauchModal(data.code, data.message, "");
          else if (data.code == "1_0004")
            this.lauchModal(data.code, data.message, "");
          else {
            if (data.data == 1) {
              this.loadData();
            } else {
              this.lauchModal(
                "Error",
                "Ocurrió un error al asignar manual.",
                ""
              );
            }
          }
          this.loader = 2;
        },
        (err) => {
          console.log(err);
          this.lauchModal("0000x00", "Error de consulta", "");
          this.loader = 2;
        }
      );
  }

  saveManualInServer() {
    if (this.new_manual == 0) this.setManualtoInstrument();
    else {
      let result: any = [];
      this.loader = 1;
      let form = new FormData();
      form.append("file", this.file.files[0]);
      form.append("id_equipo", this.id_instrument);
      form.append("descripcion", this.descripcion_manual);

      this.instument_service.saveManual(form).subscribe(
        (res) => {
          result = res;
          if (
            result.code == "1_0001" ||
            result.code == "1_0002" ||
            result.code == "1_0003"
          )
            this.lauchModal(result.code, result.message, "");
          else if (
            result.code == "1_0004" ||
            result.code == "0_007" ||
            result.code == "0_008"
          )
            this.lauchModal(result.code, result.message, "");
          else {
            if (result.data == 1) {
              this.file_name = "";
              this.date_t = "";
              this.loadData();
              this.getListManual();
            } else
              this.lauchModal(
                "Error",
                "Ocurrió un error al guardar el manual.",
                ""
              );
          }
          this.loader = 2;
        },
        (err) => {
          //this.divView.nativeElement.click();
          this.lauchModal("0000x00", "Error de consulta", "");
          console.log(err.error);
          this.loader = 2;
        }
      );
    }
  }

  seeFile(file_name) {
    var url =
      environment.uri_server + "storage/public/manuales_equipos/" + file_name;
    window.open(url, "_blank");
  }

  public calculateEndDate() : string {
    const frecuencia = Number(this.edit_frecuency);
    const fecha_calibracion = new Date(this.date_t);

    fecha_calibracion.setMonth(fecha_calibracion.getMonth() + frecuencia);
    const anio = fecha_calibracion.getFullYear();
    const mes = fecha_calibracion.getMonth() + 1;
    const dia = fecha_calibracion.getDate();

    return `${dia.toString().padStart(2, '0')}-${mes.toString().padStart(2, '0')}-${anio}`;
  }
}
