import { ThrowStmt } from "@angular/compiler";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { VehicleService } from "src/app/services/vehicle.service";

@Component({
  selector: "app-vehicle",
  templateUrl: "./vehicle.component.html",
  styleUrls: ["./vehicle.component.css"],
})
export class VehicleComponent implements OnInit {
  loader = true;
  response: any = [];
  list: any = [];

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private vehicleService: VehicleService
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  /**
   * The function retrieves data from a vehicle service and handles different response codes.
   */
  private getData(): void {
    this.loader = true;

    this.vehicleService.getAllVehicles().subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message);
        else this.list = this.response.data;
        this.loader = false;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = false;
      }
    );
  }

  /**
   * This function launches a modal with a given code and title using the Angular Material modal
   * service.
   * @param {string} code - string - This parameter represents the code that will be displayed in the
   * modal window.
   * @param {string} title - The title parameter is a string that represents the title of the modal that
   * will be launched. It will be displayed at the top of the modal to provide context to the user.
   */
  private async lauchModal(code: string, title: string): Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }
}
