import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { SaleOrderService } from "src/app/services/sale-order.service";
import { SuppliersService } from "src/app/services/suppliers.service";

@Component({
  selector: "app-suppliers",
  templateUrl: "./suppliers.component.html",
  styleUrls: ["./suppliers.component.css"],
})
export class SuppliersComponent implements OnInit {
  permmisions = JSON.parse(localStorage.getItem("permisos"));
  loader = 1;
  list: any = [];
  response: any = [];
  number_page = 0;
  pages = 0;

  constructor(
    private suppliersService: SuppliersService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.getData();
  }

  /**
   * The function retrieves a list of suppliers from a service and handles the response accordingly.
   */
  private getData(): void {
    this.suppliersService.getList().subscribe(
      (res) => {
        this.response = res;

        if (this.response.code != "0_005")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.list = this.response.data;
          this.pages = this.list.length;
        }
        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", err.message);
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * The function changes the current page number to the specified page.
   * @param page - The "page" parameter is a variable that represents the page number that the user
   * wants to navigate to. The "changePageResults" function takes this parameter and sets the
   * "number_page" variable to the value of "page". This function is likely used in a pagination system
   * to allow the user to
   */
  public changePageResults(page): void {
    this.number_page = page;
  }

  /**
   * This is a TypeScript function that launches a modal with a given code, title, and message.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - A string representing the title of the modal window that will be launched.
   * @param message - The message parameter is a string that represents the message to be displayed in
   * the modal dialog box.
   */
  private async lauchModal(
    code: string,
    title: string,
    message
  ): Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
}
