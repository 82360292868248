import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { CustomerService } from "src/app/services/customer.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-add-customer-user",
  templateUrl: "./add-customer-user.component.html",
  styleUrls: ["./add-customer-user.component.css"],
})
export class AddCustomerUserComponent implements OnInit {
  permmisions = JSON.parse(localStorage.getItem("permisos"));
  id_customer: string = "";
  response: any = [];
  loader: number = 1;
  data: any = [];
  //Variables para usuario
  puesto: string = "";
  nombre: string = "";
  apellidos: string = "";
  extension: string = "";
  telefono: string = "";
  correo: string = "";
  fechanac: string = "";

  leyenda = "";
  constructor(
    private activedRoute: ActivatedRoute,
    private customerService: CustomerService,
    private modalService: NgbModal,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.id_customer = this.activedRoute.snapshot.paramMap.get("idcliente");
    this.loadData();
  }

  /**
   * The function loads customer data and displays a modal if there is an error or if certain
   * conditions are met.
   */
  private loadData() :void {
    this.customerService.getCustomer(this.id_customer).subscribe(
      (res) => {
        this.response = res;

        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.data = this.response.data;
        }

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This function saves user data by sending a form to the server and handling the response.
   */
  private saveData() :void {
    this.loader = 1;
    let form = new FormData();
    form.append("id_customer", this.id_customer);
    form.append("name", this.nombre);
    form.append("last_name", this.apellidos);
    form.append("phone", this.telefono);
    form.append("ext", this.extension);
    form.append("email", this.correo);
    form.append("place", this.puesto);
    form.append("date_b", this.fechanac);

    this.userService.saveUserClient(form).subscribe(
      (res) => {
        this.response = res;
        console.log(res);
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.data = this.response.data;

          if (this.data == 1)
            this.leyenda = "Usuario guardado satisfactoriamente";
        }

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code and title using
   * the ModalComponent.
   * @param {string} code - A string representing some code that will be displayed in the modal.
   * @param {string} title - The title parameter is a string that represents the title of the modal
   * that will be launched.
   */
  private async lauchModal(code: string, title: string) :Promise<void>{
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }
  
}
