import { Pipe, PipeTransform } from '@angular/core';
import { IBrand } from 'src/app/services/models/IBrand';

@Pipe({
  name: 'pagination'
})
export class PaginationPipe implements PipeTransform {

  transform(brands: IBrand[], pageNumber:number, pageSize:number): IBrand[] {
    const startIndex = (pageNumber) * pageSize;
    return brands.slice(startIndex, startIndex + pageSize);
  }

}
