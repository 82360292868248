import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { OrdenVentaService } from "src/app/services/orden-venta.service";
import { environment } from "src/app/variables/enviroment";
@Component({
  selector: "app-download-zip",
  templateUrl: "./download-zip.component.html",
  styleUrls: ["./download-zip.component.css"],
})
export class DownloadZipComponent implements OnInit {
  loader: number = 2;
  odv_pdf = true;
  partidas_xlsx = true;
  informes_pdf = true;
  odv_xslx = true;
  archivos = true;
  informes_xlsx = true;
  response: any = [];
  id_odv: string = "";

  download_button = true;
  constructor(
    private activedRoute: ActivatedRoute,
    private odvService: OrdenVentaService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.id_odv = this.activedRoute.snapshot.paramMap.get("id_odv");
  }

  /**
   * This function changes the value of certain variables and updates the download button based on
   * their values.
   * @param value - The value parameter is an integer that determines which property to change in the
   * object. The function checks the value and toggles the corresponding property.
   */
  public changeValue(value) :void {
    if (value == 1) this.odv_pdf = !this.odv_pdf;
    else if (value == 2) this.partidas_xlsx = !this.partidas_xlsx;
    else if (value == 3) this.informes_pdf = !this.informes_pdf;
    else if (value == 5) this.archivos = !this.archivos;
    else if (value == 6) this.informes_xlsx = !this.informes_xlsx;

    if (
      this.odv_pdf == false &&
      this.partidas_xlsx == false &&
      this.informes_pdf == false &&
      this.archivos == false &&
      this.informes_xlsx == false
    )
      this.download_button = false;
    else this.download_button = true;
  }

  /**
   * This function downloads a ZIP file containing various data types based on user input.
   */
  public downloadZIP() :void {
    this.loader = 1;

    var data = new FormData();
    data.append("id", this.id_odv);
    data.append("odv_pdf", this.odv_pdf.toString());
    data.append("partidas_xlsx", this.partidas_xlsx.toString());
    data.append("informes_pdf", this.informes_pdf.toString());
    data.append("archivos", this.archivos.toString());
    data.append("informes_xlsx", this.informes_xlsx.toString());

    let response;
    this.odvService.downloadAllDataZip(data).subscribe(
      (res) => {
        response = res;
        if (response.code != "0_005")
          this.lauchModal(response.code, response.message);
        else this.dowloadZipFile(response.data);
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This function downloads a zip file from a specific URL and opens it in a new window.
   * @param name - The name parameter is a string that represents the name of the zip file that needs
   * to be downloaded.
   */
  private dowloadZipFile(name) :void {
    var url = environment.uri_server + "storage/public/odvs_zip/" + name;
    window.open(url, "_blank");
  }

 /**
  * This function launches a modal with a given code and title using the Angular Material modal
  * service.
  * @param {string} code - A string representing some code that will be displayed in the modal.
  * @param {string} title - The title parameter is a string that represents the title of the modal that
  * will be launched.
  */
  private async lauchModal(code: string, title: string) :Promise<void>{
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }
}
