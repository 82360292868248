import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { OrdenVentaService } from "src/app/services/orden-venta.service";

@Component({
  selector: "app-service-notifications",
  templateUrl: "./service-notifications.component.html",
  styleUrls: ["./service-notifications.component.css"],
})
export class ServiceNotificationsComponent implements OnInit {
  loader: number = 1;
  response: any = [];
  data: any = [];

  year: number;
  month = "";
  key = "";

  private currentDate = new Date();

  constructor(
    private odvService: OrdenVentaService,
    private modalService: NgbModal,
    private routerService: Router
  ) {}

  ngOnInit(): void {
    this.getOdvs();
    this.year = this.currentDate.getFullYear() - 1;
    this.key = this.getMonthActual();
    this.month = this.year + "-" + this.key;
  }

  /**
   * The function `getOdvs()` makes an API call to retrieve data and then processes the response
   * accordingly.
   */
  private getOdvs(): void {
    this.loader = 1;
    this.odvService.odvByMonth().subscribe(
      (res) => {
        this.response = res;

        if (this.response.code != "0_005")
          this.lauchModal(this.response.code, this.response.message, "");
        else this.groupByMonth(this.response.data);

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * The function `groupByMonth` takes an array of values and groups them by month based on the
   * `created_at` property.
   * @param vals - The parameter `vals` is an array of objects. Each object represents a value and has
   * a property `created_at` which is a string representing a date in the format "YYYY-MM-DD".
   */
  private groupByMonth(vals): void {
    let meses: any = {
      "01": [],
      "02": [],
      "03": [],
      "04": [],
      "05": [],
      "06": [],
      "07": [],
      "08": [],
      "09": [],
      "10": [],
      "11": [],
      "12": [],
    };

    for (let i = 0; i < vals.length; i++) {
      let mes = vals[i].created_at.split("-")[1];
      meses[mes].push(vals[i]);
    }
    this.data = meses;
    console.log(this.data);
  }

  /**
   * The function "changeMonth" takes a date string as input, extracts the month from it, and assigns
   * it to the "month" variable.
   * @param date_select - The parameter `date_select` is a string representing a date in the format
   * "YYYY-MM-DD".
   */
  public changeMonth(date_select): void {
    this.key = date_select.split("-")[1];
    this.month = this.year + "-" + this.key;
  }

  /**
   * The function "getMonthActual" returns the current month in API format, with a leading zero if the
   * month is less than 10.
   * @returns a string representing the current month in API format.
   */
  private getMonthActual(): string {
    let month: any = this.currentDate.getMonth() + 1;
    let dateInApiFormat: string;

    if (month < 10) month = "0" + month.toString();

    return dateInApiFormat = month;
  }

  /**
   * The function `cloneCot` takes in an object `info_cot`, creates a `FormData` object, sends a
   * request to clone a cot (customer order template) using the `odvService`, and handles the response
   * accordingly.
   * @param info_cot - The parameter `info_cot` is an object that contains information about a cot
   * (quotation). It has the following properties:
   */
  public cloneCot(info_cot) :void {

    let form = new FormData();
    form.append('cliente_id',info_cot.id_cliente)
    form.append('cot',info_cot.id_cot)

    this.loader = 1;
    this.odvService.cloneCotNotification(form).subscribe(
      (res) => {
        this.response = res;

        if (this.response.code != "0_005")
          this.lauchModal(this.response.code, this.response.message, "");
        else 
          this.routerService.navigateByUrl(
            "/commercial/cot/edit/" + this.response.data
          );
        

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );

  }








  /**
   * The function `lauchModal` opens a modal window with the specified code, title, and message.
   * @param {string} code - The `code` parameter is a string that represents some code that you want to
   * display in the modal. It could be any valid code, such as JavaScript, HTML, CSS, etc.
   * @param {string} title - The `title` parameter is a string that represents the title of the modal.
   * It is used to set the `title` property of the `ModalComponent` instance.
   * @param message - The `message` parameter is a string that represents the message you want to
   * display in the modal.
   */
  private async lauchModal(
    code: string,
    title: string,
    message
  ): Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);  
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
  
}
