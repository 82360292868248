import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRS } from './models/IRS';
import { IRSComplement } from './models/IRSComplement';
import { IRSDelete } from './models/IRSDelete';
import { environment } from 'src/app/variables/enviroment';

@Injectable({
  providedIn: 'root'
})
export class ServiceReportService {
  uri:string = environment.uri;
  //uri:string = "http://ngdat.ucal.com.mx/api/";
  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');
  
  constructor(private httpClient:HttpClient) { }

  getDetail(id){
    return this.httpClient.get(this.uri+"get_rs_data?api_key="+this.api_key+"&token="+this.token+"&module=5&id_rs="+id);
  }

  getList(){
    return this.httpClient.get(this.uri+"get_rs_list?api_key="+this.api_key+"&token="+this.token+"&module=4");
  }

  getDataToAdd(id_odv){
    return this.httpClient.get(this.uri+"get_data_to_add_rs?api_key="+this.api_key+"&token="+this.token+"&module=6&odv="+id_odv);
  }

  saveRS(irs:IRS){
    return this.httpClient.post(this.uri+"add_rs?api_key="+this.api_key+"&token="+this.token+"&module=6",irs);
  }
  
  addComment(obj:IRSComplement){
    return this.httpClient.post(this.uri+"add_comment_rs?api_key="+this.api_key+"&token="+this.token+"&module=5",obj);
  }

  addFile(obj){
    return this.httpClient.post(this.uri+"add_file_rs?api_key="+this.api_key+"&token="+this.token+"&module=5",obj);
  }

  editPart(data){
    return this.httpClient.post(this.uri+"edit_part_rs?api_key="+this.api_key+"&token="+this.token+"&module=37",data);
  }

  deleteParts(data:IRSDelete){
    return this.httpClient.post(this.uri+"delete_parts_rs?api_key="+this.api_key+"&token="+this.token+"&module=37",data);
  }

  getParts(id_rs){
    return this.httpClient.get(this.uri+"get_parts_to_add_rs?api_key="+this.api_key+"&token="+this.token+"&module=6&id_rs="+id_rs);
  }

  addParts(id_rs,obj){
    return this.httpClient.post(this.uri+"add_parts_to_rs?api_key="+this.api_key+"&token="+this.token+"&module=6&id_rs="+id_rs,obj);
  }

  savePartsfulfill(form){
    return this.httpClient.post(this.uri+"save_parts_fulfill?api_key="+this.api_key+"&token="+this.token+"&module=6",form);
  }

  savePartsNofulfill(form){
    return this.httpClient.post(this.uri+"save_parts_no_fulfill?api_key="+this.api_key+"&token="+this.token+"&module=6",form);
  }

  generatePdfRs(form){
    return this.httpClient.post(this.uri+"generate_pdf_rs_with_signature?api_key="+this.api_key+"&token="+this.token+"&module=6",form);
  }

  //
}
