import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-commercial",
  templateUrl: "./commercial.component.html",
  styleUrls: ["./commercial.component.css"],
})
export class CommercialComponent implements OnInit {
  permmisions = JSON.parse(localStorage.getItem("permisos"));
  options: any[] = [];
  constructor() {}

  ngOnInit(): void {
    this.validatePermmissions();
  }

  /**
   * This function validates permissions and adds corresponding options to an array.
   */
  private validatePermmissions() :void {
    if (this.permmisions.includes(7)) {
      let uri = {
        path: "/commercial/odv",
        title: "Orden de Venta",
        icon: "fa-archive",
        class: "",
      };
      this.options.push(uri);
    }
    if (this.permmisions.includes(125)) {
      let uri = {
        path: "/commercial/cot",
        title: "Cotizaciones",
        icon: "fas fa-money-check-alt",
        class: "",
      };
      this.options.push(uri);
    }
    if (this.permmisions.includes(186)) {
      let uri = {
        path: "/commercial/notifications",
        title: "Notificaciones",
        icon: "fas fa-bell",
        class: "",
      }                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
      this.options.push(uri);
    }
    let uri = {
      path: "/commercial/email",
      title: "Correos",
      icon: "fa-envelope",
      class: "",
    };
    this.options.push(uri);
  }
  
}
