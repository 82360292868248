import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { GeneratorReportsService } from 'src/app/services/generator-reports.service';
import { IInforme } from 'src/app/services/models/IInformes';
import { environment } from 'src/app/variables/enviroment';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-escantillon',
  templateUrl: './escantillon.component.html',
  styleUrls: ['./escantillon.component.css']
})
export class EscantillonComponent implements OnInit {

  uri:string = environment.uri;
  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');
  url_to_load = '';
  //descripcion, salto, numeroSaltos, identificador, startsIn, tolerancia, dobleGrafica
  variaciones = [ ["0.00 - 0.50 pulg", 0.1, 6, 0, 0.0, 0.003, false, "Pul."],
                  ["20 - 60 mm", 10, 5, 1, 20, 100, true, "mm"],
                  ["30 - 70 mm", 10, 5, 2, 30, 100, true, "mm"],
                  ["30 - 80 mm", 10, 6, 3, 30, 100, true, "mm"],
                  ["50 - 75 mm", 5, 6, 4, 50, 100, true, "mm"],
                  ["50 - 90 mm", 5, 9, 5, 50, 100, true, "mm"],
                  ["55 - 75 mm", 5, 5, 6, 55, 100, true, "mm"],
                  ["1.120 - 1.127 pulg", 0.001, 8, 7, 1.120, 0.0015, false, "Pul."],
                  ["1.128 - 1.135 pulg", 0.001, 8, 8, 1.128, 0.0015, false, "Pul."],
                  ["18 - 72 mm", 10, 6, 9, 20, 100, true, "mm"],
                ];
                
  variaciones2 = [ [0.00058, 0.1, 6, 0, 0.0, 0.003],
                [58, 10, 5, 1, 20, 100],
                [58, 10, 5, 2, 30, 100],
                [58, 10, 6, 3, 30, 100],
                [58, 5, 6, 4, 50, 100],
                [58, 5, 9, 5, 50, 100],
                [58, 5, 5, 6, 55, 100],
                [0.000408, 0.001, 8, 7, 1.120, 0.0015],
                [0.000368, 0.001, 8, 8, 1.128, 0.0015],
                [58, 10, 6, 9, 20, 100],
              ];
  variacion;
  tam = [

  ];
  labels = [

  ];
  
  idsEquipos:any=[];
  idShet:string='';
  idpatron:any=[];

  equiposOdv:any=[]

  loader:number = 1;
  response:any = [];
  data:any=[];
  pdfInformes:any=[];

  ibc_list:any = [];
  

  lugar:any = "sitio";
  temp:any;
  unidad:any;
  format:any = "AAA-MM-DD";
  fecha:any;
  ctx:any;
  myChart:any;
  myChart2:any;

  constructor(
    private datos: ActivatedRoute, 
    private router:Router, 
    public conn: HttpClient,
    private generadorReports:GeneratorReportsService, 
    private modalService:NgbModal
  ) { }

  ngOnInit(): void {
    let datos=this.datos.snapshot.paramMap.get('id_equipos')
    this.idsEquipos.push(datos.split(','));
    this.idShet = this.datos.snapshot.paramMap.get('id_hoja');
    this.idpatron = this.datos.snapshot.paramMap.get('id_patron');
    

    this.getData();
  }

  getData(){
    this.generadorReports.verPlantilla(this.idShet,this.idsEquipos).subscribe(
      res=>{
        this.response = res;
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
        else this.data = this.response.data;

        this.equiposOdv = this.data.equipos;

        this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        this.loader = 2;
      }
    )

   
  }
  
  obtenerDatos(){

    this.loader = 1;

    
    var informes=[];

    for (let i = 0; i < this.equiposOdv.length; i++) {
      let listError = [];
      let listIbc = [];
      let listInTol = [];
      let incertidumbres = [];
      let yincertidumbres = [];
      let tolerancias = [];
      let toleranciasneg = [];
      let listError2 = [];
      let listIbc2 = [];
      let listInTol2 = [];
      let incertidumbres2 = [];
      let yincertidumbres2 = [];
      let tolerancias2 = [];
      let toleranciasneg2 = [];
      let equipo_id = this.equiposOdv[i].idEquipo;
      for(var o = 0; o < this.variaciones2[this.variacion][2]; o++){
        var ibc_current = parseFloat((document.getElementById("l"+equipo_id+"-"+o) as HTMLInputElement).value);
        listIbc.push(ibc_current);
        var max_line = ibc_current + this.variaciones2[this.variacion][0]
        var min_line = ibc_current - this.variaciones2[this.variacion][0]
        var inTol=""; 

        ibc_current <= min_line ? inTol = 'No cumple' : ibc_current >= max_line ? inTol = 'No cumple': inTol = 'Si cumple';
        (document.getElementById("l"+equipo_id+"in"+o) as HTMLInputElement).setAttribute("value",inTol);
        listInTol.push(inTol);
        var error = ibc_current - this.tam[o][0];
        listError.push(error);
        var incertidumbrex = error-this.variaciones2[this.variacion][0];
        incertidumbres.push(incertidumbrex);
        var yincertidumbre = error + this.variaciones2[this.variacion][0];
        yincertidumbres.push(yincertidumbre);
        tolerancias.push(this.variaciones2[this.variacion][5])
        toleranciasneg.push(-this.variaciones2[this.variacion][5])
      }
      
      

      this.ctx = document.getElementById(equipo_id+"myChart");
      var step = 0;
      var max = this.variaciones2[this.variacion][5];
      var start = -this.variaciones2[this.variacion][5];

      this.myChart = new Chart(this.ctx, {
        type: "line",
        data: {
          labels: this.labels,
          datasets: [
            {
              label: "Tolerancia del instrumento",
              data: tolerancias,
              fill: false,
              borderDash: [5,5],
              borderColor: "rgb(0, 0, 255)"
            },
            {
              label: "Incertidumbre",
              data: yincertidumbres,
              fill: false,
              lineTension: 0.000001,
              borderDash: [5,5],
              borderColor: "rgb(0, 0, 0)"
            },
            {
              label: "Error = (Media Inst. - Patrón)",
              data: listError,
              fill: false,
              lineTension: 0.000001,
              pointBackgroundColor: "rgb(0,0,0)",
              borderColor: "rgb(255, 0, 0)"
            },
            {
              label: "Incertidumbre",
              data: incertidumbres,
              fill: false,
              lineTension: 0.000001,
              borderDash: [5,5],
              borderColor: "rgb(0, 0, 0)"
            },
            {
              label: "Tolerancia del instrumento",
              data: toleranciasneg,
              fill: false,
              borderDash: [5,5],
              borderColor: "rgb(0, 0, 255)"
            },
          ],
        },
        options: {
          legend: {
            display: false
          }
        },
        scaleSteps: Math.ceil((max - start) / step),
                scaleStepWidth: step,
                scaleStartValue: start
      });
      //DOBLE GRAFICA INICIO
      if(this.variaciones[this.variacion][6]){
        
        let equipo_id = this.equiposOdv[i].idEquipo;
        for(var o = 0; o < this.variaciones2[this.variacion][2]; o++){
          var ibc_current = parseFloat((document.getElementById("r"+equipo_id+"-"+o) as HTMLInputElement).value);
          listIbc2.push(ibc_current);
          var max_line = ibc_current + this.variaciones2[this.variacion][0]
          var min_line = ibc_current - this.variaciones2[this.variacion][0]
          var inTol=""; 

          ibc_current <= min_line ? inTol = 'No cumple' : ibc_current >= max_line ? inTol = 'No cumple': inTol = 'Si cumple';
          (document.getElementById("r"+equipo_id+"in"+o) as HTMLInputElement).setAttribute("value",inTol);
          listInTol2.push(inTol);
          var error = ibc_current - this.tam[o][0];
          listError2.push(error);
          var incertidumbrex = error-this.variaciones2[this.variacion][0];
          incertidumbres2.push(incertidumbrex);
          var yincertidumbre = error + this.variaciones2[this.variacion][0];
          yincertidumbres2.push(yincertidumbre);
          tolerancias2.push(this.variaciones2[this.variacion][5])
          toleranciasneg2.push(-this.variaciones2[this.variacion][5])
        }
        
        

        this.ctx = document.getElementById(equipo_id+"myChartR");
        var step = 0;
        var max = this.variaciones2[this.variacion][5];
        var start = -this.variaciones2[this.variacion][5];

        this.myChart2 = new Chart(this.ctx, {
          type: "line",
          data: {
            labels: this.labels,
            datasets: [
              {
                label: "Tolerancia del instrumento",
                data: tolerancias2,
                fill: false,
                borderDash: [5,5],
                borderColor: "rgb(0, 0, 255)"
              },
              {
                label: "Incertidumbre",
                data: yincertidumbres2,
                fill: false,
                lineTension: 0.000001,
                borderDash: [5,5],
                borderColor: "rgb(0, 0, 0)"
              },
              {
                label: "Error = (Media Inst. - Patrón)",
                data: listError2,
                fill: false,
                lineTension: 0.000001,
                pointBackgroundColor: "rgb(0,0,0)",
                borderColor: "rgb(255, 0, 0)"
              },
              {
                label: "Incertidumbre",
                data: incertidumbres2,
                fill: false,
                lineTension: 0.000001,
                borderDash: [5,5],
                borderColor: "rgb(0, 0, 0)"
              },
              {
                label: "Tolerancia del instrumento",
                data: toleranciasneg2,
                fill: false,
                borderDash: [5,5],
                borderColor: "rgb(0, 0, 255)"
              },
            ],
          },
          options: {
            legend: {
              display: false
            }
          },
          scaleSteps: Math.ceil((max - start) / step),
                  scaleStepWidth: step,
                  scaleStartValue: start
        });
      }

      //DOBLE GRAFICA FIN
     var doblegrafica = 0;
     var unidad2 = this.variaciones[this.variacion][7];
     var aumentoEn = this.variaciones2[this.variacion][1];
     var startsIn = this.variaciones2[this.variacion][4];
     var incer = this.variaciones2[this.variacion][0];
     var tole = this.variaciones2[this.variacion][5];
      if(this.variaciones[this.variacion][6]){
        doblegrafica = 1;
      }

      if(this.temp == null || this.unidad == null || this.fecha == null) {
        alert("Campos vacios");
      } else {
        let global = [];
        global.push(this.temp);
        global.push(this.unidad);
        global.push(this.format);
        global.push(this.fecha);
        global.push(this.lugar);
        
        let clic = this.uri+'escantillon_variaciones?api_key='+this.api_key+'&token='+this.token+
        '&module=2&id_plantilla='+this.idShet+'&libc='+listIbc+'&inTol='+listInTol+'&lerror='+
        listError+'&globales='+global+"&id_equipo="+equipo_id+"&patron_id="+this.idpatron +'&libc2='+listIbc2+'&inTol2='
        +listInTol2+'&lerror2='+listError2+"&doblegrafica="+doblegrafica+"&unidad="+unidad2+"&aumento="+aumentoEn+"&start="+
        startsIn+"&incert="+incer+"&tole="+tole;
        let a=[];
        a[0] = clic;
        a[1]=this.equiposOdv[i].identificador+this.equiposOdv[i].partida;
        a[2]=this.myChart; 
        a[3]=this.myChart2; 
       
        informes.push(a);
      }

    }
    //this.url_to_load = this.uri+"transmisor_temperatura_20?api_key="+this.api_key+"&token="+this.token+"&module=2";
    this.pdfInformes = informes;
    this.loader=2;
    
  }

  obtenerImagen(url_pdf,myChartId, dobleChartId){
    this.loader = 1;
    url_pdf = url_pdf[0];
    let base64 = myChartId.toBase64Image();
    let base642 = dobleChartId.toBase64Image();
    var to_send:IInforme = {
      img: base64,
      img2: base642,
    };

    //this.url_to_load = this.uri+"transmisor_temperatura_20?api_key="+this.api_key+"&token="+this.token+"&module=2"
    console.log(url_pdf);
    this.conn.post(url_pdf, to_send).subscribe(
      res => {
        this.response = res;
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
        else this.data = this.response.data;
        let informe = this.data;
        console.log(informe);
        this.loader = 2;
        this.downloadFile(informe);
      },
      error=>{
        //console.log(error);
        alert("Error de petición");
        this.loader = 2;
      }
    );
    
    //this.url_to_load = this.uri+"transmisor_temperatura_20?api_key="+this.api_key+"&token="+this.token+"&module=2"
    //window.open(url_pdf+"&img="+base64, "ventana1" , "width=1000,height=900,scrollbars=NO");
  }

  asignarInforme(url_pdf, myChartId, dobleChartId){
    this.loader = 1;
    url_pdf = url_pdf[0];
    let base64 = myChartId.toBase64Image();

   
    let base642 = dobleChartId.toBase64Image();

    var to_send:IInforme = {
      img: base64,
      img2: base642,
    };


    this.conn.post(url_pdf+"&asignar="+"asignar", to_send).subscribe(
      res => {
        this.response = res;
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
        else this.data = this.response.data;
        alert("Asignado correctamente");
        this.loader = 2;
      },
      error=>{
        console.log(error);
        alert("Error de petición");
        this.loader = 2;
      }
    );
  }

  downloadFile(file_name){
    var url = environment.uri_for_reports+file_name;
    window.open(url, '_blank');
  }
  
  lauchModal(code:string,title:string){
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }


  setVariacion(tipo){
    
    this.variacion = this.variaciones[tipo][3];
    console.log(this.variacion);
    this.tam = [];
    var sum = this.variaciones2[tipo][4];
    for(var i = 0; i < this.variaciones[tipo][2]; i++ ){
      console.log(sum);
      this.tam.push([sum, "-"+i, "in"+i, this.variaciones2[tipo][5]]);
      this.labels.push(sum);
      console.log(this.variaciones2[tipo][1]);
      sum = +this.intlRound(sum + this.variaciones2[tipo][1]);
    }

  }
  
  intlRound(numero, decimales =3, usarComa = false) {
    var opciones = {
      maximumFractionDigits: decimales,
      useGrouping: false
    };
    var usarComa2 = usarComa ? "es": "en";
    return new Intl.NumberFormat(usarComa2, opciones).format(numero);
  }
}