import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { NotificationsService } from 'src/app/services/notifications.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-permiso-notification',
  templateUrl: './permiso-notification.component.html',
  styleUrls: ['./permiso-notification.component.css']
})
export class PermisoNotificationComponent implements OnInit {

  result:any=[];
  constructor(
    private userService: UserService,
    private modalService:NgbModal,
    private notificationService:NotificationsService) { }

  ngOnInit(): void {
    this.getData();
  }

  getData(){
    let data:any =[]
    this.userService.getUserInternos().subscribe(
      res=>{
        data=res
        if(data.code != '0_005')this.lauchModal(data.code,data.message,'');
        else this.result = data.data;
        console.log(this.result)
      },
      err=>{
        this.lauchModal('0000x00','Error de consulta','');
        console.log(err);
      }
    )
  }

  changePermission(type,id_user){
    console.log(type,id_user)
    let data:any =[];
    this.notificationService.assingPermission(type,id_user).subscribe(
      res=>{
        data=res;
        if(data.code != '0_005')this.lauchModal(data.code,data.message,'');
        else console.log(data.data)
      },
      err=>{
        this.lauchModal('0000x00','Error de consulta','');
        console.log(err);
      }
    )
  }

  lauchModal(code:string,title:string,message){
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }

}
