import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { InstrumentService } from "src/app/services/instrument.service";
import { IOdv } from "src/app/services/models/IOdv";
import { IPart } from "src/app/services/models/IPart";
import { OrdenVentaService } from "src/app/services/orden-venta.service";

@Component({
  selector: "app-edit-odv",
  templateUrl: "./edit-odv.component.html",
  styleUrls: ["./edit-odv.component.css"],
})
export class EditOdvComponent implements OnInit {
  id_odv = "";
  id_event = "";
  loader: number = 1;
  response: any = [];
  data_general: any = [];
  parts: any = [];

  subtotal = 0;
  iva = 0;
  total = 0;
  return_ = 0;
  //Variables para agregar una partida
  concept = 1;
  price_part = 0;
  quantity_part = 1;
  part = "";
  notes_part = "N/A";
  description = "";
  tag = "";
  serie = "";
  message_add_part = "";
  validator = 0;
  model = "";
  brand:number = 1;

  //Variables para editar una ODV
  identifier = "";
  odc_client = "";
  bill = "";
  coin = "";
  pay = "";
  pay_tipes: any = [];
  attention_id = "";
  client_users: any = [];
  social_reasons: any = [];
  soc_reason = "";
  ship_to = "";
  bill_to = "";
  client_directions: any = "";
  notes = "";
  empty_inputs = 0;
  coin_type: any = [
    { desc: "MXN", id: "1" },
    { desc: "USD", id: "2" },
  ];
  type_service = "0";

  depto_odv = "";
  add_depto_odv = 0;

  deptos: any = [];
  list_deptos: any = [];
  departamento_selected = "";
  //Variables for replace instrument
  instrument_to_replace = {
    instrument: "",
    tag: "",
    brand: "",
    model: "",
    serie: "",
    depto: "",
  };

  results_for_replace_instruments: any = [];
  id_instrument_replace = "";

  id_replace_old = "";
  part_replace_old = "";
  part_replace_id:string = "";

  //BRANDS
  brand_list: any = [];
  brand_name:string = "";

  constructor(
    private odvService: OrdenVentaService,
    private instument_service: InstrumentService,
    private activedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.id_odv = this.activedRoute.snapshot.paramMap.get("id_odv");
    this.loader = 1;
    this.getOdvData();
  }

  /**
   * This function retrieves data from an API and assigns it to various variables for use in an
   * application.
   */
  private getOdvData(): void {
    this.loader = 1;
    this.odvService.getOdvToEdit(this.id_odv).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.data_general = this.response.data.general_data;

          this.social_reasons = this.response.data.razones_soc;
          this.soc_reason = this.data_general.id_razon_social;
          this.type_service = this.data_general.tipo_servicio;
          this.id_event = this.response.data.event;

          this.pay = this.response.data.general_data.id_tipo_pago;
          this.pay_tipes = this.response.data.t_pay;

          this.attention_id = this.response.data.general_data.atention_to_id;
          this.client_users = this.response.data.client_users;

          this.ship_to = this.response.data.general_data.id_ship_to;
          this.bill_to = this.response.data.general_data.id_bill_to;
          this.client_directions = this.response.data.client_directions;
          this.parts = this.response.data.parts_data;

          this.identifier = this.data_general.identifier;
          this.odc_client = this.data_general.client_odc;
          this.bill = this.data_general.bill;
          this.notes = this.data_general.notes;
          this.coin = this.data_general.coin;

          this.subtotal = 0;
          this.iva = 0;
          this.total = 0;

          //deptos odv
          if (this.data_general.id_depto != null) {
            this.depto_odv = this.data_general.id_depto;
            this.add_depto_odv = 1;
          }
          //departamentos
          this.deptos = this.response.data.deptos;
          this.searchDeptos();
          if(this.list_deptos.length > 0) {
            this.departamento_selected = this.list_deptos[0].id.toString();
          }
          for (var i = 0; i < this.parts.length; i++) {
            this.subtotal += this.parts[i].precio * this.parts[i].cantidad;
          }
          this.iva = this.subtotal * 0.16;
          this.total = this.subtotal + this.iva;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   *Metodos para agregar una partida
   */

  /**
   * This is a private function in TypeScript that sets the value of a concept.
   * @param value - The value parameter is the new value that will be assigned to the concept property.
   */
  public setConcept(value): void {
    this.concept = value;
  }

  /**
   * This function validates data before adding a new part and displays error messages if any of the
   * required fields are missing or invalid.
   */
  public validateDataToAddPart(): void {
    this.validator = 0;
    if (this.price_part <= 0) {
      this.message_add_part += "El precio debe ser mayor a 0.";
      this.validator++;
    }
    if (this.quantity_part <= 0) {
      this.message_add_part += "La cantidad debe ser mayor a 0.";
      this.validator++;
    }
    if (this.part.trim() == "") {
      this.message_add_part += "La partida no debe ser nula.";
      this.validator++;
    }
    if (this.description.trim() == "") {
      this.message_add_part += "La descripción no debe ser nula.";
      this.validator++;
    }
    if (this.tag.trim() == "") {
      this.message_add_part += "El tag no debe ser nulo.";
      this.validator++;
    }
    if (this.serie.trim() == "") {
      this.message_add_part += "La serie no debe ser nula.";
      this.validator++;
    }
    if (this.model.trim() == "") {
      this.model = "S/M";
    }
    if (this.model.trim() == "") {
      this.brand_name = "S/M";
    }

    if (this.departamento_selected.trim() == "") {
      this.message_add_part += "El departamento no debe ser nulo.";
      this.validator++;
    }
    if (this.notes_part.trim() == "") this.notes_part = "N/A";

    if (this.validator == 0) this.savePart();
  }

  /**
   * This function assigns a department ID to a variable based on user input.
   */
  public asignDepto(): void {
    if (this.add_depto_odv == 0) {
      this.add_depto_odv = 1;
      this.depto_odv = this.list_deptos[0].id.toString();
    } else {
      this.add_depto_odv = 0;
      this.depto_odv = ""; //Borrar depto guardado
    }
  }

  /**
   * This function searches for departments based on certain conditions and assigns them to different
   * variables.
   */
  private searchDeptos(): void {
    let depto_asignado;

    let primerDepto;
    let all_deptos = [];
    this.list_deptos = [];
    this.deptos.forEach((departamentos) => {
      departamentos.forEach((depto) => {
        if (depto.id_direccion_cliente == this.bill_to) {
          this.list_deptos.push(depto);
        }
        all_deptos.push(depto);
      });
    });
    //Para asignar a equipos
    //Si hay departamentos pero no hay seleccionado
    if (all_deptos.length != 0 && (this.departamento_selected == "")) {
      
      primerDepto = all_deptos[0];
       //Si hay departamentos pero SI hay seleccionado
    } else if (all_deptos.length != 0 && this.departamento_selected != "") {
      primerDepto = all_deptos.find(
        (deptos) => deptos.id === Number(this.departamento_selected)
      );
    }
    //Sí hay departamentos en la direccion de la odv prioriza colocar esos
    if (this.list_deptos.length != 0) {
      this.departamento_selected = primerDepto.id.toString();
     
    }
    if (this.departamento_selected != "" && this.list_deptos.length == 0) {
      this.list_deptos.push(primerDepto);
     
    }
    
    //Para asignar a ODV
    if (all_deptos.length != 0 && this.depto_odv != "") {
      depto_asignado = all_deptos.find(
        (deptos) => deptos.id === Number(this.depto_odv)
      );
      if (!depto_asignado) {
        this.list_deptos.push(depto_asignado);
      }
    }
    this.list_deptos = all_deptos;
  }

  /**
   * This function saves a part to an ODV and displays a modal based on the response.
   */
  private savePart(): void {
    let response = 0;
    this.loader = 1;
    let part_dto: IPart = {
      id_odv: this.id_odv,
      id_event: this.id_event,
      price: this.price_part,
      part: this.part,
      description: this.description,
      tag: this.tag,
      serie: this.serie,
      notes: this.notes_part,
      cantidad: this.quantity_part,
      modelo: this.model,
      marca: this.brand_name,
      depto: this.departamento_selected,
    };

    this.odvService.addPartToODV(part_dto).subscribe(
      (res) => {
        response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_007"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.price_part = 0;
          this.part = "";
          this.description = "";
          this.tag = "";
          this.serie = "";
          this.notes_part = "N/A";
          this.quantity_part = 1;
          this.model = "";
          this.departamento_selected = "";
          this.brand=1;
          this.brand_name="";
          this.getOdvData();
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * Metodos para editar la ODV
   */

  /**
   * This is a private function in TypeScript that sets the value of a pay property.
   * @param value - The value parameter is the new value that will be assigned to the pay property.
   */
  public setPay(value): void {
    this.pay = value;
  }

  /**
   * This is a private function in TypeScript that sets the value of a coin.
   * @param value - The value parameter is the new value that will be assigned to the "coin" property.
   */
  public setCoin(value): void {
    this.coin = value;
  }

 /**
  * This is a private function in TypeScript that sets the value of a variable called "attention_id".
  * @param value - The value parameter is the new value that will be assigned to the attention_id
  * property.
  */
 public setAttentionTo(value): void {
    this.attention_id = value;
  }

  /**
   * This is a private function in TypeScript that sets the value of a property called "bill_to".
   * @param value - The value that will be set as the new value for the "bill_to" property.
   */
  public setBillTo(value): void {
    this.bill_to = value;
  }

  /**
   * This function sets the value of "ship_to" and then calls another function called "searchDeptos".
   * @param value - The value to set for the `ship_to` property of the object.
   */
  public setShipTo(value): void {
    this.ship_to = value;
    this.searchDeptos();
  }

  /**
   * The function sets the social reason of an object using an ID.
   * @param id - The parameter "id" is the value that will be assigned to the "soc_reason" property of
   * the object. It represents the social reason identifier that will be set for the object.
   */
  public setSocialReason(id) {
    this.soc_reason = id;
  }

 /**
  * This function validates and sends data for editing an ODV (Order Delivery Verification) object.
  * @param action - It is a parameter passed to the function, which is used to validate the ODV data.
  * It is not clear from the code snippet what the data type of the parameter is or what it contains.
  */
 public validateODVData(action): void {
    this.return_ = action;
    if (this.identifier.trim() === "") this.empty_inputs = 1;
    if (this.odc_client.trim() == "") this.odc_client = "Pendiente";
    if (this.bill.trim() == "") this.bill = "Pendiente";
    if (this.notes.trim() == "") this.notes = "N/A";

    let odv_dto: IOdv = {
      id_odv: this.id_odv,
      identifier: this.identifier,
      odc: this.odc_client,
      bill: this.bill,
      notes: this.notes,
      id_days: this.pay,
      id_coin: this.coin,
      id_attention_to: this.attention_id,
      id_bill_to: this.bill_to,
      id_ship_to: this.ship_to,
      id_social_reason: this.soc_reason,
      type_service: this.type_service,
      department: this.depto_odv,
    };
    this.sendPeticionToEditODV(odv_dto);
  }

  /**
   * This function sends a request to edit general data of an ODV and handles the response accordingly.
   * @param {IOdv} dto - The parameter "dto" is of type "IOdv", which is likely an interface or type
   * representing the data structure of an ODV (whatever that may be).
   */
  private sendPeticionToEditODV(dto: IOdv): void {
    let response = 0;
    this.loader = 1;
    this.odvService.editGeneralDataODV(dto).subscribe(
      (res) => {
        response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_007"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          if (this.return_ == 0) this.getOdvData();
          else this.router.navigate(["/commercial/odv/det", this.id_odv]);
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  //Metodos para editar las partidas

  /**
   * This function edits a specific part's details based on the type of edit requested.
   * @param id_part - The ID of the part that needs to be edited.
   * @param tipe - The "tipe" parameter is a number that determines which property of the "part" object
   * should be edited.
   * @param data - The data parameter is an event object that contains information about the event that
   * triggered the function. In this case, it is used to get the value of the input field that was
   * edited.
   */
  public editValue(id_part, tipe, data) : void {
    let edit = data.target.value;

    for (let i = 0; i < this.parts.length; i++) {
      if (this.parts[i].id_det == id_part) {
        let part: IPart = {
          id_det: this.parts[i].id_det,
          id_equipo: this.parts[i].id_equipo,
          price: this.parts[i].precio,
          part: this.parts[i].partida,
          description: this.parts[i].descripcion,
          tag: this.parts[i].tag,
          modelo: this.parts[i].modelo,
          cantidad: this.parts[i].cantidad,
        };

        if (tipe == 1) part.part = edit;
        if (tipe == 2) part.description = edit;
        if (tipe == 3) part.modelo = edit;
        if (tipe == 4) part.price = edit;

        this.savePartEdit(part);
      }
    }
  } 

  /**
   * This function saves edits made to a specific part and displays a modal based on the response code.
   * @param part - The parameter "part" is an object representing a specific part that needs to be
   * edited. It is passed as an argument to the "savePartEdit" function.
   */
  private savePartEdit(part) : void {
    let response = 0;
    this.loader = 1;
    this.odvService.editSpecificPart(part).subscribe(
      (res) => {
        response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_007"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.getOdvData();
        }

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  //Metodo para reemplazar partidas
  /**
   * This is a TypeScript function that searches for an instrument to replace based on user input and
   * displays the results.
   * @param event - The event parameter is an object that represents the event that triggered the
   * function. It could be a keyboard event, a mouse event, or any other type of event.
   */
  public searchInstrument(event) : void {
    let value = event.target.value.trim();
    if (value != "") {
      this.loader = 1;
      this.odvService
        .searchInstrumentToReplace(value, this.response.data.client_id)
        .subscribe(
          (res) => {
            this.response = res;
            if (
              this.response.code == "1_0001" ||
              this.response.code == "1_0002" ||
              this.response.code == "1_0003"
            )
              this.lauchModal(this.response.code, this.response.message, "");
            else if (this.response.code == "1_0004")
              this.lauchModal(this.response.code, this.response.message, "");
            else {
              this.results_for_replace_instruments = this.response.data;
            }
            this.loader = 2;
          },
          (err) => {
            this.lauchModal("0000x00", "Error de consulta", "");
            this.loader = 2;
          }
        );
    }
  }

  /**
   * This function sets the instrument to be replaced based on the index provided.
   * @param index - The index parameter is an integer that represents the index of the part in the
   * parts array that needs to be replaced.
   */
  public setInstrumentToReplace(index) : void {
    this.id_replace_old = this.parts[index].id_equipo;
    this.part_replace_old = this.parts[index].partida;
    this.part_replace_id = this.parts[index].id_det;

    this.instrument_to_replace.instrument = this.parts[index].descripcion;
    this.instrument_to_replace.tag = this.parts[index].tag;
    this.instrument_to_replace.brand = this.parts[index].marca;
    this.instrument_to_replace.model = this.parts[index].modelo;
    this.instrument_to_replace.serie = this.parts[index].serie;
    this.instrument_to_replace.depto = this.parts[index].depto;
  }

  /**
   * This function replaces an instrument in a form using data from a form and sends a request to the
   * server.
   */
  public replaceInstrument() : void {
    let data = new FormData();
    data.append("id_instrument_new", this.id_instrument_replace);
    data.append("id_instrument_old", this.id_replace_old);
    data.append("part_replace", this.part_replace_id);
    data.append("odv_id", this.id_odv);
    data.append("event_id", this.id_event);

    this.loader = 1;
    this.odvService.replaceInstrument(data).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.getOdvData();
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }
  //BRANDS

  /**
   * The `searchBrand` function in TypeScript is used to search for a brand based on the input value,
   * displaying results or error messages accordingly.
   * @param data - The `data` parameter in the `searchBrand` function seems to be an event object that
   * is passed when the function is triggered, likely from an input field. The function then extracts
   * the value from this event object to use in the search query for a brand.
   */
  public searchBrand(data): void {
    this.loader = 1;
    let value = data.target.value;

    this.instument_service.searchBrand(value).subscribe(
      (res) => {
        let data: any = res;
        if (
          data.code == "1_0001" ||
          data.code == "1_0002" ||
          data.code == "1_0003"
        )
          this.lauchModal(data.code, data.message, "");
        else if (data.code == "1_0004")
          this.lauchModal(data.code, data.message, "");
        else {
          this.brand_list = data.data;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /* The above code is defining a private asynchronous function called `lauchModal` that takes in three
  parameters: `code` (a string), `title` (a string), and `message` (an unspecified type). */
  private async lauchModal(code: string, title: string, message) :Promise<void> {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
}
