import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdministrationRoutingModule } from './administration-routing.module';
import { DetailSupplierComponent } from './suppliers/detail-supplier/detail-supplier.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { SendEmailOdcComponent } from './send-email-odc/send-email-odc.component';
import { ProfitabilityComponent } from './profitability/profitability.component';
import { EditSupplierComponent } from './suppliers/edit-supplier/edit-supplier.component';
import { AddSupplierComponent } from './suppliers/add-supplier/add-supplier.component';
import { InvioceOdvComponent } from './cartera/invioce-odv/invioce-odv.component';
import { SendEmailComponent } from './suppliers/send-email/send-email.component';
import { UpdateWalletComponent } from './cartera/update-wallet/update-wallet.component';
import { CarteraComponent } from './cartera/cartera.component';
import { EditSaleOrderComponent } from './sale-orders/edit-sale-order/edit-sale-order.component';
import { AddSaleOrderComponent } from './sale-orders/add-sale-order/add-sale-order.component';
import { DetailSaleOrderComponent } from './sale-orders/detail-sale-order/detail-sale-order.component';
import { SaleOrdersComponent } from './sale-orders/sale-orders.component';
import { AdministrationComponent } from './administration.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid'; 
import interactionPlugin from '@fullcalendar/interaction';
import { RequisitionsComponent } from './requisitions/requisitions.component';
import { EditRequisitionsComponent } from './requisitions/edit-requisitions/edit-requisitions.component';
import { DetailRequisitionsComponent } from './requisitions/detail-requisitions/detail-requisitions.component';
import { IndicatorsWalletComponent } from './cartera/indicators-wallet/indicators-wallet.component';
import { InvoicesDateComponent } from './cartera/invoices-date/invoices-date.component';
import { VacationsComponent } from './vacations/vacations.component';
import { VacationsCalendarComponent } from './vacations/vacations-calendar/vacations-calendar.component';
import { VacationsRequestsComponent } from './vacations/vacations-requests/vacations-requests.component';
import { BrandsComponent } from './brands/brands.component';
import { FiltroPipe } from './brands/filtro.pipe';
import { PaginationPipe } from './brands/pagination.pipe';
import { OrderByPipe } from './brands/order-by.pipe';
import { DetailInvoiceComponent } from './cartera/update-wallet/detail-invoice/detail-invoice.component';
import { SendEmailWalletComponent } from './cartera/update-wallet/send-email-wallet/send-email-wallet.component';

FullCalendarModule.registerPlugins([ 
  dayGridPlugin,
  interactionPlugin
]);


@NgModule({
  declarations: [
    AdministrationComponent,
    SaleOrdersComponent,
    DetailSaleOrderComponent,
    AddSaleOrderComponent,
    EditSaleOrderComponent,
    CarteraComponent,
    UpdateWalletComponent,
    SendEmailComponent,
    InvioceOdvComponent,
    AddSupplierComponent,
    EditSupplierComponent,
    ProfitabilityComponent,
    SendEmailOdcComponent,
    SuppliersComponent,
    DetailSupplierComponent,
    RequisitionsComponent,
    EditRequisitionsComponent,
    DetailRequisitionsComponent,
    IndicatorsWalletComponent,
    InvoicesDateComponent,
    VacationsComponent,
    VacationsCalendarComponent,
    VacationsRequestsComponent,
    BrandsComponent,
    FiltroPipe,
    PaginationPipe,
    OrderByPipe,
    DetailInvoiceComponent,
    SendEmailWalletComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    AdministrationRoutingModule,
    FullCalendarModule,
  ]
})
export class AdministrationModule { }
