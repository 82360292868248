import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { OrdenVentaService } from "src/app/services/orden-venta.service";

@Component({
  selector: "app-profitability",
  templateUrl: "./profitability.component.html",
  styleUrls: ["./profitability.component.css"],
})
export class ProfitabilityComponent implements OnInit {
  result: any = [];
  response: any = [];
  loader: number = 1;
  odvs: any = [];
  odvs_concepts: any = [];
  facturas: any = [];
  facturas_concepts: any = [];
  showingodvs = 1;
  showingfacturas = 0;
  showingconcepts = 0;
  id_factura = "";
  mano_de_obra = 0;
  viaticos = 0;
  uso_patron = 0;
  showingodvwithconcepts = 0;
  odv_actual = "";
  precio_odv_actual = "";

  constructor(
    private odvService: OrdenVentaService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.getOdvList();
  }

 /**
  * The function retrieves a list of ODVs for profitability and handles the response accordingly.
  */
  private getOdvList() :void{
    this.odvService.getOdvListForProfiability().subscribe(
      (res) => {
        this.response = res;

        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.odvs = this.response.data;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This function retrieves a list of profitability concepts from an API and handles different
   * response codes accordingly.
   */
  private getOdvListConcepts() :void{
    this.odvService.getOdvListForProfiabilityConcepts().subscribe(
      (res) => {
        this.response = res;

        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.odvs_concepts = this.response.data;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * The function retrieves a list of invoices for profitability analysis from a service and handles
   * the response accordingly.
   * @param id - The parameter "id" is a variable that represents the identifier of an ODV (Operational
   * Delivery Vehicle) for which a list of invoices is being requested.
   */
  private getFacturasParaOdv(id) :void{
    this.odvService.getFacturasListForProfiability(id).subscribe(
      (res) => {
        this.response = res;

        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.facturas = this.response.data;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This function sets the current ODV and its price, and retrieves the invoices related to the given
   * ID and ODV.
   * @param id - The ID of the object being set.
   * @param odv - It is a variable that represents the current ODV (Orden de Venta) being set in the
   * function. ODV is a Spanish term that translates to "Sales Order" in English.
   * @param precio - precio is a variable that represents the price of the odv (On Demand Video)
   * service. It is being passed as a parameter to the function setIdOdv() to set the current price of
   * the odv.
   */
  public setIdOdv(id, odv, precio) :void{
    this.odv_actual = odv;
    this.precio_odv_actual = precio;
    this.getFacturasParaOdvConcepts(id);
  }

  /**
   * This function retrieves a list of invoices for profitability concepts from a service and handles
   * the response accordingly.
   * @param id - The parameter "id" is a variable that represents the identifier of a specific object
   * or entity. In this case, it is used as a parameter to retrieve a list of invoices for
   * profitability concepts from the "odvService" service.
   */
  private getFacturasParaOdvConcepts(id) :void{
    this.odvService.getFacturasListForProfiabilityConcepts(id).subscribe(
      (res) => {
        this.response = res;

        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.facturas_concepts = this.response.data;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This function sets the showingfacturas variable to 1 and calls another function to get facturas
   * for a given id.
   * @param id - The parameter "id" is a variable that represents the identifier of a specific object
   * or entity. In this case, it is used as an argument for the function "setShowingFacturas", which is
   * responsible for displaying a list of invoices related to a particular object identified by the
   * "id" parameter.
   */
  /**
   * This function sets the showingfacturas variable to 1 and calls another function to get facturas
   * for a given id.
   * @param id - The "id" parameter is likely an identifier or reference to a specific object or entity
   * within the system. It is used as an input to the "getFacturasParaOdv" function to retrieve a list
   * of related "facturas" (invoices or bills) associated with the specified "id".
   */
  public setShowingFacturas(id) :void {
    this.getFacturasParaOdv(id);
    this.showingodvs = 0;
    this.showingfacturas = 1;
  }

  /**
   * This function sets certain variables to 0 and 1 to hide the "facturas" section.
   */
  private notShowingFacturas() :void {
    this.showingodvs = 1;
    this.showingfacturas = 0;
    this.showingconcepts = 0;
    this.showingodvwithconcepts = 0;
  }

  /**
   * The function sets the showingconcepts variable to 1 and showingfacturas variable to 0, and assigns
   * the id parameter to the id_factura variable.
   * @param id - The parameter "id" is a variable that represents the ID of a factura (invoice) that is
   * being passed as an argument to the function "setShowingConcepts".
   */
  public setShowingConcepts(id) :void {
    this.showingconcepts = 1;
    this.showingfacturas = 0;
    this.id_factura = id;
  }

  /**
   * The function sets the value of "showingconcepts" to 0 and "showingfacturas" to 1.
   */
  public notShowingConcepts() :void {
    this.showingconcepts = 0;
    this.showingfacturas = 1;
  }

  /**
   * The function sets a flag to show ODVs with concepts and hides other flags.
   */
  public showingOdvWithConcepts() :void {
    this.getOdvListConcepts();
    this.showingfacturas = 0;
    this.showingodvs = 0;
    this.showingconcepts = 0;
    this.showingodvwithconcepts = 1;
  }

  /**
   * The function checks if certain fields have values and launches a modal if any of them are missing.
   */
  public sendConcepts() :void {
    if (this.mano_de_obra > 0) {
      if (this.viaticos > 0) {
        if (this.uso_patron > 0) {
          this.sendConceptsUrl();
        } else {
          this.lauchModal(
            "Campos vacios",
            "Concepto de uso de patrón faltante",
            "Por favor ingresa el valor por el uso del patrón de esta factura."
          );
        }
      } else {
        this.lauchModal(
          "Campos vacios",
          "Concepto de viáticos faltante",
          "Por favor ingresa el valor por los viáticos de esta factura."
        );
      }
    } else {
      this.lauchModal(
        "Campos vacios",
        "Concepto de mano de obra faltante",
        "Por favor ingresa el valor por la mano de obra de esta factura."
      );
    }
  }

  /**
   * This function sends a request to set concepts for a given invoice and handles the response
   * accordingly.
   */
  private sendConceptsUrl() :void{
    this.odvService
      .setConceptosFactura(
        this.id_factura,
        this.mano_de_obra,
        this.viaticos,
        this.uso_patron
      )
      .subscribe(
        (res) => {
          this.response = res;

          if (
            this.response.code == "1_0001" ||
            this.response.code == "1_0002" ||
            this.response.code == "1_0003"
          )
            this.lauchModal(this.response.code, this.response.message, "");
          else if (this.response.code == "1_0004")
            this.lauchModal(this.response.code, this.response.message, "");
          else {
            var data_result = this.response.data;
            if (data_result == 1) {
              this.notShowingFacturas();
              this.getOdvList();
            } else {
              this.lauchModal("0000x00", "Error de consulta", "");
            }
          }
          this.loader = 2;
        },
        (err) => {
          this.lauchModal("0000x00", "Error de consulta", "");
          console.log(err);
          this.loader = 2;
        }
      );
  }

 /**
  * This function launches a modal with a given code, title, and message using the Angular Material
  * modal service.
  * @param {string} code - a string representing some code that will be displayed in the modal
  * @param {string} title - The title of the modal window that will be displayed.
  * @param message - The message parameter is likely a string that contains the content to be displayed
  * in the modal dialog box. It could be an error message, a confirmation message, or any other type of
  * message that the user needs to see.
  */
  private async lauchModal(code: string, title: string, message) :Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
}
