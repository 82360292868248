import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { DriveService } from 'src/app/services/drive.service';
import { GeneratorReportsService } from 'src/app/services/generator-reports.service';
import { environment } from 'src/app/variables/enviroment';
import { DomSanitizer} from '@angular/platform-browser';
import { url } from 'inspector';


@Component({
  selector: 'app-directory',
  templateUrl: './directory.component.html',
  styleUrls: ['./directory.component.scss']
})
export class DirectoryComponent implements OnInit {

  uri:string = environment.uri;
  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');
  data_files:any = [];
  files:any = [];
  file_names = '';
  loader:number = 0;

  new_directory:string="";
  folders:any=[];
  array_usuarios:any=[];
  folder_select:string="";
  directory_act:string;
  id_propietario:string;
  nombresFiles:string=null;
  numSelect:number=0;
  url_file:string;
  user_propietario:any=[];
  url_to_load = '';

  file_to_delete:string='';

  permmisions = JSON.parse(localStorage.getItem('permisos'));

  constructor(private activedRoute:ActivatedRoute,
    private modalService:NgbModal,
    public conn: HttpClient,
    private driveService:DriveService,
    private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.directory_act=this.activedRoute.snapshot.paramMap.get('folder');
    this.id_propietario=this.activedRoute.snapshot.paramMap.get('id_propietario');    
    this.getFolders();
  }


  getFolders(){
    let result:any = [];
    this.loader = 1;
    this.driveService.readFolderUser(this.directory_act,this.id_propietario).subscribe(
      res=>{
        result = res;
        if(result.code == '1_0001' || result.code == '1_0002' || result.code == '1_0003')this.lauchModal(result.code,result.message);
          else if(result.code == '1_0004' || result.code == '0_007')this.lauchModal(result.code,result.message);
          else{
            let folders_a=result.data[0];
            folders_a.forEach(f => {
              f.nombre = f.nombre[0]+"."+f.nombre[1];
              //f.url = this.sanitizer.bypassSecurityTrustResourceUrl("https://portal.ucal.com.mx/storage/public/nube/"+this.id_propietario+"/"+this.directory_act+"/"+f.nombre);
            });
            folders_a = this.sortJSON(folders_a,'nombre','asc');
            this.folders = folders_a;
            this.user_propietario=result.data[1];
          }
          this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        console.log(err);
        this.loader = 2;
      }
    );
  }

  sortJSON(data, key, orden) {
    return data.sort(function (a, b) {
      var x = a[key],
      y = b[key];

      if (orden == 'asc') return ((x < y) ? -1 : ((x > y) ? 1 : 0))
      else return ((x > y) ? -1 : ((x < y) ? 1 : 0)); 
    });
  }

  fileDelete(nombre){
    this.file_to_delete = nombre;
  }

  deleteDocs(){
    let result:any = [];
    this.loader = 1;
    this.driveService.eliminarArchivos(this.file_to_delete,this.id_propietario,this.directory_act).subscribe(
      res => {
        result = res;
        console.log(result)
        if(result.code == '1_0001' || result.code == '1_0002' || result.code == '1_0003')this.lauchModal(result.code,result.message);
          else if(result.code == '1_0004' || result.code == '0_007')this.lauchModal(result.code,result.message);
          else{
            if(result.data == 1) this.getFolders();
            else this.lauchModal('Error','Ocurrió un error al eliminar el archivo');
          }
        this.loader = 2;
      },
      err =>{
        this.lauchModal('0000x00','Error de consulta');
        console.log(err);
        this.loader = 2;
      }
    )
  }

  loadFiles(value){
    for(var i = 0; i < value.target.files.length; i++){
      this.files.push(value.target.files[i]);
      this.nombresFiles ==null?this.nombresFiles=value.target.files[i].name
      : this.nombresFiles+=', '+value.target.files[i].name;
    }
    this.numSelect=this.files.length;
  }

  subirAchivos(){
    let result:any = [];
    this.loader = 1;

    if(this.files.length > 0){
      let form = new FormData();
      var i = 1;
      form.append("folder",this.directory_act)
      this.files.forEach(element => {
        form.append('file_'+i,element);
        i++;
      });

      this.driveService.subirMultiplesArchivos(form).subscribe(
        res=>{
          result = res;
          if(result.code == '1_0001' || result.code == '1_0002' || result.code == '1_0003')this.lauchModal(result.code,result.message);
          else if(result.code == '1_0004' || result.code == '0_007')this.lauchModal(result.code,result.message);
          else{
            this.data_files = result.data;
            if (this.data_files == 1) {
              this.getFolders();
            }
          }
          this.loader = 2;
        },err=>{
          this.lauchModal('0000x00','Error de consulta');
          console.log(err);
          this.loader = 2;
          
        }
        
      );

    }
  }

  lauchModal(code:string,title:string){
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

  backPage(){
    window.history.back();
  }

  viewDocs(url_doc){
    window.open(url_doc["changingThisBreaksApplicationSecurity"]);
  }

  downloadDoc(nombre){
    console.log(nombre)
    this.url_to_load = this.uri+"download_file_drive?api_key="+this.api_key+
    "&token="+this.token+"&module=111&id_propietario="+this.id_propietario+
    "&folder="+this.directory_act+"&nombre_doc="+nombre;
  }


}
