import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/app/variables/enviroment';

@Injectable({
  providedIn: 'root'
})
export class QrService {
  uri:string = environment.uri;
  //uri:string = "http://localhost/etalonsbackend/public/index.php/api/";
  //uri:string = "http://ngdat.ucal.com.mx/api/";

  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');
  
  constructor(private httpClient:HttpClient) { }

  getDepForUser(){
    return this.httpClient.get(this.uri+"get_departments_list_qr?api_key="+this.api_key+"&token="+this.token+"&module=39");
  }
  getReadedQR(valor){
    console.log(this.uri+"get_readed_qr?api_key="+this.api_key+"&token="+this.token+"&module=52&data="+valor);
    return this.httpClient.get(this.uri+"get_readed_qr?api_key="+this.api_key+"&token="+this.token+"&module=52&tipo=1&data="+valor);
  }
}
