import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { GeneratorReportsService } from 'src/app/services/generator-reports.service';
import { Chart } from 'chart.js';
import { HttpClient } from '@angular/common/http';
import { IInforme } from 'src/app/services/models/IInformes';
import { environment } from 'src/app/variables/enviroment';

@Component({
  selector: 'app-bascula-mb',
  templateUrl: './bascula-mb.component.html',
  styleUrls: ['./bascula-mb.component.css']
})
export class BasculaMbComponent implements OnInit {

  uri:string = environment.uri;
  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');
  url_to_load = '';

  columnas:any=[];
  finalValores:any=[];
  idsEquipos:any=[];
  idShet:string='';
  idpatron:any=[];

  equiposOdv:any=[]

  loader:number = 1;
  response:any = [];
  data:any=[];
  pdfInformes:any=[];
  clic:string;

  ibc_1:number;
  ibc_2:number;
  ibc_3:number;
  ibc_4:number;
  ibc_5:number;
  ibc_6:number;
  ibc_7:number;
  ibc_8:number;
  ibc_9:number;
  ibc_10:number;

  ref1:number;
  ref2:number;
  ref3:number;
  ref4:number;
  ref5:number;
  ref6:number;
  ref7:number;
  ref8:number;
  ref9:number;
  ref10:number;

  insert1:number;
  insert2:number;
  insert3:number;
  insert4:number;
  insert5:number;
  insert6:number;
  insert7:number;
  insert8:number;
  insert9:number;
  insert10:number;

  exce1:number;
  exce2:number;
  exce3:number;
  exce4:number;
  exce5:number;
  exce6:number;

  lugar:any = "sitio";
  temp:any;
  unidad:any;
  format:any = "AAA-MM-DD";
  fecha:any;
  ctx:any;
  myChart:any;

  valueSearchCliente: string="";
  listclientes:any = [];

  valueSearchPlanta: string="";
  listPlantas:any = [];

  repetibilidad50:number;
  repetibilidad100:number;

  constructor(
    private datos: ActivatedRoute,
    private router:Router, 
    public conn: HttpClient,
    private generadorReports:GeneratorReportsService, 
    private modalService:NgbModal
  ) { }

  ngOnInit(): void {
    let datos=this.datos.snapshot.paramMap.get('id_equipos')
    this.idsEquipos.push(datos.split(','));
    this.idShet = this.datos.snapshot.paramMap.get('id_hoja');
    this.idpatron = this.datos.snapshot.paramMap.get('id_patron');

    this.getData();
  }

  getData(){
    this.generadorReports.verPlantilla(this.idShet,this.idsEquipos).subscribe(
      res=>{
        this.response = res;
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
        else this.data = this.response.data;

        this.equiposOdv = this.data.equipos;
        this.listclientes = this.data.customers_list_search;

        this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        
        this.loader = 2;
      }
    )

   
  }

  obtClienteId(id_cliente:string){
    this.generadorReports.getPlantasByCliente(id_cliente).subscribe(
      res=>{
        this.response = res;
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
        else this.data = this.response.data;

        this.listPlantas = this.data;
        this.listPlantas.forEach(element => {
          if (element.principal == 1) {
            this.valueSearchPlanta = element.id;
          }
        });

        this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        this.loader = 2;
      }
    )
  }
  
  obtenerDatos(){

    this.loader = 1;
    var min_1 = 0; var min_2 = 0; var min_3 = 0;
    var max_1 = 0; var max_2 = 0; var max_3 = 0;
    var min_4 = 0; var min_5 = 0; var min_6 = 0;
    var max_4 = 0; var max_5 = 0; var max_6 = 0;
    var min_7 = 0; var min_8 = 0; var min_9 = 0;
    var max_7 = 0; var max_8 = 0; var max_9 = 0;
    var min_10 = 0; var max_10 = 0;

    var inTol_1=""; var inTol_2=""; var inTol_3="";
    var inTol_4=""; var inTol_5=""; var inTol_6="";
    var inTol_7=""; var inTol_8=""; var inTol_9=""; 
    var inTol_10="";

    var informes=[];
    for (let i = 0; i < this.equiposOdv.length; i++) {
      let listRef=[]; let listInsert=[];
      let listMin = []; let listMax = [];
      let listIbc = []; let listInTol = [];
      let listExc = [];
      let equipo_id = this.equiposOdv[i].idEquipo;


      this.exce1 = parseFloat((document.getElementById(equipo_id+"exc-0") as HTMLInputElement).value);
      listExc.push(this.exce1);
      this.exce2 = parseFloat((document.getElementById(equipo_id+"exc-1") as HTMLInputElement).value);
      listExc.push(this.exce2);
      this.exce3 = parseFloat((document.getElementById(equipo_id+"exc-2") as HTMLInputElement).value);
      listExc.push(this.exce3);
      this.exce4 = parseFloat((document.getElementById(equipo_id+"exc-3") as HTMLInputElement).value);
      listExc.push(this.exce4);
      this.exce5 = parseFloat((document.getElementById(equipo_id+"exc-4") as HTMLInputElement).value);
      listExc.push(this.exce5);
      this.exce6 = parseFloat((document.getElementById(equipo_id+"exc-5") as HTMLInputElement).value);
      listExc.push(this.exce6);


      this.ibc_1 = parseFloat((document.getElementById(equipo_id+"-0") as HTMLInputElement).value);
      listIbc.push(this.ibc_1);
      this.ibc_2 = parseFloat((document.getElementById(equipo_id+"-1") as HTMLInputElement).value);
      listIbc.push(this.ibc_2);
      this.ibc_3 = parseFloat((document.getElementById(equipo_id+"-2") as HTMLInputElement).value);
      listIbc.push(this.ibc_3);
      this.ibc_4 = parseFloat((document.getElementById(equipo_id+"-3") as HTMLInputElement).value);
      listIbc.push(this.ibc_4);
      this.ibc_5 = parseFloat((document.getElementById(equipo_id+"-4") as HTMLInputElement).value);
      listIbc.push(this.ibc_5);
      this.ibc_6 = parseFloat((document.getElementById(equipo_id+"-5") as HTMLInputElement).value);
      listIbc.push(this.ibc_6);
      this.ibc_7 = parseFloat((document.getElementById(equipo_id+"-6") as HTMLInputElement).value);
      listIbc.push(this.ibc_7);
      this.ibc_8 = parseFloat((document.getElementById(equipo_id+"-7") as HTMLInputElement).value);
      listIbc.push(this.ibc_8);
      this.ibc_9 = parseFloat((document.getElementById(equipo_id+"-8") as HTMLInputElement).value);
      listIbc.push(this.ibc_9);
      //this.ibc_10 = parseFloat((document.getElementById(equipo_id+"-9") as HTMLInputElement).value);
      //listIbc.push(this.ibc_10);

      //--------Referencia
      this.ref1 = parseFloat((document.getElementById(equipo_id+"re-0") as HTMLInputElement).value);
      listRef.push(this.ref1);
      this.ref2 = parseFloat((document.getElementById(equipo_id+"re-1") as HTMLInputElement).value);
      listRef.push(this.ref2);
      this.ref3 = parseFloat((document.getElementById(equipo_id+"re-2") as HTMLInputElement).value);
      listRef.push(this.ref3);
      this.ref4 = parseFloat((document.getElementById(equipo_id+"re-3") as HTMLInputElement).value);
      listRef.push(this.ref4);
      this.ref5 = parseFloat((document.getElementById(equipo_id+"re-4") as HTMLInputElement).value);
      listRef.push(this.ref5);
      this.ref6 = parseFloat((document.getElementById(equipo_id+"re-5") as HTMLInputElement).value);
      listRef.push(this.ref6);
      this.ref7 = parseFloat((document.getElementById(equipo_id+"re-6") as HTMLInputElement).value);
      listRef.push(this.ref7);
      this.ref8 = parseFloat((document.getElementById(equipo_id+"re-7") as HTMLInputElement).value);
      listRef.push(this.ref8);
      this.ref9 = parseFloat((document.getElementById(equipo_id+"re-8") as HTMLInputElement).value);
      listRef.push(this.ref9);
      //this.ref10 = parseFloat((document.getElementById(equipo_id+"re-9") as HTMLInputElement).value);
      //listRef.push(this.ref10);

      min_1 = this.ref1 - 0.10; max_1 = this.ref1 + 0.10;
      min_2 = this.ref2 - 0.10; max_2 = this.ref2 + 0.10;
      min_3 = this.ref3 - 0.10; max_3 = this.ref3 + 0.10;

      min_4 = this.ref4 - 0.20; max_4 = this.ref4 + 0.20;
      min_5 = this.ref5 - 0.20; max_5 = this.ref5 + 0.20;
      min_6 = this.ref6 - 0.20; max_6 = this.ref6 + 0.20;

      min_7 = this.ref7 - 0.30; max_7 = this.ref7 + 0.30;
      min_8 = this.ref8 - 0.30; max_8 = this.ref8 + 0.30;
      min_9 = this.ref9 - 0.30; max_9 = this.ref9 + 0.30;
      //min_10 = this.ref10 - 0.30; max_10 = this.ref10 + 0.30;

      listMin.push(min_1); listMin.push(min_2); listMin.push(min_3);
      listMin.push(min_4); listMin.push(min_5); listMin.push(min_6);
      listMin.push(min_7); listMin.push(min_8); listMin.push(min_9);
      //listMin.push(min_10);

      listMax.push(max_1); listMax.push(max_2); listMax.push(max_3); 
      listMax.push(max_4); listMax.push(max_5); listMax.push(max_6); 
      listMax.push(max_7); listMax.push(max_8); listMax.push(max_9);
      //listMax.push(max_10);

      this.ibc_1 < min_1 ? inTol_1 = 'No cumple' : this.ibc_1 > max_1 ? inTol_1 = 'No cumple'
      : inTol_1 = 'Si cumple';

      this.ibc_2 < min_2 ? inTol_2 = 'No cumple' : this.ibc_2 > max_2 ? inTol_2 = 'No cumple'
      : inTol_2 = 'Si cumple';

      this.ibc_3 < min_3 ? inTol_3 = 'No cumple' : this.ibc_3 > max_3 ? inTol_3 = 'No cumple'
      : inTol_3 = 'Si cumple';

      this.ibc_4 < min_4 ? inTol_4 = 'No cumple' : this.ibc_4 > max_4 ? inTol_4 = 'No cumple'
      : inTol_4 = 'Si cumple';

      this.ibc_5 < min_5 ? inTol_5 = 'No cumple' : this.ibc_5 > max_5 ? inTol_5 = 'No cumple'
      : inTol_5 = 'Si cumple';

      this.ibc_6 < min_6 ? inTol_6 = 'No cumple' : this.ibc_6 > max_6 ? inTol_6 = 'No cumple'
      : inTol_6 = 'Si cumple';

      this.ibc_7 < min_7 ? inTol_7 = 'No cumple' : this.ibc_7 > max_7 ? inTol_7 = 'No cumple'
      : inTol_7 = 'Si cumple';

      this.ibc_8 < min_8 ? inTol_8 = 'No cumple' : this.ibc_8 > max_8 ? inTol_8 = 'No cumple'
      : inTol_8 = 'Si cumple';

      this.ibc_9 < min_9 ? inTol_9 = 'No cumple' : this.ibc_9 > max_9 ? inTol_9 = 'No cumple'
      : inTol_9 = 'Si cumple';

      /*this.ibc_10 < min_10 ? inTol_10 = 'No cumple' : this.ibc_10 > max_10 ? inTol_10 = 'No cumple'
      : inTol_10 = 'Si cumple';*/

      (document.getElementById(equipo_id+"in0") as HTMLInputElement).setAttribute("value",inTol_1);
      listInTol.push(inTol_1);
      (document.getElementById(equipo_id+"in1") as HTMLInputElement).setAttribute("value",inTol_2);
      listInTol.push(inTol_2);
      (document.getElementById(equipo_id+"in2") as HTMLInputElement).setAttribute("value",inTol_3);
      listInTol.push(inTol_3);

      (document.getElementById(equipo_id+"in3") as HTMLInputElement).setAttribute("value",inTol_4);
      listInTol.push(inTol_4);
      (document.getElementById(equipo_id+"in4") as HTMLInputElement).setAttribute("value",inTol_5);
      listInTol.push(inTol_5);
      (document.getElementById(equipo_id+"in5") as HTMLInputElement).setAttribute("value",inTol_6);
      listInTol.push(inTol_6);

      (document.getElementById(equipo_id+"in6") as HTMLInputElement).setAttribute("value",inTol_7);
      listInTol.push(inTol_7);
      (document.getElementById(equipo_id+"in7") as HTMLInputElement).setAttribute("value",inTol_8);
      listInTol.push(inTol_8);
      (document.getElementById(equipo_id+"in8") as HTMLInputElement).setAttribute("value",inTol_9);
      listInTol.push(inTol_9);
      //(document.getElementById(equipo_id+"in9") as HTMLInputElement).setAttribute("value",inTol_10);
      //listInTol.push(inTol_10);


      //---------------------- Insertidumbre -------------------------
      this.insert1 = parseFloat((document.getElementById(equipo_id+"ser-0") as HTMLInputElement).value);
      listInsert.push(this.insert1);
      this.insert2 = parseFloat((document.getElementById(equipo_id+"ser-1") as HTMLInputElement).value);
      listInsert.push(this.insert2);
      this.insert3 = parseFloat((document.getElementById(equipo_id+"ser-2") as HTMLInputElement).value);
      listInsert.push(this.insert3);
      this.insert4 = parseFloat((document.getElementById(equipo_id+"ser-3") as HTMLInputElement).value);
      listInsert.push(this.insert4);
      this.insert5 = parseFloat((document.getElementById(equipo_id+"ser-4") as HTMLInputElement).value);
      listInsert.push(this.insert5);
      this.insert6 = parseFloat((document.getElementById(equipo_id+"ser-5") as HTMLInputElement).value);
      listInsert.push(this.insert6);
      this.insert7 = parseFloat((document.getElementById(equipo_id+"ser-6") as HTMLInputElement).value);
      listInsert.push(this.insert7);
      this.insert8 = parseFloat((document.getElementById(equipo_id+"ser-7") as HTMLInputElement).value);
      listInsert.push(this.insert8);
      this.insert9 = parseFloat((document.getElementById(equipo_id+"ser-8") as HTMLInputElement).value);
      listInsert.push(this.insert9);
      //this.insert10 = parseFloat((document.getElementById(equipo_id+"ser-9") as HTMLInputElement).value);
      //listInsert.push(this.insert10);

      var error0 = this.ibc_1 - this.ref1;
      var error1 = this.ibc_2 - this.ref2;
      var error2 = this.ibc_3 - this.ref3;
      var error3 = this.ibc_4 - this.ref4;
      var error4 = this.ibc_5 - this.ref5;
      var error5 = this.ibc_6 - this.ref6;
      var error6 = this.ibc_7 - this.ref7;
      var error7 = this.ibc_8 - this.ref8;
      var error8 = this.ibc_9 - this.ref9;
      //var error9 = this.ibc_10 - this.ref10;

      var incertidumbre1 = error0-this.insert1;
      var incertidumbre2 = error1-this.insert2;
      var incertidumbre3 = error2-this.insert3;
      var incertidumbre4 = error3-this.insert4;
      var incertidumbre5 = error4-this.insert5;
      var incertidumbre6 = error5-this.insert6;
      var incertidumbre7 = error6-this.insert7;
      var incertidumbre8 = error7-this.insert8;
      var incertidumbre9 = error8-this.insert9;
      //var incertidumbre10 = error9-this.insert10;

      var yincertidumbre1 = error0 + this.insert1;
      var yincertidumbre2 = error1 + this.insert2;
      var yincertidumbre3 = error2 + this.insert3;
      var yincertidumbre4 = error3 + this.insert4;
      var yincertidumbre5 = error4 + this.insert5;
      var yincertidumbre6 = error5 + this.insert6;
      var yincertidumbre7 = error6 + this.insert7;
      var yincertidumbre8 = error7 + this.insert8;
      var yincertidumbre9 = error8 + this.insert9;
      //var yincertidumbre10 = error9 + this.insert10;

      this.ctx = document.getElementById(equipo_id+"myChart");
      var step = 0.000;
      var max = 0.4000;
      var start = -0.4000;

      this.myChart = new Chart(this.ctx, {
        type: "line",
        data: {
          labels: ["0.0", "0.1", "0.1", "1.0", "2.0", "5.0", "10.0", "15.0", "25.0"],
          datasets: [
            {
              label: "Tolerancia del instrumento",
              data: [0.1000, 0.1000, 0.1000, 0.2000, 0.2000, 0.2000, 0.3000, 0.3000, 0.3000],
              fill: false,
              borderDash: [5,5],
              lineTension: 0.000001,
              borderColor: "rgb(0, 0, 255)"
            },
            {
              label: "Incertidumbre",
              data: [yincertidumbre1, yincertidumbre2, yincertidumbre3, 
                yincertidumbre4, yincertidumbre5, yincertidumbre6, 
                yincertidumbre7, yincertidumbre8, yincertidumbre9],
              fill: false,
              lineTension: 0.000001,
              borderColor: "rgb(0, 0, 0)"
            },
            {
              label: "Error = (Media Inst. - Patrón)",
              data: [error0, error1, error2, error3, error4, error5, error6, error7, error8],
              fill: false,
              lineTension: 0.000001,
              pointBackgroundColor: "rgb(0,0,0)",
              borderColor: "rgb(255, 0, 0)"
            },
            {
              label: "Incertidumbre",
              data: [incertidumbre1, incertidumbre2, incertidumbre3,
                incertidumbre4, incertidumbre5, incertidumbre6,
                incertidumbre7, incertidumbre8, incertidumbre9],
              fill: false,
              lineTension: 0.000001,
              borderColor: "rgb(0, 0, 0)"
            },
            {
              label: "Tolerancia del instrumento",
              data: [-0.1000, -0.1000, -0.1000, -0.2000, -0.2000, -0.2000, -0.3000, -0.3000, -0.3000],
              fill: false,
              borderDash: [5,5],
              lineTension: 0.000001,
              borderColor: "rgb(0, 0, 255)"
            },
          ],
        },
        options: {
          legend: {
            display: false
          }
        },
        scaleSteps: Math.ceil((max - start) / step),
                scaleStepWidth: step,
                scaleStartValue: start
      });
      
      if(this.temp == null || this.unidad == null || this.fecha == null) {
        alert("Campos vacios");
      } else {
        let global = [];
        global.push(this.temp);
        global.push(this.unidad);
        global.push(this.format);
        global.push(this.fecha);
        global.push(this.lugar);
        global.push(this.valueSearchCliente);
        global.push(this.repetibilidad50);
        global.push(this.repetibilidad100);
        global.push(this.valueSearchPlanta);
        
        let clic = this.uri+'bascula_mb_pdf?api_key='+this.api_key+'&token='+this.token+
        '&module=2&id_plantilla='+this.idShet+'&libc='+listIbc+'&inTol='+listInTol+
        '&lref='+listRef+'&linsert='+listInsert+'&lexce='+listExc+
        '&globales='+global+"&id_equipo="+equipo_id+"&patron_id="+this.idpatron;
        let a=[];
        a[0] = clic;
        a[1]=this.equiposOdv[i].identificador+this.equiposOdv[i].partida;
        a[2]=this.myChart;  
        informes.push(a);
      }

    }
    
    this.pdfInformes = informes;
    this.loader=2;
    
  }

  obtenerImagen(url_pdf,myChartId){
    this.loader = 1;
    url_pdf = url_pdf[0];
    let base64 = myChartId.toBase64Image();

    var to_send:IInforme = {
      img: base64,
    };

    this.conn.post(url_pdf, to_send).subscribe(
      res => {
        this.response = res;
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
        else this.data = this.response.data;
        let informe = this.data;
        //console.log(informe);
        this.loader = 2;
        this.downloadFile(informe);
      },
      error=>{
        //console.log(error);
        alert("Error de petición");
        this.loader = 2;
      }
    );
    
  }

  asignarInforme(url_pdf, myChartId){
    this.loader = 1;
    url_pdf = url_pdf[0];
    let base64 = myChartId.toBase64Image();

    var to_send:IInforme = {
      img: base64,
    };

    this.conn.post(url_pdf+"&asignar="+"asignar", to_send).subscribe(
      res => {
        this.response = res;
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
        else this.data = this.response.data;
        alert("Asignado correctamente");
        this.loader = 2;
      },
      error=>{
        console.log(error);
        alert("Error de petición");
        this.loader = 2;
      }
    );
  }

  downloadFile(file_name){
    var url = environment.uri_for_reports+file_name;
    window.open(url, '_blank');
  }
  
  lauchModal(code:string,title:string){
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

  
  
}