import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { DepotDetailComponent } from './depot/depot-detail/depot-detail.component';
import { DepotEditComponent } from './depot/depot-edit/depot-edit.component';
import { DepotComponent } from './depot/depot.component';
import { GeneratorXlsxComponent } from './generator-xlsx/generator-xlsx.component';
import { BasculaMbComponent } from './generatorreport/bascula-mb/bascula-mb.component';
import { DinamicGeneratorComponent } from './generatorreport/dinamic-generator/dinamic-generator.component';
import { EscantillonComponent } from './generatorreport/escantillon/escantillon.component';
import { GeneratorreportComponent } from './generatorreport/generatorreport.component';
import { ManometroPraxairComponent } from './generatorreport/manometro-praxair/manometro-praxair.component';
import { MedicionPhComponent } from './generatorreport/medicion-ph/medicion-ph.component';
import { MicrometroComponent } from './generatorreport/micrometro/micrometro.component';
import { NewReportComponent } from './generatorreport/new-report/new-report.component';
import { TermohigrometroEconomicoComponent } from './generatorreport/termohigrometro-economico/termohigrometro-economico.component';
import { TermohigrometroComponent } from './generatorreport/termohigrometro/termohigrometro.component';
import { TermometroEconomicoComponent } from './generatorreport/termometro-economico/termometro-economico.component';
import { TransmisorTemp120Component } from './generatorreport/transmisor-temp120/transmisor-temp120.component';
import { TransmisorTemp20Component } from './generatorreport/transmisor-temp20/transmisor-temp20.component';
import { VolumenHeineken01Component } from './generatorreport/volumen-heineken01/volumen-heineken01.component';
import { OperationsComponent } from './operations.component';
import { QrmakerComponent } from './qrmaker/qrmaker.component';
import { AddRsComponent } from './reporte-servicio/add-rs/add-rs.component';
import { EditRsComponent } from './reporte-servicio/edit-rs/edit-rs.component';
import { ReporteServicioComponent } from './reporte-servicio/reporte-servicio.component';
import { RsDetComponent } from './reporte-servicio/rs-det/rs-det.component';
import { GeneradorInformesAppComponent } from './reportmaker/generador-informes-app/generador-informes-app.component';
import { ReportmakerComponent } from './reportmaker/reportmaker.component';
import { SensorsComponent } from './sensors/sensors.component';
import { DetailExitComponent } from './vehicle/detail-exit/detail-exit.component';
import { DetailVehicleComponent } from './vehicle/detail-vehicle/detail-vehicle.component';
import { EditVehicleComponent } from './vehicle/edit-vehicle/edit-vehicle.component';
import { ExitVehicleComponent } from './vehicle/exit-vehicle/exit-vehicle.component';
import { VehicleComponent } from './vehicle/vehicle.component';
import { AddWikiComponent } from './wiki/add-wiki/add-wiki.component';
import { DetWikiComponent } from './wiki/det-wiki/det-wiki.component';
import { WikiComponent } from './wiki/wiki.component';
import { ForeignServiceComponent } from './foreign-service/foreign-service.component';
import { ForeignServiceCalendarComponent } from './foreign-service/foreign-service-calendar/foreign-service-calendar.component';

const routes: Routes = [
  //Operaciones
{ path: 'operations',    
children:[
{path:'',component:OperationsComponent},

{path:'rs', children:[
  {path:"",component:ReporteServicioComponent,},
  {path:'det/:id',component:RsDetComponent},
  {path:'add/:id_odv',component:AddRsComponent},
  {path:'edit/:id',component:EditRsComponent},
  ]
},
{path:'qrmaker', component: QrmakerComponent},

{path:'depot', children:[
  {path:'',component: DepotComponent},
  {path:'det/:tipo/:id',component: DepotDetailComponent},
  {path:'edit/:tipo/:id',component: DepotEditComponent},
  ]
},

{path:'reportmaker', children:[
  {path: '', component:ReportmakerComponent},
  {path: 'informes_app', component:GeneradorInformesAppComponent},
  {path: 'generator', children:[
    {path: '', component:GeneratorreportComponent},
    {path: 'new_report/:id_equipos/:id_hoja/:id_patron', component:NewReportComponent},
    {path: 'termohigrometro/:id_equipos/:id_hoja/:id_patron', component:TermohigrometroComponent},
    {path: 'transmisor_temp_20/:id_equipos/:id_hoja/:id_patron', component:TransmisorTemp20Component},
    {path: 'transmisor_temp_120/:id_equipos/:id_hoja/:id_patron', component:TransmisorTemp120Component},
    {path: 'medidor_ph/:id_equipos/:id_hoja/:id_patron', component:MedicionPhComponent},
    {path: 'termometro_economico/:id_equipos/:id_hoja/:id_patron', component:TermometroEconomicoComponent},
    {path: 'termohigrometro_economico/:id_equipos/:id_hoja/:id_patron', component:TermohigrometroEconomicoComponent},
    {path: 'bascula_masas/:id_equipos/:id_hoja/:id_patron', component:BasculaMbComponent},

    {path: 'volumen_01/:id_equipos/:id_hoja/:id_patron', component:VolumenHeineken01Component},

    //{path: 'volumen_01/:id_equipos/:id_hoja/:id_patron', component:VolumenHeineken01Component},
    {path: 'micrometro/:id_equipos/:id_hoja/:id_patron', component:MicrometroComponent},
    {path: 'escantillon/:id_equipos/:id_hoja/:id_patron', component:EscantillonComponent},
    {path: 'manometro/:id_equipos/:id_hoja/:id_patron', component:ManometroPraxairComponent},
    {path: 'data_sheet/:id_equipos/:id_hoja/:id_patron', component:DinamicGeneratorComponent}
  ]},

]},

{path:'generator-xlsx', children:[
  {path: '', component:GeneratorXlsxComponent},
]},

{path:'wiki', children:[
  {path: '', component: WikiComponent},
  {path: 'add_wiki', component: AddWikiComponent},
  {path: 'det/:id_det', component: DetWikiComponent}
]},
{path:'sensors', component:SensorsComponent},

{path:'vehicle', children:[
  {path: '', component: VehicleComponent},
  {path: 'detail/:id', component: DetailVehicleComponent},
  {path: 'exit/:id', component: ExitVehicleComponent},
  {path: 'exit_det/:id', component: DetailExitComponent},
  {path: 'edit/:id', component: EditVehicleComponent},
]},

{path:'foreign-service', children:[
  {path: '', component: ForeignServiceCalendarComponent},
  {path: 'calendar', component: ForeignServiceCalendarComponent},
  {path: 'instruments', component:  ForeignServiceComponent},
  
]},


]
},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OperationsRoutingModule { }
