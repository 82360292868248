import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { DashboardService } from 'src/app/services/dashboard.service';
import { environment } from 'src/app/variables/enviroment';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  today = new Date( Date.now()).toLocaleDateString();
  number_page= 0;
  pages = 0;
  day_cont = 0;
  public loader=1;
  user_image:string='assets/img/theme/user_default.png';
  response:any = [];
  notifications:any = [];

  permmisions = JSON.parse(localStorage.getItem('permisos'));

  data :any = [];

  constructor(private dashboardService:DashboardService,private modalService:NgbModal) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(){
    const fecha = new Date();
    const dia= fecha.getDate() + this.day_cont;
    var dia_to = this.zeroFill(dia,2);
    let mes:any = fecha.getMonth() + 1;
    if(mes<10){
      mes = "0" + mes.toString();
    }
    var fecha_a_comparar = fecha.getFullYear() + '-' +mes+ '-' + dia_to;

    this.dashboardService.getNotifications(0).subscribe(
      res=>{
        this.data = [];
        this.response = res;
        if(this.response.code != '0_005')this.lauchModal(this.response.code,this.response.message);
        else {
          this.notifications = this.response.data;

          this.notifications.forEach(a => {
            
            if(a.image == 1)a.img_url = environment.uri_server+"storage/public/fotos_metrologos/"+a.created_by+".jpg";
            else a.img_url = this.user_image;

            var fecha_div = a.created_at.split(' ');
            
            if(fecha_div[0] === fecha_a_comparar){
              this.data.push(a);
            }
          });
        }
        
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        console.log(err);
      }
    );
  }

  zeroFill( number, width ){
    width -= number.toString().length;
    if ( width > 0 )
    {
      return new Array( width + (/\./.test( number ) ? 2 : 1) ).join( '0' ) + number;
    }
    return number + ""; // always return a string
  }

  changePageResults(page){this.number_page = page;}

  changeDay(value){
    var to_sum = 0;
    if(value) this.day_cont++;
    else this.day_cont--;
    this.data=[];
    this.CargarNotificacionesDIA();
  }

  CargarNotificacionesDIA(){
    const fecha = new Date();
    const dia= fecha.getDate() + this.day_cont;
    var dia_to = this.zeroFill(dia,2);
    let mes:any = fecha.getMonth() + 1;
    if(mes<10){
      mes = "0" + mes.toString();
    }
    var fecha_a_comparar = fecha.getFullYear() + '-' +mes+ '-' + dia_to;

    this.notifications.forEach(a => {
            
      if(a.image == 1)a.img_url = environment.uri_server+"storage/public/fotos_metrologos/"+a.created_by+".jpg";
      else a.img_url = this.user_image;

      var fecha_div = a.created_at.split(' ');
      
      if(fecha_div[0] === fecha_a_comparar){
        this.data.push(a);
      }
    });
  }

  lauchModal(code:string,title:string){
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }
}
