import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { IRSComplement } from "src/app/services/models/IRSComplement";
import { IRSDelete } from "src/app/services/models/IRSDelete";
import { OrdenVentaService } from "src/app/services/orden-venta.service";
import { ServiceReportService } from "src/app/services/service-report.service";
import { environment } from "src/app/variables/enviroment";

@Component({
  selector: "app-rs-det",
  templateUrl: "./rs-det.component.html",
  styleUrls: ["./rs-det.component.css"],
})
export class RsDetComponent implements OnInit {
  uri: string = environment.uri; //"http://ngdat.ucal.com.mx/api/";
  //uri:string = "http://portal.ucal.com.mx/api/";
  token = localStorage.getItem("token");
  api_key = localStorage.getItem("key");

  url_to_load = "";
  aux: number = 0;

  id_rs: string = "";
  loader: number = 1;
  response: any = [];
  rs_det: any = [];
  list_to_delete: any = [];
  permmisions = JSON.parse(localStorage.getItem("permisos"));

  comment = "";
  file: any;
  file_name = "";

  files_list: any = [];
  comments_list: any = [];

  //generar pdf reporte
  list_comments: any = [];
  instruments_to_list_modal: any = [];

  action = 0;

  edit = 0;
  parts_quantity = 0;

  service_in:string = 'en Base';

  CAN_EDIT_RS:boolean = true;
  
  constructor(
    private modalService: NgbModal,
    private rs_service: ServiceReportService,
    private activedRoute: ActivatedRoute,
    private odvService: OrdenVentaService
  ) {}

  ngOnInit(): void {
    this.id_rs = this.activedRoute.snapshot.paramMap.get("id");
    this.habilitarDescarga();
    this.loadData();
  }

  /**
   * The function "habilitarDescarga" checks if a certain permission is included in an array and sets a
   * variable accordingly.
   */
  private habilitarDescarga(): void {
    if (this.permmisions.includes(74)) {
      this.aux = 1;
    }
  }

  /**
   * The function loads data from a server and handles different response codes.
   */
  private loadData(): void {
    this.loader = 1;
    this.rs_service.getDetail(this.id_rs).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003" ||
          this.response.code == "1_0004" ||
          this.response.code == "0_007"
        )
          this.lauchModal(this.response.code, this.response.message);
        else {
          console.log(this.response.data);
          this.rs_det = this.response.data;
          this.parts_quantity = this.rs_det.parts.length;
          this.files_list = this.rs_det.files;
          this.comments_list = this.rs_det.com;

          this.CAN_EDIT_RS = this.canEditRS(this.files_list);
          
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        this.loader = 2;
      }
    );
  }

  /**
   * The function "canEditRS" checks if a given file is editable by returning true if the file is empty
   * and false if it is not.
   * @param archivo - The parameter "archivo" is a variable that represents a file.
   * @returns a boolean value. If the length of the "archivo" is greater than 0, it will return false.
   * Otherwise, it will return true.
   */
  private canEditRS(archivo):boolean {

    if(archivo.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * This function saves a comment for a specific item and displays a modal with a message depending on
   * the result.
   */
  public saveCommentODV(): void {
    this.loader = 1;
    let result: any = [];
    let form: IRSComplement = {
      id_rs: this.id_rs,
      notes: this.comment,
    };
    this.rs_service.addComment(form).subscribe(
      (res) => {
        result = res;
        if (
          result.code == "1_0001" ||
          result.code == "1_0002" ||
          result.code == "1_0003"
        )
          this.lauchModal(result.code, result.message);
        else if (result.code == "1_0004" || result.code == "0_007")
          this.lauchModal(result.code, result.message);
        else {
          if (result.data == 1) {
            this.comment = "";
            this.loadData();
          } else
            this.lauchModal(
              "0000x00",
              "Algo salió mal, no se ha podido realizar el comentario. Intenta nuevamente"
            );
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        this.loader = 2;
      }
    );
  }

  /**
   * This function saves a file by sending it to a server using a POST request and displays a modal
   * with the result.
   */
  public saveFile(): void {
    let result: any = [];
    this.loader = 1;

    if (this.file.files.length > 0) {
      let form = new FormData();
      form.append("file", this.file.files[0]);
      form.append("id_rs", this.id_rs);

      this.rs_service.addFile(form).subscribe(
        (res) => {
          result = res;
          if (
            result.code == "1_0001" ||
            result.code == "1_0002" ||
            result.code == "1_0003"
          )
            this.lauchModal(result.code, result.message);
          else if (result.code == "1_0004" || result.code == "0_007")
            this.lauchModal(result.code, result.message);
          else {
            if (result.data == 1) {
              this.file_name = "";
              this.loadData();
            } else
              this.lauchModal("0000x00", "Algo salió mal. Intenta nuevamente");
          }
          this.loader = 2;
        },
        (err) => {
          this.lauchModal("0000x00", "Error de consulta");
          this.loader = 2;
        }
      );
    }
  }

  /**
   * This function loads a file and sets its name as a property.
   * @param value - The parameter "value" is an event object that is passed as an argument to the
   * function. It contains information about the event that triggered the function, such as the target
   * element that was interacted with. In this case, it is likely an event object related to a file
   * input element, as the
   */
  public loadFile(value): void {
    this.file = value.target;
    this.file_name = this.file.files[0].name;
  }

  /**
   * The function changes the value of the "edit" variable to the input parameter "val".
   * @param val - val is a parameter of type boolean that is passed to the function changeToEdit(). It
   * is used to set the value of the property 'edit' to either true or false, depending on the value of
   * val.
   */
  public changeToEdit(val): void {
    this.edit = val;
  }

  /**
   * This function edits a value in a form and sends the updated data to a server using an HTTP
   * request.
   * @param id_part - an identifier for a part
   * @param data - The data parameter is an event object that contains information about the event that
   * triggered the function. Specifically, it contains the value of the input field that was edited.
   */
  public editValue(id_part, data): void {
    let edit = data.target.value;
    let result;
    this.loader = 1;

    let form = new FormData();
    form.append("id_part", id_part);
    form.append("value", edit);

    this.rs_service.editPart(form).subscribe(
      (res) => {
        result = res;
        if (
          result.code == "1_0001" ||
          result.code == "1_0002" ||
          result.code == "1_0003"
        )
          this.lauchModal(result.code, result.message);
        else if (result.code == "1_0004" || result.code == "0_007")
          this.lauchModal(result.code, result.message);
        else {
          if (result.data == 1) this.loadData();
          else
            this.lauchModal(
              "0000x00",
              "Algo salió mal, no se ha podido realizar el comentario. Intenta nuevamente"
            );
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        this.loader = 2;
      }
    );
  }

  /**
   * The `manageList` function manages a list by adding or removing an element based on whether it
   * already exists in the list.
   * @param id_part - The parameter `id_part` is the identifier of an item in the list that needs to be
   * managed.
   */
  public manageList(id_part): void {
    if (!this.list_to_delete.includes(id_part))
      this.list_to_delete.push(id_part);
    else {
      var i = this.list_to_delete.indexOf(id_part);
      this.list_to_delete.splice(i, 1);
    }
  }

  /**
   * The `deleteParts` function sends a request to delete parts from a resource, and then updates the
   * data and UI accordingly.
   */
  public deleteParts(): void {
    this.loader = 1;
    let data: any = [];
    var to_send: IRSDelete = {
      id: this.id_rs,
      parts: this.list_to_delete,
      create_new_rs: 0,
    };
    this.rs_service.deleteParts(to_send).subscribe(
      (res) => {
        data = res;
        if (
          data.code == "1_0001" ||
          data.code == "1_0002" ||
          data.code == "1_0003" ||
          data.code == "1_0004" ||
          data.code == "0_007"
        )
          this.lauchModal(data.code, data.message);
        else {
          this.loadData();
        }
        this.loader = 2;
        this.list_to_delete = [];
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
    this.list_to_delete = [];
  }

  /**
   * The `fulfillInstruments` function sends a request to save parts fulfillment data and handles the
   * response accordingly.
   */
  public fulfillInstruments(): void {
    this.loader = 1;
    let data: any = [];
    let form = new FormData();
    form.append("id", this.id_rs);
    form.append("parts", this.list_to_delete);

    this.rs_service.savePartsfulfill(form).subscribe(
      (res) => {
        data = res;
        if (
          data.code == "1_0001" ||
          data.code == "1_0002" ||
          data.code == "1_0003" ||
          data.code == "1_0004" ||
          data.code == "0_007"
        )
          this.lauchModal(data.code, data.message);
        else {
          this.list_to_delete = [];
          this.loadData();
        }
        this.list_to_delete = [];
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
    this.list_to_delete = [];
    this.changeToEdit(0);
  }

  /**
   * The function `nofulfillInstrumentsApplyStructureData` filters out instruments from `rs_det.parts`
   * based on their `id` and adds them to the `instruments_no_fulfill` array.
   */
  public InstrumentsApplyStructureData(tipo:number) {
    this.instruments_to_list_modal = [];
    let instruments = this.rs_det.parts;

    for (let i = 0; i < instruments.length; i++) {
      for (let f = 0; f < this.list_to_delete.length; f++) {
        if (instruments[i].id == this.list_to_delete[f]) {
          this.instruments_to_list_modal.push(instruments[i]);
        }
      }
    }
    this.action=tipo;
  }

  /**
   * The function saves comments for instruments that are not fulfilled and sends them.
   */
  public saveNofulfillInstruments(): void {
    for (let i = 0; i < this.instruments_to_list_modal.length; i++) {
      let comment = (
        document.getElementById(
          this.instruments_to_list_modal[i].id
        ) as HTMLInputElement
      ).value;
      this.list_comments.push(comment);
    }
    this.sendCommentNoFulfill();
  }

  /**
   * The function `sendCommentNoFulfill()` sends a comment along with some data to a server and handles
   * the response accordingly.
   */
  public sendCommentNoFulfill() {
    this.loader = 1;
    let data: any = [];
    let form = new FormData();
    form.append("id", this.id_rs);
    form.append("parts", this.list_to_delete);
    form.append("comment", this.list_comments);
    form.append('action',this.action.toString());

    this.rs_service.savePartsNofulfill(form).subscribe(
      (res) => {
        data = res;
        if (
          data.code == "1_0001" ||
          data.code == "1_0002" ||
          data.code == "1_0003" ||
          data.code == "1_0004" ||
          data.code == "0_007"
        )
          this.lauchModal(data.code, data.message);
        else {
          this.list_to_delete = [];
          this.loadData();
        }
        this.list_to_delete = [];
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
    this.list_to_delete = [];
    this.changeToEdit(0);
  }

  /**
   * The function `downloadReportServicePdf` sends a request to generate a PDF report and then
   * downloads the generated file.
   */
  public downloadReportServicePdf() :void {
    let data: any = [];
    let form = new FormData();
    form.append('id_rs',this.id_rs);
    form.append('service',this.service_in)
    this.rs_service.generatePdfRs(form).subscribe(
      (res) => {
        data = res;
        if (
          data.code == "1_0001" ||
          data.code == "1_0002" ||
          data.code == "1_0003" ||
          data.code == "1_0004" ||
          data.code == "0_007"
        )
          this.lauchModal(data.code, data.message);
        else {
          this.downloadFile(data.data);
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        this.loader = 2;
      }
    );
  }

  /**
   * The function `descargarPdf` sets the `url_to_load` property to a specific URL based on the
   * provided parameters.
   * @param {string} archivo - The "archivo" parameter is a string that represents the name or
   * identifier of the PDF file that you want to download.
   */
  public descargarPdf(archivo: string): void {
    this.url_to_load =
      this.uri +
      "get_pdf_rs?api_key=" +
      this.api_key +
      "&token=" +
      this.token +
      "&module=37&id_rs=" +
      this.id_rs +
      "&archivo=" +
      archivo;
  }

  /**
   * The function `downloadFile` opens a new window with the URL of a file to be downloaded.
   * @param file_name - The file name is a string that represents the name of the file you want to
   * download.
   */
  private downloadFile(file_name) :void {
    var url = environment.uri_server + "storage/public/plantilla_ejem/" + file_name;
    window.open(url, "_blank");
  }

  public downloadZIP() :void {
    this.loader = 1;

    var data = new FormData();
    data.append("id", this.rs_det.odv_id);
    data.append("odv_pdf", 'false');
    data.append("partidas_xlsx", 'true');
    data.append("informes_pdf", 'true');
    data.append("archivos", 'false');
    data.append("informes_xlsx", 'false');

    let response;
    this.odvService.downloadAllDataZip(data).subscribe(
      (res) => {
        response = res;
        if (response.code != "0_005")
          this.lauchModal(response.code, response.message);
        else this.dowloadZipFile(response.data);
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        this.loader = 2;
      }
    );
  }
  private dowloadZipFile(name) :void {
    var url = environment.uri_server + "storage/public/odvs_zip/" + name;
    window.open(url, "_blank");
  }

  /**
   * The `lauchModal` function opens a modal component with a given code and title.
   * @param {string} code - A string representing the code that will be displayed in the modal.
   * @param {string} title - The `title` parameter is a string that represents the title of the modal.
   * It is used to set the `title` property of the `ModalComponent` instance.
   */
  private async lauchModal(code: string, title: string): Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }
}
