import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermohigrometroComponent } from './generatorreport/termohigrometro/termohigrometro.component';
import { GeneratorXlsxComponent } from './generator-xlsx/generator-xlsx.component';
import { NewReportComponent } from './generatorreport/new-report/new-report.component';
import { GeneratorreportComponent } from './generatorreport/generatorreport.component';
import { AddRsComponent } from './reporte-servicio/add-rs/add-rs.component';
import { EditRsComponent } from './reporte-servicio/edit-rs/edit-rs.component';
import { VolumenHeineken01Component } from './generatorreport/volumen-heineken01/volumen-heineken01.component';
import { DetWikiComponent } from './wiki/det-wiki/det-wiki.component';
import { BasculaMbComponent } from './generatorreport/bascula-mb/bascula-mb.component';
import { TermometroEconomicoComponent } from './generatorreport/termometro-economico/termometro-economico.component';
import { TermohigrometroEconomicoComponent } from './generatorreport/termohigrometro-economico/termohigrometro-economico.component';
import { MedicionPhComponent } from './generatorreport/medicion-ph/medicion-ph.component';
import { TransmisorTemp120Component } from './generatorreport/transmisor-temp120/transmisor-temp120.component';
import { TransmisorTemp20Component } from './generatorreport/transmisor-temp20/transmisor-temp20.component';
import { DepotComponent } from './depot/depot.component';
import { EscantillonComponent } from './generatorreport/escantillon/escantillon.component';
import { MicrometroComponent } from './generatorreport/micrometro/micrometro.component';
import { GeneradorInformesAppComponent } from './reportmaker/generador-informes-app/generador-informes-app.component';

import { ManometroPraxairComponent } from './generatorreport/manometro-praxair/manometro-praxair.component';
import { DepotEditComponent } from './depot/depot-edit/depot-edit.component';
import { DepotDetailComponent } from './depot/depot-detail/depot-detail.component';
import { AddWikiComponent } from './wiki/add-wiki/add-wiki.component';
import { SensorsComponent } from './sensors/sensors.component';
import { WikiComponent } from './wiki/wiki.component';
import { ReportmakerComponent } from './reportmaker/reportmaker.component';
import { QrmakerComponent } from './qrmaker/qrmaker.component';
import { RsDetComponent } from './reporte-servicio/rs-det/rs-det.component';
import { ReporteServicioComponent } from './reporte-servicio/reporte-servicio.component';
import { OperationsComponent } from './operations.component';
import { OperationsRoutingModule } from './operations-routing.module';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { VehicleComponent } from './vehicle/vehicle.component';
import { DetailVehicleComponent } from './vehicle/detail-vehicle/detail-vehicle.component';
import { ExitVehicleComponent } from './vehicle/exit-vehicle/exit-vehicle.component';
import { EditVehicleComponent } from './vehicle/edit-vehicle/edit-vehicle.component';
import { DinamicGeneratorComponent } from './generatorreport/dinamic-generator/dinamic-generator.component';
import { DetailExitComponent } from './vehicle/detail-exit/detail-exit.component';
import { ForeignServiceComponent } from './foreign-service/foreign-service.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { ForeignServiceCalendarComponent } from './foreign-service/foreign-service-calendar/foreign-service-calendar.component';


@NgModule({
  declarations: [
    OperationsComponent,
    ReporteServicioComponent,
    RsDetComponent,
    QrmakerComponent,
    ReportmakerComponent,
    WikiComponent,
    SensorsComponent,
    AddWikiComponent,
    DepotDetailComponent,
    DepotEditComponent,
    ManometroPraxairComponent,
    GeneradorInformesAppComponent,
    MicrometroComponent,
    EscantillonComponent,
    DepotComponent,
    TransmisorTemp20Component,
    TransmisorTemp120Component,
    MedicionPhComponent,
    TermohigrometroEconomicoComponent,
    TermometroEconomicoComponent,
    BasculaMbComponent,
    DetWikiComponent,
    VolumenHeineken01Component,
    EditRsComponent,
    AddRsComponent,
    GeneratorreportComponent,
    NewReportComponent,
    GeneratorXlsxComponent,
    TermohigrometroComponent,
    VehicleComponent,
    DetailVehicleComponent,
    ExitVehicleComponent,
    EditVehicleComponent,
    DinamicGeneratorComponent,
    DetailExitComponent,
    ForeignServiceComponent,
    ForeignServiceCalendarComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    OperationsRoutingModule,
    FullCalendarModule

  ]
})
export class OperationsModule { }
