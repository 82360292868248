import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IndicatorService } from "src/app/services/indicator.service";
import { Chart } from "chart.js";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { environment } from "src/app/variables/enviroment";

@Component({
  selector: "app-general-indicators",
  templateUrl: "./general-indicators.component.html",
  styleUrls: ["./general-indicators.component.css"],
})
export class GeneralIndicatorsComponent implements OnInit {
  metro: any;
  chart_metro: any = null;

  missig_reports: any;
  chart_missig_reports: any = null;

  result: any = [];
  loader = 1;

  month_selected = "08";

  months_list = [
    { id: "01", desc: "Enero" },
    { id: "02", desc: "Febrero" },
    { id: "03", desc: "Marzo" },
    { id: "04", desc: "Abril" },
    { id: "05", desc: "Mayo" },
    { id: "06", desc: "Junio" },
    { id: "07", desc: "Julio" },
    { id: "08", desc: "Agosto" },
    { id: "09", desc: "Septiembre" },
    { id: "10", desc: "Octubre" },
    { id: "11", desc: "Noviembre" },
    { id: "12", desc: "Diciembre" },
  ];

  constructor(
    private modalService: NgbModal,
    private indicatorSer: IndicatorService
  ) {}

  ngOnInit(): void {
    this.metro = document.getElementById("chart_metrologist");
    this.missig_reports = document.getElementById("chart_missing_reports");

    this.getAllIndicators(this.month_selected);
    this.getMissingReports();
  }

  /**
   * This function validates a general chart by destroying the current chart, setting the selected
   * month, and getting all indicators for that month.
   * @param month - The "month" parameter is a variable that represents the selected month for which
   * the general chart needs to be validated. It is passed as an argument to the "validateGeneralChart"
   * method.
   */
  public validateGeneralChart(month) :void {
    this.chart_metro.destroy();
    this.month_selected = month;
    this.getAllIndicators(month);
  }

  /**
   * The function retrieves data from an API and generates a bar chart using the retrieved data.
   * @param month - The month for which the indicators are being fetched.
   */
  private getAllIndicators(month) :void {
    this.indicatorSer.getAllIndicators(month).subscribe(
      (res) => {
        this.result = res;
        if (
          this.result.code == "1_0001" ||
          this.result.code == "1_0002" ||
          this.result.code == "1_0003"
        )
          this.lauchModal(this.result.code, this.result.message);
        else if (this.result.code == "1_0004")
          this.lauchModal(this.result.code, this.result.message);
        else {
          var user_name = [];
          var user_loads = [];
          var user_obj = [];

          for (var i = 0; i < this.result.data.length; i++) {
            user_name.push(this.result.data[i].name);
            user_loads.push(this.result.data[i].loads);
            user_obj.push(this.result.data[i].objective);
          }

          this.chart_metro = new Chart(this.metro, {
            type: "bar",
            data: {
              labels: user_name,
              datasets: [
                {
                  label: "Informes",
                  data: user_loads,
                  borderColor: "rgb(255, 0, 0)",
                  backgroundColor: "rgba(255, 0, 0,0.2)",
                },
                {
                  label: "Metas",
                  data: user_obj,
                  borderColor: "rgb(0, 255, 0)",
                  backgroundColor: "rgba(0, 255, 0,0.2)",
                },
              ],
            },
            options: {
              responsive: true,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
              plugins: {
                legend: {
                  position: "top",
                },
                title: {
                  display: false,
                },
              },
            },
          });
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * The function retrieves missing reports data and displays it in a radar chart, with error handling
   * for potential API errors.
   */
  private getMissingReports() :void {
    this.indicatorSer.getInstrumentWithoutReports().subscribe(
      (res) => {
        this.result = res;
        if (
          this.result.code == "1_0001" ||
          this.result.code == "1_0002" ||
          this.result.code == "1_0003"
        )
          this.lauchModal(this.result.code, this.result.message);
        else if (this.result.code == "1_0004")
          this.lauchModal(this.result.code, this.result.message);
        else {
          this.chart_missig_reports = new Chart(this.missig_reports, {
            type: "radar",
            data: {
              labels: ["<10", ">30", ">10"],
              datasets: [
                {
                  label: "MTY",
                  data: this.result.data[0],
                  borderColor: "rgb(255, 153, 169)",
                  backgroundColor: "rgba(252, 204, 204,0)",
                },
                {
                  label: "RA",
                  data: this.result.data[1],
                  borderColor: "rgb(136, 206, 251)",
                  backgroundColor: "rgba(192, 249, 250,0)",
                },
                {
                  label: "SLP",
                  data: this.result.data[2],
                  borderColor: "rgb(100, 233, 135)",
                  backgroundColor: "rgba(146, 242, 148,0)",
                },
              ],
            },
            options: {
              responsive: true,
              plugins: {
                legend: {
                  position: "top",
                },
                title: {
                  display: false,
                },
              },
              scale: {
                ticks: {
                  min: 0,
                },
              },
            },
          });
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * The function retrieves an XLSX file containing data for all indicators for a selected month and
   * downloads it if available, otherwise displays an error message.
   */
  public getGeneralXlsx() :void {
    this.indicatorSer.getAllIndicatorsXLSX(this.month_selected).subscribe(
      (res) => {
        this.result = res;
        if (
          this.result.code == "1_0001" ||
          this.result.code == "1_0002" ||
          this.result.code == "1_0003"
        )
          this.lauchModal(this.result.code, this.result.message);
        else if (this.result.code == "1_0004")
          this.lauchModal(this.result.code, this.result.message);
        else {
          if (this.result.data == "0")
            this.lauchModal(this.result.code, this.result.message);
          else {
            this.downloadFile(this.result.data);
          }
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * The function downloads a file from a specific URL and opens it in a new window.
   * @param doc - The "doc" parameter is a string that represents the name or identifier of the file
   * that needs to be downloaded. It is used to construct the URL of the file to be downloaded.
   */
  private downloadFile(doc) :void {
    var url = environment.uri_server + "storage/public/indicadores/" + doc;
    window.open(url, "_blank");
  }

 /**
  * This function launches a modal with a given code and title using the Angular Material modal
  * service.
  * @param {string} code - A string parameter that represents the code to be displayed in the modal
  * window.
  * @param {string} title - The title parameter is a string that represents the title of the modal that
  * will be launched. It is used to set the title property of the ModalComponent instance that is
  * created.
  */
  private async lauchModal(code: string, title: string) :Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }
}
