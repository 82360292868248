import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { CustomerService } from 'src/app/services/customer.service';
import { ICustomer } from 'src/app/services/models/ICustomer';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.css']
})
export class AddCustomerComponent implements OnInit {
  abreviatura:string = "";
  name:string = "";
  rfc:string = "";
  moneda:string = "";
  pago:string = "";
  notas:string = "";
  facturas:string = "";
  web:string = "";
  //direcciones
  razondir:string = "";
  calle:string = "";
  colonia:string = "";
  numero:string = "";
  ciudad:string = "";
  estado:string = "";
  cp:string = "";
  addlikeaddress:string = "0";
  addlikedireccion:string = "0";
  tipo:string = "0";
  depto:string = "General";

  result: any ;

  constructor(private router:Router ,private customerServ: CustomerService,
    private modalService:NgbModal) { }

  ngOnInit(): void {
  }

 /**
  * The function toggles the value of a variable between "0" and "1".
  */
  public plantaActivate() : void{
    if(this.addlikeaddress == "1"){
      this.addlikeaddress = "0";
    }else{
      this.addlikeaddress = "1";
    }
  }

  /**
   * This function toggles the value of a variable between "0" and "1".
   */
  public direccionActivate() :void {
    if(this.addlikedireccion == "1"){
      this.addlikedireccion = "0";
    }else{
      this.addlikedireccion = "1";
    }
  }

  /**
   * This function posts customer data to a server and navigates to a different page if successful.
   */
  public postCustomer() :void {
    if(this.addlikedireccion == "1" || this.addlikeaddress == "1"){
      this.addlikeaddress = "1";
      this.tipo = "1";
      if(this.addlikedireccion == "1" && this.addlikeaddress == "1"){
        this.addlikeaddress = "2";
        this.tipo = "2";
      }
    }

    let iCliente: ICustomer = {
      id_cliente: "0",
      abreviatura: this.abreviatura,
      rfc: this.rfc,
      nombre: this.name,
      notas: this.notas,
      ingreso_facturas: this.facturas,
      pagina_web: this.web,
      tipo_moneda: this.moneda,
      id_tipo_pago: this.pago,
      cliente_direccion: this.addlikeaddress,
      nombre_direccion: this.razondir,
      calle: this.calle,
      colonia: this.colonia,
      n_exterior: this.numero,
      ciudad: this.ciudad,
      estado: this.estado,
      cp: this.cp,
      tipo: this.tipo,
      departamento: this.depto
    };

    
    this.customerServ.postCustomer(iCliente).subscribe(
      res=>{
        
        this.result = res;
        if(this.result.data > 0){
          this.router.navigate(['programmers/customers']);
        }else{
          this.lauchModal(this.result.code, this.result.message);
        }
        
      },
      err=>{
        
        console.log(err);
      }
    );
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code and title using
   * the Angular modal service.
   * @param {string} code - The code parameter is a string that represents the code that will be
   * displayed in the modal.
   * @param {string} title - The title parameter is a string that represents the title of the modal
   * that will be launched.
   */
  private async lauchModal(code:string,title:string) :Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

}
