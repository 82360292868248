import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { VehicleService } from "src/app/services/vehicle.service";

@Component({
  selector: "app-detail-vehicle",
  templateUrl: "./detail-vehicle.component.html",
  styleUrls: ["./detail-vehicle.component.css"],
})
export class DetailVehicleComponent implements OnInit {
  loader = true;
  response: any = [];
  data: any = [];
  exits: any = [];
  services: any = [];
  kms: any = [];
  prox_man = "";
  id = "";

  //Varibles para agregar mantenimiento
  place_maintenance = "";
  price_maintenance = "";
  next_maintenance_km = "";
  next_maintenance_date = "";
  notes_maintenance = "";

  //Variables para agregar mantenimiento
  mont_year = "";
  km_initial = "";
  km_final = "";

  //Precio de la gasolina
  gas_price = 23.03;

  constructor(
    private modalService: NgbModal,
    private vehicleService: VehicleService,
    private activedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.id = this.activedRoute.snapshot.paramMap.get("id");
    this.getData();
  }

  private getData() :void {
    this.loader = true;

    this.vehicleService.getVehicleDetail(this.id).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.data = this.response.data;

          this.kms = this.data.kms;
          this.km_initial = this.data.km;
          this.exits = this.data.salidas;
          this.services = this.data.servicios;

          if (this.services.length > 0)
            this.prox_man =
              this.services[0].prox_man_km +
              " KM / " +
              this.services[0].prox_man_fecha;
          else this.prox_man = "N/A";
        }

        this.loader = false;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = false;
      }
    );
  }

  private saveMaintenance() :void {
    this.loader = true;

    let data = new FormData();
    data.append("vehicle", this.id);
    data.append("place", this.place_maintenance);
    data.append("notes", this.notes_maintenance);
    data.append("price", this.price_maintenance);
    data.append("man_km", this.next_maintenance_km);
    data.append("man_date", this.next_maintenance_date);

    this.vehicleService.saveNewService(data).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.getData();
        }

        this.loader = false;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = false;
      }
    );
  }

  private saveKMManager() :void {
    this.loader = true;

    let data = new FormData();
    data.append("vehicle", this.id);
    data.append("km_i", this.km_initial);
    data.append("km_f", this.km_final);
    data.append("month_year", this.mont_year);

    this.vehicleService.saveKMManager(data).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.getData();
        }

        this.loader = false;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = false;
      }
    );
  }

  private async lauchModal(code: string, title: string) :Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }
}
