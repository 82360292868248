import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
/*
export const ROUTES_PAGES: RouteInfo[] = [
    { path: '/dashboard', title: 'Inicio',  icon: 'ni-tv-2 text-primary', class: '' },
    { path: '/inventory', title: 'Inventario',  icon: 'ni-collection text-orange', class: '' },
    { path: '/calendar', title: 'Calendario',  icon: 'ni-calendar-grid-58 text-yellow', class: '' },
    { path: '/traceability', title: 'Trazabilidad',  icon: 'ni-air-baloon text-info', class: '' }
    /*
    { path: '/user-profile', title: 'User profile',  icon:'ni-single-02 text-yellow', class: '' },
    { path: '/tables', title: 'Tables',  icon:'ni-bullet-list-67 text-red', class: '' },
    { path: '/login', title: 'Login',  icon:'ni-key-25 text-info', class: '' },
    { path: '/register', title: 'Register',  icon:'ni-circle-08 text-pink', class: '' }*/
//];


export const ROUTES_MODULES: RouteInfo[] = [
  { path: '/administration', title: 'Administración',  icon: 'ni-world-2', class: '' },
  { path: '/operations', title: 'Operaciones',  icon: 'ni-settings', class: '' },
  { path: '/commercial', title: 'Comercial',  icon: 'ni-basket', class: '' },
  { path: '/programmers', title: 'Programadores',  icon: 'ni-spaceship', class: '' }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  permisos = JSON.parse(localStorage.getItem('permisos'));
  public isCollapsed = true;
  value_to_search:string ='';
  public menuItems: any[] = [
    { path: '/dashboard', title: 'Inicio',  icon: 'ni-tv-2 text-primary', class: '' }
  ];
  public menuModules:any[] = [
  ];

  constructor(private router: Router,private userService:UserService) { 
  }

  ngOnInit() {
    this.validarPermisos();
  }

  searchValue(){
    this.router.navigateByUrl('search_results',{skipLocationChange:true});
  }

  validarPermisos(){
      this.menuModules = [];
      this.menuItems = [{ path: '/dashboard', title: 'Inicio',  icon: 'ni-tv-2 text-primary', class: '' }];
      //PERMISOS DE ACCESO
      if(this.permisos.includes(1)){
        let uri = { path: '/inventory', title: 'Inventario',  icon: 'ni-collection text-orange', class: '' };
        this.menuItems.push(uri);
      }
      if(this.permisos.includes(2)){
        let uri = { path: '/calendar', title: 'Calendario',  icon: 'ni-calendar-grid-58 text-yellow', class: '' };
        this.menuItems.push(uri);
      }
      if(this.permisos.includes(3)){
        let uri = { path: '/traceability', title: 'Trazabilidad',  icon: 'ni-air-baloon text-info', class: '' };
        this.menuItems.push(uri);
      }
      if(this.permisos.includes(52)){
        let uri = { path: '/qr', title: 'Lector QR',  icon: 'fas fa-qrcode text-orange', class: '' };
        this.menuItems.push(uri);
      }
      if (this.permisos.includes(105)) {
        let uri = { path: '/drive', title: 'Nube',  icon: 'fab fa-google-drive text-green', class: '' };
        this.menuItems.push(uri);
      }

      //PERMISOS DE MODULOS
      if(this.permisos.includes(179)) {
        let uri = { path: '/administration', title: 'Administración',  icon: 'ni-world-2', class: '' };
        this.menuModules.push(uri);
      }
      if(this.permisos.includes(4)) {
        let uri =  { path: '/operations', title: 'Operaciones',  icon: 'ni-settings', class: '' };
        this.menuModules.push(uri);
      }
      if(this.permisos.includes(7)) {
        let uri =  { path: '/commercial', title: 'Comercial',  icon: 'ni-basket', class: '' };
        this.menuModules.push(uri);
      }
      if(
        this.permisos.includes(14) ||
        this.permisos.includes(21) ||
        this.permisos.includes(23)
        ){
          let uri =  { path: '/programmers', title: 'Programadores',  icon: 'ni-spaceship', class: '' };
          this.menuModules.push(uri);
        }
  }

  closeSession(){
    this.userService.closeSession();
  }
  
  reloadUpdate(){
    window.parent.location.reload();
  }
  
}
