import { Component, OnInit, ViewChild } from "@angular/core";
import { CalendarOptions, FullCalendarComponent } from "@fullcalendar/angular";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { InstrumentService } from "src/app/services/instrument.service";
@Component({
  selector: "app-foreign-service-calendar",
  templateUrl: "./foreign-service-calendar.component.html",
  styleUrls: ["./foreign-service-calendar.component.css"],
})
export class ForeignServiceCalendarComponent implements OnInit {
  user_id: number;
  loader: number = 1;
  response: any = [];
  data: any = [];
  solicitudes = [];
  detalle_evento = [];

  //cambio de vistas
  vista: number = 0;
  verDetalle: number = 0;
  prog_salida: number = 0;
  patron_selected: boolean = true;
  add_date: boolean = false;

  //busqueda
  busqueda: string = "";
  busqueda_data: any = [];
  patrones_off = [];

  vinc_info: any = {};
  info = 0;
  vinculado = 0;

  //equipoSeleccionado
  temp_tag: string = "";
  temp_status: number;
  temp_tipo: string = "";
  temp_id: number;

  //fechas
  current_date: string;
  year:number;
  fecha_inicio: string;
  fecha_regreso: string;
  fechas_envio = [];
  finalFechas = [];
  fechas_agregadas = [];
  fechas_bloqueadas = [];
  fechas_temp_off = [];
  programado = false;

  @ViewChild("calendar") calendarComponent: FullCalendarComponent;
  calendarOptions: CalendarOptions = {
    initialView: "dayGridMonth",
    locale: "es",
    navLinks: false,
    selectable: false,
    contentHeight: "auto",
    headerToolbar: {
      start: "today",
      center: "title",
      end: "prev,next",
    },
    events: this.finalFechas,
  };
  constructor(
    private instrumentService: InstrumentService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.verifyPatronEstatus();
    this.current_date = this.getCurrentDate();
    this.obtenerSolicitudes();
  }

  /**
   * This function sets up a calendar view with specified options and event sources, and binds a
   * function to handle date clicks.
   */
  VerCalendario(): void {
    this.add_date = false;
    this.calendarOptions = {
      initialView: "dayGridMonth",
      locale: "es",
      navLinks: false,
      selectable: false,
      contentHeight: "auto",
      headerToolbar: {
        start: "today",
        center: "title",
        end: "prev,next",
      },
      eventSources: [
        {
          events: this.finalFechas,
        },
      ],
      eventClick: this.handleDateClick.bind(this),
    };
  }

  /**
   * This function sets up a calendar with specific options and event sources for selecting dates
   * within a valid range.
   */
  calendarioSeleccionFechas(): void {
    this.patron_selected = true;
    this.busqueda_data = [];
    this.patrones_off = [];
    this.add_date = true;
    let year = new Date().getFullYear();

    this.calendarOptions = {
      initialView: "dayGridMonth",
      initialDate: this.current_date,
      locale: "es",
      navLinks: false,
      selectable: true,
      editable: false,
      contentHeight: "auto",
      headerToolbar: {
        start: "today",
        center: "title",
        end: "prev,next",
      },
      select: this.seleccionFechas.bind(this),
      validRange: {
        start: this.current_date,
        end:
          (year + 1).toString() +
          "-" +
          this.current_date.substring(5, 7) +
          "-" +
          this.current_date.substring(8, 10),
      },
      eventSources: [
        {
          events: this.fechas_agregadas,
        },
        {
          events: this.fechas_bloqueadas,
        },
        {
          events: this.fechas_envio,
        },
      ],
      selectOverlap: false,
    };
  }

  /**
  * The function "seleccionFechas" sets the start and end dates for a given range of dates.
  * @param arg - arg is a parameter of the function "seleccionFechas". It is likely an object that
  * contains two properties: "startStr" and "endStr". These properties likely represent the start and
  * end dates of a selected date range. The function sets the values of "fecha_inicio" and "fecha_regreso"
  */
  seleccionFechas(arg): void {
    this.fecha_inicio = arg.startStr;
    this.fecha_regreso = arg.endStr;
  }

  /**
 * The function handles a date click event and displays details of a request if found.
 * @param arg - The "arg" parameter is an object that contains information about the clicked date on a
 * calendar. It may include properties such as the date, the event associated with the date, and any
 * extended properties of the event.
 */
  handleDateClick(arg): void {
    this.detalle_evento = [];
    this.prog_salida = 0;
    this.vista = 0;
    let request = arg.event.extendedProps.request;

    const found = this.solicitudes.find((sol) => sol.req_id == request);
    if (found) {
      this.detalle_evento.push(found);
      this.verDetalle = 1;
    }
  }

  /**
  * The function builds a calendar structure based on given requests, colors, and types.
  * @param solicitudes - an array of objects representing requests or events to be displayed on a
  * calendar
  * @param color - The color to be used for the calendar event if the status of the request is not 1,
  * 2, or 3.
  * @param type - The "type" parameter is used to determine the type of calendar event being created.
  * It can have one of three values:
  */
  construirEstructuraCalendario(solicitudes, color, type): void {
    solicitudes.forEach((solicitud) => {
      let arrayFechas = {};
      arrayFechas["start"] = solicitud.inicio;
      arrayFechas["overlap"] = false;
      arrayFechas["end"] = solicitud.regreso;
      arrayFechas["title"] = solicitud.tag;
      arrayFechas["request"] = solicitud.req_id;
      arrayFechas["eq_id"] = solicitud.eq_id;
      arrayFechas["estatus"] = solicitud.estatus;
      if (solicitud.estatus == 3) {
        arrayFechas["color"] = "#D83131";
      } else if (solicitud.estatus == 2) {
        arrayFechas["color"] = "#6BD649";
      } else if (solicitud.estatus == 1) {
        arrayFechas["color"] = "#43DDDD";
      } else {
        arrayFechas["color"] = color;
      }

      if (type == 1) {
        this.finalFechas.push(arrayFechas);
      } else if (type == 2) {
        arrayFechas["display"] = "background";
        arrayFechas["textColor"] = "#000000";
        this.fechas_bloqueadas.push(arrayFechas);
      } else if (type == 3) {
        this.fechas_envio.push(arrayFechas);
      }
    });
  }

  /**
  * The function adds a list of dates to a calendar and checks if the selected dates overlap with
  * existing dates for a specific equipment.
  */
  agregarLista(): void {
    let obj_fechas: any = {};
    let array_fechas: any = [];
    let repetido = 0;
    let repetido2 = 0;
    repetido = this.verificarFechasRepetidas(this.fechas_envio, this.temp_id);
    repetido2 = this.verificarFechasRepetidas( this.fechas_bloqueadas,this.temp_id);

    if (repetido == 0 && repetido2 == 0) {
      obj_fechas.inicio = this.fecha_inicio;
      obj_fechas.regreso = this.fecha_regreso;
      obj_fechas.tag = this.temp_tag;
      obj_fechas.eq_id = this.temp_id;
      array_fechas.push(obj_fechas);

      this.construirEstructuraCalendario(array_fechas, "#6BD649", 3);
      this.calendarioSeleccionFechas();
    } else {
      alert("El equipo tiene una fecha programada en el rango seleccionado");
    }
  }

  /**
   * The function checks if there are any repeated dates in a list of dates for a specific team.
   * @param fechas - an array of objects containing start and end dates, as well as an id for the
   * equipment being reserved.
   * @param id_equipo - The ID of the equipment being checked for overlapping dates.
   * @returns a number, which is either 0 or 1 depending on whether there are repeated dates in the
   * `fechas` array for the given `id_equipo`.
   */
  verificarFechasRepetidas(fechas, id_equipo): number {
    let new_inicio = new Date(this.fecha_inicio);
    let new_fin = new Date(this.fecha_regreso);
    new_inicio.setDate(new_inicio.getDate() + 1);
    let repetido = 0;
    const found_bloqueadas = fechas.find((sol) => sol.eq_id === id_equipo);
    if (found_bloqueadas) {
      fechas.forEach((fechas_list) => {
        let inicio = new Date(fechas_list.start);
        let fin = new Date(fechas_list.end);
        inicio.setDate(inicio.getDate() + 1);
        if (new_inicio <= fin && new_fin >= inicio) {
          repetido = 1;
        }
      });
    }

    return repetido;
  }

  /**
   * This function checks if a requested date range for a piece of equipment overlaps with any existing
   * scheduled dates and either reprograms the request or alerts the user if there is a conflict.
   * @param sol - an array of objects representing equipment requests that need to be checked for
   * overlapping dates. Each object has properties such as eq_id (equipment ID), req_id (request ID),
   * and other details.
   */
  verificarFechaReprogramada(sol) {
    let obj_fechas: any = {};
    let repetido: number = 0;
    let req_id: number;
    let comp_fechas = [];

    this.finalFechas.forEach((fechas) => {
      if (fechas.estatus != 3) {
        comp_fechas.push(fechas);
      }
    });
    sol.forEach((solicitud) => {
      repetido = this.verificarFechasRepetidas(comp_fechas, solicitud.eq_id);
      req_id = solicitud.req_id;
    });

    if (repetido == 0) {
      obj_fechas.inicio = this.fecha_inicio;
      obj_fechas.regreso = this.fecha_regreso;
      obj_fechas.req_id = req_id;

      this.ReprogramarSolicitud(obj_fechas);
    } else {
      alert("El equipo tiene una fecha programada en el rango seleccionado");
    }
  }

  /**
   * This function removes a date from an array and updates a calendar.
   * @param index - The index parameter is an integer that represents the position of the element to be
   * removed from the array "fechas_envio". The splice method is used to remove the element at the
   * specified index.
   */
  eliminarFecha(index): void {
    this.fechas_envio.splice(index, 1);
    this.calendarioSeleccionFechas();
  }

  /**
   * The function "recopilarFechas" creates and retrieves requests based on a list of dates.
   */
  recopilarFechas(): void {
    this.fechas_envio.forEach((solicitud) => {
      this.crearSolicitud(solicitud);
    });
    setTimeout(() => {
      this.obtenerSolicitudes();
      this.programado = true;
      this.loader = 2;
    }, 2000);
  }

  /**
   * This function creates a new instrument request and handles the response accordingly.
   * @param solicitud - It is an object that contains information about a request for an instrument. It
   * has the following properties:
   */
  crearSolicitud(solicitud) {
    this.loader = 1;
    this.programado = false;
    let form_d = new FormData();
    form_d.append("fecha_inicio", solicitud.start);
    form_d.append("fecha_fin", solicitud.end);
    form_d.append("equipo", solicitud.title);
    form_d.append("nuevo", "1");
    this.instrumentService.saveInstrumentRequest(form_d).subscribe(
      (res) => {
        let response: any = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004" || response.code == "0_0007")
          this.lauchModal(response.code, response.message, "");
        else {
          this.fechas_envio = [];
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * The function sets information about an event and its details.
   * @param id - The ID of the event or request being referenced.
   * @param folio - The parameter "folio" is likely a reference to a unique identifier or reference
   * number associated with a particular record or transaction. It is being passed as an argument to
   * the "getInfo" function along with the "id" parameter. The function appears to be iterating over an
   * array called "detalle_evento
   */
  getInfo(id, folio) {
    this.detalle_evento.forEach((det) => {
      this.vinc_info.start = det.fecha_inicio;
      this.vinc_info.end = det.fecha_inicio;
      this.vinc_info.req_id = det.req_id;
      this.vinc_info.id = id;
      this.vinc_info.folio = folio;
    });
    this.info = 1;
  }

  /**
   * This function handles an event linking request and updates the UI accordingly.
   * @param solicitud - It is an object that contains the following properties:
   */
  vincularEvento(solicitud) {
    this.loader = 1;
    this.vinculado = 0;
    let form_d = new FormData();
    form_d.append("fecha_inicio", solicitud.start);
    form_d.append("fecha_fin", solicitud.end);
    form_d.append("req_id", solicitud.req_id);
    form_d.append("event", solicitud.id);
    form_d.append("nuevo", "0");
    this.instrumentService.saveInstrumentRequest(form_d).subscribe(
      (res) => {
        let response: any = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004" || response.code == "0_0007")
          this.lauchModal(response.code, response.message, "");
        else {
          this.verDetalle = 0;
          this.obtenerSolicitudes();
        }
        this.vinculado = 1;
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * This function saves an instrument request and launches a modal based on the response code.
   * @param solicitud - It is an object that contains the following properties:
   */
  vincularRS(solicitud) {
    this.loader = 1;
    this.vinculado = 0;
    let form_d = new FormData();
    form_d.append("fecha_inicio", solicitud.start);
    form_d.append("fecha_fin", solicitud.end);
    form_d.append("req_id", solicitud.req_id);
    form_d.append("rs", solicitud.id);
    form_d.append("nuevo", "0");
    this.instrumentService.saveInstrumentRequest(form_d).subscribe(
      (res) => {
        let response: any = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004" || response.code == "0_0007")
          this.lauchModal(response.code, response.message, "");
        else {
          this.verDetalle = 0;
          this.obtenerSolicitudes();
        }
        this.vinculado = 1;
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
 * The function obtains instrument requests and constructs a calendar structure based on the data
 * received.
 */
  obtenerSolicitudes(): void {
    this.finalFechas = [];
    let allRequests = [];
    this.instrumentService.getAllInstrumentRequests(this.year).subscribe(
      (res) => {
        let response: any = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004" || response.code == "0_0007")
          this.lauchModal(response.code, response.message, "");
        else {
          this.solicitudes = response.data.solicitudes;
          this.user_id = response.data.user;
          this.solicitudes.forEach((solicitud) => {
            let fechasEquipos: any = {};
            fechasEquipos.req_id = solicitud.req_id;
            fechasEquipos.tag = solicitud.tag;
            fechasEquipos.eq_id = solicitud.eq_id;
            fechasEquipos.estatus = solicitud.estatus;
            fechasEquipos.inicio = solicitud.fecha_inicio;
            fechasEquipos.regreso = solicitud.fecha_regreso;

            allRequests.push(fechasEquipos);
          });
          this.cambiarEstatusDeUsuario(allRequests);
        }
        this.construirEstructuraCalendario(allRequests, "#5e72e4", 1);
        this.VerCalendario();
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

 /**
  * This function saves an instrument request and launches a modal with a response message.
  * @param solicitud - It is an object that contains the information related to a request, including
  * the start and end dates, request ID, and a flag indicating whether it is a new request or an
  * existing one that needs to be rescheduled.
  */
  ReprogramarSolicitud(solicitud) {
    this.loader = 1;
    this.programado = false;
    let form_d = new FormData();
    form_d.append("fecha_inicio", solicitud.inicio);
    form_d.append("fecha_fin", solicitud.regreso);
    form_d.append("req_id", solicitud.req_id);
    form_d.append("nuevo", "0");
    this.instrumentService.saveInstrumentRequest(form_d).subscribe(
      (res) => {
        let response: any = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004" || response.code == "0_0007")
          this.lauchModal(response.code, response.message, "");
        else {
          this.obtenerSolicitudes();
        }
        this.programado = true;
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * This function retrieves requests for a selected instrument and constructs a calendar structure
   * based on the retrieved data.
   */
  obtenerSolicitudesEquipo(): void {
    let req_off = [];
    this.fechas_bloqueadas = [];
    this.loader = 1;
    this.instrumentService
      .getSelectedInstrumentRequests(this.temp_id)
      .subscribe(
        (res) => {
          this.response = res;
          if (
            this.response.code == "1_0001" ||
            this.response.code == "1_0002" ||
            this.response.code == "1_0003"
          )
            this.lauchModal(this.response.code, this.response.message, "");
          else if (
            this.response.code == "1_0004" ||
            this.response.code == "0_0007"
          )
            this.lauchModal(this.response.code, this.response.message, "");
          else {
            this.data = this.response.data;
            this.data.forEach((solicitud) => {
              if (solicitud.estatus != 3) {
                let fechasEquipos: any = {};
                fechasEquipos.req_id = solicitud.req_id;
                fechasEquipos.tag = solicitud.tag;
                fechasEquipos.eq_id = solicitud.eq_id;
                fechasEquipos.inicio = solicitud.fecha_inicio;
                fechasEquipos.regreso = solicitud.fecha_regreso;

                req_off.push(fechasEquipos);
              }
            });
            this.construirEstructuraCalendario(req_off, "#ED5757", 2);
            this.calendarioSeleccionFechas();
          }

          this.loader = 2;
        },
        (err) => {
          this.lauchModal("0000x00", "Error de consulta", "");

          this.loader = 2;
        }
      );
  }

 /**
  * The function changes the status of a user's equipment and event if it is currently in use.
  * @param fechas - an array of objects containing information about a user's request, including the
  * start and end dates of the request, the status of the request, and the ID of the equipment being
  * requested. The function checks if the current date is within the range of the request dates and
  * changes the status of the request and
  */
  cambiarEstatusDeUsuario(fechas): void {
    fechas.forEach((fe) => {
      let fechaFin = new Date(fe.regreso);
      let fechaInicio = new Date(fe.inicio);
      let currentDate = new Date();
      //SI ESTA EN USO CAMBIAR ESTATUS DEL EQUIPO Y DEL EVENTO
      if (
        fe.estatus == 0 &&
        currentDate.getTime() >= fechaInicio.getTime() &&
        currentDate.getTime() < fechaFin.getTime()
      ) {
        this.cambiarEstatusSolicitud(fe.req_id, 1);
        this.saveNewPatronEstatus(fe.eq_id, 1);
      }
    });
  }

  /**
   * This function changes the status of a request and displays a modal based on the response code.
   * @param req - It is a parameter that represents the request object that contains information about
   * the instrument request.
   * @param estatus - The "estatus" parameter is a variable that represents the new status that will be
   * assigned to a request. It is passed as an argument to the "cambiarEstatusSolicitud" function.
   */
  cambiarEstatusSolicitud(req, estatus): void {
    this.loader = 1;
    this.instrumentService.saveInstrumentRequestEstatus(req, estatus).subscribe(
      (res) => {
        this.response = res;

        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_0007"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.obtenerSolicitudes();
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");

        this.loader = 2;
      }
    );
  }

  /**
   * The function saves a new patron status for a given equipment and displays a modal based on the
   * response code.
   * @param equipo - It is a variable that represents the equipment for which the patron status is
   * being saved. The exact nature of the equipment is not clear from the given code snippet.
   * @param estatus - The "estatus" parameter is likely a status or state that is being assigned to a
   * "patron" (which could refer to a user or customer) associated with an "equipo" (which could refer
   * to a device or equipment).
   */
  saveNewPatronEstatus(equipo, estatus): void {
    this.loader = 1;
    this.instrumentService.savePatronEstatus(equipo, estatus).subscribe(
      (res) => {
        this.response = res;

        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_0007"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          // TODO document why this block is empty
        
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");

        this.loader = 2;
      }
    );
  }

  /**
   * The function verifies the status of a patron and launches a modal with a message based on the
   * response code.
   */
  verifyPatronEstatus(): void {
    this.loader = 1;
    this.instrumentService.verifyPatronEstatus().subscribe(
      (res) => {
        this.response = res;

        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_0007"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          // TODO document why this block is empty
        
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");

        this.loader = 2;
      }
    );
  }

  /**
   * This function searches for main instruments based on a given pattern and handles different
   * response codes accordingly.
   */
  buscarPatron(): void {
    this.loader = 1;
    this.instrumentService.searchMainInstruments(this.busqueda).subscribe(
      (res) => {
        this.response = res;

        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_0007"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.patrones_off = this.response.data;

          this.busqueda_data = this.patrones_off.filter(estatus => estatus.estatus_patron != 2 && estatus.estatus_patron != 3);
          this.patrones_off = this.patrones_off.filter(estatus => estatus.estatus_patron != 0 && estatus.estatus_patron != 1);
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");

        this.loader = 2;
      }
    );
  }

  /**
   * This function searches for an event based on a given search term and displays the results or an
   * error message.
   */
  buscarEvento(): void {
    this.loader = 1;
    this.instrumentService.searchEventPatron(this.busqueda).subscribe(
      (res) => {
        this.response = res;

        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_0007"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.busqueda_data = this.response.data;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");

        this.loader = 2;
      }
    );
  }

  /**
   * This function searches for a patron using a given query and displays the results or an error
   * message.
   */
  buscarRS(): void {
    this.loader = 1;
    this.instrumentService.searchRSPatron(this.busqueda).subscribe(
      (res) => {
        this.response = res;

        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_0007"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.busqueda_data = this.response.data;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");

        this.loader = 2;
      }
    );
  }

 /**
  * The function vaciarBusqueda() clears the search data and sets the info variable to 0.
  */
  vaciarBusqueda() {
    this.busqueda = "";
    this.busqueda_data = [];
    this.info = 0;
  }

  /**
   * The function toggles between two views and resets some variables accordingly.
   */
  cambiarVista(): void {
    if (this.vista == 0) {
      this.vista = 1;
      this.verDetalle = 0;
      this.prog_salida = 0;
    } else {
      this.vista = 0;
      this.VerCalendario();
    }
  }
  
  /**
   * This function toggles the visibility of a detailed view in a TypeScript program.
   */
  cerrarDetalleSolicitud(): void {
    if (this.verDetalle == 0) {
      this.verDetalle = 1;
      this.vista = 0;
    } else {
      this.verDetalle = 0;
    }
  }

  /**
   * The function toggles the program output and resets some variables if necessary.
   */
  programarSalida(): void {
    if (this.prog_salida == 0) {
      this.prog_salida = 1;
      this.verDetalle = 0;
      this.vista = 0;
    } else {
      this.VerCalendario();
      this.busqueda = "";
      this.prog_salida = 0;
      this.temp_id = null;
      this.temp_tag = "";
      this.temp_tipo = "";
      this.temp_status = null;
    }
  }

 /**
  * The function clears the start and return dates if they are empty or have the same value, and
  * displays an alert message if the dates are not whole days.
  */
  clearFecha(): void {
    if (this.fecha_inicio != null) {
      if (this.fecha_inicio.trim() == "") {
        this.fecha_inicio = null;
      }
      if (this.fecha_regreso != null) {
        if (this.fecha_regreso.trim() == "") {
          this.fecha_regreso = null;
        }
        if (this.fecha_inicio == this.fecha_regreso) {
          this.fecha_regreso = null;
          alert("Solo Se admiten dias enteros");
        }
      }
    }
  }


  /**
   * The function sets temporary variables for patron ID, tag, type, and status and sets the
   * patron_selected variable to false.
   * @param id - The ID of the patron being selected.
   * @param tag - The "tag" parameter is likely a unique identifier or label associated with a patron.
   * It could be a barcode, RFID tag, or some other type of identifier used to track and manage patron
   * information.
   * @param tipo - "tipo" is a variable that represents the type of patron. It could be a student,
   * faculty member, staff, or any other category of patron.
   * @param estatus - "estatus" is a variable that represents the status of a patron. It could be a
   * string or a number that indicates whether the patron is active, inactive, suspended, or any other
   * status that the system may have defined. The "selectPatron" function sets the values of the
   * "temp_status
   */
  selectPatron(id, tag, tipo, estatus): void {
    this.temp_id = id;
    this.temp_tag = tag;
    this.temp_tipo = tipo;
    this.temp_status = estatus;
    this.patron_selected = false;
  }

  /**
   * This function returns the current date in a specific API format.
   * @returns a string representing the current date in the format "YYYY-MM-DD".
   */
  getCurrentDate(): string {
    let cDate = new Date();
    let day: any = cDate.getDate();
    let month: any = cDate.getMonth() + 1;
    this.year = cDate.getFullYear();
    let dateInApiFormat: string;

    if (day < 10) day = "0" + day.toString();
    if (month < 10) month = "0" + month.toString();
    dateInApiFormat = this.year + "-" + month + "-" + day;
    return dateInApiFormat;
  }

  /**
   * This function launches a modal with a given code, title, and message.
   * @param {string} code - A string representing some code that will be displayed in the modal.
   * @param {string} title - The title parameter is a string that represents the title of the modal
   * window that will be displayed.
   * @param message - The message parameter is likely a string that contains a message to be displayed
   * in the modal dialog box. It could be an error message, a confirmation message, or any other type
   * of message that the user needs to see.
   */
  lauchModal(code: string, title: string, message) {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
}