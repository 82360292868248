import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { SuppliersService } from "src/app/services/suppliers.service";

@Component({
  selector: "app-send-email",
  templateUrl: "./send-email.component.html",
  styleUrls: ["./send-email.component.css"],
})
export class SendEmailComponent implements OnInit {
  id_supplier = "";
  identifier = "";
  notes = "";
  show_explain_message = false;
  loader = 0;
  email_to_add = "";

  emails_to_send = [];
  response: any = [];
  constructor(
    private activedRoute: ActivatedRoute,
    private supplierService: SuppliersService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.id_supplier = this.activedRoute.snapshot.paramMap.get("id");
    this.getData();
  }

  /**
   * The function retrieves data from a supplier service and updates the loader status accordingly.
   */
  private getData(): void {
    this.loader = 1;
    let data: any = [];
    this.supplierService.getDetailToEdit(this.id_supplier).subscribe(
      (res) => {
        this.response = res;

        if (this.response.code !== "0_005")
          this.lauchModal(this.response.code, this.response.message);
        else {
          data = this.response.data;
          this.identifier = data.nombre;
        }
        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * This function sends an email to evaluate a supplier and displays a response message.
   */
  public sendEmail(): void {
    this.loader = 1;
    let data: any = [];

    var to_send = new FormData();
    to_send.append("supplier_id", this.id_supplier);
    to_send.append("emails", JSON.stringify(this.emails_to_send));
    to_send.append("notes", this.notes);

    this.supplierService.emailToEvaluate(to_send).subscribe(
      (res) => {
        this.response = res;

        if (this.response.code !== "0_005")
          this.lauchModal(this.response.code, this.response.message);
        else {
          data = this.response.data;
          console.log(data);
          this.show_explain_message = true;
        }
        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * The function adds an email to an array of emails to be sent and clears the input field for the
   * email.
   */
  public addEmail(): void {
    this.emails_to_send.push(this.email_to_add);
    this.email_to_add = "";
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code and title using
   * the ModalComponent.
   * @param {string} code - A string representing the code that will be displayed in the modal window.
   * @param {string} title - The title parameter is a string that represents the title of the modal
   * that will be launched. It will be displayed at the top of the modal to provide context to the
   * user.
   */
  private async lauchModal(code: string, title: string): Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }
}
