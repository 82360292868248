import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-traceability',
  templateUrl: './traceability.component.html',
  styleUrls: ['./traceability.component.css']
})
export class TraceabilityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
