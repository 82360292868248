import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { QrService } from 'src/app/services/qr.service';
import { environment } from 'src/app/variables/enviroment';
@Component({
  selector: 'app-qrmaker',
  templateUrl: './qrmaker.component.html',
  styleUrls: ['./qrmaker.component.css']
})
export class QrmakerComponent implements OnInit {
  loader:number = 1;
  id_dep:string = '';
  response:any = [];
  data:any = [];
  url_to_load  = '';
  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');
  permmisions = JSON.parse(localStorage.getItem('permisos'));

  constructor(private qrService: QrService,private modalService:NgbModal) { }

  ngOnInit(): void {
    this.getDepartments();
  }


  /**
   * This function retrieves departments for a user using a QR service and displays them in a modal.
   */
  private getDepartments(): void {
    this.qrService.getDepForUser().subscribe(
      res=>{
        this.response = res;
        if(this.response.code != '0_005')this.lauchModal(this.response.code,this.response.message);
        else this.data = this.response.data;

        this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        console.log(err);
        this.loader = 2;
      }
    );
  }

 /**
  * This function sets the URL to load for a specific department ID in a TypeScript environment.
  * @param value - The parameter "value" is a variable that represents the ID of a department. It is
  * used to construct a URL that will be used to load a ZIP file and a QR code for the specified
  * department.
  */
  public setIdDepto(value): void {
    this.url_to_load =environment.uri+"get_zip_qr?api_key="+this.api_key+"&token="+this.token+"&module=39&id="+value;
  }

  /**
   * This is a private function in TypeScript that sets a URL to download an Excel file with department
   * data using an API key and token.
   * @param value - The value parameter is an identifier for a specific department in a system. It is
   * used to generate a URL that will download an Excel file containing information about that
   * department.
   */
  public descargarExcel(value) :void {
    this.url_to_load =environment.uri+"get_departments_excel_qr?api_key="+this.api_key+"&token="+this.token+"&module=39&id="+value;
  }

  /**
   * This is an asynchronous function that launches a modal with a given code and title using the
   * Angular Material modal service.
   * @param {string} code - The code parameter is a string that represents the code that will be
   * displayed in the modal.
   * @param {string} title - The title parameter is a string that represents the title of the modal
   * that will be launched.
   */
  private async lauchModal(code:string,title:string):Promise<void>{
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

}
