import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ɵConsole,
} from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { element } from "protractor";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { DepotService } from "src/app/services/depot.service";
import { OrdenVentaService } from "src/app/services/orden-venta.service";

@Component({
  selector: "app-odv",
  templateUrl: "./odv.component.html",
  styleUrls: ["./odv.component.scss"],
})
export class OdvComponent implements OnInit {
  loader_add_odv = 0;
  loader_search_depot = false;
  precio: number = 730438;
  loader: number = 1;
  response: any = [];
  responselientes: any = [];
  data: any = [];
  permmisions = JSON.parse(localStorage.getItem("permisos"));
  file_name = "";
  client_id = "";
  file: any;
  @ViewChild("btn_close_modal") divView: ElementRef;
  @ViewChild("btn_open_search_depot") btnOpenSearchDepot: ElementRef;
  @ViewChild("btn_close_modal_to_search_depot")
  btn_close_modal_search: ElementRef;
  @ViewChild("btn_open_modal") btn_open_modal: ElementRef;

  clients_result: any = [];
  client_to_search = "";
  listafiltro: any = [];
  listaux: any = [];
  listaodv: any = [];
  lista_odv_temporal: any = [];
  lista_odv_permanente: any = [];
  listclientes: any = [];
  valueSearchZona: string = "T";
  public anio_selected:number;

  zonas = [
    {
      zona: "T",
      nombre: "Todas las zonas",
    },
    {
      zona: "M",
      nombre: "Monterrey",
    },
    {
      zona: "R",
      nombre: "Ramos Arizpe",
    },
    {
      zona: "S",
      nombre: "San Luis",
    },
  ];
  valueSearchEstatus: string = "";
  valueSearchCliente: string = "";
  depot_selected = "";
  number_page = 0;
  pages = 0;

  social_reason_list = [];
  social_reason_id = "1";
  client_name = "";

  depot_search_results: any = [];
  public anios : any = [];
  show_temporal_odv = false;
  constructor(
    private odvService: OrdenVentaService,
    private modalService: NgbModal,
    private router: Router,
    private depotService: DepotService
  ) {}

  ngOnInit(): void {
    
    this.anio_selected = new Date().getFullYear();
    const filtroZona = localStorage.getItem("zona");
    if (filtroZona) {
      this.valueSearchZona = filtroZona;
    }
    this.loadList(this.anio_selected);
  }

  /**
   * The function loads a list of ODVs (On Demand Videos) based on a selected year and categorizes them
   * as permanent or temporary.
   * @param anio_select - It is a parameter of type any that represents the year selected by the user
   * to load a list of ODVs (Ordenes de Venta) from the server.
   */
  private loadList(anio_select:number): void {
    const SAVED_ANIO = this.anio_selected;
    this.lista_odv_temporal = [];
    this.lista_odv_permanente = [];
    this.odvService.getODVList(anio_select).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.data = this.response.data;
          this.clients_result = this.data.client_list;
          this.listclientes = this.data.customers_list_search;
          this.social_reason_list = this.data.business_name;
          this.listaodv = this.data.odv;
          this.anios = this.data.anios; 
          /* this.anio_selected = SAVED_ANIO; */
          console.log(this.anio_selected);
          console.log(this.data);
          this.data.odv.forEach((v) => {
            if (v.permanente == 1) this.lista_odv_permanente.push(v);
            else this.lista_odv_temporal.push(v);
          });

          this.listaodv = this.lista_odv_permanente;
          this.listaux = this.listaodv;
          this.FiltrarOdvs();
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );
  }
  trackByFn(index: number, item: any): number {
    return item.id; // Reemplaza 'id' con la propiedad única de tu objeto
  }

  /**
   * The function changes the list of odv to the permanent list and hides the temporary odv list.
   */
  public changeToPermanent(): void {
    this.loader = 1;
    this.listaodv = this.lista_odv_permanente;
    this.listaux = this.listaodv;
    this.show_temporal_odv = false;
    this.loader = 2;
  }

  /**
   * The function changes the current list of odv to a temporal list and sets a flag to show the
   * temporal odv.
   */
  public changeToTemporal() :void {
    this.loader = 1;
    this.listaodv = this.lista_odv_temporal;
    this.listaux = this.listaodv;
    this.show_temporal_odv = true;
    this.loader = 2;
  }

  /**
   * This function filters a list of objects based on selected search criteria.
   */
  private FiltrarOdvs() :void {
    this.listaodv = this.listaux;
    this.listafiltro = [];
    if (
      this.valueSearchZona == "T" &&
      this.valueSearchCliente == "" &&
      this.valueSearchEstatus == ""
    ) {
      this.listaodv = this.listaux;
    } else {
      if (this.valueSearchCliente === "") {
        if (this.valueSearchEstatus === "") {
          if (this.valueSearchZona === "T") {
            this.listaodv = this.listaux;
          } else {
            this.listaodv.forEach((element) => {
              if (element.identificador[3] === this.valueSearchZona) {
                this.listafiltro.push(element);
              }
            });
          }
        } else {
          if (this.valueSearchZona === "T") {
            this.listaodv.forEach((element) => {
              if (element.estatus == this.valueSearchEstatus) {
                this.listafiltro.push(element);
              }
            });
          } else {
            this.listaodv.forEach((element) => {
              if (
                element.estatus == this.valueSearchEstatus &&
                element.identificador[3] === this.valueSearchZona
              ) {
                this.listafiltro.push(element);
              }
            });
          }
        }
      } else {
        if (this.valueSearchEstatus === "") {
          if (this.valueSearchZona === "T") {
            this.listaodv.forEach((element) => {
              if (element.cliente === this.valueSearchCliente) {
                this.listafiltro.push(element);
              }
            });
          } else {
            this.listaodv.forEach((element) => {
              if (
                element.identificador[3] === this.valueSearchZona &&
                element.cliente === this.valueSearchCliente
              ) {
                this.listafiltro.push(element);
              }
            });
          }
        } else {
          if (this.valueSearchZona === "T") {
            this.listaodv.forEach((element) => {
              if (
                element.estatus == this.valueSearchEstatus &&
                element.cliente === this.valueSearchCliente
              ) {
                this.listafiltro.push(element);
              }
            });
          } else {
            this.listaodv.forEach((element) => {
              if (
                element.identificador[3] === this.valueSearchZona &&
                element.cliente === this.valueSearchCliente &&
                element.estatus == this.valueSearchEstatus
              ) {
                this.listafiltro.push(element);
              }
            });
          }
        }
      }
      this.listaodv = this.listafiltro;
    }
    localStorage.setItem("zona", this.valueSearchZona);
  }

  /**
   * This function searches for a client and displays the results in a modal.
   */
  public searchClient() :void {
    this.loader = 1;
    this.odvService.getClientSearch(this.client_to_search).subscribe(
      (res) => {
        this.clients_result = res;
        if (
          this.clients_result.code == "1_0001" ||
          this.clients_result.code == "1_0002" ||
          this.clients_result.code == "1_0003"
        )
          this.lauchModal(
            this.clients_result.code,
            this.clients_result.message,
            ""
          );
        else if (this.clients_result.code == "1_0004")
          this.lauchModal(
            this.clients_result.code,
            this.clients_result.message,
            ""
          );
        else this.clients_result = this.clients_result.data;
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This function sends a client's information to the server and navigates to a new page if
   * successful, otherwise it displays an error message.
   */
  public sendClient() :void {
    this.loader_add_odv = 1;
    let result: any = [];
    let form = new FormData();
    form.append("id_client", this.client_id);
    form.append("id_social_re", String(this.social_reason_id));

    this.odvService.saveOdv(form).subscribe(
      (res) => {
        result = res;
        console.log(res);
        if (result.code == "0_005" && result.data != "0") {
          this.divView.nativeElement.click();
          this.router.navigate(["/commercial/odv/edit", result.data]);
          this.loader_add_odv = 0;
        } else this.lauchModal(result.code, result.message, "");
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", err.message);
        console.log(err);
        this.loader_add_odv = 0;
      }
    );
  }

  /**
   * This function opens a modal to search for a depot registration.
   */
  public showModalToSearchDepotReg() :void {
    this.divView.nativeElement.click();
    this.btnOpenSearchDepot.nativeElement.click();
  }

  /**
   * This is a private function in TypeScript that sets the selected depot.
   * @param depot - The "depot" parameter is a variable that represents the selected depot. The
   * function "setDepotReg" sets the value of the "depot_selected" variable to the value of the "depot"
   * parameter.
   */
  public setDepotReg(depot) :void {
    this.depot_selected = depot;
  }

  //Metodo que guarda la ODV en el servidor
  /**
   * This function sends a client ID, social reason ID, and a file to the server to save an ODV (order
   * delivery voucher) and navigates to the edit page for the saved ODV.
   */
  public sendClientAndDocument() :void {
    this.loader_add_odv = 1;
    let result: any = [];
    if (this.file.files.length > 0) {
      let form = new FormData();
      form.append("file", this.file.files[0]);
      form.append("id_client", this.client_id);
      form.append("id_social_re", String(this.social_reason_id));

      this.odvService.saveOdvWithFile(form).subscribe(
        (res) => {
          result = res;

          if (result.code == "0_005" && result.data != "0") {
            this.divView.nativeElement.click();
            this.router.navigate(["/commercial/odv/edit", result.data]);
            this.loader_add_odv = 0;
          } else this.lauchModal(result.code, result.message, "");
        },
        (err) => {
          this.lauchModal("0000x00", "Error de consulta", err.message);
          console.log(err);
          this.loader_add_odv = 0;
        }
      );
    }
  }

  //Metodo para guargar la ODV con Almacen
  /**
   * This function sends client and depot information to the server to save an ODV and navigate to its
   * edit page.
   */
  public sendClientAndDepot() :void {
    this.loader_add_odv = 1;
    let result: any = [];
    let form = new FormData();
    form.append("id_client", this.client_id);
    form.append("id_social_re", String(this.social_reason_id));
    form.append("id_depot", String(this.depot_selected));

    this.odvService.saveOdvWithDepot(form).subscribe(
      (res) => {
        result = res;

        if (result.code == "0_005" && result.data != "0") {
          this.btn_close_modal_search.nativeElement.click();
          this.router.navigate(["/commercial/odv/edit", result.data]);
          this.loader_add_odv = 0;
        } else this.lauchModal(result.code, result.message, "");
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", err.message);
        console.log(err);
        this.loader_add_odv = 0;
      }
    );
  }

  /**
   * This function sets the client ID and name based on a given ID and a list of clients.
   * @param id - The parameter "id" is a value that represents the ID of a client. It is used in the
   * "setClient" method to set the client ID and retrieve the client name from a list of clients.
   */
  public setClient(id) :void {
    this.client_id = id;
    this.clients_result.forEach((v) => {
      if (v.id == this.client_id) {
        this.client_name = v.abreviatura;
      }
    });
  }

  /**
   * This function loads a file and sets its name as a property.
   * @param value - The parameter "value" is an event object that is passed as an argument to the
   * function. It contains information about the event that triggered the function, such as the target
   * element that was interacted with. In this case, it is likely an event object related to a file
   * input element, as the
   */
  public loadFile(value) :void {
    this.file = value.target;
    this.file_name = this.file.files[0].name;
  }

  /**
   * This is a private function in TypeScript that sets the social reason ID.
   * @param id - The parameter "id" is a variable that represents the social reason ID that will be set
   * in the object.
   */
  public setSocialReason(id) :void {
    this.social_reason_id = id;
  }

  /**
   * This function searches for depots to add ODV and displays the results.
   * @param value - The parameter "value" is a variable that represents the search query to be used in
   * the searchDepotReg method. It is passed as an argument to the function searchDepotRegToAddODV.
   */
  public searchDepotRegToAddODV(value) :void {
    this.depot_search_results = [];
    var result: any = [];
    this.loader_search_depot = true;
    this.depotService.searchDepotReg(value).subscribe(
      (res) => {
        result = res;
        this.loader_search_depot = false;
        this.depot_search_results = result.data;
        console.log(this.depot_search_results);
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", err.message);
        console.log(err);
        this.loader_search_depot = false;
      }
    );
  }

  /**
   * This function resets some variables and clicks on two different buttons to open and close modals.
   */
  public returnModals() :void {
    this.depot_selected = "";
    this.btn_close_modal_search.nativeElement.click();
    this.btn_open_modal.nativeElement.click();
  }

  /**
   * The function changes the current page number to the specified page.
   * @param page - The "page" parameter is a variable that represents the page number that the user
   * wants to navigate to. The "changePageResults" function takes this parameter and sets the
   * "number_page" variable to the value of "page". This function is likely used in a pagination system
   * to allow the user to
   */
  public changePageResults(page):void {
    this.number_page = page;
  }

  /**
   * This is an asynchronous function that launches a modal with a given code, title, and message using
   * a modal service.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - The title of the modal that will be displayed.
   * @param message - The message parameter is likely a string that contains the content of the message
   * to be displayed in the modal. It could be an error message, a confirmation message, or any other
   * type of message that the user needs to see.
   */
  private async lauchModal(code: string, title: string, message) :Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
  
}
