import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { RequisitionsService } from "src/app/services/requisitions.service";
import { SaleOrderService } from "src/app/services/sale-order.service";

@Component({
  selector: "app-requisitions",
  templateUrl: "./requisitions.component.html",
  styleUrls: ["./requisitions.component.css"],
})
export class RequisitionsComponent implements OnInit {
  permmisions = JSON.parse(localStorage.getItem("permisos"));
  loader = 2;
  list: any = [];
  response: any = [];

  value_to_search = "";

  suppliers_list = [];
  supplier_id = "";

  check_supplier: boolean = false;

  constructor(
    private routerService: Router,
    private modalService: NgbModal,
    private requisitionService: RequisitionsService
  ) {}

  ngOnInit(): void {
    this.getList();
  }

  /**
   * The function retrieves a list of data from a service and handles any errors that may occur.
   */
  private getList() :void {
    this.loader = 1;
    this.requisitionService.getList().subscribe(
      (res) => {
        this.loader = 2;
        let resp: any = res;
        if (resp.code != "0_005") this.lauchModal(resp.code, resp.message, "");
        else {
          this.list = resp.data;
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This function toggles the search for a supplier on and off.
   */
  public enableSearchSupplier() :void {
    if (this.check_supplier) this.check_supplier = false;
    else {
      this.check_supplier = true;
      this.supplier_id = "";
    }
  }

  /**
   * This function searches for a supplier using a value to search and displays the results or an error
   * message.
   */
  public searchSupplier() :void {
    let data: any = [];
    this.requisitionService.getSupplier(this.value_to_search).subscribe(
      (res) => {
        data = res;
        if (data.code == "0_005") {
          this.suppliers_list = data.data;
        } else this.lauchModal(data.code, data.message, "");
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", err.message);
        console.log(err);
      }
    );
  }

  /**
   * The function sets the supplier ID for a given object.
   * @param id - The parameter "id" is a value that represents the ID of a supplier. This function sets
   * the "supplier_id" property of an object to the value of "id".
   */
  public setSupplier(id) :void {
    this.supplier_id = id;
  }

  /**
   * This is a private function that sets the ID of a client and saves a requisition using a form data
   * object.
   * @param cli - The parameter "cli" is likely an identifier or code for a client or customer. It is
   * being used to create a new FormData object and append the "id_razon" field with the value of
   * "cli".
   */
  public setIDClient(cli) :void {
    this.loader = 1;
    let form = new FormData();
    form.append("id_razon", cli);
    form.append("supplier_id", this.supplier_id);

    this.requisitionService.saveRequisition(form).subscribe(
      (res) => {
        this.loader = 2;
        let resp: any = res;
        if (resp.code != "0_005") this.lauchModal(resp.code, resp.message, "");
        else {
          if (resp.data == 0)
            this.lauchModal(
              resp.code,
              "Ocurrió un error al registrar la requisición",
              ""
            );
          this.routerService.navigateByUrl(
            "/administration/requisitions/edit/" + resp.data
          );
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This function launches a modal with a given code, title, and message using the Angular Material
   * modal service.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - The title of the modal that will be displayed.
   * @param message - The message parameter is likely a string that contains the content to be
   * displayed in the body of the modal dialog box. It could be a message to the user or some other
   * information that needs to be communicated.
   */
  private async lauchModal(code: string, title: string, message) :Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
}
