import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
//import { ContentType } from "@angular/http/src/enums";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
//import { Console } from "console";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { ICommentODV } from "src/app/services/models/ICommentODV";
import { IPartBill } from "src/app/services/models/IPartBill";
import { IPartsStatusODV } from "src/app/services/models/IPartsStatusODV";
import { OrdenVentaService } from "src/app/services/orden-venta.service";
import { environment } from "src/app/variables/enviroment";

@Component({
  selector: "app-odv-det",
  templateUrl: "./odv-det.component.html",
  styleUrls: ["./odv-det.component.css"],
})
export class OdvDetComponent implements OnInit {
  @ViewChild("btn_close_modal") divView: ElementRef;
  uri: string = environment.uri;
  token = localStorage.getItem("token");
  api_key = localStorage.getItem("key");

  show_general_options_for_parts = true;

  id_odv: string = "";
  id_event: string = "";
  response: any = [];
  loader: number = 1;
  data: any = [];
  permmisions = JSON.parse(localStorage.getItem("permisos"));
  subtotal = 0;
  iva = 0;
  total = 0;
  parts: any = [];
  list_to_edit_status: any = [];

  url_to_load = "";

  comment = "";
  file: any;
  file_name = "";

  aux: number = 0;
  parts_no_rs = 0;
  edit = 0;

  cancel_active = 0;
  close_active = 0;
  bill_active = 0;
  age_active = 0;
  estatus_info = "";

  bill = "";
  fecha_factura = "";

  data_structure_to_bill: any = [];
  data_structure_to_agen: any = [];
  public agent_date:string = '';

  data_parts_to_bill: any = [];
  data_parts_to_agen: any = [];

  parts_to_compare: any = [];

  days_to_agen: any = "S/I";
  odvs: any = [];
  cont: number = 0;
  odv_ant: number = 0;
  odv_prox: number = 0;
  id_cotizacion = 0;

  marcar_facturada = 0;
  caracteres = 0;

  depot_data: any = 0;

  departamento = "";
  depto_odv_id:number;
  depto_estado:string;
  equipos_diff_depto: any = [];

  //REEMPLAZAR EQUIPO
  duplicate_instruments = [];

  instrument_to_replace = {
    instrument: "",
    tag: "",
    brand: "",
    model: "",
    serie: "",
    depto: "",
    part: "",
  };

  id_replace_old: string = "";
  part_replace_old: string = "";
  part_replace_id: string = "";
  results_for_replace_instruments: any = [];
  id_instrument_replace:string = "";

  //BORRAR PARTIDA
  part_delete_instrument: string = "";
  part_delete_id: string = "";
  part_delete_no: string = "";
  delete_confirm: boolean = false;

  change_status_prev = {
    new_status : -1,
    title : ''
  }
  @ViewChild("cerrarModal") divClose: ElementRef;
  constructor(
    private activedRoute: ActivatedRoute,
    private odvService: OrdenVentaService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.id_odv = this.activedRoute.snapshot.paramMap.get("id_odv");
    this.habilitarDescarga();
    this.getDetailODV();
  }

  /**
   * The function enables download if the user has permission level 60.
   */
  private habilitarDescarga(): void {
    if (this.permmisions.includes(60)) {
      this.aux = 1;
    }
  }

  /**
   * This function retrieves details of an ODV (Order Delivery Vehicle) and calculates its subtotal,
   * total, and tax.
   */
  private getDetailODV(): void {
    this.data = [];
    this.iva = 0.0;
    this.total = 0.0;
    this.subtotal = 0.0;
    this.loader = 1;
    this.odvService.getODVDetail(this.id_odv).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.data = this.response.data;
          this.id_cotizacion = this.data.id_cotizacion;
          this.depot_data = this.data.depot_data;
          this.parts = this.response.data.parts;
          this.id_event = this.data.event;
        
          this.duplicate_instruments = this.getDuplicateInstrument(this.parts);
          this.parts_no_rs = this.response.data.parts_no_rs;
          if (this.data.departamento != null) {
            this.departamento = this.data.departamento.depto;
            this.depto_odv_id = this.data.departamento.id;
            this.depto_estado = this.data.departamento.estado;
          }
          
          let facturadas = 0;
          for (var i = 0; i < this.parts.length; i++) {
            if (this.parts[i].abi_cer_fact_can_age != 3)
              this.subtotal += this.parts[i].precio * this.parts[i].cantidad;
            if (this.parts[i].facturada == 1 && 
                this.parts[i].abi_cer_fact_can_age != 3) facturadas++;
          }


          let invoiced_part = [];
          this.parts.forEach(part => {
            if(part.abi_cer_fact_can_age != 3) {
              invoiced_part.push(part);
            }
          });

          this.iva = this.subtotal * 0.16;
          this.total = this.subtotal + this.iva;
          if (this.data.estatus == 1) this.estatus_info = "Abierto";
          else if (this.data.estatus == 2) {
            this.estatus_info = "Cerrado";
            if (invoiced_part.length == facturadas) this.marcar_facturada = 1;
          } else if (this.data.estatus == 3)
            this.estatus_info = "Facturación Parcial";
          else if (this.data.estatus == 4)
            this.estatus_info = "Cerrado Parcial";
          else if (this.data.estatus == 5) this.estatus_info = "Facturado";
          else if (this.data.estatus == 6) this.estatus_info = "Cancelado";
          else if (this.data.estatus == 7)
            this.estatus_info = "Parcial Agendado";
          else if (this.data.estatus == 8) this.estatus_info = "Agendado";

          if (this.data.estatus == 1) {
            var agen_date = new Date(this.data.fecha_agenda);

            var today = new Date();

            let resta = today.getTime() - agen_date.getTime();
            resta = Math.round(resta / (1000 * 60 * 60 * 24));

            this.days_to_agen = resta;
          }

          this.odv_ant = parseInt(this.id_odv) - 1;
          this.odv_prox = parseInt(this.id_odv) + 1;
          let aux_odvs = [this.odv_ant, this.odv_prox];
          this.cont = 0;
          this.verOdvs(aux_odvs);
          this.getDiffDepartmentInstrument();
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * The function adds a specified number of days to a given date.
   * @param fecha - It is a Date object representing a specific date.
   * @param dias - The parameter "dias" is a number representing the amount of days to add to the given
   * date.
   * @returns The function is declared as returning `void`, which means it does not return any value.
   * However, the function is actually returning the `fecha` object after modifying it, which is not
   * consistent with the declared return type.
   */
  public sumarDias(fecha, dias): void {
    fecha.setDate(fecha.getDate() + dias);
    return fecha;
  }

  /**
   * This function saves a file by sending it to a server and handling the response.
   */
  public saveFile(): void {
    let result: any = [];
    this.loader = 1;

    if (this.file.files.length > 0) {
      let form = new FormData();
      form.append("file", this.file.files[0]);
      form.append("id_odv", this.id_odv);

      this.odvService.addDocumentoToODV(form).subscribe(
        (res) => {
          result = res;
          if (
            result.code == "1_0001" ||
            result.code == "1_0002" ||
            result.code == "1_0003"
          )
            this.lauchModal(result.code, result.message);
          else if (result.code == "1_0004" || result.code == "0_007")
            this.lauchModal(result.code, result.message);
          else {
            this.file_name = "";
            this.getDetailODV();
          }
          this.loader = 2;
        },
        (err) => {
          this.lauchModal("0000x00", "Error de consulta");
          console.log(err);
          this.loader = 2;
        }
      );
    }
  }

  /**
   * This function saves a comment for a specific ODV and handles different response codes accordingly.
   */
  public saveCommentODV(): void {
    this.loader = 1;
    let result: any = [];
    let form: ICommentODV = {
      id_odv: this.id_odv,
      comentario: this.comment,
    };
    this.odvService.addComment(form).subscribe(
      (res) => {
        result = res;
        if (
          result.code == "1_0001" ||
          result.code == "1_0002" ||
          result.code == "1_0003"
        )
          this.lauchModal(result.code, result.message);
        else if (result.code == "1_0004" || result.code == "0_007")
          this.lauchModal(result.code, result.message);
        else {
          if (result.data == 1) {
            this.comment = "";
            this.getDetailODV();
          } else
            this.lauchModal(
              "0000x00",
              "Algo salió mal, no se ha podido realizar el comentario. Intenta nuevamente"
            );
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This function loads a file and sets its name as a property.
   * @param value - The parameter "value" is an event object that is passed as an argument to the
   * function. It contains information about the event that triggered the function, such as the target
   * element that was interacted with. In this case, it is likely an event object related to a file
   * input element, as the
   */
  public loadFile(value): void {
    this.file = value.target;
    this.file_name = this.file.files[0].name;
  }

  /**
   * This is a private function in TypeScript that changes the value of a boolean variable called
   * "edit".
   * @param value - The value parameter is a boolean value that determines whether the component should
   * be in edit mode or not. If value is true, the component will be in edit mode, and if it is false,
   * the component will not be in edit mode.
   */
  public changeToEdit(value): void {
    this.edit = value;
    this.list_to_edit_status = [];
  }

  /**
   * The function manages a list of items by updating their status and determining which actions are
   * available based on the selected items.
   * @param id_part - an identifier for a particular part or item in a list
   * @param status - The current status of a particular item in a list. It can be 0, 1, or 4.
   * @param facturada - It is a parameter that indicates whether an item has been invoiced or not. If
   * it is equal to 1, it means the item has been invoiced, and if it is equal to 0, it means the item
   * has not been invoiced yet.
   */
  public manageList(id_part, status, facturada): void {
    
    if (!this.list_to_edit_status.includes(id_part + "," + status)) {
      this.list_to_edit_status.push(id_part + "," + status);
    } else {
      var index = this.list_to_edit_status.indexOf(id_part + "," + status);
      this.list_to_edit_status.splice(index, 1);
    }

    var close_aux = [];
    var bill_aux = [];
    var cancel_aux = [];
    var age_aux = [];
    for (var i = 0; i < this.list_to_edit_status.length; i++) {
      var array_aux = this.list_to_edit_status[i].split(",");
      if (array_aux[1] == 0) {
        age_aux.push(this.list_to_edit_status[i]);
        cancel_aux.push(this.list_to_edit_status[i]);
        if (facturada != 1) bill_aux.push(this.list_to_edit_status[i]);
      } else if (array_aux[1] == 1) {
        if (facturada != 1) bill_aux.push(this.list_to_edit_status[i]);
      } else if (array_aux[1] == 4) {
        cancel_aux.push(this.list_to_edit_status[i]);
        close_aux.push(this.list_to_edit_status[i]);
        if (facturada != 1) bill_aux.push(this.list_to_edit_status[i]);
      }
    }

    if (
      this.list_to_edit_status.length == close_aux.length &&
      this.list_to_edit_status.length > 0
    )
      this.close_active = 1;
    else this.close_active = 0;

    if (
      this.list_to_edit_status.length == cancel_aux.length &&
      this.list_to_edit_status.length > 0
    )
      this.cancel_active = 1;
    else this.cancel_active = 0;

    if (
      this.list_to_edit_status.length == bill_aux.length &&
      this.list_to_edit_status.length > 0
    )
      this.bill_active = 1;
    else this.bill_active = 0;

    if (
      this.list_to_edit_status.length == age_aux.length &&
      this.list_to_edit_status.length > 0
    )
      this.age_active = 1;
    else this.age_active = 0;

  }

  /**
   * This is a private function in TypeScript that sends a request to change the status of certain
   * parts in an ODV and handles the response accordingly.
   * @param status - The parameter "status" is a string that represents the new status that will be
   * assigned to a list of parts in an ODV (Order Delivery Vehicle) object.
   */
  public sendChangeStatus(status): void {
    this.loader = 1;

    var response_s: any = [];
    var aux_ids = [];
    for (var i = 0; i < this.list_to_edit_status.length; i++) {
      var array_aux = this.list_to_edit_status[i].split(",");
      aux_ids.push(array_aux[0]);
    }
    var parts: IPartsStatusODV = {
      id_odv: this.id_odv,
      for_parts: aux_ids,
      status: status,
      bills:0
    };

    this.odvService.sendToChangeStatusParts(parts).subscribe(
      (res) => {
        response_s = res;
        if (this.response.code != "0_005")
          this.lauchModal(this.response.code, this.response.message);
        else {
          if (response_s.data == 0)
            this.lauchModal(this.response.code, this.response.message);
          else {
            this.getDetailODV();
            this.list_to_edit_status = [];
           this.divClose.nativeElement.click();
          }
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This function sends a request to change the status of parts in an ODV and launches a modal with a
   * response message.
   */
  private sendToBill(): void {
    this.loader = 1;
    var response_s: any = [];
    var aux_ids = [];
    for (var i = 0; i < this.list_to_edit_status.length; i++) {
      var array_aux = this.list_to_edit_status[i].split(",");
      aux_ids.push(array_aux[0]);
    }
    var parts: IPartsStatusODV = {
      id_odv: this.id_odv,
      for_parts: aux_ids,
      status: "2",
      bills: this.data_parts_to_bill,
    };

    this.odvService.sendToChangeStatusParts(parts).subscribe(
      (res) => {
        response_s = res;
        if (this.response.code != "0_005")
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.data_parts_to_bill = [];
          if (response_s.data == 0)
            this.lauchModal(this.response.code, this.response.message);
          else this.getDetailODV();
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This function marks an item as billed and handles the response accordingly.
   */
  public markAsBilled(): void {
    this.loader = 1;
    this.list_to_edit_status = [];
    let response_b: any = [];
    this.odvService.markAsBilled(this.id_odv).subscribe(
      (res) => {
        response_b = res;
        if (this.response.code != "0_005")
          this.lauchModal(this.response.code, this.response.message);
        else {
          response_b.data == 1
            ? this.getDetailODV()
            : response_b == 0
            ? this.lauchModal(this.response.code, this.response.message)
            : this.lauchModal(
                this.response.code,
                this.response.message,
                response_b.data
              );

              this.parts.forEach(part => {
                if( part.facturada == 1 && 
                    part.abi_cer_fact_can_age == 1 ) {
                      this.list_to_edit_status.push(`${part.id_det},${part.abi_cer_fact_can_age}`);
                }
              });
              if(this.list_to_edit_status.length > 0) {
                this.sendChangeStatus(2);
              }
        }
       

      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This function sends a request to change the status of certain parts in an ODV and handles the
   * response accordingly.
   */
  public sendToAge(): void {
    this.loader = 1;
    var response_s: any = [];
    var aux_ids = [];
    for (var i = 0; i < this.list_to_edit_status.length; i++) {
      var array_aux = this.list_to_edit_status[i].split(",");
      aux_ids.push(array_aux[0]);
    }
    var parts: IPartsStatusODV = {
      id_odv: this.id_odv,
      for_parts: aux_ids,
      status: "4",
      bills: this.data_parts_to_agen,
    };

    this.odvService.sendToChangeStatusParts(parts).subscribe(
      (res) => {
        response_s = res;
        if (this.response.code != "0_005")
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.data_parts_to_agen = [];
          if (response_s.data == 0)
            this.lauchModal(this.response.code, this.response.message);
          else this.getDetailODV();
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This function builds a data structure based on the input parameter and assigns it to a
   * corresponding variable.
   * @param bill_ag - It is a parameter that determines whether the data structure being built is for
   * billing (bill_ag = 0) or for agency (bill_ag = 1).
   */
  public buildDataStructure(bill_ag): void {
    var aux_data = [];
    for (var i = 0; i < this.list_to_edit_status.length; i++) {
      var array_aux = this.list_to_edit_status[i].split(",");
      for (var j = 0; j < this.parts.length; j++) {
        if (this.parts[j].id_det == array_aux[0]) aux_data.push(this.parts[j]);
      }
    }
    if (bill_ag == 0) this.data_structure_to_bill = aux_data;
    else if (bill_ag == 1) this.data_structure_to_agen = aux_data;
  }

  /**
   * The function builds a data structure to send with information about parts and a bill.
   * @param va - The parameter "va" is an object that represents the event that triggered the function.
   * It is likely an instance of the Event class or a subclass of it. The function is using the
   * "target" property of the event object to get the value of the input field that triggered the
   * event.
   */
  public buidlDataStructureToSend(va): void {
     this.agent_date = va.target.value;

    for (var i = 0; i < this.data_structure_to_agen.length; i++) {
      var parts: IPartBill = {
        id_part_odv: this.data_structure_to_agen[i].id_det,
        bill: this.agent_date,
      };
      this.data_parts_to_agen.push(parts);
    }
    console.log(this.data_parts_to_agen);
  }

  /**
   * This function calculates the number of characters in a bill and removes the last character if the
   * length is greater than 7.
   */
  public calculateCharacters(): void {
    let value = this.bill.split("");
    if (this.bill.length > 7) {
      value.splice(this.bill.length - 1, 1);
      this.bill = value.join("");
    }

    this.caracteres = this.bill.length;
  }

  /**
   * This function creates a list of parts to be billed based on a list of data structures and sends it
   * for billing.
   */
  public dataStructureBill(): void {
    for (var i = 0; i < this.data_structure_to_bill.length; i++) {
      var id = this.data_structure_to_bill[i].id_det;
      var parts: IPartBill = {
        id_part_odv: id,
        bill: this.bill,
        fecha: this.fecha_factura,
      };
      this.data_parts_to_bill.push(parts);
    }

    this.sendToBill();
  }

  /**
   * This is a private function in TypeScript that compares XLSX files and sends data to compare parts.
   * @param id - The parameter "id" is a variable that represents the identifier of the data to be sent
   * for comparison.
   */
  public compareXLSX(id): void {
    var response_s: any = [];
    this.loader = 1;
    this.show_general_options_for_parts = false;
    this.odvService.sendDataToCompareParts(id).subscribe(
      (res) => {
        response_s = res;
        if (this.response.code != "0_005")
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.divView.nativeElement.click();
          this.parts_to_compare = response_s.data;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * The function cancels a comparison and shows general options for parts while getting details for
   * ODV.
   */
  private cancelCompare(): void {
    this.show_general_options_for_parts = true;
    this.getDetailODV();
  }

  /**
   * This function sets a URL to download a document using specific parameters.
   * @param {string} idDoc - A string representing the ID of a document.
   * @param {string} archivo - The "archivo" parameter is a string that represents the name of the file
   * to be downloaded.
   */
  public descargarDocs(idDoc: string, archivo: string): void {
    this.url_to_load =
      this.uri +
      "get_doc_odv?api_key=" +
      this.api_key +
      "&token=" +
      this.token +
      "&module=60&id_archivo=" +
      idDoc +
      "&id_odv=" +
      this.id_odv +
      "&archivo=" +
      archivo;
  }

  /*descargarPdf(){
    this.url_to_load = this.uri+"get_pdf_odv?api_key="+this.api_key+"&token="+this.token+"&module=66&id_odv="+this.id_odv;
  }*/

  /**
   * The function downloads a PDF file using a service and displays a modal with an error message if
   * necessary.
   */
  public descargarPdf(): void {
    this.odvService.downloadPdf(this.id_odv).subscribe(
      (res) => {
        let data: any = res;
        this.loader = 2;
        if (
          data.code == "1_0001" ||
          data.code == "1_0002" ||
          data.code == "1_0003" ||
          data.code == "1_0004"
        )
        this.lauchModal(data.code, data.message);
        else {
          this.downloadFile(data.data);
        }
      },
      (err) => {
        console.log(err);
        this.lauchModal("0000x00", "Error de consulta");
        this.loader = 2;
      }
    );
  }

  /**
   * The function downloads a file from a server and opens it in a new window.
   * @param file_name - The parameter "file_name" is a string variable that represents the name of the
   * file that needs to be downloaded.
   */
  private downloadFile(file_name): void {
    var url = environment.uri_server + "storage/public/odv_files/" + file_name;
    window.open(url, "_blank");
  }

  /**
   * The function sets a URL to download an Excel file for a specific department using an API key and
   * token.
   * @param value - The value parameter is an identifier for a specific department in a system. It is
   * used to generate a URL that can be used to download an Excel file containing information about
   * that department.
   */
  public descargarExcel(value): void {
    this.url_to_load =
      this.uri +
      "get_departments_excel_qr?api_key=" +
      this.api_key +
      "&token=" +
      this.token +
      "&module=39&id=" +
      value;
  }

  /**
   * The function selects all elements with the name "switch_" and clicks on them.
   */
  public selectAllParts(): void {
    let el = document.getElementsByName("switch_");
    for (var i = 0; i < el.length; i++) {
      el[i].click();
    }
  }

  /**
   * The function "verOdvs" is a recursive function that retrieves data from a service and performs
   * certain actions based on the response.
   * @param aux_odvs - It is an array parameter that is being passed to the function verOdvs(). The
   * array contains two elements, the first element represents the starting point of the search and the
   * second element represents the ending point of the search. The function uses these values to search
   * for odvs (orders) and performs some
   */
  private verOdvs(aux_odvs): void {
    this.cont++;
    let response;
    this.odvService.getOdvs(aux_odvs).subscribe(
      (res) => {
        response = res;
        if (this.response.code != "0_005")
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.odvs = response.data;
          if (this.odvs.length == 1) {
            if (this.odvs[0].id > this.id_odv) {
              aux_odvs[0] = aux_odvs[0] - 1;
              this.verOdvs(aux_odvs);
            } else {
              if (this.cont < 6) {
                aux_odvs[1] = aux_odvs[1] + 1;
                this.verOdvs(aux_odvs);
              }
            }
          } else if (this.odvs.length < 1) {
            aux_odvs[0] = aux_odvs[0] - 1;
            this.verOdvs(aux_odvs);
          }
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * The function downloads a zip file of fast data using an API call and launches a modal with an
   * error message if the response code is not "0_005".
   */
  public fastDownload(): void {
    let response;
    this.odvService.downloadFastDataZip(this.id_odv).subscribe(
      (res) => {
        response = res;
        if (this.response.code != "0_005")
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.dowloadZipFile(response.data);
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * The function downloads a zip file from a specific URL and opens it in a new window.
   * @param name - The name of the zip file that needs to be downloaded.
   */
  private dowloadZipFile(name): void {
    var url = environment.uri_server + "storage/public/odvs_zip/" + name;
    window.open(url, "_blank");
  }

  /**
   * The function reloads a window and navigates to a specific URL while cancelling a comparison and
   * retrieving details for a specific ID.
   * @param id - The parameter "id" is a variable that represents the ID of a commercial order delivery
   * (ODV). It is used in the function "reload_window" to reload the window with the details of the ODV
   * with the given ID.
   */
  public reload_window(id): void {
    this.cancelCompare();
    this.id_odv = id;
    let url = "/commercial/odv/det/" + id;
    this.router.navigate([url]);
    this.getDetailODV();
  }

  /**
   * This function sets the URL to download a consecutive ODV file in a TypeScript environment.
   */
  public consecutiveDownload(): void {
    this.url_to_load =
      environment.uri +
      "get_xlsx_consecutive_odv?api_key=" +
      this.api_key +
      "&token=" +
      this.token +
      "&module=129&id_odv=" +
      this.id_odv;
  }

  /**
   * This function populates an array with equipment that belongs to a different department than the
   * one specified.
   */
  private getDiffDepartmentInstrument(): void {
    this.equipos_diff_depto = [];
    if (this.departamento != "") {
      this.parts.forEach((parts) => {
        if (parts.id_depto != this.depto_odv_id) {
          this.equipos_diff_depto.push(parts);
        }
      });
    }
  }

 /**
  * The function returns a list of duplicate instruments based on a given list of calibrated instruments.
  * @param partidas - an array of objects representing calibrated instruments, where each object has a "tag"
  * property representing the name of the duplicate instrument to get.
  * @returns an array of objects that have a duplicate "tag" property.
  */
  public getDuplicateInstrument(partidas) {
    const contador = {};
    const equipos_duplicados = [];
    let equipo;
    partidas.forEach((partida) => {
      equipo = partida.tag;
      contador[equipo] = (contador[equipo] || 0) + 1;
    });
    Object.keys(contador).forEach((equipo) => {
      if (contador[equipo] > 1) {
        equipos_duplicados.push(equipo);
      }
    });
    const resultados = partidas.filter((part) =>
      equipos_duplicados.includes(part.tag)
    );

    return resultados;
  }

  //REEMPLAZAR PARTIDAS

 /**
  * The function searches for an instrument to replace based on user input and displays the results.
  * @param event - The event parameter is an object that represents the event that triggered the
  * function call. It could be a mouse click, a key press, or any other type of event.
  */
  public searchInstrument(event): void {
    let value = event.target.value.trim();
    if (value != "") {
      this.loader = 1;
      this.odvService
        .searchInstrumentToReplace(value, this.response.data.client_id)
        .subscribe(
          (res) => {
            this.response = res;
            if (
              this.response.code == "1_0001" ||
              this.response.code == "1_0002" ||
              this.response.code == "1_0003"
            )
              this.lauchModal(this.response.code, this.response.message, "");
            else if (this.response.code == "1_0004")
              this.lauchModal(this.response.code, this.response.message, "");
            else {
              this.results_for_replace_instruments = this.response.data;
            }
            this.loader = 2;
          },
          (err) => {
            this.lauchModal("0000x00", "Error de consulta", "");
            this.loader = 2;
          }
        );
    }
  }

  /**
   * This function sets the instrument to be replaced with the details of a given partida object.
   * @param partida - It seems that "partida" is an object that contains information about a particular
   * instrument or equipment. The properties of this object include "id_equipo", "partida", "id_det",
   * "descripcion", "tag", "marca", "modelo", "serie", and "depto". The
   */
  public setInstrumentToReplace(partida): void {
    this.id_replace_old = partida.id_equipo;
    this.part_replace_old = partida.partida;
    this.part_replace_id = partida.id_det;
    this.instrument_to_replace.instrument = partida.descripcion;
    this.instrument_to_replace.tag = partida.tag;
    this.instrument_to_replace.brand = partida.marca;
    this.instrument_to_replace.model = partida.modelo;
    this.instrument_to_replace.serie = partida.serie;
    this.instrument_to_replace.depto = partida.depto;
  }

 /**
  * This function replaces an instrument and updates the details of an ODV.
  */
  public replaceInstrument(): void {
    let data = new FormData();
    data.append("id_instrument_new", this.id_instrument_replace);
    data.append("id_instrument_old", this.id_replace_old);
    data.append("part_replace", this.part_replace_id);
    data.append("odv_id", this.id_odv);
    data.append("event_id", this.id_event);

    this.loader = 1;
    this.odvService.replaceInstrument(data).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.getDetailODV();
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  //SET PARTIDA A ELIMINAR
  /**
   * The function sets the details of a specific game to be deleted and confirms the deletion.
   * @param partida - It is an object that represents a match. It has the following properties:
   */
  public setPartidatoDelete(partida): void {
    this.part_delete_instrument = partida.id_equipo;
    this.part_delete_id = partida.id_det;
    this.part_delete_no = partida.partida;
    this.delete_confirm = true;
  }

 /**
  * This function deletes a duplicate part from an instrument in an ODV.
  */
  public deleteDuplicatePart(): void {
    let data = new FormData();
    data.append("id_instrument", this.part_delete_instrument);
    data.append("part_replace", this.part_delete_id);
    data.append("odv_id", this.id_odv);
    data.append("event_id", this.id_event);

    this.loader = 1;
    this.odvService.deteleDuplicatePart(data).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.getDetailODV();
        }
        this.loader = 2;
        this.delete_confirm = false;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  setInfoChangeEstatus(new_status, title) {
    this.change_status_prev.new_status = new_status;
    this.change_status_prev.title = title;
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code, title, and
   * message using the ModalComponent.
   * @param {string} code - a string representing the code to be displayed in the modal
   * @param {string} title - The title of the modal window that will be displayed.
   * @param {string} [message] - The `message` parameter is an optional string parameter that can be
   * passed to the `lauchModal` function. It is used to set the message that will be displayed in the
   * modal dialog. If no message is provided, an empty string will be used as the default value.
   */
  private async lauchModal(
    code: string,
    title: string,
    message: string = ""
  ): Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
}
