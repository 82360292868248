import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { CustomerService } from "src/app/services/customer.service";
import { ICustomer } from "src/app/services/models/ICustomer";

@Component({
  selector: "app-edit-customer",
  templateUrl: "./edit-customer.component.html",
  styleUrls: ["./edit-customer.component.css"],
})
export class EditCustomerComponent implements OnInit {
  permmisions = JSON.parse(localStorage.getItem("permisos"));
  abreviatura: string = "";
  name: string = "";
  rfc: string = "";
  moneda: string = "";
  pago: string = "";
  notas: string = "";
  facturas: string = "";
  web: string = "";
  direccionfiscal = "";

  result: any;

  id_customer: string = "";
  response: any = [];
  loader: number = 1;
  data: any = [];

  constructor(
    private router: Router,
    private activedRoute: ActivatedRoute,
    private customerService: CustomerService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.id_customer = this.activedRoute.snapshot.paramMap.get("idcliente");
    this.getCustomer();
  }

  /**
   * This function retrieves customer data from a service and handles different response codes.
   */
  private getCustomer() :void {
    this.loader = 1;
    this.customerService.getCustomer(this.id_customer).subscribe(
      (res) => {
        this.response = res;

        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message);
        else this.data = this.response.data;
        this.setParams();
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This function sets various parameters based on data provided.
   */
  private setParams() :void {
    this.abreviatura = this.data.abreviatura;
    this.name = this.data.nombre;
    this.rfc = this.data.rfc;
    this.moneda = this.data.tipo_moneda;
    this.pago = this.data.id_tipo_pago;
    this.notas = this.data.notas;
    this.facturas = this.data.ingreso_facturas;
    this.web = this.data.pagina_web;
    this.direccionfiscal = this.data.direccion_fiscal;
  }

  /**
   * This function posts customer data to the server and handles the response.
   */
  public postCustomer() :void {
    this.loader = 1;
    let iCliente: ICustomer = {
      id_cliente: this.id_customer,
      abreviatura: this.abreviatura,
      rfc: this.rfc,
      nombre: this.name,
      notas: this.notas,
      ingreso_facturas: this.facturas,
      pagina_web: this.web,
      tipo_moneda: this.moneda,
      id_tipo_pago: this.pago,
      direccion_fiscal: this.direccionfiscal,
    };

    this.customerService.postEditCustomer(iCliente).subscribe(
      (res) => {
        this.result = res;
        if (this.result.data == 1) {
          this.router.navigate(["programmers/customer/", this.id_customer]);
        } else {
          this.lauchModal(this.result.code, this.result.message);
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal(this.result.code, this.result.message);
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This is an asynchronous function that launches a modal with a given code and title using a modal
   * service.
   * @param {string} code - The code parameter is a string that represents the code that will be
   * displayed in the modal.
   * @param {string} title - The title parameter is a string that represents the title of the modal
   * that will be launched. It will be displayed at the top of the modal to provide context to the
   * user.
   */
  private async lauchModal(code: string, title: string) :Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }
}
