import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.css']
})
export class EventListComponent implements OnInit {
  loader:number = 1;
  response:any = [];
  data:any = [];
  permmisions = JSON.parse(localStorage.getItem('permisos'));
  number_page= 0;
  pages = 0;

  status_event_array = [
    {'id':1,'class': 'badge-light',   'info':'Abierto'},
    {'id':2,'class': 'badge-warning', 'info':'Almacén'},
    {'id':3,'class': 'badge-info',    'info':'Agendado'},
    {'id':4,'class': 'badge-primary', 'info':'Con Informe'},
    {'id':5,'class': 'badge-primary', 'info':'Revisado'},
    {'id':6,'class': 'badge-success', 'info':'Autorizado'},
    {'id':7,'class': 'badge-success', 'info':'Entregado'},
    {'id':8,'class': 'badge-purple', 'info':'Cancelado'},
    {'id':9,'class': 'badge-danger', 'info':'Calibración'}
  ];

  progress_view = false;

  constructor(private eventService:EventService,private modalService:NgbModal) { }

  ngOnInit(): void {
    this.loadEventList();
  }

  loadEventList(){
    this.eventService.getEventList().subscribe(
      res=>{
        this.response = res;
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message,'');
        else if(this.response.code == '1_0004')this.lauchModal(this.response.code,this.response.message,'');
        else {
          this.data = this.response.data;
          this.pages = this.data.length;
        }
        this.loader = 2;
      },
      err=>{
        this.lauchModal('0000x00','Error de consulta','');
        console.log(err);
        this.loader = 2;
      }
    );
  }

  changePageResults(page){this.number_page = page;}

  lauchModal(code:string,title:string,message){
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }

}
