import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { GeneratorReportsService } from 'src/app/services/generator-reports.service';
import { environment } from 'src/app/variables/enviroment';

@Component({
  selector: 'app-termohigrometro-economico',
  templateUrl: './termohigrometro-economico.component.html',
  styleUrls: ['./termohigrometro-economico.component.css']
})
export class TermohigrometroEconomicoComponent implements OnInit {

  uri:string = environment.uri;
  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');
  url_to_load = '';

  columnas:any=[];
  finalValores:any=[];
  idsEquipos:any=[];
  idShet:string='';
  idpatron:any=[];

  equiposOdv:any=[]
  pdfInformes:any=[];


  loader:number = 1;
  response:any = [];
  data:any=[];

  refHr1:number;
  refHr2:number;

  readHr1:number;
  readHr2:number;

  refHr11:number;
  refHr12:number;

  readHr11:number;
  readHr12:number;

  lugar:any = "sitio";
  temp:any;
  unidad:any;
  format:any = "AAA-MM-DD";
  fecha:any;

  valueSearchCliente: string="";
  listclientes:any = [];

  valueSearchPlanta: string="";
  listPlantas:any = [];

  constructor(
    private datos: ActivatedRoute, 
    private router:Router, 
    public conn: HttpClient,
    private generadorReports:GeneratorReportsService, 
    private modalService:NgbModal
  ) { }

  ngOnInit(): void {
    let datos=this.datos.snapshot.paramMap.get('id_equipos')
    this.idsEquipos.push(datos.split(','));
    this.idShet = this.datos.snapshot.paramMap.get('id_hoja');
    this.idpatron = this.datos.snapshot.paramMap.get('id_patron');

    this.getData();
  }

  getData(){
    this.generadorReports.verPlantilla(this.idShet,this.idsEquipos).subscribe(
      res=>{
        this.response = res;
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
        else this.data = this.response.data;

        this.equiposOdv = this.data.equipos;

        this.listclientes = this.data.customers_list_search;

        this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        this.loader = 2;
      }
    )

   
  }

  obtClienteId(id_cliente:string){
    this.generadorReports.getPlantasByCliente(id_cliente).subscribe(
      res=>{
        this.response = res;
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
        else this.data = this.response.data;

        this.listPlantas = this.data;
        this.listPlantas.forEach(element => {
          if (element.principal == 1) {
            this.valueSearchPlanta = element.id;
          }
        });

        this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        this.loader = 2;
      }
    )
  }


  obtenerDatos(){
    var HR = 5; var c = 1;

    var inTol_1=""; var inTol_2=""; 
    var inTol_11=""; var inTol_12="";
    var informes=[];

    for (let i = 0; i < this.equiposOdv.length; i++) {
      let listInTol = [];
      let listRef = []; let listRead = [];
      let listmin = []; let listMax = [];
      let equipo_id = this.equiposOdv[i].idEquipo;
      //------------------ Seccion Humedad --------------------

      //------- obtener datos
      this.refHr1 = parseFloat((document.getElementById(equipo_id+"refhr00") as HTMLInputElement).value);
      listRef.push(this.refHr1);
      this.refHr2 = parseFloat((document.getElementById(equipo_id+"refhr01") as HTMLInputElement).value);
      listRef.push(this.refHr2);

      this.readHr1 = parseFloat((document.getElementById(equipo_id+"readhr00") as HTMLInputElement).value);
      listRead.push(this.readHr1);
      this.readHr2 = parseFloat((document.getElementById(equipo_id+"readhr01") as HTMLInputElement).value);
      listRead.push(this.readHr2);

      // calcular min, max y asignar valor
      var min_1 = this.refHr1 - HR; listmin.push(min_1); (document.getElementById(equipo_id+"minhr00") as HTMLInputElement).setAttribute("value",min_1.toString());
      var min_2 = this.refHr2 - HR; listmin.push(min_2); (document.getElementById(equipo_id+"minhr01") as HTMLInputElement).setAttribute("value",min_2.toString());
      
      var max_1 = this.refHr1 + HR; listMax.push(max_1); (document.getElementById(equipo_id+"maxhr00") as HTMLInputElement).setAttribute("value",max_1.toString());
      var max_2 = this.refHr2 + HR; listMax.push(max_2); (document.getElementById(equipo_id+"maxhr01") as HTMLInputElement).setAttribute("value",max_2.toString());
      
      // Evaluar In Tol 
      this.readHr1 < min_1 ? inTol_1 = 'No cumple' : this.readHr1 > max_1 ? inTol_1 = 'No cumple'
      : inTol_1 = 'Si cumple'; listInTol.push(inTol_1);

      this.readHr2 < min_2 ? inTol_2 = 'No cumple' : this.readHr2 > max_2 ? inTol_2 = 'No cumple'
      : inTol_2 = 'Si cumple'; listInTol.push(inTol_2);
      
      //----------------- Temperatura -----------------------

      //----------------- Obtener datos ---------------------
      this.refHr11 = parseFloat((document.getElementById(equipo_id+"ref10") as HTMLInputElement).value);
      listRef.push(this.refHr11);
      this.refHr12 = parseFloat((document.getElementById(equipo_id+"ref11") as HTMLInputElement).value);
      listRef.push(this.refHr12);

      this.readHr11 = parseFloat((document.getElementById(equipo_id+"read10") as HTMLInputElement).value);
      listRead.push(this.readHr11);
      this.readHr12 = parseFloat((document.getElementById(equipo_id+"read11") as HTMLInputElement).value);
      listRead.push(this.readHr12);

      // calcular min, max y asignar valor
      var min_11 = this.refHr11 - c; listmin.push(min_11); 
      (document.getElementById(equipo_id+"min10") as HTMLInputElement).setAttribute("value",min_11.toString());
      var min_12 = this.refHr12 - c; listmin.push(min_12); 
      (document.getElementById(equipo_id+"min11") as HTMLInputElement).setAttribute("value",min_12.toString());
      
      var max_11 = this.refHr11 + c; listMax.push(max_11); 
      (document.getElementById(equipo_id+"max10") as HTMLInputElement).setAttribute("value",max_11.toString());
      var max_12 = this.refHr12 + c; listMax.push(max_12);
      (document.getElementById(equipo_id+"max11") as HTMLInputElement).setAttribute("value",max_12.toString());
      
      // Evaluar In Tol 
      this.readHr11 < min_11 ? inTol_11 = 'No cumple' : this.readHr11 > max_11 ? inTol_11 = 'No cumple'
      : inTol_11 = 'Si cumple'; listInTol.push(inTol_11);

      this.readHr12 < min_12 ? inTol_12 = 'No cumple' : this.readHr12 > max_12 ? inTol_12 = 'No cumple'
      : inTol_12 = 'Si cumple'; listInTol.push(inTol_12);
      
      //console.log(listInTol);

      //----------------- Globales -------------------

      if(this.temp == null || this.unidad == null || this.fecha == null) {
        alert("Campos vacios");
      } else {
        let global = [];
        global.push(this.temp);
        global.push(this.unidad);
        global.push(this.format);
        global.push(this.fecha);
        global.push(this.lugar);
        global.push(this.valueSearchCliente);
        global.push(this.valueSearchPlanta);
        
        let clic = this.uri+"termohigrometro_economico_pdf?api_key="+this.api_key+"&token="+this.token+
              "&module=2&id_plantilla="+this.idShet+"&lref="+listRef+"&tol="+listInTol+
              "&read="+listRead+"&min="+listmin+"&max="+listMax+
              "&globales="+global+"&id_equipo="+equipo_id+"&patron_id="+this.idpatron;
        let a=[];
        a[0] = clic;
        a[1]=this.equiposOdv[i].identificador+this.equiposOdv[i].partida;
        informes.push(a);
        
      }
      
    }

    this.pdfInformes = informes;
    this.loader = 2;

  }

  asignarInforme(url_pdf){
    this.loader = 1;
    url_pdf = url_pdf[0];

    this.conn.get(url_pdf+"&asignar="+"asignar").subscribe(
      res => {
        this.response = res;
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
        else this.data = this.response.data;
        alert("Asignado correctamente");
        this.loader = 2;
      },
      error=>{
        console.log(error);
        alert("Error de petición");
        this.loader = 2;
      }
    );
  }

  descargarInforme(){
    this.url_to_load = this.uri+"get_informe_pdf?api_key="+this.api_key+"&token="+this.token+"&module=2";
  }

  lauchModal(code:string,title:string){
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

}