import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { CustomerService } from "src/app/services/customer.service";
import { IUser } from "src/app/services/models/IUser";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-add-user",
  templateUrl: "./add-user.component.html",
  styleUrls: ["./add-user.component.css"],
})
export class AddUserComponent implements OnInit {
  puesto: string = "";
  nombre: string = "";
  apellidos: string = "";
  extension: string = "";
  telefono: string = "";
  correo: string = "";
  nivel: string = "";
  cliente: string = "";
  planta: string = "";
  depto: string = "";
  contrasenia: string = "";
  confirmcontrasenia: string = "";
  response_clientes: any = [];
  data_clientes: any = [];
  response: any = [];
  data: any = [];
  response_plantas: any = [];
  data_plantas: any = [];
  response_deptos: any = [];
  data_deptos: any = [];
  verdeptos: boolean = true;
  verplantas: boolean = true;
  verclientes: boolean = true;
  addLikeTemp: boolean = false;
  externo: string = "0";
  fechanac: string = "";

  fecha_ingreso: string = "";
  id_area: string = "";
  data_areas: any = [];

  constructor(
    private usuarioService: UserService,
    private modalHelper: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.cargarInfo();
  }

  /**
   * This function changes the visibility of certain elements based on the current level.
   */
  public cambioDeNivel() :void {
    if (this.nivel == "1" || this.nivel == "2") {
      this.verplantas = true;
      this.verdeptos = true;
      this.verclientes = true;
    } else {
      if (this.nivel == "3") {
        this.cargarClientes();
      } else {
        if (this.nivel == "4") {
          this.cargarPlantas();
        } else {
          if (this.nivel == "5") {
            this.cargarDeptos();
          }
        }
      }
    }
  }

  /**
   * The function "cargarPlantas" changes the status of a select element.
   */
  private cargarPlantas() :void {
    this.cambiarEstatusSelect(true, false, true);
  }

  /**
   * The function "cargarDeptos" changes the status of a select element.
   */
  private cargarDeptos() :void {
    this.cambiarEstatusSelect(true, true, false);
  }

  /**
   * The function "cargarClientes" changes the status of a select element.
   */
  private cargarClientes() :void {
    this.cambiarEstatusSelect(false, true, true);
  }

  /**
   * The function "cargarInfo" loads information from a service and handles different response codes.
   */
  private cargarInfo() :void {
    this.usuarioService.getAllPlantasClientesDeptos().subscribe(
      (resp) => {
        this.response_clientes = resp;
        if (
          this.response_clientes.code == "1_0001" ||
          this.response_clientes.code == "1_0002" ||
          this.response_clientes.code == "1_0003"
        )
          this.lauchModal(
            this.response_clientes.code,
            this.response_clientes.message
          );
        else if (this.response_clientes.code == "1_0004")
          this.lauchModal(
            this.response_clientes.code,
            this.response_clientes.message
          );
        else {
          this.data_clientes = this.response_clientes.data.clientes;
          this.data_plantas = this.response_clientes.data.plantas;
          this.data_deptos = this.response_clientes.data.deptos;
          this.data_areas = this.response_clientes.data.areas;

          this.data_areas.splice(2, 1); //REMOVER OPERACIONES
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
      }
    );
  }

  /**
   * This function toggles the value of a boolean variable called "addLikeTemp".
   */
  public userTempActiv() :void {
    if (this.addLikeTemp) {
      this.addLikeTemp = false;
    } else {
      this.addLikeTemp = true;
    }
  }

  /**
   * The function changes the status of three boolean variables.
   * @param {boolean} clientes - a boolean value indicating whether to show or hide the clients
   * section.
   * @param {boolean} plantas - A boolean value indicating whether or not to show the plants. If
   * `true`, the plants will be shown. If `false`, the plants will be hidden.
   * @param {boolean} deptos - A boolean value indicating whether or not to show departments.
   */
  private cambiarEstatusSelect(clientes: boolean, plantas: boolean, deptos: boolean) :void {
    this.verclientes = clientes;
    this.verplantas = plantas;
    this.verdeptos = deptos;
  }

  /**
   * This function checks if all required user data is entered and launches a modal with an error
   * message if any data is missing or incorrect.
   */
  public corroborarDatos() :void {
    if (this.nombre.trim() == "") {
      this.lauchModal("", "Ingresa un nombre para continuar");
    } else {
      if (this.apellidos.trim() == "") {
        this.lauchModal("", "Ingresa por lo menos un apellido para continuar");
      } else {
        if (this.correo.trim() == "") {
          this.lauchModal("", "Ingresa un correo para continuar");
        } else {
          if (this.correo.trim() == "") {
            this.lauchModal("", "Ingresa un correo para continuar");
          } else {
            if (this.contrasenia.trim() == "") {
              this.lauchModal("", "Ingresa una contraseña para continuar");
            } else {
              if (this.confirmcontrasenia.trim() == "") {
                this.lauchModal(
                  "",
                  "Por favor confirma la contraseña para continuar"
                );
              } else {
                if (this.contrasenia !== this.confirmcontrasenia) {
                  this.lauchModal(
                    "",
                    "Las contraseñas no coinciden, por favor verificalas"
                  );
                } else {
                  if (this.nivel == "") {
                    this.lauchModal(
                      "",
                      "Selecciona el nivel de usuario para continuar"
                    );
                  } else {
                    if (this.nivel == "3" && this.cliente == "") {
                      this.lauchModal(
                        "",
                        "Se necesita seleccionar un cliente para este nivel de usuario"
                      );
                    } else {
                      if (this.nivel == "4" && this.planta == "") {
                        this.lauchModal(
                          "",
                          "Se necesita seleccionar una planta para este nivel de usuario"
                        );
                      } else {
                        if (this.nivel == "5" && this.depto == "") {
                          this.lauchModal(
                            "",
                            "Se necesita seleccionar un departamento para este nivel de usuario"
                          );
                        } else {
                          this.enviarUsuario();
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /**
   * This function sends user data to a server and navigates to a different page if successful.
   */
  private enviarUsuario() :void {
    let temp = "0";
    if (this.addLikeTemp) {
      temp = "1";
    }
    if (this.nivel == "1" || this.nivel == "2") {
      this.externo = "0";
    } else {
      this.id_area = null;
      this.externo = "1";
    }
    if (this.nivel == "2") {
      //METROLOGO
      this.id_area = "3"; //OPERACIONES
    }

    let usersender: IUser = {
      nombre: this.nombre,
      apellido: this.apellidos,
      correo: this.correo,
      contrasenia: this.contrasenia,
      puesto: this.puesto,
      area: this.id_area,
      fecha_ingreso: this.fecha_ingreso,
      extension: this.extension,
      telefono: this.telefono,
      externo: this.externo,
      nivel: this.nivel,
      idcliente: this.cliente,
      iddireccioncliente: this.planta,
      iddepartamento: this.depto,
      temporal: temp,
      fechanaci: this.fechanac,

      nuevo: "1",
    };
    this.usuarioService.postUser(usersender).subscribe(
      (res) => {
        this.response = res;
        if (this.response.data > 0) {
          this.router.navigate(["programmers/users"]);
        } else {
          this.lauchModal(this.response.code, this.response.message);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code and title using a
   * modal helper.
   * @param {string} code - The code parameter is a string that represents the code that will be
   * displayed in the modal.
   * @param {string} title - The title parameter is a string that represents the title of the modal
   * that will be launched. It will be displayed at the top of the modal to provide context to the
   * user.
   */
  private async lauchModal(code: string, title: string) :Promise<void> {
    const modalRef = await this.modalHelper.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }
  
}
