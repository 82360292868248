import { Component, ElementRef, OnInit, Renderer2, ViewChild } from "@angular/core";
import { CalendarOptions, FullCalendarComponent } from "@fullcalendar/angular";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { VacationsService } from "src/app/services/vacations.service";

@Component({
  selector: "app-vacations-calendar",
  templateUrl: "./vacations-calendar.component.html",
  styleUrls: ["./vacations-calendar.component.css"],
})
export class VacationsCalendarComponent implements OnInit {
  response: any = [];
  data: any = [];
  loader: number = 0;
  id_user: number;

  cargado = 0;

  //informacion
  t_vacacional = [
    { anio: "1", dias: 12 },
    { anio: "2", dias: 14 },
    { anio: "3", dias: 16 },
    { anio: "4", dias: 18 },
    { anio: "5", dias: 20 },
    { anio: "6 a 10", dias: 22 },
    { anio: "11 a 15", dias: 24 },
    { anio: "16 a 20", dias: 26 },
    { anio: "21 a 25", dias: 28 },
    { anio: "26 a 30", dias: 30 },
    { anio: "31 a 35", dias: 32 },
  ];

  /* SELECCION DE FECHAS */
  public current_date: string = "";
  public fecha_ingreso: string = "";
  public max_date_limit: string = "";
  public fecha_inicio: string = "";
  public fecha_regreso: string = "";

  public send_dates = {
    dates: [],
    nuevo: 1,
    dias: 0
  };

  dias_disp: number = 0;
  dias_rest: number = 0;

  no_request: number = 0;

  //CALENDARIOS
  fechasAgregadas: any = [];

  addDate = false;
  cantAdd = false;

  finalFechas: any = [];
  fechas_off: any = [];
  public my_requested_days = [];
  public fechas_area = [];
  //ADMIN VIEW
  allDates: any = [];
  adminView: boolean = false;

  areaFilter = "0";
  public fecha_actual = new Date();
  public is_phone: boolean = false;
  public is_anticipated: boolean = false;
  public is_requested: boolean = false;
  public mostrar_alerta: boolean = false;
  public mensaje_modal = '';

  @ViewChild('fechaNoDisponible') miModal: ElementRef;
  permmisions = JSON.parse(localStorage.getItem("permisos"));

  @ViewChild("calendar") calendarComponent: FullCalendarComponent;
  calendarOptions: CalendarOptions = {
    initialView: "dayGridMonth",
    locale: "es",
    navLinks: false,
    selectable: false,
    headerToolbar: {
      start: "today",
      center: "title",
      end: "prev,next",
    },
    events: this.finalFechas,
  };

  constructor(
    private vacationService: VacationsService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.is_phone = window.innerWidth < 576;
    this.current_date = this.getCurrentDate(this.fecha_actual);
    this.VerCalendario();
    this.obtenerSolicitudes();
  }

  /**
   * This function obtains vacation requests and constructs a calendar structure based on the data
   * received.
   */
  private obtenerSolicitudes(): void {
    this.finalFechas = [];
    this.fechas_off = [];
    this.no_request = 0;
    this.vacationService.getVacationRequests().subscribe(
      (res) => {
        let response: any = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004" || response.code == "0_0007")
          this.lauchModal(response.code, response.message, "");
        else {
          this.data = response.data;
          this.id_user = this.data.id_user;
          this.getUserHolidaysInfo();
          this.construirEstructuraCalendario(this.my_requested_days, 1);
          this.construirEstructuraCalendario(this.fechas_area, 2, 'yellow',  'Ocupada');
          this.VerCalendario();
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  private getUserHolidaysInfo() : void {
    let vac = this.data.holidays_info;
    this.my_requested_days = []
    this.dias_disp = vac.dias_disponibles;
    this.dias_rest = vac.dias_restantes ?? 0;
    this.fecha_ingreso = vac.fecha_ingreso;
    this.max_date_limit = this.obtenerRangoDeSolicitudVacaciones();
    this.my_requested_days = this.data.area_request.filter(request => request.id_usuario === this.id_user);
    this.no_request = this.my_requested_days.length;

    if (this.dias_rest == 0 || this.dias_rest == null) {
      this.cantAdd = true;
    }

    this.fechas_area = this.data.area_request.filter(request => request.id_usuario !== this.id_user);
  }

  /**
   * This function calculates the number of days between two given dates.
   * @param fechaInicio - The starting date of a period of time.
   * @param fechaFin - fechaFin is a variable representing the end date of a period of time. It is used
   * in the function to calculate the number of days between two dates.
   * @returns the number of days between two dates (fechaInicio and fechaFin) as a number.
   */
  private calcularDias(fecha_inicio, fecha_fin): number {
    fecha_inicio = new Date(fecha_inicio);
    fecha_fin = new Date(fecha_fin);
    let no_dias = 0;

    while (fecha_fin.getTime() > fecha_inicio.getTime()) {
      fecha_inicio.setDate(fecha_inicio.getDate() + 1);
      no_dias++;
    }

    return no_dias;
  }

  /**
   * This function sets up the options for a calendar view in TypeScript, including the initial view,
   * locale, header toolbar, and event sources.
   */
  private VerCalendario(): void {
    this.addDate = false;
    this.calendarOptions = {
      initialView: "dayGridMonth",
      locale: "es",
      navLinks: false,
      selectable: false,
      headerToolbar: {
        start: "today",
        center: "title",
        end: "prev,next",
      },
      eventSources: [
        {
          events: this.finalFechas,
        },
        {
          events: this.fechas_off,
        },
      ],
    };
  }

  private construirEstructuraCalendario(array_parts, type: number,color?: string,title?: string): void {
    const is_current_user = (elements) => this.id_user == elements.id_usuario;
    const color_map = {
      0: "#989898", // SOLICITADO
      1: "#12CBCB", // AGENDADO
      2: "#44CB12", // APLICADO
      5: "#124dcb", // CURSANDO
      3: "#DB3535", // RECHAZADO
      4: "#f4e900", // CADUCADO
    };

    array_parts.forEach((solicitud) => {
      let array_fechas: any = {
        start: solicitud.fecha_inicio,
        end: solicitud.fecha_fin,
      };

      array_fechas = this.setColor( array_fechas, solicitud, color,is_current_user,color_map, type);

      switch (type) {
        case 0:
          this.agregarFechaAgendada(array_fechas, solicitud, title);
          break;
        case 1:
          this.agregarFechasArea( array_fechas, solicitud, color_map,is_current_user );
          break;
        case 2:
          this.agregarFechaBackground(array_fechas, title, color);
          break;
        case 3:
          this.agregarFechaAdministracion(array_fechas, solicitud, color_map);
          break;
      }
    });
  }

  private setColor(arrayFechas,elements,color,is_current_user,  color_map, type): any {
    if (is_current_user(elements) && type !== 3) {
      arrayFechas["color"] = color_map[elements.estatus];
    } else {
      arrayFechas["color"] = color;
    }
    return arrayFechas;
  }

  private agregarFechaAgendada(array_fechas, elements, title): void {
    array_fechas = {
      ...array_fechas,
      overlap: false,
      title: title,
      dias: elements.dias,
      f_start: elements.f_start,
      f_end: elements.f_end,
      anticipada: elements.anticipada,
      requested: elements.requested,
    };
    this.fechasAgregadas.push(array_fechas);

    this.is_requested = this.buscarFechaAnticipada(
      this.fechasAgregadas,
      "requested"
    );
    this.is_anticipated = this.buscarFechaAnticipada(
      this.fechasAgregadas,
      "anticipada"
    );
  }

  private agregarFechasArea(array_fechas, elements, color_map, is_current_user): void {
    
    array_fechas["id_user"] = elements.id_usuario;
    array_fechas["title"] = "Mis Vacaciones";
    array_fechas["color"] = color_map[elements.estatus];
    array_fechas["display"] = "auto" ;
    //array_fechas["eventOverlap"] = is_current_user(elements) ? false : true;
    this.finalFechas.push(array_fechas);
  }

  private agregarFechaBackground(array_fechas, title, color): void {
    array_fechas = {
      ...array_fechas,
      display: "background",
      color: color, 
      title: title,
      overlap: true,
    };
    this.fechas_off.push(array_fechas);
  }

  private agregarFechaAdministracion(array_fechas, elements, color_map): void {
    const status_map = {
      0: "(So)",
      1: "(Ag)",
      2: "(Ap)",
      3: "(Re)",
      4: "(Ca)",
      5: "(Cu)",
    };
    const area_map = {
      Sistemas: "#fec9bd40",
      Comercial: "#93e7c340",
      Operaciones: "#93A6E740",
      Administración: "#afafaf40",
      Dirección: "#BC80EE40",
    };

    array_fechas = {
      ...array_fechas,
      textColor: color_map[elements.estatus],
      color: area_map[elements.area],
      title: `${status_map[elements.estatus]} ${elements.nombre}`,
    };
    this.allDates.push(array_fechas);
  }

  //SELECCION DE FECHAS VACACIONES
  /**
   * This function sets up a calendar with specific options and event sources, and allows for selecting
   * dates within a valid range.
   */
  private seleccionarFechas(): void {
    this.addDate = true;

    this.calendarOptions = {
      initialView: "dayGridMonth",
      initialDate: this.getStartVacDays(),
      locale: "es",
      navLinks: false,
      selectable: true,
      headerToolbar: {
        start: "today",
        center: "title",
        end: "prev,next",
      },
      select: this.agregarEnCalendario.bind(this),
      validRange: {
        start: this.getStartVacDays(),
        end: this.obtenerRangoDeSolicitudVacaciones(),
      },
      eventSources: [
        {
          events: this.finalFechas,
        },
        {
          events: this.fechasAgregadas,
        },
        {
          events: this.fechas_off,
        },
        {
          events: [
            {
              title: "Días Anticipados",
              start: this.current_date,
              end: this.sumar15Dias(),
              display: "background",
              selectOverlap: false
            },
          ],
          color: "gray",
        },
      ],
      //selectOverlap: false,
    };
  }

  /**
   * The function adds a date range to a calendar and checks if there are enough available days.
   * @param arg - an object that contains the start and end dates of an event.
   */
  private agregarEnCalendario(arg): void {
    this.fecha_inicio = arg.startStr;
    this.fecha_regreso = arg.endStr;
    this.agregarDiasSeleccionados()
  }

  public agregarDiasSeleccionados(): void{
    let dateArray = [];
    let dateobj: any = {};
    let c_dias = 0;
    dateobj.fecha_inicio = this.fecha_inicio;
    dateobj.fecha_fin = this.fecha_regreso;
    dateobj.f_start = this.formatearFecha(this.fecha_inicio);
    dateobj.f_end = this.formatearFecha(this.fecha_regreso);
    dateobj.anticipada = this.fechaAnticipadaSeleccionada(this.fecha_inicio);
    dateobj.requested = this.verificarFechasColaboradores(
      this.fecha_inicio,
      this.fecha_regreso,
      this.fechas_off
    );

    dateobj.dias = this.calcularDias(this.fecha_inicio, this.fecha_regreso);
    dateArray.push(dateobj);
    c_dias = this.dias_rest - dateobj.dias;
    if(this.blockSelectedDay(this.fecha_inicio, this.fecha_regreso, this.finalFechas)) {
      this.mensaje_modal = 'Haz seleccionado un dia ya solicitado anteriormente';
      this.openModal();
    }
    else if (this.blockSelectedDay(this.fecha_inicio, this.fecha_regreso, this.fechasAgregadas)) {

      this.mensaje_modal = 'Haz seleccionado un dia repetido';
      this.openModal();
    } else if (c_dias >= 0 || c_dias == null) {
      this.dias_rest = this.dias_rest - dateobj.dias;
      this.construirEstructuraCalendario(dateArray, 0, "#989898", "Vacaciones");
      this.seleccionarFechas();
    } else {
      this.mensaje_modal = 'No cuentas con suficientes días';
      this.openModal();
    }
  }

  private blockSelectedDay(inicio, fin, fechas_existentes): boolean {
    const start_date = new Date(inicio);
    const end_date = new Date(fin);
    if (fechas_existentes.length > 0) {
      for (const fechas of fechas_existentes) {
        let start_added_date = new Date(fechas.start);
        let end_added_date = new Date(fechas.end);

        if (
          (start_added_date >= start_date && start_added_date < end_date) ||
          (end_added_date > start_date && end_added_date < end_date)
        ) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * This function removes a date from an array and updates the remaining days.
   * @param index - The index parameter is the index of the element in the fechasAgregadas array that
   * needs to be removed.
   */
  public eliminarFecha(index): void {
    this.dias_rest = this.dias_rest + this.fechasAgregadas[index].dias;
    this.fechasAgregadas.splice(index, 1);
    this.is_requested = this.buscarFechaAnticipada(
      this.fechasAgregadas,
      "requested"
    );
    this.is_anticipated = this.buscarFechaAnticipada(
      this.fechasAgregadas,
      "anticipada"
    );
    this.seleccionarFechas();
  }

  /**
   * The function "recopilarFechas" adds dates to a list and creates a request for each date range.
   */
  public recopilarFechas(): void {

    let dias_solicitados = 0;
    let request_dates = [];
    this.fechasAgregadas.forEach((fechas) => {
      let new_dates = {
        inicio: fechas.start,
        regreso: fechas.end
      };
      request_dates.push(new_dates);
      dias_solicitados += fechas.dias;
    });
    this.crearSolicitud(request_dates, dias_solicitados);
  }

  /**
   * This function creates a vacation request with a given date and sends it to the vacation service,
   * displaying a modal with the response message if necessary.
   * @param fecha - The parameter "fecha" is a date that is used to create a vacation request. It is
   * passed as an argument to the "crearSolicitud" method.
   */
  private crearSolicitud(request_dates, dias_solicitados) {
    this.send_dates = {
      dates: request_dates,
      nuevo: 1,
      dias: dias_solicitados
    };
    this.vacationService.sendVacationRequest(this.send_dates).subscribe(
      (res) => {
        let response: any = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004" || response.code == "0_0007")
          this.lauchModal(response.code, response.message, "");
        else {
          this.fechasAgregadas = [];
          this.solicitudCreadaAlerta();
          this.obtenerSolicitudes();
        }

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }
  solicitudCreadaAlerta() {
    this.mostrar_alerta = true;
    setTimeout(() => {
      this.mostrar_alerta = false;
    }, 5000); 
  }

  // ADMINISTRATOR VIEW
  /**
   * The function toggles between an admin view and a calendar view.
   */
  public changeView(): void {
    if (this.adminView == false) {
      this.adminView = true;
      this.addDate = false;
      this.getAllRequestByArea();
    } else {
      this.adminView = false;
      this.obtenerSolicitudes();
    }
  }
  /**
   * This function retrieves all vacation requests and filters them by area, then constructs a calendar
   * structure based on the filtered data.
   */
  private getAllRequestByArea(): void {
    this.data = [];
    this.allDates = [];
    let fechas: any = [];
    this.vacationService.getAllVacationRequests().subscribe(
      (res) => {
        let response: any = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004" || response.code == "0_0007")
          this.lauchModal(response.code, response.message, "");
        else {
          this.data = response.data;

          this.data.forEach((d) => {
            let fechasArray: any = {};
            fechasArray.id_request = d.req_id;
            fechasArray.area = d.area;
            fechasArray.id_usuario = d.user_id;
            fechasArray.nombre = d.nombre;
            fechasArray.apellido = d.apellido;
            fechasArray.estatus = d.req_estatus;
            fechasArray.fecha_inicio = d.fecha_inicio;
            fechasArray.fecha_fin = d.fecha_fin;

            if (this.areaFilter == "0") {
              //TODAS LAS AREAS
              fechas.push(fechasArray);
            } else {
              if (this.areaFilter == d.area) {
                //FILTRAR POR AREA
                fechas.push(fechasArray);
              }
            }
          });
          this.construirEstructuraCalendario(fechas, 3);

          this.verCalendarioAdmin();
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }
  /**
   * This function sets up the options for a calendar view in TypeScript, including the initial view,
   * locale, header toolbar, and event sources.
   */
  private verCalendarioAdmin(): void {
    this.calendarOptions = {
      initialView: "dayGridMonth",
      height: 700,
      locale: "es",
      navLinks: false,
      selectable: false,
      headerToolbar: {
        start: "today",
        center: "title",
        end: "prev,next",
      },
      dayMaxEvents: true,
      eventSources: [
        {
          events: this.allDates,
        },
      ],
    };
  }

  //HERRAMIENTAS
  public openModal() {
    this.miModal.nativeElement.classList.add('show');
    this.miModal.nativeElement.style.display = 'block';
    this.miModal.nativeElement.setAttribute('aria-hidden', 'false');
  }

  public cerrarModal() {
    // Cierra el modal
    this.miModal.nativeElement.classList.remove('show');
    this.miModal.nativeElement.style.display = 'none';
    this.miModal.nativeElement.setAttribute('aria-hidden', 'true');
  }
  buscarFechaAnticipada(fechas, campo) {
    return fechas.some((objeto) => objeto[campo] === true);
  }

  verificarFechasColaboradores(fecha_inicio, fecha_fin, array_Fechas_off) {
    const f_incio = new Date(fecha_inicio);
    const f_fin = new Date(fecha_fin);

    for (const objeto of array_Fechas_off) {
      const inicio = new Date(objeto.start);
      const fin = new Date(objeto.end);

      if (f_incio >= inicio && f_incio < fin) {
        return true;
      }
      if (f_fin > inicio && f_fin < fin) {
        return true;
      }
    }
    return false;
  }

  private fechaAnticipadaSeleccionada(fecha_string: string): boolean {
    const fecha = new Date(fecha_string);
    const current = new Date(this.getStartVacDays());
    const limit = new Date(this.sumar15Dias());

    if (fecha >= current && fecha < limit) {
      return true;
    }
    return false;
  }

  private formatearFecha(fecha_string: string): string {
    const fecha = new Date(fecha_string);
    const mesesAbreviados = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];
    const dia: any = fecha.getDate();
    const mesAbreviado = mesesAbreviados[fecha.getMonth()];
    return `${dia + 1} ${mesAbreviado}`;
  }

  private sumar15Dias(): string {
    const fecha_actual: Date = new Date();
    const fecha_sumada: Date = new Date(fecha_actual);
    fecha_sumada.setDate(fecha_sumada.getDate() + 15);

    const fecha_sumada_string: string = this.getCurrentDate(fecha_sumada);

    return fecha_sumada_string;
  }
  /**
   * This function returns the current date in a specific API format.
   * @returns a string representing the current date in the format "YYYY-MM-DD".
   */
  private getCurrentDate(fecha: Date): string {
    let day: any = fecha.getDate();
    let month: any = fecha.getMonth() + 1;
    let year: any = fecha.getFullYear();

    const mes_str: string = month < 10 ? `0${month}` : `${month}`;
    const dia_str: string = day < 10 ? `0${day}` : `${day}`;

    return `${year}-${mes_str}-${dia_str}`;
  }

  public obtenerRangoDeSolicitudVacaciones(): string {
    const fecha_ingreso = new Date(this.fecha_ingreso);
    const fecha_actual = new Date();
    const año_actual = fecha_actual.getFullYear();

    const ingreso_actual = this.cambiarAnio(fecha_ingreso, año_actual);

    let fin;
    if (fecha_actual > ingreso_actual) {
      fin = this.cambiarAnio(ingreso_actual, ingreso_actual.getFullYear() + 1);
    } else {
      fin = ingreso_actual;
    }

    return fin.toISOString().slice(0, 10);
  }

  private cambiarAnio(fecha: Date, nuevoAnio: number): Date {
    const nuevaFecha = new Date(fecha);
    nuevaFecha.setFullYear(nuevoAnio);
    return nuevaFecha;
  }

  private getStartVacDays(): string {
    const START_DATE = new Date();
    START_DATE.setDate(START_DATE.getDate() + 1);

    let day: any = START_DATE.getDate();
    let month: any = START_DATE.getMonth() + 1;
    let year: any = START_DATE.getFullYear();

    if (day < 10) day = "0" + day.toString();

    if (month < 10) month = "0" + month.toString();

    const FORMATTED_DATE = `${year}-${month}-${day}`;
    return FORMATTED_DATE;
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code, title, and
   * message using the ModalComponent.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - The title of the modal window that will be displayed.
   * @param {string} message - The message parameter is a string that represents the content of the
   * message to be displayed in the modal.
   */
  private async lauchModal(
    code: string,
    title: string,
    message: string
  ): Promise<void> {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
}
