import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { EventService } from 'src/app/services/event.service';
import { InstrumentService } from 'src/app/services/instrument.service';
import { environment } from 'src/app/variables/enviroment';
import { Result } from '@zxing/library';

@Component({
  selector: 'app-report-event',
  templateUrl: './report-event.component.html',
  styleUrls: ['./report-event.component.css']
})
export class ReportEventComponent implements OnInit {
  uri:string = environment.uri;
  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');
  
  id_instrument:string;
  id_report:string;
  id_event:string;
  loader:number = 1;
  response:any = [];
  data:any= [];
  doc_name = '';
  url_to_load = environment.uri_for_reports;
  //url_to_load = 'http://ngdat.ucal.com.mx/storage/public/informes/';
  permmisions = JSON.parse(localStorage.getItem('permisos'));

  infos:any=[];
  cont:number = 0;
  info_ant:number=0;
  info_prox:number=0;
  url_pdf:any;
  status:string;
  id_detalle_event:number;
  comentarios_rechazo = "";
  readyToSend = false;
  cont_2:number=0;

  id_infor = "";
  passwrd='';
  notaParaRemover='';
  nomInforme='';
  val_status:any;
  plantilla=[];

  @ViewChild("btn_close_modal") divView: ElementRef;

  constructor(
    private modalService:NgbModal,
    private instument_service:InstrumentService,
    private activedRoute:ActivatedRoute,
    private sanitizer: DomSanitizer,
    private router:Router,
    private eventService:EventService
  ) { }

  ngOnInit(): void {
    this.id_instrument = this.activedRoute.snapshot.paramMap.get('id_instrument');
    this.id_report = this.activedRoute.snapshot.paramMap.get('id_report');
    this.id_event = this.activedRoute.snapshot.paramMap.get('id_event');
    this.loadData();
  }

  /**
   * The function `loadData` is used to load data from a specified URL and handle the response
   * accordingly.
   */
  public loadData() :void {
    this.url_to_load=environment.uri_for_reports;
    this.instument_service.getReport(this.id_instrument,this.id_report).subscribe(
      res=>{
        this.response = res;
        if(this.response.code != '0_005' )this.lauchModal(this.response.code,this.response.message);
        else{
          this.data = this.response.data;
          this.doc_name = this.data.name;
          this.url_to_load = this.url_to_load+this.doc_name;
          this.status = this.data.estatus;
          this.val_status = this.data.estatus;
          this.id_infor = this.data.id_inf;
          this.id_detalle_event = this.data.id_part;
          this.docURL();
          this.cont = 0;
          this.verinfos();
          if(this.data["plantilla"] != undefined) this.plantilla = this.data.plantilla;
        }
        this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        console.log(err);
        this.loader = 2;
      }
    );
  }

  public docURL() :void {
    this.url_pdf =this.sanitizer.bypassSecurityTrustResourceUrl(this.url_to_load);
  }

  /**
   * The function `lauchModal` opens a modal window with a given code and title.
   * @param {string} code - The code parameter is a string that represents the code that will be
   * displayed in the modal. It could be any valid code, such as HTML, CSS, JavaScript, or any other
   * programming language.
   * @param {string} title - The title parameter is a string that represents the title of the modal.
   */
  lauchModal(code:string,title:string){
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

  /**
   * The function "verinfos" retrieves information from a service and populates an array with specific
   * data based on certain conditions.
   */
  public verinfos() :void{
    this.infos=[];
    let response;
    this.instument_service.getinformesEvent(this.id_event).subscribe(
      res=>{
        response = res;
        if(this.response.code != '0_005') this.lauchModal(this.response.code,this.response.message);
        else{
            let informes_cargados = response.data;
            let total = informes_cargados.length-1;
            for (let i = 0; i < informes_cargados.length; i++) {
              let aux=[]; let mas=[];
              if (informes_cargados[i].id == this.id_report) {
                if ( i==total) {
                  aux['id']=informes_cargados[i-1].id;
                  aux['informe']=informes_cargados[i-1].informe;
                  aux['id_equipo']=informes_cargados[i-1].id_equipo;
                  this.infos.push(aux);
                }else if (i==0) {
                  aux['id']=informes_cargados[i+1].id;
                  aux['informe']=informes_cargados[i+1].informe;
                  aux['id_equipo']=informes_cargados[i+1].id_equipo;
                  this.infos.push(aux);
                }else if (i>0 && i<informes_cargados.length) {
                  aux['id']=informes_cargados[i-1].id;
                  aux['informe']=informes_cargados[i-1].informe;
                  aux['id_equipo']=informes_cargados[i-1].id_equipo;
                  mas['id']=informes_cargados[i+1].id;
                  mas['informe']=informes_cargados[i+1].informe;
                  mas['id_equipo']=informes_cargados[i+1].id_equipo;
                  this.infos.push(aux);
                  this.infos.push(mas);
                }
              }
            }
        }
        this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * The function "reload_window" is used to reload a window with a specific URL and load data.
   * @param id - The "id" parameter is used to identify a specific report.
   * @param id_equipo - The parameter "id_equipo" is the ID of the equipment or instrument.
   */
  public reload_window(id,id_equipo):void {
    this.id_report=id;
    this.id_instrument=id_equipo;
    this.url_to_load=environment.uri_for_reports;
    let url = "/report_event/"+this.id_instrument+"/"+this.id_report+"/"+this.id_event;
    this.router.navigate([url]);
    this.loadData();
  }

  chageStatusPartida(estatus){
    let result_2: any = [];
    let module = "73";
    if(estatus == "2" || estatus == "4"){
      module = "72";
    }

    this.eventService.changeEstatusPart(this.id_detalle_event, estatus, module).subscribe(
      res=>{
        result_2 = res;
          if(result_2.code == '1_0001' || result_2.code == '1_0002' || result_2.code == '1_0003')this.lauchModal(result_2.code,result_2.message);
          else if(result_2.code == '1_0004' || result_2.code == '0_007')this.lauchModal(result_2.code,result_2.message);
          else {
            
            if(result_2.data == 1) {
              this.loadData();
            }
            else this.lauchModal('0000x00','Algo salió mal. Intenta nuevamente');
          }
          this.loader = 2;
      },err=>{
        
        this.lauchModal('0000x00','Error de consulta');
        console.log(err.error);
        this.loader = 2;
      }
    );
  }

  typingOnComments(){
    if(this.comentarios_rechazo.trim().length > 0){
      this.readyToSend = true;
    }else{
      this.readyToSend = false;
    }
  }

  rechazarInforme(){
    let result:any = [];
    this.loader = 1;
    this.eventService.rechazarInforme(this.id_detalle_event, this.comentarios_rechazo).subscribe(
      res=>{
        result = res;
        console.log(result);
          if(result.code == '1_0001' || result.code == '1_0002' || result.code == '1_0003')this.lauchModal(result.code,result.message);
          else if(result.code == '1_0004' || result.code == '0_007')this.lauchModal(result.code,result.message);
          else {
            
            if(result.data == 1) {
              this.router.navigate(["/event/det/"+this.id_event]);
            }
            else this.lauchModal('0000x00','Algo salió mal. Intenta nuevamente');
          }
          this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        console.log(err);
        this.loader = 2;
      }
    );
  }

  removerInforme(){
    let result:any= [];
    let form = new FormData();
    form.append('id_informe',this.id_infor);
    form.append('password',this.passwrd);
    form.append('nota', this.notaParaRemover)
    this.loader = 1;
    this.eventService.removeReportOperaciones(form).subscribe(
      res=>{
        result = res;
        console.log(result);
          if(result.code == '1_0001' || result.code == '1_0002' || result.code == '1_0003')this.lauchModal(result.code,result.message);
          else if(result.code == '1_0004' || result.code == '0_007')this.lauchModal(result.code,result.message);
          else {
            
            if(result.data == 1) {
              this.router.navigate(["/event/det/"+this.id_event]);
            }
            else if(result.data == 0){
              this.lauchModal('0_007','Contraseña incorrecta');
            }
          }
          this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        console.log(err);
        this.loader = 2;
      }
    );
  }

}
