import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { NewsService } from "src/app/services/news.service";
import { SaleOrderService } from "src/app/services/sale-order.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-send-email-odc",
  templateUrl: "./send-email-odc.component.html",
  styleUrls: ["./send-email-odc.component.css"],
})
export class SendEmailOdcComponent implements OnInit {
  loader = 0;
  asunto = "";
  usuarios: any = [];
  mensaje = "";
  tipo_email = "";

  list_auxiliar: any = [];
  remitente = "";
  destinatario = "";
  new_email = "";

  notas = "";
  userResult: any = [];

  responseAuxiliar: any = [];
  list_Auxiliar: any = [];
  data_Auxiliar: any = [];

  tipoemail2 = false;
  sendingemail = false;

  id_auxiliar = ""; //Puede ser id de orden de compra o cualquier otro para poder adjuntar ese pdf por id
  axiliar_name = "";

  constructor(
    private activedRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private saleOrderService: SaleOrderService,
    private modalService: NgbModal,
    private avisoService: NewsService
  ) {}

  ngOnInit(): void {
    this.id_auxiliar = this.activedRoute.snapshot.paramMap.get("id");
    this.tipo_email = "2";

    this.asunto = "Información de orden de compra";
    this.tipoemail2 = true;

    this.getUsers();
    this.getOdcDetail();
  }

  /**
   * This function retrieves all users with an ID of 8 using a user service and stores the result in a
   * variable.
   */
  private getUsers() :void {
    this.userService.getAllUsers("8").subscribe(
      (res) => {
        this.userResult = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /**
   * This function retrieves details of an ODC (Order Delivery Confirmation) and displays them in a
   * modal.
   */
  private getOdcDetail() :void {
    this.loader = 1;
    this.saleOrderService.getDetailODC(this.id_auxiliar).subscribe(
      (res) => {
        this.responseAuxiliar = res;
        if (this.responseAuxiliar.code != "0_005")
          this.lauchModal(
            this.responseAuxiliar.code,
            "",
            this.responseAuxiliar.message
          );
        else {
          this.data_Auxiliar = this.responseAuxiliar.data;
          console.log(this.data_Auxiliar);
          this.axiliar_name = this.data_Auxiliar.consecutivo;
        }
        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "", "Error de consulta");
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * This function adds or removes an email from an array of emails based on whether it already exists
   * in the array or not.
   * @param correo - a string representing an email address that needs to be added or removed from an
   * array of email addresses.
   */
  private agregarCorreo(correo) :void {
    if (this.usuarios.length > 0) {
      let contador: number;
      let bandera: boolean = false;
      let posicioneliminar: number;
      for (contador = 0; contador < this.usuarios.length; contador++) {
        if (this.usuarios[contador] == correo) {
          bandera = true;
          posicioneliminar = contador;
        }
      }

      if (!bandera) {
        this.usuarios.push(correo);
      } else {
        this.usuarios.splice(posicioneliminar, 1);
      }
    } else {
      this.usuarios.push(correo);
    }
    this.pasarAStringArray();
  }

  /**
   * This function converts an array of strings into a single string separated by commas.
   */
  private pasarAStringArray() :void {
    this.destinatario = "";
    this.usuarios.forEach((element) => {
      if (this.destinatario == "") {
        this.destinatario = element;
      } else {
        this.destinatario = this.destinatario + "," + element;
      }
    });
  }

  /**
   * This function adds an extra email to a list if it is not empty, otherwise it launches a modal with
   * an error message.
   */
  public agregarCorreoExtra() :void {
    if (this.new_email.trim() != "") {
      this.agregarCorreo(this.new_email);
      this.new_email = "";
    } else {
      this.lauchModal("", "Campo vacio", "Por favor ingresa un correo");
    }
  }

  /**
   * This function sends an email with specific parameters and handles success and error responses.
   */
  public sendEmail() :void {
    this.sendingemail = true;
    this.avisoService
      .sendMailAny(
        this.asunto,
        this.mensaje,
        this.destinatario,
        this.id_auxiliar,
        this.axiliar_name,
        this.tipo_email,
        this.notas
      )
      .subscribe(
        (res) => {
          this.router.navigate([
            "/administration/sale_order/det/" + this.id_auxiliar,
          ]);
        },
        (err) => {
          this.lauchModal(
            "",
            "Ocurrió un error",
            "Por favor intenta más tarde."
          );
          this.sendingemail = false;
          console.log(err);
        }
      );
  }

 /* This is a function that launches a modal component with a title, message, and code. It takes in
 three parameters: `code`, `title`, and `message`, all of which are strings. The function is marked
 as `async` and returns a `Promise<void>`. Inside the function, it opens a modal using the
 `modalService` and sets the `code`, `title`, and `message` properties of the `ModalComponent`
 instance using the `componentInstance` property of the `modalRef`. */
  private async lauchModal(code: string, title: string, message) :Promise<void>{
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
}
