import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { WikiService } from "src/app/services/wiki.service";

@Component({
  selector: "app-wiki",
  templateUrl: "./wiki.component.html",
  styleUrls: ["./wiki.component.scss"],
})
export class WikiComponent implements OnInit {
  loader = 0;
  response: any = [];
  list: any = [];
  list_no_filters: any = [];
  permmisions = JSON.parse(localStorage.getItem("permisos"));

  list_filt_area: any = [];
  list_filt_type: any = [];

  constructor(
    private wikiService: WikiService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.loadList();
  }

  /**
   * This function loads a list of data from a wiki service and performs some filtering and error
   * handling.
   */
  private loadList(): void {
    this.loader = 1;
    this.wikiService.getWiki().subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.list = this.response.data;
          this.list_no_filters = this.list;
          for (var i = 0; i < this.list.length; i++) {
            this.list_filt_area.push(this.list[i].area);
            this.list_filt_type.push(this.list[i].tipo);
          }

          var aux_area = [];
          for (var i = 0; i < this.list_filt_area.length; i++) {
            const elemento = this.list_filt_area[i];

            if (!aux_area.includes(this.list_filt_area[i])) {
              aux_area.push(elemento);
            }
          }

          var aux_type = [];
          for (var i = 0; i < this.list_filt_type.length; i++) {
            const elemento = this.list_filt_type[i];

            if (!aux_type.includes(this.list_filt_type[i])) {
              aux_type.push(elemento);
            }
          }
          this.list_filt_area = aux_area;
          this.list_filt_type = aux_type;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", err.message);
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This function filters a list based on a given type.
   * @param type - The parameter "type" is a variable that represents the type of filter to be applied
   * to the list. It is used to filter the list based on the value of the "tipo" property of each item
   * in the list. If the value of "type" is not equal to 1, the
   */
  public filterType(type): void {
    this.list = [];

    for (var i = 0; i < this.list_no_filters.length; i++) {
      if (type != 1) {
        if (this.list_no_filters[i].tipo === type)
          this.list.push(this.list_no_filters[i]);
      } else this.list.push(this.list_no_filters[i]);
    }
  }

  /**
   * This function filters a list based on a specified area parameter.
   * @param area - The parameter "area" is a variable that represents the area to be filtered in the
   * function "filterArea". It is used to filter the "list_no_filters" array and populate the "list"
   * array with the filtered results. If the value of "area" is 1, then all items
   */
  public filterArea(area): void {
    this.list = [];

    for (var i = 0; i < this.list_no_filters.length; i++) {
      if (area != 1) {
        if (this.list_no_filters[i].area === area)
          this.list.push(this.list_no_filters[i]);
      } else this.list.push(this.list_no_filters[i]);
    }
  }

  /**
   * This is a private async function that launches a modal with a given code, title, and message using
   * the ModalComponent.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - A string representing the title of the modal window that will be launched.
   * @param message - The message parameter is a string that represents the message to be displayed in
   * the modal dialog box.
   */
  private async lauchModal(
    code: string,
    title: string,
    message
  ): Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
}
