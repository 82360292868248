import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { format } from "path";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { IODC } from "src/app/services/models/IODC";
import { IPartODC } from "src/app/services/models/IPartODC";
import { RequisitionsService } from "src/app/services/requisitions.service";
import { SaleOrderService } from "src/app/services/sale-order.service";

@Component({
  selector: "app-edit-requisitions",
  templateUrl: "./edit-requisitions.component.html",
  styleUrls: ["./edit-requisitions.component.css"],
})
export class EditRequisitionsComponent implements OnInit {
  id_requisicion = "";
  loader = 2;
  data_general: any = [];
  parts: any = [];
  //Para Editar
  identifier = "";
  notes = " ";
  ship_to = "";
  bill_to = "";

  supplier_directions: any = [];
  client_directions: any = [];

  suppliers_list: any = [];

  a_nombre = "";
  direccion = "";
  municipio = "";

  attention_id = null;
  supl_users: any = [];
  tipo_moneda = 1;
  razon_social = "";
  id_proveedor = null;
  rs_cliente = "";

  coin_type: any = [
    { desc: "MXN", id: "1" },
    { desc: "USD", id: "2" },
  ];
  rs_list: any = [
    { id: 1, rs: "ETALONS S.A. DE C.V." },
    { id: 2, rs: "ETALONET S.A. DE C.V." },
    { id: 3, rs: "UCAL MED SA DE CV" },
  ];

  //Agregar partidas
  concept = 0;
  price_part = 0;
  quantity_part = 1;
  notes_part = "N/A";
  desc_part = "N/A";
  tag = "N/A";
  marca = "N/A";
  modelo = "N/A";
  serie = "N/A";
  message_add_part = "S/I";

  total = 0;

  //Datos para editar la partida
  edit = 0;

  id_part_to_delete = 0;
  tag_part_to_delete = "";
  price_part_to_delete = "";

  constructor(
    private activedRoute: ActivatedRoute,
    private requisitionService: RequisitionsService,
    private saleOrderService: SaleOrderService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.id_requisicion = this.activedRoute.snapshot.paramMap.get("id");
    this.getData();
  }

  /**
   * This function retrieves data from an API and assigns it to various variables for use in a web
   * application.
   */
  private getData() :void {
    this.total = 0;
    let response: any = [];
    this.requisitionService.getDataToEdit(this.id_requisicion).subscribe(
      (res) => {
        response = res;
        if (response.code != "0_005")
          this.lauchModal(response.code, response.message, "");
        else {
          this.data_general = response.data;
          this.identifier = this.data_general.general.folio;
          this.tipo_moneda = this.data_general.general.tipo_moneda;
          this.notes = this.data_general.general.nota;
          this.razon_social = this.data_general.general.razon_social;
          this.rs_cliente =
            this.razon_social == "ETALONS S.A. DE C.V."
              ? "1"
              : this.razon_social == "ETALONET S.A. DE C.V."
              ? "2"
              : "3";

          this.supl_users = this.data_general.list_contactos;
          this.attention_id = this.data_general.atencion_a;
          this.client_directions = this.data_general.direcciones_cliente;
          this.supplier_directions = this.data_general.direcciones_proveedor;
          this.bill_to = this.data_general.bill_to;
          this.ship_to =
            this.data_general.ship_to == null
              ? "57"
              : this.data_general.ship_to;
          this.parts = this.data_general.partidas;


          this.a_nombre = this.data_general.general.a_nombre_de == null
              ? "S/I" : this.data_general.general.a_nombre_de;

          this.direccion = this.data_general.general.direccion == null
              ? "S/I" : this.data_general.general.direccion;

          this.municipio = this.data_general.general.municipio == null
              ? "S/I" : this.data_general.general.municipio;

          for (let i = 0; i < this.parts.length; i++) {
            this.total += this.parts[i].cantidad * this.parts[i].precio;
          }
        }
        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", err.message);
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * This is a private function in TypeScript that sets the value of a property called "rs_cliente".
   * @param value - The value parameter is the new value that will be assigned to the rs_cliente
   * property.
   */
  public setRsCliente(value) :void {
    this.rs_cliente = value;
  }

  /**
   * The function calls another function to save new data for a part.
   */
  public validateDataToAddPart() :void {
    this.saveNewPart();
  }

  /**
   * This is a private function in TypeScript that searches for a supplier using a value and updates
   * the suppliers list accordingly.
   * @param value - The value parameter is a string that represents the search query for the supplier.
   * It is used as a parameter for the getSupplier() method of the requisitionService to retrieve a
   * list of suppliers that match the search query.
   */
  public searchSupplier(value) :void {
    let data: any = [];
    this.loader = 1;
    this.requisitionService.getSupplier(value).subscribe(
      (res) => {
        data = res;
        if (data.code == "0_005") {
          this.suppliers_list = data.data;
        } else this.lauchModal(data.code, data.message, "");
        this.loader = 2;
      },
      (err) => {
        this.loader = 2;
        this.lauchModal("0000x00", "Error de consulta", err.message);
        console.log(err);
      }
    );
  }

  /**
   * This is a private function in TypeScript that sets the supplier ID.
   * @param id - The parameter "id" is a value that represents the ID of a supplier. The function
   * "setSupplier" sets the value of the "id_proveedor" property to the value of the "id" parameter.
   */
  public setSupplier(id) :void {
    this.id_proveedor = id;
  }

  /**
   * The function sets the value of the "bill_to" property.
   * @param value - The value parameter is the new value that will be assigned to the "bill_to"
   * property of the object.
   */
  public setBillTo(value) :void {
    this.bill_to = value;
  }

  /**
   * This is a private function in TypeScript that sets the value of a ship_to property.
   * @param val - The value that will be set as the new value for the "ship_to" property.
   */
  public setShipTo(val) :void {
    this.ship_to = val;
  }

  /**
   * This is a private function in TypeScript that sets the value of a coin type.
   * @param value - The value parameter is the new value that will be assigned to the tipo_moneda
   * property.
   */
  public setCoin(value) :void {
    this.tipo_moneda = value;
  }

  /**
   * This is a private function in TypeScript that sets the attention_id property to a given value.
   * @param value - The value that will be assigned to the `attention_id` property.
   */
  public setAttentionTo(value) :void {
    this.attention_id = value;
  }

  /**
   * This function saves supplier data to a requisition form using a POST request and displays error
   * messages if necessary.
   */
  public saveSupplier() :void {
    this.loader = 1;
    let form = new FormData();
    form.append("id_requisicion", this.id_requisicion);
    form.append("id_proveedor", this.id_proveedor);
    form.append("nota", this.notes);
    form.append("tipo_moneda", this.tipo_moneda.toString());
    this.requisitionService.saveSupplier(form).subscribe(
      (res) => {
        let data: any = [];
        data = res;
        if (data.code == "0_005") {
          data.data == 1
            ? this.getData()
            : this.lauchModal(data.code, "Ocurrió un arror", "");
        } else this.lauchModal(data.code, data.message, "");
        this.loader = 2;
      },
      (err) => {
        this.loader = 2;
        this.lauchModal("0000x00", "Error de consulta", err.message);
        console.log(err);
      }
    );
  }

  /**
   * This function saves edits made to a requisition form.
   * @param tipo - The "tipo" parameter is a number that is used to determine the type of action to be
   * taken after the requisition is saved. If "tipo" is equal to 1, the user will be redirected to the
   * requisition details page. Otherwise, nothing will happen.
   */
  public saveEditRequisition(tipo) :void {
    this.loader = 1;
    let form = new FormData();
    form.append("id_requisicion", this.id_requisicion);
    form.append("nota", this.notes);
    form.append("tipo_moneda", this.tipo_moneda.toString());
    form.append("id_attention_to", this.attention_id);
    form.append("id_bill_to", this.bill_to);
    form.append("id_ship_to", this.ship_to.toString());
    form.append("a_nombre", this.a_nombre);
    form.append("direccion", this.direccion);
    form.append("municipio", this.municipio);
    form.append("rs_cliente", this.rs_cliente);

    this.requisitionService.saveEditReq(form).subscribe(
      (res) => {
        let data: any = [];
        data = res;
        if (data.code == "0_005") {
          data.data == 1
            ? tipo == 1
              ? this.router.navigateByUrl(
                  "/administration/requisitions/det/" + this.id_requisicion
                )
              : console.log("")
            : this.lauchModal(data.code, "Ocurrió un arror", "");
        } else this.lauchModal(data.code, data.message, "");
        this.loader = 2;
      },
      (err) => {
        this.loader = 2;
        this.lauchModal("0000x00", "Error de consulta", err.message);
        console.log(err);
      }
    );
  }

  //Edicion de partidas
  /**
   * This is a private function in TypeScript that changes the value of a variable called "edit".
   * @param val - val is a parameter of type boolean that is passed to the function changeToEdit(). It
   * is used to set the value of the variable 'edit' to either true or false, depending on the value of
   * val.
   */
  public changeToEdit(val) :void {
    this.edit = val;
  }

  public editValue(id,event,type) :void {
  }

  public sendToEditPart(value) :void {
  }

  /**
   * This is a private function in TypeScript that sets the value of a concept property.
   * @param val - val is a parameter that represents the value that will be set as the new value for
   * the concept property.
   */
  public setConcept(val) :void {
    this.concept = val;
  }

  /**
   * This function saves a new part by sending a form data object to the server and handling the
   * response.
   */
  private saveNewPart() :void {
    console.log(this.concept);
    this.loader = 1;
    let form = new FormData();
    form.append("concept", this.concept.toString());
    form.append("price", this.price_part.toString());
    form.append("cantidad", this.quantity_part.toString());
    form.append("description", this.desc_part.toString());
    form.append("notes", this.notes_part.toString());
    form.append("tag", this.tag.toString());
    form.append("marca", this.marca.toString());
    form.append("modelo", this.modelo.toString());
    form.append("serie", this.serie.toString());
    form.append("id_requisicion", this.id_requisicion);

    let response: any = [];
    this.requisitionService.addPartRequisicion(form).subscribe(
      (res) => {
        response = res;
        if (response.code != "0_005")
          this.lauchModal(response.code, response.message, "");
        else {
          response.data == 1
            ? this.getData()
            : this.lauchModal(
                response.code,
                "Ocurrió un error al guardar la partida",
                ""
              );
        }
        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", err.message);
        console.log(err);
        this.loader = 0;
      }
    );
  }

  public confirmDeletePart(id) :void {
  }

  public sendDeletePart() :void {
  }

  /**
   * This function launches a modal with a given code, title, and message.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - The title of the modal that will be displayed.
   * @param message - The message parameter is likely a string that contains some information or
   * instructions to be displayed in the modal. It will be passed as a property to the ModalComponent
   * instance created by the modalService.
   */
  private async lauchModal(code: string, title: string, message) :Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }

  /**
   * This is a private function that opens a modal component with a title and message.
   * @param {string} title - A string representing the title of the modal window that will be
   * displayed.
   * @param message - The message parameter is a string that contains the message to be displayed in
   * the modal dialog box.
   */
  public infoUser(title: string, message) :void {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.another_cause = true;
    modalRef.componentInstance.code = "NA";
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }

  
}
