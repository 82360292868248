import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../variables/enviroment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  uri:string = environment.uri;
  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');

  private alertSource = new Subject();
  alert$ = this.alertSource.asObservable();

  constructor(private httpClient:HttpClient) { }

  showAlert(title:string, description:string, time:number = 5000){
    this.alertSource.next({title,description,time})
  }

  saveToken(){
    return this.httpClient.get(this.uri+"save_token_user_nav?api_key="+this.api_key+"&token="+this.token+"&module=105");
  }

  getUserInterno(){
    return this.httpClient.get(this.uri+"get_users_permissions_notifications?api_key="+this.api_key+"&token="+this.token+"&module=14");
  }

  assingPermission(tipo,id_usuario){
    return this.httpClient.get(this.uri+"get_to_assign_permission_notification?api_key="+this.api_key+"&token="+this.token+"&module=152&tipo="+tipo+"&id_usuario_permiso="+id_usuario);
  }

}
