import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { IndicatorService } from "src/app/services/indicator.service";

@Component({
  selector: "app-marathons",
  templateUrl: "./marathons.component.html",
  styleUrls: ["./marathons.component.css"],
})
export class MarathonsComponent implements OnInit {
  loader: number = 0;
  response: any = [];
  list: any = [];

  constructor(
    private indicators_service: IndicatorService,
    private modalHelper: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  /**
   * This function retrieves data from a service and handles different response codes.
   */
  private getData() :void {
    this.loader = 1;
    this.indicators_service.getAllMarathons().subscribe(
      (res) => {
        this.loader = 0;
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.list = this.response.data;
        }
      },
      (err) => {
        this.loader = 0;
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
      }
    );
  }

  /**
   * This is an asynchronous function that launches a modal with a given code and title using a modal
   * helper.
   * @param {string} code - A string representing some code that will be displayed in the modal window.
   * @param {string} title - The title parameter is a string that represents the title of the modal
   * that will be launched. It will be displayed at the top of the modal to provide context to the
   * user.
   */
  private async lauchModal(code: string, title: string) :Promise<void> {
    const modalRef = await this.modalHelper.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }
}
