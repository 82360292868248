import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/app/variables/enviroment';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  uri:string = environment.uri;
  //uri:string = "http://ngdat.ucal.com.mx/api/";

  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');
  
  constructor(private httpClient:HttpClient) { }

  
  getEventList(){
    return this.httpClient.get(this.uri+"get_events_list?api_key="+this.api_key+"&token="+this.token+"&module=20");
  }

  getEventDetail(id){
    return this.httpClient.get(this.uri+"get_event_detail?api_key="+this.api_key+"&token="+this.token+"&module=32&id_event="+id);
  }

  setReport(data){
    return this.httpClient.post(this.uri+"save_report_instrument?api_key="+this.api_key+"&token="+this.token+"&module=32",data);
  }

  getEventsHistory(id){
    return this.httpClient.get(this.uri+"get_event_history?api_key="+this.api_key+"&token="+this.token+"&module=51&id="+id);
  }
  
  changeEstatusPart(id, estatus, modulo){
    return this.httpClient.get(this.uri+"set_estatus_partida_new?api_key="+this.api_key+"&token="+this.token+"&module="+modulo+"&id_part="+id+"&new_estatus="+estatus);
  }

  rechazarInforme(id_evt, comments){
    return this.httpClient.get(this.uri+"rechazar_informe?api_key="+this.api_key+"&token="+this.token+"&module=78&id_evt_dt="+id_evt+"&coments="+comments);
  }

  aceptarInformeRechazado(id_inf_rec){
    return this.httpClient.get(this.uri+"aceptar_informe_rechazado?api_key="+this.api_key+"&token="+this.token+"&module=86&id_inf_rec="+id_inf_rec);
  }

  getListaInformesRechazados(){
    return this.httpClient.get(this.uri+"get_lista_informes_rechazados?api_key="+this.api_key+"&token="+this.token+"&module=79");
  }

  deleteAllIReport(){
    return this.httpClient.get(this.uri+"delete_aceptados_resubidos?api_key="+this.api_key+"&token="+this.token+"&module=79");
  }

  uploadXLSXToPart(data){
    return this.httpClient.post(this.uri+"upload_xlsx_event_part?api_key="+this.api_key+"&token="+this.token+"&module=83",data);
  }

  changePartsOdv(id_odv,list_part){
    return this.httpClient.post(this.uri+"change_parts_odv?api_key="+this.api_key+"&token="+this.token+"&module=104&id_odv="+id_odv,list_part);
  }

  downloadZIPReports(id_odv){
    return this.httpClient.get(this.uri+"get_zip_reports_from_event?api_key="+this.api_key+"&token="+this.token+"&module=119&id_event="+id_odv);
  }

  removeReportOperaciones(data){
    return this.httpClient.post(this.uri+"remove_report?api_key="+this.api_key+"&token="+this.token+"&module=122",data);
  }

  removeReportAdmin(data){
    return this.httpClient.post(this.uri+"remove_report?api_key="+this.api_key+"&token="+this.token+"&module=123",data);
  }

  searchUserBajaInforme(data){
    return this.httpClient.post(this.uri+"search_user_delete_informe?api_key="+this.api_key+"&token="+this.token+"&module=130",data);
  }

  removeXlsxInforme(data){
    return this.httpClient.post(this.uri+"remove_xlsx_parts?api_key="+this.api_key+"&token="+this.token+"&module=147",data);
  }

  searchUser(data){
    return this.httpClient.post(this.uri+"search_user_delete_informe?api_key="+this.api_key+"&token="+this.token+"&module=82",data);
  }

  uploadFilesReports(data){
    return this.httpClient.post(this.uri+"upload_cumulus_reports?api_key="+this.api_key+"&token="+this.token+"&module=32",data);
  }

  uploadFilesReportsXlsx(data){
    return this.httpClient.post(this.uri+"upload_massive_reports_xlsx?api_key="+this.api_key+"&token="+this.token+"&module=32",data);
  }

  generateLabelsEvent(data) {
    return this.httpClient.post(this.uri+"generate_xslx_labels?api_key="+this.api_key+"&token="+this.token+"&module=188",data);
  }
  
}