import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { DataAnaliticsService } from "src/app/services/data-analitics.service";
import { environment } from "src/app/variables/enviroment";
@Component({
  selector: "app-analitics",
  templateUrl: "./analitics.component.html",
  styleUrls: ["./analitics.component.css"],
})
export class AnaliticsComponent implements OnInit {
  permmisions = JSON.parse(localStorage.getItem("permisos"));
  loader = 0;
  reportType = 1;
  response: any = [];
  data: any = [];
  data_for_odv: any = [];
  total_price_odvs = 0;

  initial_date = "0";
  final_date = "0";

  reports = [
    { id: "1", option: "Porcentaje de Rechazo de Informes Por Metrólogo" },
    { id: "2", option: "Tiempo de Autorización de Informe" },
    //{'id':'3','option':'Reporte de ODV'},
    { id: "4", option: "Informes revisados por que usuario"},
    { id: "5", option: "Reporte informes autorizados y removidos"}
  ];

  month_list = [
    { id: "1", mes: "Enero" },
    { id: "2", mes: "Febrero" },
    { id: "3", mes: "Marzo" },
    { id: "4", mes: "Abril" },
    { id: "5", mes: "Mayo" },
    { id: "6", mes: "Junio" },
    { id: "7", mes: "Julio" },
    { id: "8", mes: "Agosto" },
    { id: "9", mes: "Septiembre" },
    { id: "10", mes: "Octubre" },
    { id: "11", mes: "Noviembre" },
    { id: "12", mes: "Diciembre" },
  ];

  share_with = "";
  moth_selected = "";

  constructor(
    private dataAnaliticsService: DataAnaliticsService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  /**
   * The function loads data based on the report type selected.
   */
  
  private loadData(): void {
    if (this.reportType == 1) this.loadPercentDeclined(0);
    else if (this.reportType == 2) this.loadTimeToAcceptReport();
  }

 /**
  * This is a private function in TypeScript that sets the report type.
  * @param val - val is a parameter that represents the value that will be assigned to the reportType
  * property. The data type of val is not specified in the code snippet, but it should match the data
  * type of the reportType property.
  */
  public setReportType(val): void {
    this.reportType = val;
  }

  

  /**
   * The function loads a percent declined report based on the report type and date range, and sorts
   * the data in descending order if the report type is 0.
   * @param report - The report parameter is a variable that is used to determine which type of report
   * to generate. It is passed as an argument to the getPercentDeclinedByMetro method of the
   * dataAnaliticsService. The value of this parameter is either 0 or 1. If it is 0, the
   */
  public loadPercentDeclined(report): void {
    if (this.reportType == 1) {
      this.loader = 1;
      this.dataAnaliticsService
        .getPercentDeclinedByMetro(report, this.initial_date, this.final_date)
        .subscribe(
          (res) => {
            this.response = res;

            if (this.response.code != "0_005")
              this.lauchModal(this.response.code, this.response.message, "");
            else {
              if (report == 0) {
                this.data = this.response.data;
                var aux;
                for (var i = 0; i < this.data.length - 1; i++) {
                  for (var j = 0; j < this.data.length - i - 1; j++) {
                    if (
                      Number(this.data[j + 1].porc_rec) >
                      Number(this.data[j].porc_rec)
                    ) {
                      aux = this.data[j + 1];
                      this.data[j + 1] = this.data[j];
                      this.data[j] = aux;
                    }
                  }
                }
              } else this.downloadFile(this.response.data);
            }
            this.loader = 0;
          },
          (err) => {
            this.lauchModal("0000x00", "Error de consulta", err.message);
            console.log(err);
            this.loader = 0;
          }
        );
    } else this.loadODVReport();
  }


  public downloadReportsRevisationByUser(report){
    //estatus 0 es creado / rechazado
    //estatus 2 es revisado
    //estatus 3 esautorizado
    if (this.reportType == 4) {
      this.loader = 1;
      this.dataAnaliticsService
        .getReportsRevisationsByUsersXLSX(this.initial_date, this.final_date)
        .subscribe(
          (res) => {
            this.response = res;

            if (this.response.code != "0_005")
              this.lauchModal(this.response.code, this.response.message, "");
            else {
              if (report == 0) {
                this.data = this.response.data;
              } else this.downloadFile(this.response.data);
            }
            this.loader = 0;
          },
          (err) => {
            this.lauchModal("0000x00", "Error de consulta", err.message);
            console.log(err);
            this.loader = 0;
          }
        );
    } else this.loadODVReport();
  }


  /**
   * This function downloads an XLSX file containing ODV data for a selected month.
   */
  public downloadODV_Facts_XSLXMonth(): void {
    this.loader = 1;
    this.dataAnaliticsService
      .getOdvDataXLSXByMonth(this.moth_selected, this.share_with)
      .subscribe(
        (res) => {
          this.response = res;
          if (this.response.code != "0_005")
            this.lauchModal(this.response.code, this.response.message, "");
          else {
            var name_xlsx = this.response.data;
            if (name_xlsx == 0)
              this.lauchModal("0_007", this.response.message, "");
            else this.downloadFile(this.response.data);
          }
          this.loader = 0;
        },
        (err) => {
          this.lauchModal("0000x00", "Error de consulta", err.message);
          console.log(err);
          this.loader = 0;
        }
      );
  }

 /**
  * This function downloads an XLSX file containing ODV data based on a specified date range and
  * sharing criteria.
  */
  public downloadODV_Facts_XSLXList(): void {
    this.loader = 1;
    this.dataAnaliticsService
      .getODVDataXLSXByList(this.initial_date, this.final_date, this.share_with)
      .subscribe(
        (res) => {
          this.response = res;
          if (this.response.code != "0_005")
            this.lauchModal(this.response.code, this.response.message, "");
          else {
            var name_xlsx = this.response.data;
            if (name_xlsx == 0)
              this.lauchModal("0_007", this.response.message, "");
            else this.downloadFile(this.response.data);
          }
          this.loader = 0;
        },
        (err) => {
          this.lauchModal("0000x00", "Error de consulta", err.message);
          console.log(err);
          this.loader = 0;
        }
      );
  }

  /**
   * This function loads ODV report data and calculates the total price of ODVs within a given date
   * range.
   */
  private loadODVReport(): void {
    this.total_price_odvs = 0;
    this.loader = 1;
    this.dataAnaliticsService
      .getOdvData(this.initial_date, this.final_date)
      .subscribe(
        (res) => {
          this.response = res;
          console.log(this.response);
          if (this.response.code != "0_005")
            this.lauchModal(this.response.code, this.response.message, "");
          else {
            this.data_for_odv = this.response.data;
            this.data_for_odv.forEach((v) => {
              this.total_price_odvs += v.price;
            });
          }

          this.loader = 0;
        },
        (err) => {
          this.lauchModal("0000x00", "Error de consulta", err.message);
          console.log(err);
          this.loader = 0;
        }
      );
  }

  /**
   * This function loads the time it takes to accept reports and handles the response accordingly.
   */
  public loadTimeToAcceptReport(): void {
    this.loader = 1;
    this.dataAnaliticsService.getTimeToAcceptReports().subscribe(
      (res) => {
        this.response = res;

        if (this.response.code != "0_005")
          this.lauchModal(this.response.code, this.response.message, "");
        else this.downloadFile(this.response.data);

        console.log(this.data);
        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", err.message);
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * This function downloads a file from a specific URL and opens it in a new window.
   * @param name - The name of the file to be downloaded.
   */
  private downloadFile(name): void {
    var url =
      environment.uri_server +
      "storage/public/plantillas_reportes_xslx/generados/" +
      name;
    window.open(url, "_blank");
  }

  /**
   * This function sets the selected month value in a TypeScript class.
   * @param value - The value parameter is the new value that will be assigned to the month_selected
   * property.
   */
  public setMonth(value): void {
    this.moth_selected = value;
  }

  /**
   * This is a private async function that launches a modal with a given code, title, and message using
   * the ModalComponent.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - A string representing the title of the modal window that will be launched.
   * @param message - The message parameter is a string that represents the message to be displayed in
   * the modal dialog box.
   */
  private async lauchModal(
    code: string,
    title: string,
    message
  ): Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
  
}
