import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { INews } from './models/INews';
import { environment } from 'src/app/variables/enviroment';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  uri:string = environment.uri;
  //uri:string = "http://ngdat.ucal.com.mx/api/";

  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');

  constructor(private httpClient: HttpClient) { }

  getNews(){  
    
    return this.httpClient.get(this.uri+"get_all_news?api_key="+this.api_key+"&token="+this.token+"&module=23");
  }

  getNew(idnew){  
    
    return this.httpClient.get(this.uri+"get_detail_new?api_key="+this.api_key+"&token="+this.token+"&module=25&new_id="+idnew);
  }

  postNew(inew: INews){
    
      return this.httpClient.post(this.uri+"post_new?api_key="+this.api_key+"&token="+this.token+"&module=24",inew);
  }

  sendMailAny(titulo, mensaje, destinatarios, id_auxiliar, parametro_extra, tipo, notas){
    return this.httpClient.get(this.uri+"send_mail_from_emails_module?api_key="+this.api_key+"&token="+this.token+"&module=8&destinatarios="+destinatarios+"&id_auxiliar="+id_auxiliar+"&tipo="+tipo+"&titulo="+titulo+"&mensaje="+mensaje+"&notas="+notas+"&parametroextra="+parametro_extra);
  }

  getListEmail(){
    return this.httpClient.get(this.uri+"get_list_email?api_key="+this.api_key+"&token="+this.token+"&module=8");
  }
  

  getUsersList(){  
    
    return this.httpClient.get(this.uri+"get_users_news_list?api_key="+this.api_key+"&token="+this.token+"&module=24");
  }
}
