import { ThrowStmt } from "@angular/compiler";
import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { ServiceReportService } from "src/app/services/service-report.service";

@Component({
  selector: "app-reporte-servicio",
  templateUrl: "./reporte-servicio.component.html",
  styleUrls: ["./reporte-servicio.component.css"],
})
export class ReporteServicioComponent implements OnInit {
  loader: number = 1;
  response: any = [];
  rs_list: any = [];
  permmisions = JSON.parse(localStorage.getItem("permisos"));
  number_page = 0;
  pages = 0;

  constructor(
    private modalService: NgbModal,
    private rs_service: ServiceReportService
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  /**
   * The function loads data from a service and handles different response codes.
   */
  private loadData(): void {
    this.loader = 1;
    this.rs_service.getList().subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.rs_list = this.response.data;
          this.pages = this.rs_list.length;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * The function changes the current page number to the specified page.
   * @param page - The "page" parameter is a variable that represents the page number that the user
   * wants to navigate to. The "changePageResults" function takes this parameter and sets the
   * "number_page" variable to the value of "page". This function is likely used in a pagination system
   * to allow the user to
   */
  public changePageResults(page): void {
    this.number_page = page;
  }

  /**
   * This is a TypeScript function that launches a modal with a given code, title, and message.
   * @param {string} code - a string parameter that represents some code that will be displayed in the
   * modal window.
   * @param {string} title - A string representing the title of the modal window that will be
   * displayed.
   * @param message - The parameter "message" is not being used in the function and therefore its
   * purpose is unclear. It may have been intended to be used as a message to be displayed in the
   * modal, but it is not currently implemented in the code.
   */
  private async lauchModal(
    code: string,
    title: string,
    message
  ): Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }
}
