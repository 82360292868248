import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { VehicleService } from "src/app/services/vehicle.service";
import { environment } from "src/app/variables/enviroment";

@Component({
  selector: "app-detail-exit",
  templateUrl: "./detail-exit.component.html",
  styleUrls: ["./detail-exit.component.css"],
})
export class DetailExitComponent implements OnInit {
  loader = true;
  response: any = [];
  data: any = [];

  vehicle: any = [];
  exit_points: any = [];
  imgs: any = [];

  id = "";

  uri: string = environment.uri_server;

  img_fron: string = "0";
  img_trac: string = "0";
  img_der: string = "0";
  img_izq: string = "0";

  constructor(
    private modalService: NgbModal,
    private vehicleService: VehicleService,
    private activedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.id = this.activedRoute.snapshot.paramMap.get("id");
    this.getData();
  }

  /**
   * This function retrieves data from a vehicle service and assigns it to various variables, while
   * also handling error cases.
   */
  private getData() :void{
    this.loader = true;

    this.vehicleService.getVehicleExit(this.id).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.data = this.response.data;

          this.vehicle = this.data.vehicle;
          this.exit_points = this.data.rev;
          this.imgs = this.data.imgs;

          if (this.imgs.img_frontal != null)
            this.img_fron =
              this.uri +
              "storage/public/salida_vehicle_imgs/" +
              this.imgs.img_frontal;
          if (this.imgs.img_tracera != null)
            this.img_trac =
              this.uri +
              "storage/public/salida_vehicle_imgs/" +
              this.imgs.img_tracera;
          if (this.imgs.img_derecha != null)
            this.img_der =
              this.uri +
              "storage/public/salida_vehicle_imgs/" +
              this.imgs.img_derecha;
          if (this.imgs.img_izquierda != null)
            this.img_izq =
              this.uri +
              "storage/public/salida_vehicle_imgs/" +
              this.imgs.img_izquierda;
        }

        this.loader = false;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = false;
      }
    );
  }

  /**
   * This is an asynchronous function that launches a modal with a given code and title using a modal
   * service.
   * @param {string} code - The code parameter is a string that represents the code that will be
   * displayed in the modal.
   * @param {string} title - The title parameter is a string that represents the title of the modal
   * that will be launched. It will be displayed at the top of the modal to provide context to the
   * user.
   */
  private async lauchModal(code: string, title: string) :Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }
}
