import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { VehicleService } from "src/app/services/vehicle.service";

@Component({
  selector: "app-exit-vehicle",
  templateUrl: "./exit-vehicle.component.html",
  styleUrls: ["./exit-vehicle.component.css"],
})
export class ExitVehicleComponent implements OnInit {
  id = "";
  loader = false;
  response: any = [];
  list: any = [];

  date_request = "";
  requested_by = "0";
  km = "";
  gas_level = "";
  notes = "";

  exit_points = {
    checkBoxDriverCard: false,
    checkBoxFrontLight: false,
    checkBoxCurrentInsurance: false,
    checkBoxAntenna: false,
    checkBoxOilLevel: false,
    checkBoxRefactionTire: false,
    checkBoxRugs: false,
    checkBoxCirculationCard: false,
    checkBoxBehindLights: false,
    checkBoxRadio: false,
    checkBoxTirePressure: false,
    checkBoxWheelCaps: false,
    checkBoxCrossPiece: false,
    checkBoxInternalCleaning: false,
  };

  // Variables para imagenes
  imagePathFrontal = null;
  img_url_frontal: any;

  imagePathBehind = null;
  img_url_behind: any;

  imagePathRight = null;
  img_url_right: any;

  imagePathLeft = null;
  img_url_left: any;

  constructor(
    private modalService: NgbModal,
  /**
   * This is a private asynchronous function that launches a modal with a given code and title using a
   * modal service.
   * @param {string} code - a string representing the code that will be displayed in the modal window.
   * @param {string} title - The title parameter is a string that represents the title of the modal
   * that will be launched. It will be displayed at the top of the modal to provide context to the
   * user.
   */
    private vehicleService: VehicleService,
    private activedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.id = this.activedRoute.snapshot.paramMap.get("id");
    this.getUsersList();
  }

  /**
   * The function retrieves a list of users from a vehicle service and handles different response codes
   * accordingly.
   */
  private getUsersList() :void {
    this.loader = true;

    this.vehicleService.getUsersList().subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message);
        else this.list = this.response.data;

        this.loader = false;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = false;
      }
    );
  }

  /**
   * The function previews uploaded images and sets their URLs based on their position.
   * @param files - an array of files that the user has selected for previewing
   * @param pos - pos is a parameter that represents the position of the image being previewed. It is
   * an integer value that can take on values from 0 to 3, where 0 represents the frontal image, 1
   * represents the behind image, 2 represents the right image, and 3 represents the left
   */
  public previewImg(files, pos) :void {
    var reader = new FileReader();
    if (pos == 0) {
      this.imagePathFrontal = files[0];
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
        this.img_url_frontal = reader.result;
      };
    }

    if (pos == 1) {
      this.imagePathBehind = files[0];
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
        this.img_url_behind = reader.result;
      };
    }

    if (pos == 2) {
      this.imagePathRight = files[0];
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
        this.img_url_right = reader.result;
      };
    }

    if (pos == 3) {
      this.imagePathLeft = files[0];
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
        this.img_url_left = reader.result;
      };
    }
  }

  /**
   * This function saves a new exit for a vehicle with various data and images.
   */
  public saveExit() :void {
    this.loader = true;
    let data = new FormData();
    data.append("vehicle", this.id);
    data.append("date", this.date_request);
    data.append("user", this.requested_by);
    data.append("km", this.km);
    data.append("gas", this.gas_level);
    data.append("notes", this.notes);
    data.append(
      "checkBoxDriverCard",
      this.exit_points["checkBoxDriverCard"] == true ? "1" : "0"
    );
    data.append(
      "checkBoxFrontLight",
      this.exit_points["checkBoxFrontLight"] == true ? "1" : "0"
    );
    data.append(
      "checkBoxCurrentInsurance",
      this.exit_points["checkBoxCurrentInsurance"] == true ? "1" : "0"
    );
    data.append(
      "checkBoxAntenna",
      this.exit_points["checkBoxAntenna"] == true ? "1" : "0"
    );
    data.append(
      "checkBoxOilLevel",
      this.exit_points["checkBoxOilLevel"] == true ? "1" : "0"
    );
    data.append(
      "checkBoxRefactionTire",
      this.exit_points["checkBoxRefactionTire"] == true ? "1" : "0"
    );
    data.append(
      "checkBoxRugs",
      this.exit_points["checkBoxRugs"] == true ? "1" : "0"
    );
    data.append(
      "checkBoxCirculationCard",
      this.exit_points["checkBoxCirculationCard"] == true ? "1" : "0"
    );
    data.append(
      "checkBoxBehindLights",
      this.exit_points["checkBoxBehindLights"] == true ? "1" : "0"
    );
    data.append(
      "checkBoxRadio",
      this.exit_points["checkBoxRadio"] == true ? "1" : "0"
    );
    data.append(
      "checkBoxTirePressure",
      this.exit_points["checkBoxTirePressure"] == true ? "1" : "0"
    );
    data.append(
      "checkBoxWheelCaps",
      this.exit_points["checkBoxWheelCaps"] == true ? "1" : "0"
    );
    data.append(
      "checkBoxCrossPiece",
      this.exit_points["checkBoxCrossPiece"] == true ? "1" : "0"
    );
    data.append(
      "checkBoxInternalCleaning",
      this.exit_points["checkBoxInternalCleaning"] == true ? "1" : "0"
    );

    data.append(
      "frontal_img",
      this.imagePathFrontal == null ? "0" : this.imagePathFrontal
    );
    data.append(
      "behind_img",
      this.imagePathBehind == null ? "0" : this.imagePathBehind
    );
    data.append(
      "left_img",
      this.imagePathLeft == null ? "0" : this.imagePathLeft
    );
    data.append(
      "right_img",
      this.imagePathRight == null ? "0" : this.imagePathRight
    );

    this.vehicleService.saveNewExit(data).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.router.navigateByUrl("/operations/vehicle/detail/" + this.id);
        }
        this.loader = false;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = false;
      }
    );
  }

 /**
  * The function updates the gas level based on the value of a range input.
  * @param event - The event parameter is an object that represents an event that occurred in the
  * browser, such as a user interacting with an input element. In this case, it is likely an event that
  * is triggered when the user changes the value of an input element, and the function is using the
  * event object to retrieve the
  */
 public changeRange(event) :void {
    this.gas_level = event.target.value;
  }

  /**
   * This function verifies if certain fields have been filled out before allowing the user to save and
   * exit.
   * @returns A boolean value is being returned, either true or false.
   */
  public verifySaveExit() :boolean {
    if (this.requested_by != "0" && this.km != "" && this.gas_level != "")
      return true;
    else return false;
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code and title using
   * the ModalComponent.
   * @param {string} code - A string representing the code that will be displayed in the modal window.
   * @param {string} title - The title parameter is a string that represents the title of the modal
   * that will be launched. It will be displayed at the top of the modal to provide context to the
   * user.
   */
  private async lauchModal(code: string, title: string) :Promise<void>{
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

}
