import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Chart } from "chart.js";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { IndicatorService } from "src/app/services/indicator.service";
import { environment } from "src/app/variables/enviroment";

@Component({
  selector: "app-detail-marathon",
  templateUrl: "./detail-marathon.component.html",
  styleUrls: ["./detail-marathon.component.css"],
})
export class DetailMarathonComponent implements OnInit {
  response: any = [];
  detail: any = [];
  info_met: any = [];
  total_reports: any = 0;
  loader = 1;
  id_marathon: string = "";

  first_user = {
    id: "",
    inf: "",
    name: "",
    img: "",
  };
  second_user = {
    id: "",
    inf: "",
    name: "",
    img: "",
  };
  third_user = {
    id: "",
    inf: "",
    name: "",
    img: "",
  };

  url = environment.uri_server + "storage/public/fotos_metrologos/";

  metro: any;
  chart_metro: any;

  zones: any;
  chart_zone: any;
  constructor(
    private activedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private indicatorSer: IndicatorService
  ) {}

  ngOnInit(): void {
    this.metro = document.getElementById("chart_metro");
    this.zones = document.getElementById("chart_zone");

    this.id_marathon = this.activedRoute.snapshot.paramMap.get("id");

    this.loadGeneralData();
    this.loadZoneChart();
  }

  /**
   * This function loads general data for a marathon and displays it in a chart.
   */
  private loadGeneralData() :void {
    this.loader = 1;
    this.indicatorSer.getMarathonDetail(this.id_marathon).subscribe(
      (res) => {
        this.response = res;
        this.loader = 2;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.detail = this.response.data;
          this.info_met = this.detail.metro;

          this.first_user.id = this.info_met[0].id_usuario;
          this.first_user.name = this.info_met[0].usuario;
          this.first_user.inf = this.info_met[0].informes_cargados;
          if (this.info_met[0].img == 1)
            this.first_user.img = this.info_met[0].id_usuario;
          else this.first_user.img = "default";

          this.second_user.id = this.info_met[1].id_usuario;
          this.second_user.name = this.info_met[1].usuario;
          this.second_user.inf = this.info_met[1].informes_cargados;
          if (this.info_met[1].img == 1)
            this.second_user.img = this.info_met[1].id_usuario;
          else this.second_user.img = "default";

          this.third_user.id = this.info_met[2].id_usuario;
          this.third_user.name = this.info_met[2].usuario;
          this.third_user.inf = this.info_met[2].informes_cargados;
          if (this.info_met[1].img == 2)
            this.third_user.img = this.info_met[2].id_usuario;
          else this.third_user.img = "default";

          this.loadMetrologistChart();
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This function loads a bar chart with data on the number of reports loaded by each metrologist.
   */
  private loadMetrologistChart() :void {
    var metro_names = [];
    var metro_values = [];

    for (var i = 0; i < this.info_met.length; i++) {
      metro_names.push(this.info_met[i].usuario);
      metro_values.push(this.info_met[i].informes_cargados);

      this.total_reports += this.info_met[i].informes_cargados;
    }

    this.chart_metro = new Chart(this.metro, {
      type: "bar",
      data: {
        labels: metro_names,
        datasets: [
          {
            label: "Informes",
            data: metro_values,
            borderColor: "rgb(255, 0, 0)",
            backgroundColor: "rgba(255, 0, 0,0.2)",
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: false,
          },
        },
      },
    });
  }

  /**
   * The function creates a pie chart using Chart.js library with data and options specified.
   */
  private loadZoneChart() :void {
    this.chart_zone = new Chart(this.zones, {
      type: "pie",
      data: {
        labels: ["MTY", "RA", "SLP"],
        datasets: [
          {
            data: [133, 86, 52],
            backgroundColor: ["#FF6384", "#6384FF", "#84FF63"],
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: false,
          },
        },
      },
    });
  }

  /**
   * This is a private function that changes the status of a marathon and handles the response
   * accordingly.
   * @param status - The parameter "status" is of type "any" and is used to specify the new status of a
   * marathon.
   */
  private changeStatus(status) :void {
    var resp: any = [];
    this.loader = 1;
    this.indicatorSer.setStatusMarathon(this.id_marathon, status).subscribe(
      (res) => {
        resp = res;
        this.loader = 2;
        if (resp.code != "0_005") this.lauchModal(resp.code, resp.message);
        else {
          this.loadGeneralData();
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code and title using
   * the ModalComponent.
   * @param {string} code - The code parameter is a string that represents the code that will be
   * displayed in the modal.
   * @param {string} title - The title parameter is a string that represents the title of the modal
   * that will be launched. It will be displayed at the top of the modal to provide context to the
   * user.
   */
  private async lauchModal(code: string, title: string) :Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }
}
