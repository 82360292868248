import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { QuotationService } from "src/app/services/quotation.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";

@Component({
  selector: "app-cot-add-client",
  templateUrl: "./cot-add-client.component.html",
  styleUrls: ["./cot-add-client.component.css"],
})
export class CotAddClientComponent implements OnInit {
  permmisions = JSON.parse(localStorage.getItem("permisos"));
  id_cot = "";
  loader = 2;

  //Datos generales
  abreviatura = "";
  nombre = "";
  rfc = "";
  moneda = "1";
  pago = "3";

  //Datos de direccion
  razondir = "";
  calle = "";
  colonia = "";
  numero = "";
  ciudad = "";
  estado = "";
  cp = "";

  constructor(
    private activedRoute: ActivatedRoute,
    private quotationService: QuotationService,
    private modalService: NgbModal,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.id_cot = this.activedRoute.snapshot.paramMap.get("id_cot");
  }

  /**
   * The function checks if all required fields are filled and returns a boolean value.
   * @returns A boolean value is being returned. If all the specified properties are not empty strings,
   * the function will return true. Otherwise, it will return undefined.
   */
  public showButton(): boolean {
    if (
      this.abreviatura != "" &&
      this.nombre != "" &&
      this.rfc != "" &&
      this.razondir != "" &&
      this.calle != "" &&
      this.colonia != "" &&
      this.numero != "" &&
      this.ciudad != "" &&
      this.estado != "" &&
      this.cp != ""
    )
      return true;
  }

  /**
   * This function saves client data to a server and launches a modal with a response message.
   */
  public saveClient(): void {
    this.loader = 1;
    let data = new FormData();
    data.append("id_cot", this.id_cot);

    data.append("alias", this.abreviatura);
    data.append("name", this.nombre);
    data.append("rfc", this.rfc);
    data.append("coin_type", this.moneda);
    data.append("payment_method", this.pago);

    data.append("social_reason", this.razondir);
    data.append("street", this.calle);
    data.append("colony", this.colonia);
    data.append("ext", this.numero);
    data.append("city", this.ciudad);
    data.append("state", this.estado);
    data.append("cp", this.cp);

    this.quotationService.saveNewClient(data).subscribe(
      (res) => {
        let response: any = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004" || response.code == "0_0007")
          this.lauchModal(response.code, response.message, "");
        else {
          this.route.navigateByUrl("/commercial/cot/det/" + this.id_cot);
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This is an asynchronous function that launches a modal with a given code, title, and message using
   * a modal service.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - The title of the modal that will be displayed.
   * @param message - The message parameter is likely a string that contains the content to be
   * displayed in the body of the modal dialog box. It could be a message to the user or some other
   * information that needs to be communicated.
   */
  private async lauchModal(
    code: string,
    title: string,
    message
  ): Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
  
}
