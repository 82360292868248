import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserService } from "src/app/services/user.service";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { VacationsService } from "src/app/services/vacations.service";
import { NGB_DATEPICKER_PARSER_FORMATTER_FACTORY } from "@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-parser-formatter";

@Component({
  selector: "app-vacations",
  templateUrl: "./vacations.component.html",
  styleUrls: ["./vacations.component.css"],
})
export class VacationsComponent implements OnInit {
  id_user: string = "";
  user_image: string = "assets/img/theme/user_default.png";

  response: any = [];
  data: any = [];
  loader: number = 0;
  users: any = [];

  requests: any = [];

  mostrar = 0;

  //DATOS ENVIADOS
  no_empleado = "";
  f_ingreso = "";
  dias_disp = "";
  dias_rest = "";
  id_user_det = "";
  id_control = "";
  nuevo = "0";

  //SOLICITUDES
  dias_disponibles = 0;
  no_dias: number = 0;

  private currentDate = new Date();
  disable_save: boolean = true;
  disabledEditing = true;

  constructor(
    private userService: UserService,
    private modalService: NgbModal,
    private vacationService: VacationsService
  ) {}

  ngOnInit(): void {
    this.getVacRequests();
    this.obtenerUsuariosInternos();
  }

  /**
   * The function toggles the value of a variable and calls another function to obtain internal users.
   */
  public mostrarSinFolio() :void {
    if (this.mostrar == 0) {
      this.mostrar = 1;
    } else {
      this.mostrar = 0;
    }
    this.obtenerUsuariosInternos();
  }

  /**
   * The function toggles between enabling and disabling editing mode for a user detail form.
   */
  public editUserDetail() :void {
    if (this.disabledEditing == true) {
      //editar
      this.disabledEditing = false;
    } else {
      // cancelar
      this.disable_save = true;
      this.disabledEditing = true;
      this.getInternDetail(this.id_user_det);
    }
  }

  /**
   * This function enables the save button if it was previously disabled.
   */
  public ableToSave() :void {
    if (this.disable_save == true) {
      this.disable_save = false;
    }
  }

  /**
   * This function enables saving dates if the disable_save flag is false and calls another function.
   */
  public ableToSaveDate() :void {
    if (this.disable_save == true) {
      this.disable_save = false;
    }
    this.authorizedDaysPerYears();
  }
  /*
   validateYear() {
    let year = this.currentDate.getFullYear();
    let initialDate = new Date('2022-01-01');

    let diferencia = this.currentDate.getTime() - initialDate.getTime();
    let cursado = diferencia / 1000 / 60 / 60 / 24;
    cursado = Math.round(cursado);

    if(cursado >= 365) {
      //initialDate = new Date(year.toString()+'-01-01');
      this.updateVacationInfo();
    }
  }

  updateVacationInfo() {
    let response:any;
    this.vacationService.updateAntiquity().subscribe(
      res=>{
        response = res;
        if(response.code == '1_0001' || response.code == '1_0002' || response.code == '1_0003')this.lauchModal(response.code,response.message,'');
        
        else if(response.code == '1_0004' || response.code == '0_0007')this.lauchModal(response.code,response.message,'');
        else {
          alert("Año Actualizado");
        }
        this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta','');
        this.loader = 2;
      }
    );
  } */

  /**
   * This function calculates the number of authorized days off per year based on the employee's length
   * of service.
   */
  private authorizedDaysPerYears() :void {
    let dias;
    let fecha2;

    if (this.f_ingreso != null) {
      fecha2 = new Date(this.f_ingreso);
      let diferencia = this.currentDate.getTime() - fecha2.getTime();
      let diasLaborados = diferencia / 1000 / 60 / 60 / 24;
      diasLaborados = Math.round(diasLaborados);

      if (diasLaborados < 365) {
        dias = 0;
      } else if (diasLaborados >= 365 && diasLaborados < 365 * 2) {
        dias = 12;
      } else if (diasLaborados >= 365 * 2 && diasLaborados < 365 * 3) {
        dias = 14;
      } else if (diasLaborados >= 365 * 3 && diasLaborados < 365 * 4) {
        dias = 16;
      } else if (diasLaborados >= 365 * 4 && diasLaborados < 365 * 5) {
        dias = 18;
      } else if (diasLaborados >= 365 * 5 && diasLaborados < 365 * 6) {
        dias = 20;
      } else if (diasLaborados >= 365 * 6 && diasLaborados < 365 * 11) {
        dias = 22;
      } else if (diasLaborados >= 365 * 11 && diasLaborados < 365 * 16) {
        dias = 24;
      } else if (diasLaborados >= 365 * 16 && diasLaborados < 365 * 21) {
        dias = 24;
      } else if (diasLaborados >= 365 * 16 && diasLaborados < 365 * 21) {
        dias = 26;
      } else if (diasLaborados >= 365 * 21 && diasLaborados < 365 * 26) {
        dias = 28;
      } else if (diasLaborados >= 365 * 26 && diasLaborados < 365 * 31) {
        dias = 30;
      } else if (diasLaborados >= 365 * 31) {
        dias = 32;
      } else {
        dias = 0;
      }

      this.dias_disp = dias;
    } else {
      this.f_ingreso = "";
    }
  }

  /**
   * The function obtains internal users on vacation and orders them by employee number.
   */
  private obtenerUsuariosInternos() :void {
    let usuariosInternos: any = [];
    let response: any;
    this.userService.getUserInternosVacation().subscribe(
      (res) => {
        response = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004" || response.code == "0_0007")
          this.lauchModal(response.code, response.message, "");
        else {
          usuariosInternos = response.data;
          this.orderByEmployeeNumber(usuariosInternos);
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * This function sorts an array of internal users by their employee number and filters out any users
   * without an employee number.
   * @param usuariosInternos - an array of objects representing internal users, each object containing
   * properties such as id_empleado (employee number) and empleado (employee name).
   * @returns The function is not returning anything, it is just sorting and filtering an array and
   * assigning the result to the `this.users` property.
   */
  private orderByEmployeeNumber(usuariosInternos) :void {
    this.users = [];
    usuariosInternos.sort(function (a, b) {
      if (a.id_empleado == null) {
        a.id_empleado = "";
      }

      if (b.id_empleado == null) {
        b.id_empleado = "";
      }

      if (a.id_empleado != "" && b.empleado != "") {
        if (a.id_empleado > b.id_empleado) {
          return 1;
        }
        if (a.id_empleado < b.id_empleado) {
          return -1;
        }
        // a must be equal to b
        return 0;
      }
      return -1;
    });

    let filtrados = usuariosInternos.filter((item) => item.id_empleado === "");
    usuariosInternos = usuariosInternos.filter(
      (item) => item.id_empleado != ""
    );

    if (this.mostrar == 1) {
      this.users = usuariosInternos.concat(filtrados);
    } else {
      this.users = usuariosInternos;
    }
  }

  /**
   * This is a private function in TypeScript that retrieves vacation details for a given user ID and
   * handles different response codes accordingly.
   * @param id - The parameter "id" is a variable that represents the ID of an intern whose details are
   * being retrieved.
   */
  private getInternDetail(id) :void {
    let response: any;
    this.id_user_det = id.toString();
    this.vacationService.getUserVacationDetail(this.id_user_det).subscribe(
      (res) => {
        response = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004" || response.code == "0_0007")
          this.lauchModal(response.code, response.message, "");
        else {
          this.data = response.data;

          this.data.forEach((d) => {
            this.no_empleado = d.id_empleado;
            this.dias_disp = d.dias_disponibles;
            this.dias_rest = d.dias_restantes;
            this.f_ingreso = d.fecha_ingreso;
            this.id_control = d.id_control;
          });
        }

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * This function validates and saves intern details.
   */
  public validateInternDetail() :void {
    if (this.no_empleado == null) {
      this.no_empleado = "";
    }
    if (this.id_control == null) {
      this.nuevo = "1";
      this.id_control = "";
    } else {
      this.nuevo = "0";
    }
    if (this.dias_disp != null) {
      this.dias_disp = this.dias_disp.toString();
    } else {
      this.dias_disp = "";
    }

    this.saveInternDetail();
  }

  /**
   * This function saves the details of an employee's vacation information.
   */
  private saveInternDetail() :void {
    let form_d = new FormData();
    form_d.append("id_empleado", this.no_empleado);
    form_d.append("id_control", this.id_control);
    form_d.append("f_ingreso", this.f_ingreso);
    form_d.append("dias_disponibles", this.dias_disp);
    form_d.append("user_detalle", this.id_user_det);
    form_d.append("nuevo", this.nuevo);

    this.vacationService.changeDetailUser(form_d).subscribe(
      (res) => {
        let response: any = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004" || response.code == "0_0007")
          this.lauchModal(response.code, response.message, "");
        else {
          this.obtenerUsuariosInternos();
          this.disabledEditing = true;
          this.disable_save = true;
        }

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * This function retrieves all vacation requests and handles different response codes accordingly.
   */
  private getVacRequests() :void {
    this.vacationService.getAllVacationRequests().subscribe(
      (res) => {
        let response: any = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004" || response.code == "0_0007")
          this.lauchModal(response.code, response.message, "");
        else {
          this.requests = response.data;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * This is a TypeScript function that launches a modal with a given code, title, and message.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - The title of the modal window that will be displayed.
   * @param {string} message - The message parameter is a string that represents the content of the
   * message to be displayed in the modal.
   */
  private async lauchModal(code: string, title: string, message: string) :Promise<void>{
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
}
