import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { GeneratorReportsService } from "src/app/services/generator-reports.service";
import { environment } from "src/app/variables/enviroment";
import { Chart } from "chart.js";
import { IInforme } from "src/app/services/models/IInformes";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-generador-informes-app",
  templateUrl: "./generador-informes-app.component.html",
  styleUrls: ["./generador-informes-app.component.css"],
})
export class GeneradorInformesAppComponent implements OnInit {
  uri: string = environment.uri;
  token = localStorage.getItem("token");
  api_key = localStorage.getItem("key");

  //descripcion, salto, numeroSaltos, identificador, startsIn, tolerancia, dobleGrafica
  variaciones = [
    ["0.00 - 0.50 pulg", 0.1, 6, 0, 0.0, 0.003, false, "Pul."],
    ["20 - 60 mm", 10, 5, 1, 20, 100, true, "mm"],
    ["30 - 70 mm", 10, 5, 2, 30, 100, true, "mm"],
    ["30 - 80 mm", 10, 6, 3, 30, 100, true, "mm"],
    ["50 - 75 mm", 5, 6, 4, 50, 100, true, "mm"],
    ["50 - 90 mm", 5, 9, 5, 50, 100, true, "mm"],
    ["55 - 75 mm", 5, 5, 6, 55, 100, true, "mm"],
    ["1.120 - 1.127 pulg", 0.001, 8, 7, 1.12, 0.0015, false, "Pul."],
    ["1.128 - 1.135 pulg", 0.001, 8, 8, 1.128, 0.0015, false, "Pul."],
    ["18 - 72 mm", 10, 6, 9, 20, 100, true, "mm"],
  ];
  variaciones2 = [
    [0.00058, 0.1, 6, 0, 0.0, 0.003],
    [58, 10, 5, 1, 20, 100],
    [58, 10, 5, 2, 30, 100],
    [58, 10, 6, 3, 30, 100],
    [58, 5, 6, 4, 50, 100],
    [58, 5, 9, 5, 50, 100],
    [58, 5, 5, 6, 55, 100],
    [0.000408, 0.001, 8, 7, 1.12, 0.0015],
    [0.000368, 0.001, 8, 8, 1.128, 0.0015],
    [58, 10, 6, 9, 20, 100],
  ];
  variacion;
  tam = [];
  labels = [];

  ibc_list: any = [];

  file: any;
  file_name = "";
  pdfInformes: any = [];
  pdfInformesDouble: any = [];
  informeGraficaCheck: any = [];

  loader: number = 0;
  data: any = [];
  response: any = [];
  informe: any = [];
  informe_dym: any = [];

  data_files: any = [];
  files: any;
  file_names = "";

  ctx: any;
  myChart: any;
  myChart2: any;

  variante: number = 1;

  incertidumbre: number;
  toleranciaJust: number = 0.003;

  listFiles: any = [];

  list: any = [];
  number_page = 0;
  pages = 0;

  info_req: number;
  doc;
  url_to_pdf = "";
  base_64_plantilla = "";
  elements_tree: any = [];
  imgData = "../../../assets/img/0002.jpg";
  firma_user = "";
  firmaBase64: any;

  dinamica_jspdf = 0;

  prevPdf: number = 0;
  constructor(
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    public conn: HttpClient,
    private generadorReports: GeneratorReportsService
  ) {}

  ngOnInit(): void {
    this.ctx = document.getElementById("myChart");
    this.getFilesCsv();
  }

  /**
   * This function retrieves CSV files from a cloud service and handles different response codes.
   */
  private getFilesCsv(): void {
    let result: any = [];
    this.loader = 1;
    this.generadorReports.getFilesCsvNubeG().subscribe(
      (res) => {
        result = res;
        if (
          result.code == "1_0001" ||
          result.code == "1_0002" ||
          result.code == "1_0003"
        )
          this.lauchModal(result.code, result.message);
        else if (result.code == "1_0004" || result.code == "0_007")
          this.lauchModal(result.code, result.message);
        else {
          this.listFiles = result.data;
          this.pages = this.listFiles.length;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This function loads a file and sets its name as a property.
   * @param value - The parameter "value" is an event object that is passed as an argument to the
   * function when it is called. It contains information about the event that triggered the function,
   * such as the target element that was interacted with. In this case, it is likely an event object
   * related to a file input
   */
  public loadFile(value): void {
    this.file = value.target;
    this.file_name = this.file.files[0].name;
  }

  /**
   * This is a private function in TypeScript that generates a CSV report based on a given ID and
   * displays it in various formats depending on the data.
   * @param id - The parameter "id" is likely an identifier or key used to retrieve data for generating
   * a CSV report. It is passed as an argument to the "generateInformeCsvNube" function.
   */
  public generateInformeCsvNube(id): void {
    let result: any = [];
    this.loader = 1;
    this.generadorReports.generateInfoCsvNubeG(id).subscribe(
      (res) => {
        result = res;
        console.log(result.data);
        if (
          result.code == "1_0001" ||
          result.code == "1_0002" ||
          result.code == "1_0003"
        )
          this.lauchModal(result.code, result.message);
        else if (result.code == "1_0004" || result.code == "0_007")
          this.lauchModal(result.code, result.message);
        else {
          this.data = result.data;
        }

        this.prevPdf = 0;
        if (this.data[0][3] == 9) {
          this.informe = this.data[0];
        } else {
          var val_shet = parseInt(this.data[2][1], 10);

          switch (val_shet) {
            case 3:
              this.transmisor();
              break;
            case 4:
              this.transmisor();
              break;
            case 5:
              this.medidorPh();
              break;
            case 9:
              this.informe = this.data[0];
              break;
            case 10:
              this.micrometros();
              break;
            case 11:
              this.data[1][5] == 25
                ? this.setVariacion(this.data[1][4])
                : this.setVariacion(0);
              this.escantillon();
              break;
            case 12:
              this.conductividad();
              break;
            case 13:
              this.balanzas();
              break;
            case 57:
              this.pressure();
              break;
            default:
              this.downloadFile(this.data[3]);
              break;
          }
        }

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This function saves a file and performs different actions based on the sheet number of the data in
   * the file.
   */
  public saveFile(): void {
    let result: any = [];
    this.loader = 1;

    if (this.file.files.length > 0) {
      let form = new FormData();
      form.append("file", this.file.files[0]);

      this.generadorReports.createReportFile(form).subscribe(
        (res) => {
          result = res;
          if (
            result.code == "1_0001" ||
            result.code == "1_0002" ||
            result.code == "1_0003"
          )
            this.lauchModal(result.code, result.message);
          else if (result.code == "1_0004" || result.code == "0_007")
            this.lauchModal(result.code, result.message);
          else this.data = result.data;

          var val_shet = parseInt(this.data[2][1], 10);

          this.prevPdf = 0;
          if (this.data[3] == 9) {
            this.informe = this.data;
          }

          switch (val_shet) {
            case 3:
              this.transmisor();
              break;
            case 4:
              this.transmisor();
              break;
            case 5:
              this.medidorPh();
              break;
            case 9:
              this.informe = this.data;
              break;
            case 10:
              this.micrometros();
              break;
            case 11:
              this.data[1][5] == 25
                ? this.setVariacion(this.data[1][4])
                : this.setVariacion(0);
              this.escantillon();
              break;
            case 12:
              this.conductividad();
              break;
            case 13:
              this.balanzas();
              break;
            case 57:
              this.pressure();
              break;
            default:
              this.downloadFile(this.data[3]);
              break;
          }

          this.loader = 2;
        },
        (err) => {
          console.log(err);
          this.lauchModal("0000x00", "Error de consulta");
          this.loader = 2;
        }
      );
    }
  }

  /**
   * This function generates a line chart and prepares data for a report based on the chart and other
   * input data.
   */
  private pressure(): void {
    this.loader = 1;
    let referencia = [40, 60, 80, 100, 120, 140, 160, 180, 240];
    let listIbc = this.data[0];
    let error_vals = [];

    for (let i = 0; i < referencia.length; i++) {
      let err = listIbc[i] - referencia[i];
      error_vals.push(err);
    }

    let limit1 = [],
      limit2 = [];

    for (let i = 0; i < error_vals.length; i++) {
      limit1[i] = error_vals[i] + 1;
      limit2[i] = error_vals[i] + 1 - (error_vals[i] + 1 + (error_vals[i] + 1));
    }

    var step = 3.0;
    var max = 50;
    var start = -240;

    this.myChart = new Chart(this.ctx, {
      type: "line",
      data: {
        labels: ["40", "60", "80", "100", "120", "140", "160", "180", "240"],
        datasets: [
          {
            label: "Limit",
            data: limit1,
            fill: false,
            lineTension: 0.000001,
            borderDash: [15, 15],
            pointBackgroundColor: "rgb(255,255,255)",
            borderColor: "rgb(255, 255, 255)",
          },
          {
            label: "Mediciones",
            data: error_vals,
            fill: false,
            lineTension: 0.000001,
            borderDash: [10, 10],
            pointBackgroundColor: "rgb(255,95,0)",
            /*borderColor: "rgb(255, 0, 0)"*/
          },
          {
            label: "Limit",
            data: limit2,
            fill: false,
            lineTension: 0.000001,
            borderDash: [15, 15],
            pointBackgroundColor: "rgb(255,255,255)",
            borderColor: "rgb(255, 255, 255)",
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        scaleSteps: Math.ceil((max - start) / step),
        scaleStepWidth: step,
        scaleStartValue: start,
      },
    });

    var informes = [];
    let check = this.data[5];
    let comments = this.data[6];

    let global = [];
    global.push(this.data[1][1]); //TEMPERATURA
    global.push(this.data[1][2]); //HR
    global.push("DD-MM-AAAA"); //FORMATO
    global.push(this.data[1][0]); //FECHA
    global.push("Base");

    let clic =
      this.uri +
      "generate_report_baumanometro?api_key=" +
      this.api_key +
      "&token=" +
      this.token +
      "&module=2&id_plantilla=57" +
      "&globales=" +
      global +
      "&id_equipo=" +
      this.data[2][2] +
      "&patron_id=" +
      this.data[4];

    let a = [];
    a[0] = clic;
    a[1] = this.data[3].identificador + this.data[3].partida;
    a[2] = this.myChart;
    a[3] = check;
    a[4] = comments;
    a[5] = listIbc;
    a[6] = error_vals;
    informes.push(a);

    this.informeGraficaCheck = informes;
    this.loader = 2;
  }

  /**
   * The function "balanzas" creates a chart and generates a PDF report based on data inputs and
   * predefined values.
   */
  private balanzas(): void {
    this.loader = 1;

    this.ibc_list = this.data[0];
    var list_global = this.data[1];
    var list_ids = this.data[2];
    var odv = this.data[3];
    var list_patrones = this.data[4];
    var list_incert = this.data[5];
    var list_excent = this.data[6];
    var list_rep100 = this.data[7];
    var list_rep50 = this.data[8];
    var list_bascula = this.data[9];

    var listMin = [],
      listMax = [];
    var informes = [];
    let listError = [],
      listInTol = [],
      listIntervalo = this.data[10];

    listIntervalo.push(2.0);
    listIntervalo.push(5.0);
    listIntervalo.push(7.0);
    listIntervalo.push(10.0);
    listIntervalo.push(15.0);
    listIntervalo.push(17.001);
    listIntervalo.push(20.001);
    listIntervalo.push(22.001);
    listIntervalo.push(24.001);
    listIntervalo.push(25.001);

    for (let i = 0; i < this.ibc_list.length; i++) {
      listMin.push(listIntervalo[i] - 0.05);
      listMax.push(listIntervalo[i] + 0.05);
    }

    for (let i = 0; i < this.ibc_list.length; i++) {
      this.ibc_list[i] <= listMin[i]
        ? listInTol.push("No cumple")
        : this.ibc_list[i] >= listMax[i]
        ? listInTol.push("No cumple")
        : listInTol.push("Si cumple");
    }

    var error0 = this.ibc_list[0] - 2.0;
    var error1 = this.ibc_list[1] - 5.0;
    var error2 = this.ibc_list[2] - 7.0;
    var error3 = this.ibc_list[3] - 10.0;
    var error4 = this.ibc_list[4] - 15.0;
    var error5 = this.ibc_list[5] - 17.001;
    var error6 = this.ibc_list[6] - 20.001;
    var error7 = this.ibc_list[7] - 22.001;
    var error8 = this.ibc_list[8] - 24.001;
    var error9 = this.ibc_list[9] - 25.001;

    var incertidumbre1 = error0 - list_incert[0];
    var incertidumbre2 = error1 - list_incert[1];
    var incertidumbre3 = error2 - list_incert[2];
    var incertidumbre4 = error3 - list_incert[3];
    var incertidumbre5 = error4 - list_incert[4];
    var incertidumbre6 = error5 - list_incert[5];
    var incertidumbre7 = error6 - list_incert[6];
    var incertidumbre8 = error7 - list_incert[7];
    var incertidumbre9 = error8 - list_incert[8];
    var incertidumbre10 = error9 - list_incert[9];

    var yincertidumbre1 = error0 + parseFloat(list_incert[0]);
    var yincertidumbre2 = error1 + parseFloat(list_incert[1]);
    var yincertidumbre3 = error2 + parseFloat(list_incert[2]);
    var yincertidumbre4 = error3 + parseFloat(list_incert[3]);
    var yincertidumbre5 = error4 + parseFloat(list_incert[4]);
    var yincertidumbre6 = error5 + parseFloat(list_incert[5]);
    var yincertidumbre7 = error6 + parseFloat(list_incert[6]);
    var yincertidumbre8 = error7 + parseFloat(list_incert[7]);
    var yincertidumbre9 = error8 + parseFloat(list_incert[8]);
    var yincertidumbre10 = error9 + parseFloat(list_incert[9]);

    this.ctx = document.getElementById("myChart");
    var step = 0.0;
    var max = 0.06;
    var start = -0.06;

    this.myChart = new Chart(this.ctx, {
      type: "line",
      data: {
        labels: [
          "2.000",
          "5.000",
          "7.000",
          "10.000",
          "15.000",
          "17.001",
          "20.001",
          "22.001",
          "24.001",
          "25.001",
        ],
        datasets: [
          {
            label: "Tolerancia del instrumento",
            data: [0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05],
            fill: false,
            borderDash: [5, 5],
            lineTension: 0.000001,
            borderColor: "rgb(0, 0, 255)",
          },
          {
            label: "Incertidumbre",
            data: [
              yincertidumbre1,
              yincertidumbre2,
              yincertidumbre3,
              yincertidumbre4,
              yincertidumbre5,
              yincertidumbre6,
              yincertidumbre7,
              yincertidumbre8,
              yincertidumbre9,
              yincertidumbre10,
            ],
            fill: false,
            lineTension: 0.000001,
            borderColor: "rgb(0, 0, 0)",
          },
          {
            label: "Error = (Media Inst. - Patrón)",
            data: [
              error0,
              error1,
              error2,
              error3,
              error4,
              error5,
              error6,
              error7,
              error8,
              error9,
            ],
            fill: false,
            lineTension: 0.000001,
            pointBackgroundColor: "rgb(0,0,0)",
            borderColor: "rgb(255, 0, 0)",
          },
          {
            label: "Incertidumbre",
            data: [
              incertidumbre1,
              incertidumbre2,
              incertidumbre3,
              incertidumbre4,
              incertidumbre5,
              incertidumbre6,
              incertidumbre7,
              incertidumbre8,
              incertidumbre9,
              incertidumbre10,
            ],
            fill: false,
            lineTension: 0.000001,
            borderColor: "rgb(0, 0, 0)",
          },
          {
            label: "Tolerancia del instrumento",
            data: [
              -0.05, -0.05, -0.05, -0.05, -0.05, -0.05, -0.05, -0.05, -0.05,
              -0.05,
            ],
            fill: false,
            borderDash: [5, 5],
            lineTension: 0.000001,
            borderColor: "rgb(0, 0, 255)",
          },
          {
            label: "",
            data: [
              -0.06, -0.06, -0.06, -0.06, -0.06, -0.06, -0.06, -0.06, -0.06,
              -0.06,
            ],
            fill: false,
            lineTension: 0.000001,
            borderColor: "#AARRGGBB",
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        scaleSteps: Math.ceil((0.7 - -0.7) / 0.0),
        scaleStepWidth: 0.0,
        scaleStartValue: -0.7,
      },
    });

    let global = [];
    global.push(this.data[1][1]);
    global.push(this.data[1][2]);
    global.push("AAA-MM-DD");
    global.push(this.data[1][0]);
    global.push("sitio");
    global.push(this.data[2][0]);
    global.push(40);
    global.push(80);
    global.push(57);

    let clic =
      this.uri +
      "bascula_mb_pdf?api_key=" +
      this.api_key +
      "&token=" +
      this.token +
      "&module=2&id_plantilla=8&libc=" +
      this.ibc_list +
      "&inTol=" +
      listInTol +
      "&lref=" +
      listIntervalo +
      "&linsert=" +
      list_incert +
      "&lexce=" +
      list_excent +
      "&globales=" +
      global +
      "&id_equipo=" +
      list_ids[2] +
      "&patron_id=" +
      list_patrones;

    let a = [];
    a[0] = clic;
    a[1] = this.data[3].identificador + this.data[3].partida;
    a[2] = this.myChart;
    informes.push(a);

    this.pdfInformes = informes;
    this.loader = 2;
  }

  /**
   * The transmisor function generates a chart and data for a temperature transmitter and checks if it
   * meets certain tolerances and uncertainties.
   */
  private transmisor(): void {
    this.loader = 1;

    var inTol_1 = "",
      inTol_2 = "",
      inTol_3 = "",
      inTol_4 = "";
    var informes = [];

    let listError = [],
      listIbc = [],
      listInTol = [];

    //console.log(this.data[1][4]);
    listIbc = this.data[0];

    if (this.data[2][1] == 3) {
      let intervalo_1 = 0;
      let intervalo_2 = 0;
      let intervalo_3 = 0;
      let listIntervalos = [];

      if (this.data[1][4] == 1) {
        intervalo_1 = 0.0;
        intervalo_2 = 10.0;
        intervalo_3 = 20.0;
      } else if (this.data[1][4] == 3) {
        intervalo_1 = 70.0;
        intervalo_2 = 80.0;
        intervalo_3 = 90.0;
      } else if (this.data[1][4] == 4) {
        intervalo_1 = 5.0;
        intervalo_2 = 10.0;
        intervalo_3 = 18.0;
      } else if (this.data[1][4] == 5) {
        intervalo_1 = 60.0;
        intervalo_2 = 80.0;
        intervalo_3 = 100.0;
      } else if (this.data[1][4] == 6) {
        intervalo_1 = -5.0;
        intervalo_2 = 0.0;
        intervalo_3 = 5.0;
      } else if (this.data[1][4] == 7) {
        intervalo_1 = 65.0;
        intervalo_2 = 75.0;
        intervalo_3 = 85.0;
      } else if (this.data[1][4] == 8) {
        intervalo_1 = 5.0;
        intervalo_2 = 10.0;
        intervalo_3 = 15.0;
      } else if (this.data[1][4] == 9) {
        intervalo_1 = 60.0;
        intervalo_2 = 70.0;
        intervalo_3 = 80.0;
      } else if (this.data[1][4] == 10) {
        intervalo_1 = 3.0;
        intervalo_2 = 18.0;
        intervalo_3 = 105.0;
      }

      listIntervalos.push(intervalo_1);
      listIntervalos.push(intervalo_2);
      listIntervalos.push(intervalo_3);
      let min_1 = intervalo_1 - 0.5;
      let min_2 = intervalo_2 - 0.5;
      let min_3 = intervalo_3 - 0.5;

      let max_1 = intervalo_1 + 0.5;
      let max_2 = intervalo_2 + 0.5;
      let max_3 = intervalo_3 + 0.5;

      listIbc[0] < min_1
        ? (inTol_1 = "No cumple")
        : listIbc[0] > max_1
        ? (inTol_1 = "No cumple")
        : (inTol_1 = "Si cumple");

      listIbc[1] < min_2
        ? (inTol_2 = "No cumple")
        : listIbc[1] > max_2
        ? (inTol_2 = "No cumple")
        : (inTol_2 = "Si cumple");

      listIbc[2] < min_3
        ? (inTol_3 = "No cumple")
        : listIbc[2] > max_3
        ? (inTol_3 = "No cumple")
        : (inTol_3 = "Si cumple");

      listInTol.push(inTol_1);
      listInTol.push(inTol_2);
      listInTol.push(inTol_3);

      let error0 = listIbc[0] - intervalo_1;
      let error10 = listIbc[1] - intervalo_2;
      let error20 = listIbc[2] - intervalo_3;

      listError.push(error0);
      listError.push(error10);
      listError.push(error20);

      let line_g = [];
      line_g.push(0);
      line_g.push(0);
      line_g.push(0);
      let incertidumbre1 = error0 - 0.11;
      let incertidumbre2 = error10 - 0.11;
      let incertidumbre3 = error20 - 0.11;

      let yincertidumbre1 = error0 + 0.11;
      let yincertidumbre2 = error10 + 0.11;
      let yincertidumbre3 = error20 + 0.11;

      this.myChart = new Chart(this.ctx, {
        type: "line",
        data: {
          labels: [
            intervalo_1.toString(),
            intervalo_2.toString(),
            intervalo_3.toString(),
          ],
          datasets: [
            {
              label: "Tolerancia del instrumento",
              data: [0.6, 0.6, 0.6],
              fill: false,
              borderDash: [5, 5],
              borderColor: "rgb(0, 0, 255)",
            },
            {
              label: "Incertidumbre",
              data: [yincertidumbre1, yincertidumbre2, yincertidumbre3],
              fill: false,
              lineTension: 0.000001,
              borderDash: [5, 5],
              borderColor: "rgb(0, 0, 0)",
            },
            {
              label: "Error = (Media Inst. - Patrón)",
              data: [error0, error10, error20],
              fill: false,
              lineTension: 0.000001,
              pointBackgroundColor: "rgb(0,0,0)",
              borderColor: "rgb(255, 0, 0)",
            },
            {
              label: "Linea",
              data: line_g,
              fill: false,
              lineTension: 0.000001,
              pointBackgroundColor: "rgb(0,0,0)",
              borderColor: "rgb(0, 0, 0)",
            },
            {
              label: "Incertidumbre",
              data: [incertidumbre1, incertidumbre2, incertidumbre3],
              fill: false,
              lineTension: 0.000001,
              borderDash: [5, 5],
              borderColor: "rgb(0, 0, 0)",
            },
            {
              label: "Tolerancia del instrumento",
              data: [-0.6, -0.6, -0.6],
              fill: false,
              borderDash: [5, 5],
              borderColor: "rgb(0, 0, 255)",
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
        },
      });

      let global = [];
      global.push(this.data[1][1]);
      global.push(this.data[1][2]);
      global.push("AAA-MM-DD");
      global.push(this.data[1][0]);
      global.push("sitio");
      global.push(this.data[5]);

      let clic =
        this.uri +
        "transmisor_temperatura_20?api_key=" +
        this.api_key +
        "&token=" +
        this.token +
        "&module=2&id_plantilla=3&libc=" +
        listIbc +
        "&inTol=" +
        listInTol +
        "&lerror=" +
        listError +
        "&globales=" +
        global +
        "&id_equipo=" +
        this.data[2][2] +
        "&patron_id=" +
        this.data[4] +
        "&listIntervalos=" +
        listIntervalos;
      let a = [];
      a[0] = clic;
      a[1] = this.data[3].identificador + this.data[3].partida;
      a[2] = this.myChart;
      informes.push(a);

      this.pdfInformes = informes;
      this.loader = 2;
    } else {
      listIbc[0] <= 47
        ? (inTol_1 = "No cumple")
        : listIbc[0] >= 53
        ? (inTol_1 = "No cumple")
        : (inTol_1 = "Si cumple");

      listIbc[1] <= 72
        ? (inTol_2 = "No cumple")
        : listIbc[1] >= 77
        ? (inTol_2 = "No cumple")
        : (inTol_2 = "Si cumple");

      listIbc[2] <= 97
        ? (inTol_3 = "No cumple")
        : listIbc[2] >= 103
        ? (inTol_3 = "No cumple")
        : (inTol_3 = "Si cumple");

      listIbc[3] <= 117
        ? (inTol_4 = "No cumple")
        : listIbc[3] >= 123
        ? (inTol_4 = "No cumple")
        : (inTol_4 = "Si cumple");

      listInTol.push(inTol_1);
      listInTol.push(inTol_2);
      listInTol.push(inTol_3);
      listInTol.push(inTol_4);

      var error50 = listIbc[0] - 50.0;
      var error75 = listIbc[1] - 75.0;
      var error100 = listIbc[2] - 100.0;
      var error120 = listIbc[3] - 120.0;

      listError.push(error50);
      listError.push(error75);
      listError.push(error100);
      listError.push(error120);

      let line_g = [];
      line_g.push(0);
      line_g.push(0);
      line_g.push(0);
      line_g.push(0);

      var incertidumbre1 = error50 - 0.11;
      var incertidumbre2 = error75 - 0.11;
      var incertidumbre3 = error100 - 0.11;
      var incertidumbre4 = error120 - 0.11;

      var yincertidumbre1 = error50 + 0.11;
      var yincertidumbre2 = error75 + 0.11;
      var yincertidumbre3 = error100 + 0.11;
      var yincertidumbre4 = error120 + 0.11;

      var step = 0.0;
      var max = 0.4;
      var start = -0.4;

      this.myChart = new Chart(this.ctx, {
        type: "line",
        data: {
          labels: ["50", "75", "100", "120"],
          datasets: [
            {
              label: "Tolerancia del instrumento",
              data: [2.0, 2.0, 2.0, 2.0],
              fill: false,
              borderDash: [5, 5],
              borderColor: "rgb(0, 0, 255)",
            },
            {
              label: "Incertidumbre",
              data: [
                yincertidumbre1,
                yincertidumbre2,
                yincertidumbre3,
                yincertidumbre4,
              ],
              fill: false,
              lineTension: 0.000001,
              borderDash: [5, 5],
              borderColor: "rgb(0, 0, 0)",
            },
            {
              label: "Error = (Media Inst. - Patrón)",
              data: [error50, error75, error100, error120],
              fill: false,
              lineTension: 0.000001,
              pointBackgroundColor: "rgb(0,0,0)",
              borderColor: "rgb(255, 0, 0)",
            },
            {
              label: "Linea",
              data: line_g,
              fill: false,
              lineTension: 0.000001,
              pointBackgroundColor: "rgb(0,0,0)",
              borderColor: "rgb(0, 0, 0)",
            },
            {
              label: "Incertidumbre",
              data: [
                incertidumbre1,
                incertidumbre2,
                incertidumbre3,
                incertidumbre4,
              ],
              fill: false,
              lineTension: 0.000001,
              borderDash: [5, 5],
              borderColor: "rgb(0, 0, 0)",
            },
            {
              label: "Tolerancia del instrumento",
              data: [-2.0, -2.0, -2.0, -2.0],
              fill: false,
              borderDash: [5, 5],
              borderColor: "rgb(0, 0, 255)",
            },
            {
              data: [-2.1, -2.1, -2.1],
              fill: false,
              borderColor: "rgb(255, 255, 255)",
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scaleSteps: Math.ceil((max - start) / step),
          scaleStepWidth: step,
          scaleStartValue: start,
        },
      });

      let global = [];
      global.push(this.data[1][1]);
      global.push(this.data[1][2]);
      global.push("AAA-MM-DD");
      global.push(this.data[1][0]);
      global.push("sitio");
      global.push(this.data[5]);

      let clic =
        this.uri +
        "transmisor_temperatura_120?api_key=" +
        this.api_key +
        "&token=" +
        this.token +
        "&module=2&id_plantilla=4&libc=" +
        listIbc +
        "&inTol=" +
        listInTol +
        "&lerror=" +
        listError +
        "&globales=" +
        global +
        "&id_equipo=" +
        this.data[2][2] +
        "&patron_id=" +
        this.data[4];

      let a = [];
      a[0] = clic;
      a[1] = this.data[3].identificador + this.data[3].partida;
      a[2] = this.myChart;
      informes.push(a);

      this.pdfInformes = informes;
      this.loader = 2;
    }
  }

  /**
   * The micrometros function creates a chart and generates a report based on input data, including
   * tolerance and error calculations.
   */
  private micrometros(): void {
    this.loader = 1;
    var min_1 = -0.001;
    var max_1 = 0.001;
    var min_2 = 0.099;
    var max_2 = 0.101;
    var min_3 = 0.199;
    var max_3 = 0.201;
    var min_4 = 0.299;
    var max_4 = 0.301;
    var min_5 = 0.399;
    var max_5 = 0.401;
    var min_6 = 0.499;
    var max_6 = 0.501;

    var inTol_1 = "";
    var inTol_2 = "";
    var inTol_3 = "";
    var inTol_4 = "";
    var inTol_5 = "";
    var inTol_6 = "";

    var informes = [];

    let listError = [];

    let listIbc = [];
    let listInTol = [];

    listIbc = this.data[0];

    listIbc[0] <= min_1
      ? (inTol_1 = "No cumple")
      : listIbc[0] >= max_1
      ? (inTol_1 = "No cumple")
      : (inTol_1 = "Si cumple");

    listIbc[1] <= min_2
      ? (inTol_2 = "No cumple")
      : listIbc[1] >= max_2
      ? (inTol_2 = "No cumple")
      : (inTol_2 = "Si cumple");

    listIbc[2] <= min_3
      ? (inTol_3 = "No cumple")
      : listIbc[2] >= max_3
      ? (inTol_3 = "No cumple")
      : (inTol_3 = "Si cumple");

    listIbc[3] <= min_4
      ? (inTol_4 = "No cumple")
      : listIbc[3] >= max_4
      ? (inTol_4 = "No cumple")
      : (inTol_4 = "Si cumple");

    listInTol.push(inTol_1);
    listInTol.push(inTol_2);
    listInTol.push(inTol_3);
    listInTol.push(inTol_4);

    if (listIbc.length == 5) {
      this.variante = 2;
      listIbc[4] <= min_5
        ? (inTol_5 = "No cumple")
        : listIbc[4] >= max_5
        ? (inTol_5 = "No cumple")
        : (inTol_5 = "Si cumple");
      listInTol.push(inTol_5);
    } else if (listIbc.length == 6) {
      var dataGlobal = this.data[1];
      if (dataGlobal[4] == 0.003) {
        this.variante = 3;
        listIbc[5] <= min_6
          ? (inTol_6 = "No cumple")
          : listIbc[5] >= max_6
          ? (inTol_6 = "No cumple")
          : (inTol_5 = "Si cumple");
      } else if (dataGlobal[4] == 0.0015) {
        this.variante = 4;
        listIbc[5] <= min_6
          ? (inTol_6 = "No cumple")
          : listIbc[5] >= max_6
          ? (inTol_6 = "No cumple")
          : (inTol_5 = "Si cumple");
      }
    }

    var error0 = listIbc[0] - 0.0;
    var error1 = listIbc[1] - 0.1;
    var error2 = listIbc[2] - 0.2;
    var error3 = listIbc[3] - 0.3;
    var error4 = 0;
    this.variante == 2 ? (error4 = listIbc[4] - 0.4) : console.log(error4);

    listError.push(error0);
    listError.push(error1);
    listError.push(error2);
    listError.push(error3);
    this.variante == 2 ? listError.push(error4) : console.log(listError);

    var incertidumbre1 = error0 - 0.00058;
    var incertidumbre2 = error1 - 0.00058;
    var incertidumbre3 = error2 - 0.00058;
    var incertidumbre4 = error3 - 0.00058;
    var incertidumbre5 = 0;
    this.variante == 2
      ? (incertidumbre5 = error4 - 0.00058)
      : console.log(incertidumbre5);

    var incertidumbres1 = [];
    if (this.variante == 2) {
      incertidumbres1.push(incertidumbre1);
      incertidumbres1.push(incertidumbre2);
      incertidumbres1.push(incertidumbre3);
      incertidumbres1.push(incertidumbre4);
      incertidumbres1.push(incertidumbre5);
    } else {
      incertidumbres1.push(incertidumbre1);
      incertidumbres1.push(incertidumbre2);
      incertidumbres1.push(incertidumbre3);
      incertidumbres1.push(incertidumbre4);
    }

    var yincertidumbre1 = error0 + 0.00058;
    var yincertidumbre2 = error1 + 0.00058;
    var yincertidumbre3 = error2 + 0.00058;
    var yincertidumbre4 = error3 + 0.00058;
    var yincertidumbre5 = 0;
    this.variante == 2
      ? (yincertidumbre5 = error4 + 0.00058)
      : console.log(yincertidumbre5);

    var yincertidumbres = [];
    if (this.variante == 2) {
      yincertidumbres.push(yincertidumbre1);
      yincertidumbres.push(yincertidumbre2);
      yincertidumbres.push(yincertidumbre3);
      yincertidumbres.push(yincertidumbre4);
      yincertidumbres.push(yincertidumbre5);
    } else {
      yincertidumbres.push(yincertidumbre1);
      yincertidumbres.push(yincertidumbre2);
      yincertidumbres.push(yincertidumbre3);
      yincertidumbres.push(yincertidumbre4);
    }

    if (this.variante == 2) {
      var labelsDta = ["0", "0.1", "0.2", "0.3", "0.4"];
      var dataTolerancias = ["0.003", "0.003", "0.003", "0.003", "0.003"];
      var lesstolerancias = ["-0.003", "-0.003", "-0.003", "-0.003", "-0.003"];
    } else {
      var labelsDta = ["0", "0.1", "0.2", "0.3"];
      var dataTolerancias = ["0.003", "0.003", "0.003", "0.003"];
      var lesstolerancias = ["-0.003", "-0.003", "-0.003", "-0.003"];
    }

    var step = 0.0;
    var max = 0.001;
    var start = -0.001;

    this.myChart = new Chart(this.ctx, {
      type: "line",
      data: {
        labels: labelsDta,
        datasets: [
          {
            label: "Tolerancia del instrumento",
            data: dataTolerancias,
            fill: false,
            borderDash: [5, 5],
            borderColor: "rgb(0, 0, 255)",
          },
          {
            label: "Incertidumbre",
            data: yincertidumbres,
            fill: false,
            lineTension: 0.000001,
            borderDash: [5, 5],
            borderColor: "rgb(0, 0, 0)",
          },
          {
            label: "Error = (Media Inst. - Patrón)",
            data: listError,
            fill: false,
            lineTension: 0.000001,
            pointBackgroundColor: "rgb(0,0,0)",
            borderColor: "rgb(255, 0, 0)",
          },
          {
            label: "Incertidumbre",
            data: incertidumbres1,
            fill: false,
            lineTension: 0.000001,
            borderDash: [5, 5],
            borderColor: "rgb(0, 0, 0)",
          },
          {
            label: "Tolerancia del instrumento",
            data: lesstolerancias,
            fill: false,
            borderDash: [5, 5],
            borderColor: "rgb(0, 0, 255)",
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
      },
      scaleSteps: Math.ceil((max - start) / step),
      scaleStepWidth: step,
      scaleStartValue: start,
    });

    let global = [];
    global.push(this.data[1][1]);
    global.push(this.data[1][2]);
    global.push("AAA-MM-DD");
    global.push(this.data[1][0]);
    global.push("sitio");

    let clic =
      this.uri +
      "micrometro_variaciones?api_key=" +
      this.api_key +
      "&token=" +
      this.token +
      "&module=2&id_plantilla=10&libc=" +
      listIbc +
      "&inTol=" +
      listInTol +
      "&lerror=" +
      listError +
      "&globales=" +
      global +
      "&id_equipo=" +
      this.data[2][2] +
      "&patron_id=" +
      this.data[4] +
      "&tole=" +
      this.toleranciaJust;
    let a = [];
    a[0] = clic;
    a[1] = this.data[3].identificador + this.data[3].partida;
    a[2] = this.myChart;
    informes.push(a);

    this.pdfInformes = informes;
    this.loader = 2;
  }

  /**
   * The function "escantillon" generates a chart and related data for a given set of input parameters.
   */
  private escantillon(): void {
    this.loader = 1;
    var informes = [];

    let listError = [];
    let listIbc = [];
    let listInTol = [];
    let incertidumbres = [];
    let yincertidumbres = [];
    let tolerancias = [];
    let toleranciasneg = [];
    let listError2 = [];
    let listIbc2 = [];
    let listInTol2 = [];
    let incertidumbres2 = [];
    let yincertidumbres2 = [];
    let tolerancias2 = [];
    let toleranciasneg2 = [];

    for (var o = 0; o < this.variaciones2[this.variacion][2]; o++) {
      listIbc = this.data[0];
      var max_line = listIbc[o] + this.variaciones2[this.variacion][0];
      var min_line = listIbc[o] - this.variaciones2[this.variacion][0];

      //console.log(listIbc[o]);
      var inTol = "";

      listIbc[o] <= min_line
        ? (inTol = "No cumple")
        : listIbc[o] >= max_line
        ? (inTol = "No cumple")
        : (inTol = "Si cumple");
      listInTol.push(inTol);
      var error = listIbc[o] - this.tam[o][0];
      listError.push(error);
      var incertidumbrex = error - this.variaciones2[this.variacion][0];
      incertidumbres.push(incertidumbrex);
      var yincertidumbre = error + this.variaciones2[this.variacion][0];
      yincertidumbres.push(yincertidumbre);
      tolerancias.push(this.variaciones2[this.variacion][5]);
      toleranciasneg.push(-this.variaciones2[this.variacion][5]);
    }

    var step = 0;
    var max = this.variaciones2[this.variacion][5];
    var start = -this.variaciones2[this.variacion][5];

    this.myChart = new Chart(this.ctx, {
      type: "line",
      data: {
        labels: this.labels,
        datasets: [
          {
            label: "Tolerancia del instrumento",
            data: tolerancias,
            fill: false,
            borderDash: [5, 5],
            borderColor: "rgb(0, 0, 255)",
          },
          {
            label: "Incertidumbre",
            data: yincertidumbres,
            fill: false,
            lineTension: 0.000001,
            borderDash: [5, 5],
            borderColor: "rgb(0, 0, 0)",
          },
          {
            label: "Error = (Media Inst. - Patrón)",
            data: listError,
            fill: false,
            lineTension: 0.000001,
            pointBackgroundColor: "rgb(0,0,0)",
            borderColor: "rgb(255, 0, 0)",
          },
          {
            label: "Incertidumbre",
            data: incertidumbres,
            fill: false,
            lineTension: 0.000001,
            borderDash: [5, 5],
            borderColor: "rgb(0, 0, 0)",
          },
          {
            label: "Tolerancia del instrumento",
            data: toleranciasneg,
            fill: false,
            borderDash: [5, 5],
            borderColor: "rgb(0, 0, 255)",
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
      },
      scaleSteps: Math.ceil((max - start) / step),
      scaleStepWidth: step,
      scaleStartValue: start,
    });
    //DOBLE GRAFICA INICIO
    if (this.variaciones[this.variacion][6]) {
      for (var o = 0; o < this.variaciones2[this.variacion][2]; o++) {
        //for (let i = 0; i < listIbc2.length; i++) {
        listIbc2 = this.data[5];
        var max_line = listIbc2[o] + this.variaciones2[this.variacion][0];
        var min_line = listIbc2[o] - this.variaciones2[this.variacion][0];
        var inTol = "";

        listIbc2[o] <= min_line
          ? (inTol = "No cumple")
          : listIbc2[o] >= max_line
          ? (inTol = "No cumple")
          : (inTol = "Si cumple");

        listInTol2.push(inTol);
        var error = listIbc2[o] - this.tam[o][0];
        listError2.push(error);
        var incertidumbrex = error - this.variaciones2[this.variacion][0];
        incertidumbres2.push(incertidumbrex);
        var yincertidumbre = error + this.variaciones2[this.variacion][0];
        yincertidumbres2.push(yincertidumbre);
        tolerancias2.push(this.variaciones2[this.variacion][5].toFixed(2));
        toleranciasneg2.push(-this.variaciones2[this.variacion][5].toFixed(2));
      }

      this.ctx = document.getElementById("myChartR");
      var step = 0;
      var max = this.variaciones2[this.variacion][5];
      var start = -this.variaciones2[this.variacion][5];

      this.myChart2 = new Chart(this.ctx, {
        type: "line",
        data: {
          labels: this.labels,
          datasets: [
            {
              label: "Tolerancia del instrumento",
              data: tolerancias2,
              fill: false,
              borderDash: [5, 5],
              borderColor: "rgb(0, 0, 255)",
            },
            {
              label: "Incertidumbre",
              data: yincertidumbres2,
              fill: false,
              lineTension: 0.000001,
              borderDash: [5, 5],
              borderColor: "rgb(0, 0, 0)",
            },
            {
              label: "Error = (Media Inst. - Patrón)",
              data: listError2,
              fill: false,
              lineTension: 0.000001,
              pointBackgroundColor: "rgb(0,0,0)",
              borderColor: "rgb(255, 0, 0)",
            },
            {
              label: "Incertidumbre",
              data: incertidumbres2,
              fill: false,
              lineTension: 0.000001,
              borderDash: [5, 5],
              borderColor: "rgb(0, 0, 0)",
            },
            {
              label: "Tolerancia del instrumento",
              data: toleranciasneg2,
              fill: false,
              borderDash: [5, 5],
              borderColor: "rgb(0, 0, 255)",
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
        },
        scaleSteps: Math.ceil((max - start) / step),
        scaleStepWidth: step,
        scaleStartValue: start,
      });
    }

    //DOBLE GRAFICA FIN
    var doblegrafica = 0;
    var unidad2 = this.variaciones[this.variacion][7];
    var aumentoEn = this.variaciones2[this.variacion][1];
    var startsIn = this.variaciones2[this.variacion][4];
    var incer = this.variaciones2[this.variacion][0];
    var tole = this.variaciones2[this.variacion][5];
    if (this.variaciones[this.variacion][6]) {
      doblegrafica = 1;
    }

    let global = [];
    global.push(this.data[1][1]);
    global.push(this.data[1][2]);
    global.push("AAA-MM-DD");
    global.push(this.data[1][0]);
    global.push("sitio");

    let clic =
      this.uri +
      "escantillon_variaciones?api_key=" +
      this.api_key +
      "&token=" +
      this.token +
      "&module=2&id_plantilla=11&libc=" +
      listIbc +
      "&inTol=" +
      listInTol +
      "&lerror=" +
      listError +
      "&globales=" +
      global +
      "&id_equipo=" +
      this.data[2][2] +
      "&patron_id=" +
      this.data[4] +
      "&libc2=" +
      listIbc2 +
      "&inTol2=" +
      listInTol2 +
      "&lerror2=" +
      listError2 +
      "&doblegrafica=" +
      doblegrafica +
      "&unidad=" +
      unidad2 +
      "&aumento=" +
      aumentoEn +
      "&start=" +
      startsIn +
      "&incert=" +
      incer +
      "&tole=" +
      tole;
    let a = [];

    a[0] = clic;
    a[1] = this.data[3].identificador + this.data[3].partida;
    a[2] = this.myChart;
    if (doblegrafica == 1) {
      a[3] = this.myChart2;
    }

    informes.push(a);

    if (doblegrafica == 1) {
      this.pdfInformesDouble = informes;
    } else this.pdfInformes = informes;
    this.loader = 2;
  }

  /**
   * This function calculates and displays various measurements and uncertainties related to
   * conductivity, and generates a PDF report.
   */
  private conductividad(): void {
    var incertPdf = [];
    if (this.data[1][4] == 0) {
      var patron1 = 5.0;
      var patron2 = 12.88;
      var patron3 = 111.8;
      this.labels = ["5.000", "12.880", "111.800"];
      var incertidumbre_1 = 0.163;
      var incertidumbre_2 = 0.15;
      var incertidumbre_3 = 0.15;
      var tolerancia_p = 2.0;
      var min_1 = 3.0;
      var min_2 = 10.88;
      var min_3 = 99.8;
      var max_1 = 7.0;
      var max_2 = 14.88;
      var max_3 = 113.8;
    } else if (this.data[1][4] == 1 || this.data[1][4] == 2) {
      var patron1 = 12.88;
      var patron2 = 14.0;
      this.labels = ["12.880", "14.000"];
      var incertidumbre_1 = 0.163;
      var incertidumbre_2 = 0.15;
      var tolerancia_p = 2.0;
      var min_1 = 10.88;
      var min_2 = 12.0;
      var max_1 = 14.88;
      var max_2 = 16.0;
    } else if (this.data[1][4] == 3) {
      var patron1 = 500.0;
      var patron2 = 1400.0;
      this.labels = ["500.000", "1400.000"];
      var incertidumbre_1 = 5.774;
      var incertidumbre_2 = 16.166;
      var tolerancia_p = 50.0;
      var min_1 = 450.0;
      var min_2 = 1350.0;
      var max_1 = 550.0;
      var max_2 = 1450.0;
    }

    var inTol_1 = "";
    var inTol_2 = "";
    var inTol_3 = "";

    var informes = [];
    let listError = [];
    var patrones = [];
    let listIbc = [];
    let listInTol = [];

    listIbc = this.data[0];

    listIbc[0] <= min_1
      ? (inTol_1 = "No cumple")
      : listIbc[0] >= max_1
      ? (inTol_1 = "No cumple")
      : (inTol_1 = "Si cumple");

    listIbc[1] <= min_2
      ? (inTol_2 = "No cumple")
      : listIbc[1] >= max_2
      ? (inTol_2 = "No cumple")
      : (inTol_2 = "Si cumple");

    listInTol.push(inTol_1);
    listInTol.push(inTol_2);
    patrones.push(patron1);
    patrones.push(patron2);

    if (this.data[1][4] == 0) {
      listIbc[2] <= min_3
        ? (inTol_3 = "No cumple")
        : listIbc[2] >= max_3
        ? (inTol_3 = "No cumple")
        : (inTol_3 = "Si cumple");
      listInTol.push(inTol_3);
      patrones.push(patron3);
    }

    var error1 = listIbc[0] - patron1;
    var error2 = listIbc[1] - patron2;
    listError.push(error1);
    listError.push(error2);

    var incertidumbre1 = error1 - incertidumbre_1;
    var incertidumbre2 = error2 - incertidumbre_2;
    var yincertidumbre1 = error1 + incertidumbre_1;
    var yincertidumbre2 = error2 + incertidumbre_2;
    var yincertidumbres = [];
    var incertidumbres = [];
    var tolerancias = [];
    var toleranciasneg = [];
    var line_g = [];

    tolerancias.push(tolerancia_p);
    tolerancias.push(tolerancia_p);
    toleranciasneg.push(-tolerancia_p);
    toleranciasneg.push(-tolerancia_p);

    incertidumbres.push(incertidumbre1);
    incertidumbres.push(incertidumbre2);
    yincertidumbres.push(yincertidumbre1);
    yincertidumbres.push(yincertidumbre2);
    line_g.push(0);
    line_g.push(0);

    if (this.data[1][4] == 0) {
      var error3 = listIbc[2] - patron3;
      listError.push(error3);
      incertPdf = ["0.163", "0.150", "0.150"];
      var incertidumbre3 = error3 - incertidumbre_3;
      var yincertidumbre3 = error3 + incertidumbre_3;
      tolerancias.push(tolerancia_p);
      toleranciasneg.push(-tolerancia_p);
      incertidumbres.push(incertidumbre3);
      yincertidumbres.push(yincertidumbre3);
      line_g.push(0);
    } else if (this.data[1][4] == 1 || this.data[1][4] == 2)
      incertPdf = ["0.163", "0.150"];
    else if (this.data[1][4] == 3) incertPdf = ["5.774", "16.166"];

    //console.log(this.data[1][4])

    this.myChart = new Chart(this.ctx, {
      type: "line",
      data: {
        labels: this.labels,
        datasets: [
          {
            label: "Tolerancia del instrumento",
            data: tolerancias,
            fill: false,
            borderDash: [5, 5],
            borderColor: "rgb(0, 0, 255)",
          },
          {
            label: "Incertidumbre",
            data: yincertidumbres,
            fill: false,
            lineTension: 0.000001,
            borderDash: [5, 5],
            borderColor: "rgb(0, 0, 0)",
          },
          {
            label: "Error = (Media Inst. - Patrón)",
            data: listError,
            fill: false,
            lineTension: 0.000001,
            pointBackgroundColor: "rgb(0,0,0)",
            borderColor: "rgb(255, 0, 0)",
          },
          {
            label: "Linea",
            data: line_g,
            fill: false,
            lineTension: 0.000001,
            pointBackgroundColor: "rgb(0,0,0)",
            borderColor: "rgb(0, 0, 0)",
          },
          {
            label: "Incertidumbre",
            data: incertidumbres,
            fill: false,
            lineTension: 0.000001,
            borderDash: [5, 5],
            borderColor: "rgb(0, 0, 0)",
          },
          {
            label: "Tolerancia del instrumento",
            data: toleranciasneg,
            fill: false,
            borderDash: [5, 5],
            borderColor: "rgb(0, 0, 255)",
          },
          {
            data: [-0.6, -0.6, -0.6],
            fill: false,
            borderColor: "rgb(255, 255, 255)",
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
      },
    });

    let global = [];
    global.push(this.data[1][1]);
    global.push(this.data[1][2]);
    global.push("AAA-MM-DD");
    global.push(this.data[1][0]);
    global.push("sitio");

    let clic =
      this.uri +
      "conductividad_variaciones?api_key=" +
      this.api_key +
      "&token=" +
      this.token +
      "&module=2&id_plantilla=" +
      this.data[1][4] +
      "&libc=" +
      listIbc +
      "&inTol=" +
      listInTol +
      "&lerror=" +
      listError +
      "&globales=" +
      global +
      "&id_equipo=" +
      this.data[2][2] +
      "&patron_id=" +
      this.data[4] +
      "&tole=" +
      tolerancia_p +
      "&medpatron=" +
      patrones +
      "&incertidumbre=" +
      incertPdf;
    let a = [];

    a[0] = clic;
    a[1] = this.data[3].identificador + this.data[3].partida;
    a[2] = this.myChart;
    informes.push(a);

    this.pdfInformes = informes;
    this.loader = 2;
  }

  /**
   * This function calculates and displays the tolerance, error, and uncertainty of a pH meter and
   * generates a PDF report.
   */
  private medidorPh(): void {
    this.loader = 1;
    var min_1 = 3.5;
    var min_2 = 6.5;
    var min_3 = 9.5;
    var max_1 = 4.5;
    var max_2 = 7.5;
    var max_3 = 10.5;

    var inTol_1 = "";
    var inTol_2 = "";
    var inTol_3 = "";

    var informes = [];

    let listError = [];

    let listIbc = [];
    let listInTol = [];

    listIbc = this.data[0];

    listIbc[0] < min_1
      ? (inTol_1 = "No cumple")
      : listIbc[0] > max_1
      ? (inTol_1 = "No cumple")
      : (inTol_1 = "Si cumple");

    listIbc[1] < min_2
      ? (inTol_2 = "No cumple")
      : listIbc[1] > max_2
      ? (inTol_2 = "No cumple")
      : (inTol_2 = "Si cumple");

    listIbc[2] < min_3
      ? (inTol_3 = "No cumple")
      : listIbc[2] > max_3
      ? (inTol_3 = "No cumple")
      : (inTol_3 = "Si cumple");

    listInTol.push(inTol_1);
    listInTol.push(inTol_2);
    listInTol.push(inTol_3);

    var error50 = listIbc[0] - 4.0;
    var error75 = listIbc[1] - 7.0;
    var error100 = listIbc[2] - 10.0;

    listError.push(error50);
    listError.push(error75);
    listError.push(error100);

    var incertidumbre1 = error50 - 0.049;
    var incertidumbre2 = error75 - 0.049;
    var incertidumbre3 = error100 - 0.049;
    var yincertidumbre1 = error50 + 0.049;
    var yincertidumbre2 = error75 + 0.049;
    var yincertidumbre3 = error100 + 0.049;

    var step = 0.0;
    var max = 0.6;
    var start = -0.6;

    this.myChart = new Chart(this.ctx, {
      type: "line",
      data: {
        labels: ["4", "7", "10"],
        datasets: [
          {
            label: "Tolerancia del instrumento",
            data: [0.5, 0.5, 0.5],
            fill: false,
            borderDash: [5, 5],
            borderColor: "rgb(0, 0, 255)",
          },
          {
            label: "Incertidumbre",
            data: [yincertidumbre1, yincertidumbre2, yincertidumbre3],
            fill: false,
            lineTension: 0.000001,
            borderDash: [5, 5],
            borderColor: "rgb(0, 0, 0)",
          },
          {
            label: "Error = (Media Inst. - Patrón)",
            data: [error50, error75, error100],
            fill: false,
            lineTension: 0.000001,
            pointBackgroundColor: "rgb(0,0,0)",
            borderColor: "rgb(255, 0, 0)",
          },
          {
            label: "Incertidumbre",
            data: [incertidumbre1, incertidumbre2, incertidumbre3],
            fill: false,
            lineTension: 0.000001,
            borderDash: [5, 5],
            borderColor: "rgb(0, 0, 0)",
          },
          {
            label: "Tolerancia del instrumento",
            data: [-0.5, -0.5, -0.5],
            fill: false,
            borderDash: [5, 5],
            borderColor: "rgb(0, 0, 255)",
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
      },
      scaleSteps: Math.ceil((max - start) / step),
      scaleStepWidth: step,
      scaleStartValue: start,
    });

    let global = [];
    global.push(this.data[1][1]);
    global.push(this.data[1][2]);
    global.push("AAA-MM-DD");
    global.push(this.data[1][0]);
    global.push("sitio");

    console.log(global);

    let clic =
      this.uri +
      "medidor_ph?api_key=" +
      this.api_key +
      "&token=" +
      this.token +
      "&module=2&id_plantilla=5&libc=" +
      listIbc +
      "&inTol=" +
      listInTol +
      "&globales=" +
      global +
      "&id_equipo=" +
      this.data[2][2] +
      "&patron_id=" +
      this.data[4];
    let a = [];
    a[0] = clic;
    a[1] = this.data[3].identificador + this.data[3].partida;
    a[2] = this.myChart;
    informes.push(a);

    this.pdfInformes = informes;
    this.loader = 2;
  }

  /**
   * The function downloads a file by opening its URL in a new window.
   * @param file_name - The parameter "file_name" is a string that represents the name of the file that
   * needs to be downloaded.
   */
  private downloadFile(file_name): void {
    var url = environment.uri_for_reports + file_name;
    window.open(url, "_blank");
  }

  /**
   * The function assigns a CSV report to a specific equipment and date, and handles different response
   * codes accordingly.
   * @param info - The parameter "info" is an array that contains three elements:
   */
  public asignarInforme(info): void {
    let informe = info[0];
    let _id_equipo = info[1];
    let fecha = info[2];

    this.generadorReports
      .asignarInformeCsv(informe, _id_equipo, fecha)
      .subscribe(
        (res) => {
          this.response = res;
          //console.log(this.response);
          if (
            this.response.code == "1_0001" ||
            this.response.code == "1_0002" ||
            this.response.code == "1_0003"
          )
            this.lauchModal(this.response.code, this.response.message);
          else if (
            this.response.code == "1_0004" ||
            this.response.code == "0_0007"
          )
            this.lauchModal(this.response.code, this.response.message);
          else this.info_req = this.response.data;
          this.loader = 2;
        },
        (error) => {
          this.lauchModal("0000x00", "Error de consulta");
          console.log(error);
          this.loader = 2;
        }
      );
  }

  /**
   * The function obtains an image in base64 format, sends it to a specified URL using a POST request,
   * and downloads the resulting file.
   * @param url_pdf - A string representing the URL where the PDF will be generated and downloaded.
   * @param myChartId - It is a variable that represents the ID of a chart element in the HTML page. It
   * is used to convert the chart into a base64 image that can be sent to the server.
   */
  public obtenerImagen(url_pdf, myChartId): void {
    this.loader = 1;
    url_pdf = url_pdf[0];
    let base64 = myChartId.toBase64Image();

    var to_send: IInforme = {
      img: base64,
    };
    this.conn.post(url_pdf, to_send).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_0007"
        )
          this.lauchModal(this.response.code, this.response.message);
        else this.data = this.response.data;
        let informe = this.data;
        this.loader = 2;
        this.downloadFile(informe);
      },
      (error) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(error);
        this.loader = 2;
      }
    );
  }

  /**
   * The function obtains two base64 images and sends them along with a URL to a server, then downloads
   * the resulting file.
   * @param url_pdf - A string representing the URL to which the POST request will be sent.
   * @param myChartId - It is a variable that likely refers to a chart object or element in the code.
   * The method `toBase64Image()` is called on it to obtain a base64 encoded image.
   * @param dobleChartId - dobleChartId is a variable that likely refers to a chart object or element
   * that contains a chart, which is being converted to a base64 image and sent as part of a request to
   * a server.
   */
  public obtenerImagen2(url_pdf, myChartId, dobleChartId): void {
    this.loader = 1;
    url_pdf = url_pdf[0];
    let base64 = myChartId.toBase64Image();
    let base642 = dobleChartId.toBase64Image();
    var to_send: IInforme = {
      img: base64,
      img2: base642,
    };

    //console.log(url_pdf);
    this.conn.post(url_pdf, to_send).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_0007"
        )
          this.lauchModal(this.response.code, this.response.message);
        else this.data = this.response.data;
        let informe = this.data;
        this.loader = 2;
        this.downloadFile(informe);
      },
      (error) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(error);
        this.loader = 2;
      }
    );
  }

  /**
   * The function obtains an image in base64 format, sends it along with other data to a specified URL
   * using a POST request, and downloads the resulting file.
   * @param url_pdf - The URL where the PDF will be generated and saved.
   * @param myChartId - It is a variable that contains the chart object that needs to be converted to a
   * base64 image.
   * @param check - A boolean value indicating whether a certain condition or requirement has been met
   * or not.
   * @param comment - A string variable that contains any comments or notes related to the image being
   * sent.
   * @param ValIbc - It is a variable that represents the value of IBC (Income Base Contribution) which
   * is used in social security systems to calculate the contributions made by an individual or
   * employer towards social security benefits.
   * @param valError - valError is a variable that represents the error value obtained from a
   * calculation or analysis. It is being passed as a parameter to the function obtenerImagenCheck() to
   * be included in the data object that will be sent in the HTTP POST request.
   */
  public obtenerImagenCheck(
    url_pdf,
    myChartId,
    check,
    comment,
    ValIbc,
    valError
  ): void {
    this.loader = 1;
    url_pdf = url_pdf[0];
    let base64 = myChartId.toBase64Image();

    var to_send: IInforme = {
      img: base64,
      check: check,
      comments: comment,
      libc: ValIbc,
      lerror: valError,
    };
    this.conn.post(url_pdf, to_send).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_0007"
        )
          this.lauchModal(this.response.code, this.response.message);
        else this.data = this.response.data;
        let informe = this.data;
        this.loader = 2;
        this.downloadFile(informe);
      },
      (error) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(error);
        this.loader = 2;
      }
    );
  }

  /**
   * This function assigns a graph image to a PDF report and sends it to a server using HTTP POST
   * request.
   * @param url_pdf - A string representing the URL of a PDF file.
   * @param myChartId - myChartId is a variable that likely contains the ID of a chart element in the
   * HTML document. It is being used to convert the chart image to a base64 encoded string, which is
   * then included in the data sent to the server.
   */
  public asignarInformeGraph(url_pdf, myChartId): void {
    this.loader = 1;
    url_pdf = url_pdf[0];
    let base64 = myChartId.toBase64Image();

    var to_send: IInforme = {
      img: base64,
    };

    this.conn.post(url_pdf + "&asignar=" + "asignar", to_send).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_0007"
        )
          this.lauchModal(this.response.code, this.response.message);
        else this.info_req = this.response.data;
        this.loader = 2;
      },
      (error) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(error);
        this.loader = 2;
      }
    );
  }

  /**
   * This is a TypeScript function that assigns a graph check report with various parameters to a given
   * URL using HTTP POST request.
   * @param url_pdf - A string representing the URL of the PDF file to which the graph check will be
   * assigned.
   * @param myChartId - This parameter is likely an ID or reference to a chart object that is being
   * converted to a base64 image and included in the `to_send` object.
   * @param check - a boolean value indicating whether a certain condition or requirement has been met
   * or not.
   * @param comment - A string containing any comments or notes related to the graph or report being
   * assigned.
   * @param ValIbc - ValIbc is a variable that represents the value of IBC (Income Base Contribution)
   * in the context of the code. It is being passed as a parameter to the function
   * asignarInformeGraphcheck.
   * @param valError - valError is a variable that represents the value of the error in the data being
   * analyzed or displayed in the graph. It is used as a parameter in the function
   * asignarInformeGraphcheck to send this value along with other information to the server for further
   * processing.
   */
  public asignarInformeGraphcheck(
    url_pdf,
    myChartId,
    check,
    comment,
    ValIbc,
    valError
  ): void {
    this.loader = 1;
    url_pdf = url_pdf[0];
    let base64 = myChartId.toBase64Image();

    var to_send: IInforme = {
      img: base64,
      check: check,
      comments: comment,
      libc: ValIbc,
      lerror: valError,
    };

    this.conn.post(url_pdf + "&asignar=" + "asignar", to_send).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_0007"
        )
          this.lauchModal(this.response.code, this.response.message);
        else this.info_req = this.response.data;
        this.loader = 2;
      },
      (error) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(error);
        this.loader = 2;
      }
    );
  }

  /**
   * This function assigns a report by sending two base64 images and a URL to a server using HTTP POST
   * request.
   * @param url_pdf - a string representing the URL where the PDF will be generated and saved.
   * @param myChartId - It is a variable that likely contains a reference to a chart object, which is
   * then converted to a base64 image using the `toBase64Image()` method.
   * @param dobleChartId - dobleChartId is a variable that likely holds a reference to a chart object
   * that can be converted to a base64 image using the toBase64Image() method. This base64 image is
   * then included in an object that is sent as part of a POST request to a specified URL.
   */
  public asignarInforme2(url_pdf, myChartId, dobleChartId): void {
    this.loader = 1;
    url_pdf = url_pdf[0];
    let base64 = myChartId.toBase64Image();
    let base642 = dobleChartId.toBase64Image();

    var to_send: IInforme = {
      img: base64,
      img2: base642,
    };

    this.conn.post(url_pdf + "&asignar=" + "asignar", to_send).subscribe(
      (res) => {
        this.response = res;
        //console.log(this.response);
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_0007"
        )
          this.lauchModal(this.response.code, this.response.message);
        else this.info_req = this.response.data;
        this.loader = 2;
      },
      (error) => {
        console.log(error);
        this.lauchModal("0000x00", "Error de consulta");
        this.loader = 2;
      }
    );
  }

  /**
   * This function sets the variation and size of an object based on a given type.
   * @param tipo - The parameter "tipo" is a variable that represents the type of variation being set.
   * It is used to access specific values in the "variaciones" and "variaciones2" arrays to determine
   * the size and labels of the variation being set.
   */
  private setVariacion(tipo: number): void {
    this.variacion = this.variaciones[tipo][3];
    this.tam = [];
    var sum = this.variaciones2[tipo][4];
    for (var i = 0; i < this.variaciones[tipo][2]; i++) {
      this.tam.push([sum, "-" + i, "in" + i, this.variaciones2[tipo][5]]);
      this.labels.push(sum);
      sum = +this.intlRound(sum + this.variaciones2[tipo][1]);
    }
  }

  /**
   * This is a TypeScript function that rounds a number to a specified number of decimal places and
   * formats it with or without commas based on a boolean parameter.
   * @param numero - The number that needs to be rounded and formatted as a string.
   * @param [decimales=3] - The number of decimal places to round the number to. It has a default value
   * of 3 if not specified.
   * @param [usarComa=false] - The parameter "usarComa" is a boolean value that determines whether to
   * use a comma as the decimal separator or not. If it is set to true, the function will use a comma
   * as the decimal separator. If it is set to false, the function will use a period as the decimal
   * @returns The function `intlRound` returns a string that represents the given `numero` rounded to
   * the specified number of `decimales` and formatted according to the specified `usarComa` parameter.
   * The formatting is done using the `Intl.NumberFormat` object.
   */
  private intlRound(numero, decimales = 3, usarComa = false): string {
    var opciones = {
      maximumFractionDigits: decimales,
      useGrouping: false,
    };
    var usarComa2 = usarComa ? "es" : "en";
    return new Intl.NumberFormat(usarComa2, opciones).format(numero);
  }

  /**
   * This is an asynchronous function that launches a modal with a given code and title using a modal
   * service.
   * @param {string} code - a string representing the code that will be displayed in the modal window.
   * @param {string} title - The title parameter is a string that represents the title of the modal
   * that will be launched.
   */
  private async lauchModal(code: string, title: string): Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

  /**
   * The function changes the current page number to the specified page.
   * @param page - The "page" parameter is a variable that represents the page number that the user
   * wants to navigate to. The "changePageResults" function takes this parameter and sets the
   * "number_page" variable to the value of "page". This function is likely used in a pagination system
   * to allow the user to
   */
  public changePageResults(page): void {
    this.number_page = page;
  }

  /**
   * This function opens a dynamic URL in a new window.
   * @param url_dym - url_dym is a parameter of type string that represents the URL of a file that
   * needs to be opened in a new window.
   */
  public verFile(url_dym): void {
    window.open(url_dym);
  }

  /**
   * The function generates a dynamic template for a PDF report and adds relevant data to an array.
   */
  public plantillaDinamica(): void {
    this.informe_dym = [];
    let clic =
      this.uri +
      "url_plantillaDinamicaPdf?api_key=" +
      this.api_key +
      "&token=" +
      this.token +
      "&module=2&plantilla=" +
      this.data;
    this.informe_dym.push(clic);
    this.informe_dym.push(this.data[8].identificador + this.data[8].partida);
    this.loader = 2;
  }

  /**
   * The function downloads a default file using a specific URI, API key, and token.
   */
  public descargarDefault(): void {
    window.open(
      this.uri +
        "download_default?api_key=" +
        this.api_key +
        "&token=" +
        this.token +
        "&module=2"
    );
  }

  /**
   * This function returns a sanitized URL to a PDF document.
   * @returns The `docURL()` function is returning a sanitized URL to a PDF document. The
   * `sanitizer.bypassSecurityTrustResourceUrl()` method is used to bypass Angular's built-in security
   * and allow the URL to be used as a trusted resource.
   */
  public docURL(): any {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.url_to_pdf);
  }
  
}
