import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/app/variables/enviroment';

@Injectable({
  providedIn: 'root'
})
export class XslxGeneratorService {
  uri:string = environment.uri;
  //uri:string = "http://ngdat.ucal.com.mx/api/";
  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');
  constructor(private httpClient: HttpClient) { }

  getClients(){
    return this.httpClient.get(this.uri+"get_clients_list_xlsx?api_key="+this.api_key+"&token="+this.token+"&module=75");
  }

  getReport(month,client,type,zone,f_ini, f_fin, r_type){
    return this.httpClient.get(this.uri+"get_report_xslx?api_key="+this.api_key+"&token="+this.token+"&module=75&month="+month+"&client="+client+"&type="+type+"&zone="+zone+"&fecha_inicio="+f_ini+"&fecha_fin="+f_fin+"&tipo_rec="+r_type);
  }
}
