import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { IRS } from "src/app/services/models/IRS";
import { ServiceReportService } from "src/app/services/service-report.service";

@Component({
  selector: "app-add-rs",
  templateUrl: "./add-rs.component.html",
  styleUrls: ["./add-rs.component.css"],
})
export class AddRsComponent implements OnInit {
  identifier_odv = "";
  identifier_rs = "RS";
  id_odv = "";
  loader: number = 1;
  response: any = [];
  metrologos: any = [];
  list: any = [];
  validator = 0;
  message = "";
  notes_rs = "N/A";

  constructor(
    private activedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private rs_service: ServiceReportService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.id_odv = this.activedRoute.snapshot.paramMap.get("id_odv");
    this.loadDataToGenerate();
  }

  /**
   * The function loads data to generate a response and launches a modal based on the response code.
   */
  private loadDataToGenerate(): void {
    this.rs_service.getDataToAdd(this.id_odv).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.identifier_odv = this.response.data.odv_identifier;
          this.notes_rs = this.response.data.odv_notes ?? 'N/A';
          this.metrologos = this.response.data.metrologos;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "Error en servidor.");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code, title, and
   * message using the ModalComponent.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - The title of the modal that will be displayed.
   * @param message - The message parameter is likely a string that contains the content to be
   * displayed in the body of the modal dialog box. It could be a message to the user or some other
   * information that needs to be communicated.
   */
  private async lauchModal(
    code: string,
    title: string,
    message
  ): Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }

  /**
   * This is a private function in TypeScript that adds or removes an ID from a list based on whether
   * it already exists in the list.
   * @param id - The id parameter is a unique identifier for a metro station that is being added to or
   * removed from a list of metro stations.
   */
  public setMetro(id): void {
    if (!this.list.includes(id)) this.list.push(id);
    else {
      var i = this.list.indexOf(id);
      this.list.splice(i, 1);
    }
  }

  /**
   * The function validates data and sends a request to save if there are no errors.
   */
  public validateData(): void {
    this.validator = 0;
    //this.identifier_odv.replace(/ /g, "");

    /*if (this.identifier_rs == "RS") {
      this.validator++;
      this.message += "El folio no debe estar vacío.";
    }*/
    if (this.list.length == 0) {
      this.validator++;
      this.message += "Debe seleccionar al menos un metrólogo.";
    }

    if (this.validator == 0) this.sendPeticionToSave();
  }

  /**
   * This function sends a request to save data related to a RS (Request for Service) and handles the
   * response accordingly.
   */
  private sendPeticionToSave(): void {
    this.loader = 1;
    var data: IRS = {
      id_odv: this.id_odv,
      //folio: this.identifier_rs,
      for_users: this.list,
      notas: this.notes_rs,
    };

    this.rs_service.saveRS(data).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_007"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else this.router.navigate(["/operations/rs/edit/", this.response.data]);

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );
  }
}
