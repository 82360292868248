import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CardsStartComponent } from './cards-start/cards-start.component';
import { FormsModule } from '@angular/forms';
import { ModalComponent } from './modal/modal.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { PrivacyNoLoginComponent } from './privacy-no-login/privacy-no-login.component';
import { ModalPdfComponent } from './modal-pdf/modal-pdf.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    FormsModule
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    CardsStartComponent,
    ModalComponent,
    PrivacyComponent,
    PrivacyNoLoginComponent,
    ModalPdfComponent
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    CardsStartComponent
  ]
})
export class ComponentsModule { }
