import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {QrScannerComponent} from 'angular2-qrscanner';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { QrService } from 'src/app/services/qr.service';
@Component({
  selector: 'app-qr',
  templateUrl: './qr.component.html',
  styleUrls: ['./qr.component.css']
})
export class QrComponent implements OnInit {
  @ViewChild(QrScannerComponent, { static : false }) qrScannerComponent: QrScannerComponent ;
  message = '';
  qr_data = '';
  loader = 2;
  response:any = [];
  instr:any = '';
  videoDevices:MediaDeviceInfo[] = [];
  constructor(private qrService:QrService,private modalService:NgbModal) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.qrScannerComponent.getMediaDevices().then(devices => {

      for (const device of devices) {
          if (device.kind.toString() === 'videoinput') {
              this.videoDevices.push(device);
          }
      }
      if (this.videoDevices.length > 0){
          let choosenDev;
          for (const dev of this.videoDevices){
              if (dev.label.toString().includes('back')){
                  choosenDev = dev;
                  break;
              }
          }
          console.log(choosenDev);
          if (choosenDev) {
              this.qrScannerComponent.chooseCamera.next(choosenDev);
          } else {
              this.qrScannerComponent.chooseCamera.next(this.videoDevices[0]);
          }
      }
    });

    this.qrScannerComponent.capturedQr.subscribe(result => {
      console.log(result);
        this.qr_data = result;
        this.loadData();
    });
  }

  cambiarCamara(index){
    this.qrScannerComponent.chooseCamera.next(this.videoDevices[index]);
  }

  loadData(){

    let obj =  this.qr_data.split('_');
    if(obj[0] != 'ETALONS') this.message = 'Este código no pertenece a ETALONS';
    else{
      this.message = '';
      this.loader = 1;
      this.qrService.getReadedQR(obj[2]).subscribe(
        res=>{
          console.log(this.response);
          this.response = res;
          if(this.response.code == '0_007')this.message = 'No se encontró equipo.';
          else this.instr = this.response.data;
          this.loader =2;
        },err=>{
          this.lauchModal('0000x00','Error de consulta');
          console.log(err);
          this.loader = 2;
        }
      );
    }
  }

  lauchModal(code:string,title:string){
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

}
