import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { InstrumentService } from 'src/app/services/instrument.service';
import { environment } from 'src/app/variables/enviroment';

@Component({
  selector: 'app-reports-list',
  templateUrl: './reports-list.component.html',
  styleUrls: ['./reports-list.component.css']
})
export class ReportsListComponent implements OnInit {
  loader:number = 1;
  response:any = [];
  data:any= [];
  number_page= 0;
  pages = 0;
  permmisions = JSON.parse(localStorage.getItem('permisos'));

  fecha_inicio:any;
  fecha_final:any;

  clients:any;
  client:string='0';

  deptos:any;
  depto:string='0';

  type_report:string="1";

  url_to_load = '';
  uri:string = environment.uri;
  private currentDate = new Date();

  vista_informes_mant=0;

  constructor(
    private modalService:NgbModal,
    private instrument_service:InstrumentService
  ) { }

  ngOnInit(): void {
    this.loadList();
  }

  changeView(){
    if(this.vista_informes_mant==1){
      this.vista_informes_mant=0;
      this.loadList();
    }else{
      this.vista_informes_mant=1;
      this.loadListMaintenance();
    }
  }

  loadList(){
    this.loader = 1;
    this.instrument_service.getAllReports().subscribe(
      res=>{
        this.response = res;
        if(this.response.code != '0_005')this.lauchModal(this.response.code,this.response.message)
        else {
          this.data = this.response.data;
          this.pages = this.data.length;
          if(this.permmisions.includes(148)){
            this.getClients();
          }
        }
        
        this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        console.log(err);
        this.loader = 2;
      }
    );
  }

  loadListMaintenance(){
    this.loader = 1;
    this.instrument_service.getAllReportsMaintenance().subscribe(
      res=>{
        this.response = res;
        if(this.response.code != '0_005')this.lauchModal(this.response.code,this.response.message)
        else {
          this.data = this.response.data;
          this.pages = this.data.length;
          console.log(this.data)
        }
        
        this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        console.log(err);
        this.loader = 2;
      }
    );
  }

  changePageResults(page){this.number_page = page;}

  lauchModal(code:string,title:string){
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }


  getClients(){
    this.instrument_service.getClientsByUser().subscribe(
      res=>{
        this.response = res;
        if(this.response.code != "0_005")this.lauchModal(this.response.code,this.response.message)
        else{
          this.clients = this.response.data;
        }
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        console.log(err);
        this.loader = 2;
      }
    );
  }

  getDeptos(){
    this.instrument_service.getDeptosByClientUser(this.client).subscribe(
      res=>{
        this.response = res;
        if(this.response.code != "0_005")this.lauchModal(this.response.code,this.response.message)
        else{
          this.deptos = this.response.data
        }
      }
    )
  }

  generarZip(){
    let mesActual:any = this.currentDate.getMonth() + 1;

    if(mesActual<10){
      mesActual = "0" + mesActual.toString();
    }
    let mes_select = this.fecha_inicio.split('-')[1];
    let mes_min = this.getCurrentDateDay()
    let mes_max = this.fecha_final.split('-')[1];

    if(mes_select < mes_min || mes_select > mesActual)
      alert('Ingresa una fecha valida')
    else if(mes_max > mesActual) alert('Ingresa una fecha valida') 
    else {

      this.instrument_service.downloadReportsPdfByDate(this.fecha_inicio,this.fecha_final,this.client,this.type_report).subscribe(
        res=>{
          this.response = res;
          if(this.response.code != "0_005")this.lauchModal(this.response.code,this.response.message);
          else{
            if(this.response.data==0) this.lauchModal('Error','No se encontró información');
            else this.downloadFile(this.response.data);
          }
          
        },err=>{
          this.lauchModal('0000x00','Error de consulta')
          console.log(err);
          this.loader=2;
        }
      )
    }
    
  }

  getCurrentDateDay(): string{
    let day:any = this.currentDate.getDate();
    let month:any = this.currentDate.getMonth() + 1;
    let year:any = this.currentDate.getFullYear();
    let dateInApiFormat: string;
      
    if(day<10) day = "0" + day.toString();

    month = month - 2;
      
    if(month<10) month = "0" + month.toString();
    return month;
  }

  downloadFile(file_name){
    var url = environment.uri_server+'storage/public/informes_zip/'+file_name;
    window.open(url, '_blank')
  }

  
}
