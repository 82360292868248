import { Component } from '@angular/core';
import { NotificationsService } from './services/notifications.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'argon-dashboard-angular';
  showAlert = false;
  nameNotification = 'Actualizacion odv';
  descriptioNotification:any;

  constructor(private alertService:NotificationsService){}

  ngOnInit(){
    this.alertService.alert$.subscribe((res:any) => {
      this.nameNotification = res.title;
      this.descriptioNotification = res.description;
      this.showAlert=true;
      setTimeout(() => {
        this.showAlert = false;
      }, res.time);
    })
  }

  closeAlert(){
    this.showAlert=false;
  }

}
