import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ICommentODV } from './models/ICommentODV';
import { IOdv } from './models/IOdv';
import { IPart } from './models/IPart';
import { IPartsStatusODV } from './models/IPartsStatusODV';
import { environment } from 'src/app/variables/enviroment';

@Injectable({
  providedIn: 'root'
})
export class OrdenVentaService {
  uri:string = environment.uri;
  //uri:string = "http://ngdat.ucal.com.mx/api/";

  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');
  
  constructor(private httpClient:HttpClient) { }

  getODVList(anio_selected){
    return this.httpClient.get(this.uri+"get_all_odvs?api_key="+this.api_key+"&token="+this.token+"&module=7&anio_select="+anio_selected);
  }

  getOdvListForProfiability(){
    return this.httpClient.get(this.uri+"get_odvs_facturadas?api_key="+this.api_key+"&token="+this.token+"&module=76");
  }
  getOdvListForProfiabilityConcepts(){
    return this.httpClient.get(this.uri+"get_odvs_facturadas_conceptos?api_key="+this.api_key+"&token="+this.token+"&module=76");
  }

  getFacturasListForProfiability(id_odv){
    return this.httpClient.get(this.uri+"get_facturas_para_odv?api_key="+this.api_key+"&token="+this.token+"&module=76&id_odv="+id_odv);
  }

  getFacturasListForProfiabilityConcepts(id_odv){
    return this.httpClient.get(this.uri+"get_facturas_para_odv_conceptos?api_key="+this.api_key+"&token="+this.token+"&module=76&id_odv="+id_odv);
  }

  setConceptosFactura(id_factura, c_mano_obra, c_viaticos, c_uso_patron){
    return this.httpClient.get(this.uri+"set_conceptos_factura?api_key="+this.api_key+"&token="+this.token+"&module=76&id_factura="+id_factura+"&mano_obra="+c_mano_obra+"&viaticos="+c_viaticos+"&uso_patron="+c_uso_patron);
  }
  
  getODVDetail(id_odv){
    return this.httpClient.get(this.uri+"get_detail_odv?api_key="+this.api_key+"&token="+this.token+"&module=8&odv="+id_odv);
  }

  getOdvToEdit(id_odv){
    return this.httpClient.get(this.uri+"get_odv_edit?api_key="+this.api_key+"&token="+this.token+"&module=30&odv="+id_odv);
  }

  sendSurveyEmail(data){
    return this.httpClient.post(this.uri+"send_email_odv_survey?api_key="+this.api_key+"&token="+this.token+"&module=93",data);
  }

  //Metodos para crear ODV con o sin XLSX y con Almacen
  saveOdvWithFile(data:any):Observable<any>{
    return this.httpClient.post(this.uri+"add_odv_file?api_key="+this.api_key+"&token="+this.token+"&module=9",data);
  }
  saveOdv(data:any):Observable<any>{
    return this.httpClient.post(this.uri+"add_odv?api_key="+this.api_key+"&token="+this.token+"&module=9",data);
  }
  saveOdvWithDepot(data:any):Observable<any>{
    return this.httpClient.post(this.uri+"add_odv_depot?api_key="+this.api_key+"&token="+this.token+"&module=9",data);
  }



  //Metodo para agregar una partida
  addPartToODV(data:IPart):Observable<any>{
    return this.httpClient.post(this.uri+"add_part_to_odv?api_key="+this.api_key+"&token="+this.token+"&module=30",data);
  }

  getClientSearch(client){
    return this.httpClient.get(this.uri+"search_value_to_odv?api_key="+this.api_key+"&token="+this.token+"&module=157&client_value="+client);
  }

  addDocumentoToODV(data:any):Observable<any>{
    return this.httpClient.post(this.uri+"add_odv_extra_file?api_key="+this.api_key+"&token="+this.token+"&module=31",data);
  }

  editGeneralDataODV(data:IOdv):Observable<any>{
    return this.httpClient.post(this.uri+"edit_general_data_odv?api_key="+this.api_key+"&token="+this.token+"&module=30",data);
  }

  editSpecificPart(data:IPart):Observable<any>{
    return this.httpClient.post(this.uri+"edit_general_data_part?api_key="+this.api_key+"&token="+this.token+"&module=30",data);
  }

  addComment(data:ICommentODV):Observable<any>{
    return this.httpClient.post(this.uri+"add_comment_odv?api_key="+this.api_key+"&token="+this.token+"&module=8",data);
  }

  sendEmail(titulo, mensaje, para, id_odv, identificador):Observable<any>{
    return this.httpClient.get(this.uri+"send_mail_odv?api_key="+this.api_key+"&token="+this.token+"&module=8&allemails="+para+"&titulo="+titulo+"&odv_id="+id_odv+"&odv_idt="+identificador+"&mensaje="+mensaje);
  }

  sendToChangeStatusParts(data:IPartsStatusODV){
    return this.httpClient.post(this.uri+"change_parts_odv_status?api_key="+this.api_key+"&token="+this.token+"&module=65",data);
  }

  getPartsOdvQr(id_odv){
    return this.httpClient.get(this.uri+"get_partidas_qr?api_key="+this.api_key+"&token="+this.token+"&module=7&odv_id="+id_odv);
  }

  getOdvListForDetEmail(){
    return this.httpClient.get(this.uri+"get_odvs_for_email?api_key="+this.api_key+"&token="+this.token+"&module=7");
  }

  getOdvDetailForDetEmail(id_odv){
    return this.httpClient.get(this.uri+"get_odv_detail_for_email?api_key="+this.api_key+"&token="+this.token+"&module=7&id_odv="+id_odv);
  }

  sendDataToCompareParts(id_odv){
    return this.httpClient.get(this.uri+"get_comparison_result?api_key="+this.api_key+"&token="+this.token+"&module=109&id_file="+id_odv);
  }
  
  getOdvs(id_odv){
    return this.httpClient.get(this.uri+"get_siguente_odv?api_key="+this.api_key+"&token="+this.token+"&module=7&id="+id_odv)
  }

  downloadFastDataZip(id_odv){
    return this.httpClient.get(this.uri + "get_zip_reports_odv?api_key="+this.api_key+"&token="+this.token+"&module=7&id="+id_odv)
  }
  downloadAllDataZip(data){
    return this.httpClient.post(this.uri + "get_zip_reports_odv_custom?api_key="+this.api_key+"&token="+this.token+"&module=4",data)
  }

  searchODVForAddDepotEntry(value){
    return this.httpClient.get(this.uri + "search_odv_add_depot?api_key="+this.api_key+"&token="+this.token+"&module=103&value="+value)
  }

  //Buscar y reemplazar
  searchInstrumentToReplace(value,id_client){
    return this.httpClient.get(this.uri + "search_instrument_to_replace?api_key="+this.api_key+"&token="+this.token+"&module=30&value="+value+"&client="+id_client)
  }

  replaceInstrument(data){
    return this.httpClient.post(this.uri + "replace_instrument_odv2?api_key="+this.api_key+"&token="+this.token+"&module=30",data)
  }

  getClientSearchCot(client){
    return this.httpClient.get(this.uri+"search_value_to_odv?api_key="+this.api_key+"&token="+this.token+"&module=21&client_value="+client);
  }

  downloadPdf(id_odv){
    return this.httpClient.get(this.uri+"get_pdf_odv?api_key="+this.api_key+"&token="+this.token+"&module=66&id_odv="+id_odv)
  }

  markAsBilled(id_odv){
    return this.httpClient.get(this.uri+"mark_billed_odv?api_key="+this.api_key+"&token="+this.token+"&module=168&id_odv="+id_odv);
  }

  deteleDuplicatePart(data){
    return this.httpClient.post(this.uri + "delete_duplicate_part?api_key="+this.api_key+"&token="+this.token+"&module=30",data);
  }


  /// Notificaciones

  odvByMonth() {
    return this.httpClient.get(this.uri+"get_odvs_by_month?api_key="+this.api_key+"&token="+this.token+"&module=167"); 
  }

  cloneCotNotification(data){
    return this.httpClient.post(this.uri+"send_clone_cot_notifications_odv?api_key="+this.api_key+"&token="+this.token+"&module=167",data);
  }

  sendEmailCotNotification(data){
    return this.httpClient.post(this.uri+"send_email_notifications_odv?api_key="+this.api_key+"&token="+this.token+"&module=167",data);
  }

  getInfoCotEmail(id_cot){
    return this.httpClient.get(this.uri+"get_info_cot_email?api_key="+this.api_key+"&token="+this.token+"&module=167&id_cot="+id_cot);
  }


}
