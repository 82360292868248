import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { TicketsService } from "src/app/services/tickets.service";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { environment } from "src/app/variables/enviroment";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-tickets",
  templateUrl: "./tickets.component.html",
  styleUrls: ["./tickets.component.css"],
})
export class TicketsComponent implements OnInit {
  loader = 1;
  response: any = [];
  ticket_list: any = [];
  month_selected = 0;
  file_name = "";
  tipo_reporte: string = "";

  id_mount = 0;

  months: any = [
    { id: "0", month: "Seleccionar" },
    { id: "01", month: "Enero" },
    { id: "02", month: "Febrero" },
    { id: "03", month: "Marzo" },
    { id: "04", month: "Abril" },
    { id: "05", month: "Mayo" },
    { id: "06", month: "Junio" },
    { id: "07", month: "Julio" },
    { id: "08", month: "Agosto" },
    { id: "09", month: "Septiembre" },
    { id: "10", month: "Octubre" },
    { id: "11", month: "Noviembre" },
    { id: "12", month: "Diciembre" },
  ];

  @ViewChild("btn_close_modal") divView: ElementRef;
  permmisions = JSON.parse(localStorage.getItem("permisos"));

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private ticketService: TicketsService
  ) {}

  ngOnInit(): void {
    this.getAllList();
  }

  /**
   * The function retrieves a list of tickets and handles different response codes accordingly.
   */
  private getAllList() :void {
    this.loader = 1;
    this.ticketService.getTicketList().subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else this.ticket_list = this.response.data;
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * The function adds a new ticket and handles the response accordingly.
   */
  public addTicket() :void {
    this.response = [];
    this.loader = 1;
    this.ticketService.addNewTicket().subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else
          this.router.navigate([
            "/programmers/tickets/add_ticket",
            this.response.data,
          ]);
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This function launches a modal with a given code, title, and message using the Angular
   * ModalService.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - The title of the modal that will be displayed.
   * @param message - The message parameter is likely a string that contains the content to be
   * displayed in the body of the modal dialog box. It could be a message to the user or some other
   * information that needs to be communicated.
   */
  private async lauchModal(code: string, title: string, message) :Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }

  /**
   * This is a private function in TypeScript that sets the month selected based on the provided ID.
   * @param id - The "id" parameter is a value that represents the type of month selected. It is used
   * in the "setType" method to set the "month_selected" property to the selected month type.
   */
  public setType(id) :void {
    this.month_selected = id;
  }

 /**
  * This is a private function in TypeScript that sets the type of a report.
  * @param tipo - tipo is a parameter of the function setTypeReport(). It represents the type of report
  * that needs to be set. The function sets the value of the instance variable tipo_reporte to the
  * value of the tipo parameter.
  */
 public setTypeReport(tipo) :void {
    this.tipo_reporte = tipo;
  }

  /**
   * This function generates a report either by user or by registration, depending on the type of
   * report selected.
   */
  public generarInforme() :void {
    this.loader = 1;
    if (this.tipo_reporte == "2") this.reportePorRegistro();
    else this.reportePorUsuario();
  }

  /**
   * This function retrieves a ticket report for a selected month and downloads the file if available.
   */
  private reportePorRegistro() :void {
    this.ticketService.getTicketReport(this.month_selected).subscribe(
      (res) => {
        this.response = res;
        if (this.response.code != "0_005" || this.response.data == "0")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.file_name = this.response.data;
          this.downloadFile();
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This function retrieves a ticket report by user and downloads the file if available, otherwise it
   * displays an error message.
   */
  private reportePorUsuario() :void {
    this.ticketService.getTicketReportByUser(this.month_selected).subscribe(
      (res) => {
        this.response = res;
        if (this.response.code != "0_005" || this.response.data == "0")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.file_name = this.response.data;
          this.downloadFile();
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * The function downloads a file from a specified URL and opens it in a new window.
   */
  private downloadFile() :void {
    //var url = "http://ngdat.ucal.com.mx/storage/public/plantillas_reportes_xslx/generados/"+this.file_name;
    var url =
      environment.uri_server + "storage/public/soporte/" + this.file_name;
    window.open(url, "_blank");
  }
}
