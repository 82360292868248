import { Injectable } from '@angular/core';
import { environment } from 'src/app/variables/enviroment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { IMarathon } from './models/IMarathon';

@Injectable({
  providedIn: 'root'
})
export class IndicatorService {
  uri:string = environment.uri;
  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');
  constructor(private httpClient:HttpClient,private router:Router) { }

  setIndicatorsForUser(id_user,values){
    return this.httpClient.post(this.uri+"set_indicator_for_user?api_key="+this.api_key+"&token="+this.token+"&module=90&id="+id_user,values);
  }

  getAllIndicators(mes){
    return this.httpClient.get(this.uri+"get_indicator_metrologist?api_key="+this.api_key+"&token="+this.token+"&module=88&month="+mes+"&anio=2022");
  }

  getAllIndicatorsXLSX(mes){
    return this.httpClient.get(this.uri+"get_xlsx_general_indicator?api_key="+this.api_key+"&token="+this.token+"&module=88&month="+mes);
  }

  getInstrumentWithoutReports(){
    return this.httpClient.get(this.uri+"get_instrument_without_report?api_key="+this.api_key+"&token="+this.token+"&module=88");
  }

  //Maratones
  saveMarathon(data: IMarathon){
    return this.httpClient.post(this.uri+"save_new_marathon?api_key="+this.api_key+"&token="+this.token+"&module=91",data);
  }

  getAllMarathons(){
    return this.httpClient.get(this.uri+"get_all_marathon?api_key="+this.api_key+"&token="+this.token+"&module=88");
  }

  getMarathonDetail(id_marathon){
    return this.httpClient.get(this.uri+"get_detail_marathon?api_key="+this.api_key+"&token="+this.token+"&module=92&id_marathon="+id_marathon);
  }

  setStatusMarathon(id_marathon, status){
    return this.httpClient.get(this.uri+"set_status_marathon?api_key="+this.api_key+"&token="+this.token+"&module=92&id_marathon="+id_marathon+"&status="+status);
  }
}
