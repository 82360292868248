import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { GeneratorReportsService } from 'src/app/services/generator-reports.service';
import { environment } from 'src/app/variables/enviroment';

@Component({
  selector: 'app-manometro-praxair',
  templateUrl: './manometro-praxair.component.html',
  styleUrls: ['./manometro-praxair.component.css']
})
export class ManometroPraxairComponent implements OnInit {

  uri:string = environment.uri;
  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');
  url_to_load = '';

  idsEquipos:any=[];
  idShet:string='';
  idpatron:any=[];

  equiposOdv:any=[]
  pdfInformes:any=[];

  loader:number = 1;
  response:any = [];
  data:any=[];

  media_1:number;
  media_2:number;
  media_3:number;
  media_4:number;
  media_5:number;
  media_6:number;
  media_7:number;
  media_8:number;
  media_9:number;
  media_10:number;

  insert_1:number;
  insert_2:number;
  insert_3:number;
  insert_4:number;
  insert_5:number;
  insert_6:number;
  insert_7:number;
  insert_8:number;
  insert_9:number;
  insert_10:number;

  lugar:any = "sitio";
  temp:any;
  unidad:any;
  format:any = "AAA-MM-DD";
  fecha:any;

  variante:number = 1;

  constructor(
    private datos: ActivatedRoute, 
    private router:Router, 
    public conn: HttpClient,
    private generadorReports:GeneratorReportsService, 
    private modalService:NgbModal
  ) { }

  ngOnInit(): void {
    let datos=this.datos.snapshot.paramMap.get('id_equipos')
    this.idsEquipos.push(datos.split(','));
    this.idShet = this.datos.snapshot.paramMap.get('id_hoja');
    this.idpatron = this.datos.snapshot.paramMap.get('id_patron');
    this.getData();
  }


  getData(){
    this.generadorReports.verPlantilla(this.idShet,this.idsEquipos).subscribe(
      res=>{
        this.response = res;
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
        else this.data = this.response.data;

        this.equiposOdv = this.data.equipos;

        this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        this.loader = 2;
      }
    )
   
  }

  obtenerDatos(){
    var inTol_1=""; var inTol_2=""; var inTol_3=""; var inTol_4=""; var inTol_5="";
    var inTol_11=""; var inTol_12=""; var inTol_13=""; var inTol_14=""; var inTol_15="";

    var maxmediap1 = 24; var minmediap1 = 16; 
    var maxmediap2 = 54; var minmediap2 = 46;
    var maxmediap3 = 104; var minmediap3 = 96; 
    var maxmediap4 = 154; var minmediap4 = 146;
    var maxmediap5 = 204; var minmediap5 = 196;

    if(this.variante == 1){
      maxmediap1 = 24; minmediap1 = 16; 
      maxmediap2 = 54; minmediap2 = 46;
      maxmediap3 = 104; minmediap3 = 96; 
      maxmediap4 = 154; minmediap4 = 146;
      maxmediap5 = 204; minmediap5 = 196;
    }else if(this.variante==2){
      maxmediap1 = 72; minmediap1 = 48; 
      maxmediap2 = 162; minmediap2 = 138;
      maxmediap3 = 312; minmediap3 = 288; 
      maxmediap4 = 462; minmediap4 = 438;
      maxmediap5 = 612; minmediap5 = 588;
    }else{
      maxmediap1 = 48; minmediap1 = 32; 
      maxmediap2 = 108; minmediap2 = 92;
      maxmediap3 = 208; minmediap3 = 192; 
      maxmediap4 = 328; minmediap4 = 312;
      maxmediap5 = 408; minmediap5 = 392;
    }
    
    var informes=[];
    for (let i = 0; i < this.equiposOdv.length; i++) {
      let listInTol = [];
      let listMedia = [];
      let listInsert = [];
      let equipo_id = this.equiposOdv[i].idEquipo;

      this.media_1 = parseFloat((document.getElementById(equipo_id+"media0") as HTMLInputElement).value);
      listMedia.push(this.media_1);
      this.media_2 = parseFloat((document.getElementById(equipo_id+"media1") as HTMLInputElement).value);
      listMedia.push(this.media_2);
      this.media_3 = parseFloat((document.getElementById(equipo_id+"media2") as HTMLInputElement).value);
      listMedia.push(this.media_3);
      this.media_4 = parseFloat((document.getElementById(equipo_id+"media3") as HTMLInputElement).value);
      listMedia.push(this.media_4);
      this.media_5 = parseFloat((document.getElementById(equipo_id+"media4") as HTMLInputElement).value);
      listMedia.push(this.media_5);

      
        this.insert_1 = parseFloat((document.getElementById(equipo_id+"insert0") as HTMLInputElement).value);
        listInsert.push(this.insert_1);
        this.insert_2 = parseFloat((document.getElementById(equipo_id+"insert1") as HTMLInputElement).value);
        listInsert.push(this.insert_2);
        this.insert_3 = parseFloat((document.getElementById(equipo_id+"insert2") as HTMLInputElement).value);
        listInsert.push(this.insert_3);
        this.insert_4 = parseFloat((document.getElementById(equipo_id+"insert3") as HTMLInputElement).value);
        listInsert.push(this.insert_4);
        this.insert_5 = parseFloat((document.getElementById(equipo_id+"insert4") as HTMLInputElement).value);
        listInsert.push(this.insert_5);
      

      this.media_1 > maxmediap1 ? inTol_1 = "No cumple" : this.media_1 < minmediap1?
      inTol_1 = "No cumple": inTol_1 = "Si cumple";

      this.media_2 > maxmediap2 ? inTol_2 = "No cumple" : this.media_2 < minmediap2?
      inTol_2 = "No cumple": inTol_2 = "Si cumple";

      this.media_3 > maxmediap3 ? inTol_3 = "No cumple" : this.media_3 < minmediap3?
      inTol_3 = "No cumple": inTol_3 = "Si cumple";

      this.media_4 > maxmediap4 ? inTol_4 = "No cumple" : this.media_4 < minmediap4?
      inTol_4 = "No cumple": inTol_4 = "Si cumple";

      this.media_5 > maxmediap5 ? inTol_5 = "No cumple" : this.media_5 < minmediap5?
      inTol_5 = "No cumple": inTol_5 = "Si cumple";

      (document.getElementById(equipo_id+"juicio0") as HTMLInputElement).setAttribute("value",inTol_1);
      (document.getElementById(equipo_id+"juicio1") as HTMLInputElement).setAttribute("value",inTol_2);
      (document.getElementById(equipo_id+"juicio2") as HTMLInputElement).setAttribute("value",inTol_3);
      (document.getElementById(equipo_id+"juicio3") as HTMLInputElement).setAttribute("value",inTol_4);
      (document.getElementById(equipo_id+"juicio4") as HTMLInputElement).setAttribute("value",inTol_5);

      this.media_6 = parseFloat((document.getElementById(equipo_id+"media5") as HTMLInputElement).value);
      listMedia.push(this.media_6);
      this.media_7 = parseFloat((document.getElementById(equipo_id+"media6") as HTMLInputElement).value);
      listMedia.push(this.media_7);
      this.media_8 = parseFloat((document.getElementById(equipo_id+"media7") as HTMLInputElement).value);
      listMedia.push(this.media_8);
      this.media_9 = parseFloat((document.getElementById(equipo_id+"media8") as HTMLInputElement).value);
      listMedia.push(this.media_9);
      this.media_10 = parseFloat((document.getElementById(equipo_id+"media9") as HTMLInputElement).value);
      listMedia.push(this.media_10);

      this.insert_6 = parseFloat((document.getElementById(equipo_id+"insert5") as HTMLInputElement).value);
      listInsert.push(this.insert_6);
      this.insert_7 = parseFloat((document.getElementById(equipo_id+"insert6") as HTMLInputElement).value);
      listInsert.push(this.insert_7);
      this.insert_8 = parseFloat((document.getElementById(equipo_id+"insert7") as HTMLInputElement).value);
      listInsert.push(this.insert_8);
      this.insert_9 = parseFloat((document.getElementById(equipo_id+"insert8") as HTMLInputElement).value);
      listInsert.push(this.insert_9);
      this.insert_10 = parseFloat((document.getElementById(equipo_id+"insert9") as HTMLInputElement).value);
      listInsert.push(this.insert_10);

      this.media_6 > maxmediap5 ? inTol_11 = "No cumple" : this.media_6 < minmediap5?
      inTol_11 = "No cumple": inTol_11 = "Si cumple";

      this.media_7 > maxmediap4 ? inTol_12 = "No cumple" : this.media_7 < minmediap4?
      inTol_12 = "No cumple": inTol_12 = "Si cumple";

      this.media_8 > maxmediap3 ? inTol_13 = "No cumple" : this.media_8 < minmediap3?
      inTol_13 = "No cumple": inTol_13 = "Si cumple";

      this.media_9 > maxmediap2 ? inTol_14 = "No cumple" : this.media_9 < minmediap2?
      inTol_14 = "No cumple": inTol_14 = "Si cumple";

      this.media_10 > maxmediap1 ? inTol_15 = "No cumple" : this.media_10 < minmediap1?
      inTol_15 = "No cumple": inTol_15 = "Si cumple";

      (document.getElementById(equipo_id+"juicio5") as HTMLInputElement).setAttribute("value",inTol_11);
      (document.getElementById(equipo_id+"juicio6") as HTMLInputElement).setAttribute("value",inTol_12);
      (document.getElementById(equipo_id+"juicio7") as HTMLInputElement).setAttribute("value",inTol_13);
      (document.getElementById(equipo_id+"juicio8") as HTMLInputElement).setAttribute("value",inTol_14);
      (document.getElementById(equipo_id+"juicio9") as HTMLInputElement).setAttribute("value",inTol_15);

      listInTol.push(inTol_1); listInTol.push(inTol_2); listInTol.push(inTol_3);
      listInTol.push(inTol_4); listInTol.push(inTol_5);

      listInTol.push(inTol_11); listInTol.push(inTol_12); listInTol.push(inTol_13);
      listInTol.push(inTol_14); listInTol.push(inTol_15);

      if(this.temp == null || this.unidad == null || this.fecha == null) {
        alert("Campos vacios");
      } else {
        let global = [];
        global.push(this.temp);
        global.push(this.unidad);
        global.push(this.format);
        global.push(this.fecha);
        global.push(this.lugar);
        
        let clic = this.uri+"manometro_informe_pdf?api_key="+this.api_key+"&token="+this.token+
              "&module=2&id_plantilla="+this.idShet+"&lmedia="+listMedia+"&tol="+listInTol+
              "&globales="+global+"&id_equipo="+equipo_id+"&patron_id="+
              this.idpatron+"&variante="+this.variante+"&insert="+listInsert;
        let a=[];
        a[0] = clic;
        a[1]=this.equiposOdv[i].identificador+this.equiposOdv[i].partida;
        informes.push(a);
        
      }

    }

    this.pdfInformes = informes;
    this.loader = 2;  
  }

  lauchModal(code:string,title:string){
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

  selectVariante(){
    if (this.variante==1) {
      for (let i = 0; i < this.equiposOdv.length; i++) {
        let equipo_id = this.equiposOdv[i].idEquipo;

        (<HTMLInputElement> document.getElementById(equipo_id+"insert0")).disabled = true;
        (<HTMLInputElement> document.getElementById(equipo_id+"insert1")).disabled = true;
        (<HTMLInputElement> document.getElementById(equipo_id+"insert2")).disabled = true;
        (<HTMLInputElement> document.getElementById(equipo_id+"insert3")).disabled = true;
        (<HTMLInputElement> document.getElementById(equipo_id+"insert4")).disabled = true;
        (<HTMLInputElement> document.getElementById(equipo_id+"insert5")).disabled = true;
        (<HTMLInputElement> document.getElementById(equipo_id+"insert6")).disabled = true;
        (<HTMLInputElement> document.getElementById(equipo_id+"insert7")).disabled = true;
        (<HTMLInputElement> document.getElementById(equipo_id+"insert8")).disabled = true;
        (<HTMLInputElement> document.getElementById(equipo_id+"insert9")).disabled = true;

        (document.getElementById(equipo_id+"patron0") as HTMLInputElement).setAttribute("value",'20');
        (document.getElementById(equipo_id+"patron1") as HTMLInputElement).setAttribute("value",'50');
        (document.getElementById(equipo_id+"patron2") as HTMLInputElement).setAttribute("value",'100');
        (document.getElementById(equipo_id+"patron3") as HTMLInputElement).setAttribute("value",'150');
        (document.getElementById(equipo_id+"patron4") as HTMLInputElement).setAttribute("value",'200');
        (document.getElementById(equipo_id+"patron5") as HTMLInputElement).setAttribute("value",'200');
        (document.getElementById(equipo_id+"patron6") as HTMLInputElement).setAttribute("value",'150');
        (document.getElementById(equipo_id+"patron7") as HTMLInputElement).setAttribute("value",'100');
        (document.getElementById(equipo_id+"patron8") as HTMLInputElement).setAttribute("value",'50');
        (document.getElementById(equipo_id+"patron9") as HTMLInputElement).setAttribute("value",'20');

        (document.getElementById(equipo_id+"insert0") as HTMLInputElement).setAttribute("value",'1.4');
        (document.getElementById(equipo_id+"insert1") as HTMLInputElement).setAttribute("value",'1.4');
        (document.getElementById(equipo_id+"insert2") as HTMLInputElement).setAttribute("value",'1.5');
        (document.getElementById(equipo_id+"insert3") as HTMLInputElement).setAttribute("value",'1.5');
        (document.getElementById(equipo_id+"insert4") as HTMLInputElement).setAttribute("value",'1.5');
        (document.getElementById(equipo_id+"insert5") as HTMLInputElement).setAttribute("value",'1.5');
        (document.getElementById(equipo_id+"insert6") as HTMLInputElement).setAttribute("value",'1.5');
        (document.getElementById(equipo_id+"insert7") as HTMLInputElement).setAttribute("value",'1.5');
        (document.getElementById(equipo_id+"insert8") as HTMLInputElement).setAttribute("value",'1.4');
        (document.getElementById(equipo_id+"insert9") as HTMLInputElement).setAttribute("value",'1.4');

        (document.getElementById(equipo_id+"tole0") as HTMLInputElement).setAttribute("value",'4.0');
        (document.getElementById(equipo_id+"tole1") as HTMLInputElement).setAttribute("value",'4.0');
        (document.getElementById(equipo_id+"tole2") as HTMLInputElement).setAttribute("value",'4.0');
        (document.getElementById(equipo_id+"tole3") as HTMLInputElement).setAttribute("value",'4.0');
        (document.getElementById(equipo_id+"tole4") as HTMLInputElement).setAttribute("value",'4.0');
        (document.getElementById(equipo_id+"tole5") as HTMLInputElement).setAttribute("value",'4.0');
        (document.getElementById(equipo_id+"tole6") as HTMLInputElement).setAttribute("value",'4.0');
        (document.getElementById(equipo_id+"tole7") as HTMLInputElement).setAttribute("value",'4.0');
        (document.getElementById(equipo_id+"tole8") as HTMLInputElement).setAttribute("value",'4.0');
        (document.getElementById(equipo_id+"tole9") as HTMLInputElement).setAttribute("value",'4.0');
      }
    }else if (this.variante==2) {
      for (let i = 0; i < this.equiposOdv.length; i++) {
        let equipo_id = this.equiposOdv[i].idEquipo;
        (document.getElementById(equipo_id+"patron0") as HTMLInputElement).setAttribute("value",'60');
        (document.getElementById(equipo_id+"patron1") as HTMLInputElement).setAttribute("value",'150');
        (document.getElementById(equipo_id+"patron2") as HTMLInputElement).setAttribute("value",'300');
        (document.getElementById(equipo_id+"patron3") as HTMLInputElement).setAttribute("value",'450');
        (document.getElementById(equipo_id+"patron4") as HTMLInputElement).setAttribute("value",'600');
        (document.getElementById(equipo_id+"patron5") as HTMLInputElement).setAttribute("value",'600');
        (document.getElementById(equipo_id+"patron6") as HTMLInputElement).setAttribute("value",'450');
        (document.getElementById(equipo_id+"patron7") as HTMLInputElement).setAttribute("value",'300');
        (document.getElementById(equipo_id+"patron8") as HTMLInputElement).setAttribute("value",'150');
        (document.getElementById(equipo_id+"patron9") as HTMLInputElement).setAttribute("value",'60');

        (document.getElementById(equipo_id+"insert0") as HTMLInputElement).setAttribute("value",'2.9');
        (document.getElementById(equipo_id+"insert1") as HTMLInputElement).setAttribute("value",'2.9');
        (document.getElementById(equipo_id+"insert2") as HTMLInputElement).setAttribute("value",'2.9');
        (document.getElementById(equipo_id+"insert3") as HTMLInputElement).setAttribute("value",'3.0');
        (document.getElementById(equipo_id+"insert4") as HTMLInputElement).setAttribute("value",'3.0');

        (document.getElementById(equipo_id+"insert5") as HTMLInputElement).setAttribute("value",'3.0');
        (document.getElementById(equipo_id+"insert6") as HTMLInputElement).setAttribute("value",'3.0');
        (document.getElementById(equipo_id+"insert7") as HTMLInputElement).setAttribute("value",'2.9');
        (document.getElementById(equipo_id+"insert8") as HTMLInputElement).setAttribute("value",'2.9');
        (document.getElementById(equipo_id+"insert9") as HTMLInputElement).setAttribute("value",'2.9');

        (<HTMLInputElement> document.getElementById(equipo_id+"insert0")).disabled = false;
        (<HTMLInputElement> document.getElementById(equipo_id+"insert1")).disabled = false;
        (<HTMLInputElement> document.getElementById(equipo_id+"insert2")).disabled = false;
        (<HTMLInputElement> document.getElementById(equipo_id+"insert3")).disabled = false;
        (<HTMLInputElement> document.getElementById(equipo_id+"insert4")).disabled = false;
        (<HTMLInputElement> document.getElementById(equipo_id+"insert5")).disabled = false;
        (<HTMLInputElement> document.getElementById(equipo_id+"insert6")).disabled = false;
        (<HTMLInputElement> document.getElementById(equipo_id+"insert7")).disabled = false;
        (<HTMLInputElement> document.getElementById(equipo_id+"insert8")).disabled = false;
        (<HTMLInputElement> document.getElementById(equipo_id+"insert9")).disabled = false;

        (document.getElementById(equipo_id+"tole0") as HTMLInputElement).setAttribute("value",'12.0');
        (document.getElementById(equipo_id+"tole1") as HTMLInputElement).setAttribute("value",'12.0');
        (document.getElementById(equipo_id+"tole2") as HTMLInputElement).setAttribute("value",'12.0');
        (document.getElementById(equipo_id+"tole3") as HTMLInputElement).setAttribute("value",'12.0');
        (document.getElementById(equipo_id+"tole4") as HTMLInputElement).setAttribute("value",'12.0');
        (document.getElementById(equipo_id+"tole5") as HTMLInputElement).setAttribute("value",'12.0');
        (document.getElementById(equipo_id+"tole6") as HTMLInputElement).setAttribute("value",'12.0');
        (document.getElementById(equipo_id+"tole7") as HTMLInputElement).setAttribute("value",'12.0');
        (document.getElementById(equipo_id+"tole8") as HTMLInputElement).setAttribute("value",'12.0');
        (document.getElementById(equipo_id+"tole9") as HTMLInputElement).setAttribute("value",'12.0');
      }
    }else if (this.variante==3) {
      
    }{

      for (let i = 0; i < this.equiposOdv.length; i++) {
        let equipo_id = this.equiposOdv[i].idEquipo;
        (document.getElementById(equipo_id+"patron0") as HTMLInputElement).setAttribute("value",'40');
        (document.getElementById(equipo_id+"patron1") as HTMLInputElement).setAttribute("value",'100');
        (document.getElementById(equipo_id+"patron2") as HTMLInputElement).setAttribute("value",'200');
        (document.getElementById(equipo_id+"patron3") as HTMLInputElement).setAttribute("value",'320');
        (document.getElementById(equipo_id+"patron4") as HTMLInputElement).setAttribute("value",'400');
        (document.getElementById(equipo_id+"patron5") as HTMLInputElement).setAttribute("value",'400');
        (document.getElementById(equipo_id+"patron6") as HTMLInputElement).setAttribute("value",'320');
        (document.getElementById(equipo_id+"patron7") as HTMLInputElement).setAttribute("value",'200');
        (document.getElementById(equipo_id+"patron8") as HTMLInputElement).setAttribute("value",'100');
        (document.getElementById(equipo_id+"patron9") as HTMLInputElement).setAttribute("value",'40');

        (document.getElementById(equipo_id+"insert0") as HTMLInputElement).setAttribute("value",'0.17');
        (document.getElementById(equipo_id+"insert1") as HTMLInputElement).setAttribute("value",'0.17');
        (document.getElementById(equipo_id+"insert2") as HTMLInputElement).setAttribute("value",'0.17');
        (document.getElementById(equipo_id+"insert3") as HTMLInputElement).setAttribute("value",'0.17');
        (document.getElementById(equipo_id+"insert4") as HTMLInputElement).setAttribute("value",'0.17');

        (document.getElementById(equipo_id+"insert5") as HTMLInputElement).setAttribute("value",'0.17');
        (document.getElementById(equipo_id+"insert6") as HTMLInputElement).setAttribute("value",'0.17');
        (document.getElementById(equipo_id+"insert7") as HTMLInputElement).setAttribute("value",'0.17');
        (document.getElementById(equipo_id+"insert8") as HTMLInputElement).setAttribute("value",'0.17');
        (document.getElementById(equipo_id+"insert9") as HTMLInputElement).setAttribute("value",'0.17');

        (<HTMLInputElement> document.getElementById(equipo_id+"insert0")).disabled = true;
        (<HTMLInputElement> document.getElementById(equipo_id+"insert1")).disabled = true;
        (<HTMLInputElement> document.getElementById(equipo_id+"insert2")).disabled = true;
        (<HTMLInputElement> document.getElementById(equipo_id+"insert3")).disabled = true;
        (<HTMLInputElement> document.getElementById(equipo_id+"insert4")).disabled = true;
        (<HTMLInputElement> document.getElementById(equipo_id+"insert5")).disabled = true;
        (<HTMLInputElement> document.getElementById(equipo_id+"insert6")).disabled = true;
        (<HTMLInputElement> document.getElementById(equipo_id+"insert7")).disabled = true;
        (<HTMLInputElement> document.getElementById(equipo_id+"insert8")).disabled = true;
        (<HTMLInputElement> document.getElementById(equipo_id+"insert9")).disabled = true;

        (document.getElementById(equipo_id+"tole0") as HTMLInputElement).setAttribute("value",'8.0');
        (document.getElementById(equipo_id+"tole1") as HTMLInputElement).setAttribute("value",'8.0');
        (document.getElementById(equipo_id+"tole2") as HTMLInputElement).setAttribute("value",'8.0');
        (document.getElementById(equipo_id+"tole3") as HTMLInputElement).setAttribute("value",'8.0');
        (document.getElementById(equipo_id+"tole4") as HTMLInputElement).setAttribute("value",'8.0');
        (document.getElementById(equipo_id+"tole5") as HTMLInputElement).setAttribute("value",'8.0');
        (document.getElementById(equipo_id+"tole6") as HTMLInputElement).setAttribute("value",'8.0');
        (document.getElementById(equipo_id+"tole7") as HTMLInputElement).setAttribute("value",'8.0');
        (document.getElementById(equipo_id+"tole8") as HTMLInputElement).setAttribute("value",'8.0');
        (document.getElementById(equipo_id+"tole9") as HTMLInputElement).setAttribute("value",'8.0');
      }

    }
  }

}
