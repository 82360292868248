import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/app/variables/enviroment";
import { CalendarOptions, FullCalendarComponent } from "@fullcalendar/angular";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { WalletService } from "src/app/services/wallet.service";
import { isNgTemplate } from "@angular/compiler";
import { ICobranzaReport } from "src/app/services/models/ICobranzaReport";

@Component({
  selector: "app-update-wallet",
  templateUrl: "./update-wallet.component.html",
  styleUrls: ["./update-wallet.component.scss"],
})
export class UpdateWalletComponent implements OnInit {
  @ViewChild("calendar") calendarComponent: FullCalendarComponent;
  calendarOptions: CalendarOptions = {
    initialView: "dayGridMonth",
    weekends: false,
  };

  response: any = [];
  data: any = [];
  loader: number = 0;

  filtro: string = "todas";

  //Un archivo
  file: any;
  file_name = "";

  //Varios archivos
  file_names = "";
  files: any = [];

  numSelect: number = 0;
  nombresFiles: string = null;

  number_page = 0;
  pages = 0;

  private currentDate = new Date();
  final_fechas = [];

  id_factura = "";
  factura = "";
  comentario = null;
  fecha_cobro: any;
  valueRS: number = 3;
  public noDuplicateFile:any =[];
  tab_acitive = 1;

  facturas_comparadas: any = [];

  nueva_fecha: any = null;
  comentario_agenda: null;

  view = 1;
  fecha: string = "";
  lista_facturas_dia: any = [];
  total_dia_mxn = 0.0;
  total_dia_usd = 0.0;

  report_name = "";
  //REPORTE COBRANZA
  months = [
    'Todos',
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  years = [2022, 2023, 2024];
  r_social = [
    { 
      id: 1,
      value: 'A',
      name: "Todas" 
    },
    { 
      id: 2,
      value: 'T',
      name: "Etalonet" 
    },
    {
      id: 3,
      value: 'E',
      name: "Etalons",
    },
  ];
  date_type = [
    { 
      id: 2,
      value: 'fecha_expedida',
      name: "Expedida" 
    },
    {
      id: 3,
      value: 'fecha_cobro',
      name: "Cobro",
    },
  ];
  date_range = [
    { 
      id: 1,
      name: "Todas" 
    },
    { 
      id: 2, 
      name: "Rango Fechas" 
    },
    {
      id: 3,
      name: "Año/Mes",
    },
  ];
  date_status = [
    { 
      id: 1,
      value: -1,
      name: "Todas" 
    },
    { 
      id: 2,
      value: 3,
      name: "Agendadas" 
    },
    {
      id: 3,
      value: 1,
      name: "Pagadas",
    },
  ];
  public cob_report: ICobranzaReport = {};

  public selectedMonth:number = 0;
  public selectedYear:number;
  //comentarios
  comments: any = [];
  nodata = 0;
  permmisions = JSON.parse(localStorage.getItem("permisos"));

  folios_faltantes: any = [];

  constructor(
    private carteraService: WalletService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.obtenerListaFacturas();
    //this.obtenerComparacionFacturas();
    this.selectedYear = this.currentDate.getFullYear();
  }

  /**
   * This function sets the view to a calendar view and retrieves data for the current month.
   */
  public calendarioView(): void {
    this.view = 2;
    this.getMesData();
  }

  /**
   * The function toggles the display of weekends on a calendar by changing a boolean value.
   */
  public toggleWeekends(): void {
    this.calendarOptions.weekends = !this.calendarOptions.weekends; // toggle the boolean!
  }

  /**
   * The function "cobranzaView" sets the view to 1, clears the data and facturas_comparadas arrays,
   * and calls two functions to obtain a list of invoices and compare them.
   */
  public cobranzaView(): void {
    this.view = 1;
    this.data = [];
    this.facturas_comparadas = [];
    this.obtenerListaFacturas();
    this.obtenerComparacionFacturas();
  }

  /**
   * This function obtains a comparison of invoices and formats the data before assigning it to a
   * variable.
   */
  private obtenerComparacionFacturas(): void {
    this.facturas_comparadas = [];
    let response: any;
    this.carteraService.obtenerComparacionFacturas("01").subscribe(
      (res) => {
        response = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004" || response.code == "0_0007")
          this.lauchModal(response.code, response.message, "");
        else {
          this.facturas_comparadas = response.data;

          this.facturas_comparadas = response.data.map((a) => {
            a.saldo = new Intl.NumberFormat("es-MX", {
              maximumSignificantDigits: 15,
            }).format(a.saldo);
            return a;
          });
        }

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * This function obtains a list of invoices and formats the data before displaying it.
   */
  private obtenerListaFacturas(): void {
    this.loader = 1;
    let response: any;
    this.carteraService.getInviocesUpdate(this.filtro).subscribe(
      (res) => {
        response = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004" || response.code == "0_0007")
          this.lauchModal(response.code, response.message, "");
        else {
          this.data = response.data;

          this.data.forEach((a) => {
            a.forEach((b) => {
              b.saldo = new Intl.NumberFormat("es-MX", {
                maximumSignificantDigits: 15,
              }).format(b.saldo);
            });
          });
          this.pages = this.data.length;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

/**
 * The function `getNotDuplicateFileType` filters out duplicate file types from an array of objects
 * based on a specific condition.
 * @param archivos - The parameter "archivos" is an array of objects. Each object represents a file and
 * has two properties: "tipo_archivo" (file type) and "enviado" (whether the file has been sent or not,
 * represented by 0 or 1).
 * @returns an array of unique objects based on the "tipo_archivo" property.
 */
  public getNotDuplicateFileType(archivos){
    const tiposUnicos: Record<string, any> = {};

    for (const objeto of archivos) {
      const { tipo_archivo, enviado } = objeto;
      if (tiposUnicos[tipo_archivo] || (enviado === 0 && tiposUnicos[tipo_archivo])) {
        continue;
      }
      tiposUnicos[tipo_archivo] = objeto;
    }
    const objetosUnicos = Object.values(tiposUnicos);

    return objetosUnicos;
  }

  /**
   * The function changes the filter based on the value of a variable and then retrieves a list of
   * invoices.
   */
  public changeFilter(): void {
    this.filtro =
      this.valueRS == 1
        ? "todas"
        : this.valueRS == 2
        ? "E"
        : this.valueRS == 3
        ? "T"
        : "U";

    this.obtenerListaFacturas();
  }

  /**
   * This function filters out remaining folios from a given set of data.
   */
  public FilterRemainingFolios(): void {
    this.loader = 1;
    this.folios_faltantes = [];
    let folio_anterior = 0;
    let serie_anterior = "";
    this.data.forEach((arrays) => {
      arrays.forEach((facturas) => {
        let folio_actual = Number(facturas.folio.slice(0, -1));
        let serie_actual = facturas.folio.slice(-1);

        if (serie_actual == serie_anterior) {
          let resta = folio_anterior - folio_actual;
          let folio_faltante = 0;

          if (serie_actual == "C") {
            folio_faltante = Number(facturas.folio.slice(0, -2));
          } else {
            folio_faltante = Number(facturas.folio.slice(0, -1));
          }

          if (resta > 1) {
            for (let i = folio_actual; i < folio_anterior - 1; i++) {
              folio_faltante = folio_faltante + 1;

              this.searchFacturas(folio_faltante + serie_actual);
            }
          }
        }
        folio_anterior = folio_actual;
        serie_anterior = serie_actual;
      });
    });
    this.loader = 2;
  }

  /**
   * The function searches for invoices and handles the response accordingly.
   * @param factura - The parameter "factura" is a variable that represents the invoice number being
   * searched for in the "searchFacturas" function.
   */
  private searchFacturas(factura): void {
    this.carteraService.searchInvoice(factura).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_0007"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          let estatus = this.response.data;
          this.folios_faltantes.push({ folio: factura, estatus: estatus });
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
      }
    );
  }

  /**
   * This function sets the ID and folio of a factura (invoice).
   * @param id - The ID of the factura (invoice).
   * @param folio - Folio is a variable that represents the unique identifier or number assigned to a
   * specific invoice or bill. It is usually used to track and manage financial transactions in a
   * business or organization.
   */
  public setFactura(id, folio): void {
    this.factura = folio;
    this.id_factura = id;
  }

  /**
   * This function changes the status of an invoice and launches a modal with a message depending on
   * the response.
   * @param estatus - The parameter "estatus" is a variable that represents the status of an invoice.
   * It is used to determine whether the invoice is being marked as paid (estatus = 1) or unpaid
   * (estatus = 0).
   */
  public changeStatus(estatus): void {
    this.loader = 1;
    let module = null;

    let form = new FormData();
    form.append("id_factura", this.id_factura);
    form.append("estatus", estatus);
    form.append("comentario", this.comentario);

    if (estatus == 1) {
      form.append("fecha", this.fecha_cobro);
      module = 169;
    } else module = 170;

    let response: any;
    this.carteraService.changeStatusInvoice(form, module).subscribe(
      (res) => {
        response = res;
        this.loader = 0;
        if (response.code != "0_005")
          this.lauchModal(response.code, response.message, "");
        else if (response.data != 1)
          this.lauchModal(response.code, "Error de consulta", response.message);
        else this.obtenerListaFacturas();
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * This function loads files selected by the user and updates the list of file names and the number
   * of selected files.
   * @param value - The parameter "value" is an object that represents the event that triggered the
   * function. It contains information about the files that were selected by the user in an input file
   * element.
   */
  public loadFiles(value): void {
    for (var i = 0; i < value.target.files.length; i++) {
      this.files.push(value.target.files[i]);
      this.nombresFiles == null
        ? (this.nombresFiles = value.target.files[i].name)
        : (this.nombresFiles += ", " + value.target.files[i].name);
    }
    this.numSelect = this.files.length;
  }

  /**
   * This function uploads multiple files to a server and displays a modal with the result.
   */
  public subirAchivos(): void {
    let result: any = [];
    this.loader = 1;

    if (this.files.length > 0) {
      let form = new FormData();
      var i = 1;
      this.files.forEach((element) => {
        form.append("file_" + i, element);
        i++;
      });
      this.carteraService.subirMultiplesArchivos(form).subscribe(
        (res) => {
          result = res;
          if (
            result.code == "1_0001" ||
            result.code == "1_0002" ||
            result.code == "1_0003"
          )
            this.lauchModal(result.code, result.message, "");
          else if (result.code == "1_0004" || result.code == "0_007")
            this.lauchModal(result.code, result.message, "");
          else {
            if (result.data != "1" || result.data != 1)
              this.lauchModal(
                result.code,
                result.message,
                "Ocurrió un error al procesar la solicitud"
              );
            else {
              this.obtenerListaFacturas();
              this.lauchModal(
                result.code,
                "Documento cargado correctamente",
                "La operación se ejecuta con exito"
              );
            }
          }

          this.loader = 2;
        },
        (err) => {
          this.lauchModal("0000x00", "Error de consulta", "");
          this.loader = 2;
        }
      );
    }
  }

  /* The above code defines a private function called `restaFechas` that takes two date strings as
  input (`f1` and `f2`) and returns the difference between them in days. The function first splits
  the input date strings into year, month, and day components and converts them to UTC dates using
  the `Date.UTC()` method. It then calculates the difference between the two dates in milliseconds
  and converts it to days using the formula `dif / (1000 * 60 * 60 * 24)`. The function returns the
  calculated number of days. */
  public restaFechas = function (f1, f2): number {
    var aFecha1 = f1.split("-");
    var aFecha2 = f2.split("-");

    var fFecha1 = Date.UTC(aFecha1[0], aFecha1[1] - 1, aFecha1[2]);
    var fFecha2 = Date.UTC(aFecha2[0], aFecha2[1] - 1, aFecha2[2]);

    var dif = fFecha1 - fFecha2;
    var dias = Math.floor(dif / (1000 * 60 * 60 * 24));
    return dias;
  };

  /**
   * This function returns the current date in a specific API format.
   * @returns a string representing the current date in the format "YYYY-MM-DD".
   */
  public getCurrentDateDay(): string {
    let day: any = this.currentDate.getDate();
    let month: any = this.currentDate.getMonth() + 1;
    let year: any = this.currentDate.getFullYear();
    let dateInApiFormat: string;

    if (day < 10) day = "0" + day.toString();

    if (month < 10) month = "0" + month.toString();

    dateInApiFormat = year.toString() + "-" + month + "-" + day;
    return dateInApiFormat;
  }

  /**
   * The function changes the current page number to the specified page.
   * @param page - The "page" parameter is a variable that represents the page number that the user
   * wants to navigate to. The "changePageResults" function takes this parameter and updates the
   * "number_page" variable with the new page number.
   */
  public changePageResults(page): void {
    this.number_page = page;
  }

  /**
   * This function sorts an array of objects based on the "id" property in ascending order.
   * @returns The function is not returning anything, it is just sorting the "data" array in place.
   */
  public ordenarArryColumns(): void {
    this.data = this.data.sort(function (a, b) {
      if (a.id < b.id) {
        return -1;
      } else {
        return 1;
      }
    });
  }

  /**
   * This is a private function in TypeScript that reprograms a cobro (payment) for a factura (invoice)
   * using a form data object and a service call.
   */
  public reprogramarCobro(): void {
    this.loader = 1;
    let form = new FormData();
    form.append("id_factura", this.id_factura);
    form.append("fecha", this.nueva_fecha);
    form.append("coment", this.comentario_agenda);

    this.carteraService.reprogramarFacturaCobranza(form).subscribe(
      (res) => {
        let resp: any = res;
        if (resp.code != "0_005") this.lauchModal(resp.code, resp.message, "");
        else {
          if (resp.data == 1) this.obtenerListaFacturas();
          else this.lauchModal(resp.code, "Ocurrió un error", "");
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * This function retrieves comments for a specific invoice ID and handles different response codes
   * accordingly.
   * @param id - The parameter "id" is the identifier of the invoice for which the comments are being
   * obtained.
   */
  public obtenerComentariosFactura(id): void {
    this.loader = 1;
    let response: any;
    this.carteraService.getInvoiceComments(id).subscribe(
      (res) => {
        response = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004" || response.code == "0_0007")
          this.lauchModal(response.code, response.message, "");
        else {
          this.comments = response.data;
          if (this.comments.length <= 0) {
            this.nodata = 1;
          } else {
            this.nodata = 0;
          }
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /* CALENDARIO  */

  /**
   * This function sets up the options for a calendar view and binds a date click event to a handler
   * function.
   */
  private mostrareventosCalendar(): void {
    this.calendarOptions = {
      initialView: "dayGridMonth",
      dateClick: this.handleDateClick.bind(this), // bind is important!
      navLinks: false,
      editable: true,
      events: this.final_fechas,
    };
    this.loader = 2;
  }

  /**
   * The function handles a date click event and retrieves invoice data for the selected month.
   * @param arg - arg is an object that contains the date string of the clicked date. It is used to
   * retrieve invoices for the selected month.
   */
  private handleDateClick(arg): void {
    this.loader = 1;
    this.total_dia_mxn = 0.0;
    this.total_dia_usd = 0.0;
    this.carteraService.getInvoicesMonth(arg.dateStr).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_0007"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else this.data = this.response.data;
        this.lista_facturas_dia = this.data;

        this.lista_facturas_dia.forEach((list) => {
          list.saldo = parseFloat(list.saldo);

          if (list.divisa != "Pesos") {
            this.total_dia_usd = this.total_dia_usd + list.saldo;
          } else {
            this.total_dia_mxn = this.total_dia_mxn + list.saldo;
          }
        });

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * The function retrieves data from a service and constructs a calendar structure based on the data.
   */
  private getMesData(): void {
    this.fecha = this.getCurrentDateMonth();
    let agendados = [],
      pagados = [];

    this.carteraService.getInvoicesMonth(this.fecha).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_0007"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else this.data = this.response.data;

        this.final_fechas = [];

        this.data.map(function (x) {
          let aux = [];
          aux[0] = x.folio;
          aux[1] = x.saldo;
          aux[2] = x.fecha_cobro;

          if (x.estatus == 3) agendados.push(aux);
          if (x.estatus == 1) pagados.push(aux);
        });

        ///---------- pagado

        var fechas_aux_count3 = [],
          arr_aux_pag = [];
        for (var a = 0; a < pagados.length; a++) {
          if (!arr_aux_pag.includes(pagados[a])) arr_aux_pag.push(pagados[a]);
        }

        for (let i = 0; i < arr_aux_pag.length; i++) {
          let cont = 0;
          let fecha_grupo = "";
          for (let f = 0; f < pagados.length; f++) {
            if (arr_aux_pag[i][2] == pagados[f][2]) {
              cont++;
              fecha_grupo = arr_aux_pag[i][2];
              if (!fechas_aux_count3.includes(fecha_grupo))
                fechas_aux_count3.push(fecha_grupo);
            }
          }
        }
        this.construirEstructuraCalendario(
          fechas_aux_count3,
          "#4caf50",
          "Pagado"
        );

        ///--------- agendados
        var arr_aux_agn = [];
        let fechas_aux_count = [];
        for (var d = 0; d < agendados.length; d++) {
          if (!arr_aux_agn.includes(agendados[d]))
            arr_aux_agn.push(agendados[d]);
        }

        for (var e = 0; e < arr_aux_agn.length; e++) {
          let fecha_grupo = "";
          for (var f = 0; f < agendados.length; f++) {
            if (arr_aux_agn[e][2] == agendados[f][2]) {
              fecha_grupo = arr_aux_agn[e][2];
              if (!fechas_aux_count.includes(fecha_grupo))
                fechas_aux_count.push(fecha_grupo);
            }
          }
        }
        this.construirEstructuraCalendario(
          fechas_aux_count,
          "#3f51b5",
          "Agendado"
        );

        this.mostrareventosCalendar();

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * This function builds a calendar structure by iterating through an array of dates and adding them
   * to a final array with a specified color and title.
   * @param array_parts - An array of date/time values representing the parts of a calendar event (e.g.
   * start date/time, end date/time, recurrence dates/times).
   * @param color - The color parameter is a string that represents the color of the event in the
   * calendar. It can be any valid CSS color value, such as "red", "#FF0000", or "rgb(255, 0, 0)".
   * @param title - A string representing the title of the calendar event.
   */
  private construirEstructuraCalendario(array_parts, color, title): void {
    array_parts.forEach((elements) => {
      let arrayFechas = [];
      arrayFechas["title"] = title;
      arrayFechas["start"] = elements;
      arrayFechas["color"] = color;
      this.final_fechas.push(arrayFechas);
    });
  }

  /**
   * The function retrieves data from an API and constructs a calendar with events based on the data.
   */
  public someMethod(): void {
    let calendarApi = this.calendarComponent.getApi();

    let mesCalendar;

    let mesNext = calendarApi.getDate().getMonth() + 1;

    let anioCalendar = calendarApi.getDate().getFullYear();

    if (calendarApi.getDate().getMonth() + 1 < 10) {
      mesCalendar = "0" + mesNext.toString();
    } else mesCalendar = mesNext.toString();

    let anio_mes = anioCalendar + "-" + mesCalendar;

    let agendados = [],
      pagados = [];

    this.carteraService.getInvoicesMonth(anio_mes).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_0007"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else this.data = this.response.data;

        this.final_fechas = [];

        this.data.map(function (x) {
          let aux = [];
          aux[0] = x.folio;
          aux[1] = x.saldo;
          aux[2] = x.fecha_cobro;

          if (x.estatus == 3) agendados.push(aux);
          if (x.estatus == 1) pagados.push(aux);
        });
        ///---------- pagado

        var fechas_aux_count3 = [],
          arr_aux_pag = [];
        for (var a = 0; a < pagados.length; a++) {
          if (!arr_aux_pag.includes(pagados[a])) arr_aux_pag.push(pagados[a]);
        }

        for (let i = 0; i < arr_aux_pag.length; i++) {
          let cont = 0;
          let fecha_grupo = "";
          for (let f = 0; f < pagados.length; f++) {
            if (arr_aux_pag[i][2] == pagados[f][2]) {
              cont++;
              fecha_grupo = arr_aux_pag[i][2];
              if (!fechas_aux_count3.includes(fecha_grupo))
                fechas_aux_count3.push(fecha_grupo);
            }
          }
        }
        this.construirEstructuraCalendario(
          fechas_aux_count3,
          "#4caf50",
          "Pagado"
        );

        ///--------- agendados
        var arr_aux_agn = [];
        let fechas_aux_count = [];
        for (var d = 0; d < agendados.length; d++) {
          if (!arr_aux_agn.includes(agendados[d]))
            arr_aux_agn.push(agendados[d]);
        }

        for (var e = 0; e < arr_aux_agn.length; e++) {
          let fecha_grupo = "";
          for (var f = 0; f < agendados.length; f++) {
            if (arr_aux_agn[e][2] == agendados[f][2]) {
              fecha_grupo = arr_aux_agn[e][2];
              if (!fechas_aux_count.includes(fecha_grupo))
                fechas_aux_count.push(fecha_grupo);
            }
          }
        }
        this.construirEstructuraCalendario(
          fechas_aux_count,
          "#3f51b5",
          "Agendado"
        );

        this.mostrareventosCalendar();

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * This function returns the current month in a specific format for an API.
   * @returns a string representing the current month in API format.
   */
  public getMonthActual(): string {
    let month: any = this.currentDate.getMonth() + 1;
    let dateInApiFormat: string;

    if (month < 10) month = "0" + month.toString();

    dateInApiFormat = month;
    return dateInApiFormat;
  }

  /**
   * This function returns the current date and month in a specific API format.
   * @returns a string representing the current year and month in the format "YYYY-MM".
   */
  private getCurrentDateMonth(): string {
    let day: any = this.currentDate.getDate();
    let month: any = this.currentDate.getMonth() + 1;
    let year: any = this.currentDate.getFullYear();
    let dateInApiFormat: string;

    if (day < 10) day = "0" + day.toString();

    if (month < 10) month = "0" + month.toString();

    dateInApiFormat = year.toString() + "-" + month;
    return dateInApiFormat;
  }

  /* REPORTE FACTURAS */

  /**
   * This function generates a report by calling an API and downloading the resulting file.
   */
  public generateReport() {
    this.loader = 1;
    this.carteraService.getInvoicesReport(this.cob_report).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_0007"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else this.report_name = this.response.data;
          
        this.downloadFile();

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  public addYear(id):void {
    if(id == 3) {
      this.cob_report.year_month = `${this.selectedYear}`;
      this.selectedMonth = 0;
    } else {
      this.cob_report.year_month = null;
    }
    this.cob_report.end_date = null;
    this.cob_report.start_date = null;
  }

  /**
   * The function removes the end date if the start date is empty.
   */
  public removeEndDate():void {
    if(this.cob_report.start_date == '') {
      this.cob_report.end_date = null;
      this.cob_report.start_date = null;
    }
  }

  /**
   * This function downloads a file from a specific URL and opens it in a new window.
   */
  private downloadFile(): void {
    var url =
      environment.uri_server +
      "storage/public/plantillas_reportes_xslx/generados/" +
      this.report_name;
    window.open(url, "_blank");
  }

  /**
   * This function returns a string representing the selected month and year in a specific format for
   * use in an API call.
   * @returns a string in the format "YYYY-MM" representing the selected month and year.
   */
  public getReportDate(): string {
    
    let month: any = Number(this.selectedMonth);
    let year: any = this.selectedYear;
    let dateInApiFormat: string;

    if (month < 10) month = "0" + month.toString();
    if(this.selectedMonth == 0) {
      return year.toString();
    }

    dateInApiFormat = year.toString() + "-" + month;
    return dateInApiFormat;
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code, title, and
   * message using a modal service.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - The title of the modal window that will be displayed.
   * @param {string} message - The message parameter is a string that represents the message to be
   * displayed in the modal dialog box.
   */
  private async lauchModal(
    code: string,
    title: string,
    message: string
  ): Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
}
