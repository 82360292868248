import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../variables/enviroment';

@Injectable({
  providedIn: 'root'
})
export class RequisitionsService {
  uri:string = environment.uri;
  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');

  constructor(private httpClient:HttpClient) { }

  getList(){
    return this.httpClient.get(this.uri+"get_list_requisitions?api_key="+this.api_key+"&token="+this.token+"&module=158");
  }

  getDetailRequisition(id_requisicion){
    return this.httpClient.get(this.uri+"get_requisition_datail?api_key="+this.api_key+"&token="+this.token+"&module=161&id_requisicion="+id_requisicion);
  }

  saveRequisition(data){
    return this.httpClient.post(this.uri+"save_new_requisition?api_key="+this.api_key+"&token="+this.token+"&module=159",data);
  }

  getDataToEdit(id_requisicion){
    return this.httpClient.get(this.uri+"get_edit_requisition?api_key="+this.api_key+"&token="+this.token+"&module=160&id_requisicion="+id_requisicion);
  }

  saveSupplier(form){
    return this.httpClient.post(this.uri+"save_supplier_requisition?api_key="+this.api_key+"&token="+this.token+"&module=160",form);
  }

  saveEditReq(form){
    return this.httpClient.post(this.uri+"save_edit_requisition?api_key="+this.api_key+"&token="+this.token+"&module=160",form);
  }

  addPartRequisicion(form){
    return this.httpClient.post(this.uri+"add_part_requisition?api_key="+this.api_key+"&token="+this.token+"&module=160",form);
  }

  setStatusRequisition(estatus,permiso,id){
    return this.httpClient.get(this.uri+"set_status_requisition?api_key="+this.api_key+"&token="+this.token+"&module="+permiso+"&estatus="+estatus+"&id_requisicion="+id);
  }

  addOdcRequisition(form){
    return this.httpClient.post(this.uri+"generate_odc_by_requisition?api_key="+this.api_key+"&token="+this.token+"&module=163",form);
  }

  editPartReq(form){
    return this.httpClient.post(this.uri+"edit_part_requisition?api_key="+this.api_key+"&token="+this.token+"&module=165",form);
  }

  deletePartReq(id_part){
    return this.httpClient.get(this.uri+"delete_part_requisition?api_key="+this.api_key+"&token="+this.token+"&module=166&id_part="+id_part);
  }

  addDocumentoToReq(form){
    return this.httpClient.post(this.uri+"add_requisition_file?api_key="+this.api_key+"&token="+this.token+"&module=174",form);
  }

  getSupplier(value_to_search){
    return this.httpClient.get(this.uri+"search_supplier?api_key="+this.api_key+"&token="+this.token+"&module=159&value="+value_to_search);
  }

  disavowRequisition(form){
    return this.httpClient.post(this.uri+"disavow_requisicion?api_key="+this.api_key+"&token="+this.token+"&module=173",form);
  }

}
