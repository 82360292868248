import { ResourceLoader } from "@angular/compiler";
import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { NewsService } from "src/app/services/news.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-emails",
  templateUrl: "./emails.component.html",
  styleUrls: ["./emails.component.css"],
})
export class EmailsComponent implements OnInit {
  result: any = [];
  loader = 0;
  showingEmailsList = false;
  permmisions = JSON.parse(localStorage.getItem("permisos"));

  tipo = null;
  asunto = "";
  mensaje = "";
  notas = "";
  para = "";
  remitente = "";

  tarjetas = [
    [
      "Informativo",
      "Enviar un correo sin archivo adjunto",
      "/commercial/email/det",
      "fa-envelope",
      "orangered",
      "bg-orange",
      "bg-warning",
      1,
    ],
    [
      "Orden de compra",
      "Enviar una orden de compra por correo, con archivo adjunto",
      "/commercial/email/det",
      "fa-shopping-cart",
      "blue",
      "bg-info",
      "bg-info",
      2,
    ],
    [
      "Envío de informes",
      "Enviar un correo con todos los informes de una orden de venta en formato ZIP",
      "/commercial/email/det",
      "fa-file-archive",
      "green",
      "bg-success",
      "bg-success",
      3,
    ],
  ];

  constructor(
    private userService: UserService,
    private modalService: NgbModal,
    private avisoService: NewsService
  ) {}

  ngOnInit(): void {}

  /**
   * This function retrieves a list of emails from a service and displays them if successful, otherwise
   * it displays an error message.
   */
  private getEmailList() :void {
    this.loader = 1;
    this.avisoService.getListEmail().subscribe(
      (res) => {
        this.result = res;

        if (this.result.code == "0_005" && this.result.data != "0") {
          console.log(this.result);
        } else this.lauchModal(this.result.code, this.result.message);
        this.loader = 0;
        this.showingEmailsList = true;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * The function "enviarCorreo" sets the "showingEmailsList" variable to false.
   */
  private enviarCorreo() :void {
    this.showingEmailsList = false;
  }

  /**
   * This is a private function in TypeScript that sets the details of an email, including the subject,
   * message, sender, recipients, notes, and type.
   * @param asunto - The subject of the email.
   * @param mensaje - The "mensaje" parameter is a string that represents the body or content of the
   * email message. It contains the actual text that the sender wants to communicate to the recipients.
   * @param remitente - The email address of the sender of the email.
   * @param destinatarios - "destinatarios" refers to the recipients of the email, i.e. the people who
   * will receive the email. It is likely a list or array of email addresses.
   * @param notas - The "notas" parameter is a string that can be used to add any additional notes or
   * comments related to the email.
   * @param tipo - The "tipo" parameter is a number that represents the type of email being set. In
   * this code, it is being subtracted by 1 before being assigned to the "tipo" property. The specific
   * meaning of each type is not clear from this code snippet alone.
   */
  private setDetailEmail(asunto, mensaje, remitente, destinatarios, notas, tipo) :void {
    this.remitente = remitente;
    this.asunto = asunto;
    this.mensaje = mensaje;
    this.para = destinatarios;
    this.notas = notas;
    this.tipo = tipo - 1;
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code and title using
   * the ModalComponent.
   * @param {string} code - A string representing the code that will be displayed in the modal window.
   * @param {string} title - The title parameter is a string that represents the title of the modal
   * that will be launched.
   */
  private async lauchModal(code: string, title: string) :Promise<void>{
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }
}
