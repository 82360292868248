import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';  
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { ModulesModule } from './pages/modules/modules.module';
import { AccessModule } from './pages/access/access.module';
import { RestorepasswordComponent } from './pages/user/restorepassword/restorepassword.component';
import { SettingsComponent } from './pages/user/settings/settings.component';
import { IndicatorsComponent } from './pages/user/indicators/indicators.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AuthenticationGuard } from './authentication.guard';


@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    ModulesModule,
    FormsModule,
    AccessModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    RestorepasswordComponent,
    SettingsComponent,
    IndicatorsComponent,
  ],
  providers: [
    AuthenticationGuard

  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
