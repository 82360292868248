import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-modules-permissions",
  templateUrl: "./modules-permissions.component.html",
  styleUrls: ["./modules-permissions.component.css"],
})
export class ModulesPermissionsComponent implements OnInit {
  id_user = "0";
  response: any = [];
  results: any = [];
  permissions: any = [];
  user_data: any = [];
  loader: number = 1;
  level = "";
  array_user_level = [
    { id: "1", det: "Int. Metrólogo" },
    { id: "2", det: "Int. Admin" },
    { id: "3", det: "Int. Program." },
    { id: "4", det: "Ext. Final" },
  ];

  constructor(
    private activedRoute: ActivatedRoute,
    private userService: UserService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.id_user = this.activedRoute.snapshot.paramMap.get("id_user");
    this.loadData();
  }

  /**
   * The function loads data from a user service and handles the response accordingly.
   */
  private loadData() :void {
    this.userService.getModulesList(this.id_user).subscribe(
      (res) => {
        this.response = res;

        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003" ||
          this.response.code == "1_0004" ||
          this.response.code == "0_007"
        )
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.results = this.response.data;
          this.permissions = this.results.permissions;
          this.user_data = this.results.user_data;

          if (this.user_data.level == 1) this.level = "Int. Metrólogo";
          else if (this.user_data.level == 2) this.level = "Int. Admin";
          else if (this.user_data.level == 3) this.level = "Int. Program.";
          else if (this.user_data.level == 4) this.level = "Ext. Final";
        }

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * The function sets the user level and handles the response from the server.
   * @param id - The parameter "id" is a variable that represents the user ID for which the level is
   * being set.
   */
  private setUserLevel(id) :void {
    this.loader = 1;
    this.userService.setLevelUser(id, this.id_user).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003" ||
          this.response.code == "1_0004" ||
          this.response.code == "0_007"
        )
          this.lauchModal(this.response.code, this.response.message);
        else this.loadData();
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This function changes user permissions based on the provided action, permission ID, and user ID.
   * @param accion - This parameter is a number (0 or 1) that determines whether to add or remove a
   * permission for a user.
   * @param permission_id - The ID of the permission that is being changed for the user.
   * @param user_id - The ID of the user whose permission is being changed.
   */
  private changePermission(accion, permission_id, user_id) :void {
    this.loader = 1;
    if (accion == 1) {
      this.userService.deleteUserPermission(user_id, permission_id).subscribe(
        (res) => {
          this.response = res;

          if (
            this.response.code == "1_0001" ||
            this.response.code == "1_0002" ||
            this.response.code == "1_0003" ||
            this.response.code == "1_0004" ||
            this.response.code == "0_007"
          )
            this.lauchModal(this.response.code, this.response.message);
          else {
            if (this.response.data == 1) this.loadData();
          }

          this.loader = 2;
        },
        (err) => {
          this.lauchModal("0000x00", "Error de consulta");
          console.log(err);
          this.loader = 2;
        }
      );
    } else if (accion == 0) {
      this.userService.setUserPermission(user_id, permission_id).subscribe(
        (res) => {
          this.response = res;

          if (
            this.response.code == "1_0001" ||
            this.response.code == "1_0002" ||
            this.response.code == "1_0003" ||
            this.response.code == "1_0004" ||
            this.response.code == "0_007"
          )
            this.lauchModal(this.response.code, this.response.message);
          else {
            if (this.response.data == 1) this.loadData();
          }

          this.loader = 2;
        },
        (err) => {
          this.lauchModal("0000x00", "Error de consulta");
          console.log(err);
          this.loader = 2;
        }
      );
    }
  }

 /**
  * This function launches a modal with a given code and title using the Angular Material modal
  * service.
  * @param {string} code - A string parameter that represents the code to be displayed in the modal
  * window.
  * @param {string} title - The title parameter is a string that represents the title of the modal that
  * will be launched. It will be displayed at the top of the modal to provide context to the user.
  */
  private async lauchModal(code: string, title: string) :Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }
}
