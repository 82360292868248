import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { VehicleService } from "src/app/services/vehicle.service";

@Component({
  selector: "app-edit-vehicle",
  templateUrl: "./edit-vehicle.component.html",
  styleUrls: ["./edit-vehicle.component.css"],
})
export class EditVehicleComponent implements OnInit {
  loader = false;
  response: any = [];
  data: any = [];
  id = "";

  km = " ";
  performance = "";
  user = "";
  prox_maintenance = "";
  notes = "";
  ubication = "";

  locations = ["Monterrey, NL", "San Luis, SLP", "Ramos Arizpe, Coah"];

  users_list: any = [];
  prox_man = "";

  constructor(
    private vehicleService: VehicleService,
    private modalService: NgbModal,
    private activedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.id = this.activedRoute.snapshot.paramMap.get("id");
    this.getData();
  }

  /**
   * This function retrieves data from a vehicle service and assigns it to various variables, with
   * error handling and modals.
   */
  private getData() :void {
    this.loader = true;

    this.vehicleService.getVehicleForEdit(this.id).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.data = this.response.data;
          this.km = this.data.km;
          this.performance = this.data.rendimiento;
          this.user =
            this.data.usuario_asignado == "N/A"
              ? 0
              : this.data.usuario_asignado;
          this.prox_maintenance = this.data.prox_mantenimiento;
          this.notes = this.data.notes;
          this.ubication = this.data.ubicacion;

          this.users_list = this.data.usuarios;

          if (this.data.servicios.length > 0)
            this.prox_man =
              this.data.servicios[0].prox_man_km +
              " KM / " +
              this.data.servicios[0].prox_man_fecha;
          else this.prox_man = "N/A";
        }

        this.loader = false;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = false;
      }
    );
  }

  /**
   * This function saves vehicle data and launches a modal with a response message.
   * @param return_ - A boolean value indicating whether to return to the previous page after saving
   * the data or not.
   */
  public saveData(return_) :void {
    this.loader = true;

    let data = new FormData();
    data.append("vehicle", this.id);
    data.append("performance", this.performance);
    data.append("user", this.user);
    data.append("ubication", this.ubication);
    data.append("notes", this.notes);

    this.vehicleService.saveVehicleEdit(data).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message);
        else {
          if (return_)
            this.router.navigateByUrl("/operations/vehicle/detail/" + this.id);
          else this.getData();
        }

        this.loader = false;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = false;
      }
    );
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code and title using
   * the ModalComponent.
   * @param {string} code - A string representing the code that will be displayed in the modal window.
   * @param {string} title - The title parameter is a string that represents the title of the modal
   * that will be launched.
   */
  private async lauchModal(code: string, title: string) :Promise<void>{
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

}
