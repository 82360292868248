import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { XslxGeneratorService } from "src/app/services/xslx-generator.service";
import { environment } from "src/app/variables/enviroment";

@Component({
  selector: "app-generator-xlsx",
  templateUrl: "./generator-xlsx.component.html",
  styleUrls: ["./generator-xlsx.component.css"],
})
export class GeneratorXlsxComponent implements OnInit {

  reports: any = [];

  zone: any = [
    { id: 1, zone: "Todas" },
    { id: 2, zone: "Monterrey" },
    { id: 3, zone: "Ramos Arizpe" },
    { id: 4, zone: "San Luis" },
  ];

  months: any = [
    { id: "0", month: "Seleccionar" },
    { id: "01", month: "Enero" },
    { id: "02", month: "Febrero" },
    { id: "03", month: "Marzo" },
    { id: "04", month: "Abril" },
    { id: "05", month: "Mayo" },
    { id: "06", month: "Junio" },
    { id: "07", month: "Julio" },
    { id: "08", month: "Agosto" },
    { id: "09", month: "Septiembre" },
    { id: "10", month: "Octubre" },
    { id: "11", month: "Noviembre" },
    { id: "12", month: "Diciembre" },
  ];

  client: any = [];

  loader: number = 1;
  response: any = [];

  report_selected = 0;
  month_selected = 0;
  client_selected = -1;
  zone_selected = 0;
  date_init = "";
  date_fin = "";

  file_name = "";
  r_type = "0";

  download_available: boolean = false;

  permmisions = JSON.parse(localStorage.getItem("permisos"));
  
  constructor(
    private service: XslxGeneratorService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.getClientsList();
  }

  /**
   * This function retrieves a list of clients from a service and handles the response accordingly.
   */
  private getClientsList(): void {
    this.service.getClients().subscribe(
      (res) => {
        this.response = res;
        if (this.response.code != "0_005")
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.client = this.response.data.lista_clientes;
          this.reports = this.response.data.reportes;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        this.loader = 2;
      }
    );
  }

  /**
   * This function sets variables based on input type and id, and determines if a download is available
   * based on the selected report and its corresponding variables.
   * @param type - an integer value representing the type of selection being made (0-5 for different
   * reports, 6-8 for other options)
   * @param id - id is a parameter that represents the selected option or value for a particular type
   * of report or filter. It is used in the function to determine which report or filter has been
   * selected and to update the corresponding variables and properties accordingly.
   */
  public setType(type, id): void {
    if (type == 0) {
      this.report_selected = id;
      if (id != 1 && id != 5) this.month_selected = 1;
    } else if (type == 1) this.client_selected = id;
    else if (type == 2) this.month_selected = id;
    else if (type == 3) this.zone_selected = id;
    else if (type == 4) this.date_init = id;
    else if (type == 5) this.date_fin = id;

    if (this.report_selected == 0) this.download_available = false;
    else if (this.report_selected == 1) {
      if (this.month_selected == 0) this.download_available = false;
      else this.download_available = true;
    } else if (this.report_selected == 2 || this.report_selected == 4) {
      if (this.zone_selected == 0) this.download_available = false;
      else this.download_available = true;
    } else if (this.report_selected == 3) {
      if (this.client_selected == -1) this.download_available = false;
      else this.download_available = true;
    } else if (this.report_selected == 5) {
      if (this.date_init == "" || this.date_fin == "")
        this.download_available = false;
      else this.download_available = true;
    } else if (this.report_selected == 6) {
      this.r_type = "0";
      this.download_available = true;
    } else if (this.report_selected == 7) {
      this.r_type = "1";
      this.download_available = true;
    } else if (this.report_selected == 8) {
      if (this.client_selected == -1) this.download_available = false;
      else this.download_available = true;
    }
  }

  /**
   * This function generates a report by calling a service and downloading a file based on selected
   * parameters, with error handling and a loading indicator.
   */
  public generateReport(): void {
    this.loader = 1;

    this.service
      .getReport(
        this.month_selected,
        this.client_selected,
        this.report_selected,
        this.zone_selected,
        this.date_init,
        this.date_fin,
        this.r_type
      )
      .subscribe(
        (res) => {
          this.response = res;
          if (this.response.code != "0_005" || this.response.data == "0")
            this.lauchModal(this.response.code, this.response.message);
          else {
            this.file_name = this.response.data;
            this.downloadFile();
          }
          this.loader = 2;
        },
        (err) => {
          this.lauchModal("0000x00", "Error de consulta");
          this.loader = 2;
        }
      );
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code and title using
   * the Angular modal service.
   * @param {string} code - A string representing the code that will be displayed in the modal window.
   * @param {string} title - The title parameter is a string that represents the title of the modal
   * that will be launched.
   */
  private async lauchModal(code: string, title: string): Promise<void> {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

  /**
   * This function downloads a file from a specified URL and opens it in a new window.
   */
  private downloadFile(): void {
    let url =
      environment.uri_server +
      "storage/public/plantillas_reportes_xslx/generados/" +
      this.file_name;
    window.open(url, "_blank");
  }
}
