import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { GeneratorReportsService } from 'src/app/services/generator-reports.service';
import { environment } from 'src/app/variables/enviroment';

@Component({
  selector: 'app-volumen-heineken01',
  templateUrl: './volumen-heineken01.component.html',
  styleUrls: ['./volumen-heineken01.component.css']
})
export class VolumenHeineken01Component implements OnInit {

  uri:string = environment.uri;
  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');
  url_to_load = '';

  columnas:any=[];
  finalValores:any=[];
  idsEquipos:any=[];
  idShet:string='';
  idpatron:any=[];

  equiposOdv:any=[]

  loader:number = 1;
  response:any = [];
  data:any=[];
  pdfInformes:any=[];
  clic:string;

  lugar:any = "sitio";
  temp:any;
  unidad:any;
  format:any = "AAA-MM-DD";
  fecha:any;

  asignado:number=2;

  numMedicion1:number;
  numMedicion2:number;
  numMedicion3:number;
  numMedicion4:number;
  numMedicion5:number;
  numMedicion6:number;
  numMedicion7:number;
  numMedicion8:number;
  numMedicion9:number;
  numMedicion10:number;

  volumenMax1:number;
  volumenMax2:number;
  volumenMax3:number;
  volumenMax4:number;
  volumenMax5:number;
  volumenMax6:number;
  volumenMax7:number;
  volumenMax8:number;
  volumenMax9:number;
  volumenMax10:number;
  
  volumen2_1:number;
  volumen2_2:number;
  volumen2_3:number;
  volumen2_4:number;
  volumen2_5:number;
  volumen2_6:number;
  volumen2_7:number;
  volumen2_8:number;
  volumen2_9:number;
  volumen2_10:number;

  VolumenAjustado:number;
  temAgua:number;

  valueSearchCliente: string="";
  listclientes:any = [];

  valueSearchPlanta: string="";
  listPlantas:any = [];

  arrayActiveVol:any = [];


  constructor(
    private datos: ActivatedRoute,
    public conn: HttpClient,
    private generadorReports:GeneratorReportsService, 
    private modalService:NgbModal
  ) { }

  ngOnInit(): void {
    let datos=this.datos.snapshot.paramMap.get('id_equipos')
    this.idsEquipos.push(datos.split(','));
    this.idShet = this.datos.snapshot.paramMap.get('id_hoja');
    this.idpatron = this.datos.snapshot.paramMap.get('id_patron');

    this.getData();
  }

  getData(){
    this.generadorReports.verPlantilla(this.idShet,this.idsEquipos).subscribe(
      res=>{
        this.response = res;
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
        else this.data = this.response.data;

        this.equiposOdv = this.data.equipos;
        this.equiposOdv.forEach(element => {
          this.arrayActiveVol.push(element.idEquipo);
        });

        this.listclientes = this.data.customers_list_search;

        this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        this.loader = 2;
      }
    )
  }

  agregarVol25(id){

    if(this.arrayActiveVol.includes(id)){
      var x = this.arrayActiveVol.indexOf(id);
      this.arrayActiveVol.splice(x, 1);
    }
    else this.arrayActiveVol.push(id);
  }

  obtClienteId(id_cliente:string){
    this.generadorReports.getPlantasByCliente(id_cliente).subscribe(
      res=>{
        this.response = res;
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
        else this.data = this.response.data;

        this.listPlantas = this.data;
        this.listPlantas.forEach(element => {
          if (element.principal == 1) {
            this.valueSearchPlanta = element.id;
          }
        });

        this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        this.loader = 2;
      }
    )
  }

  obtenerDatos(){
    var informes=[];
    if(this.temp == null || this.unidad == null || this.fecha == null || this.VolumenAjustado == null
      || this.temAgua == null) {
      alert("Campos vacios");
    } else {
      for (let i = 0; i < this.equiposOdv.length; i++) {
        let listmed2=[]; 
        let listIbc = [];
        let equipo_id = this.equiposOdv[i].idEquipo;
        
        //-- Volumen al maximo
        this.volumenMax1 = parseFloat((document.getElementById(equipo_id+"-0") as HTMLInputElement).value);
        this.volumenMax1 == null ? alert("Volumen maximo, campo 1 del equipo "+this.equiposOdv[i].tag+" vacía") : listIbc.push(this.volumenMax1);

        this.volumenMax2 = parseFloat((document.getElementById(equipo_id+"-1") as HTMLInputElement).value);
        this.volumenMax2 == null ? alert("Volumen maximo campo 2 del equipo "+this.equiposOdv[i].tag+" vacía") : listIbc.push(this.volumenMax2);

        this.volumenMax3 = parseFloat((document.getElementById(equipo_id+"-2") as HTMLInputElement).value);
        this.volumenMax3 == null ? alert("Volumen maximo campo 3 del equipo "+this.equiposOdv[i].tag+" vacía") : listIbc.push(this.volumenMax3);

        this.volumenMax4 = parseFloat((document.getElementById(equipo_id+"-3") as HTMLInputElement).value);
        this.volumenMax4 == null ? alert("Volumen maximo campo 4 del equipo "+this.equiposOdv[i].tag+" vacía") : listIbc.push(this.volumenMax4);

        this.volumenMax5 = parseFloat((document.getElementById(equipo_id+"-4") as HTMLInputElement).value);
        this.volumenMax5 == null ? alert("Volumen maximo campo 5 del equipo "+this.equiposOdv[i].tag+" vacía") : listIbc.push(this.volumenMax5);

        this.volumenMax6 = parseFloat((document.getElementById(equipo_id+"-5") as HTMLInputElement).value);
        this.volumenMax6 == null ? alert("Volumen maximo campo 6 del equipo "+this.equiposOdv[i].tag+" vacía") : listIbc.push(this.volumenMax6);

        this.volumenMax7 = parseFloat((document.getElementById(equipo_id+"-6") as HTMLInputElement).value);
        this.volumenMax7 == null ? alert("Volumen maximo campo 7 del equipo "+this.equiposOdv[i].tag+" vacía") : listIbc.push(this.volumenMax7);

        this.volumenMax8 = parseFloat((document.getElementById(equipo_id+"-7") as HTMLInputElement).value);
        this.volumenMax8 == null ? alert("Volumen maximo campo 8 del equipo "+this.equiposOdv[i].tag+" vacía") : listIbc.push(this.volumenMax8);

        this.volumenMax9 = parseFloat((document.getElementById(equipo_id+"-8") as HTMLInputElement).value);
        this.volumenMax9 == null ? alert("Volumen maximo campo 9 del equipo "+this.equiposOdv[i].tag+" vacía") : listIbc.push(this.volumenMax9);

        this.volumenMax10 = parseFloat((document.getElementById(equipo_id+"-9") as HTMLInputElement).value);
        this.volumenMax10 == null ? alert("Volumen maximo campo 10 del equipo "+this.equiposOdv[i].tag+" vacía") : listIbc.push(this.volumenMax10);

        if (this.arrayActiveVol.includes(equipo_id)) {
          
          //-------- VOlumen 25%
          this.volumen2_1 = parseFloat((document.getElementById(equipo_id+"v20") as HTMLInputElement).value);
          
          this.volumen2_2 = parseFloat((document.getElementById(equipo_id+"v21") as HTMLInputElement).value);
          
          this.volumen2_3 = parseFloat((document.getElementById(equipo_id+"v22") as HTMLInputElement).value);
          
          this.volumen2_4 = parseFloat((document.getElementById(equipo_id+"v23") as HTMLInputElement).value);
          
          this.volumen2_5 = parseFloat((document.getElementById(equipo_id+"v24") as HTMLInputElement).value);
          
          this.volumen2_6 = parseFloat((document.getElementById(equipo_id+"v25") as HTMLInputElement).value);
          
          this.volumen2_7 = parseFloat((document.getElementById(equipo_id+"v26") as HTMLInputElement).value);
          
          this.volumen2_8 = parseFloat((document.getElementById(equipo_id+"v27") as HTMLInputElement).value);
          
          this.volumen2_9 = parseFloat((document.getElementById(equipo_id+"v28") as HTMLInputElement).value);
          
          this.volumen2_10 = parseFloat((document.getElementById(equipo_id+"v29") as HTMLInputElement).value);

          this.volumen2_1 != null ? listmed2.push(this.volumen2_1) : alert("Volumen 25 %, campo 1 del equipo "+this.equiposOdv[i].tag+" vacía");
          this.volumen2_2 != null ? listmed2.push(this.volumen2_2) : alert("Volumen 25 %, campo 2 del equipo "+this.equiposOdv[i].tag+" vacía");
          this.volumen2_3 != null ? listmed2.push(this.volumen2_3) : alert("Volumen 25 %, campo 3 del equipo "+this.equiposOdv[i].tag+" vacía");
          this.volumen2_5 != null ? listmed2.push(this.volumen2_4) : alert("Volumen 25 %, campo 4 del equipo "+this.equiposOdv[i].tag+" vacía");
          this.volumen2_5 != null ? listmed2.push(this.volumen2_5) : alert("Volumen 25 %, campo 5 del equipo "+this.equiposOdv[i].tag+" vacía");
          this.volumen2_6 != null ? listmed2.push(this.volumen2_6) : alert("Volumen 25 %, campo 6 del equipo "+this.equiposOdv[i].tag+" vacía");
          this.volumen2_7 != null ? listmed2.push(this.volumen2_7) : alert("Volumen 25 %, campo 7 del equipo "+this.equiposOdv[i].tag+" vacía");
          this.volumen2_8 != null ? listmed2.push(this.volumen2_8) : alert("Volumen 25 %, campo 8 del equipo "+this.equiposOdv[i].tag+" vacía");
          this.volumen2_9 != null ? listmed2.push(this.volumen2_9) : alert("Volumen 25 %, campo 9 del equipo "+this.equiposOdv[i].tag+" vacía");
          this.volumen2_10 != null ? listmed2.push(this.volumen2_10) : alert("Volumen 25 %, campo 10 del equipo "+this.equiposOdv[i].tag+" vacía");
        }
        
          let global = [];
          global.push(this.temp);
          global.push(this.unidad);
          global.push(this.format);
          global.push(this.fecha);
          global.push(this.lugar);
          global.push(this.valueSearchCliente);
          global.push(this.valueSearchPlanta);
          global.push(this.VolumenAjustado);
          global.push(this.temAgua);
          
        let clic = this.uri+'heineken_volumen_1?api_key='+this.api_key+'&token='+this.token+
          '&module=2&id_plantilla='+this.idShet+'&libc='+listIbc+
          '&lmed2='+listmed2+
          '&globales='+global+"&id_equipo="+equipo_id+"&patron_id="+this.idpatron;
        let a=[];
        a[0] = clic;
        a[1]=this.equiposOdv[i].identificador+this.equiposOdv[i].partida;
        informes.push(a);
      }
    
    this.pdfInformes = informes;
    this.loader=2;
    }
    
    
  }


  asignarInforme(url_pdf){
    this.loader = 1;
    url_pdf = url_pdf[0];

    this.conn.get(url_pdf+"&asignar="+"asignar").subscribe(
      res => {
        this.response = res;
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
        else this.data = this.response.data;
        alert("Asignado correctamente");
        this.loader = 2;
      },
      error=>{
        console.log(error);
        alert("Error de petición");
        this.loader = 2;
      }
    );
  }


  lauchModal(code:string,title:string){
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

}
