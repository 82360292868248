import { Component, ElementRef, HostListener, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { OrdenVentaService } from "src/app/services/orden-venta.service";
import { QuotationService } from "src/app/services/quotation.service";
import { environment } from "src/app/variables/enviroment";

interface FiltersCot {
  folio?: string,
  estatus?: number,
  rs?: number,
  rep_ventas?: number,
  prioridad?: number,
  cliente?: number,
  monto_estatus?: string,
  monto?: number,
  type_date?: number,
  date_month?: string,
  start_date?: string,
  end_date?: string
}

@Component({
  selector: "app-cotizaciones",
  templateUrl: "./cotizaciones.component.html",
  styleUrls: ["./cotizaciones.component.scss"],
})
export class CotizacionesComponent implements OnInit {
  loader: number = 1;
  permmisions = JSON.parse(localStorage.getItem("permisos"));
  @ViewChild("btn_close_modal") divView: ElementRef;
  //Variables para buscar cliente
  client_to_search = "";
  clients_result: any = [];
  client_id = "0";
  new_client = false;

  //Variables para el XLSX
  file_name = "";
  file: any;

  //Variables para filtro
  filter = false;
  //Values for modal filter
  public id_filtro:number = 0;
  public filters = [
    { id:0, active: true,  value: "Estatus",        clave: 'estatus',       filtered: false, hide: false },
    { id:1, active: false, value: "Rep. De Ventas", clave: 'rep_ventas',    filtered: false, hide: false },
    { id:2, active: false, value: "Razón Social",   clave: 'rs',            filtered: false, hide: false },
    { id:3, active: false, value: "Prioridad",      clave: 'prioridad',     filtered: false, hide: false },
    { id:4, active: false, value: "Fecha",          clave: 'type_date',     filtered: false, hide: false },
    { id:5, active: false, value: "Monto",          clave: 'monto_estatus', filtered: false, hide: false },
    { id:6, active: false, value: "Busqueda",       clave: 'folio',         filtered: false, hide: true },
    { id:7, active: false, value: "Cliente",        clave: 'cliente',       filtered: false, hide: false },
  ];


  data = [];
  data_showed: any = [];

  /* FILTRO POR REPRESENTANTE DE VENTAS */
  public sales_rep_list = [];

  /* FILTRO POR ESTATUS */
  public estatus_filter_list = [
    {name:'Abierto', value: 0},
    {name:'Ganado', value: 1},
    {name:'Perdido', value: 2},
    {name:'Enviado', value: 3},
    {name:'Toma decisión', value: 4}
  ];

  //Variables para monto

  public monto_equals = [
    {monto: 'igual a', value: '='},
    {monto: 'Mayor a', value: '>'},
    {monto: 'Menor a', value: '<'},
  ];

  value_sum = "";
  loader_add_odv = 2;

  //Variables para razon social - CREAR COT
  public social_reason_list = [];
  social_reason_id = "1";

  /* FILTRO POR PRIORIDAD */
  public  priority_list = [];

  /* FILTRO POR FECHAS */
  public meses = [
    { mes: "01", value: "Enero" },
    { mes: "02", value: "Febrero" },
    { mes: "03", value: "Marzo" },
    { mes: "04", value: "Abril" },
    { mes: "05", value: "Mayo" },
    { mes: "06", value: "Junio" },
    { mes: "07", value: "Julio" },
    { mes: "08", value: "Agosto" },
    { mes: "09", value: "Septiembre" },
    { mes: "10", value: "Octubre" },
    { mes: "11", value: "Noviembre" },
    { mes: "12", value: "Diciembre" },
  ];
  public anios = [
    { anio: '2024' },
    { anio: '2023' },
    { anio: '2022' },
    { anio: '2021' },
    { anio: '2020' },
    { anio: '2019' },
  ];
  public fechas_filter = [
    { active: false, value: "Por mes" },
    { active: false, value: "Por fechas" },
  ];
  public selected_month:string = '01';
  public selected_year:string = '';
  private fecha_actual = new Date();

  /* PAGINACION COTIZACIONES */
  public total_quotations:number = 0;
  public data_loaded:boolean = false;
  private current_page:number = 1;
  private all_data_loaded:boolean = false;

  /* FILTROS GENERAL */
  public filters_cot:FiltersCot = {};
  public filters_cot_default:FiltersCot = {
    folio:'',
    estatus: -1,
    rep_ventas: -1,
    rs: -1,
    prioridad: 0,
    cliente: 0,
    monto_estatus:'',
    monto:0,
    type_date: -1,
    date_month:'',
    start_date:'',
    end_date:''
  }
  private filters_cot_active:FiltersCot = {
    folio:'',
    estatus: -1,
    rep_ventas: -1,
    rs: -1,
    prioridad: 0,
    cliente: 0,
    monto_estatus:'',
    type_date: -1,
  }
  public filter_count:number = 0

  public filters_aplicated = [];
  public filters_selected = [];

  constructor(
    private odvService: OrdenVentaService,
    private modalService: NgbModal,
    private quotationServ: QuotationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.selected_year = this.fecha_actual.getFullYear().toString();
    Object.assign(this.filters_cot, this.filters_cot_default);
    this.getData();
  }
  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.onScroll);
  }

 /**
  * The `getData` function retrieves data from a server and updates various properties based on the
  * response.
  */
  private getData(): void {
    this.loader = 1;
    this.data_loaded = false;
    this.quotationServ.getQuotationList(this.current_page).subscribe(
      (res) => {
        let response: any = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004")
          this.lauchModal(response.code, response.message, "");
        else {
          this.data = [...this.data, ...response.data.data_list.data];
          this.total_quotations = response.data.data_list.total;
          
          if(this.total_quotations == this.data.length)  this.all_data_loaded = true;
          else this.all_data_loaded = false;

          this.sales_rep_list = response.data.sales_rep;
          this.social_reason_list = response.data.business_name;
          this.priority_list = response.data.priority_list;
          this.filter_count = this.countActiveFilters(this.filters_cot, this.filters_cot_default);

          this.data_showed = this.data;
        }
        this.data_loaded = true;
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }
  
  /**
   * The function formats a given value as currency in Mexican pesos.
   * @param value - The value parameter is the number that needs to be formatted as currency in Mexican
   * pesos (MXN).
   * @returns The `setFormat` function is returning a formatted string representation of the `value`
   * parameter, using the `Intl.NumberFormat` API to format the number as a currency in Mexican pesos
   * (MXN).
   */
  public setFormat(value): string {
    const options2 = { style: "currency", currency: "MXN" };
    const numberFormat2 = new Intl.NumberFormat("es-MX", options2);

    return numberFormat2.format(value);
  }

  /**
   * The function changes the active filter in a modal based on the selected position.
   * @param position - Position is a number that represents the index of the filter in the filters
   * array that needs to be activated.
   */
  public changeFilterModal(id): void {
    this.filters.forEach((v) => {
      v.active = false;
    });
    const FILTRO_SELECTED = this.filters.find((objeto) => objeto.id == id);

    FILTRO_SELECTED.active = true;
  }

  //busqueda de cliente
  /**
   * This function searches for a client using a given search criteria and displays the results in a
   * modal window.
   */
  public searchClient(): void {
    this.loader = 1;
    this.odvService.getClientSearchCot(this.client_to_search).subscribe(
      (res) => {
        this.clients_result = res;
        if (
          this.clients_result.code == "1_0001" ||
          this.clients_result.code == "1_0002" ||
          this.clients_result.code == "1_0003" ||
          this.clients_result.code == "1_0004"
        )
          this.lauchModal(
            this.clients_result.code,
            this.clients_result.message,
            ""
          );
        else this.clients_result = this.clients_result.data;
        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 0;
      }
    );
  }

  //Asignacion de cliente
  /**
   * This is a private function in TypeScript that sets the client ID.
   * @param id - The "id" parameter is a value that represents the client ID that will be set for the
   * current object instance. The method "setClient" is a private method that sets the client ID for
   * the object instance.
   */
  public setClient(id): void {
    this.client_id = id;
  }

  //Carga de archivo
  /**
   * This function loads a file and sets its name as a property.
   * @param value - The parameter "value" is an event object that is passed as an argument to the
   * function when it is called. It contains information about the event that triggered the function,
   * such as the target element that was interacted with. In this case, it is likely an event object
   * related to a file input
   */
  public loadFile(value): void {
    this.file = value.target;
    this.file_name = this.file.files[0].name;
  }

  /**
   *
   *
   * Agregar registro
   *
   *
   */

  /**
   * This function generates a quote by sending data to a server and handling the response.
   */
  public generateQuote(): void {
    let data = new FormData();
    data.append("client_id", this.client_id);
    data.append("new_client", this.new_client ? "1" : "0");
    data.append("social_reason", this.social_reason_id);

    this.loader = 1;
    this.quotationServ.addNewQuotation(data).subscribe(
      (res) => {
        this.loader = 2;
        let resp: any = res;
        if (resp.code != "0_005") this.lauchModal(resp.code, resp.message, "");
        else {
          this.divView.nativeElement.click();
          this.router.navigateByUrl("/commercial/cot/det/" + resp.data);
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * This is a private function in TypeScript that sets the social reason ID.
   * @param id - The parameter "id" is a variable that represents the social reason ID that will be set
   * in the object.
   */
  public setSocialReason(id): void {
    this.social_reason_id = id;
  }

  /*******************************
  *    DESCARGAR REPORTES        *
  *******************************/

/**
 * The function `descargarReporte` is used to generate and download a report, handling different
 * response codes and displaying error messages if necessary.
 */
  public descargarReporte(): void {
    this.loader = 1;
    this.quotationServ.generarReporte().subscribe(
      (res) => {
        let resp: any = res;
        if (
          resp.code == "1_0001" ||
          resp.code == "1_0002" ||
          resp.code == "1_0003" ||
          resp.code == "1_0004" ||
          resp.code == "1_005"
        )
          this.lauchModal(
            this.clients_result.code,
            this.clients_result.message,
            "");
        else {
            this.downloadFile(resp.data);
        }
        
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
 * The `downloadFile` function opens a new window to download a file from a specific URL.
 * @param file_name - The `file_name` parameter is a string that represents the name of the file that
 * you want to download.
 */
  private downloadFile(file_name): void {
    let url =
      environment.uri_server +
      "storage/public/cotizaciones/reportes/generados/" +
      file_name;
    window.open(url, "_blank");
  }

  /*******************************
  *    SCROLLING COTIZACIONES   *
  *******************************/


  /* The above code is a TypeScript function that listens for the scroll event on the window. When the
  user scrolls, the function checks if the scroll position is near the bottom of the page. If it is,
  and if the necessary conditions are met (i.e., `all_data_loaded` is false, `data_loaded` is true),
  it increments the `current_page` variable and calls the `getData()` function to fetch more data. */
  @HostListener('window:scroll', ['$event'])
  public onScroll(): void {
    const windowHeight = document.body.offsetHeight;
    const scrollThreshold = 10;

    if (!this.all_data_loaded && this.isScrolledToBottom(windowHeight, scrollThreshold) && this.data_loaded) {
        this.current_page++;
        this.getData();
    }
  }

  /**
 * The function determines if the user has scrolled to the bottom of the page by comparing the window
 * height, scroll threshold, and current scroll position.
 * @param {number} windowHeight - The `windowHeight` parameter represents the height of the window or
 * viewport in pixels. It is used to calculate whether the user has scrolled to the bottom of the page.
 * @param {number} scrollThreshold - The scrollThreshold parameter is a number that represents the
 * distance from the bottom of the window at which the function considers the user to have scrolled to
 * the bottom.
 * @returns a boolean value indicating whether the user has scrolled to the bottom of the window.
 */
  private isScrolledToBottom(windowHeight: number, scrollThreshold: number): boolean {
    const isScrolledToBottom = (window.innerHeight + window.scrollY + scrollThreshold) >= windowHeight;
    return isScrolledToBottom;
  }

  /**
   * The function sets a boolean variable to indicate that all data has been loaded.
   */
  private activeScrollLoad() : void {
    this.all_data_loaded = true;
  }

 /**
  * The function "desactiveScrollLoad" sets the variable "all_data_loaded" to false.
  */
  private desactiveScrollLoad() : void {
    this.all_data_loaded = false;
  }

  /*******************************
  *    FILTERS COTIZACIONES       *
  *******************************/
  /**
   * The function `getDataFilter` retrieves data from a server and updates the component's properties
   * based on the response.
   */
  public getDataFilter(): void {
    this.loader = 1;
    this.quotationServ.getQuotationFilterList(this.filters_cot).subscribe(
      (res) => {
        let response: any = res;
        if (
          response.code == "1_0001" ||
          response.code == "1_0002" ||
          response.code == "1_0003"
        )
          this.lauchModal(response.code, response.message, "");
        else if (response.code == "1_0004")
          this.lauchModal(response.code, response.message, "");
        else {
          this.data = response.data.data_list;
          this.social_reason_list = response.data.business_name;
          this.priority_list = response.data.priority_list;
          this.data_showed = this.data;
          this.activeScrollLoad();
          this.filter_count = this.countActiveFilters(this.filters_cot, this.filters_cot_active);
        }
        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 0;
      }
    );
  }

  /**
   * The function `countActiveFilters` calculates the number of active filters by comparing two objects
   * and counting the differences.
   * @param Filtros - Filtros is an object that represents the current filters being applied. It
   * contains key-value pairs where the key represents the filter name and the value represents the
   * filter value.
   * @param FiltrosDefault - FiltrosDefault is an object that represents the default values for the
   * filters. It contains key-value pairs where the keys represent the filter names and the values
   * represent the default values for those filters.
   * @returns the number of active filters, which is represented by the variable "diferencias".
   */
  private countActiveFilters(Filtros, FiltrosDefault) : number {
    const index_filtro = Object.keys(Filtros);
    const index_filtro2 = Object.keys(FiltrosDefault);
    let diferencias = 0;
    for (const clave of index_filtro) {
      if (index_filtro2.includes(clave) && Filtros[clave] !== FiltrosDefault[clave]) {
        diferencias++;
      }
    }
    return diferencias;
  }
  
  /**
   * The function "clearAllFilters" resets all filters to their default values and clears the selected
   * filters and data.
   */
  public clearAllFilters() : void {
    Object.assign(this.filters_cot, this.filters_cot_default);
    this.filters_selected = [];
    this.data = [];
    this.filters.forEach(element => {
      element.filtered = false;
    });
    this.getData();
  }

  /**
 * The function `addSelectedFilter` adds a new filter to the `filters_selected` array based on the
 * provided `nombre` and `tipo` values.
 * @param {string} nombre - The parameter "nombre" is a string that represents the name of the filter.
 * @param {number} tipo - The parameter "tipo" is a number that represents the type of filter.
 */
  public addSelectedFilter(nombre:string, tipo:number) : void {
    if (tipo === 7) {
        const CLIENTE = this.clients_result.find(objeto => objeto.id === Number(nombre));
        nombre = CLIENTE.abreviatura;
    }

    const new_filter = {
        name: nombre,
        type: tipo,
        filter_name: this.filters[tipo].value,
        filter_value: this.filters[tipo].clave,
    };

    const indice_tipo = this.filters_selected.findIndex(obj => obj.type === new_filter.type);

    if (indice_tipo !== -1) {
        this.filters_selected[indice_tipo] = new_filter;
    } else {
        this.filters_selected.push(new_filter);
    }

    this.filters[tipo].filtered = true;
  }

  /**
 * The function "disabledRemoveFilter" checks if there is an active filter and returns true if there is
 * no active filter or if the active filter is not currently being applied.
 * @returns a boolean value. If the active filter is not filtered, it will return true. Otherwise, it
 * will return false.
 */
  public disabledRemoveFilter():boolean {
    const FILTRO_ACTIVO = this.filters.find((objeto) => objeto.active === true);

    if(FILTRO_ACTIVO.filtered) {
      return false;
    }
    return true;
  }

  /**
  * The function removes the selected filter from the filters_selected array and resets the
  * corresponding filter in the filters_cot array.
  */
  public removeSelectedFilter() : void {
    const FILTRO_ACTIVO = this.filters.find((objeto) => objeto.active === true);
    const indice_tipo = this.filters_selected
      .findIndex(obj => obj.filter_value === FILTRO_ACTIVO.clave);
    this.filters_selected.splice(indice_tipo, 1);

    this.filters_cot[FILTRO_ACTIVO.clave] = this.filters_cot_default[FILTRO_ACTIVO.clave];
    this.filters[FILTRO_ACTIVO.id].filtered = false;
  }
 
  /**
 * The function checks if the filters for a quotation can be applied based on certain conditions.
 * @returns a boolean value. If any of the specified conditions are met, it will return true.
 * Otherwise, it will return false.
 */
  public canApplyFilters():boolean {
      this.filters_cot.monto = this.filters_cot.monto ?? 0;

      if (
          (this.filters_cot.type_date === 1 && (this.filters_cot.start_date === '' || this.filters_cot.end_date === '')) ||
          (this.filters_cot.type_date === 0 && this.filters_cot.date_month === '') ||
          (this.filters_cot.monto_estatus !== '' && this.filters_cot.monto === 0) ||
          (this.filters_cot.monto !== 0 && this.filters_cot.monto_estatus === '')
      ) {
          return true;
      }
  
      return false;
  }

  /**
 * The function `getMonthYearFilter()` sets the `date_month` filter based on the selected year and
 * month.
 */
  getMonthYearFilter(){
    this.filters_cot.date_month = 
    `${this.selected_year}-${this.selected_month}`
  }


  /**
   * This function launches a modal with a given code, title, and message using the Angular Material
   * modal service.
   * @param {string} code - a string representing some code that will be displayed in the modal
   * @param {string} title - The title of the modal that will be displayed.
   * @param message - The message parameter is likely a string that contains the content to be
   * displayed in the body of the modal dialog box. It could be a message to the user or some other
   * information related to the code or title being displayed.
   */
  private async lauchModal(
    code: string,
    title: string,
    message
  ): Promise<void> {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
}
 