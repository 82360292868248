import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../variables/enviroment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class VacationsService {

  uri:string = environment.uri;

  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');


  constructor(private httpClient:HttpClient) { }


  getUserVacationDetail(id){
    return this.httpClient.get(this.uri+"get_user_vacation_detail?api_key="+this.api_key+"&token="+this.token+"&module=177&user_det="+id);
  }

  changeDetailUser(form:FormData){
    return this.httpClient.post(this.uri+"change_detail_user?api_key="+this.api_key+"&token="+this.token+"&module=177", form);
  }

  sendVacationRequest(holiday_request){
    return this.httpClient.post(this.uri+"send_vacation_request?api_key="+this.api_key+"&token="+this.token+"&module=177", holiday_request);
  }

  getVacationRequests(){
    return this.httpClient.get(this.uri+"get_vacation_requests?api_key="+this.api_key+"&token="+this.token+"&module=177");
  }

  getAllVacationRequests(){
    return this.httpClient.get(this.uri+"get_all_vacation_requests?api_key="+this.api_key+"&token="+this.token+"&module=177");
  }

  updateStatusRquests(data){
    return this.httpClient.post(this.uri+"update_status_vacation_requests?api_key="+this.api_key+"&token="+this.token+"&module=177", data);
  }

  updateRemainingDays(days){
    return this.httpClient.post(this.uri+"update_remaining_vacation_days?api_key="+this.api_key+"&token="+this.token+"&module=177&n_days="+days, days);
  }

  updateUserVacationEstatus(data){
    return this.httpClient.post(this.uri+"update_user_vacation_status?api_key="+this.api_key+"&token="+this.token+"&module=177", data);
  }

  getRequestDetail(id){
    return this.httpClient.get(this.uri+"get_request_detail?api_key="+this.api_key+"&token="+this.token+"&module=152&req_id="+id);
  }


  /* updateAntiquity(){
    return this.httpClient.get(this.uri+"get_user_antiquity?api_key="+this.api_key+"&token="+this.token+"&module=152");
  } */
  
}
