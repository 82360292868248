import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { AdministrationModule } from './administration/administration.module';
import { CommercialModule } from './commercial/commercial.module';
import { OperationsModule } from './operations/operations.module';
import { ProgrammersModule } from './programmers/programmers.module';
import { ModalPdfComponent } from 'src/app/components/modal-pdf/modal-pdf.component';

FullCalendarModule.registerPlugins([ 
  dayGridPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
    FullCalendarModule,
    FormsModule,

    AdministrationModule,
    CommercialModule,
    OperationsModule,
    ProgrammersModule
  ],
  providers:[
  ]
})
export class ModulesModule { }
