import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { InstrumentService } from "src/app/services/instrument.service";
import { IBrand } from "src/app/services/models/IBrand";

@Component({
  selector: "app-brands",
  templateUrl: "./brands.component.html",
  styleUrls: ["./brands.component.css"],
})
export class BrandsComponent implements OnInit {
  private response: any = [];
  public loader: number = 0;
  permmisions = JSON.parse(localStorage.getItem("permisos"));

  public brands_list: IBrand[] = [];
  public pages: number = 0;
  public number_page: number = 0;
  public total_brands: number = 0;
  public pageSize: number = 15; // Cantidad de elementos por página

  public add_brand: boolean = false;
  public search_brand: boolean = false;

  public brand_search: string = "";
  public brand_search_id: string;
  public results_search: any = [];

  public new_brand: IBrand = {};

  public recieved_brand_name: string = "";
  public recieved_brand_id: string;

  //FILTROS
  public filter_search_brand: string = "";

  //ULTIMAS CREADAS
  public total_last_brands:number = 0;
  public last_brands: IBrand[] = [];
  public view_last_added: boolean = false;
  public order_by: any[] = [
    { id: 0, value: "all", name: "Ordenar por" },
    { id: 1, value: "equipos_asignados", name: "Equipos Asignados" },
    { id: 2, value: "brand", name: "Nombre" },
    { id: 3, value: "created_at", name: "Creación" },
  ];
  public order_selected: string = "all";
  public asc_desc: string = "asc";

  public view = 0;

  constructor(
    private instument_service: InstrumentService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.getBrands();
  }

  /**
   * The `getBrands` function retrieves a list of brands from a service and updates the component's
   * state accordingly.
   */
  private getBrands(): void {
    this.loader = 1;
    this.instument_service.getAllBrands().subscribe(
      (res) => {
        this.response = res;

        if (this.response.code != "0_005")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.brands_list = this.response.data;
          this.pages = Math.ceil(this.brands_list.length / this.pageSize);
          this.total_brands = this.response.data.length;
        }
        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", err.message);
        this.loader = 0;
      }
    );
  }

  /**
   * The function `get30DaysBrands` makes an API call to retrieve the last 30 days' worth of brands and
   * handles the response accordingly.
   */
  private get30DaysBrands(): void {
    this.loader = 1;
    this.instument_service.get30DaysBrands().subscribe(
      (res) => {
        this.response = res;

        if (this.response.code != "0_005")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.last_brands = this.response.data;
          this.total_last_brands = this.last_brands.length;
        }
        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", err.message);
        this.loader = 0;
      }
    );
  }

  /**
   * The function "onSearchBrand" filters a list of brands based on a search query and updates the
   * total number of brands and the number of pages accordingly.
   * @param {string} search - The "search" parameter is a string that represents the brand name or
   * keyword that the user wants to search for.
   */
  public onSearchBrand(search: string) {
    this.number_page = 0;
    this.filter_search_brand = search;

    this.total_brands = this.brands_list.filter((item) =>
      item.brand.toUpperCase().includes(search.toUpperCase())
    ).length;
    this.pages = Math.ceil(this.total_brands / this.pageSize);
  }

  /**
   * The function "dataNewBrand" initializes a new brand object with default values.
   */
  public dataNewBrand(): void {
    this.new_brand.id = "";
    this.new_brand.brand = this.filter_search_brand;
    this.new_brand.notes = "N/A";
    this.new_brand.isNew = true;
    this.new_brand.num_instruments = 0;
  }

  /**
   * The addBrand function saves a new brand registration and displays a success message if the
   * operation is successful.
   */
  public addBrand(): void {
    this.loader = 1;
    this.instument_service.saveNewBrandRegister(this.new_brand).subscribe(
      (res) => {
        let data: any = res;
        this.loader = 2;
        if (
          data.code == "1_0001" ||
          data.code == "1_0002" ||
          data.code == "1_0003"
        )
          this.lauchModal(data.code, data.message, "");
        else if (data.code == "1_0004")
          this.lauchModal(data.code, data.message, "");
        else if (data.code == "0_008") {
          this.lauchModal(data.code, data.message, "");
        } else {
          this.lauchModal(
            "1",
            "Operación exitosa",
            `Se ha creado la marca ${this.new_brand.brand} correctamente.`
          );

          this.canAddBrand();
          this.getBrands();
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * The `editBrand` function saves a edited brand registration and displays a modal with a success
   * message if the operation is successful.
   */
  public editBrand(): void {
    this.loader = 1;
    this.instument_service.saveNewBrandRegister(this.new_brand).subscribe(
      (res) => {
        let data: any = res;
        this.loader = 2;
        if (
          data.code == "1_0001" ||
          data.code == "1_0002" ||
          data.code == "1_0003"
        )
          this.lauchModal(data.code, data.message, "");
        else if (data.code == "1_0004")
          this.lauchModal(data.code, data.message, "");
        else if (data.code == "0_008") {
          this.lauchModal(data.code, data.message, "");
        } else {
          this.lauchModal(
            "1",
            "Operación exitosa",
            `Se ha modificado la marca correctamente.`
          );
          this.getBrands();
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * The `removeBrand` function is used to remove a brand from a list and display a success message if
   * the operation is successful.
   */
  public removeBrand(): void {
    this.loader = 1;
    this.instument_service.removeBrand(this.new_brand.id).subscribe(
      (res) => {
        let data: any = res;
        this.loader = 2;
        if (
          data.code == "1_0001" ||
          data.code == "1_0002" ||
          data.code == "1_0003"
        )
          this.lauchModal(data.code, data.message, "");
        else if (data.code == "1_0004")
          this.lauchModal(data.code, data.message, "");
        else {
          this.lauchModal(
            "1",
            "Operación exitosa",
            `Se ha Eliminado la marca correctamente.`
          );
          this.getBrands();
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * The function `dataBrandEdit` assigns values from the `brand` object to properties of the
   * `new_brand` object.
   * @param brand - The "brand" parameter is an object that contains the following properties:
   */
  public dataBrandEdit(brand): void {
    this.new_brand.id = brand.id;
    this.new_brand.brand = brand.brand;
    this.new_brand.notes = brand.notas;
    this.new_brand.isNew = false;
    this.new_brand.num_instruments = brand.equipos_asignados;
  }

  /**
   * The function assigns an empty value to certain variables and resets a boolean flag.
   */
  public assignBrandEmpty(): void {
    this.recieved_brand_id = this.brand_search_id;
    this.recieved_brand_name = this.brand_search;

    this.results_search = [];
    this.brand_search_id = null;
    this.brand_search = null;
    this.search_brand = false;
  }

  /**
   * The `emptyBrand` function sends a request to the server to empty a brand and handles the response
   * accordingly.
   */
  public emptyBrand(): void {
    this.loader = 1;
    let brands = new FormData();
    brands.append("empty", this.new_brand.id);
    brands.append("recieved", this.recieved_brand_id);

    this.instument_service.emptyBrand(brands).subscribe(
      (res) => {
        let data: any = res;
        if (
          data.code == "1_0001" ||
          data.code == "1_0002" ||
          data.code == "1_0003"
        )
          this.lauchModal(data.code, data.message, "");
        else if (data.code == "1_0004")
          this.lauchModal(data.code, data.message, "");
        else {
          this.getBrands();
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * The function "puedeAgregarMarca" toggles the "add_brand" property and updates other related
   * properties accordingly.
   */
  public canAddBrand(): void {
    if (this.add_brand === false) {
      this.add_brand = true;
      this.new_brand.brand = this.brand_search;
    } else {
      this.add_brand = false;
      this.brand_search = "";
      this.search_brand = false;
      this.new_brand.notes = "N/A";
    }
  }

  /**
   * The function `searchBrand` is used to search for a brand based on the input provided by the user,
   * and it handles the response and error cases accordingly.
   * @param data - The "data" parameter is an object that contains the value of the search input field.
   */
  public searchBrand(data): void {
    this.loader = 1;
    this.brand_search = data.target.value;

    this.instument_service.searchBrand(this.brand_search).subscribe(
      (res) => {
        let data: any = res;
        if (
          data.code == "1_0001" ||
          data.code == "1_0002" ||
          data.code == "1_0003"
        )
          this.lauchModal(data.code, data.message, "");
        else if (data.code == "1_0004")
          this.lauchModal(data.code, data.message, "");
        else {
          this.results_search = data.data;
        }
        this.search_brand = true;
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  /**
   * The function `verUltimasCreadas` toggles the `view_last_added` property and calls the
   * `get30DaysBrands` function if `view_last_added` is false.
   */
  public verUltimasCreadas() {
    if (this.view_last_added === false) {
      this.view_last_added = true;
      this.filter_search_brand = "";
      this.get30DaysBrands();
    } else {
      this.view_last_added = false;
    }
    this.view = 0;
  }

  /**
   * The function "changePageResults" updates the value of the "number_page" variable with the provided
   * "page" parameter.
   * @param page - The parameter "page" is the new page number that you want to set for the
   * "number_page" property.
   */
  public changePageResults(page): void {
    this.number_page = page;
  }

  /**
   * The `lauchModal` function is a private async function that opens a modal component with a given
   * code, title, and message.
   * @param {string} code - The `code` parameter is a string that represents the code to be displayed
   * in the modal. It could be any valid code snippet or code block.
   * @param {string} title - The `title` parameter is a string that represents the title of the modal.
   * It is used to set the `title` property of the `ModalComponent` instance.
   * @param message - The `message` parameter is a string that represents the message to be displayed
   * in the modal.
   */
  private async lauchModal(
    code: string,
    title: string,
    message
  ): Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
}
 