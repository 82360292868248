import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IInstrument } from './models/IInstrument';
import { environment } from 'src/app/variables/enviroment';
import { url } from 'inspector';
import { rendererTypeName } from '@angular/compiler';
import { IBrand } from './models/IBrand';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InstrumentService {
  //uri:string = "http://192.168.0.7/etalonsbackend/public/index.php/api/";
  uri:string = environment.uri;
  //uri:string = "http://ngdat.ucal.com.mx/api/";

  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');
  constructor(private httpClient:HttpClient) { }

  getAllInstruments(){  
    return this.httpClient.get(this.uri+"get_user_stock?api_key="+this.api_key+"&token="+this.token+"&module=1");
  }

  getInstrumentDetail(instrument_id){
    let url = this.uri+"get_instument_detail?api_key="+this.api_key+"&token="+this.token+"&module=10&id_instrument="+instrument_id;
    return this.httpClient.get(url);
  }

  saveEdit(data:IInstrument){
    return this.httpClient.post(this.uri+"save_edit_instrument?api_key="+this.api_key+"&token="+this.token+"&module=50", data);
  }

  getInstrumentExpired(reporte){
    let token = localStorage.getItem('token');
    let api_key = localStorage.getItem('key');
    return this.httpClient.get(this.uri+"get_expired_instruments?api_key="+api_key+"&module=13&token="+token+"&reporte="+reporte);
  }

  getInstrumentExpiredByDepto(value){
    let token = localStorage.getItem('token');
    let api_key = localStorage.getItem('key');
    return this.httpClient.get(this.uri+"get_expired_instruments_by_depto?api_key="+api_key+"&module=13&token="+token+"&id="+value);
  }

  getBusquedaGlobal(valorabuscar){
    return this.httpClient.get(this.uri+"search_value?api_key="+this.api_key+"&token="+this.token+"&module=35&value="+valorabuscar);
  }

  obtenerBusquedasPrevias(){
    return this.httpClient.get(this.uri+"get_previous_searches?api_key="+this.api_key+"&token="+this.token+"&module=35");
  }

  setComment(data){
    return this.httpClient.post(this.uri+"save_comment_instrument?api_key="+this.api_key+"&token="+this.token+"&module=10",data);
  }

  saveReport(data){
    return this.httpClient.post(this.uri+"save_report_instrument_det?api_key="+this.api_key+"&token="+this.token+"&module=10",data);
  }

  getReport(id_instrument,id_report){
    return this.httpClient.get(this.uri+"get_report?api_key="+this.api_key+"&token="+this.token+"&module=41&id_instrument="+id_instrument+"&id_report="+id_report);
  }

  getAllReports(){
    return this.httpClient.get(this.uri+"get_all_reports?api_key="+this.api_key+"&token="+this.token+"&module=19");
  }

  getAllReportsMaintenance(){
    return this.httpClient.get(this.uri+"get_all_reports_maintenance?api_key="+this.api_key+"&token="+this.token+"&module=19");
  }

  loadInstrumentImage(data){
    return this.httpClient.post(this.uri+"save_instrument_image?api_key="+this.api_key+"&token="+this.token+"&module=96",data);
  }

  searchInstrumentType(tipo){
    return this.httpClient.get(this.uri+"get_search_instriment_type?api_key="+this.api_key+"&token="+this.token+"&module=107&tipo="+tipo);
  }

  changeInstrumentPermanent(id_equipo, id_tipo){
    return this.httpClient.get(this.uri+"get_change_instrument_permanent?api_key="+this.api_key+"&token="+this.token+"&module=107&id_tipo="+id_tipo+"&id_equipo="+id_equipo);
  }

  getinformesEvent(id_evento){
    return this.httpClient.get(this.uri+"search_informes_event?api_key="+this.api_key+"&token="+this.token+"&module=120&id_evento="+id_evento);
  }

  searchBrand(valor){
    return this.httpClient.get(this.uri+"search_new_brand?api_key="+this.api_key+"&token="+this.token+"&module=50&brand="+valor);
  }

  searchDepto(id_instrument,valor){
    return this.httpClient.get(this.uri+"search_new_depto_for_instrument?api_key="+this.api_key+"&token="+this.token+"&module=50&value="+valor+"&id="+id_instrument);
  }

  getClientsByUser(){
    return this.httpClient.get(this.uri+"get_clients_list_xlsx?api_key="+this.api_key+"&token="+this.token+"&module=148")
  }

  getDeptosByClientUser(cliente){
    return this.httpClient.get(this.uri+"get_deptos_client_user?api_key="+this.api_key+"&token="+this.token+"&module=148&cliente="+cliente)
  }

  downloadReportsPdfByDate(fecha_inicio, fecha_final,client,type_report){
    return this.httpClient.get(this.uri+"download-reports-pdf-by-dates?api_key="+this.api_key+"&token="+this.token+"&module=148&cliente="+client+"&fecha_inicial="+fecha_inicio+"&fecha_final="+fecha_final+"&tipo="+type_report);
  }

  getListManual(){
    return this.httpClient.get(this.uri+"get_list_manual?api_key="+this.api_key+"&token="+this.token+"&module=10")
  }

  saveManual(data){
    return this.httpClient.post(this.uri+"save_manual_instrument?api_key="+this.api_key+"&token="+this.token+"&module=10",data);
  }

  asignarManual(id_manual,id_equipo){
    return this.httpClient.get(this.uri+"set_manual_instrument?api_key="+this.api_key+"&token="+this.token+"&module=10&id_manual="+id_manual+"&id_equipo="+id_equipo)
  }

  saveInstrumentoOdvDepto(data){
    return this.httpClient.post(this.uri+"save_instrument_odv_depto?api_key="+this.api_key+"&token="+this.token+"&module=107",data);
  }

  //almacen Internos
  listInternalInstruments(){
    return this.httpClient.get(this.uri+"list_internal_instruments?api_key="+this.api_key+"&token="+this.token+"&module=181");
  }

  getAllInstrumentRequests(year){
    return this.httpClient.get(this.uri+"get_all_instrument_requests?api_key="+this.api_key+"&token="+this.token+"&module=181&year="+year);
  }

  searchMainInstruments(tag:string){
    return this.httpClient.get(this.uri+"search_main_instruments_list?api_key="+this.api_key+"&token="+this.token+"&module=181&value_search="+tag);
  }

  saveInstrumentRequest(data){
    return this.httpClient.post(this.uri+"save_instrument_request?api_key="+this.api_key+"&token="+this.token+"&module=181", data);
  }

  getSelectedInstrumentRequests(id:number){
    return this.httpClient.get(this.uri+"get_selected_instrument_requests?api_key="+this.api_key+"&token="+this.token+"&module=181&instrument="+id);
  }

  saveInstrumentRequestEstatus(request, estatus){
    return this.httpClient.get(this.uri+"save_estatus_patron_request?api_key="+this.api_key+"&token="+this.token+"&module=181&req_id="+request+"&estatus="+estatus);
  }
  savePatronEstatus(equipo, estatus){
    return this.httpClient.get(this.uri+"save_estatus_patron?api_key="+this.api_key+"&token="+this.token+"&module=181&eq_id="+equipo+"&estatus="+estatus);
  }

  verifyPatronEstatus(){
    return this.httpClient.get(this.uri+"verify_estatus_patron?api_key="+this.api_key+"&token="+this.token+"&module=181");
  }

  searchEventPatron(folio:string){
    return this.httpClient.get(this.uri+"search_event_main_instrument?api_key="+this.api_key+"&token="+this.token+"&module=181&value_search="+folio);
  }
  searchRSPatron(folio:string){
    return this.httpClient.get(this.uri+"search_rs_main_instrument?api_key="+this.api_key+"&token="+this.token+"&module=181&value_search="+folio);
  }

  //Marcas
  saveNewBrandRegister(data:IBrand):Observable<IBrand>{
    return this.httpClient.post(this.uri+"save_new_brand?api_key="+this.api_key+"&token="+this.token+"&module=183", data);
  }

  updateBrand(data:IBrand):Observable<IBrand>{
    return this.httpClient.post(this.uri+"uptade_brand?api_key="+this.api_key+"&token="+this.token+"&module=184", data);
  }

  getAllBrands():Observable<IBrand>{
    return this.httpClient.get(this.uri+"get_all_brands?api_key="+this.api_key+"&token="+this.token+"&module=183");
  }
  get30DaysBrands():Observable<IBrand>{
    return this.httpClient.get(this.uri+"get_thirty_days_brands?api_key="+this.api_key+"&token="+this.token+"&module=183");
  }
  emptyBrand(data){
    return this.httpClient.post(this.uri+"empty_brand?api_key="+this.api_key+"&token="+this.token+"&module=184", data);
  }
  removeBrand(id:string){
    return this.httpClient.delete(this.uri+"delete_brand?api_key="+this.api_key+"&token="+this.token+"&module=184&id="+id);
  }

  instrumentsDisabled(){
    return this.httpClient.get(this.uri+"list_disabled_instruments?api_key="+this.api_key+"&token="+this.token+"&module=191");
  }

  enableOrDisabled(data){
    return this.httpClient.post(this.uri+"enable_or_disabled_instrument?api_key="+this.api_key+"&token="+this.token+"&module=191", data);
  }

}
