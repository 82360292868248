import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { WalletService } from "src/app/services/wallet.service";

@Component({
  selector: "app-invioce-odv",
  templateUrl: "./invioce-odv.component.html",
  styleUrls: ["./invioce-odv.component.css"],
})
export class InvioceOdvComponent implements OnInit {
  response: any = [];
  data: any = [];
  loader: number = 0;

  list: any = [];
  number_page = 0;
  pages = 0;
  detalle_fact: number = 0;

  constructor(
    private carteraService: WalletService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.obtenerListaFacturas();
  }

  /**
   * This function toggles the value of a variable between 0 and 1.
   */
  public verFacturas() :void {
    this.detalle_fact == 0 ? (this.detalle_fact = 1) : (this.detalle_fact = 0);
  }

  /**
   * This function retrieves a list of invoices and handles different response codes accordingly.
   */
  private obtenerListaFacturas() :void {
    this.loader = 1;
    this.carteraService.getInviocesByOdv().subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_0007"
        )
          this.lauchModal(this.response.code, this.response.message);
        else this.data = this.response.data;

        this.pages = this.data.length;
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        this.loader = 2;
      }
    );
  }

  /**
   * This function changes the page results and resets the "detalle_fact" variable if it is set to 1.
   * @param page - The "page" parameter is a variable that represents the page number that the user
   * wants to navigate to. It is passed as an argument to the "changePageResults" function.
   */
  public changePageResults(page) :void {
    this.number_page = page;
    if (this.detalle_fact == 1) this.detalle_fact = 0;
  }

 /**
  * This function launches a modal with a given code and title using the Angular Material modal
  * service.
  * @param {string} code - A string representing some code that will be displayed in the modal.
  * @param {string} title - The title parameter is a string that represents the title of the modal that
  * will be launched.
  */
  private async lauchModal(code: string, title: string) :Promise<void>{
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }
}
