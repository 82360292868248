import { Component, OnInit,Input } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { EventService } from 'src/app/services/event.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../modal/modal.component';
//import { NotificationsService } from 'src/app/services/notifications.service';
import { on } from 'process';
import { time } from 'console';
import { environment } from 'src/app/variables/enviroment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public focus;
  public location: Location;
  value_to_search:string ='';
  @Input() user_name:string;
  user_image:string='assets/img/theme/user_default.png';
  permisos = JSON.parse(localStorage.getItem('permisos'));
  response:any = [];
  data:any = [];
  notifications:any = [];
  today = new Date( Date.now()).toLocaleDateString();

  externo:number;

  constructor(
    location: Location,
    private userService:UserService,
    private dashboardService:DashboardService,
    private route:Router,
    private modalService:NgbModal,
    //private notificationsApi:NotificationsService
    ) {
    this.location = location;
  }

  ngOnInit() {
    this.user_name = localStorage.getItem('user_name');
    if(localStorage.getItem('img_user') !== '0') 
      this.user_image = 'http://etalonet.com/portal/images/perfil/'+localStorage.getItem('img_user');

      this.getData();
      
    //1800000
    //this.tiempo=1800000;
    
    /*
    setInterval(() => {
     
      this.doSomething();
    }, this.tiempo);
    
    */
  }
  
  /*doSomething(){
    this.notificationsApi.saveToken().subscribe(
      (res:any) => {
        let titleNot=res.data.title;
        let description = res.data.detalle;
        console.log(res.data);
        this.notificationsApi.showAlert(titleNot,description);
      },err=>{
        console.log('ERR',err);
      }
    )
  }*/
  

  getData(){
    this.dashboardService.getNavbarData().subscribe(
      res=>{
        this.response = res;
        if(this.response.code != '0_005')this.lauchModal(this.response.code,this.response.message);
        else {
          this.data = this.response.data;
          this.externo = this.data.externo;
        }
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        console.log(err);
      }
    );
  }

  reloadComponent(){
    this.ngOnInit();
  }
  
  searchValue(){
    this.route.navigateByUrl('search_results',{skipLocationChange:true});
  }

  closeSession(){
    this.userService.closeSession();
  }

  lauchModal(code:string,title:string){
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

  getNotifications(){
    this.dashboardService.getNotifications(1).subscribe(
      res=>{
        this.response = res;

        if(this.response.code != '0_005')this.lauchModal(this.response.code,this.response.message);
        else {
          this.notifications = this.response.data;

          this.notifications.forEach(a => {
            var title = a.title;

            if(a.image == 1)a.img_url = environment.uri_server+"storage/public/fotos_metrologos/"+a.created_by+".jpg";
            else a.img_url = 'assets/img/theme/user_default.png';
          });
        }
        
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        console.log(err);
      }
    );
  }
}
