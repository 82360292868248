import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/app/variables/enviroment';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {
getDepot(id: string) {
    throw new Error('Method not implemented.');
    }
    uri:string = environment.uri;
  //uri:string = "http://ngdat.ucal.com.mx/api/";

    token = localStorage.getItem('token');
    api_key = localStorage.getItem('key');
    constructor(private httpClient:HttpClient) { }
    
    getTicketList(){
      return this.httpClient.get(this.uri+"get_ticket_list?api_key="+this.api_key+"&token="+this.token+"&module=121");
    }

    addNewTicket(){
      return this.httpClient.get(this.uri+"add_new_ticket?api_key="+this.api_key+"&token="+this.token+"&module=121");
    }

    getTicketDetail(id){
      return this.httpClient.get(this.uri+"get_ticket_detail?api_key="+this.api_key+"&token="+this.token+"&module=121&id="+id);
    }

    searchUser(data){
      return this.httpClient.post(this.uri+"search_user?api_key="+this.api_key+"&token="+this.token+"&module=121",data);
    }

    saveTicketEdit(data:FormData){
      return this.httpClient.post(this.uri+"save_ticket_edit?api_key="+this.api_key+"&token="+this.token+"&module=121",data);
    }
    getTicketReport(month){
      return this.httpClient.get(this.uri+"get_report_tickets?api_key="+this.api_key+"&token="+this.token+"&module=121&month="+month);
    }
    getTicketReportByUser(month){
      return this.httpClient.get(this.uri+"get_report_tickets_byUser?api_key="+this.api_key+"&token="+this.token+"&module=121&month="+month);
    }

  }