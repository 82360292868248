import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/app/variables/enviroment';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {
getDepot(id: string) {
    throw new Error('Method not implemented.');
    }
    uri:string = environment.uri;
    token = localStorage.getItem('token');
    api_key = localStorage.getItem('key');
    
    constructor(private httpClient:HttpClient) { }

    getAllVehicles(){
      return this.httpClient.get(this.uri+"get_vehicle_list?api_key="+this.api_key+"&token="+this.token+"&module=100");
    }

    getVehicleDetail(id){
      return this.httpClient.get(this.uri+"get_vehicle_detail?api_key="+this.api_key+"&token="+this.token+"&module=100&vehicle="+id);
    }

    getVehicleForEdit(id){
      return this.httpClient.get(this.uri+"get_vehicle_data_for_edit?api_key="+this.api_key+"&token="+this.token+"&module=100&vehicle="+id);
    }

    saveVehicleEdit(data:FormData){
      return this.httpClient.post(this.uri+"save_vehicle_edit?api_key="+this.api_key+"&token="+this.token+"&module=100",data);
    }

    saveNewExit(data){
      return this.httpClient.post(this.uri+"save_vehicle_exit?api_key="+this.api_key+"&token="+this.token+"&module=100",data);
    }

    saveNewService(data:FormData){
      return this.httpClient.post(this.uri+"save_vehicle_service?api_key="+this.api_key+"&token="+this.token+"&module=100",data);
    }

    getUsersList(){
      return this.httpClient.get(this.uri+"get_users_vehicle_list?api_key="+this.api_key+"&token="+this.token+"&module=100");
    }

    getVehicleExit(id_exit){
      return this.httpClient.get(this.uri+"get_vehicle_exit_detail?api_key="+this.api_key+"&token="+this.token+"&module=100&exit_="+id_exit);
    }

    saveKMManager(data){
      return this.httpClient.post(this.uri+"generate_km_manager?api_key="+this.api_key+"&token="+this.token+"&module=100",data);
      
    }
}
