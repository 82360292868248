import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { DashboardService } from 'src/app/services/dashboard.service';
import { InstrumentService } from 'src/app/services/instrument.service';
import { environment } from 'src/app/variables/enviroment';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent implements OnInit {
  uri:string = environment.uri;
  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');

  results:any = [];
  response:any = [];
  number_page= 0;
  filterlist:any = [];
  listaauxiliar:any = [];
  i:number = 0;
  pages = 0;
  loader:number = 1;
  permmisions = JSON.parse(localStorage.getItem('permisos'));
  deptoToSearh: string = "";
  deptos:any = [];
  url_to_load = '';
  
  constructor(private router:Router,
    private insturment:InstrumentService,
    private modalService:NgbModal,
    private dashboardService:DashboardService) { }

  ngOnInit(): void {
    this.getDeptos();
    this.insturment.getAllInstruments().subscribe(
      res=>{
        this.response = res;
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')
        this.lauchModal(this.response.code,this.response.message);
        else{
          this.results = this.response.data;
          this.listaauxiliar = this.results;
          this.pages = this.results.length;
        }
        this.loader = 2;
      },
      err=>{
        this.lauchModal('0000x00','Error de consulta');
        console.log(err);
        this.loader = 2;
      }
    );  
  }

  changePageResults(page){this.number_page = page;}

  searchByDepto(){
    this.filterlist = [];
    this.results = this.listaauxiliar;
    
    if(this.deptoToSearh === ""){
      this.results = this.listaauxiliar;
    }else{
      this.results.forEach(element => {

        for(this.i =0; this.i < element.length; this.i++){

          if(element[this.i].id_depto == this.deptoToSearh){
            this.filterlist.push(element[this.i]);
          }
        } 
      });
      
      this.results = [this.filterlist];
      this.pages = this.results.length;
    

    }
  }
  
  getDeptos(){
    this.dashboardService.getDashboardData().subscribe(
      res=>{
        this.response = res;

        if(this.response.code != '0_005')this.lauchModal(this.response.code,this.response.message);
        else this.deptos = this.response.data.deptos;
        
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        console.log(err);
      }
    );
  }

  lauchModal(code:string,title:string){
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

  descargarFstin(id){
    this.url_to_load = this.uri+"download_fstin?api_key="+this.api_key+"&token="+this.token+"&module=180&id_equipo="+id;
  }
  

}
