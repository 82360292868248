import { Component, OnInit, ViewChild } from '@angular/core';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { CalendarService } from 'src/app/services/calendar.service';

@Component({
  selector: 'app-calendar-maintenance',
  templateUrl: './calendar-maintenance.component.html',
  styleUrls: ['./calendar-maintenance.component.css']
})
export class CalendarMaintenanceComponent implements OnInit {

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    weekends: false
  };

  loader=2;
  response:any = [];
  data:any=[];

  mesActual:any;
  anioActual:any;
  filtrarMes:any;
  hoy:any;

  dias:number;
  arrayDias:any=[];
  finalEvents:any=[];
  eventosT:any=[];
  fecha: string='';

  diaActual:string='';
  
  private currentDate = new Date();

  permmisions = JSON.parse(localStorage.getItem('permisos'));

  constructor(private calendarService:CalendarService,private modalService:NgbModal) { }

  ngOnInit(): void {
    this.arrayDias=[];
    this.finalEvents=[];
    this.anioActual = this.currentDate.getFullYear();
    this.filtrarMes = this.getMonthActual();
    
    this.getMesData();
  }

  mostrareventosCalendar(val){
    this.calendarOptions = ({
      initialView: 'dayGridMonth',
      dateClick: this.handleDateClick.bind(this), // bind is important!
      navLinks:false,
      editable:true,
      events: val,
    });
  }

  getMesData(){
    this.fecha = this.getCurrentDateMonth();
    this.diaActual = this.getCurrentDateDay();
    let vig = []; let prox =[]; let venc=[];

    this.calendarService.getEventosMesMantenimiento(this.fecha,this.diaActual).subscribe(
      res=>{
        this.response = res;
        
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
        else this.eventosT = this.response.data;
        console.log(this.eventosT);
        
        let val=[];
        for (let i = 0; i < this.eventosT.length; i++) {
          if(this.eventosT[i].prox==0){
            vig.push(this.eventosT[i].fecha_vencimiento);

          }else if(this.eventosT[i].prox==1){
            prox.push(this.eventosT[i].fecha_vencimiento);

          }else if(this.eventosT[i].prox==2){
            venc.push(this.eventosT[i].fecha_vencimiento);
          }
            
        }

        var final_fechas = [];

        ///------------ Vigentes
        var arr_aux_vig = [];
        for(var a = 0; a< vig.length; a++){
          if(!arr_aux_vig.includes(vig[a])) arr_aux_vig.push(vig[a]);
        }

        for(var b = 0; b < arr_aux_vig.length ; b++){
          let cont = 0;
          for(var c = 0; c < vig.length ; c++){
            if(arr_aux_vig[b] == vig[c]) cont++;
          }
          
          let data:any = [];
          data['title']=cont;
          data['start']=arr_aux_vig[b];
          data['color']='#4caf50';

          final_fechas.push(data);
        }

        ///--------- Proximos
        var arr_aux_prox = [];
        for(var d = 0; d< prox.length; d++){
          if(!arr_aux_prox.includes(prox[d])) arr_aux_prox.push(prox[d]);
        }

        for(var e = 0; e < arr_aux_prox.length ; e++){
          let cont = 0;
          for(var f = 0; f < prox.length ; f++){
            if(arr_aux_prox[e] == prox[f]) cont++;
          }
          let data:any = [];
          data['title']=cont;
          data['start']=arr_aux_prox[e];
          data['color']='#ff9800';

          final_fechas.push(data);
        }

        ///--------- Vencidos
        var arr_aux_ven = [];
        for(var i = 0; i < venc.length ; i++){
          if(!arr_aux_ven.includes(venc[i])) arr_aux_ven.push(venc[i]);
        }
        
        for(var i = 0; i < arr_aux_ven.length ; i++){
          var cont = 0;
          for(var j = 0; j < venc.length ; j++){
            if(arr_aux_ven[i] == venc[j]) cont++;
          }

          var data:any = [];
          data['title']=cont;
          data['start']=arr_aux_ven[i];
          data['color']='#e53935';

          final_fechas.push(data);
        }

        this.mostrareventosCalendar(final_fechas);
        this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        console.log(err);
        this.loader = 2;
      }
    );

  }

  nextMonth() {
    
    let calendarApi = this.calendarComponent.getApi();

    let mesCalendar;

    let mesNext = calendarApi.getDate().getMonth()+1;

    let anioCalendar = calendarApi.getDate().getFullYear();

    
    if(calendarApi.getDate().getMonth()+1<10){
      mesCalendar = "0"+mesNext.toString();
    }else mesCalendar = mesNext.toString();

    let anio_mes = anioCalendar+"-"+mesCalendar;

    this.diaActual = this.getCurrentDateDay();
    let vig = []; let prox =[]; let venc=[];

    this.calendarService.getEventosMesMantenimiento(anio_mes,this.diaActual).subscribe(
      res=>{
        this.response = res;
        
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
        else this.eventosT = this.response.data;
        
        let val=[];
        for (let i = 0; i < this.eventosT.length; i++) {

          if(this.eventosT[i].prox==0){
            vig.push(this.eventosT[i].fecha_vencimiento);

          }else if(this.eventosT[i].prox==1){
            prox.push(this.eventosT[i].fecha_vencimiento);

          }else if(this.eventosT[i].prox==2){
            venc.push(this.eventosT[i].fecha_vencimiento);
          }
            
        }

        var final_fechas = [];

        ///------------ Vigentes
        var arr_aux_vig = [];
        for(var a = 0; a< vig.length; a++){
          if(!arr_aux_vig.includes(vig[a])) arr_aux_vig.push(vig[a]);
        }

        for(var b = 0; b < arr_aux_vig.length ; b++){
          let cont = 0;
          for(var c = 0; c < vig.length ; c++){
            if(arr_aux_vig[b] == vig[c]) cont++;
          }
          let data:any = [];
          data['title']=cont;
          data['start']=arr_aux_vig[b];
          data['color']='#4caf50';

          final_fechas.push(data);
        }

        ///--------- Proximos
        var arr_aux_prox = [];
        for(var d = 0; d< prox.length; d++){
          if(!arr_aux_prox.includes(prox[d])) arr_aux_prox.push(prox[d]);
        }

        for(var e = 0; e < arr_aux_prox.length ; e++){
          let cont = 0;
          for(var f = 0; f < prox.length ; f++){
            if(arr_aux_prox[e] == prox[f]) cont++;
          }
          let data:any = [];
          data['title']=cont;
          data['start']=arr_aux_prox[e];
          data['color']='#ff9800';

          final_fechas.push(data);
        }

        ///--------- Vencidos
        var arr_aux_ven = [];
        for(var i = 0; i < venc.length ; i++){
          if(!arr_aux_ven.includes(venc[i])) arr_aux_ven.push(venc[i]);
        }
        
        for(var i = 0; i < arr_aux_ven.length ; i++){
          var cont = 0;
          for(var j = 0; j < venc.length ; j++){
            if(arr_aux_ven[i] == venc[j]) cont++;
          }
          var data:any = [];
          data['title']=cont;
          data['start']=arr_aux_ven[i];
          data['color']='#e53935';

          final_fechas.push(data);
        }

        this.mostrareventosCalendar(final_fechas);
        this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        console.log(err);
        this.loader = 2;
      }
    );



  }

  handleDateClick(arg) {
    this.loader = 1;

    this.diaActual = this.getCurrentDateDay();

    this.calendarService.getEventosDiaMantenimiento(arg.dateStr,this.diaActual).subscribe(
      res=>{
        this.response = res;
        
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
        else this.data = this.response.data;
        console.log(this.data)

        this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        console.log(err);
        this.loader = 2;
      }
    );

  }

  diasEnMesSelected(month:any) {
    let year:any = this.currentDate.getFullYear();
    return new Date(year, month, 0).getDate();
  }

  diasEnUnMes() {
    let month:any = this.currentDate.getMonth() + 1;
    let year:any = this.currentDate.getFullYear();

    if(month<10)  month = "0" + month.toString();
      
    return new Date(year, month, 0).getDate();
  }

  getMonthActual(): string{
    let month:any = this.currentDate.getMonth() + 1;
    let dateInApiFormat: string;
    
    if(month<10) month = "0" + month.toString();
    
    dateInApiFormat = month;
    return dateInApiFormat;
  }

  getCurrentDateMonth(): string{
    let day:any = this.currentDate.getDate();
    let month:any = this.currentDate.getMonth() + 1;
    let year:any = this.currentDate.getFullYear();
    let dateInApiFormat: string;
    
    if(day<10) day = "0" + day.toString();
    
    if(month<10) month = "0" + month.toString();
    
    dateInApiFormat = year.toString() + "-" + month;
    return dateInApiFormat;
  }

  getCurrentDateDay(): string{
    let day:any = this.currentDate.getDate();
    let month:any = this.currentDate.getMonth() + 1;
    let year:any = this.currentDate.getFullYear();
    let dateInApiFormat: string;
      
    if(day<10) day = "0" + day.toString();
      
    if(month<10) month = "0" + month.toString();
      
    dateInApiFormat = year.toString() + "-" + month + "-" + day;
    return dateInApiFormat;
  }

  lauchModal(code:string,title:string){
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }
  
}
