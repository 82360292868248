import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAddress } from './models/IAddress';
import { ICustomer } from './models/ICustomer';
import { environment } from 'src/app/variables/enviroment';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  uri:string = environment.uri;
  //uri:string = "http://ngdat.ucal.com.mx/api/";

  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');
  constructor(private httpClient:HttpClient) { }

  getCustomers(){
    return this.httpClient.get(this.uri+"get_all_clients?api_key="+this.api_key+"&token="+this.token+"&module=21");
  }
  
  getCustomer(id_customer){
    return this.httpClient.get(this.uri+"get_detail_client?api_key="+this.api_key+"&token="+this.token+"&module=22"+"&cliente_id="+id_customer);
  }

  getAddress(id_address){
    return this.httpClient.get(this.uri+"get_direccion_by_id?api_key="+this.api_key+"&token="+this.token+"&module=34"+"&id="+id_address);
  }

  getDepartments(id){
    return this.httpClient.get(this.uri+"get_departments_ubi?api_key="+this.api_key+"&token="+this.token+"&module=22"+"&id="+id);
  }

  postCustomer(iCustomer: ICustomer){
    return this.httpClient.post(this.uri+"add_client?api_key="+this.api_key+"&token="+this.token+"&module=33", iCustomer);
  }

  postDireccion(iDireccion: IAddress){
    return this.httpClient.post(this.uri+"add_address_client?api_key="+this.api_key+"&token="+this.token+"&module=34", iDireccion);
  }

  postEditCustomer(iCustomer: ICustomer){
    return this.httpClient.post(this.uri+"edit_client?api_key="+this.api_key+"&token="+this.token+"&module=34", iCustomer);
  }
}
