import { Component, OnInit, ViewChild } from "@angular/core";
import { CalendarOptions, FullCalendarComponent } from "@fullcalendar/angular";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { WalletService } from "src/app/services/wallet.service";

@Component({
  selector: "app-cartera",
  templateUrl: "./cartera.component.html",
  styleUrls: ["./cartera.component.css"],
})
export class CarteraComponent implements OnInit {
  @ViewChild("calendar") calendarComponent: FullCalendarComponent;
  calendarOptions: CalendarOptions = {
    initialView: "dayGridMonth",
    weekends: false,
  };

  response: any = [];
  data: any = [];
  loader: number = 0;
  todos: number = 1;

  fecha_pago: any;
  comentario: string;

  nueva_fecha: any;
  id_factura: string;

  fecha: string = "";
  anioAux: any = "2022";
  diaActual: string = "";

  list: any = [];
  number_page = 0;
  pages = 0;

  lista_facturas_dia: any = [];

  file: any;
  file_name = "";

  total_vencido = 0.0;
  total_quince = 0.0;
  total_treinta = 0.0;
  total_mas = 0.0;

  private currentDate = new Date();
  final_fechas = [];

  permmisions = JSON.parse(localStorage.getItem("permisos"));

  constructor(
    private carteraService: WalletService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.obtenerListaFacturas();
    this.verCalendario();
  }

  /**
   * This function toggles the display of weekends on a calendar by changing a boolean value.
   */
  public toggleWeekends() :void {
    this.calendarOptions.weekends = !this.calendarOptions.weekends; // toggle the boolean!
  }

  /**
   * This function sets the value of "todos" to 1 and calls the "getMesData" function.
   */
  public calendarioView() :void {
    this.todos = 1;
    this.getMesData();
  }

  /**
   * The function initializes a variable and calls another function to obtain a list of invoices.
   */
  public generalView() :void {
    this.todos = 0;
    this.obtenerListaFacturas();
  }

  /**
   * The function updates the view by setting the value of "todos" to 2 and calling another function to
   * obtain a list of invoices.
   */
  public updateView() :void {
    this.todos = 2;
    this.obtenerListaFacturasAll();
  }

  /**
   * This function toggles between displaying all invoices and displaying invoices for a specific
   * month.
   */
  private verCalendario() :void {
    if (this.todos == 1) {
      this.todos = 0;
      this.obtenerListaFacturas();
    } else {
      this.todos = 1;
      this.getMesData();
    }
  }

  /**
   * The function obtains a list of invoices and handles different response codes accordingly.
   */
  private obtenerListaFacturas() :void {
    this.loader = 1;
    this.carteraService.getInviocesAll().subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_0007"
        )
          this.lauchModal(this.response.code, this.response.message);
        else this.data = this.response.data;
        this.pages = this.data.length;
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        this.loader = 2;
      }
    );
  }

  /**
   * This function retrieves a list of invoices and categorizes them based on their due dates.
   */
  private obtenerListaFacturasAll() :void {
    this.loader = 1;
    this.carteraService.getInviocesUpdate("todas").subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_0007"
        )
          this.lauchModal(this.response.code, this.response.message);
        else this.data = this.response.data;

        var f1 = this.getCurrentDateDay();

        this.data.forEach((e) => {
          let dias_dif = this.restaFechas(e.fecha_cobro, f1);
          if (dias_dif <= 0) {
            e.vencido = "$" + e.price;
            let price_aux = e.price.replace(",", "");
            this.total_vencido = this.total_vencido + parseFloat(price_aux);
          } else if (dias_dif > 1 && dias_dif < 16) {
            e.quince_dias = "$" + e.price;
            let price_aux = e.price.replace(",", "");
            this.total_quince = this.total_quince + parseFloat(price_aux);
          } else if (dias_dif > 16 && dias_dif < 31) {
            e.treinta_dias = "$" + e.price;
            let price_aux = e.price.replace(",", "");
            this.total_treinta = this.total_treinta + parseFloat(price_aux);
          } else if (dias_dif > 31) {
            e.mas_dias = "$" + e.price;
            let price_aux = e.price.replace(",", "");
            this.total_mas = this.total_mas + parseFloat(price_aux);
          }

          this.total_vencido = parseFloat(this.total_vencido.toFixed(2));
          this.total_quince = parseFloat(this.total_quince.toFixed(2));
          this.total_treinta = parseFloat(this.total_treinta.toFixed(2));
          this.total_mas = parseFloat(this.total_mas.toFixed(2));
        });

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        this.loader = 2;
      }
    );
  }

  /**
   * The function "reprogramar" sets the value of the variable "id_factura" to the input parameter
   * "id".
   * @param {string} id - The parameter "id" is a string that represents the ID of a factura (invoice).
   * The function "reprogramar" takes this ID as an argument and assigns it to the "id_factura"
   * property of the object.
   */
  public reprogramar(id: string) :void {
    this.id_factura = id;
  }

  /**
   * The function sets a new date value to a private variable.
   * @param {any} date - The "date" parameter is of type "any", which means it can accept any data
   * type. It is used as an argument for the "loadDate" method, which sets the value of the
   * "nueva_fecha" property to the value of the "date" parameter.
   */
  public loadDate(date: any) :void {
    this.nueva_fecha = date;
  }

  /**
   * This is a private function in TypeScript that reprograms a payment and updates the list of
   * invoices.
   */
  public reprogramarCobro() :void {
    this.loader = 1;
    this.carteraService
      .reprogramarCobro(this.id_factura, this.nueva_fecha)
      .subscribe(
        (res) => {
          this.response = res;
          if (
            this.response.code == "1_0001" ||
            this.response.code == "1_0002" ||
            this.response.code == "1_0003"
          )
            this.lauchModal(this.response.code, this.response.message);
          else if (
            this.response.code == "1_0004" ||
            this.response.code == "0_0007"
          )
            this.lauchModal(this.response.code, this.response.message);
          else this.response.data;

          this.obtenerListaFacturas();

          this.loader = 2;
        },
        (err) => {
          this.lauchModal("0000x00", "Error de consulta");
          this.loader = 2;
        }
      );
  }

  /**
   * This is a private function in TypeScript that sets the payment date.
   * @param {any} date - The parameter "date" is of type "any", which means it can accept any data type
   * as input. However, based on the function name "fecha_cobro" (which translates to "payment date" in
   * Spanish), it is likely that the expected input for this parameter is a date object
   */
  public fecha_cobro(date: any) :void {
    this.fecha_pago = date;
  }

  /**
   * This function pays an invoice and updates the list of invoices.
   */
  public pagar_factura() :void {
    this.loader = 1;
    this.carteraService
      .getToPayInvoice(this.id_factura, this.fecha_pago, this.comentario)
      .subscribe(
        (res) => {
          this.response = res;
          if (
            this.response.code == "1_0001" ||
            this.response.code == "1_0002" ||
            this.response.code == "1_0003"
          )
            this.lauchModal(this.response.code, this.response.message);
          else if (
            this.response.code == "1_0004" ||
            this.response.code == "0_0007"
          )
            this.lauchModal(this.response.code, this.response.message);
          else this.response.data;

          this.obtenerListaFacturas();

          this.loader = 2;
        },
        (err) => {
          this.lauchModal("0000x00", "Error de consulta");
          this.loader = 2;
        }
      );
  }

  /**
   * The function changes the current page results to the specified page number.
   * @param page - The "page" parameter is a variable that represents the page number that the user
   * wants to navigate to. The "changePageResults" function takes this parameter and updates the
   * "number_page" variable with the new page number.
   */
  public changePageResults(page) :void {
    this.number_page = page;
  }

  /**
   * This function launches a modal with a given code and title using the Angular Material modal
   * service.
   * @param {string} code - A string representing the code that will be displayed in the modal window.
   * @param {string} title - The title parameter is a string that represents the title of the modal
   * that will be displayed. It will be passed as a property to the ModalComponent instance.
   */
  private async lauchModal(code: string, title: string) :Promise<void>{
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

  ////------------Calendarios cuadriculado---------------

  /**
   * This function sets up the options for a calendar view and binds a date click event to a handler
   * function.
   */
  private mostrareventosCalendar() :void{
    this.calendarOptions = {
      initialView: "dayGridMonth",
      dateClick: this.handleDateClick.bind(this), // bind is important!
      navLinks: false,
      editable: true,
      events: this.final_fechas,
    };
    this.loader = 2;
  }

  /**
   * The function handles a click event on a date and retrieves data from a server based on the date
   * clicked.
   * @param arg - The argument passed to the function, which is expected to have a property called
   * "dateStr".
   */
  private handleDateClick(arg) :void{
    this.loader = 1;
    this.carteraService.getCalendarDay(arg.dateStr).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_0007"
        )
          this.lauchModal(this.response.code, this.response.message);
        else this.data = this.response.data;
        this.lista_facturas_dia = this.data;

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        this.loader = 2;
      }
    );
  }

  /**
   * The function retrieves data from a service and constructs a calendar structure based on the data.
   */
  private getMesData() :void {
    this.fecha = this.getCurrentDateMonth();
    let abiertos = [],
      agendados = [],
      pagados = [];

    this.carteraService.getCalendarAll(this.fecha).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_0007"
        )
          this.lauchModal(this.response.code, this.response.message);
        else this.data = this.response.data;
        this.final_fechas = [];
        let fechas_aux_count2 = [];

        this.data.map(function (x) {
          let aux = [];
          aux[0] = x.factura;
          aux[1] = x.price;
          aux[2] = x.fecha_cobro;

          if (x.status == 0) abiertos.push(aux);
          if (x.status == 1) agendados.push(aux);
          if (x.status == 2) {
            aux[2] = x.fecha_pago.split(" ")[0];
            pagados.push(aux);
          }
        });

        ///------------ Abiertos
        var arr_aux_abr = [];
        for (var a = 0; a < abiertos.length; a++) {
          if (!arr_aux_abr.includes(abiertos[a])) arr_aux_abr.push(abiertos[a]);
        }
        for (var b = 0; b < arr_aux_abr.length; b++) {
          let fecha_grupo = "";
          for (var c = 0; c < abiertos.length; c++) {
            if (arr_aux_abr[b][2] == abiertos[c][2]) {
              fecha_grupo = arr_aux_abr[b][2];
              if (!fechas_aux_count2.includes(fecha_grupo))
                fechas_aux_count2.push(fecha_grupo);
            }
          }
        }

        this.construirEstructuraCalendario(
          fechas_aux_count2,
          "#757575",
          "Abierto"
        );

        ///---------- pagado

        var fechas_aux_count3 = [],
          arr_aux_pag = [];
        for (var a = 0; a < pagados.length; a++) {
          if (!arr_aux_pag.includes(pagados[a])) arr_aux_pag.push(pagados[a]);
        }

        for (let i = 0; i < arr_aux_pag.length; i++) {
          let cont = 0;
          let fecha_grupo = "";
          for (let f = 0; f < pagados.length; f++) {
            if (arr_aux_pag[i][2] == pagados[f][2]) {
              cont++;
              fecha_grupo = arr_aux_pag[i][2];
              if (!fechas_aux_count3.includes(fecha_grupo))
                fechas_aux_count3.push(fecha_grupo);
            }
          }
        }
        this.construirEstructuraCalendario(
          fechas_aux_count3,
          "#4caf50",
          "Pagado"
        );

        ///--------- agendados
        var arr_aux_agn = [];
        let fechas_aux_count = [];
        for (var d = 0; d < agendados.length; d++) {
          if (!arr_aux_agn.includes(agendados[d]))
            arr_aux_agn.push(agendados[d]);
        }

        for (var e = 0; e < arr_aux_agn.length; e++) {
          let fecha_grupo = "";
          for (var f = 0; f < agendados.length; f++) {
            if (arr_aux_agn[e][2] == agendados[f][2]) {
              fecha_grupo = arr_aux_agn[e][2];
              if (!fechas_aux_count.includes(fecha_grupo))
                fechas_aux_count.push(fecha_grupo);
            }
          }
        }
        this.construirEstructuraCalendario(
          fechas_aux_count,
          "#3f51b5",
          "Agendado"
        );

        this.mostrareventosCalendar();

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        this.loader = 2;
      }
    );
  }

  /**
   * This is a private function in TypeScript that builds a calendar structure by iterating through an
   * array of dates and adding them to a final array with a specified color and title.
   * @param array_parts - An array of date/time values representing the parts of the calendar structure
   * to be built.
   * @param color - The color parameter is a string that represents the color of the event in the
   * calendar. It can be any valid CSS color value, such as "red", "#FF0000", or "rgb(255, 0, 0)".
   * @param title - A string representing the title of the calendar event.
   */
  private construirEstructuraCalendario(array_parts, color, title) :void{
    array_parts.forEach((elements) => {
      let arrayFechas = [];
      arrayFechas["title"] = title;
      arrayFechas["start"] = elements;
      arrayFechas["color"] = color;
      this.final_fechas.push(arrayFechas);
    });
  }

  /**
   * This function retrieves data from a service and constructs a calendar with events based on the
   * data.
   */
  public someMethod() :void {
    let calendarApi = this.calendarComponent.getApi();

    let mesCalendar;

    let mesNext = calendarApi.getDate().getMonth() + 1;

    let anioCalendar = calendarApi.getDate().getFullYear();

    if (calendarApi.getDate().getMonth() + 1 < 10) {
      mesCalendar = "0" + mesNext.toString();
    } else mesCalendar = mesNext.toString();

    let anio_mes = anioCalendar + "-" + mesCalendar;

    let abiertos = [],
      agendados = [],
      pagados = [];

    this.carteraService.getCalendarAll(anio_mes).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_0007"
        )
          this.lauchModal(this.response.code, this.response.message);
        else this.data = this.response.data;
        this.final_fechas = [];

        this.data.map(function (x) {
          let aux = [];
          aux[0] = x.factura;
          aux[1] = x.price;
          aux[2] = x.fecha_cobro;

          if (x.status == 0) abiertos.push(aux);
          if (x.status == 1) agendados.push(aux);
          if (x.status == 2) {
            aux[2] = x.fecha_pago.split(" ")[0];
            pagados.push(aux);
          }
        });

        let fechas_aux_count2 = [];
        ///------------ Abiertos
        var arr_aux_abr = [];
        for (var a = 0; a < abiertos.length; a++) {
          if (!arr_aux_abr.includes(abiertos[a])) arr_aux_abr.push(abiertos[a]);
        }

        for (var b = 0; b < arr_aux_abr.length; b++) {
          let fecha_grupo = "";
          for (var c = 0; c < abiertos.length; c++) {
            if (arr_aux_abr[b][2] == abiertos[c][2]) {
              fecha_grupo = arr_aux_abr[b][2];
              if (!fechas_aux_count2.includes(fecha_grupo))
                fechas_aux_count2.push(fecha_grupo);
            }
          }
        }

        this.construirEstructuraCalendario(
          fechas_aux_count2,
          "#757575",
          "Abierto"
        );

        ///---------- pagado

        var fechas_aux_count3 = [],
          arr_aux_pag = [];
        for (var a = 0; a < pagados.length; a++) {
          if (!arr_aux_pag.includes(pagados[a])) arr_aux_pag.push(pagados[a]);
        }

        for (let i = 0; i < arr_aux_pag.length; i++) {
          let cont = 0;
          let fecha_grupo = "";
          for (let f = 0; f < pagados.length; f++) {
            if (arr_aux_pag[i][2] == pagados[f][2]) {
              cont++;
              fecha_grupo = arr_aux_pag[i][2];
              if (!fechas_aux_count3.includes(fecha_grupo))
                fechas_aux_count3.push(fecha_grupo);
            }
          }
        }
        this.construirEstructuraCalendario(
          fechas_aux_count3,
          "#32CD32",
          "Pagado"
        );

        ///--------- agendados
        var arr_aux_agn = [];
        let fechas_aux_count = [];
        for (var d = 0; d < agendados.length; d++) {
          if (!arr_aux_agn.includes(agendados[d]))
            arr_aux_agn.push(agendados[d]);
        }

        for (var e = 0; e < arr_aux_agn.length; e++) {
          let fecha_grupo = "";
          for (var f = 0; f < agendados.length; f++) {
            if (arr_aux_agn[e][2] == agendados[f][2]) {
              fecha_grupo = arr_aux_agn[e][2];
              if (!fechas_aux_count.includes(fecha_grupo))
                fechas_aux_count.push(fecha_grupo);
            }
          }
        }

        this.construirEstructuraCalendario(
          fechas_aux_count,
          "#3f51b5",
          "Agendado"
        );

        this.mostrareventosCalendar();

        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        this.loader = 2;
      }
    );
  }

  /**
   * This function returns the current month in a specific format for an API.
   * @returns a string representing the current month in API format.
   */
  public getMonthActual(): string {
    let month: any = this.currentDate.getMonth() + 1;
    let dateInApiFormat: string;

    if (month < 10) month = "0" + month.toString();

    dateInApiFormat = month;
    return dateInApiFormat;
  }

  /**
   * This function returns the current date in a specific API format.
   * @returns a string representing the current date in the format "YYYY-MM-DD".
   */
  private getCurrentDateDay(): string {
    let day: any = this.currentDate.getDate();
    let month: any = this.currentDate.getMonth() + 1;
    let year: any = this.currentDate.getFullYear();
    let dateInApiFormat: string;

    if (day < 10) day = "0" + day.toString();

    if (month < 10) month = "0" + month.toString();

    dateInApiFormat = year.toString() + "-" + month + "-" + day;
    return dateInApiFormat;
  }

  /**
   * This function returns the current date and month in a specific API format.
   * @returns a string representing the current date and month in a specific format (YYYY-MM).
   */
  private getCurrentDateMonth(): string {
    let day: any = this.currentDate.getDate();
    let month: any = this.currentDate.getMonth() + 1;
    let year: any = this.currentDate.getFullYear();
    let dateInApiFormat: string;

    if (day < 10) day = "0" + day.toString();

    if (month < 10) month = "0" + month.toString();

    dateInApiFormat = year.toString() + "-" + month;
    return dateInApiFormat;
  }

  /* The above code defines a private function called `restaFechas` that takes two date strings as
  input and returns the difference between them in days. The function first splits the input date
  strings into year, month, and day components and then converts them into UTC format using the
  `Date.UTC()` method. It then calculates the difference between the two dates in milliseconds and
  converts it into days by dividing it by the number of milliseconds in a day. Finally, it returns
  the number of days as a number. */
  private restaFechas = function (f1, f2) :number{
    let aFecha1 = f1.split("-");
    let aFecha2 = f2.split("-");

    let fFecha1 = Date.UTC(aFecha1[0], aFecha1[1] - 1, aFecha1[2]);
    let fFecha2 = Date.UTC(aFecha2[0], aFecha2[1] - 1, aFecha2[2]);

    let dif = fFecha1 - fFecha2;
    let dias = Math.floor(dif / (1000 * 60 * 60 * 24));
    return dias;
  };
}
