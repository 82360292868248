import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { LogService } from "src/app/services/log.service";

@Component({
  selector: "app-log",
  templateUrl: "./log.component.html",
  styleUrls: ["./log.component.css"],
})
export class LogComponent implements OnInit {
  results: any = [];
  response: any = [];
  loader: number = 1;
  number_page = 0;
  pages = 0;

  constructor(
    private router: Router,
    private logServ: LogService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.firstFunc();
  }

  /**
   * The function retrieves logs and displays them in a modal or as data, with error handling.
   */
  private firstFunc() :void {
    this.logServ.getLogs().subscribe(
      (resp) => {
        this.response = resp;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message);
        else this.results = this.response.data;
        this.pages = this.results.length;
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * The function changes the current page to the specified page number.
   * @param page - The "page" parameter is a variable that represents the page number that the user
   * wants to navigate to. The "changePage" function takes this parameter and sets the "number_page"
   * variable to the value of the "page" parameter.
   */
  public changePage(page) :void {
    this.number_page = page;
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code and title using
   * the ModalComponent.
   * @param {string} code - The code parameter is a string that represents the code that will be
   * displayed in the modal.
   * @param {string} title - The title parameter is a string that represents the title of the modal
   * that will be launched. It will be displayed at the top of the modal to provide context to the
   * user.
   */
  private async lauchModal(code: string, title: string) :Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }
}
