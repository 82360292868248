import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { ISupplier } from "src/app/services/models/ISupplier";
import { ISupplierAddress } from "src/app/services/models/ISupplierAddress";
import { ISupplierContact } from "src/app/services/models/ISupplierContact";
import { SuppliersService } from "src/app/services/suppliers.service";

@Component({
  selector: "app-edit-supplier",
  templateUrl: "./edit-supplier.component.html",
  styleUrls: ["./edit-supplier.component.css"],
})
export class EditSupplierComponent implements OnInit {
  //Variables para controlar las interfaces
  view_to_add = false;
  view_to_edit = false;

  id_supplier = "";
  loader = 1;
  response: any = [];
  contacts: any = [];
  directions: any = [];

  //Datos generales
  supplier_name = "";
  supplier_service = "";
  supplier_accreditation = "";
  supplier_rfc = "";
  supplier_account = "";
  supplier_coin = "";
  supplier_web_page = "";
  suppplier_invoice = "";
  supplier_ptype = "";
  coin_type: any = [
    { desc: "MXN", id: "1" },
    { desc: "USD", id: "2" },
  ];
  pay_tipes: any = [];

  //Datos para agregar contacto
  contact_name = "";
  contact_email = "";
  contact_phone = "";
  contact_ext = "";
  contact_puesto = "";

  //Datos para agregar direcciones
  address_street = "";
  address_ext = "";
  address_int = "";
  address_colony = "";
  address_cp = "";
  address_mun = "";
  address_city = "";
  address_state = "";
  address_country = 1;
  country_list_: any = [
    { desc: "MX", id: "1" },
    { desc: "US", id: "2" },
  ];

  constructor(
    private activedRoute: ActivatedRoute,
    private supplierService: SuppliersService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.id_supplier = this.activedRoute.snapshot.paramMap.get("id");
    this.getData();
  }

  /**
   * The function retrieves data from a supplier service and assigns it to various variables.
   */
  private getData(): void {
    this.loader = 1;
    let data: any = [];
    this.supplierService.getDetailToEdit(this.id_supplier).subscribe(
      (res) => {
        this.response = res;
        if (this.response.code !== "0_005")
          this.lauchModal(this.response.code, this.response.message);
        else {
          data = this.response.data;

          this.supplier_name = data.nombre;
          this.supplier_service = data.servicio_producto;
          this.supplier_accreditation = data.acreditacion;
          this.supplier_rfc = data.rfc;
          this.supplier_account = data.cuenta_bancaria;
          this.supplier_coin = data.moneda;
          this.supplier_web_page = data.pagina_web;
          this.suppplier_invoice = data.folio;
          this.supplier_ptype = data.id_tipo_pago;
          this.pay_tipes = data.t_pay;
          this.contacts = data.contacts;
          this.directions = data.address;
        }
        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * This is a private function in TypeScript that validates data to be sent and saves it using a
   * supplier service, with error handling and conditional routing.
   * @param action - The "action" parameter is a number that determines what action to take after the
   * data is validated and sent. If the value is 0, it means to retrieve data. If the value is not 0,
   * it means to navigate to a specific page using the Angular router.
   */
  public validateDataToSend(action): void {
    this.loader = 1;
    var to_send: ISupplier = {
      id: this.id_supplier,
      name: this.supplier_name,
      service_product: this.supplier_service,
      acredditation: this.supplier_accreditation,
      rfc: this.supplier_rfc,
      account: this.supplier_account,
      pay: Number(this.supplier_ptype),
      coin: Number(this.supplier_coin),
      web_page: this.supplier_web_page,
      invoice: this.suppplier_invoice,
    };

    this.supplierService.saveGeneralEdit(to_send).subscribe(
      (res) => {
        this.response = res;
        if (this.response.code != "0_005")
          this.lauchModal(this.response.code, this.response.message);
        else {
          if (action == 0) this.getData();
          else
            this.router.navigate([
              "/administration/suppliers/det",
              this.id_supplier,
            ]);
        }
        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * This function saves a supplier contact by sending data to a server and handling the response.
   */
  public saveContact(): void {
    this.loader = 1;
    var data: ISupplierContact = {
      id_supplier: this.id_supplier,
      name: this.contact_name,
      email: this.contact_email,
      stall: this.contact_puesto,
      phone: this.contact_phone,
      ext: this.contact_ext,
    };
    this.supplierService.addContact(data).subscribe(
      (res) => {
        this.response = res;
        if (this.response.code !== "0_005")
          this.lauchModal(this.response.code, this.response.message);
        else this.getData();

        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * This function saves a supplier's address by sending a request to the server and handling the
   * response.
   */
  public saveAdress(): void {
    this.loader = 1;
    var data: ISupplierAddress = {
      id_supplier: this.id_supplier,
      calle: this.address_street,
      ext: this.address_ext,
      int: this.address_int,
      col: this.address_colony,
      cp: this.address_cp,
      mun: this.address_mun,
      ciu: this.address_city,
      est: this.address_state,
      pais: Number(this.address_country),
    };

    this.supplierService.addAddress(data).subscribe(
      (res) => {
        this.response = res;
        if (this.response.code !== "0_005")
          this.lauchModal(this.response.code, this.response.message);
        else this.getData();

        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 0;
      }
    );
  }

  /**
   * This is a private function in TypeScript that sets the value of a coin for a supplier.
   * @param value - The value parameter is the new value that will be set for the supplier_coin
   * property.
   */
  public setCoin(value): void {
    this.supplier_coin = value;
  }

  /**
   * This is a private function that sets the value of a property called "supplier_ptype".
   * @param value - The value parameter is the new value that will be assigned to the supplier_ptype
   * property.
   */
  public setPay(value): void {
    this.supplier_ptype = value;
  }

  /**
   * This is a private function in TypeScript that sets the value of the address_country property.
   * @param value - The value parameter is the new value that will be set for the address_country
   * property.
   */
  public setCountry(value): void {
    this.address_country = value;
  }

  /**
   * This is an asynchronous function that launches a modal with a given code and title using a modal
   * service.
   * @param {string} code - The code parameter is a string that represents the code that will be
   * displayed in the modal.
   * @param {string} title - The title parameter is a string that represents the title of the modal
   * that will be launched. It will be displayed at the top of the modal to provide context to the
   * user.
   */
  private async lauchModal(code: string, title: string): Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

  /**
   * This function toggles the view_to_add boolean property.
   */
  public changeToAdd(): void {
    this.view_to_add = !this.view_to_add;
  }

  /**
   * This function toggles a boolean value between true and false.
   */
  public changeToEdit(): void {
    this.view_to_edit = !this.view_to_edit;
  }

  /**
   * This function edits a specific address property based on the type of edit and sends the updated
   * data to be edited.
   * @param id - The id of the supplier address being edited.
   * @param type - The type parameter is an integer that represents the type of data being edited. The
   * possible values are:
   * @param event - The event parameter is an object that represents the event that triggered the
   * function call. It could be a mouse click, a key press, or any other type of event. The function
   * uses the event.target.value property to get the new value that the user entered in an input field.
   */
  public editValue(id, type, event): void {
    let edit = event.target.value;

    for (var i = 0; i < this.directions.length; i++) {
      if (id == this.directions[i].id) {
        var data: ISupplierAddress = {
          id: id,
          calle: this.directions[i].calle,
          ext: this.directions[i].numero_ext,
          int: this.directions[i].numero_int,
          col: this.directions[i].colonia,
          cp: this.directions[i].codigo_postal,
          mun: this.directions[i].municipio,
          ciu: this.directions[i].ciudad,
          est: this.directions[i].estado,
        };

        if (type == 1) data.calle = edit;
        else if (type == 2) data.ext = edit;
        else if (type == 3) data.int = edit;
        else if (type == 4) data.col = edit;
        else if (type == 5) data.ciu = edit;
        else if (type == 6) data.est = edit;
        else if (type == 7) data.cp = edit;

        this.sendToEdit(data);
      }
    }
  }

  /**
   * The function sends an object to be edited through a service and handles the response accordingly.
   * @param obj - The parameter "obj" is an object that contains the data to be edited for a supplier's
   * address. It is passed as an argument to the "editAddress" method of the "supplierService" service.
   */
  private sendToEdit(obj): void {
    this.loader = 1;

    this.supplierService.editAddress(obj).subscribe(
      (res) => {
        this.response = res;
        if (this.response.code !== "0_005")
          this.lauchModal(this.response.code, this.response.message);
        else this.getData();
        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 0;
      }
    );
  }
}
