import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-informes-rechazados',
  templateUrl: './informes-rechazados.component.html',
  styleUrls: ['./informes-rechazados.component.css']
})
export class InformesRechazadosComponent implements OnInit {

  loader:number = 1;
  response:any = [];
  data:any = [];
  permmisions = JSON.parse(localStorage.getItem('permisos'));

  constructor(private eventService:EventService,private modalService:NgbModal) { }

  ngOnInit(): void {
    this.loadEventList();
  }

  loadEventList(){
    this.eventService.getListaInformesRechazados().subscribe(
      res=>{
        this.response = res;
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message,'');
        else if(this.response.code == '1_0004')this.lauchModal(this.response.code,this.response.message,'');
        else {
          this.data = this.response.data;
        }
          
        this.loader = 2;
      },
      err=>{
        this.lauchModal('0000x00','Error de consulta','');
        console.log(err);
        this.loader = 2;
      }
    );
  }

  lauchModal(code:string,title:string,message){
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }

  limpiarAll(){ 
    this.eventService.deleteAllIReport().subscribe(
      res=>{
        this.response = res;
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message,'');
        else if(this.response.code == '1_0004')this.lauchModal(this.response.code,this.response.message,'');
        else {
          
          if(this.response.data == 1){
            this.loadEventList();
          }else{
            this.lauchModal("", "Error", "Ocurrio un error, por favor intenta más tarde.");
          }
        }
          
        this.loader = 2;
      },
      err=>{
        this.lauchModal('0000x00','Error de consulta','');
        console.log(err);
        this.loader = 2;
      }
    );
  }

}
