import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../variables/enviroment';

@Injectable({
  providedIn: 'root'
})
export class QuotationService {
  uri:string = environment.uri;

  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');
  
  constructor(private httpClient:HttpClient) { }

  getQuotationList(page: number){
    return this.httpClient.get(this.uri+"get_quotation_list22?api_key="+this.api_key+"&token="+this.token+"&module=125&page="+page);
  }

  getQuotationDetail(id){
    return this.httpClient.get(this.uri+"get_quotation_detail?api_key="+this.api_key+"&token="+this.token+"&module=126&cot="+id);
  }

  addNewQuotation(data){
    return this.httpClient.post(this.uri+"add_new_quotation?api_key="+this.api_key+"&token="+this.token+"&module=128",data);
  }

  getDataForEdit(id){
    return this.httpClient.get(this.uri+"get_quotation_data_for_edit?api_key="+this.api_key+"&token="+this.token+"&module=127&cot="+id);
  }

  saveComment(data:FormData){
    return this.httpClient.post(this.uri+"add_new_quotation_comment?api_key="+this.api_key+"&token="+this.token+"&module=132",data);
  }

  saveEdit(data:FormData){
    return this.httpClient.post(this.uri+"save_quotation_edit?api_key="+this.api_key+"&token="+this.token+"&module=127",data);
  }

  sendEmail(data:FormData){
    return this.httpClient.post(this.uri+"send_quotation_email?api_key="+this.api_key+"&token="+this.token+"&module=134",data);
  }

  loadPartsByXLSX(data:FormData){
    return this.httpClient.post(this.uri+"load_parts_xlsx_quotation?api_key="+this.api_key+"&token="+this.token+"&module=138",data);
  }

  saveNewClient(data:FormData){
    return this.httpClient.post(this.uri+"save_client_by_quotation?api_key="+this.api_key+"&token="+this.token+"&module=142",data);
  }

  addContactToClient(data:FormData){
    return this.httpClient.post(this.uri+"add_contact_to_client_by_quotation?api_key="+this.api_key+"&token="+this.token+"&module=139",data);
  }

  setContactToQuotation(id_quotation,id_contact){
    return this.httpClient.get(this.uri+"set_contact_to_quotation?api_key="+this.api_key+"&token="+this.token+"&module=139&cot="+id_quotation+"&contact="+id_contact);
  }

  addPartToQuotation(data:FormData){
    return this.httpClient.post(this.uri+"save_manual_part_to_quotation?api_key="+this.api_key+"&token="+this.token+"&module=138",data);
  }

  deletePart(id){
    return this.httpClient.get(this.uri+"delete_part_to_quotation?api_key="+this.api_key+"&token="+this.token+"&module=141&instrument="+id);
  }

  editPart(data:FormData){
    return this.httpClient.post(this.uri+"edit_part_from_quotation?api_key="+this.api_key+"&token="+this.token+"&module=140",data);
  }

  createTemporalODV(data:FormData){
    return this.httpClient.post(this.uri+"create_temporal_odv_from_quotation?api_key="+this.api_key+"&token="+this.token+"&module=137",data);
  }

  searchODVToAddQuotation(value){
    return this.httpClient.get(this.uri+"search_odv_to_add_quotation_part?api_key="+this.api_key+"&token="+this.token+"&module=137&value="+value);
  }

  addPartsToODVFromQuotation(data:FormData){
    return this.httpClient.post(this.uri+"add_parts_to_odv_from_quotation?api_key="+this.api_key+"&token="+this.token+"&module=137",data);
  }

  addAditionalConcepts(data:FormData){
    return this.httpClient.post(this.uri+"add_aditional_parts_to_quotation?api_key="+this.api_key+"&token="+this.token+"&module=137",data);
  }

  deleteAditionalConcepts(id){
    return this.httpClient.get(this.uri+"delete_aditional_parts_to_quotation?api_key="+this.api_key+"&token="+this.token+"&module=137&concept_id="+id);
  }

  downloadPdf(id, chunks){
    return this.httpClient.get(this.uri+"download_pdf_quotation?api_key="+this.api_key+"&token="+this.token+"&module=143&cot="+id+"&chunks="+chunks);
  }

  generateQuotationFromCopy(id,cliente_id){
    return this.httpClient.get(this.uri+"create_quotation_copy?api_key="+this.api_key+"&token="+this.token+"&module=143&cot="+id+"&cliente_id="+cliente_id);
  }

  generarReporte(){
    return this.httpClient.get(this.uri+"generate_report_quotation?api_key="+this.api_key+"&token="+this.token+"&module=125");
  }

  obtenerIndicadores(mes,anio){
    return this.httpClient.get(this.uri+"get_indicators_quotation?api_key="+this.api_key+"&token="+this.token+"&module=125&mes="+mes+"&anio="+anio);
  }

  getQuotationFilterList(filters: any){
    return this.httpClient.post(this.uri+"get_quotation_filtered_list?api_key="+this.api_key+"&token="+this.token+"&module=125", filters);
  }

}
