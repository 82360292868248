import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ILogin } from './models/ILogin';
import { IUser } from './models/IUser';
import { environment } from 'src/app/variables/enviroment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  uri:string = environment.uri;
  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');
  
  constructor(private httpClient:HttpClient,private router:Router) { }

  loginUser(iLogin:ILogin){
    return this.httpClient.post(this.uri+"login",iLogin);
  }
  getUserData(){
    let token_ = localStorage.getItem('token');
    let api_key_ = localStorage.getItem('key');

    return this.httpClient.get(this.uri+"get_user_data?api_key="+api_key_+"&token="+token_+"&module=11");
  }

  getPersonalIndicators(id_user,permiso){
    return this.httpClient.get(this.uri+"get_personal_indicators?api_key="+this.api_key+"&token="+this.token+"&module="+permiso+"&id="+id_user);
  }

  getAllPlantasClientesDeptos(){
    return this.httpClient.get(this.uri+"get_data_cli_pla_dep?api_key="+this.api_key+"&token="+this.token+"&module=14");
  }

  getActivityRegister(){
    return this.httpClient.get(this.uri+"get_user_activity_register?api_key="+this.api_key+"&token="+this.token+"&module=12");
  }
  getModulesList(user_id){
    let uri = this.uri+"get_all_modules_for_user?api_key="+this.api_key+"&token="+this.token+"&module=16&user_data_id="+user_id;
    return this.httpClient.get(uri);
  }
  setLevelUser(level,id_usuario){
    return this.httpClient.get(this.uri+"set_level_to_user?api_key="+this.api_key+"&token="+this.token+"&module=16&level="+level+"&id_u="+id_usuario);
  }

  setUserPermission(user_id,permission_id){
    let uri = this.uri+"set_permission_for_user?api_key="+this.api_key+"&token="+this.token+"&module=16&permission_id="+permission_id+"&user_data_id="+user_id;
    return this.httpClient.get(uri);
  }
  deleteUserPermission(user_id,permission_id){
    let uri = this.uri+"delete_permission_for_user?api_key="+this.api_key+"&token="+this.token+"&module=16&permission_id="+permission_id+"&user_data_id="+user_id;
    return this.httpClient.get(uri);
  }

  getLogByUser(user_id){
    return this.httpClient.get(this.uri+"get_log_activity_by_user?api_key="+this.api_key+"&token="+this.token+"&module=11&user_data_id="+user_id);
  }

  getDeptosByUser(user_id){
    return this.httpClient.get(this.uri+"get_deptos_assigned_by_user?api_key="+this.api_key+"&token="+this.token+"&module=11&user_data_id="+user_id);
  }

  restorePassword(email){
    return this.httpClient.get(this.uri+"restore_password_email?email_user="+email);
  }

  public get getSession(): boolean {
    return (localStorage.getItem('key') !== null && localStorage.getItem('token') !== null);
  }

  getAllUsers(module){
    return this.httpClient.get(this.uri+"get_users_list?api_key="+this.api_key+"&token="+this.token+"&module="+module);
  }

  getDeptosUser(idusuario){
    return this.httpClient.get(this.uri+"get_user_deptos_list?api_key="+this.api_key+"&token="+this.token+"&module=18&user_data_id="+idusuario);
  }

  setDeptoUser(idusuario, depto){
    return this.httpClient.get(this.uri+"set_permission_depto_for_user?api_key="+this.api_key+"&token="+this.token+"&module=18&user_id="+idusuario+"&depto_id="+depto+"&user_data_id="+idusuario);
  }

  quitDeptoUser(idusuario, depto){
    return this.httpClient.get(this.uri+"delete_permission_depto_for_user?api_key="+this.api_key+"&token="+this.token+"&module=18&user_id="+idusuario+"&depto_id="+depto+"&user_data_id="+idusuario);
  }

  getInfoUserByID(id){
    return this.httpClient.get(this.uri+"get_user_data_by_id?api_key="+this.api_key+"&token="+this.token+"&module=11&user_data_id="+id);
  }
  
  postUser(iUser: IUser){
    return this.httpClient.post(this.uri+"save_new_user?api_key="+this.api_key+"&token="+this.token+"&module=62", iUser);
  }

  saveUserClient(iUser){
    return this.httpClient.post(this.uri+"save_user_to_client?api_key="+this.api_key+"&token="+this.token+"&module=99", iUser);
  }

  changePasswod(iduser, contrasenia){
    return this.httpClient.get(this.uri+"change_password?api_key="+this.api_key+"&token="+this.token+"&module=71&id_usuario="+iduser+"&new_password="+contrasenia);
  }

  supportRequest(obj:FormData){
    return this.httpClient.post(this.uri+"send_support_request?api_key="+this.api_key+"&token="+this.token+"&module=106",obj);
  }

  getAllUsersInactive(module){
    return this.httpClient.get(this.uri+"get_users_inactive_list?api_key="+this.api_key+"&token="+this.token+"&module="+module);
  }

  reactivateUser(id_user){
    return this.httpClient.get(this.uri+"reactivate_user?api_key="+this.api_key+"&token="+this.token+"&module=151&id_user_active="+id_user);
  }

  getUserInternos(){
    return this.httpClient.get(this.uri+"get_users_permissions_notifications?api_key="+this.api_key+"&token="+this.token+"&module=152");
  }

  getUserInternosVacation(){
    return this.httpClient.get(this.uri+"get_users_vacations_list?api_key="+this.api_key+"&token="+this.token+"&module=177");
  }

  searcEtalonethUser(data){
    return this.httpClient.post(this.uri+"search_etalonet_user?api_key="+this.api_key+"&token="+this.token+"&module=121",data);
  }
  sendAccessEmail(data){
    return this.httpClient.post(this.uri+"send_email_portal_access?api_key="+this.api_key+"&token="+this.token+"&module=71",data);
  }
  

  closeSession() {
    localStorage.removeItem('user_name');
    localStorage.removeItem('img_user');
    localStorage.removeItem('key');
    localStorage.removeItem('token');
    localStorage.removeItem('permisos');
    localStorage.removeItem('user_name');
    localStorage.removeItem('img_user');
    this.router.navigate(['login']);
  }
  
}
