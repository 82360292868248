import { Pipe, PipeTransform } from '@angular/core';
import { IBrand } from 'src/app/services/models/IBrand';

@Pipe({
  name: 'filtro'
})
export class FiltroPipe implements PipeTransform {

  transform(brands: IBrand[], page: number = 0, search:string = ''): IBrand[] {
    if(search.length === 0) {
     return brands;
    } 
    const filteredBrands = brands.filter( brand => brand.brand.toUpperCase().includes( search.toUpperCase() ));
    return filteredBrands; 
  }

}
