import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/app/variables/enviroment';
import { IDepotEdit } from './models/IDepotEdit';

@Injectable({
  providedIn: 'root'
})
export class DepotService {
  getDepot(id: string) {
    throw new Error('Method not implemented.');
  }
  uri:string = environment.uri;
  //uri:string = "http://ngdat.ucal.com.mx/api/";

  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');
  constructor(private httpClient:HttpClient) { }

  searchBrand(data){
    return this.httpClient.post(this.uri+"search_brand_depot?api_key="+this.api_key+"&token="+this.token+"&module=102",data);
  }

  serchDepto(id){
    return this.httpClient.get(this.uri+"get_search_depto?api_key="+this.api_key+"&token="+this.token+"&module=103&id_cliente="+id);
  }

  getClientSearch(client){
    return this.httpClient.get(this.uri+"search_value_to_odv?api_key="+this.api_key+"&token="+this.token+"&module=103&client_value="+client);
  }

  searchDepotReg(value_to_seach){
    return this.httpClient.get(this.uri+"search_depot_add_odv?api_key="+this.api_key+"&token="+this.token+"&module=9&value="+value_to_seach);
  }

//NEW DEPOT
  getInOutDepotList(){
    return this.httpClient.get(this.uri+"get_in_out_depot?api_key="+this.api_key+"&token="+this.token+"&module=100");
  }

  getInDepotList(){
    return this.httpClient.get(this.uri+"get_in_depot?api_key="+this.api_key+"&token="+this.token+"&module=100");
  }

  getOutDepotList(){
    return this.httpClient.get(this.uri+"get_out_depot?api_key="+this.api_key+"&token="+this.token+"&module=100");
  }

  getDepotDetailInOut(id, type:string){
    return this.httpClient.get(this.uri+"get_depot_detail_in_out?api_key="+this.api_key+"&token="+this.token+"&module=101&type="+type+"&id="+id);
  }
  addDepotInOut(data){
    return this.httpClient.post(this.uri+"add_depot_in_out?api_key="+this.api_key+"&token="+this.token+"&module=103",data);
  }
  
  saveDepotInOutEdit(data){
    return this.httpClient.post(this.uri+"save_depot_inout_edit?api_key="+this.api_key+"&token="+this.token+"&module=102",data);
  }
  newOdvDepotIn(data){
    return this.httpClient.post(this.uri+"add_new_depot_odv?api_key="+this.api_key+"&token="+this.token+"&module=103",data);
  }
  
  getByEntryDepotOut(entry){
    return this.httpClient.get(this.uri+"new_entry_depot_out?api_key="+this.api_key+"&token="+this.token+"&module=103&entry_value="+entry);
  }

  newOutByEntryDepot(data){
    return this.httpClient.post(this.uri+"new_out_by_entry?api_key="+this.api_key+"&token="+this.token+"&module=103",data);
  }
  saveEditedPart(partidas){
    return this.httpClient.post(this.uri+"save_changes_depot_part?api_key="+this.api_key+"&token="+this.token+"&module=103",{partidas});
  }  
  deleteDepotPart(partidas){
    return this.httpClient.post(this.uri+"delete_depot_part?api_key="+this.api_key+"&token="+this.token+"&module=103",{partidas});
  }

  updateDepotStatusParts(partidas){
    return this.httpClient.post(this.uri+"update_depot_parts?api_key="+this.api_key+"&token="+this.token+"&module=103",partidas);
  }

  searchInformationNewPart(data){
    return this.httpClient.post(this.uri+"search_info_new_part?api_key="+this.api_key+"&token="+this.token+"&module=102",data);
  }

  addNewDepotPart(new_part : IDepotEdit){
    return this.httpClient.post(this.uri+"new_depot_part?api_key="+this.api_key+"&token="+this.token+"&module=103",new_part);
  }

  downloadDepotFile(tipo, depot_id) {
    return this.httpClient.get(this.uri + "download_detail_depot?api_key=" + this.api_key + "&token=" +this.token + "&module=2"+"&depot_type="+tipo +"&id_depot="+depot_id );
  }
  addPartImages(images){
    return this.httpClient.post(this.uri+"add_part_depot_images?api_key="+this.api_key+"&token="+this.token+"&module=103",images);
  }

  getDepotImagesList(depot_type, depot_id){
    return this.httpClient.get(this.uri+"get_depot_images_list?api_key="+this.api_key+"&token="+this.token+"&module=103"+"&depot_type="+depot_type+"&&depot_id="+depot_id);
  }
}
