import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/app/variables/enviroment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeneratorReportsService {
  uri:string = environment.uri;
  //uri:string = "http://ngdat.ucal.com.mx/api/";


  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');

  constructor(private httpClient:HttpClient) { }

  createReportFile(data:any):Observable<any>{
    return this.httpClient.post(this.uri+"read_csv?api_key="+this.api_key+"&token="+this.token+"&module=43",data);
  }

  buscarOdvTest(){
    console.log(this.uri+"get_odv_identifier_app?api_key="+this.api_key+"&token="+this.token+"&module=35&value=20EM");
    return this.httpClient.get(this.uri+"get_odv_identifier_app?api_key="+this.api_key+"&token="+this.token+"&module=35&value=20EM");
  }

  buscarTags(id_user:string,tag:string){
    return this.httpClient.get(this.uri+"search_instrument_rg?api_key="+this.api_key+"&token="+this.token+"&module=43&id_user="+id_user+"&tag="+tag);
  }

  buscarHoja(nombre:string){
    return this.httpClient.get(this.uri+"search_datasheet_rg?api_key="+this.api_key+"&token="+this.token+"&module=43&value_search="+nombre);
  }

  buscarPatron(tag:string){
    return this.httpClient.get(this.uri+"search_ref_inst_rg?api_key="+this.api_key+"&token="+this.token+"&module=43&value_search="+tag);
  }

  evaluarOdv(idEquipos:any=[]){
    return this.httpClient.get(this.uri+"evaluate_inst_rg?api_key="+this.api_key+"&token="+this.token+"&module=43&value_search="+idEquipos)
  }

  buscarEquiposId(ids:any=[]){
    return this.httpClient.get(this.uri+"search_instrument_id_rg?api_key="+this.api_key+"&token="+this.token+"&module=43&id="+ids)
  }

  //--------------- plantillas ---------------

  verPlantilla(id:string,ids:any=[]){
    return this.httpClient.get(this.uri+"get_data_plantila_rg?api_key="+this.api_key+"&token="+this.token+"&module=43&hoja="+id+"&id_equipos="+ids);
  }

  makePdf(idShet,equipo_id){
    return this.httpClient.get(this.uri+"get_informe_pdf?api_key="+this.api_key+"&token="+this.token+
    "&module=43&id_plantilla="+idShet+"&id_equipo="+equipo_id);
  }

  getPlantasByCliente(id_cliente){
    return this.httpClient.get(this.uri+"get_plantas_cliente?api_key="+this.api_key+"&token="+this.token+
    "&module=43&id_cliente="+id_cliente);
  }
  
  asignarInformeCsv(informe, _id_equipo, fecha){
    return this.httpClient.get(this.uri+"asignar_pdf_file_csv?api_key="+this.api_key+"&token="+this.token+"&module=43&id_equipo="+_id_equipo+"&informe="+informe+"&date="+fecha);
  }

  getFilesCsvNube(){
    return this.httpClient.get(this.uri+"get_files_usr_csv?api_key="+this.api_key+"&token="+this.token+"&module=105");
  }

  getFilesCsvNubeG(){
    return this.httpClient.get(this.uri+"get_files_usr_csv_g?api_key="+this.api_key+"&token="+this.token+"&module=105");
  }

  generateInfoCsvNubeG(id){
    return this.httpClient.get(this.uri+"generate_report_csv_g?api_key="+this.api_key+"&token="+this.token+"&module=105&id_file="+id);
  }
  
  generarInformeDinamico(global,ids,patrones,med,id_hoja){
    return this.httpClient.get(this.uri+"generate_report_app_dym?api_key="+this.api_key+"&token="+this.token+"&module=43&hoja="+id_hoja+"&global_data="+global+"&ids="+ids+"&patrones="+patrones+"&med="+med);
  }
  

}
