import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserService } from "src/app/services/user.service";
import { Router } from "@angular/router";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { IUser } from "src/app/services/models/IUser";
import { environment } from "src/app/variables/enviroment";

@Component({
  selector: "app-detail-user",
  templateUrl: "./detail-user.component.html",
  styleUrls: ["./detail-user.component.css"],
})
export class DetailUserComponent implements OnInit {
  result: any = [];
  user_image: string = "assets/img/theme/user_default.png";
  id_user: string = "";
  data: any = [];
  loader: number = 1;
  contrasenia: string = "";
  confirmarcontrasenia: string = "";
  mensaje: string = "";
  mensaje2: string = "";

  deptos_assigned: any = [];
  log_res: any = [];
  log_data: any = [];
  result_password: any = [];
  data_password: any = [];
  list: any = [];

  response: any = [];
  customer_address: any = [];
  pages = 0;
  number_page = 0;

  updatingInfo = 0;
  nombre = "";
  apellido = "";
  puesto = "";
  id_area = "";
  extension = "";
  telefono = "";
  correo = "";
  level;

  public passw_send:string = '';
  public email_sent: boolean = false;
  data_areas: any = [];
  name_area = "";
  activo: number = 1;
  nota_active: boolean = false;
  permisos = JSON.parse(localStorage.getItem("permisos"));
  @ViewChild("cerrarModal") divView: ElementRef;
  constructor(
    private activedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private userSer: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.id_user = this.activedRoute.snapshot.paramMap.get("id_user");
    this.getUser();
  }

  /**
   * The function retrieves user information by ID and assigns the data to various variables, with
   * error handling included.
   */
  private getUser() :void {
    this.userSer.getInfoUserByID(this.id_user).subscribe(
      (res) => {
        this.result = res;
        this.data = this.result.data;
        if (
          this.result.code == "1_0001" ||
          this.result.code == "1_0002" ||
          this.result.code == "1_0003"
        )
          this.lauchModal(this.result.code, this.result.message);
        else if (this.result.code == "1_0004")
          this.lauchModal(this.result.code, this.result.message);
        else {
          this.data = this.result.data;
          if (this.data.image == 1) {
            this.user_image =
              environment.uri_server +
              "storage/public/fotos_metrologos/" +
              this.id_user +
              ".jpg";
          }
        }
        this.activo = this.data.activo;
        this.nombre = this.data.name;
        this.apellido = this.data.last_name;
        this.correo = this.data.email;
        this.puesto = this.data.place;
        this.id_area = this.data.area;
        this.extension = this.data.extension;
        this.telefono = this.data.phone;
        this.customer_address = this.data.deptos;
        this.level = this.data.level;
        this.loader = 2;

        this.data_areas = this.data.areas;
        if(this.data_areas != undefined ) {

          this.data_areas.forEach((da) => {
            if (da.id == this.id_area) {
              this.name_area = da.area;
            }
          });
          this.data_areas.splice(2, 1);
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This function activates a user and displays a modal with a message depending on the result.
   */
  public activarUsuario() :void {
    this.userSer.reactivateUser(this.id_user).subscribe(
      (res) => {
        this.response = res;
        if (this.result.code != "0_005")
          this.lauchModal(this.result.code, this.result.message);
        else {
          this.response = this.response.data;
          if (this.response == 1) {
            this.activo = 1;
            this.nota_active = true;
          }
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * The function toggles the value of a variable between 0 and 1.
   */
  public updatingInformation() :void {
    if (this.updatingInfo == 1) {
      this.updatingInfo = 0;
    } else {
      this.updatingInfo = 1;
    }
  }

  /**
   * The function retrieves log data for a user and displays it in a modal, with error handling.
   */
  public getLog() :void {
    this.loader = 1;
    this.userSer.getLogByUser(this.id_user).subscribe(
      (res) => {
        this.log_res = res;
        this.log_data = this.log_res.data;
        this.pages = this.log_res.data.length;

        if (
          this.log_res.code == "1_0001" ||
          this.log_res.code == "1_0002" ||
          this.log_res.code == "1_0003"
        )
          this.lauchModal(this.log_res.code, this.log_res.message);
        else if (this.log_res.code == "1_0004")
          this.lauchModal(this.log_res.code, this.log_res.message);
        else this.log_data = this.log_res.data;
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This function retrieves the departments assigned to a user and displays an error message if there
   * is an issue with the retrieval.
   */
  public getDeptosAssigned() :void {
    this.loader = 1;
    this.userSer.getDeptosByUser(this.id_user).subscribe(
      (res) => {
        this.deptos_assigned = res;
        if (
          this.deptos_assigned.code == "1_0001" ||
          this.deptos_assigned.code == "1_0002" ||
          this.deptos_assigned.code == "1_0003"
        )
          this.lauchModal(
            this.deptos_assigned.code,
            this.deptos_assigned.message
          );
        else if (this.deptos_assigned.code == "1_0004")
          this.lauchModal(
            this.deptos_assigned.code,
            this.deptos_assigned.message
          );
        else this.deptos_assigned = this.deptos_assigned.data;
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * The function changes the current page number to the specified page.
   * @param page - The "page" parameter is a variable that represents the page number that the user
   * wants to navigate to. The "changePageResults" function takes this parameter and updates the
   * "number_page" variable with the new page number.
   */
  public changePageResults(page) :void {
    this.number_page = page;
  }

  /**
   * This function validates and saves a password if it meets certain criteria.
   */
  public savePassword() :void {
    if (this.contrasenia.trim() == "") {
      this.mensaje = "Ingresa la contraseña para continuar.";
    } else {
      if (this.confirmarcontrasenia.trim() == "") {
        this.mensaje = "Confirma la contraseña para continuar.";
      } else {
        if (this.contrasenia !== this.confirmarcontrasenia) {
          this.mensaje = "Las contraseñas no coinciden. Por favor verificalas.";
        } else {
          if (this.contrasenia.length >= 6) {
            this.mensaje = "";
            this.mensaje2 = "Listo. Enviando...";
            this.sendPassword();
          } else {
            this.mensaje =
              "La contraseña debe contener por lo menos 6 caracteres.";
          }
        }
      }
    }
  }

  /**
   * This function sends a password change request to the server and handles the response accordingly.
   */
  private sendPassword() :void {
    this.loader = 1;
    this.userSer.changePasswod(this.id_user, this.contrasenia).subscribe(
      (res) => {
        this.result_password = res;
        this.data_password = this.result_password.data;

        if (
          this.result_password.code == "1_0001" ||
          this.result_password.code == "1_0002" ||
          this.result_password.code == "1_0003"
        )
          this.lauchModal(
            this.result_password.code,
            this.result_password.message
          );
        else if (this.result_password.code == "1_0004")
          this.lauchModal(
            this.result_password.code,
            this.result_password.message
          );
        else {
          this.mensaje2 = "¡Se cambió la contraseña con exito!";
          this.contrasenia = "";
          this.confirmarcontrasenia = "";
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This function sends user information to a server and updates the information if successful.
   */
  public enviarUsuario() :void {
    let usersender: IUser = {
      id: this.id_user,
      nombre: this.nombre,
      apellido: this.apellido,
      puesto: this.puesto,
      area: this.id_area,
      extension: this.extension,
      telefono: this.telefono,
      nuevo: "0",
    };
    this.userSer.postUser(usersender).subscribe(
      (res) => {
        this.response = res;

        if (this.response.data > 0) {
          this.updatingInformation();
          this.getUser();
        } else {
          this.lauchModal(this.response.code, this.response.message);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  public sendEmail() :void{
    this.loader = 1;
    let form = new FormData();
    form.append("password", this.passw_send);
    form.append("id_usuario", this.id_user);

    this.userSer.sendAccessEmail(form).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.email_sent = true;
          this.divView.nativeElement.click();
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        this.loader = 2;
      }
    );
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code and title using
   * the ModalComponent.
   * @param {string} code - The code parameter is a string that represents the code that will be
   * displayed in the modal.
   * @param {string} title - The title parameter is a string that represents the title of the modal
   * that will be launched. It will be displayed at the top of the modal to provide context to the
   * user.
   */
  private async lauchModal(code: string, title: string) :Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }
}
