import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-restorepassword',
  templateUrl: './restorepassword.component.html',
  styleUrls: ['./restorepassword.component.css']
})
export class RestorepasswordComponent implements OnInit {
  email:string = "";
  result:any = [];

  constructor(private modalService:NgbModal, private userSer: UserService, private router:Router) { }

  ngOnInit(): void {
  }

  enviarSolicitud(){
    if(this.email.trim() == ""){
      this.lauchModal("Ingresa tu correo para continuar", "Campo vacío");
    }else{
      this.userSer.restorePassword(this.email).subscribe(
        res=>{
          this.result = res;
          
  
          if(this.result.data == 1){
            this.router.navigate(['login']);
          }else if(this.result.data == 3){
            this.lauchModal("Ocurri ó un error en el servidor, por favor intenta más tarde.", "");
            
          }else{
            this.lauchModal("Este correo no coincidió con nuestros registros, por favor verificalo","");
          }
        },
        err=>{
          
          console.log(err);
        }
      );
    }
  }

  lauchModal(title:string, complemento: string){
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = complemento;
    modalRef.componentInstance.title = title;
  }
}
