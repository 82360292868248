import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CommercialRoutingModule } from './commercial-routing.module';
import { ServiceSurveysComponent } from './odv/service-surveys/service-surveys.component';
import { TemporalOdvComponent } from './temporal-odv/temporal-odv.component';
import { CotDetComponent } from './cotizaciones/cot-det/cot-det.component';
import { DownloadZipComponent } from './odv/download-zip/download-zip.component';
import { OdvQrComponent } from './odv/odv-qr/odv-qr.component';
import { DetEmailComponent } from './emails/det-email/det-email.component';
import { EmailsComponent } from './emails/emails.component';
import { SendEmailOdvComponent } from './odv/send-email-odv/send-email-odv.component';
import { EditOdvComponent } from './odv/edit-odv/edit-odv.component';
import { OdvDetComponent } from './odv/odv-det/odv-det.component';
import { CotizacionesComponent } from './cotizaciones/cotizaciones.component';
import { OdvComponent } from './odv/odv.component';
import { CommercialComponent } from './commercial.component';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { CotEditComponent } from './cotizaciones/cot-edit/cot-edit.component';
import { CotAddClientComponent } from './cotizaciones/cot-add-client/cot-add-client.component';
import { IndicatorsCotComponent } from './cotizaciones/indicators-cot/indicators-cot.component';
import { ServiceNotificationsComponent } from './service-notifications/service-notifications.component';
import { EmailNotificationComponent } from './service-notifications/email-notification/email-notification.component';
@NgModule({
  declarations: [
    CommercialComponent,
    OdvComponent,
    CotizacionesComponent,
    OdvDetComponent,
    EditOdvComponent,
    SendEmailOdvComponent,
    EmailsComponent,
    DetEmailComponent,
    OdvQrComponent,
    DownloadZipComponent,
    CotDetComponent,
    CotEditComponent,
    TemporalOdvComponent,
    ServiceSurveysComponent,
    IndicatorsCotComponent,
    CotAddClientComponent,
    ServiceNotificationsComponent,
    EmailNotificationComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CommercialRoutingModule
  ]
})
export class CommercialModule { }
