import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { InstrumentService } from "src/app/services/instrument.service";
import { environment } from "src/app/variables/enviroment";

@Component({
  selector: "app-disabled",
  templateUrl: "./disabled.component.html",
  styleUrls: ["./disabled.component.css"],
})
export class DisabledComponent implements OnInit {
  loader: number = 1;
  response: any = [];

  list_instruments: JSON;

  token = localStorage.getItem("token");
  api_key = localStorage.getItem("key");
  url_to_load = "";

  constructor(
    private instument_service: InstrumentService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  /**
   * The `getData` function retrieves instrument data and handles the response accordingly.
   */
  private getData(): void {
    this.instument_service.instrumentsDisabled().subscribe(
      (res) => {
        this.response = res;

        if (this.response.code != "0_005")
          this.lauchModal(this.response.code, this.response.message);
        else this.list_instruments = this.response.data;
        console.log(this.list_instruments);
        this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
      }
    );
  }

  /**
   * The function `enableInstrument` enables or disables an instrument based on a checkbox input and
   * sends a request to a service for processing.
   * @param check - The `check` parameter is likely a checkbox element or a variable representing the
   * state of a checkbox. The `checked` property of a checkbox element returns `true` if the checkbox
   * is checked, and `false` if it is unchecked. In this function, it is used to determine the value of
   * @param id_instrument - The `id_instrument` parameter in the `enableInstrument` function is used to
   * specify the unique identifier of the instrument that you want to enable or disable. This
   * identifier is typically a string or a number that uniquely identifies the instrument within your
   * system. When calling the `enableInstrument` function, you
   */
  public enableInstrument(check, id_instrument): void {
    this.loader = 1;
    let active = check.checked === true ? 1 : 0;
    let data = new FormData();
    data.append("active", active.toString());
    data.append("id_instrument", id_instrument);

    this.instument_service.enableOrDisabled(data).subscribe(
      (res) => {
        this.response = res;
        if (this.response.code != "0_005")
          this.lauchModal(this.response.code, this.response.message);
        else if (this.response.data == 1) this.loader = 0;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 0;
      }
    );
  }

  public downloadReport():void{
    this.url_to_load =
      environment.uri +
      "download_instruments_disabled?api_key=" +
      this.api_key +
      "&token=" +
      this.token +
      "&module=191&";
  }

  /**
   * The function `launchModal` opens a modal window with a specified code and title in a TypeScript
   * environment.
   * @param {string} code - The `code` parameter in the `launchModal` function is a string that
   * represents some code or text that will be displayed in the modal window. It is used to populate
   * the content of the modal with the specified code.
   * @param {string} title - The `title` parameter in the `launchModal` function is a string that
   * represents the title of the modal that will be displayed. It is used to set the title of the modal
   * component when opening a modal dialog.
   */
  public async lauchModal(code: string, message: string): Promise<void> {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = message;
  }
}
