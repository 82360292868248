import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ISupplier } from './models/ISupplier';
import { ISupplierContact } from './models/ISupplierContact';
import { ISupplierAddress } from 'src/app/services/models/ISupplierAddress';
import { ISupplierAdd } from './models/ISupplierAdd';
import { Observable } from 'rxjs';
import { environment } from 'src/app/variables/enviroment';
@Injectable({
  providedIn: 'root'
})
export class SuppliersService {
  uri:string = environment.uri;
  //uri:string = "http://ngdat.ucal.com.mx/api/";
  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');
  
  constructor(private httpClient:HttpClient) { }

  getList(){
    return this.httpClient.get(this.uri+"get_supliers_list?api_key="+this.api_key+"&token="+this.token+"&module=26");
  }
  getDetail(data){
    return this.httpClient.get(this.uri+"get_suplier_detail?api_key="+this.api_key+"&token="+this.token+"&module=27&id="+data);
  }
  getDetailToEdit(id){
    return this.httpClient.get(this.uri+"get_supplier_detail_to_edit?api_key="+this.api_key+"&token="+this.token+"&module=59&id="+id);
  }
  saveGeneralEdit(data: ISupplier){
    return this.httpClient.post(this.uri+"save_supplier_edit?api_key="+this.api_key+"&token="+this.token+"&module=59",data);
  }
  addContact(data:ISupplierContact){
    return this.httpClient.post(this.uri+"add_contact_supplier?api_key="+this.api_key+"&token="+this.token+"&module=59",data);
  }
  addAddress(data:ISupplierAddress){
    return this.httpClient.post(this.uri+"add_address_supplier?api_key="+this.api_key+"&token="+this.token+"&module=59",data);
  }
  getDataToAddSupplier(){
    return this.httpClient.get(this.uri+"get_data_to_add_supplier?api_key="+this.api_key+"&token="+this.token+"&module=63");
  }
  saveSupplier(data:ISupplierAdd){
    return this.httpClient.post(this.uri+"add_supplier?api_key="+this.api_key+"&token="+this.token+"&module=63",data);
  }
  editAddress(data: ISupplierAddress){
    return this.httpClient.post(this.uri+"edit_supplier_address?api_key="+this.api_key+"&token="+this.token+"&module=59",data);
  }
  emailToEvaluate(emails){
    return this.httpClient.post(this.uri+"send_email_to_evaluate?api_key="+this.api_key+"&token="+this.token+"&module=110",emails);
  }
  addActaConstitutiva(data:any):Observable<any>{
    return this.httpClient.post(this.uri+"add_acta_constitutiva?api_key="+this.api_key+"&token="+this.token+"&module=59",data);
  }
}
