import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Chart } from "chart.js";
import { WalletService } from "src/app/services/wallet.service";
import { ModalComponent } from "src/app/components/modal/modal.component";
@Component({
  selector: "app-indicators-wallet",
  templateUrl: "./indicators-wallet.component.html",
  styleUrls: ["./indicators-wallet.component.css"],
})
export class IndicatorsWalletComponent implements OnInit {
  response: any = [];
  data: any = [];
  loader: number = 0;

  /* FECHA ACTUAL AL ABRIR EL COMPONENTE */
  private currentDate = new Date();
  private currentDay:number = this.currentDate.getDate();
  public selectedYear:number = this.currentDate.getFullYear();
  public selectedMonth:number = this.currentDate.getMonth();
  public selectedWeek:number = 0;
  private fecha:string = '';

  /* GRAFICA */
  public values_array_pagados:any = { };
  public values_array_agend:any= {};
  public no_values:boolean = false;
  public total_mxn:number = 0.0;
  public total_usd:number = 0.0;

  /* DATOS PARA SELECTORES */
  public years:number[] = [2022, 2023, 2024];
  public months:string[] = [ 
    "Enero","Febrero","Marzo", "Abril", "Mayo",
    "Junio","Julio", "Agosto","Septiembre", 
    "Octubre", "Noviembre", "Diciembre",
  ];
  public dias_semana: string[] = [ 
    "Domingo","Lunes", "Martes",
    "Miercoles", "Jueves", "Viernes",
    "Sabado",
  ];

  /* DIAS QUE TIENE UN MES */
  public weeks_per_month:string[] = [];
  private weeks: string[][] = [];
  private dias_mes:number = 0;
  private my_chart:Chart;
  ctx:any;
  constructor(
    private carteraService: WalletService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    //selecciona semana Y MES actual al iniciar
     this.ctx = document.getElementById("my_canvas");
    this.getMesData();
    this.dias_mes = new Date(
      this.selectedYear,
      this.selectedMonth + 1,
      0
    ).getDate();
    this.selectedWeek = this.getCurrentWeek();
  }

 /**
  * The `getMesData` function retrieves data for a specific month, categorizes it into paid and
  * scheduled invoices, calculates the total values for each category in both MXN and USD currencies,
  * and displays the data in an indicator.
  */
  public getMesData() :void {
    this.selectedWeek = Number(this.selectedWeek);
    this.getWeeksPerMonth();
    this.fecha = this.getCurrentDateMonth();

    let pagados = [],
      agendados = [];
    this.carteraService.getInvoicesMonth(this.fecha).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (
          this.response.code == "1_0004" ||
          this.response.code == "0_0007"
        )
          this.lauchModal(this.response.code, this.response.message);
        else {
          this.data = this.response.data;
          this.data.forEach((d) => {
            if (d.estatus == 1) {
              pagados.push(d);
            } else if (d.estatus == 3) {
              agendados.push(d);
            }
          });
          this.values_array_pagados.mxn = this.collectData(pagados, 'Pesos');
          this.values_array_pagados.usd = this.collectData(pagados, 'USD');
          this.values_array_pagados.mxn_total = this.sumarArray(this.values_array_pagados.mxn);
          this.values_array_pagados.usd_total = this.sumarArray(this.values_array_pagados.usd);
          
          this.values_array_agend.mxn = this.collectData(agendados, 'Pesos');
          this.values_array_agend.usd = this.collectData(agendados, 'USD');
          this.values_array_agend.mxn_total = this.sumarArray(this.values_array_agend.mxn);
          this.values_array_agend.usd_total = this.sumarArray(this.values_array_agend.usd);
  
          if(this.values_array_pagados.mxn_total == 0.0 && 
            this.values_array_pagados.usd_total == 0.0 &&
            this.values_array_agend.mxn_total== 0.0 && 
            this.values_array_agend.usd_total == 0.0) 
          {
            this.no_values = true;
          }else {
            this.no_values = false;
          }
          
          this.ShowIndicator();
        } 
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        this.loader = 2;
      }
    );
  }

  /**
   * This function returns the current date and month in a specific API format.
   * @returns a string in the format "YYYY-MM" representing the current year and month.
   */
  private getCurrentDateMonth(): string {
    let month: any = Number(this.selectedMonth) + 1;
    let year: any = this.selectedYear;
    let dateInApiFormat: string;

    if (month < 10) month = "0" + month.toString();
    dateInApiFormat = year.toString() + "-" + month;

    return dateInApiFormat;
  }

  /* CREA EL CALENDARIO Y LO SEPARA POR SEMANAS */
  
/**
 * The function calculates the number of weeks in a given month and stores the weeks in an array.
 */
  public getWeeksPerMonth() :void {
    this.dias_mes = new Date(
      this.selectedYear,
      Number(this.selectedMonth) + 1,
      0
    ).getDate();
    this.weeks_per_month = [];
    this.weeks = [];
    let week:string[] = [];

    for (let days = 1; days <= this.dias_mes; days++) {

      const indice = new Date(
        this.selectedYear,
        this.selectedMonth,
        days
      ).getDay();

      const day = days.toString().padStart(2,'0');
      week.push(day);

      //al pasar los 7 dias se guarda el array
      if (this.dias_semana[indice] == "Sabado" || days === this.dias_mes) {
        this.weeks_per_month.push(week[0] + "-" + week[week.length - 1]); // obtener rango de dias
        if (week.length < 7) {
          week = week.concat(Array(7 - week.length).fill("00")); // rellenar espacios vacios
        }
        this.weeks.push(week);
        week = [];
      }
    }
  }

  /* Selecciona la semana actual al iniciar */
  /**
   * This function finds the current week based on the current day and a predefined list of weeks.
   * @returns the number of the current week.
   */
  private getCurrentWeek(): number {
    let findActualWeek: number;
    let day;

    this.weeks.forEach((e, i) => {
      if (this.currentDay < 10) day = "0" + this.currentDay.toString();
      else day = this.currentDay.toString();

      if (e.includes(day)) {
        findActualWeek = i;
      }
    });

    return findActualWeek;
  }

  /* COMPARA LOS DÍAS DE LA SEMNA PARA RECOLECTAR LA INFO */

 /**
  * The function `collectData` takes in a status array and a divisa value, and calculates the total
  * saldo for each day of the selected week.
  * @param status - The `status` parameter is an array of objects representing some kind of status.
  * Each object in the array has properties such as `fecha_cobro`, `divisa`, and `saldo`.
  * @param divisa - The parameter "divisa" represents a currency.
  * @returns an array of numbers, specifically the day totals for a given week and currency.
  */
  private collectData(status, divisa) : number[]{
    const dayTotals:number[] = [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0];

    this.weeks.forEach((w, i) => {
      if (i === this.selectedWeek) {
        status.forEach((e) => {
          const dayIndex = w.findIndex((day) => day === e.fecha_cobro.substr(8, 9));
          if (dayIndex !== -1 && e.divisa === divisa) {
            dayTotals[dayIndex] += Number(e.saldo);
          }
        });
      }
    });
    this.getTotalSemana();
    return dayTotals;
  }

  /**
   * This function calculates the total amount of money in USD and MXN for a selected week based on
   * data provided.
   */
  private getTotalSemana() :void{
    let total_usd_temp:number = 0.0;
    let total_mxn_temp:number = 0.0;
    this.weeks.forEach((w, i) => {
      if (i == this.selectedWeek) {
        this.data.forEach((e) => {
          if (w.includes(e.fecha_expedida.substr(0, 2))) {
            if (e.divisa == "Pesos") {
              total_mxn_temp += Number(e.saldo);
            } else {
              total_usd_temp += Number(e.saldo);
            }
          }
        });
      }
    });

    this.total_mxn = Number(total_mxn_temp.toFixed(2));
    this.total_usd = Number(total_usd_temp.toFixed(2));
  }
 
  public ShowIndicator() :void {
    
    if(this.my_chart != undefined)  this.my_chart.destroy();
    
    const data = {
      labels: this.dias_semana,
      datasets: [
        {
          label: "$ MXN Cobranza",
          data: this.values_array_pagados.mxn,
          borderColor: "rgb(103, 232, 82)",
           //  backgroundColor: "rgba(143, 250, 126,0.2)",
          fill: false,
          hidden: false,
          lineTension: 0,
        },
        {
          label: "$ USD Cobranza",
          data: this.values_array_pagados.usd,
          borderColor: "rgb(255, 0, 82)",
         // backgroundColor: "rgba(143, 0, 126,0.2)",
         fill: false,
         hidden: false,
          lineTension: 0,
        },
        {
          label: "$ MXN Cobranza Agendados",
          data: this.values_array_agend.mxn,
          borderColor: "rgb(141, 236, 239)",
          //backgroundColor: "rgba(141, 236, 239,0.2)",
          fill: false,
          hidden: false,
          lineTension: 0,
          
        },
        {
          label: "$ USD Cobranza Agendados",
          data: this.values_array_agend.usd,
          borderColor: "rgb(141, 166, 249)",
          //backgroundColor: "rgba(141, 236, 239,0.2)",
          fill: false,
          hidden: false,
          lineTension: 0,
          
        },
        {
          label: "Meta Agendados",
          data: [250000,250000,250000,250000,250000,250000,250000],
          borderColor: "rgb(32, 167, 255)",
          fill: false,
          hidden: true,
          lineTension: 0,
        },
      ],
    };

    const config = {
      type: "line",
      data,
      options: {
        legend: {
          display: true
        },
       hover: {
          mode: 'index',
          intersect: false
       },
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          mode: 'index',
          intersect: false,
          callbacks: {
            label: function (tooltipItem, data) {
              return tooltipItem.yLabel
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,");
            },
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: function (value) {
                  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }, 
              },
            },
          ],
        },
      },
    };
   
    this.my_chart = new Chart(this.ctx, config);
  }

 /**
  * The function "sumarArray" takes an array of numbers as input and returns the sum of all the numbers
  * in the array.
  * @param {number[]} arr - The parameter "arr" is an array of numbers.
  * @returns the sum of all the numbers in the given array.
  */
  private sumarArray(arr: number[]): number {
    return arr.reduce((acumulador, valorActual) => acumulador + valorActual, 0);
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code and title using
   * the ModalComponent.
   * @param {string} code - A string representing some code that will be displayed in the modal.
   * @param {string} title - The title parameter is a string that represents the title of the modal
   * that will be launched.
   */
  private async lauchModal(code: string, title: string) :Promise<void>{
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

}
