import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { IWikiReg } from "src/app/services/models/IWikiReg";
import { WikiService } from "src/app/services/wiki.service";
@Component({
  selector: "app-add-wiki",
  templateUrl: "./add-wiki.component.html",
  styleUrls: ["./add-wiki.component.scss"],
})
export class AddWikiComponent implements OnInit {
  response: any = [];
  loader = 2;
  section = 0;
  array_sections = [
    { id: "1", det: "Externos" },
    { id: "2", det: "Metrología General" },
    { id: "3", det: "Procedimientos Específicos" },
    { id: "4", det: "Formatos" },
  ];

  document_type = 0;
  document_type_array = [
    { id: "0", det: "Seleccionar" },
    { id: "1", det: "Calidad" },
    { id: "2", det: "Política" },
    { id: "3", det: "Procedimiento" },
    { id: "4", det: "Formato" },
  ];

  description_doc = "";
  id_doc = "";
  area_doc = "";
  date_doc = "";
  file_name = "";

  file: any;
  constructor(
    private wikiService: WikiService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {}

  /**
   * This is a private function in TypeScript that sets the section ID.
   * @param id - The "id" parameter is a value that represents the section identifier that we want to
   * set. This method is likely part of a larger class or component that manages different sections or
   * pages, and this method allows us to set the current section to a specific identifier.
   */
  public setSection(id): void {
    this.section = id;
  }

  /**
   * This is a private function in TypeScript that sets the document type based on the provided ID.
   * @param id - The "id" parameter is the identifier of the document type that is being set. It is
   * used to update the "document_type" property of the object.
   */
  public setDocumentType(id): void {
    this.document_type = id;
  }

  /**
   * This function loads a file and sets its name as a property.
   * @param value - The parameter "value" is an event object that is passed as an argument to the
   * function when it is called. It contains information about the event that triggered the function,
   * such as the target element that was interacted with. In this case, the target element is a file
   * input field, and the
   */
  public loadFile(value): void {
    this.file = value.target;
    this.file_name = this.file.files[0].name;
  }

  /**
   * The function sends data and a file (if specified) to be saved in a database through an API call.
   * @param {boolean} file - A boolean value indicating whether or not a file is being sent along with
   * the form data. If true, the file will be appended to the FormData object. If false, only the form
   * data will be sent.
   */
  public sendToSave(file: boolean): void {
    let reg = new FormData();

    reg.append("section", String(this.section));
    reg.append("description", this.description_doc);
    reg.append("clave", this.id_doc);
    reg.append("area", this.area_doc);
    reg.append("date", this.date_doc);
    reg.append("type", String(this.document_type));

    if (file) {
      reg.append("file", this.file.files[0]);
      reg.append("file_contains", String(1));
      reg.append("file_name", this.file_name);
    } else {
      reg.append("file_contains", String(0));
    }
    this.loader = 1;
    console.log(reg);
    this.wikiService.addNewRegister(reg).subscribe(
      (res) => {
        this.response = res;
        this.loader = 2;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.router.navigate(["/operations/wiki"]);
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", err.message);
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This is a private function that launches a modal with a specified code, title, and message using
   * the Angular modal service.
   * @param {string} code - a string representing the code to be displayed in the modal
   * @param {string} title - The title of the modal window that will be displayed.
   * @param message - The message parameter is a string that represents the content or information that
   * will be displayed in the modal dialog box.
   */
  private async lauchModal(
    code: string,
    title: string,
    message
  ): Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }
}
