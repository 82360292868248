import { Component, OnInit } from "@angular/core";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CustomerService } from "src/app/services/customer.service";

@Component({
  selector: "app-customers",
  templateUrl: "./customers.component.html",
  styleUrls: ["./customers.component.css"],
})
export class CustomersComponent implements OnInit {
  permmisions = JSON.parse(localStorage.getItem("permisos"));
  loader: number = 1;
  response: any = [];
  data: any = [];

  constructor(
    private customerService: CustomerService,
    private modalHelper: NgbModal
  ) {}

  ngOnInit(): void {
    this.customersList();
  }

  /**
   * This function retrieves a list of customers from a service and handles different response codes
   * accordingly.
   */
  private customersList() :void {
    this.customerService.getCustomers().subscribe(
      (resp) => {
        this.response = resp;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message);
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message);
        else this.data = this.response.data;
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta");
        console.log(err);
        this.loader = 2;
      }
    );
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code and title using a
   * modal helper.
   * @param {string} code - A string representing some code that will be displayed in the modal window.
   * @param {string} title - The title parameter is a string that represents the title of the modal
   * that will be launched. It will be displayed at the top of the modal to provide context to the
   * user.
   */
  private async lauchModal(code: string, title: string) :Promise<void> {
    const modalRef = await this.modalHelper.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }
  
}
