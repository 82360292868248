import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ProgrammersRoutingModule } from './programmers-routing.module';
import { DetTicketComponent } from './tickets/det-ticket/det-ticket.component';
import { AddTicketComponent } from './tickets/add-ticket/add-ticket.component';
import { TicketsComponent } from './tickets/tickets.component';
import { AnaliticsComponent } from './analitics/analitics.component';
import { AddCustomerUserComponent } from './customers/add-customer-user/add-customer-user.component';
import { AddMarathonComponent } from './general-indicators/marathons/add-marathon/add-marathon.component';
import { DetailMarathonComponent } from './general-indicators/marathons/detail-marathon/detail-marathon.component';
import { MarathonsComponent } from './general-indicators/marathons/marathons.component';
import { GeneralIndicatorsComponent } from './general-indicators/general-indicators.component';
import { UpdateAddressComponent } from './customers/update-address/update-address.component';
import { AddUserComponent } from './users/add-user/add-user.component';
import { EditDeptosComponent } from './users/edit-deptos/edit-deptos.component';
import { DetailUserComponent } from './users/detail-user/detail-user.component';
import { EditCustomerComponent } from './customers/edit-customer/edit-customer.component';
import { AddAddressComponent } from './customers/add-address/add-address.component';
import { AddCustomerComponent } from './customers/add-customer/add-customer.component';
import { LogComponent } from './log/log.component';
import { AddNewComponent } from './news/add-new/add-new.component';
import { DetailNewsComponent } from './news/detail-news/detail-news.component';
import { NewsComponent } from './news/news.component';
import { DetailcustomerComponent } from './customers/detailcustomer/detailcustomer.component';
import { CustomersComponent } from './customers/customers.component';
import { ModulesPermissionsComponent } from './users/modules-permissions/modules-permissions.component';
import { UsersComponent } from './users/users.component';
import { ProgrammersComponent } from './programmers.component';
import { UsersInactiveComponent } from './users/users-inactive/users-inactive.component';
import { UserIndicatorsComponent } from './general-indicators/user-indicators/user-indicators.component';

@NgModule({
  declarations: [
    ProgrammersComponent,
    UsersComponent,
    ModulesPermissionsComponent,
    CustomersComponent,
    DetailcustomerComponent,
    NewsComponent,
    DetailNewsComponent,
    AddNewComponent,
    LogComponent,
    AddCustomerComponent,
    AddAddressComponent,
    EditCustomerComponent,
    DetailUserComponent,
    EditDeptosComponent,
    AddUserComponent,
    UpdateAddressComponent,
    GeneralIndicatorsComponent,
    MarathonsComponent,
    DetailMarathonComponent,
    AddMarathonComponent,
    AddCustomerUserComponent,
    AnaliticsComponent,
    TicketsComponent,
    AddTicketComponent,
    DetTicketComponent,
    UsersInactiveComponent,
    UserIndicatorsComponent,
    
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ProgrammersRoutingModule,
  ]
})
export class ProgrammersModule { }
