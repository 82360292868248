import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/app/variables/enviroment';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  uri:string = environment.uri;
  //uri:string = "http://ngdat.ucal.com.mx/api/";

  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');

  constructor(private httpClient:HttpClient) { }

  getEventosMes(mes:string,fecha:string){
    return this.httpClient.get(this.uri+"get_calendar_data?api_key="+this.api_key+"&token="+this.token+"&module=2&month="+mes+"&day="+fecha);
  }

  getEventosDia(dia:string,fecha:string){
    return this.httpClient.get(this.uri+"get_calendar_specific_data?api_key="+this.api_key+"&token="+this.token+"&module=2&objetive_day="+dia+"&day="+fecha);
  }

  getTodosEventos(mes:string,fecha:string){
    return this.httpClient.get(this.uri+"get_calendar_all_events?api_key="+this.api_key+"&token="+this.token+"&module=2&month="+mes+"&day="+fecha);
  }

  setFechaEventoInforme(id_informe:string,fecha:string){
    return this.httpClient.get(this.uri+"set_calendar_fecha_evento?api_key="+this.api_key+"&token="+this.token+"&module=2&id_informe="+id_informe+"&fecha_ven="+fecha);
  }

  getTodosEventosMantenimiento(mes:string,fecha:string){
    return this.httpClient.get(this.uri+"get_calendar_all_events_mant?api_key="+this.api_key+"&token="+this.token+"&module=153&month="+mes+"&day="+fecha);
  }

  getEventosMesMantenimiento(mes:string,fecha:string){
    return this.httpClient.get(this.uri+"get_calendar_data_mant?api_key="+this.api_key+"&token="+this.token+"&module=153&month="+mes+"&day="+fecha);
  }

  getEventosDiaMantenimiento(dia:string,fecha:string){
    return this.httpClient.get(this.uri+"get_calendar_specific_data_mant?api_key="+this.api_key+"&token="+this.token+"&module=153&objetive_day="+dia+"&day="+fecha);
  }
  
}
