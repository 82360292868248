import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneratorReportsService } from 'src/app/services/generator-reports.service';
import { environment } from 'src/app/variables/enviroment';
import { Chart } from 'chart.js';
import { IInforme } from 'src/app/services/models/IInformes';
import { ModalComponent } from 'src/app/components/modal/modal.component';

@Component({
  selector: 'app-micrometro',
  templateUrl: './micrometro.component.html',
  styleUrls: ['./micrometro.component.css']
})
export class MicrometroComponent implements OnInit {

  uri:string = environment.uri;
  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');
  url_to_load = '';
  //["Texto", brinco, numeroSaltos];
  variaciones = [ ["0.00 - 0.50 pulg", 0.1, 6, 0, 5],
                  ["0.00 - 0.40 pulg", 0.1, 5, 1, 4],
                  ["0.00 - 0.30 pulg", 0.1, 4, 2, 3]
                ];
  variaciones2 = [ [0, 0.1, 6, 0, 5],
    [0, 0.1, 5, 1, 4],
    [0, 0.1, 4, 2, 3]
  ];
  tam = [

  ];
  variacion = 0;
  
  finalValores:any=[];
  idsEquipos:any=[];
  idShet:string='';
  idpatron:any=[];

  equiposOdv:any=[]

  loader:number = 1;
  response:any = [];
  data:any=[];
  pdfInformes:any=[];
  clic:string;

  ibc_1:number;
  ibc_2:number;
  ibc_3:number;
  ibc_4:number;
  ibc_5:number;
  ibc_6:number;
  

  lugar:any = "sitio";
  temp:any;
  unidad:any;
  format:any = "AAA-MM-DD";
  fecha:any;
  ctx:any;
  myChart:any;
  incertidumbre: number;
  toleranciaJust: number;
  showCBTolerancia = false;
  constructor(
    private datos: ActivatedRoute, 
    private router:Router, 
    public conn: HttpClient,
    private generadorReports:GeneratorReportsService, 
    private modalService:NgbModal
  ) { }

  ngOnInit(): void {
    let datos=this.datos.snapshot.paramMap.get('id_equipos')
    this.idsEquipos.push(datos.split(','));
    this.idShet = this.datos.snapshot.paramMap.get('id_hoja');
    this.idpatron = this.datos.snapshot.paramMap.get('id_patron');

    this.getData();
  }

  getData(){
    this.generadorReports.verPlantilla(this.idShet,this.idsEquipos).subscribe(
      res=>{
        this.response = res;
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
        else this.data = this.response.data;

        this.equiposOdv = this.data.equipos;

        this.loader = 2;
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        this.loader = 2;
      }
    )

   
  }
  
  obtenerDatos(){

    this.loader = 1;
    var min_1 = -0.001; var max_1 = 0.001; 
    var min_2 = 0.099; var max_2 = 0.101; 
    var min_3 = 0.199; var max_3 = 0.201;
    var min_4 = 0.299; var max_4 = 0.301;
    var min_5 = 0.399; var max_5 = 0.401;
    var min_6 = 0.499; var max_6 = 0.501;

    var inTol_1=""; var inTol_2=""; var inTol_3="";
    var inTol_4=""; var inTol_5=""; var inTol_6="";

    var informes=[];
    for (let i = 0; i < this.equiposOdv.length; i++) {
      let listError = [];
      var tolerancia = [];
      var lesstolerancia = [];
      tolerancia.push(this.toleranciaJust);
      lesstolerancia.push(-this.toleranciaJust);
      tolerancia.push(this.toleranciaJust);
      lesstolerancia.push(-this.toleranciaJust);
      tolerancia.push(this.toleranciaJust);
      lesstolerancia.push(-this.toleranciaJust);
      tolerancia.push(this.toleranciaJust);
      lesstolerancia.push(-this.toleranciaJust);
      
      let listIbc = []; let listInTol = [];
      let equipo_id = this.equiposOdv[i].idEquipo;

      this.ibc_1 = parseFloat((document.getElementById(equipo_id+"-0") as HTMLInputElement).value);
      listIbc.push(this.ibc_1);
      this.ibc_2 = parseFloat((document.getElementById(equipo_id+"-1") as HTMLInputElement).value);
      listIbc.push(this.ibc_2);
      this.ibc_3 = parseFloat((document.getElementById(equipo_id+"-2") as HTMLInputElement).value);
      listIbc.push(this.ibc_3);
      this.ibc_4 = parseFloat((document.getElementById(equipo_id+"-3") as HTMLInputElement).value);
      listIbc.push(this.ibc_4);
      if(this.variacion > 3){
        this.ibc_5 = parseFloat((document.getElementById(equipo_id+"-4") as HTMLInputElement).value);
        listIbc.push(this.ibc_5);
        if(this.variacion > 4)
          this.ibc_6 = parseFloat((document.getElementById(equipo_id+"-5") as HTMLInputElement).value);
          listIbc.push(this.ibc_6);
      }
      
      
      this.ibc_1 <= min_1 ? inTol_1 = 'No cumple' : this.ibc_1 >= max_1 ? inTol_1 = 'No cumple'
      : inTol_1 = 'Si cumple';

      this.ibc_2 <= min_2 ? inTol_2 = 'No cumple' : this.ibc_2 >= max_2 ? inTol_2 = 'No cumple'
      : inTol_2 = 'Si cumple';

      this.ibc_3 <= min_3 ? inTol_3 = 'No cumple' : this.ibc_3 >= max_3 ? inTol_3 = 'No cumple'
      : inTol_3 = 'Si cumple';

      this.ibc_4 <= min_4 ? inTol_4 = 'No cumple' : this.ibc_4 >= max_4 ? inTol_4 = 'No cumple'
      : inTol_4 = 'Si cumple';
      if(this.variacion > 3){
        this.ibc_5 <= min_5 ? inTol_5 = 'No cumple' : this.ibc_5 >= max_5 ? inTol_5 = 'No cumple'
        : inTol_5 = 'Si cumple';
        if(this.variacion > 4){
          this.ibc_6 <= min_6 ? inTol_6 = 'No cumple' : this.ibc_6 >= max_6 ? inTol_6 = 'No cumple'
          : inTol_6 = 'Si cumple';
        }
      }


      (document.getElementById(equipo_id+"in0") as HTMLInputElement).setAttribute("value",inTol_1);
      listInTol.push(inTol_1);
      (document.getElementById(equipo_id+"in1") as HTMLInputElement).setAttribute("value",inTol_2);
      listInTol.push(inTol_2);
      (document.getElementById(equipo_id+"in2") as HTMLInputElement).setAttribute("value",inTol_3);
      listInTol.push(inTol_3);
      (document.getElementById(equipo_id+"in3") as HTMLInputElement).setAttribute("value",inTol_4);
      listInTol.push(inTol_4);
      if(this.variacion > 3){
        (document.getElementById(equipo_id+"in4") as HTMLInputElement).setAttribute("value",inTol_5);
        listInTol.push(inTol_5);
        if(this.variacion > 4){
          (document.getElementById(equipo_id+"in5") as HTMLInputElement).setAttribute("value",inTol_6);
          listInTol.push(inTol_6);
        }
      }

      var error0 = this.ibc_1 - 0.000;
      var error1 = this.ibc_2 - 0.100;
      var error2 = this.ibc_3 - 0.200;
      var error3 = this.ibc_4 - 0.300;
      var error4 = this.ibc_5 - 0.400;
      var error5 = this.ibc_6 - 0.500;
      
      listError.push(error0);
      listError.push(error1);
      listError.push(error2);
      listError.push(error3);
      if(this.variacion > 3){
        listError.push(error4);
        if(this.variacion > 4){
          listError.push(error5);
        }
      }

      var incertidumbre1 = error0-0.00058;
      var incertidumbre2 = error1-0.00058;
      var incertidumbre3 = error2-0.00058;
      var incertidumbre4 = error3-0.00058;
      var incertidumbre5 = error4-0.00058;
      var incertidumbre6 = error5-0.00058;
      var incertidumbres1 = [incertidumbre1, incertidumbre2, incertidumbre3, incertidumbre4];
      if(this.variacion > 3){
        incertidumbres1.push(incertidumbre5);
        if(this.variacion > 4){
          incertidumbres1.push(incertidumbre6);
        }
      }
      

      var yincertidumbre1 = error0 + 0.00058;
      var yincertidumbre2 = error1 + 0.00058;
      var yincertidumbre3 = error2 + 0.00058;
      var yincertidumbre4 = error3 + 0.00058;
      var yincertidumbre5 = error4 + 0.00058;
      var yincertidumbre6 = error5 + 0.00058;
      var yincertidumbres = [yincertidumbre1, yincertidumbre2, yincertidumbre3, yincertidumbre4];
      if(this.variacion > 3){
        yincertidumbres.push(yincertidumbre5);
        tolerancia.push(this.toleranciaJust);
        lesstolerancia.push(-this.toleranciaJust);
        if(this.variacion > 4){
          yincertidumbres.push(yincertidumbre6);
          tolerancia.push(this.toleranciaJust);
          lesstolerancia.push(-this.toleranciaJust);
        }
      }

      this.ctx = document.getElementById(equipo_id+"myChart");
      var step = 0.000;
      var max = 0.001;
      var start = -0.001;

      this.myChart = new Chart(this.ctx, {
        type: "line",
        data: {
          labels: ["0", "10", "20"],
          datasets: [
            {
              label: "Tolerancia del instrumento",
              data: tolerancia,
              fill: false,
              borderDash: [5,5],
              borderColor: "rgb(0, 0, 255)"
            },
            {
              label: "Incertidumbre",
              data: yincertidumbres,
              fill: false,
              lineTension: 0.000001,
              borderDash: [5,5],
              borderColor: "rgb(0, 0, 0)"
            },
            {
              label: "Error = (Media Inst. - Patrón)",
              data: listError,
              fill: false,
              lineTension: 0.000001,
              pointBackgroundColor: "rgb(0,0,0)",
              borderColor: "rgb(255, 0, 0)"
            },
            {
              label: "Incertidumbre",
              data: incertidumbres1,
              fill: false,
              lineTension: 0.000001,
              borderDash: [5,5],
              borderColor: "rgb(0, 0, 0)"
            },
            {
              label: "Tolerancia del instrumento",
              data: lesstolerancia,
              fill: false,
              borderDash: [5,5],
              borderColor: "rgb(0, 0, 255)"
            },
          ],
        },
        options: {
          legend: {
            display: false
          }
        },
        scaleSteps: Math.ceil((max - start) / step),
                scaleStepWidth: step,
                scaleStartValue: start
      });
      
      if(this.temp == null || this.unidad == null || this.fecha == null) {
        alert("Campos vacios");
      } else {
        let global = [];
        global.push(this.temp);
        global.push(this.unidad);
        global.push(this.format);
        global.push(this.fecha);
        global.push(this.lugar);
        
        let clic = this.uri+'micrometro_variaciones?api_key='+this.api_key+'&token='+this.token+
        '&module=2&id_plantilla='+this.idShet+'&libc='+listIbc+'&inTol='+listInTol+'&lerror='+
        listError+'&globales='+global+"&id_equipo="+equipo_id+"&patron_id="+this.idpatron+"&tole="+this.toleranciaJust;
        let a=[];
        a[0] = clic;
        console.log(clic);
        a[1]=this.equiposOdv[i].identificador+this.equiposOdv[i].partida;
        a[2]=this.myChart;  
        informes.push(a);
      }

    }
    
    this.pdfInformes = informes;
    this.loader=2;
    
  }

  obtenerImagen(url_pdf,myChartId){
    this.loader = 1;
    url_pdf = url_pdf[0];
    let base64 = myChartId.toBase64Image();

    var to_send:IInforme = {
      img: base64,
    };

    this.conn.post(url_pdf, to_send).subscribe(
      res => {
        this.response = res;
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
        else this.data = this.response.data;
        let informe = this.data;
        //console.log(informe);
        this.loader = 2;
        this.downloadFile(informe);
      },
      error=>{
        //console.log(error);
        alert("Error de petición");
        this.loader = 2;
      }
    );
    
    //this.url_to_load = this.uri+"transmisor_temperatura_20?api_key="+this.api_key+"&token="+this.token+"&module=2"
    //window.open(url_pdf+"&img="+base64, "ventana1" , "width=1000,height=900,scrollbars=NO");
  }

  asignarInforme(url_pdf, myChartId){
    this.loader = 1;
    url_pdf = url_pdf[0];
    let base64 = myChartId.toBase64Image();

    var to_send:IInforme = {
      img: base64,
    };


    this.conn.post(url_pdf+"&asignar="+"asignar", to_send).subscribe(
      res => {
        this.response = res;
        if(this.response.code == '1_0001' || this.response.code == '1_0002' || this.response.code == '1_0003')this.lauchModal(this.response.code,this.response.message);
        else if(this.response.code == '1_0004' || this.response.code == '0_0007')this.lauchModal(this.response.code,this.response.message);
        else this.data = this.response.data;
        alert("Asignado correctamente");
        this.loader = 2;
      },
      error=>{
        console.log(error);
        alert("Error de petición");
        this.loader = 2;
      }
    );
  }

  downloadFile(file_name){
    var url = environment.uri_for_reports+file_name;
    window.open(url, '_blank');
  }

  setVariacion(tipo){
    
    this.variacion = this.variaciones2[tipo][4];
    console.log(this.variacion);
    this.tam = [];
    var sum = 0.0;
    for(var i = 0; i < this.variaciones2[tipo][2]; i++ ){
      
      this.tam.push([sum.toFixed(2), "-"+i, "in"+i]);
      sum = sum +  this.variaciones2[tipo][1];
    }

    if(this.variacion == 3){
      this.toleranciaJust = 0.003;
      this.showCBTolerancia = false;
    }
    if(this.variacion == 4){
      this.toleranciaJust = 0.0015;
      this.showCBTolerancia = false;
    }
    if(this.variacion == 5){
      this.toleranciaJust = 0.0015;
      this.showCBTolerancia = true;
    }

  }
  
  setTolerancia(tipo){
    if(tipo == 1){
      this.toleranciaJust = 0.003;
    }
    if(tipo == 2){
      this.toleranciaJust = 0.0015;
    }
  }

  lauchModal(code:string,title:string){
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }
}
