import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Chart } from 'chart.js';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { IndicatorService } from 'src/app/services/indicator.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-indicators',
  templateUrl: './indicators.component.html',
  styleUrls: ['./indicators.component.css']
})
export class IndicatorsComponent implements OnInit {
  ctx:any;
  myChart:any;
  data_general:any = [];
  data_charts:any = [];
  result:any = [];
  loader = 1;
  id_user:string = '';
  module:string = '';
  permisos = JSON.parse(localStorage.getItem('permisos'));

  ind_jan = 0;
  ind_feb = 0;
  ind_mar = 0;
  ind_apr = 0;
  ind_may = 0;
  ind_jun = 0;
  ind_jul = 0;
  ind_aug = 0;
  ind_sep = 0;
  ind_oct = 0;
  ind_nov = 0;
  ind_dec = 0;

  constructor(private activedRoute:ActivatedRoute, private modalService:NgbModal, private userSer: UserService, private indicatorSer:IndicatorService) { }

  ngOnInit(): void {
    this.ctx = document.getElementById("chart");
    this.id_user = this.activedRoute.snapshot.paramMap.get('id');
    this.module = this.activedRoute.snapshot.paramMap.get('module');
    this.getIndicators();
  }

  getIndicators(){
    this.loader = 1;
    this.userSer.getPersonalIndicators(this.id_user,this.module).subscribe(
      res=>{
        this.result = res;
        this.data_general = this.result.data;

        if(this.result.code == '1_0001' || this.result.code == '1_0002' || this.result.code == '1_0003')this.lauchModal(this.result.code,this.result.message);
        else if(this.result.code == '1_0004')this.lauchModal(this.result.code,this.result.message);
        else {
          this.myChart = new Chart(this.ctx, {
            type: 'line',
            data: {
            labels: ["Ene", "Feb", "Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic"],
            datasets: [
              {
                label: 'Informes',
                data:this.data_general.quantitys,
                fill: true,
                borderColor: "rgb(255, 0, 0)",
                backgroundColor:"rgba(255, 0, 0,0.2)",
                lineTension: 0,   
              },
              {
                label: 'Metas',
                data:this.data_general.for_month,
                fill: true,
                borderColor: "rgb(0, 255, 0)",
                backgroundColor:"rgba(0, 255, 0,0.2)",
                lineTension: 0,   
              }
            ]
            },
            options: {
              bezierCurve : false,
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                },
                title: {
                  display: false
                }
              }
            },
          });

          this.ind_jan = this.data_general.for_month[0];
          this.ind_feb = this.data_general.for_month[1];
          this.ind_mar = this.data_general.for_month[2];
          this.ind_apr = this.data_general.for_month[3];
          this.ind_may = this.data_general.for_month[4];
          this.ind_jun = this.data_general.for_month[5];
          this.ind_jul = this.data_general.for_month[6];
          this.ind_aug = this.data_general.for_month[7];
          this.ind_sep = this.data_general.for_month[8];
          this.ind_oct = this.data_general.for_month[9];
          this.ind_nov = this.data_general.for_month[10];
          this.ind_dec = this.data_general.for_month[11];
        }
        
        this.loader = 2;
        
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        console.log(err);
        this.loader = 2;
      }
    );
  }
/* ASIGNAR META */
  setIndicatorToUser(){
    var values = [
      this.ind_jan,
      this.ind_feb,
      this.ind_mar,
      this.ind_apr,
      this.ind_may,
      this.ind_jun,
      this.ind_jul,
      this.ind_aug,
      this.ind_sep,
      this.ind_oct,
      this.ind_nov,
      this.ind_dec
    ];

    let form = new FormData();
    form.append('metas',JSON.stringify(values));
    
    this.indicatorSer.setIndicatorsForUser(this.id_user,form).subscribe(
      res=>{
        this.result = res;
        
        if(this.result.code == '1_0001' || this.result.code == '1_0002' || this.result.code == '1_0003')this.lauchModal(this.result.code,this.result.message);
        else if(this.result.code == '1_0004')this.lauchModal(this.result.code,this.result.message);
        else {
          this.myChart.destroy();
          this.getIndicators();
        }
      },err=>{
        this.lauchModal('0000x00','Error de consulta');
        console.log(err);
        this.loader = 2;
      }
    );
  }

  lauchModal(code:string,title:string){
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }

}
