import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TicketsService } from "src/app/services/tickets.service";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { EventService } from "src/app/services/event.service";
import { environment } from "src/app/variables/enviroment";
import { InstrumentService } from "src/app/services/instrument.service";

@Component({
  selector: "app-event-detail",
  templateUrl: "./event-detail.component.html",
  styleUrls: ["./event-detail.component.scss"],
})
export class EventDetailComponent implements OnInit {
  removerDetalle: string;

  uri: string = environment.uri;
  token = localStorage.getItem("token");
  api_key = localStorage.getItem("key");

  id_event: string = "";
  loader: number = 1;
  response: any = [];
  data: any = [];
  partidas: any = [];
  xlsx_list: any = [];
  permmisions = JSON.parse(localStorage.getItem("permisos"));

  file: any;
  file_name = "";
  date_t = "";
  public partida_det = {
    id_equipo: "",
    id_event_det: "",
    id_rs: "",
    frecuencia: 0,
    partida: "",
    tag: "",
    equipo: "",
  };

  editando = 0;
  list_change_status = [];
  list_change_cancelar_status = [];
  list_change_revisar_status = [];
  list_change_autorizar_status = [];
  allset = false;
  inf_rechazados: any = [];
  comentarios_rechazo = "";
  id_rechazar = 0;
  informe_rechazado = "";
  readyToSend = false;
  listaaenviar = "";

  id_informe_remover = "";
  passwrd = "";
  notaParaRemover = "";
  nomInforme = "";

  id_part_selected_to_xlsx = "";

  search_user = "";
  search_user_result = [];
  search_user_id = "";
  userUploadReport: string = "";
  calibratedUser: boolean = true;

  id_partida_remover = "";
  nomXlsx = "";
  notaParaRemoverXlsx = "";

  departamento_odv = "";
  depto_odv_id = 0;
  depto_edo: string;
  equipos_diff_depto: any = [];

  status_event_array = [
    { id: 1, class: "badge-light", info: "Abierto" },
    { id: 2, class: "badge-warning", info: "Almacén" },
    { id: 3, class: "badge-info", info: "Agendado" },
    { id: 4, class: "badge-primary", info: "Con Informe" },
    { id: 5, class: "badge-primary", info: "Revisado" },
    { id: 6, class: "badge-success", info: "Autorizado" },
    { id: 7, class: "badge-success", info: "Entregado" },
    { id: 8, class: "badge-purple", info: "Cancelado" },
    { id: 9, class: "badge-danger", info: "Calibración" },
  ];

  //Carga de informes por masiva
  nombresFiles: string = null;
  numSelect: number = 0;
  response_upload_reports: any = {};

  files: any = [];

  //Carga de xlsx masiva

  nombresFiles_: string = null;
  numSelect_: number = 0;
  filesxlsx: any = [];

  public url_to_load : string = '';

  @ViewChild("btn_close_modal") divView: ElementRef;

  constructor(
    private eventService: EventService,
    private modalService: NgbModal,
    private activedRoute: ActivatedRoute,
    private instument_service: InstrumentService
  ) {}

  ngOnInit(): void {
    this.id_event = this.activedRoute.snapshot.paramMap.get("id");
    this.loadEventDetail();
  }

  setAll() {
    if (this.allset == true) {
      this.allset = false;
      this.list_change_cancelar_status = [];
      this.list_change_revisar_status = [];
      this.list_change_autorizar_status = [];
    } else {
      this.allset = true;
      this.list_change_status = [];
      this.partidas.forEach((element) => {
        if (element.estatus == 0) {
          this.list_change_cancelar_status.push(element.id_det_ev);
        }
        if (element.estatus == 1) {
          this.list_change_revisar_status.push(element.id_det_ev);
        }
        if (element.estatus == 2) {
          this.list_change_autorizar_status.push(element.id_det_ev);
        }
      });
    }
  }

  loadEventDetail() {
    this.xlsx_list = [];
    this.eventService.getEventDetail(this.id_event).subscribe(
      (res) => {
        this.response = res;
        this.loader = 2;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.data = this.response.data;
          if (this.data.departamento != null) {
            this.departamento_odv = this.data.departamento.depto;
            this.depto_odv_id = this.data.departamento.id;
            this.depto_edo = this.data.departamento.estado;
          }
          this.inf_rechazados = this.data.inf_rechazados;
          this.partidas = this.data.partidas;
          this.getDiffDepartmentInstrument();

          for (var i = 0; i < this.partidas.length; i++) {
            var xlsx = this.partidas[i].xlsx;
            for (var j = 0; j < xlsx.length; j++) {
              var value = {
                id_part: xlsx[j].id,
                part: this.partidas[i].partida,
                tag: this.partidas[i].tag,
                desc: this.partidas[i].descripcion,
                report: this.partidas[i].informe,
                xlsx: xlsx[j].archivo,
              };

              this.xlsx_list.push(value);
            }
          }
        }
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  downloadFile(document) {
    var url = environment.uri_server + "storage/public/xlsx_events/" + document;
    window.open(url, "_blank");
  }

  enviarSolicitudes(estatus) {
    if (estatus == 4) {
      this.list_change_status = this.list_change_cancelar_status;
    }
    if (estatus == 2) {
      this.list_change_status = this.list_change_revisar_status;
    }
    if (estatus == 3) {
      this.list_change_status = this.list_change_autorizar_status;
    }

    for (let o = 0; o < this.list_change_status.length; o++) {
      let element = this.list_change_status[o];

      if (o == 0) {
        this.listaaenviar += element;
      } else {
        this.listaaenviar += ",";
        this.listaaenviar += element;
      }
    }
    this.chageStatusPartida(this.listaaenviar, estatus);
    this.cambiarAEditando();
  }

  cambiarAEditando() {
    if (this.editando == 1) {
      this.editando = 0;
      this.allset = false;
      this.list_change_revisar_status = [];
      this.list_change_cancelar_status = [];
      this.list_change_autorizar_status = [];
    } else {
      this.editando = 1;
    }
  }

  addToList(id, estatus) {
    let posicionaeliminar = null;
    let eliminar = false;

    if (estatus == 0) {
      for (let i = 0; i < this.list_change_cancelar_status.length; i++) {
        let element = this.list_change_cancelar_status[i];
        if (id == element) {
          posicionaeliminar = i;
          eliminar = true;
        }
      }
      if (eliminar) {
        this.list_change_cancelar_status.splice(posicionaeliminar, 1);
      } else {
        this.list_change_cancelar_status.push(id);
      }
    }
    if (estatus == 1) {
      for (let i = 0; i < this.list_change_revisar_status.length; i++) {
        let element = this.list_change_revisar_status[i];
        if (id == element) {
          posicionaeliminar = i;
          eliminar = true;
        }
      }
      if (eliminar) {
        this.list_change_revisar_status.splice(posicionaeliminar, 1);
      } else {
        this.list_change_revisar_status.push(id);
      }
    }
    if (estatus == 2) {
      for (let i = 0; i < this.list_change_autorizar_status.length; i++) {
        let element = this.list_change_autorizar_status[i];
        if (id == element) {
          posicionaeliminar = i;
          eliminar = true;
        }
      }
      if (eliminar) {
        this.list_change_autorizar_status.splice(posicionaeliminar, 1);
      } else {
        this.list_change_autorizar_status.push(id);
      }
    }
  }

  changeID(partida_selected) {
    const { id_equipo, id_det_ev, id_rs, frecuencia, partida, tag, equipo } =
      partida_selected;
    this.partida_det.id_equipo = id_equipo;
    this.partida_det.id_event_det = id_det_ev;
    this.partida_det.id_rs = id_rs;
    this.partida_det.frecuencia = frecuencia;
    this.partida_det.partida = partida;
    this.partida_det.tag = tag;
    this.partida_det.equipo = equipo;
  }

  loadDate(value) {
    this.date_t = value;
  }

  chageStatusPartida(id, estatus) {
    let result_2: any = [];
    let module = "73";
    if (estatus == "2" || estatus == "4") {
      module = "72";
    }

    this.eventService.changeEstatusPart(id, estatus, module).subscribe(
      (res) => {
        result_2 = res;
        if (
          result_2.code == "1_0001" ||
          result_2.code == "1_0002" ||
          result_2.code == "1_0003"
        )
          this.lauchModal(result_2.code, result_2.message, "");
        else if (result_2.code == "1_0004" || result_2.code == "0_007")
          this.lauchModal(result_2.code, result_2.message, "");
        else {
          if (result_2.data == 1) {
            this.loadEventDetail();
            this.listaaenviar = "";
          } else
            this.lauchModal(
              "0000x00",
              "Algo salió mal. Intenta nuevamente",
              ""
            );
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  saveFileInServer() {
    let result: any = [];
    this.loader = 1;
    let form = new FormData();
    form.append("file", this.file.files[0]);
    form.append("id_equipo", this.partida_det.id_equipo);
    form.append("id_event_det", this.partida_det.id_event_det);
    form.append("date_t", this.date_t);
    form.append("id_rs", this.partida_det.id_rs);
    this.calibratedUser
      ? form.append("calibratedUser", "1")
      : form.append("calibratedUser", "0");
    form.append("id_user_calibrated", this.search_user_id);

    this.eventService.setReport(form).subscribe(
      (res) => {
        result = res;
        if (
          result.code == "1_0001" ||
          result.code == "1_0002" ||
          result.code == "1_0003"
        )
          this.lauchModal(result.code, result.message, "");
        else if (result.code == "1_0004" || result.code == "0_007")
          this.lauchModal(result.code, result.message, "");
        else {
          if (result.data == 1) {
            this.divView.nativeElement.click();
            this.file_name = "";
            this.date_t = "";
            this.partida_det.id_equipo = "";
            this.partida_det.id_event_det = "";
            this.partida_det.id_rs = "";
            this.partida_det.frecuencia = 0;

            this.file = "";
            this.file_name = "";
            this.loadEventDetail();
          } else
            this.lauchModal(
              "0000x00",
              "Algo salió mal. Intenta nuevamente",
              ""
            );
        }
        this.loader = 2;
      },
      (err) => {
        this.divView.nativeElement.click();
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  calibratedByUser() {
    this.calibratedUser
      ? (this.calibratedUser = false)
      : (this.calibratedUser = true);
  }

  prepareRechazar(id, informe) {
    this.id_rechazar = id;
    this.informe_rechazado = informe;
  }

  typingOnComments() {
    if (this.comentarios_rechazo.trim().length > 0) {
      this.readyToSend = true;
    } else {
      this.readyToSend = false;
    }
  }

  rechazarInforme() {
    let result: any = [];
    this.loader = 1;
    this.eventService
      .rechazarInforme(this.id_rechazar, this.comentarios_rechazo)
      .subscribe(
        (res) => {
          result = res;
          if (
            result.code == "1_0001" ||
            result.code == "1_0002" ||
            result.code == "1_0003"
          )
            this.lauchModal(result.code, result.message, "");
          else if (result.code == "1_0004" || result.code == "0_007")
            this.lauchModal(result.code, result.message, "");
          else {
            if (result.data == 1) {
              this.loadEventDetail();
              this.comentarios_rechazo = "";
            } else
              this.lauchModal(
                "0000x00",
                "Algo salió mal. Intenta nuevamente",
                ""
              );
          }
          this.loader = 2;
        },
        (err) => {
          this.lauchModal("0000x00", "Error de consulta", "");
          this.loader = 2;
        }
      );
  }

  aceptarInformeRechazado(id) {
    let result: any = [];
    this.loader = 1;
    this.eventService.aceptarInformeRechazado(id).subscribe(
      (res) => {
        result = res;
        if (
          result.code == "1_0001" ||
          result.code == "1_0002" ||
          result.code == "1_0003"
        )
          this.lauchModal(result.code, result.message, "");
        else if (result.code == "1_0004" || result.code == "0_007")
          this.lauchModal(result.code, result.message, "");
        else {
          if (result.data == 1) {
            this.loadEventDetail();
          } else
            this.lauchModal(
              "0000x00",
              "Algo salió mal. Intenta nuevamente",
              ""
            );
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  loadFile(value) {
    this.file = value.target;
    this.file_name = this.file.files[0].name;
  }

  prepareToUoploadXLSX(id) {
    this.id_part_selected_to_xlsx = id;
  }

  loadXLSXToPart() {
    let result: any = [];

    let form = new FormData();
    form.append("id_event_det", this.id_part_selected_to_xlsx);
    form.append("id_event", this.id_event);
    form.append("file", this.file.files[0]);

    this.loader = 1;

    this.eventService.uploadXLSXToPart(form).subscribe(
      (res) => {
        result = res;
        if (
          result.code == "1_0001" ||
          result.code == "1_0002" ||
          result.code == "1_0003"
        )
          this.lauchModal(result.code, result.message, "");
        else if (result.code == "1_0004" || result.code == "0_007")
          this.lauchModal(result.code, result.message, "");
        else {
          if (result.data == 1) {
            this.divView.nativeElement.click();
            this.file_name = "";
            this.xlsx_list = [];
            this.loadEventDetail();
          } else
            this.lauchModal(
              "0000x00",
              "Algo salió mal. Intenta nuevamente",
              ""
            );
        }
        this.loader = 2;
      },
      (err) => {
        this.divView.nativeElement.click();
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );

    this.divView.nativeElement.click();
  }

  editarPartida() {
    this.loader = 1;
    let result: any = [];
    var id_odv = "";
    var to_send: any = [];
    id_odv = this.partidas[0].id_odv;
    for (let i = 0; i < this.partidas.length; i++) {
      var val = (
        document.getElementById(
          this.partidas[i].id_equipo + "-part"
        ) as HTMLInputElement
      ).value;
      var data_parts: any = [];
      data_parts.push(i, this.partidas[i].id_equipo);
      data_parts.push(val);
      to_send.push(data_parts);
    }

    this.eventService.changePartsOdv(id_odv, to_send).subscribe(
      (res) => {
        result = res;
        if (
          result.code == "1_0001" ||
          result.code == "1_0002" ||
          result.code == "1_0003"
        )
          this.lauchModal(result.code, result.message, "");
        else if (result.code == "1_0004" || result.code == "0_007")
          this.lauchModal(result.code, result.message, "");
        else {
          if (result.data == 1) {
            this.loadEventDetail();
            this.editando = 0;
          } else {
            this.lauchModal(
              "0000x00",
              "Algo salió mal. Intenta nuevamente",
              ""
            );
          }
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  peticionDownloadZip() {
    this.loader = 1;
    let result: any = [];

    this.eventService.downloadZIPReports(this.id_event).subscribe(
      (res) => {
        result = res;
        if (
          result.code == "1_0001" ||
          result.code == "1_0002" ||
          result.code == "1_0003"
        )
          this.lauchModal(result.code, result.message, "");
        else if (result.code == "1_0004" || result.code == "0_007")
          this.lauchModal(result.code, result.message, "");
        else {
          if (result.data == 0) {
            this.lauchModal(
              "0000x00",
              "Algo salió mal. Intenta nuevamente",
              ""
            );
          } else this.downloadZipFile(result.data);
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  downloadZipFile(fileName) {
    var url = environment.uri_server + "storage/public/zip_eventos/" + fileName;
    window.open(url, "_blank");
  }

  lauchModal(code: string, title: string, message) {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
  }

  prepareRemoverInforme(tipo, id, nombre, detalle) {
    if (tipo == 1) {
      this.id_informe_remover = id;
      this.nomInforme = nombre;
      this.removerDetalle = detalle;
    } else {
      this.id_partida_remover = id;
      this.nomXlsx = nombre;
    }
  }
  public canRemoveInforme(): boolean{
    if(this.passwrd != '' && this.passwrd != null) {
      if(this.notaParaRemover != '' && this.notaParaRemover != null) {
        if(this.search_user_id != '' && this.search_user_id != null ) {
          return false;
        }
      }
    }
    return true;
  }

  removerInforme() {
    let form = new FormData();
    form.append("id_informe", this.id_informe_remover);
    form.append("password", this.passwrd);
    form.append("nota", this.notaParaRemover);
    form.append("id_brindado", this.search_user_id);
    form.append("id_detalle", this.removerDetalle);
    this.loader = 1;
    this.eventService.removeReportAdmin(form).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          if (this.response.data == 0) {
            this.lauchModal("0_007", "Contraseña incorrecta", "");
          } else this.loadEventDetail();
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  searchUser() {
    let form = new FormData();
    form.append("user", this.search_user);
    this.loader = 1;
    this.eventService.searchUserBajaInforme(form).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.search_user_result = this.response.data;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  searchUserName() {
    this.search_user = this.userUploadReport;
    this.searchUserCargarInforme();
  }

  setSearchUser(id, detail, index) {
    const USER_SELECTED = this.search_user_result[index];
    this.search_user_id = id;
    this.search_user = detail;
    this.userUploadReport = `${USER_SELECTED.nombre} ${USER_SELECTED.apellido}`;
    this.search_user_result = [];
  }

  removerxlsx() {
    let form = new FormData();
    form.append("id_partida", this.id_partida_remover);
    form.append("password", this.passwrd);
    form.append("nota", this.notaParaRemoverXlsx);
    form.append("id_brindado", this.search_user_id);

    this.loader = 1;
    this.eventService.removeXlsxInforme(form).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          if (this.response.data == 0) {
            this.lauchModal("0_007", "Contraseña incorrecta", "");
          } else this.loadEventDetail();
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  searchUserCargarInforme() {
    let form = new FormData();
    form.append("user", this.search_user);
    this.loader = 1;
    this.eventService.searchUser(form).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.search_user_result = this.response.data;
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  getDiffDepartmentInstrument() {
    this.equipos_diff_depto = [];
    if (this.departamento_odv != "") {
      this.partidas.forEach((parts) => {
        if (parts.id_depto != this.depto_odv_id) {
          this.equipos_diff_depto.push(parts);
        }
      });
    }
  }

  sendInstrumentsToEditDepto(type, equipo) {
    if (type == 1) {
      this.equipos_diff_depto.forEach((equipos) => {
        this.saveInstrumentToOdvDepto(equipos.id_equipo);
      });
    } else {
      this.saveInstrumentToOdvDepto(equipo);
    }
  }

  saveInstrumentToOdvDepto(id) {
    let form = new FormData();

    form.append("id_equipo", id);
    form.append("id_depto", this.depto_odv_id.toString());
    this.loader = 1;

    this.instument_service.saveInstrumentoOdvDepto(form).subscribe(
      (res) => {
        this.response = res;
        if (
          this.response.code == "1_0001" ||
          this.response.code == "1_0002" ||
          this.response.code == "1_0003"
        )
          this.lauchModal(this.response.code, this.response.message, "");
        else if (this.response.code == "1_0004")
          this.lauchModal(this.response.code, this.response.message, "");
        else {
          this.loadEventDetail();
        }
        this.loader = 2;
      },
      (err) => {
        this.lauchModal("0000x00", "Error de consulta", "");
        this.loader = 2;
      }
    );
  }

  subirAchivos() {
    let result: any = [];
    this.loader = 1;

    if (this.files.length > 0) {
      let form = new FormData();
      var i = 1;
      this.files.forEach((element) => {
        form.append("date", this.date_t);
        form.append("id_evento", this.id_event);
        form.append("file_" + i, element);
        i++;
      });

      this.eventService.uploadFilesReports(form).subscribe(
        (res) => {
          result = res;
          if (
            result.code == "1_0001" ||
            result.code == "1_0002" ||
            result.code == "1_0003"
          )
            this.lauchModal(result.code, "", result.message);
          else if (result.code == "1_0004" || result.code == "0_007")
            this.lauchModal(result.code, "", result.message);
          else {
            this.response_upload_reports = result.data;
            this.loadEventDetail();
          }
          this.loader = 2;
        },
        (err) => {
          this.lauchModal("0000x00", "Error de consulta", "");
          this.loader = 2;
        }
      );
    }
  }

  loadFiles(value) {
    for (var i = 0; i < value.target.files.length; i++) {
      this.files.push(value.target.files[i]);
      this.nombresFiles == null
        ? (this.nombresFiles = value.target.files[i].name)
        : (this.nombresFiles += ", " + value.target.files[i].name);
    }
    this.numSelect = this.files.length;
  }

  subirAchivosXlsxMasivo() {
    let result: any = [];
    this.loader = 1;

    if (this.filesxlsx.length > 0) {
      let form = new FormData();
      var i = 1;
      this.filesxlsx.forEach((element) => {
        form.append("id_evento", this.id_event);
        form.append("file_" + i, element);
        i++;
      });

      this.eventService.uploadFilesReportsXlsx(form).subscribe(
        (res) => {
          result = res;
          if (
            result.code == "1_0001" ||
            result.code == "1_0002" ||
            result.code == "1_0003"
          )
            this.lauchModal(result.code, "", result.message);
          else if (result.code == "1_0004" || result.code == "0_007")
            this.lauchModal(result.code, "", result.message);
          else {
            this.response_upload_reports = result.data;
            this.loadEventDetail();
          }
          this.loader = 2;
        },
        (err) => {
          this.lauchModal("0000x00", "Error de consulta", "");
          this.loader = 2;
        }
      );
    }
  }

  loadFilesXlsx(value) {
    for (var i = 0; i < value.target.files.length; i++) {
      this.filesxlsx.push(value.target.files[i]);
      this.nombresFiles_ == null
        ? (this.nombresFiles_ = value.target.files[i].name)
        : (this.nombresFiles_ += ", " + value.target.files[i].name);
    }
    this.numSelect_ = this.filesxlsx.length;
  }

  public calculateEndDate(): string {
    const frecuencia = this.partida_det.frecuencia;
    const fecha_calibracion = new Date(this.date_t);

    fecha_calibracion.setMonth(fecha_calibracion.getMonth() + frecuencia);
    const anio = fecha_calibracion.getFullYear();
    const mes = fecha_calibracion.getMonth() + 1;
    const dia = fecha_calibracion.getDate();

    return `${dia.toString().padStart(2, "0")}-${mes
      .toString()
      .padStart(2, "0")}-${anio}`;
  }

  public downloadLabels():void {
    this.url_to_load =
    environment.uri +
    "get_labels_xlsx?api_key=" +
    this.api_key +
    "&token=" +
    this.token +
    "&module=188&id_event=" +
    this.id_event;
  }
}
