import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/app/variables/enviroment';

@Injectable({
  providedIn: 'root'
})
export class SaleOrderService {
  uri:string = environment.uri;
  //uri:string ="http://localhost/etalonsbackend/public/index.php/api/";
  //uri:string = "http://ngdat.ucal.com.mx/api/";
  token = localStorage.getItem('token');
  api_key = localStorage.getItem('key');
  
  constructor(private httpClient:HttpClient) { }

  getODCList(){
    return this.httpClient.get(this.uri+"get_odc_list?api_key="+this.api_key+"&token="+this.token+"&module=46");
  }
  getSupplier(value_to_search){
    return this.httpClient.get(this.uri+"search_supplier?api_key="+this.api_key+"&token="+this.token+"&module=48&value="+value_to_search);
  }
  saveODC(data){
    return this.httpClient.post(this.uri+"generate_odc?api_key="+this.api_key+"&token="+this.token+"&module=48",data);
  }
  getDetailODC(data){
    return this.httpClient.get(this.uri+"get_data_odc?api_key="+this.api_key+"&token="+this.token+"&module=47&id="+data);
  }
  getDataToEdit(data){
    return this.httpClient.get(this.uri+"get_data_odc_edit?api_key="+this.api_key+"&token="+this.token+"&module=53&id="+data);
  }
  saveEditODC(data){
    return this.httpClient.post(this.uri+"save_data_odc_edit?api_key="+this.api_key+"&token="+this.token+"&module=53",data);
  }
  addPartODC(data){
    return this.httpClient.post(this.uri+"add_part_odc?api_key="+this.api_key+"&token="+this.token+"&module=53",data);
  }
  saveEditPart(data){
    return this.httpClient.post(this.uri+"edit_part_odc?api_key="+this.api_key+"&token="+this.token+"&module=53",data);
  }
  deleteEditPart(id){
    return this.httpClient.get(this.uri+"delete_part_odc?api_key="+this.api_key+"&token="+this.token+"&module=53&id_det="+id);
  }
  authorizeODC(id_odc){
    return this.httpClient.get(this.uri+"authorize_odc?api_key="+this.api_key+"&token="+this.token+"&module=54&id="+id_odc);
  }
  unauthorizeODC(id_odc){
    return this.httpClient.get(this.uri+"unauthorize_odc?api_key="+this.api_key+"&token="+this.token+"&module=54&id="+id_odc);
  }
  cancelODC(id_odc){
    return this.httpClient.get(this.uri+"cancel_odc?api_key="+this.api_key+"&token="+this.token+"&module=54&id="+id_odc);
  }
  saveCommentODC(data){
    return this.httpClient.post(this.uri+"save_comment_odc?api_key="+this.api_key+"&token="+this.token+"&module=47",data);
  }
  addDocumentToODC(data){
    return this.httpClient.post(this.uri+"save_document_odc?api_key="+this.api_key+"&token="+this.token+"&module=56",data);
  }
  changeEstatusToSend(id_odc){
    return this.httpClient.get(this.uri+"change_estatus_to_send?api_key="+this.api_key+"&token="+this.token+"&module=47&id="+id_odc);
  }
  billParts(data){
    return this.httpClient.post(this.uri+"billing_parts_odc?api_key="+this.api_key+"&token="+this.token+"&module=57",data);
  }
  getPDF(id){
    return this.httpClient.get(this.uri+"get_pdf_odc?api_key="+this.api_key+"&token="+this.token+"&module=58&id="+id);
  }
  getList(){
    return this.httpClient.get(this.uri+"get_supliers_list_names?api_key="+this.api_key+"&token="+this.token+"&module=26");
  }
  postConsultaClienteProveedor(data,cliente,tipo){
    return this.httpClient.get(this.uri+"get_client_supliers_filter?api_key="+this.api_key+"&token="+this.token+"&module=108&cliente="+cliente+"&proveedor="+data+'&tipo='+tipo);
  }

  getOdcs(id_odc){
    return this.httpClient.get(this.uri+"get_siguente_odc?api_key="+this.api_key+"&token="+this.token+"&module=46&id="+id_odc)
  }

  approveOdc(id_odc){
    return this.httpClient.get(this.uri+"approve_odc?api_key="+this.api_key+"&token="+this.token+"&module=167&id="+id_odc)
  }

  

}

