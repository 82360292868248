import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { CustomerService } from "src/app/services/customer.service";
import { IAddress } from "src/app/services/models/IAddress";

@Component({
  selector: "app-update-address",
  templateUrl: "./update-address.component.html",
  styleUrls: ["./update-address.component.css"],
})
export class UpdateAddressComponent implements OnInit {
  id: string = "";
  razondir: string = "";
  calle: string = "";
  colonia: string = "";
  numero: string = "";
  ciudad: string = "";
  estado: string = "";
  cp: string = "";
  result: any;
  data: any = [];
  id_cliente: string = "";
  constructor(
    private router: Router,
    private activedRoute: ActivatedRoute,
    private customerService: CustomerService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.id = this.activedRoute.snapshot.paramMap.get("iddireccion");
    this.getAddress();
  }

  /**
   * This function retrieves a customer's address from a service and updates the component's data
   * accordingly.
   */
  private getAddress() :void {
    this.customerService.getAddress(this.id).subscribe(
      (res) => {
        this.result = res;
        console.log(res);
        if (
          this.result.code == "1_0001" ||
          this.result.code == "1_0002" ||
          this.result.code == "1_0003"
        )
          this.lauchModal(this.result.code, this.result.message);
        else if (this.result.code == "1_0004")
          this.lauchModal(this.result.code, this.result.message);
        else {
          this.data = this.result.data;
          this.razondir = this.data.nombre;
          this.calle = this.data.calle;
          this.colonia = this.data.colonia;
          this.numero = this.data.n_exterior;
          this.ciudad = this.data.ciudad;
          this.estado = this.data.estado;
          this.cp = this.data.cp;
          this.id_cliente = this.data.id_cliente;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /**
   * This function posts a new address to the server for a customer.
   */
  public postDireccion() :void {
    let iDireccion: IAddress = {
      id: this.id,
      nombre_direccion: this.razondir,
      calle: this.calle,
      colonia: this.colonia,
      n_exterior: this.numero,
      ciudad: this.ciudad,
      estado: this.estado,
      cp: this.cp,
      nueva: "0",
    };

    this.customerService.postDireccion(iDireccion).subscribe(
      (res) => {
        console.log(res);
        this.result = res;
        if (this.result.data > 0) {
          this.router.navigate(["programmers/customer/", this.id_cliente]);
        } else {
          this.lauchModal(
            this.result.code,
            "Ocurrió un error en el servidor, intenta mas tarde."
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /**
   * This is a private asynchronous function that launches a modal with a given code and title using
   * the ModalComponent.
   * @param {string} code - The code parameter is a string that represents the code that will be
   * displayed in the modal.
   * @param {string} title - The title parameter is a string that represents the title of the modal
   * that will be launched. It will be displayed at the top of the modal to provide context to the
   * user.
   */
  private async lauchModal(code: string, title: string) :Promise<void> {
    const modalRef = await this.modalService.open(ModalComponent);
    modalRef.componentInstance.code = code;
    modalRef.componentInstance.title = title;
  }
}
